/** @jsx jsx */
import { jsx } from '@emotion/core';
import { MouseEvent, KeyboardEvent, forwardRef } from 'react';
import * as styles from './styles';

export interface IProps {
  title: string;
  onClick: (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLDivElement>) => void;
  color?: string;
  tabIndex?: number;
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
}

jsx;
export default forwardRef<HTMLDivElement, IProps>(({ title, color, onClick, tabIndex = 0, onKeyDown }, ref) => {
  return (
    <div
      css={styles.selectedBtn(color)}
      onClick={onClick}
      onKeyPress={(e: KeyboardEvent<HTMLDivElement>) => onClick(e)}
      tabIndex={tabIndex}
      ref={ref}
      onKeyDown={onKeyDown}
    >
      {title}
    </div>
  );
});
