/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, forwardRef } from 'react';
// utils
import _ from 'lodash';
import moment, { Moment } from 'moment';
import Datetime, { DatetimepickerProps } from 'react-datetime';
import { Omit } from '../../../../utils/typeHandlers';
import { toast } from 'react-toastify';
// other
import { DATE_FORMAT, YEAR_FORMAT } from '../../../../config';
import { getTimeFormat, isAmPmTimeFormat } from '../../../../utils/timeFormatter';
import * as styles from './styles';
import './datepicker.css';

const TypedDatetime = Datetime as React.ComponentType<
  DatetimepickerProps & {
    renderInput?: (props: any, openCalendar: () => void, closeCalendar: () => void) => void;
  }
>;

export type IDatepickerValue = number | string | Moment;

interface IRange {
  from?: any;
  to?: any;
}

interface IProps extends Omit<DatetimepickerProps, 'onChange' | 'value'> {
  value: IDatepickerValue;
  onChange: (a: number) => void;
  dateFormat?: string | boolean;
  timeFormat?: string | boolean;
  timeConstraints?: any;
  disabled?: boolean;
  range?: IRange;
  autoFocus?: boolean;
  tabIndex?: number;
  onBlur?: () => void;
  onFocusLeave?: () => void;
  onKeyPress?: (e: any) => void;
  setStatic?: boolean;
  forwardRef?: any;
}

interface IState {
  localValue: any;
}

jsx;

const checkRange = (value: any, { from = null, to = null }: IRange, time = 'day') => {
  let result = true;
  from = moment.utc(from);
  to = moment.utc(to);

  if (from && to) {
    result = value.isSameOrAfter(from.startOf(time)) && value.isSameOrBefore(to.endOf(time));
  } else if (from) {
    result = value.isSameOrAfter(from.startOf(time));
  } else if (to) {
    result = value.isSameOrBefore(to.endOf(time));
  }

  return result;
};

export class DatetimePicker extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      localValue: null,
    };
  }

  getDateTimeFormat = () => {
    const { dateFormat = DATE_FORMAT, timeFormat = getTimeFormat() } = this.props;
    return `${dateFormat} ${timeFormat} ${YEAR_FORMAT}`;
  };

  change = (value: Moment | string) => {
    const { onChange, range } = this.props;
    value = _.isString(value) ? moment.utc(value) : value;

    if (!range || checkRange(value, range, 'minute')) {
      const timestamp = value.valueOf();

      onChange(timestamp);
    }
    this.forceUpdate();
  };

  setLocalValue = (value: string) => {
    this.setState({ localValue: value });
  };

  clearLocalValue = () => {
    this.setState({ localValue: null });
  };

  parseStringToTime = (value: any) => {
    const { value: initialValue } = this.props;
    const year = moment.utc(initialValue).format(YEAR_FORMAT);
    const dateTimeFormat = this.getDateTimeFormat();
    return moment.utc(`${value} ${year}`, dateTimeFormat).valueOf();
  };

  endManualEdit = () => {
    const { localValue } = this.state;

    if (localValue) {
      const time = this.parseStringToTime(localValue);
      if (time) {
        this.change(moment(time));
      } else {
        toast.error('You entered an incorrect date');
      }
      this.clearLocalValue();
    }
  };

  render() {
    const {
      value,
      tabIndex,
      disabled = false,
      timeConstraints = { minutes: { min: 0, max: 59, step: 30 } },
      dateFormat = DATE_FORMAT,
      timeFormat = getTimeFormat(),
      range = null,
      autoFocus = false,
      onBlur,
      onKeyPress,
      onFocusLeave,
      setStatic = false,

      ...props
    } = this.props;

    if (!isAmPmTimeFormat()) {
      moment.updateLocale('en', {
        week: {
          dow: 1,
          doy: 1,
        },
      });
    }

    let { inputProps = {} } = props;
    inputProps = disabled ? { ...inputProps, disabled: true } : inputProps;

    return (
      <div css={setStatic ? styles.datapickerStaticBox : styles.datapickerBox}>
        <TypedDatetime
          renderInput={({ value, onChange, ...props }) => {
            const { localValue } = this.state;
            value = localValue || value;
            return (
              <input
                {...props}
                onChange={(e) => {
                  this.setLocalValue(e.currentTarget.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.endManualEdit();
                  }
                  onKeyPress && onKeyPress(e);
                }}
                onBlur={() => {
                  this.endManualEdit();
                  onBlur && onBlur();
                  onFocusLeave && onFocusLeave();
                }}
                value={value}
                autoFocus={autoFocus}
                tabIndex={tabIndex}
                ref={this.props.forwardRef}
              />
            );
          }}
          isValidDate={(current) => {
            return range ? checkRange(current, range) : true;
          }}
          locale={'en'}
          {...props}
          utc={true}
          inputProps={inputProps}
          dateFormat={dateFormat}
          timeFormat={timeFormat}
          timeConstraints={timeConstraints}
          value={moment.utc(value)}
          onChange={this.change}
        />
      </div>
    );
  }
}

export default forwardRef<HTMLInputElement, IProps>((props, ref) => <DatetimePicker {...props} forwardRef={ref} />);
