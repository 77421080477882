/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { ReactNode, KeyboardEvent } from 'react';
import * as styles from '../multiselect/styles';
import { convert, select, NO_SELECTED_MESSAGE } from '../multiselect/MultiSelect';
import { IProps as MultiSelectProps } from '../selectWithTabs/MultiSelectWithTabs';
import PopoverBtn from '../../popover/PopoverBtn';
import MultiSelectWithTabs from './MultiSelectWithTabs';
import * as collect from '../../../../utils/collection';
import { IElementToCreate } from './MultiSelectWithTabs';
import * as inputStyles from '../../form/styles';

jsx;

interface IProps extends MultiSelectProps {
  noSelectedMsg?: string;
  inputProps?: object;
  disableReposition?: boolean;
  elementToCreate: (a: IElementToCreate) => ReactNode;
  actionCreate?: () => void;
  onBlur?: () => void;
  shoudOpenOnFocus?: boolean;
  onFocusLeave?: () => void;
  autoHeight?: boolean;
  isVisibilityHight?: boolean;
}

interface ILocateContent {
  targetRect: ClientRect;
  popoverRect: ClientRect;
  nudgedTop: number;
  nudgedLeft: number;
}

export const MultiSelectInputWithTabs = React.forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const {
    scheme,
    selected,
    items,
    onBlur,
    disableReposition,
    noSelectedMsg = NO_SELECTED_MESSAGE,
    autoHeight = false,
    isVisibilityHight = true,
  } = props;

  const selectedItemTitles = collect.extractKeyValue(select(convert(items, scheme), selected), 'title');

  const areaText = selectedItemTitles.length ? selectedItemTitles.join(', ') : noSelectedMsg;

  const handleKeyPress = (e: KeyboardEvent<HTMLElement>, cb: any) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      cb(e);
    }
  };

  const locateContent = ({ targetRect, popoverRect, nudgedTop, nudgedLeft }: ILocateContent) => {
    const pageYOffset = window.pageYOffset;
    const pageXOffset = window.pageXOffset;

    if (targetRect.top <= 0) {
      return { top: targetRect.top + pageYOffset, left: nudgedLeft + pageXOffset };
    }

    if (window.innerHeight - targetRect.top <= 0) {
      return { top: targetRect.top - popoverRect.height + pageYOffset, left: nudgedLeft + pageXOffset };
    }

    return { top: nudgedTop + pageYOffset, left: nudgedLeft + pageXOffset };
  };

  return (
    <PopoverBtn
      btn={(openPopover, isOpen) => (
        <div onClick={openPopover} css={styles.multiSelectInputWrapper(isOpen)}>
          <div css={inputStyles.formFieldBox}>
            <input
              type="text"
              ref={ref}
              value={areaText}
              onClick={(e) => {
                setTimeout(() => {
                  e.stopPropagation();
                  e.preventDefault();
                  openPopover(e);
                }, 1000);
              }}
              onFocus={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setTimeout(() => {
                  openPopover(e);
                }, 200);
              }}
              onChange={() => {}}
              onKeyDown={(e: any) => handleKeyPress(e, openPopover)}
              css={styles.multiSelectInput}
            />
          </div>
        </div>
      )}
      disableReposition={disableReposition}
      autoHeight={autoHeight}
      onClose={onBlur}
      isVisibilityHight={isVisibilityHight}
      contentLocation={({ targetRect, popoverRect, nudgedTop, nudgedLeft }) =>
        locateContent({ targetRect, popoverRect, nudgedTop, nudgedLeft })
      }
      renderContent={(closeDropdown) => (
        <div css={styles.multiSelectPopoverBox}>
          <MultiSelectWithTabs
            onApply={(e) => {
              closeDropdown(e);
              e.stopPropagation();
              e.preventDefault();
              props.onFocusLeave && props.onFocusLeave();
            }}
            onPressEscape={(e: any) => {
              closeDropdown(e);
              props.onFocusLeave && props.onFocusLeave();
            }}
            {...props}
          />
        </div>
      )}
    />
  );
});

export default MultiSelectInputWithTabs;
