/** @jsx jsx */
import { jsx } from '@emotion/core';
import { MouseEvent, forwardRef, KeyboardEvent } from 'react';

import * as styles from '../styles';

export interface IFormRowProps {
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  onBlur?: (e: any) => void;
  onFocusLeave?: () => void;
  onBlurBackup?: (e: KeyboardEvent<HTMLButtonElement>) => void;
  onKeyDown?: (e: any) => void;
}

jsx;
export const CancelAction = forwardRef<HTMLButtonElement, IFormRowProps>((props, ref) => {
  const setBlurBackup = (e: any) => {
    if (e.shiftKey && (e.key === 'Tab' || e.keyCode === 9) && props.onBlurBackup) {
      props.onBlurBackup(e);
    }
    if (!e.shiftKey && (e.key === 'Tab' || e.keyCode === 9) && props.onFocusLeave) {
      props.onFocusLeave();
    }
  };
  return (
    <button
      css={styles.forwardedCancelAction}
      onClick={props.onClick}
      ref={ref}
      tabIndex={0}
      onBlur={props.onBlur}
      onKeyDown={(e: any) => {
        setBlurBackup(e);
        props.onKeyDown && props.onKeyDown(e);
      }}
    />
  );
});
export default CancelAction;
