/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import moment from 'moment';

import { DropTarget, DropTargetMonitor } from 'react-dnd';

jsx;

const MSECONDS_OF_DAY = 86400000;

const dayTarget = {
  canDrop(props: any, monitor: any) {
    return true;
  },

  hover(props: any, monitor: any, component: any) {
    const clientOffset = monitor.getClientOffset();
    const isJustOverThisOne = monitor.isOver({ shallow: true });
    const canDrop = monitor.canDrop();
  },

  drop({ workDay, updateActivityRangeReq, copyActivityReq }: any, monitor: any, component: any) {
    if (!monitor.didDrop()) {
      return {
        moved: true,
        //hack only for grid
        afterDrop: (dropResult: any) => {
          const { gridData, rowData } = monitor.getItem();
          const deltaTime = moment.utc(workDay.from).valueOf() - moment.utc(gridData.workDay.from).valueOf();

          if (deltaTime !== 0) {
            const activityFrom = moment.utc(rowData.range.from).valueOf();
            const activityTo = moment.utc(rowData.range.to).valueOf();
            const productionFrom = moment.utc(gridData.production.loadIn.from).valueOf();
            const productionTo = moment.utc(gridData.production.loadOut.to).valueOf();

            let safeDeltaTime;

            if (deltaTime < 0) {
              const activityFromWithDelta = activityFrom + deltaTime;
              safeDeltaTime =
                activityFromWithDelta < productionFrom ? productionFrom - activityFromWithDelta + deltaTime : deltaTime;
            } else {
              const activityToWithDelta = activityTo + deltaTime;
              safeDeltaTime =
                activityToWithDelta > productionTo ? deltaTime - (activityToWithDelta - productionTo) : deltaTime;
            }

            const range = {
              from: activityFrom + safeDeltaTime,
              to: activityTo + safeDeltaTime,
            };

            const action = dropResult.dropEffect === 'copy' ? copyActivityReq : updateActivityRangeReq;
            const data = dropResult.dropEffect === 'copy' ? { ranges: [range] } : range;

            action({ ownerId: gridData.production.id, id: rowData.id, ...data });
          }
        },
      };
    }
  },
};

function collect(connect: any, monitor: any) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
  };
}

export default DropTarget('activity', dayTarget, collect);
