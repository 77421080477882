import { nsSelector } from '../../../../../../utils/redux';

const supplierAgentsSelector = nsSelector('supplierAgents');
export const supplierAgents = supplierAgentsSelector('list');
export const supplierAgentsSearchText = supplierAgentsSelector('searchText');
export const supplierAgentsPage = supplierAgentsSelector('page');
export const supplierAgentsOffset = supplierAgentsSelector('offset');
export const supplierAgentsTotal = supplierAgentsSelector('total');

export const supplierAgent = supplierAgentsSelector('item');
