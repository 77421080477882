/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import { withFormik, FormikProps, Form } from 'formik';
import * as yup from 'yup';
import { compose } from 'recompose';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { Action } from '../../../../store/types';
import * as validatinShemas from '../../../../utils/validatinShemas';

import {} from './store/selectors';

import PrimaryBtn from '../../../common/buttons/OutlineGreen';
import OutlineBtn from '../../../common/buttons/OutlineForwarded';
import form from '../../../common/form';
import ImageUpload from '../../../common/form/kits/ImageUpload';

import { Colors } from '../../../../styles/styles';

interface FormValues {
  name: string;
  file: string | null;
  agentName: string;
  phone: string;
  email: string;
}

interface OtherProps {
  createClient: Action<{}>;
  backToSelect: (e: any) => void;
  onCreateAndSelect: (value: any) => void;
  initName: string;
}

interface FormProps {
  initialEmail?: string;
}

jsx;

const CreateClientForm = ({
  errors,
  touched,
  values,
  setFieldValue,
  ...props
}: OtherProps & FormikProps<FormValues>) => {
  const { FormRow, FormField, FormError } = form;
  const firstFieldRef: any = React.createRef();
  const btnCancelRef: any = React.createRef();
  const btnSubmitRef: any = React.createRef();
  const handleLeaveFocusFromBtn = (event: KeyboardEvent) => {
    if (event.key === 'Tab' || event.keyCode === 9) {
      event.preventDefault();
      event.stopPropagation();
      firstFieldRef && firstFieldRef.current && firstFieldRef.current.focus();
    }
    if (event.shiftKey === true) {
      if (event.key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
        btnCancelRef && btnCancelRef.current && btnCancelRef.current.focus();
      }
    }
  };
  const handleLeaveFocus = (event: KeyboardEvent) => {
    if (event.shiftKey === true) {
      if (event.key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
        btnSubmitRef && btnSubmitRef.current && btnSubmitRef.current.focus();
      }
    }
  };

  return (
    <Form>
      <FormRow>
        <label htmlFor="name">Client name</label>
        <FormField
          name="name"
          placeholder="name"
          autoFocus
          innerRef={firstFieldRef}
          onKeyDown={(e: any) => handleLeaveFocus(e)}
        />
        <FormError name="name" errors={errors} touched={touched} />
      </FormRow>

      <FormRow>
        <label htmlFor="agentName">Agent name</label>
        <FormField name="agentName" placeholder="agent name" />
        <FormError name="agentName" errors={errors} touched={touched} />
      </FormRow>

      <FormRow>
        <label htmlFor="email">Email</label>
        <FormField name="email" placeholder="email" />
        <FormError name="email" errors={errors} touched={touched} />
      </FormRow>

      <FormRow>
        <label htmlFor="phone">Phone</label>
        <FormField name="phone" placeholder="+1234567890" />
        <FormError name="phone" errors={errors} touched={touched} />
        <p css={style.description}>Please add phone number in international format. Use only '+' and numbers.</p>
      </FormRow>
      <FormRow>
        <ImageUpload
          name="file"
          file={_.get(values, 'file.file')}
          onSelect={(file: any) => {
            setFieldValue('file', file);
          }}
          supportedFormats={validatinShemas.SUPPORTED_IMAGE_FORMATS}
          onSelectUnsupported={(file: any) => {
            toast.error(`Unsupported image format "${file.type}"`);
          }}
        />
        <FormError name="file" errors={errors} touched={touched} />
      </FormRow>
      <div css={style.actionsBox}>
        <OutlineBtn
          title="Back to Select"
          isWide={false}
          onClick={props.backToSelect}
          tabIndex={-1}
          ref={btnCancelRef}
        />
        <PrimaryBtn
          title="Create and Select"
          isWide={false}
          tabIndex={0}
          onKeyPress={handleLeaveFocusFromBtn}
          ref={btnSubmitRef}
        />
      </div>
    </Form>
  );
};

export const exchangeFormic = withFormik<FormProps & OtherProps, FormValues>({
  mapPropsToValues: (props) => {
    return {
      name: props.initName,
      file: null,
      agentName: '',
      phone: '',
      email: '',
    };
  },

  validationSchema: yup.object({}).shape({
    name: validatinShemas.vNameReq,
    agentName: validatinShemas.vNameReq,
    phone: validatinShemas.vPhoneReq,
    email: validatinShemas.vEmailReq,
    file: yup.mixed().test('file', validatinShemas.REQUIRE_MESSAGE, (value) => {
      return !!value;
    }),
  }),

  handleSubmit: ({ file: { file }, ...values }: any, { props }) => {
    props.createClient({ ...values, file });
    props.onCreateAndSelect(values.name);
  },
  displayName: 'CreateClientForm',
});

export default compose<OtherProps & FormikProps<FormValues>, OtherProps>(exchangeFormic)(CreateClientForm);

const style = {
  description: css`
    font-size: 12px;
    color: ${Colors.LightBlack};
    margin: 5px;
  `,
  actionsBox: css`
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
  `,
};
