import { put, call, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
// actions
import * as actions from './actions';
// utils
import { toast } from 'react-toastify';
import { getResponseMessage } from '../../../../../../../utils/response';
import routes from '../../../../../../../routes';

export default function* watchReducersAsync() {
  yield takeEvery(actions.UPDATE_CALLSHEET_REQ, updateCallSheetAsync);
}

export function* updateCallSheetAsync({ api, payload: { productionId, generalId, ...values } }: any) {
  try {
    const path = `/productions/${productionId}/generals/${generalId}`;
    yield call(api.post, path, values.data, { headers: { 'Content-Type': 'multipart/form-data' } });
    yield put(actions.updateCallSheetReqSuccess({}));
    yield put(push(routes.ProductionCallSheet.replace(':id', productionId)));
    //yield call([toast, 'info'], 'Call sheet successfully updated!');
  } catch (err) {
    const messageToShow = getResponseMessage(err);
    yield put(actions.updateCallSheetReqError(err));
    yield call([toast, 'error'], messageToShow ? messageToShow : 'Something went wrong, try again or reload the page.');
  }
}
