/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import _ from 'lodash';

import * as styles from './styles';
import { ICell } from './CellView';

export interface IProps extends ICell {
  data?: any;
  isDisable?: boolean;
  isEdit?: boolean;
  handlers?: any;
}

jsx;

export const Cell: FunctionComponent<IProps> = ({
  data = null,
  cellStyle,
  title,
  path,
  serializer = _.identity,
  render = _.identity,
  edit = null,
  isDisable = false,
  handlers = _.identity,
  width = 100,
}): ReactElement<IProps> => {
  const editRender = _.get(edit, 'render', _.identity);
  cellStyle = cellStyle || [];
  return <div css={[styles.cellEdit(width), cellStyle]}>{editRender(data, handlers, null)}</div>;
};

export default Cell;
