/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, createRef, Fragment } from 'react';
import * as styles from './styles';
import Text from '../TextWithRef';
import _ from 'lodash';
import PrimaryBtn from '../../buttons/OutlineGreen';
import OutlineBtn from '../../buttons/OutlineForwarded';

jsx;

interface IProps {
  value?: string;
  className?: string;
  onComplete?: (value: string) => void;
  backToSelect: (e: any) => void;
  onCreateAndSelect: (value: any) => void;
  onFocusLeave?: () => void;
}

export interface IState {
  localValue?: string;
}

class CreateNewUser extends Component<IProps, IState> {
  private inputRef: any;
  private cancelRef: any;
  private submitRef: any;
  constructor(props: IProps) {
    super(props);
    this.inputRef = createRef();
    this.cancelRef = createRef();
    this.submitRef = createRef();
    this.state = {
      localValue: this.props.value,
    };
  }

  keyPress = (e: any) => {
    if (e.key === 'Enter') {
      this.complete();
    }
  };

  complete = () => {
    const { onComplete = _.identity } = this.props;
    onComplete(this.getValue());
    this.setState({ localValue: '' });
  };

  getValue = () => {
    return this.state.localValue || '';
  };

  change = (localValue: any) => {
    this.setState({ localValue });
  };
  handleLeaveFocusFromBtn = (event: KeyboardEvent) => {
    if (event.key === 'Tab' || event.keyCode === 9) {
      event.preventDefault();
      event.stopPropagation();
      this.inputRef && this.inputRef.current && this.inputRef.current.focus();
    } else if (event.shiftKey === true) {
      if (event.key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
        this.cancelRef && this.cancelRef.current && this.cancelRef.current.focus();
      }
    } else if (event.key === 'Enter') {
      this.props.onFocusLeave && this.props.onFocusLeave();
      this.props.onCreateAndSelect(this.state.localValue);
      this.complete();
    }
  };

  handleLeaveFocus = (event: KeyboardEvent) => {
    if (event.shiftKey === true) {
      if (event.key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
        this.submitRef && this.submitRef.current && this.submitRef.current.focus();
      }
    }
  };

  render() {
    const { className } = this.props;

    return (
      <Fragment>
        <div css={styles.input} className={className}>
          <Text
            onKeyPress={this.keyPress}
            onChange={(e) => {
              this.change(_.get(e, 'currentTarget.value'));
            }}
            value={this.state.localValue}
            autoFocus
            ref={this.inputRef}
            onKeyDown={(e: any) => this.handleLeaveFocus(e)}
          />
          <div
            onClick={(e) => {
              this.complete();
            }}
          />
        </div>
        <div css={[styles.actionsBox, styles.addNewContainer]}>
          <OutlineBtn
            type="button"
            isSmall={true}
            title="Back to Select"
            onClick={this.props.backToSelect}
            tabIndex={-1}
            ref={this.cancelRef}
          />
          <PrimaryBtn
            type="button"
            title="Create and Select"
            tabIndex={0}
            onKeyPress={this.handleLeaveFocusFromBtn}
            onClick={(e) => {
              this.props.onFocusLeave && this.props.onFocusLeave();
              this.props.onCreateAndSelect(this.state.localValue);
              this.complete();
            }}
            ref={this.submitRef}
          />
        </div>
      </Fragment>
    );
  }
}

export default CreateNewUser;
