/** @jsx jsx */
import { Fragment, Component } from 'react';
import { jsx, css } from '@emotion/core';
// redux
import { connect } from '../../../../utils/redux';
import { categoriesList } from './store/selectors';
import { categoriesLoadRequest, categoryDeleteRequest } from './store/actions';
// types
import { Action } from '../../../../store/types';
import { CategoriesListState, Category } from './store/types';
// components
import { Waypoint } from 'react-waypoint';
import { Link } from 'react-router-dom';
import Loader from '../../../common/loader/Loader';
import GRID from '../../../common/grid';
import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
// utils
import _ from 'lodash';
import CheckAcl from '../CheckAcl';
import { push } from 'connected-react-router';
// other
import { ListStatuses } from '../../../../utils/store/state/state';
import Routes from '../../../../routes';
import withAcl, { IWrappedProps } from '../withAcl';

const { Actions, DeleteAction, EditAction, ToolBar, Footer, Grid, GridBox } = GRID;

interface IProps extends IWrappedProps {
  categoriesList: CategoriesListState;
  categoriesLoadRequest: Action<{}>;
  categoryDeleteRequest: Action<{}>;
  push: Action<{}>;
}

interface IState {}

jsx;
class CategoriesList extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      editItem: null,
      isOpen: false,
    };
  }

  componentDidMount(): void {
    this.props.categoriesLoadRequest({ page: 0, size: 20 });
  }

  fetchMoreCategories = () => {
    const { categoriesLoadRequest, categoriesList } = this.props;
    const { page } = categoriesList.params;
    categoriesLoadRequest({ page: page + 1, size: 20 });
  };

  onWaypointEnter = () => {
    const { categoriesList } = this.props;
    if (categoriesList.status === ListStatuses.Request) return;
    this.fetchMoreCategories();
  };

  deleteCategory = (item: Category) => {
    const { categoryDeleteRequest } = this.props;
    categoryDeleteRequest(item.id);
  };

  render() {
    const { categoriesList, push, test } = this.props;
    const isLoading = categoriesList.status === ListStatuses.Request;
    const total = _.get(categoriesList, 'meta.total', 0);
    const hasMoreCategories = total > _.get(categoriesList.data, 'length', 0);

    const schema = [
      { 
        title: 'Name',
        path: 'name',
        width: 25,
        cellStyle: { maxWidth: '25%' },
      }, // name
      {
        title: 'Types',
        width: 50,
        cellStyle: { maxWidth: '50%', whiteSpace: 'normal' },
        serializer: (item: any) => {
          return Object.values(item.types)
            .map((type: any) => type.name)
            .sort()
            .join(', ');
        },
      },
    ];

    const actions = ['U', 'D'].filter((action: string) => test('category', action));
    const actionsSchema = actions.length
      ? [
          {
            title: 'Actions',
            width: 7,
            render: (item: any) => {
              return (
                <Actions>
                  {actions.includes('U') && (
                    <Link to={Routes.CategoriesEdit.replace(':id', String(item.id))}>
                      <EditAction />
                    </Link>
                  )}

                  {actions.includes('D') && (
                    <DeleteAction
                      onConfirm={(e) => {
                        this.deleteCategory(item);
                      }}
                    />
                  )}
                </Actions>
              );
            },
          },
        ]
      : [];

    return (
      <Fragment>
        <GridBox css={style.gridBox}>
          <ToolBar
            title="Machinery categories"
            actionsRight={
              <CheckAcl resources="category" actions="C">
                <Link to={Routes.CategoriesCreate}>
                  <PrimaryBtn title="Create category" />
                </Link>
              </CheckAcl>
            }
          />
          <Grid
            onRowDoubleClick={(e: any, { id }) => {
              if (test('category', 'U')) {
                push(Routes.CategoriesEdit.replace(':id', id));
              }
            }}
            shema={[...schema, ...actionsSchema]}
            data={categoriesList.data || []}
          />
          <Footer>
            <Loader css={style.loader} isLoading={isLoading} />
          </Footer>
        </GridBox>

        {hasMoreCategories && !isLoading && <Waypoint onEnter={this.onWaypointEnter} />}
      </Fragment>
    );
  }
}

export default connect(
  {
    categoriesList,
  },
  {
    categoriesLoadRequest,
    categoryDeleteRequest,
    push,
  }
)(withAcl(CategoriesList));

const style = {
  loader: css`
    margin: 5px 0;
  `,
  gridBox: css`
    margin-bottom: 100px;
  `,
};
