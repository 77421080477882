/** @jsx jsx */
import { jsx, css } from '@emotion/core';
// types
import { IProduction } from '../store/types';
import { Action } from '../../../../../store/types';
// components
import modal from '../../../../common/modal';
import PrimaryBtn from '../../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../../common/buttons/OutlineBtn';

const { Modal, ModalHeader, ModalFooter, ModalBody, ModalActions } = modal;
interface OtherProps {
  isOpen: boolean;
  onClose: () => void;
  activities: Number[];
  production: IProduction;
  onDeleteClick: Action<{}>;
}

jsx;
const ActivitiesDeleteModal = ({ isOpen, onClose, production, activities, onDeleteClick }: OtherProps) => {
  const onCancelClick = () => {
    onClose();
  };

  return (
    <div>
      <Modal isOpen={isOpen}>
        <ModalHeader>{`Delete activities (${activities.length})`}</ModalHeader>
        <ModalBody>
          <div css={style.confirmationBox}>
            <p css={style.massage}>Are you sure you want to delete selected activities?</p>
          </div>
        </ModalBody>

        <ModalFooter>
          <ModalActions css={style.modalActions}>
            <OutlineBtn type="button" onClick={onCancelClick} title="Cancel" />
            <PrimaryBtn
              type="button"
              title="Delete"
              style={{ marginRight: 0 }}
              onClick={() =>
                onDeleteClick({
                  ownerId: production.id,
                  ids: activities,
                })
              }
            />
          </ModalActions>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ActivitiesDeleteModal;

const style = {
  modal: css`
    width: 500px;
  `,
  modalActions: css`
    text-align: right;
  `,
  massage: css`
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.38;
    text-align: center;
  `,
  confirmationBox: css`
    padding: 20px;
  `,
};
