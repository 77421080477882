/** @jsx jsx */
import { jsx } from '@emotion/core';
import { InputHTMLAttributes, KeyboardEvent, forwardRef } from 'react';
import * as styles from './styles';

jsx;

interface IProps extends InputHTMLAttributes<{}> {
  onChange: any;
}
export const Checkbox = forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const selectItem = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      props.onChange();
    }
  };
  return (
    <input
      type="checkbox"
      css={styles.checkboxInput}
      {...props}
      onChange={props.onChange}
      onKeyPress={(e) => selectItem(e)}
      ref={ref}
    />
  );
});

export default Checkbox;
