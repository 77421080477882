/** @jsx jsx */
import { jsx, css, keyframes, SerializedStyles } from '@emotion/core';

import * as styles from '../../../../../styles/styles';

import burgerMenu from './images/burgerMenu.svg';

export const button = css({
  padding: '0px',
  margin: '0px',
  border: '0px',
  marginTop: '15px',
  height: '17px',
  width: '26px',
  cursor: 'pointer',
  ':hover': {
    opacity: 0.5,
  },
  ':focus': {
    outline: 0,
  },
});

export const burgerBlack = [
  button,
  css({
    '& svg': css({
      fill: styles.Colors.RegularBlack,
    }),
    '& rect': css({
      fill: styles.Colors.White,
    }),
  }),
];

export const burgerWhite = [
  button,
  css({
    '& svg': css({
      fill: styles.Colors.White,
    }),
    '& rect': css({
      fill: styles.Colors.AltGreen,
    }),
  }),
];

export const getBurger = (thema?: string): SerializedStyles | SerializedStyles[] => {
  return thema === 'white' ? burgerWhite : burgerBlack;
};

export const fixPosition = css({
  position: 'fixed',
  left: '-300px',
  top: 0,
});

export const menuOwerlay = [
  fixPosition,
  css({
    width: '100%',
    left: 0,
    height: '100vh',
    opacity: 0,
    backgroundColor: 'black',
    zIndex: 1000,
  }),
];

export const menuBox = [
  fixPosition,
  css({
    left: '-300px',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '280px',
    backgroundColor: styles.Colors.Green,
    zIndex: 1001,
  }),
];

export const openMenuAnimation = keyframes({
  to: {
    left: 0,
  },
});

export const closeMenuAnimation = keyframes({
  from: {
    left: 0,
  },
  to: {
    left: '-300px',
  },
});

export const openMenuBox = [
  menuBox,
  css({
    animation: `200ms linear 0ms 1 both ${openMenuAnimation}`,
  }),
];

export const closeMenuBox = [
  menuBox,
  css({
    animation: `200ms linear 0ms 1 both ${closeMenuAnimation}`,
    //animationDirection: "reverse"
  }),
];

export const menuHeader = css({
  backgroundColor: styles.Colors.AltGreen,
  boxSizing: 'border-box',
  padding: '10px 30px 0 0',
  minHeight: '70px',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  boxShadow: '0px 0px 7px rgba(34, 55, 4, 0.2)',
});

export const navBox = [
  styles.H3,
  css({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    padding: '0 0 50px 50px',
    '& ul': css({
      padding: '0',
      margin: '0',
    }),
    '& li': css({
      listStyleType: 'none',
      paddingTop: '30px',
    }),
    '& li a': css({
      textDecoration: 'none',
      color: styles.Colors.White,
      ': hover': css({
        opacity: 0.5,
      }),
    }),
    '& li a.active': css({
      color: styles.Colors.White,
      opacity: 0.5,
    }),
  }),
];

export const topNav = css({});

export const bottomNav = css({
  //maxHeight: "300px"
});
