/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import * as styles from '../../../styles/styles';

import editIcon from './images/edit.svg';
import deleteIcon from './images/delete.svg';
import vectorL from './images/vectorL.svg';
import vectorR from './images/vectorR.svg';
import spread from './images/spread.svg';
import applyIcon from './images/apply.svg';
import cancelIcon from './images/cancel.svg';
import viewIcon from './images/view.svg';

export const actionsBox = css({
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  display: 'flex',
  //justifyContent: 'space-around',
  alignItems: 'center',
  '& a': {
    maxHeight: '20px',
  },
});

export const btnAction = css({
  height: '20px',
  width: '20px',
  border: '0px',
  cursor: 'pointer',
  ':focus': {
    outline: 0,
  },
  backgroundRepeat: 'no-repeat',
  backgroundColor: 'inherit',
  backgroundPosition: 'center',
});

export const editAction = [
  btnAction,
  css({
    backgroundImage: `url(${editIcon})`,
    marginRight: '10px',
  }),
];
export const viewAction = [
  btnAction,
  css({
    backgroundImage: `url(${viewIcon})`,
    backgroundSize: '94%',
    marginRight: '16px',
  }),
];

export const deleteAction = [
  btnAction,
  css({
    backgroundImage: `url(${deleteIcon})`,
  }),
];

export const cancelAction = [
  btnAction,
  css({
    backgroundImage: `url(${cancelIcon})`,
  }),
];
export const forwardedCancelAction = [
  btnAction,
  css({
    backgroundImage: `url(${cancelIcon})`,
    '& :active, :focus, :hover': {
      filter: 'drop-shadow(1px 2px 1px #49661c)',
      transform: 'scale(1.5)',
    },
  }),
];

export const applyAction = [
  btnAction,
  css({
    backgroundImage: `url(${applyIcon})`,
  }),
];
export const forwardedApplyAction = [
  btnAction,
  css({
    backgroundImage: `url(${applyIcon})`,
    '& :active, :focus': {
      filter: 'drop-shadow(1px 2px 1px #49661c)',
      transform: 'scale(1.5)',
    },
  }),
];

export const actionListBtn = [
  btnAction,
  css({
    backgroundImage: `url(${spread})`,
    backgroundPosition: 'center',
    margin: 'auto',
    marginLeft: '10px',
  }),
];

export const gridBox = css({
  backgroundColor: styles.Colors.White,
  boxShadow: '2px 2px 7px rgba(150, 150, 150, 0.25)',
});

//deprecated
export const toolbar = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: '0 30px',
  height: '65px',
  borderBottom: `1px solid ${styles.Colors.LightGreyMainBG}`,
});

//deprecated
export const title = [
  styles.H2,
  css({
    lineHeight: '35px',
  }),
];

export const toolbarBox = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: '0 30px',
  height: '65px',
  borderBottom: `1px solid ${styles.Colors.LightGreyMainBG}`,
});

export const toolbarTitleBox = [
  styles.H2,
  css({
    lineHeight: '35px',
    whiteSpace: 'nowrap',
  }),
];

export const toolbarActionsBox = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginLeft: '50px',
});
export const toolbarActionsLeft = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  marginRight: '30px',
});
export const toolbarActionsRight = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
});

export const row = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  borderBottom: `1px solid ${styles.Colors.LightGreyMainBG}`,
});

export const rowEdit = [
  row,
  css({
    backgroundColor: styles.Colors.LightGrey,
  }),
];
export const rowEmphasis = [
  row,
  css({
    backgroundColor: styles.Colors.Background,
  }),
];

export const rowDisable = css({
  opacity: 0.3,
});

export const cell = (width: number) => [
  styles.MainText,
  css({
    //whiteSpace: 'nowrap',
    overflow: 'hidden',
    minWidth: `${width}%`,
    boxSizing: 'border-box',
    padding: '10px 0px 10px 30px',
  }),
];

export const cellPrint = css({
  height: '100%',
  verticalAlign: 'top',
});

export const cellEdit = (width: number) => [
  styles.MainText,
  css({
    minWidth: `${width}%`,
    boxSizing: 'border-box',
    padding: '5px 5px',
    height: '100%',
    '& input': {
      height: '30px',
    },
  }),
];

export const headCell = (width: number) => [
  styles.H3,
  css({
    boxSizing: 'border-box',
    textAlign: 'left',
    padding: '0px 30px',
    lineHeight: '55px',
    whiteSpace: 'nowrap',
    height: '55px',
    minWidth: `${width}%`,
  }),
];
export const headSticky = css({
  position: 'sticky',
  top: '64px',
  backgroundColor: '#fff',
  zIndex: 1,
});

export const table = css({
  borderCollapse: 'collapse',
  border: 'none',
  width: '100%',
  '& .grid-row': {
    width: '100%',
  },
});

export const tr = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  overflow: 'visible',
  height: '100%',
  padding: 0,
  margin: 0,
});

export const td = css({
  width: '100%',
});

export const footer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  padding: '0 30px',
  minHeight: '55px',
});

const list = css({
  listStyleType: 'none',
  display: 'flex',
  alignItems: 'center',
  padding: '0',
});

const listItem = css({
  margin: '0 10px',
  cursor: 'pointer',
  borderRadius: '200px',
  '& a': styles.MainText,
  '& a:active, a:focus': {
    outline: 0,
    border: 'none',
  },
});

export const circleBtn = css({
  borderRadius: '200px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
});

export const navBtn = css({
  width: '37px',
  height: '37px',
  overflow: 'hidden',
  textIndent: '-99px',
  '& a': {
    padding: '200px',
  },
});

export const prev = [
  circleBtn,
  navBtn,
  css({
    backgroundColor: styles.Colors.Grey,
    backgroundImage: `url(${vectorL})`,
  }),
];

export const next = [
  circleBtn,
  navBtn,
  css({
    backgroundColor: styles.Colors.Grey,
    backgroundImage: `url(${vectorR})`,
  }),
];

export const selected = [
  circleBtn,
  css({
    minWidth: '29px',
    height: '29px',
    textAlign: 'center',
    lineHeight: '28px',
    backgroundColor: styles.Colors.Green,
    '& a': {
      color: styles.Colors.White,
    },
  }),
];

export const paginator = css({
  '& ul': list,
  '& li': listItem,
  '& .selected': selected,
  '& .previous': prev,
  '& .next': next,
});

export const xxx = css({});
