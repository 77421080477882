/** @jsx jsx */
import { Fragment, Component } from 'react';
import { jsx, css } from '@emotion/core';
import compose from 'recompose/compose';

// redux
import { connect } from '../../../../utils/redux';
import {
  suppliersAddLoading,
  suppliersList,
  suppliersLoading,
  suppliersListMeta,
  suppliersListParams,
} from './store/selectors';
import {
  supplierFunctionsLoadRequest,
  supplierFunctionsAddRequest,
  supplierFunctionsUpdateRequest,
  supplierFunctionsDeleteRequest,
} from './store/actions';
// types
import { Action } from '../../../../store/types';
import { SupplierFunction, SupplierFunctions } from './store/types';
// components
import { Waypoint } from 'react-waypoint';
import ReactModal from 'react-modal';
import Loader from '../../../common/loader/Loader';
import GRID from '../../../common/grid';
import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import FunctionForm from './FunctionForm';
import withAcl, { IWrappedProps } from '../withAcl';
import CheckAcl from '../CheckAcl';
import withVisualContext from '../../../../contexts/withVisualContext';
import { IVisualContext } from '../../../../contexts/VisualContext';

// utils
import _ from 'lodash';

interface IProps extends IWrappedProps {
  supplierFunctionsLoadRequest: Action<{}>;
  supplierFunctionsAddRequest: Action<{}>;
  supplierFunctionsDeleteRequest: Action<{}>;
  supplierFunctionsUpdateRequest: Action<{}>;
  suppliersList: SupplierFunctions;
  suppliersListMeta: { total: number; size: number };
  suppliersListParams: { page: number; size: number };
  suppliersLoading: boolean;
  suppliersAddLoading: boolean;
  suppliersDeleteLoading: boolean;
  suppliersUpdateLoading: boolean;
  visual: IVisualContext;
}

interface IState {
  isOpen: boolean;
  editItem: any;
}

ReactModal.setAppElement('#root');

jsx;
class SupplierFunctionsList extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      editItem: null,
      isOpen: false,
    };
  }

  componentDidMount(): void {
    this.props.supplierFunctionsLoadRequest({ page: 0, size: 20 });
  }

  fetchMoreSupplierFunctions = () => {
    const { supplierFunctionsLoadRequest, suppliersListParams, suppliersLoading } = this.props;
    const { page } = suppliersListParams;
    supplierFunctionsLoadRequest({ page: page + 1, size: 20 });
  };

  onWaypointEnter = () => {
    const { suppliersLoading } = this.props;
    if (suppliersLoading) return;
    this.fetchMoreSupplierFunctions();
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };

  closeModal = () => {
    this.setState({ isOpen: false });
    this.clearEditItem();
  };

  delete = (item: SupplierFunction) => {
    this.props.supplierFunctionsDeleteRequest(item);
  };

  create = () => {
    const { test } = this.props;
    if (test('supplierFunction', 'C')) {
      this.openModal();
    }
  };

  clearEditItem = () => {
    this.setState({ editItem: null });
  };

  edit = (item: any) => {
    const { test } = this.props;
    if (test('supplierFunction', 'U')) {
      this.setState({ editItem: item });
      this.openModal();
    }
  };

  onSave = (values: SupplierFunction) => {
    const { editItem } = this.state;
    const { supplierFunctionsAddRequest, supplierFunctionsUpdateRequest } = this.props;
    if (editItem) {
      supplierFunctionsUpdateRequest({ values, item: editItem });
    } else {
      supplierFunctionsAddRequest({ values });
    }
    this.closeModal();
  };

  render() {
    const { suppliersList, suppliersLoading, suppliersListMeta, test, visual } = this.props;
    const { isOpen, editItem } = this.state;
    const { Actions, DeleteAction, EditAction, ToolBar, Footer, Grid, GridBox } = GRID;

    const total = _.get(suppliersListMeta, 'total', 0);
    const hasMoreFunctions = total > suppliersList.length;

    const nameCol = { title: 'Name', path: 'name' };
    const actionCol = {
      title: 'Actions',
      width: 7,
      render: (item: any) => {
        return (
          <Actions>
            <CheckAcl resources="supplierFunction" actions="U">
              <EditAction
                onClick={(e) => {
                  this.edit(item);
                }}
              />
            </CheckAcl>
            <CheckAcl resources="supplierFunction" actions="D">
              <DeleteAction
                onConfirm={(e) => {
                  this.delete(item);
                }}
              />
            </CheckAcl>
          </Actions>
        );
      },
    };

    const gridSchema = test('supplierFunction', ['U', 'D']) ? [nameCol, actionCol] : [nameCol];

    return (
      <Fragment>
        <GridBox css={style.gridBox}>
          <ToolBar
            title={`Supplier ${visual.labels.functions} `}
            actionsRight={
              <CheckAcl resources="supplierFunction" actions="C">
                <PrimaryBtn onClick={this.create} title={`Create  ${visual.labels.function}`} />
              </CheckAcl>
            }
          />

          <Grid
            onRowDoubleClick={(e: any, item: any) => {
              this.edit(item);
            }}
            shema={gridSchema}
            data={suppliersList}
          />
          <Footer>
            <Loader css={style.loader} isLoading={suppliersLoading} />
          </Footer>
        </GridBox>

        {hasMoreFunctions && !suppliersLoading && <Waypoint onEnter={this.onWaypointEnter} />}
        {isOpen && (
          <FunctionForm
            isOpen={isOpen}
            onSave={this.onSave}
            onClose={this.closeModal}
            item={editItem}
            functionName={visual.labels.function}
          />
        )}
      </Fragment>
    );
  }
}

export default compose<any, {}>(
  connect(
    {
      suppliersList,
      suppliersListMeta,
      suppliersListParams,
      suppliersLoading,
      suppliersAddLoading,
    },
    {
      supplierFunctionsLoadRequest,
      supplierFunctionsAddRequest,
      supplierFunctionsUpdateRequest,
      supplierFunctionsDeleteRequest,
    }
  ),
  withAcl,
  withVisualContext
)(SupplierFunctionsList);

const style = {
  loader: css`
    margin: 5px 0;
  `,
  footer: css`
    min-height: 50px;
    height: auto;
  `,
  gridBox: css`
    margin-bottom: 100px;
  `,
};
