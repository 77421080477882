/** @jsx jsx */
import { jsx, SerializedStyles } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import ReactModal, { Styles } from 'react-modal';

import * as styles from './styles';

export interface IProps {
  className?: string;
}

jsx;

export const Header: FunctionComponent<IProps> = ({ children, ...props }): ReactElement<IProps> => {
  return (
    <div css={styles.actionsBox} {...props}>
      {children}
    </div>
  );
};

export default Header;
