/** @jsx jsx */
import { jsx } from '@emotion/core';
import { MouseEvent, Fragment, Component, ReactElement } from 'react';

import Text from '../../form/Text';
import ToolbarActionBox from './ToolbarActionBox';

import * as styles from './styles';
import * as React from 'react';
import _ from 'lodash';

export interface IProps {
  onChange: (a: string) => void;
  title?: string;
  text?: string;
  delay?: number;
}

export interface IState {
  text: string | null;
}

jsx;

class Search extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      text: null,
    };
  }

  delayTimer: any = null;

  clearTimer = () => {
    if (this.delayTimer) {
      clearTimeout(this.delayTimer);
    }
  };

  static getDerivedStateFromProps({ text = '' }: IProps, prevState: IState) {
    return prevState.text === null ? { text } : null;
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    const { delay = 500, onChange } = this.props;
    const { text } = this.state;
    this.clearTimer();

    if (_.isString(text) && prevState.text !== text) {
      this.delayTimer = setTimeout(() => {
        onChange(text);
      }, delay);
    }
  }

  componentWillUnmount(): void {
    this.clearTimer();
  }

  change = (e: any) => {
    const targetValue = e.currentTarget.value;
    const { text } = this.state;
    if (targetValue !== text) {
      this.setState({ text: targetValue });
    }
  };

  render() {
    const { title = 'Search' } = this.props;
    const { text } = this.state;
    return (
      <ToolbarActionBox title={title}>
        <Text
          name="search"
          onChange={(e) => {
            this.change(e);
          }}
          value={text || ''}
        />
      </ToolbarActionBox>
    );
  }
}

export default Search;
