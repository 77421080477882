/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, Children } from 'react';
import { Form as FormicForm } from 'formik';
import * as styles from './styles';

export interface IProps {}

jsx;

export const Form: FunctionComponent<IProps> = ({ children }): ReactElement<IProps> => {
  return (
    <div css={styles.formBox}>
      <FormicForm>{children}</FormicForm>
    </div>
  );
};

export default Form;
