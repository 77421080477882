import ModalActions from './ModalActions';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import Modal from './Modal';
import * as styles from './styles';

export default {
  ModalActions,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  styles,
};
