import { nsSelector } from '../../../../../../utils/redux';

const clientAgentsSelector = nsSelector('clientAgents');
export const clientAgents = clientAgentsSelector('list');
export const clientAgentsSearchText = clientAgentsSelector('searchText');
export const clientAgentsPage = clientAgentsSelector('page');
export const clientAgentsOffset = clientAgentsSelector('offset');
export const clientAgentsTotal = clientAgentsSelector('total');

export const clientAgent = clientAgentsSelector('item');
