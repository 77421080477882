/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import * as styles from './styles';

export interface IProps {
  overflowShown?: 'auto' | 'visible';
}

jsx;
export const ModalBody: FunctionComponent<IProps> = ({ overflowShown = 'auto', children }): ReactElement<IProps> => {
  return overflowShown === 'auto' ? (
    <div css={styles.body}>{children}</div>
  ) : (
    <div css={styles.bodyVisibleOverflowY}>{children}</div>
  );
};

export default ModalBody;
