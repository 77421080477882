/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import * as styles from './styles';

import removeBtn from './images/removeBtn.svg';

jsx;

interface IProps {
  onRemove: () => void;
}

export const FormField: FunctionComponent<IProps> = ({ children, onRemove }) => {
  return (
    <div css={styles.removeBox}>
      <div css={styles.removeBoxBtn} onClick={onRemove} />
      {children}
    </div>
  );
};

export default FormField;
