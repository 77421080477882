/** @jsx jsx */
import { jsx } from '@emotion/core';

import _ from 'lodash';
import { Component, ReactNode, forwardRef, KeyboardEvent, createRef } from 'react';

import * as styles from './styles';
import * as object from '../../../../utils/object';
import * as collection from '../../../../utils/collection';
import TabRow from '../TabRow';
import SelectOption from '../select/SelectOption';
import { IListForSelect } from './ListForSelect';

export interface IScheme {
  id: string;
  title: string;
}
export interface IElementToCreate {
  init: string;
  backToSelect: (e: any) => void;
  onCreateAndSelect: (name: string) => void;
}

export interface IProps {
  selected: string | number | null;
  elementToCreate?: (a: IElementToCreate) => ReactNode;
  listToSelect?: (a: IListForSelect) => void;
  actionCreate?: () => void;
  search: string;
  items: any[];
  resultScheme: IScheme;
  scheme?: any;
  itemsToDisplay: any[];
  onChange?: (a: number[] | number) => void;
  closeDropdown?: any;
  handleSelect?: any;
  onResetForm?: () => void;
  noSelectedMsg?: string;
  selectAllBtn?: boolean;
  className?: string;
  onApply?: (e: any) => void;
  onPressEscape?: (e: any) => void;
  forwardRef?: any;
}

export interface IState {
  tab: number;
  inputSearch: string;
}

export const NO_SELECTED_MESSAGE = 'No selected items';

export const convert = (items: any[], scheme: IScheme) => {
  return items.map((item) => object.buildData(item, scheme));
};

export const select = (convertedItems: any[], selected: number[]) => {
  return collection.selectBy(convertedItems, { id: selected });
};

export const exclude = (convertedItems: any[], selected: number[]) => {
  return collection.excludeBy(convertedItems, { id: selected });
};

jsx;
class SelectWithTabs extends Component<IProps, IState> {
  private optionsRef: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      tab: 0,
      inputSearch: this.props.search,
    };
    this.optionsRef = createRef();
  }

  goToAddNew = (e: any) => {
    e.stopPropagation();
    this.setState({ tab: 1 });
  };
  goToSelect = (e: any) => {
    e.stopPropagation();
    this.setState({ tab: 0 });
  };
  handleAddNew = (e: KeyboardEvent) => {
    e.preventDefault();
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.setState({ tab: 1 });
    }
  };

  handleKeys = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      !!this.props.onPressEscape && this.props.onPressEscape(event);
    }
    if (event.key === 'ArrowRight' || event.keyCode === 39) {
      event.preventDefault();
      this.goToAddNew(event);
    }
    if (event.key === 'ArrowLeft' || event.keyCode === 37) {
      event.preventDefault();
      this.goToSelect(event);
    }
  };
  applyAddingNew = (name: string) => {
    this.setState({
      tab: 0,
      inputSearch: name,
    });
    setTimeout(() => {
      const items = this.searchFilter(this.props.itemsToDisplay, this.state.inputSearch);
      if (items.length === 1) {
        if (this.props.forwardRef && this.props.forwardRef.current) {
          this.props.forwardRef.current.click();
        }
      }
    }, 1600);
  };

  handleInputChange = (e: any) => {
    this.setState({ inputSearch: e.currentTarget.value });
  };
  changeFocusToOptions = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.keyCode === 13 || event.key === 'ArrowDown' || event.keyCode === 40) {
      if (this.props.forwardRef && this.props.forwardRef.current) {
        this.props.forwardRef.current.focus();
      }
    }
    if (event.key === 'Escape' || event.keyCode === 27) {
      !!this.props.onPressEscape && this.props.onPressEscape(event);
    }
  };
  handleShortcads = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.shiftKey === true) {
      if (event.key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
        if (this.props.forwardRef && this.props.forwardRef.current) {
          this.props.forwardRef.current.focus();
        }
      }
    }
  };
  searchFilter = (items: any[], text: string) => {
    return items.filter((item) => item.name.toLowerCase().includes(text.toLowerCase()));
  };

  render() {
    const { elementToCreate, search, itemsToDisplay, selected, resultScheme, handleSelect, onPressEscape } = this.props;
    const { tab, inputSearch } = this.state;

    const searchedItems = inputSearch === '' ? itemsToDisplay : this.searchFilter(itemsToDisplay, inputSearch);

    const noMatchesInSearch = searchedItems.length < 1;

    return (
      <div css={styles.multiSelectBox} onKeyDown={(e: any) => this.handleKeys(e)}>
        <div>
          <TabRow>
            <div onClick={this.goToSelect} css={styles.tabItem(tab === 0)} ref={this.optionsRef} tabIndex={-1}>
              Select
            </div>
            <div onClick={this.goToAddNew} css={styles.tabItem(tab === 1)}>
              Add new
            </div>
          </TabRow>
          {tab === 0 && (
            <div>
              <input
                type="text"
                tabIndex={0}
                value={inputSearch}
                autoFocus
                onChange={(e) => this.handleInputChange(e)}
                css={styles.searchText}
                onKeyDown={(e: any) => {
                  this.changeFocusToOptions(e);
                  this.handleShortcads(e);
                }}
              />
              {noMatchesInSearch ? (
                <p
                  css={styles.addNewMessage}
                  onClick={this.goToAddNew}
                  tabIndex={0}
                  onKeyDown={(e: any) => this.handleAddNew(e)}
                >
                  No results. You can add <span css={styles.searchQuery}>{`${inputSearch}`}</span> instead
                </p>
              ) : (
                <div>
                  <SelectOption
                    items={searchedItems}
                    selected={selected}
                    resultScheme={resultScheme}
                    select={handleSelect}
                    onPressEscape={onPressEscape}
                    ref={this.props.forwardRef}
                  />
                </div>
              )}
            </div>
          )}
          {tab === 1 && (
            <div css={styles.addNewContainer}>
              {!!elementToCreate &&
                elementToCreate({
                  init: this.state.inputSearch,
                  backToSelect: this.goToSelect,
                  onCreateAndSelect: (name) => this.applyAddingNew(name),
                })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default forwardRef<HTMLDivElement, IProps>((props, ref) => <SelectWithTabs {...props} forwardRef={ref} />);
