/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import * as styles from './styles';
import BBStandLogo from '../../../images/BBStandLogo.svg';
import TigLogo from '../../../images/tig-logo-white.svg';
import { IVisualContext } from '../../../contexts/VisualContext';
import withVisualContext from '../../../contexts/withVisualContext';

interface IProps {
  visual: IVisualContext;
}

jsx;

const Box: FunctionComponent<IProps> = ({ children, ...props }): ReactElement<IProps> => {
  return (
    <div css={props.visual.isCopy ? styles.bgTigSports : styles.bg}>
      <div css={styles.LoginBox}>
        <img src={props.visual.isCopy ? TigLogo : BBStandLogo} alt="" />
        {children}
      </div>
    </div>
  );
};

export default withVisualContext(Box);
