/** @jsx jsx */
import { jsx } from '@emotion/core';
import { compose, lifecycle } from 'recompose';
import { RouteComponentProps } from 'react-router';

import { connect } from '../../../../utils/redux';

import { getProductionReq, getSubproductionReq } from './store/actions';
import { Action } from '../../../../store/types';
import { production, subproduction } from './store/selectors';

import CreateSubForm from './CreateSubForm';

import * as styles from './styles';
import { IProduction } from './store/types';

interface FormValues extends Partial<IProduction> {}

interface MatchParams {
  production_id?: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  getProductionReq: Action<{}>;
  production: IProduction;
}

jsx;

const withLifecycle = lifecycle<IProps, {}>({
  componentDidMount() {
    const { production_id } = this.props.match.params;
    this.props.getProductionReq(production_id);
  },
});

const Edit = (props: IProps) => {
  const { production } = props;
  const form = production ? <CreateSubForm {...props} /> : <div>Loading...</div>;
  return <div css={styles.formBox}>{form}</div>;
};

interface FormProps {
  initialEmail?: string;
}

export default compose<IProps, {}>(
  connect(
    { production, subproduction },
    { getProductionReq, getSubproductionReq }
  ),
  withLifecycle
)(Edit);
