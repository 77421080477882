/** @jsx jsx */
import React, { Component, Fragment } from 'react';
import { jsx, css } from '@emotion/core';
// components
import form from '../../../common/form';
import BaseBtn from '../../buttons/BaseBtn';
import DateRangeWithName, { IDataRangeWithName, IDateRangeWithNameProps } from './DateRangeWithName';
import Checkbox from '../InputCheckbox';
// other
import removeIcon from '../../../../images/icons/remove.svg';

export interface IProps
  extends Pick<IDateRangeWithNameProps, 'nameLabel' | 'fromLabel' | 'toLabel' | 'disabledFrom' | 'disabledTo'> {
  value: IDataRangeWithName[];
  onChange: (a: IDataRangeWithName[]) => void;
  checkboxForHiding?: string;
  onHideShow?: (a: any) => void;
  hidedShow?: boolean;
  btn?: any;
  error: any;
  shoudSetDefaultValues?: boolean;
}

export interface IState {
  focused: boolean;
}

const EMPTY_DATE_RANGE_WITH_NAME: IDataRangeWithName = { name: '', range: { from: '', to: '' } };

jsx;
class DateRangeListWithName extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      focused: false,
    };
  }
  add = (e: any) => {
    const { onChange, value } = this.props;
    onChange([...value, EMPTY_DATE_RANGE_WITH_NAME]);
    this.setState({ focused: true });
  };

  change = (index: number) => (newItem: IDataRangeWithName) => {
    const { onChange, value } = this.props;
    onChange(value.map((item, key) => (key === index ? newItem : item)));
  };

  deleteRow = (index: number) => () => {
    const { onChange, value } = this.props;
    onChange(value.filter((item, key) => key !== index));
  };

  render() {
    const { FormRow } = form;
    const {
      value: values,
      nameLabel,
      fromLabel,
      toLabel,
      btn,
      error,
      disabledFrom,
      disabledTo,
      checkboxForHiding,
      shoudSetDefaultValues = false,
      hidedShow = false,
    } = this.props;
    const { focused } = this.state;
    const AddRowButton = btn
      ? () =>
          React.cloneElement(btn, {
            type: 'button',
            onClick: this.add,
          })
      : () => <Fragment />;

    return (
      <Fragment>
        {values.map((rangeValue, index) => {
          return (
            <FormRow key={index} css={style.formRow}>
              <DateRangeWithName
                value={rangeValue}
                onChange={this.change(index)}
                nameLabel={nameLabel}
                fromLabel={fromLabel}
                toLabel={toLabel}
                shoudSetDefaultValues={shoudSetDefaultValues}
                disabledFrom={disabledFrom}
                disabledTo={disabledTo}
                isFocused={index === values.length - 1 && focused ? true : false}
              />
              {values.length > 1 && (
                <BaseBtn css={style.removeBtn} onClick={this.deleteRow(index)} type="button" tabIndex={0} />
              )}
            </FormRow>
          );
        })}
        {error}
        <FormRow>
          <AddRowButton />

          {checkboxForHiding && (
            <label css={style.checkboxBtn}>
              <Checkbox
                name="hideShow"
                checked={hidedShow}
                onChange={(e: any) => {
                  this.props.onHideShow && this.props.onHideShow(e);
                }}
              />{' '}
              {checkboxForHiding}
            </label>
          )}
        </FormRow>
      </Fragment>
    );
  }
}

export default DateRangeListWithName;

const style = {
  formRow: css`
    position: relative;
    display: flex;
  `,
  removeBtn: css`
    width: 32px;
    height: 32px;
    background: no-repeat url(${removeIcon}) center;
    border: none;
    outline: none;
    cursor: pointer;
    position: absolute;
    left: 100%;
    bottom: 0;
    transform: translate(-50%, -5px);
    &:focus {
      filter: drop-shadow(1px 2px 1px #49661c);
      transform: scale(1.5);
    },
  `,
  checkboxBtn: css`
    display: inline !important;
    margin-left: 30px;
  `,
};
