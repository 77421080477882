/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import PublicBgTop from '../../../images/PublicBgTop.svg';
import PublicBgMid from '../../../images/PublicBgMid.svg';
import PublicBgBottom from '../../../images/PublicBgBottom.svg';

import * as styles from '../../../styles/styles';

export const bg = css({
  background: `url(${PublicBgTop}) no-repeat, url(${PublicBgMid}) left 44% no-repeat, url(${PublicBgBottom}) left bottom no-repeat`,
  backgroundSize: '28vh,25vh,15vh',
  backgroundColor: styles.Colors.LightGreyMainBG,
});

export const bgTigSports = css({
  backgroundSize: '28vh,25vh,15vh',
  backgroundColor: styles.Colors.DarkBG,
});

export const LoginBox = css({
  maxWidth: '300px',
  margin: 'auto',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '100vh',
});

export const formBox = css({
  padding: '20px  0',
  color: '#fff',
});

export const Description = [styles.MainText, css({})];
export const DescriptionLight = [styles.MainTextLight, css({})];

export const ContentSection = css({
  marginTop: '50px',
  textAlign: 'left',
});
