/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import * as styles from '../../../../styles/styles';

export const thumbBox = css({
  width: '100px',
  boxSizing: 'border-box',
});

export const imagePreviewBox = css({
  height: '70vh',
  width: '70vh',
  display: 'flex',
  alignItems: 'center',
  '& img': {
    width: '100%',
    height: 'auto',
  },
});

export const imageIconBox = css({
  width: '100px',
  height: '100px',
  margin: '10px auto',
  display: 'flex',
  alignItems: 'center',
  //border: `1px solid ${styles.Colors.Grey}`,
  //borderRadius: '2px',
  overflow: 'hidden',
});

export const imageIcon = (options: any = {}) => {
  const defaultOptions = {
    width: '100%',
    height: 'auto',
  };
  return css({ ...defaultOptions, ...options });
};

export const fileIcon = (options: any = {}) => {
  const defaultOptions = {
    width: '100%',
    height: '100px',
    backgroundSize: '85%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: '10px auto',
  };
  return css({ ...defaultOptions, ...options });
};

export const name = [
  styles.MainText,
  css({
    textAlign: 'center',
    marginTop: '20px',
    width: '100%',
    overflow: 'hidden',
  }),
];

export const checkbox = css({});
