/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import * as yup from 'yup';
import { withFormik, Form, FormikProps } from 'formik';

import PrimaryBtn from '../../common/buttons/PrimaryBtn';
import FormRow from '../../common/form/FormRow';
import FormField from '../../common/form/FormField';
import FormError from '../../common/form/FormError';

import { Action } from '../../../store/types';

import * as styles from './styles';
import * as validatinShemas from '../../../utils/validatinShemas';
import { IVisualContext } from '../../../contexts/VisualContext';
import withVisualContext from '../../../contexts/withVisualContext';
import { Colors } from '../../../styles/styles';

interface FormValues {
  name: string;
  phone: string;
  password: string;
}

export interface IContext {
  email: string;
  name: string;
  phone: string;
  token: string;
}

interface IProps {
  context: IContext;
  invinantionReq: Action<{}>;
  visual?: IVisualContext;
}

jsx;

const InvitationForm: FunctionComponent<IProps & FormikProps<FormValues>> = ({
  visual,
  errors,
  touched,
}): ReactElement<IProps & FormikProps<FormValues>> => {
  const labelTextColor = visual && visual.isCopy ? Colors.White : Colors.RegularBlack;
  const buttonBorder = visual && visual.isCopy ? `1px solid ${Colors.White}` : '0px solid transparent';
  return (
    <div css={styles.formBox}>
      <Form>
        <FormRow>
          <label htmlFor="name" style={{ color: labelTextColor }}>
            Name
          </label>
          <FormField name="name" placeholder="name" />
          <FormError name="name" errors={errors} touched={touched} />
        </FormRow>

        <FormRow>
          <label htmlFor="phone" style={{ color: labelTextColor }}>
            Phone
          </label>
          <FormField name="phone" placeholder="+1234567890" />
          <FormError name="phone" errors={errors} touched={touched} />
          <p css={style.description}>Please add phone number in international format. Use only '+' and numbers.</p>
        </FormRow>

        <FormRow>
          <label htmlFor="password" style={{ color: labelTextColor }}>
            Password
          </label>
          <FormField name="password" type="password" placeholder="password" />
          <FormError name="password" errors={errors} touched={touched} />
        </FormRow>

        <FormRow>
          <PrimaryBtn title="Complete registration" isWide={true} style={{ border: buttonBorder }} />
        </FormRow>
      </Form>
    </div>
  );
};

interface FormProps extends IProps {}

const entSchema = yup.object({}).shape({
  name: validatinShemas.vNameReq,
  phone: validatinShemas.vPhoneReq,
  password: validatinShemas.vPasswordReq,
});

const exchangeFormic = withFormik<FormProps, FormValues>({
  mapPropsToValues: (props) => {
    return {
      name: props.context.name || '',
      phone: props.context.phone || '',
      password: '',
    };
  },

  validationSchema: entSchema,

  handleSubmit: (values, { props, setSubmitting }) => {
    props.invinantionReq({ data: values, token: props.context.token });
  },

  displayName: 'RestoreForm',
});

export default exchangeFormic(withVisualContext(InvitationForm));

const style = {
  description: css`
    font-size: 12px;
    color: ${Colors.LightBlack};
    margin: 5px;
  `,
};
