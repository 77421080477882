/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withFormik, Form, FormikProps } from 'formik';
import * as yup from 'yup';
import { compose } from 'recompose';
import { connect } from '../../../../utils/redux';
import FormRow from '../../../common/form/FormRow';
import ActionsRow from '../../../common/form/ActionsRow';
import FieldsArea from '../../../common/form/FieldsArea';
import FormBlock from '../../../common/form/FormBlock';
import FormDropzone from '../../../common/form/FormDropzone';
import FieldsSet from '../../../common/form/FieldsSet';
import TextArea from '../../../common/form/TextArea';
import FormError from '../../../common/form/FormError';
import RemoveBox from '../../../common/form/RemoveBox';
import FileThumb, { ALLOWABLE_FILE_TYPES } from '../../../common/form/fileviewer/FileThumb';
import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../common/buttons/OutlineBtn';
import modal from '../../../common/modal';
import * as styles from './styles';
import { toast } from 'react-toastify';
import uniqid from 'uniqid';
import { tr } from '../../../common/grid/styles';
import { Link } from 'react-router-dom';
import routes from '../../../../routes';
import { MAX_FILE_UPLOAD_MB } from '../../../../config';

interface FormValues {
  blocks: any[];
  attachments: any[];
}

interface OtherProps {
  onSave: (values: any) => void;
  onClose: () => void;
  item: any;
}

jsx;

const GeneralInfoForm = ({
  onClose,
  item,
  errors,
  touched,
  values,
  setFieldValue,
}: OtherProps & FormikProps<FormValues>) => {
  const { Modal, ModalHeader, ModalFooter, ModalBody, ModalActions } = modal;

  return (
    <Form>
      <FieldsArea>
        <FormBlock>
          <FieldsSet width={2}>
            {values.blocks.map((value: any, index: number) => {
              const name = `blocks.${index}`;
              return (
                <FormRow key={value.id}>
                  <label htmlFor="name">
                    <div css={styles.blockTitle}>{value.name}</div>
                  </label>

                  <TextArea
                    placeholder={'Please add general info '}
                    name={name}
                    value={value.description}
                    cols={200}
                    rows={5}
                    onChange={(e: any) => {
                      setFieldValue(name, { ...value, description: e.currentTarget.value });
                    }}
                  />

                  <FormError name={`${name}.description`} errors={errors} touched={touched} />
                </FormRow>
              );
            })}
          </FieldsSet>
        </FormBlock>

        <FormBlock>
          <FieldsSet width={4}>
            <div css={styles.filesListBox}>
              {values.attachments.map((attachment: any) => {
                const file = attachment.isNew ? attachment.file : attachment;
                return (
                  <div key={attachment.id} css={styles.iconWrapper}>
                    <RemoveBox
                      onRemove={() => {
                        const attachments = values.attachments.filter((attach) => attach.id !== attachment.id);
                        setFieldValue('attachments', attachments);
                      }}
                    >
                      <FileThumb file={file} isShowName={true} isImagePreview={true} isLoadableFile={true} />
                    </RemoveBox>
                  </div>
                );
              })}
            </div>

            <FormDropzone
              onDrop={(files: any) => {
                files = validateFiles(files);
                files = files.map((file: any) => ({ file, isNew: true, id: uniqid('new-') }));
                setFieldValue('attachments', [...values.attachments, ...files]);
              }}
              maxSize={MAX_FILE_UPLOAD_MB}
              fileFormats={ALLOWABLE_FILE_TYPES}
            />
          </FieldsSet>
        </FormBlock>
      </FieldsArea>
      <ActionsRow>
        <OutlineBtn type="button" onClick={onClose} title="Cancel" />
        <PrimaryBtn title="Save" type="submit" />
      </ActionsRow>
    </Form>
  );
};

export const validateFiles = (files: any[]) => {
  files = files.filter((file: any) => {
    if (!checkFileFormat(file)) {
      toast.error(`File ${file.name} is incorrect`);
      return false;
    }

    if (!checkFileSize(file)) {
      toast.error(`File ${file.name} is more then ${MAX_FILE_UPLOAD_MB}Mb`);
      return false;
    }

    return true;
  });

  return files;
};

const checkFileFormat = (file: any) => {
  // TODO: it would be better to rely on file.type <- mime type
  // https://en.wikipedia.org/wiki/Media_type
  // or http://qaru.site/questions/62245/how-to-check-file-mime-type-with-javascript-before-upload
  const ext = /(?:\.([^.]+))?$/.exec(file.name)![1] || '';
  return ALLOWABLE_FILE_TYPES.includes(ext.toLowerCase());
};

export const checkFileSize = (file: any) => {
  return file.size <= MAX_FILE_UPLOAD_MB * 1024 * 1024;
};

const validationSchema = yup.object({}).shape({
  blocks: yup.array().of(
    yup.object({}).shape({
      description: yup.string().required('This field is required'),
    })
  ),
});

export const FormikHoc = withFormik<OtherProps, FormValues>({
  enableReinitialize: true,

  mapPropsToValues: ({ item: { blocks, attachments } }) => {
    return {
      blocks: blocks.map((block: any) => ({ id: block.id, name: block.name, description: block.description || '' })),
      attachments,
    };
  },

  validationSchema,

  handleSubmit: ({ blocks, attachments }: any, { props: { onSave }, setSubmitting }) => {
    let data = new FormData();

    for (let key in blocks) {
      data.append('blocks[]', JSON.stringify(blocks[key]));
    }

    const newFiles = attachments.filter((attach: any) => attach.isNew);
    for (let key in newFiles) {
      const { file } = newFiles[key];
      data.append('attachments', file, file.name);
    }

    const oldFiles = attachments.filter((attach: any) => !attach.isNew);
    for (let key in oldFiles) {
      const file = oldFiles[key];
      data.append('attachments[]', JSON.stringify(file));
    }

    onSave(data);
  },

  displayName: 'GeneralInfoForm',
});

export default compose<OtherProps & FormikProps<FormValues>, OtherProps>(FormikHoc)(GeneralInfoForm);
