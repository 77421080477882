/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Component, createRef } from 'react';
import { compose } from 'recompose';
import { withFormik, FormikProps, Form } from 'formik';
import * as yup from 'yup';
import * as styles from '../multiselect/styles';
// components
import form from '..';
import EmbededMultiSelect from './EmbededMultiSelect';
// other
import * as validatinShemas from '../../../../utils/validatinShemas';
import { Colors } from '../../../../styles/styles';
import LABELS from '../../../../constants';
import PrimaryBtn from '../../buttons/OutlineGreen';
import OutlineBtn from '../../buttons/OutlineForwarded';

const { FormRow, FormField, FormError } = form;

export interface ISupplierFormValues {
  name: string;
  functions: number[];
  agentName: string;
  email: string;
  phone: string;
}

export interface FormProps {
  initialEmail?: string;
  initialName?: string;
  onSave: (values: ISupplierFormValues) => void;
  supplierFunctions: any[];
  backToSelect: (a: any) => void;
  onCreateAndSelect: (name: string) => void;
  checkIsSupplierAlreadyExists?: (values: ISupplierFormValues, onSave: any, onSelect: any) => void;
}

jsx;
class CreateSupplierTab extends Component<FormProps & FormikProps<ISupplierFormValues>> {
  private cancelRef: any;
  private submitRef: any;
  private firstFieldRef: any;
  constructor(props: FormProps & FormikProps<ISupplierFormValues>) {
    super(props);
    this.cancelRef = createRef();
    this.submitRef = createRef();
    this.firstFieldRef = createRef();
  }
  handleLeaveFocusFromBtn = (event: KeyboardEvent) => {
    if (event.key === 'Tab' || event.keyCode === 9) {
      event.preventDefault();
      event.stopPropagation();
      this.firstFieldRef && this.firstFieldRef.current && this.firstFieldRef.current.focus();
    }
    if (event.shiftKey === true) {
      if (event.key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
        this.cancelRef && this.cancelRef.current && this.cancelRef.current.focus();
      }
    }
  };
  handleLeaveFocus = (event: KeyboardEvent) => {
    if (event.shiftKey === true) {
      if (event.key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
        this.submitRef && this.submitRef.current && this.submitRef.current.focus();
      }
    }
  };
  render() {
    const { errors, touched, values, setFieldValue, supplierFunctions } = this.props;
    return (
      <div css={styles.addNewContainer}>
        <Form>
          <FormRow>
            <label htmlFor="name">Supplier name</label>
            <FormField
              name="name"
              placeholder="name"
              autoFocus
              innerRef={this.firstFieldRef}
              onKeyDown={(e: any) => this.handleLeaveFocus(e)}
            />
            <FormError name="name" errors={errors} touched={touched} />
          </FormRow>

          <FormRow>
            <label htmlFor="functions">{LABELS.Functions}</label>
            <EmbededMultiSelect
              items={supplierFunctions}
              selected={values.functions}
              scheme={{ id: 'id', title: 'name' }}
              onChange={(values) => {
                setFieldValue('functions', values);
              }}
            />
            <FormError name="functions" errors={errors} touched={touched} />
          </FormRow>
          <FormRow>
            <label htmlFor="agentName">Agent name</label>
            <FormField name="agentName" placeholder="agent name" />
            <FormError name="agentName" errors={errors} touched={touched} />
          </FormRow>

          <FormRow>
            <label htmlFor="email">Email</label>
            <FormField name="email" placeholder="email" />
            <FormError name="email" errors={errors} touched={touched} />
          </FormRow>

          <FormRow>
            <label htmlFor="phone">Phone</label>
            <FormField name="phone" placeholder="+1234567890" />
            <FormError name="phone" errors={errors} touched={touched} />
            <p css={style.description}>Please add phone number in international format. Use only '+' and numbers.</p>
          </FormRow>
          <div css={[styles.actionsBox, styles.addNewContainer]}>
            <OutlineBtn
              type="button"
              isSmall={true}
              title="Back to Select"
              onClick={this.props.backToSelect}
              tabIndex={-1}
              ref={this.cancelRef}
            />
            <PrimaryBtn
              title="Create and Select"
              tabIndex={0}
              onKeyPress={this.handleLeaveFocusFromBtn}
              ref={this.submitRef}
            />
          </div>
        </Form>
      </div>
    );
  }
}

export const formikHoc = withFormik<FormProps, ISupplierFormValues>({
  mapPropsToValues: ({ initialName }) => {
    return {
      name: initialName ? initialName : '',
      functions: [],
      agentName: '',
      email: '',
      phone: '',
    };
  },

  validationSchema: yup.object({}).shape({
    name: validatinShemas.vNameReq,
    functions: validatinShemas.vIdListReq,
    agentName: validatinShemas.vNameReq,
    email: validatinShemas.vEmailReq,
    phone: validatinShemas.vPhoneReq,
  }),
  handleSubmit: (values, { props: { checkIsSupplierAlreadyExists, onSave, onCreateAndSelect } }) => {
    checkIsSupplierAlreadyExists && checkIsSupplierAlreadyExists(values, onSave, onCreateAndSelect);
  },
  displayName: 'CreateSupplierTab',
});

export default compose<FormProps & FormikProps<ISupplierFormValues>, FormProps>(formikHoc)(CreateSupplierTab);
const style = {
  description: css`
    font-size: 12px;
    color: ${Colors.LightBlack};
    margin: 5px;
  `,
};
