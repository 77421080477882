import React, { Component, createContext } from 'react';
import LABELS, { ILabel } from '../constants';
import BBStandLogo from '../images/bb-logo-original.svg';
import TigSportLogo from '../images/tig-logo.png';

export interface IVisualContext {
  logo: string;
  labels: ILabel;
  isCopy: boolean;
}

const Context = createContext<IVisualContext | null>(null);

export default class VisualContext extends Component {
  static Consumer = Context.Consumer;
  state: IVisualContext = {
    logo: BBStandLogo,
    labels: LABELS,
    isCopy: false,
  };

  componentDidMount() {
    if (
      !document.URL.includes('https://backbone-beta-app.atlasproductionplanning.com') &&
      !document.URL.includes('https://atlas.backbone-international.com') &&
      !document.URL.includes('http://localhost:3000')
    ) {
      document.title = 'TIG sports';
      const favIcon = document.getElementById('favicon');
      favIcon && favIcon.setAttribute('href', '/tig-sports.ico');
      this.setState({
        logo: TigSportLogo,
        isCopy: true,
      });
    } else {
      this.setState({ logo: BBStandLogo });
    }
  }

  render() {
    return <Context.Provider value={this.state}>{this.props.children}</Context.Provider>;
  }
}
