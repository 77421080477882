/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withFormik, FormikProps } from 'formik';
import * as yup from 'yup';
import { compose, lifecycle } from 'recompose';
// redux
import { connect } from '../../../../utils/redux';
import { updateProductionReq } from './store/actions';
import { getClientsForSelectReq, refreshClientForSelect } from '../Clients/store/actions';
import { getClientAgentsReq, setClientAgentsParams } from '../Clients/Agents/store/actions';
import { clientsForSelect } from '../Clients/store/selectors';
import { clientAgents } from '../Clients/Agents/store/selectors';
import { getProducersReq } from '../Producers/store/actions';
import { producers } from '../Producers/store/selectors';
// types
import { Action } from '../../../../store/types';
import { ClientAgentListState } from '../Clients/Agents/store/types';
import { ActivityTypes } from './store/types';
import { ClientListState } from '../Clients/store/types';
import { IProducer } from '../Producers/store/types';
// components
import AsyncData from '../../../common/async/AsyncData';
import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../common/buttons/OutlineBtn';
import Checkbox from '../../../common/form/InputCheckbox';
import form from '../../../common/form';
import ImageUpload from '../../../common/form/kits/ImageUpload';
import select from '../../../common/form/select';
import page from '../../../common/page';
import MultiSelectArea from '../../../common/form/multiselect/MultiSelectArea';
import DateRange, { IDataRangeValue } from '../../../common/form/datepicker/DateRange';
import { IDataRangeWithName } from '../../../common/form/datepicker/DateRangeWithName';
import DateRangeListWithName from '../../../common/form/datepicker/DateRangeListWithName';
import { toast } from 'react-toastify';
// utils
import { extractKeyValue } from '../../../../utils/collection';
import * as validatinShemas from '../../../../utils/validatinShemas';
import _ from 'lodash';
import withVisualContext from '../../../../contexts/withVisualContext';
import { IVisualContext } from '../../../../contexts/VisualContext';
// other
import routes from '../../../../routes';

interface FormValues {
  name: string;
  client: number;
  clientAgents: number[];
  producer: number;
  freelancers: number[];
  loadIn: IDataRangeValue;
  loadOut: IDataRangeValue;
  shows: IDataRangeWithName[];
  rehearsals: IDataRangeWithName[];
  file?: string;
  hideShows: boolean;
  showTransport: boolean;
  showMachinery: boolean;
}

interface OtherProps {
  production: any;
  updateProductionReq: Action<{}>;
  getClientsForSelectReq: Action<{}>;
  refreshClientForSelect: Action<{}>;
  clientsForSelect: ClientListState;
  allClientAgents: ClientAgentListState;
  setClientAgentsParams: Action<{}>;
  getClientAgentsReq: Action<{}>;
  producers: IProducer[];
  getProducersReq: Action<{}>;
  history: any;
  visual: IVisualContext;
}

interface FormProps {}

jsx;
const withLifecycle = lifecycle<OtherProps, {}>({
  componentDidMount() {
    const { getProducersReq, production, setClientAgentsParams, refreshClientForSelect } = this.props;
    refreshClientForSelect();
    getProducersReq({ size: 1000 });
    setClientAgentsParams({ ownerId: production.client.id, size: 100 });
  },
});

const prepareProductionValues = (values: FormValues) => {
  const clearEmpty = (item: IDataRangeWithName) => item.name || item.range.from || item.range.to;
  return {
    ...values,
    shows: values.shows.filter(clearEmpty),
    rehearsals: values.rehearsals.filter(clearEmpty),
  };
};

const CreateForm = ({
  errors,
  touched,
  values,
  setFieldValue,
  setClientAgentsParams,
  getClientAgentsReq,
  allClientAgents,
  getClientsForSelectReq,
  clientsForSelect,
  producers,
  production,
  visual,
}: OtherProps & FormikProps<FormValues>) => {
  const { FormRow, FormRowSections, FormField, FormError, FormBlock, FieldsSet, ActionsRow, Form, FieldsArea } = form;
  const { Select, Option } = select;
  const { Page, Breadcrumbs, PageHeader } = page;
  const prodId = production.id;

  return (
    <Fragment>
      <PageHeader title="Update production">
        <Breadcrumbs
          path={[
            { title: 'Productions', link: routes.Productions },
            {
              title: production.name,
              link: routes.Production.replace(':id', `${prodId}`),
            },
            {
              title: 'Update production',
              link: routes.ProductionsEdit.replace(':id', `${prodId}`),
            },
          ]}
        />
      </PageHeader>
      <Page>
        <Form>
          <div>
            <AsyncData
              data={[
                { asyncData: clientsForSelect, asyncGetAction: getClientsForSelectReq },
                { asyncData: allClientAgents, asyncGetAction: getClientAgentsReq },
              ]}
            >
              {() => {
                return (
                  <Fragment>
                    <FieldsArea>
                      {/* MAIN */}
                      <FormBlock>
                        <FieldsSet width={2}>
                          <FormRow>
                            <FormRowSections width={2}>
                              <label htmlFor="name">Name</label>
                              <FormField name="name" placeholder="name" />
                              <FormError name="name" errors={errors} touched={touched} />
                            </FormRowSections>

                            <FormRowSections width={2}>
                              <label htmlFor="subProduction">{visual.labels.Subproduction}</label>
                              <FormField name="subProduction" placeholder="Main" disabled={true} />
                              <FormError name="subProduction" errors={errors} touched={touched} />
                            </FormRowSections>
                          </FormRow>

                          <FormRow>
                            <FormRowSections width={2}>
                              <label htmlFor="code">Syntax</label>
                              <FormField name="code" placeholder="syntax" />
                              <FormError name="code" errors={errors} touched={touched} />
                            </FormRowSections>

                            <FormRowSections width={2}>
                              <label htmlFor="location">Location</label>
                              <FormField name="location" placeholder="location" />
                              <FormError name="location" errors={errors} touched={touched} />
                            </FormRowSections>
                          </FormRow>

                          <FormRow>
                            <FormRowSections width={2}>
                              <label htmlFor="client">Client</label>
                              <Select
                                scheme={{ title: 'name' }}
                                items={clientsForSelect.data}
                                selected={values.client}
                                onChange={(value) => {
                                  setFieldValue('client', value);
                                  setFieldValue('clientAgents', []);
                                  setClientAgentsParams({ ownerId: value });
                                }}
                              >
                                {(item: any, selected: boolean) => {
                                  return <Option title={item.name} selected={selected} />;
                                }}
                              </Select>

                              <FormError name="client" errors={errors} touched={touched} />
                            </FormRowSections>

                            <FormRowSections width={2}>
                              <label htmlFor="clientAgents">Client agent</label>
                              <MultiSelectArea
                                scheme={{ id: 'id', title: 'name' }}
                                items={allClientAgents.data}
                                selected={values.clientAgents}
                                onChange={(value) => {
                                  setFieldValue('clientAgents', value);
                                }}
                              >
                                {(item: any, selected: boolean) => {
                                  return <Option title={item.name} selected={selected} />;
                                }}
                              </MultiSelectArea>
                              <FormError name="clientAgents" errors={errors} touched={touched} />
                            </FormRowSections>
                          </FormRow>

                          <FormRow>
                            <FormRowSections width={2}>
                              <label htmlFor="producer">Production manager</label>
                              <Select
                                scheme={{ title: 'name' }}
                                items={producers.filter((item) => item.isActive)}
                                selected={values.producer}
                                onChange={(value) => {
                                  setFieldValue('producer', value);
                                }}
                              >
                                {(item: any, selected: boolean) => {
                                  return <Option title={item.name} selected={selected} />;
                                }}
                              </Select>
                              <FormError name="producer" errors={errors} touched={touched} />
                            </FormRowSections>

                            <FormRowSections width={2}>
                              <label htmlFor="freelancers">Freelancers</label>
                              <MultiSelectArea
                                items={producers.filter((item) => item.isActive && item.isFreelancer)}
                                selected={values.freelancers}
                                scheme={{ id: 'id', title: 'name' }}
                                onChange={(value) => {
                                  setFieldValue('freelancers', value);
                                }}
                              />
                              <FormError name="freelancers" errors={errors} touched={touched} />
                            </FormRowSections>
                          </FormRow>
                        </FieldsSet>
                      </FormBlock>
                      {/* LOAD DATES */}
                      <FormBlock>
                        <FieldsSet width={2}>
                          <FormRow>
                            <DateRange
                              value={values.loadIn}
                              fromLabel={'Load in from'}
                              toLabel={'Load in to'}
                              onChange={(value) => {
                                setFieldValue('loadIn', value);
                              }}
                            />
                          </FormRow>
                          <FormError name="loadIn" errors={errors} touched={touched} />

                          <FormRow>
                            <DateRange
                              value={values.loadOut}
                              fromLabel={'Load out from'}
                              toLabel={'Load out to'}
                              onChange={(value) => {
                                setFieldValue('loadOut', value);
                              }}
                            />
                          </FormRow>
                          <FormError name="loadIn" errors={errors} touched={touched} />
                        </FieldsSet>
                      </FormBlock>

                      {/* SHOWS DATES */}
                      <FormBlock>
                        <FieldsSet width={2}>
                          <DateRangeListWithName
                            nameLabel="Shows name"
                            fromLabel="Show start"
                            toLabel="Show end"
                            value={values.shows}
                            error={<FormError name="shows" errors={errors} touched={touched} />}
                            btn={<OutlineBtn title={'Add new show'} />}
                            onChange={(value) => {
                              setFieldValue('shows', value);
                            }}
                            checkboxForHiding="Hide in the schedule"
                            hidedShow={values.hideShows}
                            onHideShow={(e: any) => {
                              setFieldValue('hideShows', e.currentTarget.checked);
                            }}
                          />
                        </FieldsSet>
                      </FormBlock>

                      {/* REHEARSALS DATES */}
                      <FormBlock>
                        <FieldsSet width={2}>
                          <DateRangeListWithName
                            nameLabel="Rehearsals name"
                            fromLabel="Rehearsals start"
                            toLabel="Rehearsals end"
                            value={values.rehearsals}
                            error={<FormError name="rehearsals" errors={errors} touched={touched} />}
                            btn={<OutlineBtn title={'Add new rehearsal'} />}
                            onChange={(value) => {
                              setFieldValue('rehearsals', value);
                            }}
                          />
                        </FieldsSet>
                      </FormBlock>

                      <FormBlock>
                        <FieldsSet width={1}>
                          <FormRow>
                            <label htmlFor="file">Production logo</label>
                            <ImageUpload
                              name="file"
                              file={_.get(values, 'file')}
                              onSelect={(file: any) => {
                                setFieldValue('file', file.file);
                              }}
                              supportedFormats={validatinShemas.SUPPORTED_IMAGE_FORMATS}
                              onSelectUnsupported={(file: any) => {
                                toast.error(`Unsupported image format "${file.type}"`);
                              }}
                            />
                            <FormError name="file" errors={errors} touched={touched} />
                          </FormRow>
                        </FieldsSet>
                      </FormBlock>

                      <FormBlock>
                        <FieldsSet width={1}>
                          <FormRow>
                            <label css={checkboxLabel}>
                              <Checkbox
                                name="showTransport"
                                checked={values.showTransport}
                                onChange={(e: any) => {
                                  setFieldValue('showTransport', e.currentTarget.checked);
                                }}
                              />{' '}
                              Show Transport schedule
                            </label>
                            <label css={checkboxLabel}>
                              <Checkbox
                                name="showMachinery"
                                checked={values.showMachinery}
                                onChange={(e: any) => {
                                  setFieldValue('showMachinery', e.currentTarget.checked);
                                }}
                              />{' '}
                              Show Machinery schedule
                            </label>
                          </FormRow>
                        </FieldsSet>
                      </FormBlock>
                    </FieldsArea>
                  </Fragment>
                );
              }}
            </AsyncData>

            <ActionsRow>
              <Link to={routes.Production.replace(':id', production.id)}>
                <OutlineBtn title="Cancel" isWide={false} />
              </Link>
              <PrimaryBtn title="Update production" isWide={false} />
            </ActionsRow>
          </div>
        </Form>
      </Page>
    </Fragment>
  );
};

export const exchangeFormic = withFormik<FormProps & OtherProps, FormValues>({
  enableReinitialize: true,
  validationSchema: yup.object({}).shape({
    name: validatinShemas.vNameReq,
    clientAgents: validatinShemas.vIdListReq,
    producer: validatinShemas.vIdReq,
    client: yup.string().required(),
    location: yup.string().required(),
    code: yup.string().required('syntax is a required field'),
  }),

  mapPropsToValues: ({ production }) => {
    const extractDTWithName = (item: any) => ({
      id: item.id,
      name: item.name || '',
      range: { from: item.range.from || '', to: item.range.to || '' },
    });
    const shows = production.mainActivities
      .filter((item: any) => item.type === ActivityTypes.SHOW)
      .map(extractDTWithName);
    const rehearsals = production.mainActivities
      .filter((item: any) => item.type === ActivityTypes.REHEARSAL)
      .map(extractDTWithName);

    return {
      name: production.name || '',
      subProduction: 'Main',
      location: production.location || '',
      code: production.code || '',
      client: production.client.id || 0,
      clientAgents: extractKeyValue(production.clientAgents, 'id'),
      producer: production.producer.id,
      freelancers: extractKeyValue(production.freelancers, 'id'),
      loadIn: { from: production.loadIn.from || '', to: production.loadIn.to || '' },
      loadOut: { from: production.loadOut.from || '', to: production.loadOut.to || '' },
      shows: shows,
      rehearsals: rehearsals,
      file: production.file || '',
      hideShows: production.hideShows || false,
      showTransport: production.showTransport || false,
      showMachinery: production.showMachinery || false,
    };
  },
  handleSubmit: ({ file, ...values }: any, { props: { updateProductionReq, production }, setSubmitting }) => {
    file = file === production.file ? undefined : file;
    values = { ...values, file };
    updateProductionReq({ id: production.id, ...prepareProductionValues(values) });
  },
  displayName: 'EditForm',
});

export default compose<OtherProps & FormikProps<FormValues>, {}>(
  connect(
    { clientsForSelect, allClientAgents: clientAgents, producers },
    {
      updateProductionReq,
      getClientsForSelectReq,
      refreshClientForSelect,
      getClientAgentsReq,
      setClientAgentsParams,
      getProducersReq,
    }
  ),
  withVisualContext,
  withLifecycle,
  exchangeFormic
)(CreateForm);

const checkboxLabel = css({
  cursor: 'pointer',
  marginTop: '12px',
  display: 'flex !important',
  alignItems: 'center  !important',
  columnGap: '10px',
});
