/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import { withFormik, Field, FormikProps } from 'formik';
import * as yup from 'yup';
import { compose } from 'recompose';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { connect } from '../../../../utils/redux';

import { createClientReq } from './store/actions';
import { Action } from '../../../../store/types';
import * as validatinShemas from '../../../../utils/validatinShemas';

import {} from './store/selectors';

import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../common/buttons/OutlineBtn';
import form from '../../../common/form';
import ImageUpload from '../../../common/form/kits/ImageUpload';
import page from '../../../common/page';
import routes from '../../../../routes';

import { Link } from 'react-router-dom';
import { Colors } from '../../../../styles/styles';

interface FormValues {
  name: string;
  file: string | null;
  agentName: string;
  phone: string;
  email: string;
}

interface OtherProps {
  createClientReq: Action<{}>;
  backToSelect: (e: any) => void;
  onCreateAndSelect: (value: any) => void;
}

interface FormProps {
  initialEmail?: string;
}

jsx;

const CreateForm = ({ errors, touched, values, setFieldValue }: OtherProps & FormikProps<FormValues>) => {
  const { FormRow, FormField, FormFile, FormError, FormBlock, FieldsSet, ActionsRow, Form, FieldsArea, Thumb } = form;
  const { Page, Breadcrumbs, PageHeader } = page;

  return (
    <Fragment>
      <PageHeader title="New client">
        <Breadcrumbs
          path={[{ title: 'Clients', link: routes.Clients }, { title: 'New client', link: routes.ClientsCreate }]}
        />
      </PageHeader>
      <Page>
        <Form>
          <div>
            <FieldsArea>
              <FormBlock>
                <FieldsSet>
                  <FormRow>
                    <label htmlFor="name">Name</label>
                    <FormField name="name" placeholder="name" />
                    <FormError name="name" errors={errors} touched={touched} />
                  </FormRow>

                  <FormRow>
                    <ImageUpload
                      name="file"
                      file={_.get(values, 'file.file')}
                      onSelect={(file: any) => {
                        setFieldValue('file', file);
                      }}
                      supportedFormats={validatinShemas.SUPPORTED_IMAGE_FORMATS}
                      onSelectUnsupported={(file: any) => {
                        toast.error(`Unsupported image format "${file.type}"`);
                      }}
                    />
                    <FormError name="file" errors={errors} touched={touched} />
                  </FormRow>
                </FieldsSet>

                <FieldsSet>
                  <FormRow>
                    <label htmlFor="agentName">Agent name</label>
                    <FormField name="agentName" placeholder="agent name" />
                    <FormError name="agentName" errors={errors} touched={touched} />
                  </FormRow>

                  <FormRow>
                    <label htmlFor="email">Email</label>
                    <FormField name="email" placeholder="email" />
                    <FormError name="email" errors={errors} touched={touched} />
                  </FormRow>

                  <FormRow>
                    <label htmlFor="phone">Phone</label>
                    <FormField name="phone" placeholder="+1234567890" />
                    <FormError name="phone" errors={errors} touched={touched} />
                    <p css={style.description}>
                      Please add phone number in international format. Use only '+' and numbers.
                    </p>
                  </FormRow>
                </FieldsSet>
              </FormBlock>
            </FieldsArea>

            <ActionsRow>
              <Link to={routes.Clients}>
                <OutlineBtn title="Cancel" isWide={false} />
              </Link>
              <PrimaryBtn title="Create client" isWide={false} />
            </ActionsRow>
          </div>
        </Form>
      </Page>
    </Fragment>
  );
};

export const exchangeFormic = withFormik<FormProps & OtherProps, FormValues>({
  mapPropsToValues: (props) => {
    return {
      name: '',
      file: null,
      agentName: '',
      phone: '',
      email: '',
    };
  },

  validationSchema: yup.object({}).shape({
    name: validatinShemas.vNameReq,
    agentName: validatinShemas.vNameReq,
    phone: validatinShemas.vPhoneReq,
    email: validatinShemas.vEmailReq,
    file: yup.mixed().test('file', validatinShemas.REQUIRE_MESSAGE, (value) => {
      return !!value;
    }),
  }),

  handleSubmit: ({ file: { file }, ...values }: any, { props, setSubmitting }) => {
    props.createClientReq({ ...values, file });
    props.onCreateAndSelect(values.name);
  },
  displayName: 'LoginForm',
});

export default compose<OtherProps & FormikProps<FormValues>, {}>(
  connect(
    {},
    { createClientReq }
  ),
  exchangeFormic
)(CreateForm);

const style = {
  description: css`
    font-size: 12px;
    color: ${Colors.LightBlack};
    margin: 5px;
  `,
};
