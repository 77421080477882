/** @jsx jsx */
import { FunctionComponent, ReactElement } from 'react';
// components
import { Widget } from '../../../../../common/widget/Widget';
// styles
import { jsx, css } from '@emotion/core';
import { H2, FontFamilies, Colors } from '../../../../../../styles/styles';
// types
import { IProduction } from '../../store/types';
// utils
import moment from 'moment';
import { getTimeFormat } from '../../../../../../utils/timeFormatter';
import _ from 'lodash';
import { IActivity } from '../store/types';

export interface IProps {
  production: IProduction;
}

jsx;
export const MainActivitiesWidget: FunctionComponent<IProps> = ({ production }): ReactElement<IProps> => {
  const sortActivities = (activities: IActivity[]): IActivity[] => {
    return Object.values(activities).sort(
      (a: IActivity, b: IActivity) => +new Date(a.range.from) - +new Date(b.range.from)
    );
  };

  const getBulletClassname = (index: number): string => {
    if (index === 0) return 'first';
    if (index === activityList.length - 1) return 'last';
    return '';
  };

  const buildActivityName = ({ name, type }: IActivity, index: number): string => {
    type = type || '';
    name = !type ? name : name ? `(${name})` : `#${index}`;
    return type ? `${type} ${name}` : name;
  };

  const activityList = [
    {
      name: 'Load in',
      range: production.loadIn,
    },
    ...sortActivities(production.mainActivities),
    {
      name: 'Load out',
      range: production.loadOut,
      isLoadOut: true,
    },
  ];

  return (
    <Widget css={style.widget}>
      <div css={style.left}>
        <div css={style.title}>Main activities</div>
      </div>
      <div css={style.right}>
        {activityList.map((activity, index) => {
          const displayDate = _.get(activity, 'isLoadOut') ? activity.range.to : activity.range.from;
          const date = moment.utc(displayDate).format('MMMM D');
          const time = moment.utc(displayDate).format(getTimeFormat());
          return (
            <div css={style.row} key={index}>
              <div css={style.name}>{buildActivityName(activity as IActivity, index)}</div>
              <div css={style.timeline}>
                <div
                  css={style.bullet}
                  className={getBulletClassname(index)}
                  style={{ background: `hsla(${index * 45}, 70%, 50%, 1)` }}
                />
              </div>
              <div css={style.datetime}>
                <span css={style.date}>{date}</span>
                <span css={style.time}>{time}</span>
              </div>
            </div>
          );
        })}
      </div>
    </Widget>
  );
  ``;
};

export default MainActivitiesWidget;

const style = {
  widget: css`
    color: ${Colors.RegularBlack};
    display: flex;
    min-height: 200px;
  `,
  left: css`
    position: relative;
    flex: 0;
    min-width: 50px;
    border-right: 1px solid rgba(150, 150, 150, 0.25);
  `,
  right: css`
    flex: 1;
    font-family: ${FontFamilies.Main};
    font-size: 13px;
    padding: 5px;
  `,
  title: [
    H2,
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      writing-mode: vertical-lr;
      transform: rotate(180deg);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    `,
  ],
  row: css`
    display: flex;
    align-items: center;
    flex: 1;
    min-height: 40px;
  `,
  name: css`
    flex: 0 0 40%;
    text-align: right;
    font-weight: 600;
    margin: 5px 0;
  `,
  timeline: css`
    flex: 0 0 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    position: relative;
  `,
  datetime: css`
    flex: 0 0 48%;
    text-align: left;
  `,
  date: css`
    display: inline-block;
    min-width: 90px;
  `,
  time: css`
    margin-left: 10px;
  `,
  bullet: css`
    width: 9px;
    height: 9px;
    border-radius: 50%;
    border: 3px solid ${Colors.Grey};

    &:after,
    &:before {
      content: '';
      display: block;
      width: 4px;
      height: 25px;
      background: ${Colors.Grey};
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
    }

    &:before {
      transform: translate(-50%, -100%);
    }

    &.first:before,
    &.last:after {
      height: 15px;
      border-radius: 2px;
    }
  `,
};
