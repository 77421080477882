/** @jsx jsx */
import { Fragment, Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { jsx } from '@emotion/core';
// redux
import { connect } from '../../../../../../utils/redux';
import { production, productionLoading } from '../../store/selectors';
import { getProductionReq, setProduction } from '../../store/actions';
import { producer } from '../../../Producers/store/selectors';
import { versionList } from '../store/selectors';
import { VersionsListState } from '../store/types';
import { getSupplierFunctionsReq } from '../../../SupplierFunctions/store/actions';
import { supplierFunctions } from '../../../SupplierFunctions/store/selectors';
import { getSuppliersFilteredReq, refreshSuppliersFiltered } from '../../../Suppliers/store/actions';
import { suppliersFiltered } from '../../../Suppliers/store/selectors';
import { getProductionsReq, updateProductionDaysReq, clearProduction } from '../../store/actions';
import { cloneProductionReq, clearSelectedActivities } from '../store/actions';
import { productions } from '../../store/selectors';
import { allCategoriesList } from '../../../Categories/store/selectors';
import { getAllCategoriesRequest } from '../../../Categories/store/actions';
import { getMachineriesReq, printMachineriesReq, downloadMachineriesReq, clearMachineriesData } from './store/actions';
import { machineries } from './store/selectors';
// types
import { MachineriesListState } from './store/types';
import { Action } from '../../../../../../store/types';
import { IProduction, ProductionListState } from '../../store/types';
import { IProducer } from '../../../Producers/store/types';
import { SupplierFunctionListState } from '../../../SupplierFunctions/store/types';
import { SupplierListState } from '../../../Suppliers/store/types';
import { CategoriesListState } from '../../../Categories/store/types';
// components
import Breadcrumbs from '../../../../../common/page/Breadcrumbs';
import Loader from '../../../../../common/loader/Loader';
import AsyncData from '../../../../../common/async/AsyncData';
import Widget from '../../../../../common/widget/Widget';
import PageHeader from '../../../../../common/page/PageHeader';
import DropdownMenu from '../../../../../common/popover/DropdownMenu';
import DropdownButton from '../../../../../common/popover/DropdownButton';
import PopoverBtn from '../../../../../common/popover/PopoverBtn';
import List from './List';
import ListHeader from './ListHeader';
// other
import routes from '../../../../../../routes';
// utils
import _ from 'lodash';
import { selectOneBy } from '../../../../../../utils/collection';
import * as utils from '../utils';
import moment from 'moment';
import withAcl, { IWrappedProps } from '../../../withAcl';
import { compose } from 'recompose';

interface MatchParams {
  id?: string;
}

interface IProps extends RouteComponentProps<MatchParams>, IWrappedProps {
  getProductionReq: Action<{}>;
  setProduction: Action<{}>;
  production: IProduction;
  productionLoading: Boolean;
  producer: IProducer;
  getSupplierFunctionsReq: Action<{}>;
  supplierFunctions: SupplierFunctionListState;
  getSuppliersFilteredReq: Action<{}>;
  refreshSuppliersFiltered: Action<{}>;
  suppliersFiltered: SupplierListState;
  cloneProductionReq: Action<{}>;
  productions: ProductionListState;
  clearProduction: Action<{}>;
  getProductionsReq: Action<{}>;
  updateProductionDaysReq: Action<{}>;
  clearSelectedActivities: Action<{}>;
  allCategoriesList: CategoriesListState;
  getAllCategoriesRequest: Action<{}>;
  machineries: MachineriesListState;
  getMachineriesReq: Action<{}>;
  clearMachineriesData: Action<{}>;
  printMachineriesReq: Action<{}>;
  downloadMachineriesReq: Action<{}>;
  versionList: VersionsListState;
}

interface IState {
  selectDay: any;
  isEditMode: boolean;
  editItemId: any;
}

jsx;
class ProductionMachinery extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectDay: null,
      isEditMode: false,
      editItemId: null,
    };
  }

  componentDidMount = () => {
    const {
      getProductionReq,
      clearProduction,
      refreshSuppliersFiltered,
      match: { params },
    } = this.props;
    clearProduction();
    getProductionReq(params.id, { fetchRelatedData: true });
    refreshSuppliersFiltered();
  };

  componentWillReceiveProps(nextProps: IProps) {
    const {
      getProductionReq,
      production,
      productionLoading,
      match: { params },
    } = nextProps;

    const isCurrentProduction = production && _.get(production, 'id') === Number(params.id);
    if (!isCurrentProduction && !productionLoading) {
      getProductionReq(params.id, { fetchRelatedData: true });
    }
  }

  componentWillUnmount() {
    this.props.clearMachineriesData();
  }

  print = () => {
    const { production, printMachineriesReq, test } = this.props;
    printMachineriesReq(production.id);
  };

  download = () => {
    const { production, downloadMachineriesReq, versionList, test } = this.props;

    const prodVersion =
      versionList && versionList.data
        ? _.get(selectOneBy(versionList.data, { 'production.id': production.id }), 'name')
        : '';

    const fileName = `${production.code} Machinery plates V${prodVersion}.${moment(Date.now()).format('YYMMDD')}.pdf`;
    test('machinery', 'P') && downloadMachineriesReq({ id: production.id, name: fileName });
  };

  setEditMode = (isEditMode: boolean) => {
    this.setState({ isEditMode });
  };

  setEditItemId = (editItemId: any) => {
    this.setState({ editItemId });
  };

  render() {
    const {
      production,
      supplierFunctions,
      suppliersFiltered,
      getSupplierFunctionsReq,
      getSuppliersFilteredReq,
      allCategoriesList,
      getAllCategoriesRequest,
      machineries,
      getMachineriesReq,
      test,
      location: { pathname },
    } = this.props;

    const { selectDay, isEditMode, editItemId } = this.state;

    if (_.every([production], Boolean)) {
      return (
        <Fragment>
          <AsyncData
            data={[
              { asyncData: supplierFunctions, asyncGetAction: getSupplierFunctionsReq },
              {
                asyncData: suppliersFiltered,
                asyncGetAction: (params: any) => {
                  const productionId = _.get(production, 'main.id', production.id);
                  return getSuppliersFilteredReq({
                    ...params,
                    addRelatedByProductionId: productionId,
                    size: 100,
                  });
                },
              },
              { asyncData: allCategoriesList, asyncGetAction: getAllCategoriesRequest },
              {
                asyncData: machineries,
                asyncGetAction: (params: any) => getMachineriesReq({ ...params, ownerId: production.id }),
              },
            ]}
          >
            {() => {
              const rangeRepresentation = utils.getRangeOfWorkDays(
                production.loadIn.from,
                production.loadOut.to,
                'day'
              );

              return (
                <Fragment>
                  <PageHeader title={'Machinery schedule'}>
                    <Breadcrumbs
                      pathname={pathname}
                      path={[
                        { title: 'Productions', link: routes.Productions },
                        {
                          title: production.name,
                          link: routes.Production.replace(':id', `${_.get(production, 'id')}`),
                        },
                        {
                          title: 'Machinery',
                          link: routes.ProductionMachinery.replace(':id', `${_.get(production, 'id')}`),
                        },
                      ]}
                    />
                    {test('machinery', 'P') && (
                      <PopoverBtn
                        btn={<DropdownButton />}
                        disableReposition
                        align="end"
                        renderContent={(closeDropdown) => (
                          <DropdownMenu
                            closeDropdown={closeDropdown}
                            actions={[{ label: 'Download machineries', handler: this.download }]}
                          />
                        )}
                      />
                    )}
                  </PageHeader>

                  <Widget>
                    <ListHeader
                      day={selectDay}
                      days={rangeRepresentation}
                      onBack={() => {
                        this.setState({ selectDay: null });
                      }}
                      onSelectDay={(selectDay: any) => {
                        this.setState({ selectDay });
                        this.setState({ editItemId: null });
                      }}
                      isEditMode={isEditMode}
                      onEditStart={() => this.setEditMode(true)}
                      onEditEnd={() => this.setEditMode(false)}
                    />
                    <List
                      onSelectDay={(selectDay: any) => {
                        this.setState({ selectDay });
                      }}
                      day={selectDay}
                      days={rangeRepresentation}
                      production={production}
                      isEditMode={isEditMode}
                      suppliersForSelect={suppliersFiltered}
                      allCategoriesList={allCategoriesList}
                      machineries={Object.values(machineries.data)}
                      onEditStart={(data: any) => {
                        this.setEditItemId(data.id);
                      }}
                      onEditEnd={() => {
                        this.setEditItemId(null);
                      }}
                      editItemId={editItemId}
                      params={machineries.params}
                    />
                  </Widget>
                </Fragment>
              );
            }}
          </AsyncData>
        </Fragment>
      );
    } else {
      return <Loader isLoading />;
    }
  }
}

export default compose<any, {}>(
  connect(
    {
      production,
      productionLoading,
      producer,
      supplierFunctions,
      suppliersFiltered,
      productions,
      allCategoriesList,
      machineries,
      versionList,
    },
    {
      getProductionReq,
      clearProduction,
      setProduction,
      getProductionsReq,
      updateProductionDaysReq,
      getSupplierFunctionsReq,
      refreshSuppliersFiltered,
      getSuppliersFilteredReq,
      cloneProductionReq,
      clearSelectedActivities,
      getAllCategoriesRequest,
      getMachineriesReq,
      printMachineriesReq,
      downloadMachineriesReq,
      clearMachineriesData,
    }
  ),
  withAcl
)(ProductionMachinery);
