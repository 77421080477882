/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactElement, FunctionComponent } from 'react';
import Popover, { ArrowContainer, PopoverProps } from 'react-tiny-popover';
import { Omit } from '../../../utils/typeHandlers';

import * as styles from './styles';

interface IProps extends Omit<PopoverProps, 'content'> {
  fixedWidth?: number;
  autoHeight?: boolean;
  tabIndex?: number;
  isVisibilityHight?: boolean;
}

jsx;
const PopoverFC: FunctionComponent<IProps> = ({
  children,
  isOpen,
  onClickOutside,
  contentLocation,
  fixedWidth = 'auto',
  autoHeight = false,
  disableReposition,
  align,
  isVisibilityHight = true,
}): ReactElement<IProps> => {
  return (
    <Popover
      containerStyle={isVisibilityHight ? styles.container : styles.containerLowVisibility}
      isOpen={isOpen}
      position={['bottom', 'top', 'right', 'left']}
      align={align}
      padding={7}
      disableReposition={disableReposition}
      contentLocation={contentLocation}
      onClickOutside={onClickOutside}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowColor={'white'}
          arrowSize={7}
          arrowStyle={styles.arrow}
        >
          <div css={styles.contentBox(fixedWidth, autoHeight)}>{children}</div>
        </ArrowContainer>
      )}
    >
      <span />
    </Popover>
  );
};

export default PopoverFC;
