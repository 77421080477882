import si, { ImmutableObject } from 'seamless-immutable';
import * as ru from '../../../../../../../utils/redux';
import * as r from '../../../../../../../utils/reducers';

import { getItemState, getListState, ListStatuses } from '../../../../../../../utils/store/state/state';
import { GatesListState, GateItemState } from './types';

import * as actions from './actions';

interface IState {
  list: GatesListState;
  item: GateItemState;
}

const INITIAL_STATE: ImmutableObject<IState> = si({
  list: getListState({
    page: 0,
    size: 12,
  }),
  item: getItemState({ id: 0 }),
});

const listReducer = r.createReducer('list');
const itemReducer = r.createReducer('item');

export default ru.processActions(
  () => ({
    [actions.CLEAR_GATES]: listReducer(r.resetReducer),
    [actions.GET_GATES_REQ]: listReducer(r.loadingReducer),
    [actions.GET_GATES_REQ_SUCCESS]: (store: ImmutableObject<IState>, action: any) => {
      const {
        payload: { data, params },
        meta,
      } = action;
      return store
        .setIn(['list', 'data'], params.page > 0 ? store.list.data.concat(data) : data)
        .setIn(['list', 'status'], ListStatuses.Actual)
        .setIn(['list', 'meta'], meta)
        .setIn(['list', 'params'], params);
    },
    [actions.GET_GATES_REQ_ERROR]: listReducer(r.errorReducer),

    [actions.GET_GATE_REQ]: itemReducer(r.loadingReducer),
    [actions.GET_GATE_REQ_SUCCESS]: itemReducer(r.successReducer),
    [actions.GET_GATE_REQ_ERROR]: itemReducer(r.errorReducer),
    [actions.SET_GATE]: (store: ImmutableObject<IState>, action: any) => {
      const {
        payload: { data },
      } = action;
      return store.setIn(['item', 'data'], data[0]).setIn(['item', 'status'], ListStatuses.Actual);
    },
    [actions.CLEAR_GATE]: itemReducer(r.clearData),

    [actions.CREATE_GATE_REQ]: listReducer(r.loadingReducer),
    [actions.CREATE_GATE_REQ_SUCCESS]: listReducer(r.successReducer),
    [actions.CREATE_GATE_REQ_ERROR]: listReducer(r.errorReducer),
    [actions.CREATE_GATE]: listReducer(r.addDataReducer('id')),

    [actions.UPDATE_GATE_REQ]: listReducer(r.loadingReducer),
    [actions.UPDATE_GATE_REQ_SUCCESS]: listReducer(r.successReducer),
    [actions.UPDATE_GATE_REQ_ERROR]: listReducer(r.errorReducer),
    [actions.UPDATE_GATE]: listReducer(r.updateDataReducer('id')),

    [actions.DELETE_GATE_REQ]: listReducer(r.loadingReducer),
    [actions.DELETE_GATE_REQ_SUCCESS]: listReducer(r.successReducer),
    [actions.DELETE_GATE_REQ_ERROR]: listReducer(r.errorReducer),
    [actions.DELETE_GATE]: listReducer(r.deleteDataReducer('id')),
  }),
  INITIAL_STATE
);
