/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { withFormik, Form, FormikProps } from 'formik';
// redux
import { compose } from 'recompose';
// actions, types
import { IProduction, ProductionListState, ActivityTypes } from '../store/types';
import { Action } from '../../../../../store/types';
// components
import modal from '../../../../common/modal';
import FieldsSet from '../../../../common/form/FieldsSet';
import FormBlock from '../../../../common/form/FormBlock';
import FormRow from '../../../../common/form/FormRow';
import FormError from '../../../../common/form/FormError';
import Checkbox from '../../../../common/form/Checkbox';
import PrimaryBtn from '../../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../../common/buttons/OutlineBtn';
import Datepicker from '../../../../common/form/datepicker/Datepicker';
import Select from '../../../../common/form/select/Select';
import Option from '../../../../common/form/select/Option';
// utils
import _ from 'lodash';
import * as yup from 'yup';
import moment from 'moment';
import FieldSet from '../../../../common/form/FieldsSet';

interface FormValues {
  showFrom: string | number;
  cloneActivities: boolean;
  cloneSuppliers: boolean;
  //cloneFunctions: boolean;
  //cloneRemarks: boolean;
  cloneMachineries?: boolean;
  cloneGates?: boolean;
  cloneCallsheet?: boolean;
  cloneTransports?: boolean;
  main?: number;
}

interface OtherProps {
  onClose: () => void;
  cloneProduction: Action<{}>;
  production: IProduction;
  productions?: ProductionListState;
}

jsx;
const CloneProductionForm = ({
  onClose,
  production,
  productions,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  values,
}: OtherProps & FormikProps<FormValues>) => {
  const { Modal, ModalHeader, ModalFooter, ModalBody, ModalActions } = modal;

  const showDateOnChange = (value: number) => setFieldValue('showFrom', value);
  const showDateOnBlur = () => setFieldTouched('showFrom', true);

  const onProductionChange = (value: number | number[]) => {
    setFieldValue('main', value);
  };

  const { isMain } = production;

  const productionsList = _.get(productions, 'data', []);
  const productionsOptions = productionsList.filter((prod) => prod.isMain);

  return (
    <div>
      <Modal isOpen={true}>
        <Form>
          <ModalHeader>Clone production</ModalHeader>
          <ModalBody>
            {/* show_date */}

            <FieldSet width={2}>
              <FormRow>
                <label htmlFor="showFrom">Show start date</label>
                <Datepicker value={values.showFrom} onChange={showDateOnChange} onBlur={showDateOnBlur} />
                <FormError name="showFrom" errors={errors} touched={touched} />
              </FormRow>
            </FieldSet>

            <FormBlock>
              <FieldSet width={2}>
                {/* cloneActivities */}
                <FormRow>
                  <label>
                    <Checkbox
                      name="cloneActivities"
                      checked={values.cloneActivities}
                      onChange={(e: any) => {
                        const { checked } = e.currentTarget;
                        if (
                          (!isMain && !checked) || 
                          !checked && !values.cloneMachineries && !values.cloneCallsheet
                        ) {
                          setFieldValue('cloneSuppliers', false);
                        }
                        setFieldValue('cloneActivities', checked);
                      }}
                    />
                    <span style={{ marginLeft: '5px' }}>Clone activities</span>
                  </label>
                  <FormError name="cloneActivities" errors={errors} touched={touched} />
                </FormRow>
                {/* cloneSuppliers */}
                <FormRow>
                  <label>
                    <Checkbox
                      name="cloneSuppliers"
                      checked={values.cloneSuppliers}
                      onChange={(e: any) => {
                        const { checked } = e.currentTarget;
                        const ifActivities = checked && values.cloneActivities;
                        const ifCallsheet = checked && values.cloneCallsheet;
                        const ifMachineries = checked && values.cloneMachineries;
                        const condChecked = isMain ? ifActivities || ifCallsheet || ifMachineries : ifActivities;
                        setFieldValue('cloneSuppliers', condChecked);
                      }}
                    />
                    <span style={{ marginLeft: '5px' }}>Clone suppliers</span>
                  </label>
                  <FormError name="cloneSuppliers" errors={errors} touched={touched} />
                </FormRow>
                {/* cloneFunctions */}
                {/*<FormRow>
                  <label>
                    <Checkbox name="cloneFunctions" checked={values.cloneFunctions} onChange={handleChange} />
                    <span style={{ marginLeft: '5px' }}>Clone functions</span>
                  </label>
                  <FormError name="cloneFunctions" errors={errors} touched={touched} />
                </FormRow>*/}
                {/* cloneMachineries */}
                {isMain && (
                  <FormRow>
                    <label>
                      <Checkbox name="cloneMachineries" checked={values.cloneMachineries} onChange={(e: any) => {
                        const { checked } = e.currentTarget;
                        if (!checked && !values.cloneActivities && !values.cloneCallsheet) {
                          setFieldValue('cloneSuppliers', false);
                        }
                        setFieldValue('cloneMachineries', checked);
                      }} />
                      <span style={{ marginLeft: '5px' }}>Clone machineries</span>
                    </label>
                    <FormError name="cloneMachineries" errors={errors} touched={touched} />
                  </FormRow>
                )}
              </FieldSet>
              <FieldSet width={2}>
                {/*cloneCallsheet*/}
                {isMain && (
                  <FormRow>
                    <label>
                      <Checkbox name="cloneCallsheet" checked={values.cloneCallsheet} onChange={(e: any) => {
                        const { checked } = e.currentTarget;
                        if (!checked && !values.cloneActivities && !values.cloneMachineries) {
                          setFieldValue('cloneSuppliers', false);
                        }
                        setFieldValue('cloneCallsheet', checked);
                      }} />
                      <span style={{ marginLeft: '5px' }}>Clone callsheet</span>
                    </label>
                    <FormError name="cloneCallsheet" errors={errors} touched={touched} />
                  </FormRow>
                )}

                {/*cloneGates*/}

                {isMain && (
                  <FormRow>
                    <label>
                      <Checkbox
                        name="cloneGates"
                        checked={values.cloneGates}
                        onChange={(e: any) => {
                          const { checked } = e.currentTarget;
                          setFieldValue('cloneGates', !checked && values.cloneTransports ? true : checked);
                        }}
                      />
                      <span style={{ marginLeft: '5px' }}>Clone gates</span>
                    </label>
                    <FormError name="cloneGates" errors={errors} touched={touched} />
                  </FormRow>
                )}

                {/*cloneTransports*/}
                {isMain && (
                  <FormRow>
                    <label>
                      <Checkbox
                        name="cloneTransports"
                        checked={values.cloneTransports}
                        onChange={(e: any) => {
                          const { checked } = e.currentTarget;
                          if (checked) {
                            setFieldValue('cloneGates', true);
                          }
                          setFieldValue('cloneTransports', checked);
                        }}
                      />
                      <span style={{ marginLeft: '5px' }}>Clone transports</span>
                    </label>
                    <FormError name="cloneTransports" errors={errors} touched={touched} />
                  </FormRow>
                )}
              </FieldSet>
            </FormBlock>

            {/* option */}
            {production.main && productionsOptions && (
              <FieldSet width={4}>
                <FormRow>
                  <Select
                    items={[{ name: 'Select main production', id: null }, ...productionsOptions]}
                    scheme={{ id: 'id', title: 'name' }}
                    selected={values.main || null}
                    unselectedMsg="Select main production"
                    onChange={onProductionChange}
                  >
                    {(item: any, selected: boolean) => {
                      return <Option title={item.name} selected={selected} />;
                    }}
                  </Select>
                  <FormError name="main" errors={errors} touched={touched} />
                </FormRow>
              </FieldSet>
            )}
          </ModalBody>
          <ModalFooter>
            <ModalActions css={style.modalActions}>
              <OutlineBtn type="button" onClick={onClose} title="Cancel" />
              <PrimaryBtn type="submit" title="Clone" />
            </ModalActions>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

const validationSchema = yup.object({}).shape({
  showFrom: yup.string().required('Show date is required'),
  cloneSuppliers: yup.boolean(),
});

export default compose<OtherProps & FormikProps<FormValues>, OtherProps>(
  withFormik<OtherProps, FormValues>({
    enableReinitialize: true,
    mapPropsToValues: ({ production }) => {
      const fromDate = _.get(_.find(production.mainActivities, { type: ActivityTypes.SHOW }), 'range.from');

      const commonFields = {
        showFrom: Number(new Date(fromDate)),
        cloneActivities: true,
        cloneSuppliers: true,
      };

      const mainFields = {
        //cloneFunctions: true,
        //cloneRemarks: true,
        cloneMachineries: true,
        cloneGates: true,
        cloneCallsheet: true,
        cloneTransports: true,
      };

      return production.isMain ? { ...commonFields, ...mainFields } : commonFields;
    },

    validationSchema,
    handleSubmit: (values, { props }) => {
      values = values.main ? values : _.omit(values, ['main']);
      values = { ...values, showFrom: moment.utc(values.showFrom).valueOf() };
      props.cloneProduction({
        id: props.production.id,
        data: values,
      });
    },
    displayName: 'CloneProductionForm',
  })
)(CloneProductionForm);

const style = {
  modalActions: css`
    text-align: right;
  `,
};
