/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import * as styles from '../../../styles/styles';
import removeBtn from './images/removeBtn.svg';

export const formBox = css({
  backgroundColor: styles.Colors.White,
  boxShadow: '2px 2px 7px rgba(150, 150, 150, 0.25)',
});

export const fieldsArea = css({
  padding: '0 10px',
  backgroundColor: styles.Colors.White,
});

export const resetInput = css({
  border: '0',
  outline: 'none',
  backgroundColor: styles.Colors.White,
});

export const inputPlaceholder = [
  styles.InputText,
  css({
    color: styles.Colors.Grey,
  }),
];

export const autocomplete = [
  css({
    WebkitTextFillColor: styles.Colors.RegularBlack,
    WebkitBoxShadow: `0 0 0px 1000px ${styles.Colors.InsetBoxShadow} inset`,
    transition: 'background-color 5000s ease-in-out 0s',
  }),
];

export const autofill = css({
  '&:-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus': [autocomplete],
});

export const formInput = [
  resetInput,
  autofill,
  styles.InputText,
  css({
    boxSizing: 'border-box',
    padding: '0 13px',
    lineHeight: '36px',
    width: '100%',
    border: `1px solid ${styles.Colors.Grey}`,

    ':hover,:focus': resetInput,
    ':placeholder': inputPlaceholder,

    ':hover, :focus-within': css({
      border: `1px solid ${styles.Colors.Green}`,
    }),

    ':disabled, :disabled:hover': {
      opacity: 0.5,
      border: `1px solid ${styles.Colors.Grey}`,
    },
  }),
];

export const formFieldBox = css({
  '& input': formInput,
  '& textarea': [
    formInput,
    css({
      lineHeight: '1.5',
      padding: '10px',
    }),
  ],
});

export const formFileBox = css({
  position: 'relative',
  '& input': {
    cursor: 'pointer',
    position: 'absolute',
    padding: '10px',
    left: '0px',
    top: '0px',
    width: '100%',
    boxSizing: 'border-box',
    opacity: 0,
    zIndex: 1,
  },
});

export const thumbImg = css({
  width: '150px',
  height: '150px',
  backgroundSize: 'cover;',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  margin: '10px auto',
});

export const fileUploadBox = css({
  width: '150px',
  margin: 'auto',
});

export const formRadioBox = [
  styles.MainText,
  css({
    height: '30px',
    marginRight: '20px',
    '& input': {
      margin: '5px',
    },
    // 'input[type="radio"]': {
    //   accentColor: 'green',
    // },
  }),
];
export const RadioBox = [
  styles.MainText,
  css({
    height: '30px',
    marginRight: '20px',
    '& input': {
      margin: '5px',
      cursor: 'pointer',
      accentColor: styles.Colors.Green,
    },
    '& input:focus-within': css({
      outline: 'none',
      WebkitFilter: `drop-shadow(3px 4px 2px #48671C)`,
    }),
  }),
];
export const formLabel = [
  styles.MainText,
  css({
    lineHeight: '18px',
    width: '100%',
    paddingBottom: '8px',
    display: 'block',
    color: styles.Colors.RegularBlack,
    fontFamily: styles.FontFamilies.Main,
  }),
];

export const formRow = css({
  position: 'relative',
  padding: '30px 0 0 0',
  width: '100%',
  '& label': formLabel,
  '&:first-of-type': {
    paddingTop: '0px',
  },
  '& .form-row-section': {
    paddingRight: '20px',
    boxSizing: 'border-box',
  },
  '& .form-row-section:first-of-type': {
    // paddingLeft: '0px',
    //boxSizing: 'border-box',
  },
  '& .form-row-section:last-child': {
    paddingRight: '0px',
    //boxSizing: 'border-box',
  },
});

export const formRowSections = (width: number) =>
  css({
    width: `${width}%`,
    margin: 0,
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
  });

export const actionsRow = css({});

export const errorMessage = [
  css({
    position: 'absolute',
    top: '100%',
    left: '0',
    lineHeight: '20px',
  }),
];

export const errorText = [
  styles.ScheduleText,
  css({
    opacity: 0,
    transition: 'opacity 0.3s ease',
    color: styles.Colors.ThemeRed,
  }),
];

export const errorTextVisible = [
  css({
    opacity: 1,
  }),
];

export const fieldsSet = (width: number, direction = 'vertical') => {
  if (direction === 'vertical') {
    return css({
      flexGrow: 1,
      maxWidth: `${width}%`,
      padding: '0 20px',
    });
  } else {
    return css({
      display: 'flex',
      flexWrap: 'nowrap',
      maxWidth: `${width}%`,
      padding: '0 20px',
    });
  }
};

export const formBlock = css({
  display: 'flex',
  paddingTop: '20px',
  paddingBottom: '20px',
  flexWrap: 'wrap',
});

export const actionsRowBox = css({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0 10px',
  height: '65px',
  borderTop: `1px solid ${styles.Colors.Grey}`,
});

export const actionsRowItem = css({
  paddingRight: '20px',
});

export const dropzoneBox = [
  styles.H3,
  css({
    cursor: 'pointer',
    height: '150px',
    width: '100%',
    border: `2px dashed ${styles.Colors.AltGrey}`,
    borderRadius: '2px',
    padding: '30px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: styles.Colors.White,
    '&:hover': {
      backgroundColor: styles.Colors.HoveredWhite,
    },
  }),
];

export const fileFormatsInfo = [
  styles.MainText,
  css({
    color: styles.Colors.DarkGrey,
  }),
];

export const removeBox = css({
  width: 'auto',
  position: 'relative',
});

export const checkboxInput = css({
  opacity: 1,
  '&:checked': {
    outlineColor: styles.Colors.Green,
    WebkitBoxShadow: `0 0 0px 1000px ${styles.Colors.LightGreen} inset`,
  },
  '&:focus': {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: styles.Colors.Green,
    outline: `1px solid ${styles.Colors.Green}`,
  },
});
export const removeBoxBtn = css({
  height: '25px',
  width: '25px',
  background: `url(${removeBtn}) 50% 50% no-repeat`,
  position: 'absolute',
  top: '-25px',
  right: '-25px',
  cursor: 'pointer',
});

export const checkbox = css({
  '&:focus': {
    outlineColor: styles.Colors.Green,
    WebkitBoxShadow: `0 0 0px 1000px ${styles.Colors.LightGreenGrey} inset`,
  },
});
