import _ from 'lodash';
import * as func from './func';
import { handleActions } from 'redux-actions';
import { connect as reduxConnect } from 'react-redux';

export const PAYLOAD_KEY = 'payload';

export const STATUS_FIELD = 'status';
export const DATA_FIELD = 'data';
export const ERROR_FIELD = 'error';
export const META_FIELD = 'meta';
export const PARAMS_FIELD = 'params';

export const STATUS_NEW = 'new';
export const STATUS_EMPTY = 'empty';
export const STATUS_OLD = 'old';
export const STATUS_REQUEST = 'request';
export const STATUS_ERROR = 'error';
export const STATUS_SUCCESS = 'success';
export const STATUS_ACTUAL = 'actual';
export const STATUS_UPDATED = 'updated';
export const STATUS_DELETED = 'deleted';
export const STATUS_CREATED = 'created';

export const PARAMS_PAGE = 'page';
export const PARAMS_SEARCH = 'search';
export const PARAMS_OFFSET = 'offset';
export const PARAMS_FILTER = 'filter';

export const statusChecker = func.makeChecker();

export const isOld = func.makeChecker(STATUS_OLD);
export const isNew = func.makeChecker(STATUS_NEW);
export const isEmpty = func.makeChecker(STATUS_EMPTY);
export const isRequest = func.makeChecker(STATUS_REQUEST);
export const isError = func.makeChecker(STATUS_ERROR);
export const isActual = func.makeChecker(STATUS_ACTUAL);
export const isUpdated = func.makeChecker(STATUS_UPDATED);
export const isDeleted = func.makeChecker(STATUS_DELETED);
export const isCreated = func.makeChecker(STATUS_CREATED);

export const getPayloadField = (action, fieldPath) => {
  return _.get(getPayload(action), fieldPath);
};

export const getPayload = (action) => {
  return _.get(action, PAYLOAD_KEY);
};

export const selector = (path) => (state, ownProps) => {
  return _.isFunction(path) ? path(state, ownProps) : _.get(state, path);
};

export const nsSelector = (nameSpace) => (path) => {
  return selector(_.compact([nameSpace, path]));
};

export const mapDispatch = (actions) => (dispatch) => {
  return {
    ..._.mapValues(actions, (action) => (payload, ...params) => {
      const actionBody = action(payload, ...params);
      return _.isFunction(actionBody) ? actionBody(dispatch) : dispatch(actionBody);
    }),
    dispatch,
  };
};

export const mapState = (map) => (state, ownProps) => {
  return _.mapValues(map, (path) => selector(path)(state, ownProps));
};

export const connect = (props, dispatch) => {
  return reduxConnect(mapState(props), mapDispatch(dispatch));
};

export const processActions = (actionMap, initialState) => (store, action) => {
  return handleActions(actionMap(), initialState)(store, action);
};
