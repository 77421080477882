/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import widget from '../../../../../common/widget';
import { IProduction } from '../../store/types';

export interface IProps {
  production: IProduction;
}

jsx;

export const ProductionInfoWidget: FunctionComponent<IProps> = ({ children, production }): ReactElement<IProps> => {
  const { Widget, WidgetHeader, WidgetKeyValueRow } = widget;

  return (
    <Widget>
      <WidgetHeader title="Production information" />
      <WidgetKeyValueRow title="Name:">{production.name}</WidgetKeyValueRow>
      <WidgetKeyValueRow title="Syntax:">{production.code}</WidgetKeyValueRow>
      <WidgetKeyValueRow title="Location:">{production.location}</WidgetKeyValueRow>
    </Widget>
  );
};

export default ProductionInfoWidget;
