import si, { ImmutableObject } from 'seamless-immutable';
import * as ru from '../../../../../../utils/redux';
import { IClientAgent, ClientAgentListState } from './types';
import * as actions from './actions';
import { getListState, getItemState, ListStatuses } from '../../../../../../utils/store/state/state';
import * as config from '../../../../../../config';
import * as r from '../../../../../../utils/reducers';
import _ from 'lodash';

interface IState {
  list: ClientAgentListState;
  item: IClientAgent | null;
  itemLoading: boolean;
  itemError: string;
}

const INITIAL_STATE: ImmutableObject<IState> = si({
  // AGENTS LIST
  list: getListState({
    page: 0,
    size: config.GRID_ROW_PAER_PAGE_DEFAULT,
    ownerId: null,
  }),
  // ITEM
  item: null,
  itemLoading: false,
  itemError: '',
  // INVITATION
  invitationLoading: false,
  invitationError: '',
});

const listReducer = r.createReducer('list');

export default ru.processActions(
  () => ({
    // LIST
    [actions.GET_CLIENT_AGENTS_REQ]: listReducer(r.loadingReducer),
    [actions.GET_CLIENT_AGENTS_REQ_SUCCESS]: (store: ImmutableObject<IState>, action: any) => {
      const {
        payload: { data, params },
        meta,
      } = action;

      return store
        .setIn(['list', 'data'], params.page > 0 ? store.list.data.concat(data) : data)
        .setIn(['list', 'status'], ListStatuses.Actual)
        .setIn(['list', 'meta'], meta)
        .setIn(['list', 'params'], params);
    },
    [actions.GET_CLIENT_AGENTS_REQ_ERROR]: listReducer(r.errorReducer),
    [actions.SET_CLIENT_AGENTS]: listReducer(r.listDataReducer),
    [actions.REFRESH_CLIENT_AGENTS]: listReducer(r.refreshReducer),
    [actions.SET_CLIENT_AGENTS_PARAMS]: listReducer(r.paramsReducer),
    [actions.CLEAR_CLIENT_AGENTS]: listReducer(r.resetReducer),

    // GET CLIENT_AGENT
    [actions.SET_CLIENT_AGENT]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('item', _.head(action.payload));
    },

    [actions.CLEAR_CLIENT_AGENT]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('item', null);
    },

    // UPDATE CLIENT_AGENT
    [actions.UPDATE_CLIENT_AGENT]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('item', _.head(action.payload));
    },

    // CREATE CLIENT AGENT
    [actions.ADD_CLIENT_AGENT]: (store: ImmutableObject<IState>, action: any) => {
      return store.setIn(['list', 'data'], store.list.data.concat(action.payload));
    },

    // DELETE CLIENT_AGENT
    [actions.DELETE_CLIENT_AGENT_REQ_SUCCESS]: (store: ImmutableObject<IState>, { payload }: any) => {
      return store.setIn(['list', 'data'], store.list.data.filter((agent) => agent.email !== payload.email));
    },

    // SEND_CLIENT_AGENT_INVITATION
    [actions.SEND_CLIENT_AGENT_INVITATION_REQ]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('invitationLoading', true);
    },
    [actions.SEND_CLIENT_AGENT_INVITATION_SUCCESS]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('invitationLoading', false);
    },
    [actions.SEND_CLIENT_AGENT_INVITATION_ERROR]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('invitationLoading', false).set('invitationError', action.payload);
    },
  }),
  INITIAL_STATE
);
