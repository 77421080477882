import { css } from '@emotion/core';
import { Colors } from '../styles';

export const toastify = css`
  .Toastify__toast {
    font-family: inherit !important;

    .Toastify__toast-body {
      font-size: 14px;
      padding-left: 5px;
      white-space: pre-wrap;
    }
  }

  .Toastify__toast--success {
    background-color: ${Colors.ToastSuccess};
  }

  .Toastify__toast--info {
    background-color: ${Colors.ToastInfo};
  }

  .Toastify__toast--warning {
    background-color: ${Colors.ToastWarn};
  }

  .Toastify__toast--error {
    background-color: ${Colors.ToastError};
  }
`;

export const reactHint = css`
  .react-hint {
    font-family: inherit !important;
    font-size: 13px;

    .react-hint__content {
      background-color: rgba(0, 0, 0, 0.75);
    }
  }
`;
