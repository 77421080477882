/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import ReactPaginate from 'react-paginate';
import { GRID_ROW_PAER_PAGE_DEFAULT } from '../../../config';

import * as styles from './styles';
import * as React from 'react';

export interface IProps {
  offset?: number;
  page?: number;
  total: number;
  onPageChange?(selectedItem: { selected: number }): void;
}

jsx;

export const Paginator: FunctionComponent<IProps> = (
  { total, offset = GRID_ROW_PAER_PAGE_DEFAULT, page = 0, onPageChange },
  ...props
): ReactElement<IProps> => {
  const pageCount = Math.ceil(total / offset);
  return (
    <div css={styles.paginator}>
      <ReactPaginate
        forcePage={page}
        pageCount={pageCount}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        onPageChange={onPageChange}
        {...props}
      />
    </div>
  );
};

export default Paginator;
