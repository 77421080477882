/** @jsx jsx */
import { jsx } from '@emotion/core';
import { compose, withHandlers } from 'recompose';
// redux
import { logout } from '../../../store/actions';
import { Action } from '../../../store/types';
// utils
import { clearToken } from '../../../utils/auth';
import { connect } from '../../../utils/redux';

jsx;

interface IProp {
  logout: Action<{}>;
}

export const enhance = withHandlers<IProp, any>({
  logOut: ({ logout }: IProp) => (event: any) => {
    clearToken();
    logout();
    event.preventDefault();
  },
});

export const storeConnect = connect(
  null,
  { logout }
);

export default compose(
  storeConnect,
  enhance
);
