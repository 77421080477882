/** @jsx jsx */
import { jsx } from '@emotion/core';
import moment from 'moment';
import _ from 'lodash';
import si from 'seamless-immutable';
import * as utils from '../../utils';
import { extractKeyValue } from '../../../../../../../utils/collection';

import { Component, createRef, Fragment, SyntheticEvent } from 'react';
import TimelineValuePart from './TimelineValuePart';

import * as styles from './styles';

import * as array from '../../../../../../../utils/array';
import { correctRange } from './range';
import { limitRange } from '../../utils';

export interface IProps {
  machinery: any;
  users: any;
  isEdit?: boolean;
  onChange?: (id: any, range: any) => void;
  timeRound?: number;
  boxRange: any;
  limitRange: any;
}

export interface IState {}

jsx;
class TimelineValue extends Component<IProps, IState> {
  private timelineValueBox: any;

  constructor(props: IProps) {
    super(props);
    this.timelineValueBox = createRef();
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.windowResize);
    this.forceUpdate();
  };

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {}

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.windowResize);
  }

  windowResize = (e: any) => {
    this.forceUpdate();
  };

  splitByPeriods = (users: any, boxRange: any) => {
    const userResolver = (currentUser: any) => (resolveUser: any) => {
      return currentUser.id === resolveUser.id;
    };

    const result = users.reduce((result: any, user: any, index: any) => {
      const prevUser = array.prev(users, userResolver(user), false);
      const nextUser = array.next(users, userResolver(user), false);

      const from = _.get(prevUser, 'range.to', boxRange.from);
      const to = _.get(nextUser, 'range.from', boxRange.to);
      const period = { from, to };
      return [...result, { user, period }];
    }, []);

    return result;
  };

  excludeUserById = (users: any[], id: any) => {
    return users.filter((user: any) => {
      return user.id !== id;
    });
  };

  changeTimeline(id: any, range: any) {
    const { onChange = _.identity, users, limitRange } = this.props;
    const currentUser = _.find(users, { id });
    const otherUsers = this.excludeUserById(users, id);
    const otherUserRanges = extractKeyValue(otherUsers, 'range');

    const isIntersection = utils.checkRangesIntersection([...otherUserRanges, range]);
    range = isIntersection ? correctRange(range, otherUserRanges, limitRange) : range;
    onChange(id, range);
  }

  render() {
    const { isEdit = false, users, boxRange, limitRange, timeRound = 30, machinery } = this.props;
    const lineParts = this.splitByPeriods([...users], boxRange);

    return (
      <div css={styles.timelineValueBox} ref={this.timelineValueBox}>
        {lineParts.map((item: any) => {
          return (
            <TimelineValuePart
              machinery={machinery}
              key={item.user.id}
              data={item}
              isEdit={isEdit}
              boxRange={boxRange}
              limitRange={limitRange}
              timelineBoxEl={this.timelineValueBox.current}
              timeRound={timeRound}
              onChange={(value: any) => {
                this.changeTimeline(item.user.id, value);
              }}
            />
          );
        })}
      </div>
    );
  }
}

export default TimelineValue;
