/** @jsx jsx */
import { jsx } from '@emotion/core';
import { MouseEvent, Fragment, FunctionComponent, ReactElement } from 'react';

import Row from '../../popover/Row';
import { Link, LinkProps } from 'react-router-dom';

import * as styles from './styles';

export interface IProps extends LinkProps {
  title: string;
}

jsx;

export const Delete: FunctionComponent<IProps> = ({ title, ...props }): ReactElement<IProps> => {
  return (
    <div css={styles.actionText}>
      <Link {...props}>
        <Row>{title}</Row>
      </Link>
    </div>
  );
};

export default Delete;
