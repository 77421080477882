/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import * as styles from '../../../../styles/styles';
import done from './images/done.svg';
import arrow from './images/arrow.svg';

export const optionBase = [
  styles.MainText,
  css({
    whiteSpace: 'nowrap',
    lineHeight: '40px',
    padding: '0 40px 0 13px',
    position: 'relative',
    cursor: 'pointer',
    '& :hover, :focus': {
      backgroundColor: styles.Colors.HoveredWhite,
    },
  }),
];

export const selectOption = [
  styles.MainText,
  css({
    whiteSpace: 'nowrap',
    lineHeight: '40px',
    position: 'relative',
    cursor: 'pointer',
    ':hover, :focus-within, :focus': css({
      border: `1px solid ${styles.Colors.Green}`,
      backgroundColor: `${styles.Colors.HoveredWhite}`,
      outline: 'none',
    }),
  }),
];
export const searchText = [
  styles.MainText,
  css({
    fontWeight: 400,
    fontSize: '13px',
    color: '#B1B1B1',
    textAlign: 'left',
    paddingTop: '18px',
    paddingBottom: '10px',
    paddingRight: '10px',
    paddingLeft: '12px',
    border: 'none',
    outline: 'none',
    ':hover, :focus-within, :focus': css({
      border: 'none',
      outline: 'none',
    }),
  }),
];

export const optionSelected = css({
  backgroundColor: styles.Colors.HoveredWhite,
  '&:after': {
    content: '""',
    position: 'absolute',
    height: '40px',
    width: '36px',
    right: '0px',
    backgroundColor: styles.Colors.HoveredWhite,
    backgroundImage: `url( ${done} )`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '45%',
  },
});

export const option = (selected: boolean) => {
  return selected ? [optionBase, optionSelected] : optionBase;
};

export const header = (disable: boolean = false) => {
  const disableStyle = disable
    ? css({
        cursor: 'inherit',
        opacity: 0.5,
        ':hover, :focus-within': css({
          border: `1px solid ${styles.Colors.Green}`,
          outline: 'none',
        }),
      })
    : {};

  return [
    styles.InputText,
    css({
      lineHeight: '36px',
      width: '100%',
      boxSizing: 'border-box',
      position: 'relative',
      whiteSpace: 'nowrap',
      // display: 'flex',
      // justifyContent: 'space-between',
      cursor: 'pointer',
      backgroundColor: styles.Colors.White,
      border: `1px solid ${styles.Colors.Grey}`,
      ':hover, :focus-within': css({
        border: `1px solid ${styles.Colors.Green}`,
        outline: 'none',
      }),
    }),
    disableStyle,
  ];
};

export const input = css({
  padding: '8px 0 8px 13px',
  display: 'flex',
  backgroundColor: styles.Colors.LightGrey,
});

export const apply = css({
  height: '38px',
  width: '40px',
  backgroundImage: `url( ${done} )`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '45%',
  cursor: 'pointer',
});

export const headerText = css({
  minWidth: '100px',
  maxWidth: 'calc(100% - 40px)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
  paddingLeft: '13px',
});

export const headerArrow = (isOpen: boolean) =>
  css({
    position: 'absolute',
    right: '0',
    top: '0',
    bottom: '0',
    width: '36px',
    backgroundImage: `url( ${arrow} )`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '15px',
    transform: `rotate( ${isOpen ? 180 : 0}deg )`,
  });

export const popoverBox = css({
  height: 0,
  width: 0,
  margin: 'auto',
});
export const tabItem = (isActive: boolean) =>
  css({
    lineHeight: '30px',
    fontSize: '13px',
    fontWeight: 400,
    paddingLeft: '18px',
    paddingRight: '18px',
    paddingTop: '8px',
    paddingBottom: '12px',
    borderBottom: isActive ? '1px solid #48671C' : '1px solid #e6e6e6',
    color: isActive ? '#48671C' : '#505050',
    ':focus': {
      backgroundColor: styles.Colors.AltGrey,
    },
  });

export const simpleSelectInputWrapper = (isOpen: boolean) =>
  css({
    position: 'relative',

    '&:after': {
      content: '""',
      position: 'absolute',
      right: '0',
      top: '0',
      bottom: '0',
      width: '36px',
      backgroundImage: `url( ${arrow} )`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '15px',
      transform: `rotate( ${isOpen ? 180 : 0}deg )`,
    },
  });
export const simpleSelectInput = [
  css({
    paddingRight: '30px !important',
    position: 'relative',
  }),
];
export const simpleSelectPopoverBox = css({
  maxWidth: '400px',
});
export const addNewMessage = css({
  fontWeight: 400,
  fontSize: '13px',
  color: '#B1B1B1',
  textAlign: 'right',
  paddingBottom: '10px',
  marginTop: '16px',
  paddingRight: '20px',
  paddingLeft: '20px',
});

export const xxx = css({});
