/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';

import React from 'react';
import UpdateProfileForm from './UpdateProfileForm';

interface IProps {
    token: string;
}

jsx;

const UpdateProfile: FunctionComponent<IProps> = (
    props: IProps
): ReactElement<IProps> => {
    return <UpdateProfileForm/>;
};

export default UpdateProfile;
