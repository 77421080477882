/** @jsx jsx */
import { jsx } from '@emotion/core';
import { SFC, ReactElement, MouseEvent, FunctionComponent } from 'react';
import * as styles from './styles';

interface IProps {
  type: 'white' | 'black';
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

jsx;

const BurgetButton: FunctionComponent<IProps> = ({ type, onClick }): ReactElement<IProps> => {
  return (
    <button css={styles.getBurger(type)} onClick={onClick}>
      <svg width="26" height="17" viewBox="0 0 26 17" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="100%" fill="none" />
        <path d="M0 17H25.848V14.1667H0V17ZM0 9.91667H25.848V7.08333H0V9.91667ZM0 0V2.83333H25.848V0H0Z" />
      </svg>
    </button>
  );
};

export default BurgetButton;
