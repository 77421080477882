/** @jsx jsx */
import { ReactElement, FunctionComponent } from 'react';
import compose from 'recompose/compose';
import { jsx } from '@emotion/core';
// redux
import { connect } from '../../../utils/redux';
import { restoreEmailReq, restorePasswordReq } from '../../../store/actions';
import { Action } from '../../../store/types';
import { withProps } from 'recompose';
// components
import { Link } from 'react-router-dom';
import Box from './Box';
import RestorePassForm from './RestorePassForm';
import RestoreEmailForm from './RestoreEmailForm';
import OutlineBtn from '../../common/buttons/OutlineBtn';
// styles
import { H1, H1white } from '../../../styles/styles';
import * as styles from './styles';
// other
import { getSearchParam } from '../../../utils/url';
import routes from '../../../routes';
import { IVisualContext } from '../../../contexts/VisualContext';
import withVisualContext from '../../../contexts/withVisualContext';

interface IProps {
  token: string | null;
  restorePasswordReq: Action<{}>;
  restoreEmailReq: Action<{}>;
  visual: IVisualContext;
}

jsx;

const update: Partial<IProps> = {
  token: getSearchParam('token'),
};

export const withHash = withProps(
  (props: IProps): IProps => {
    return { ...props, ...update };
  }
);

export const Restore: FunctionComponent<IProps> = ({
  token,
  restorePasswordReq,
  restoreEmailReq,
  visual,
}): ReactElement<IProps> => {
  const message = token ? 'Enter new password' : 'Enter your email below';
  const form = token ? (
    <RestorePassForm token={token} restorePasswordReq={restorePasswordReq} isForCopy={visual.isCopy} />
  ) : (
    <RestoreEmailForm restoreEmailReq={restoreEmailReq} isForCopy={visual.isCopy} />
  );

  return (
    <Box>
      <section css={styles.ContentSection}>
        <h1 css={visual.isCopy ? H1white : H1}>Restore password</h1>
        <div css={visual.isCopy ? styles.DescriptionLight : styles.Description}>{message}:</div>

        {form}

        <div>
          <Link to={routes.Login}>
            <OutlineBtn isWide title="Back to login" />
          </Link>
        </div>
      </section>
    </Box>
  );
};

export default compose<any, {}>(
  connect(
    {},
    { restoreEmailReq, restorePasswordReq }
  ),
  withVisualContext,
  withHash
)(Restore);
