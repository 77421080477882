/** @jsx jsx */
import { jsx } from "@emotion/core";

import * as styles from "./styles";
import { withProps } from "recompose";
import { BaseBtn, IBaseBtnProps } from "./BaseBtn";

jsx;

const update: Partial<IBaseBtnProps> = {
  btnStyle: styles.primary
};

const PrimaryBtn = withProps(
  (props: IBaseBtnProps): IBaseBtnProps => {
    return { ...props, ...update };
  }
)(BaseBtn);

export default PrimaryBtn;
