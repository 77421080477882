/** @jsx jsx */
import { jsx } from '@emotion/core';
import moment from 'moment';
import _ from 'lodash';

import { Component, createRef } from 'react';
import * as styles from '../styles';
import TimelineLink from './TimelineLink';
import * as utils from '../../utils';

import { BarTypes } from './Timeline';

export interface IProps {
  boxRange: any;
  valueRange: any;
  limitRange: any;

  color: string;
  childColor?: string;
  isEdit?: boolean;
  onChange?: (range: any) => void;
  isSelected?: boolean;
  onSelect?: (e: any) => void;
  onDeselect?: (e: any) => void;
  onClick?: (e: any) => void;
  htmlElementCollector?: (el: any) => void;
  groupElements?: any;
  onUnlink?: (e: any) => void;

  barType: BarTypes;
}

export interface IState {
  startValue: any;
  currentBarWidth: any;
  currentValue: any;
  pixelValue: any;
  isVerticalMove: boolean;
  isMove: boolean;
  resizeTarget: RESIZE_TARGETS | null;
  mouseStartPosition: number;
  deltaTime: any;
}

enum RESIZE_TARGETS {
  From = 'from',
  To = 'to',
}

const MSECONDS_OF_DAY = 86400000;

jsx;
class TimelineValue extends Component<IProps, IState> {
  private timelineValueBox: any;
  private timelineValue: any;

  constructor(props: IProps) {
    super(props);

    this.timelineValueBox = createRef();
    this.timelineValue = createRef();

    this.state = {
      startValue: null,
      currentBarWidth: 0,
      currentValue: null,
      pixelValue: null,
      resizeTarget: null,
      isMove: false,
      isVerticalMove: false,
      mouseStartPosition: 0,
      deltaTime: null,
    };
  }

  componentDidMount(): void {
    const { htmlElementCollector = _.identity } = this.props;
    htmlElementCollector(this.timelineValue.current);
  }

  componentWillUnmount(): void {
    const { htmlElementCollector = _.identity } = this.props;
    htmlElementCollector(this.timelineValue.current);
    this.clearResize();
  }

  startHorizontalMove = (e: any, target: RESIZE_TARGETS | null = null) => {
    e.stopPropagation();
    e.preventDefault();

    const { boxRange, valueRange } = this.props;

    window.addEventListener('mousemove', this.mouseMove, false);
    window.addEventListener('click', this.applyHorizontalMove, false);

    const timeDelta = moment.utc(boxRange.to).valueOf() - moment.utc(boxRange.from).valueOf();

    const pixelValue = Math.round(MSECONDS_OF_DAY / this.timelineValueBox.current.offsetWidth);

    const startValue = this.getValueInBoxRange(valueRange, boxRange);

    this.setState({
      isMove: true,
      mouseStartPosition: e.clientX,
      resizeTarget: target,
      pixelValue,
      currentValue: startValue,
      startValue,
      deltaTime: 0,
    });
  };

  mouseMove = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState((state, props) => {
      const deltaTime = this.getDeltaTime(state, e.clientX);
      const currentValue = this.getNewCurrentValue(state, deltaTime);
      return currentValue ? { ...state, currentValue, deltaTime } : state;
    });
  };

  getDeltaTime = ({ pixelValue, mouseStartPosition }: any, currentCursorPositionX: any) => {
    return (currentCursorPositionX - mouseStartPosition) * pixelValue;
  };

  getNewCurrentValue = ({ resizeTarget, currentValue, startValue }: any, deltaTime: number) => {
    if (startValue && currentValue) {
      if (resizeTarget) {
        const newTargetValue = startValue[resizeTarget] + deltaTime;
        return { ...currentValue, [resizeTarget]: newTargetValue };
      } else {
        return { from: startValue['from'] + deltaTime, to: startValue['to'] + deltaTime };
      }
    }
    return null;
  };

  applyHorizontalMove = () => {
    const { currentValue, startValue, resizeTarget, deltaTime } = this.state;
    const { onChange = _.identity, valueRange, boxRange } = this.props;

    let apply;

    this.clearResize();
    if (resizeTarget) {
      const valueRangeConverted = _.mapValues(valueRange, (item) => moment.utc(item).valueOf());
      const applyValue = this.processResizeApplyValue(currentValue, resizeTarget);
      apply = { ...valueRangeConverted, [resizeTarget]: applyValue };
    } else {
      const { from, to } = valueRange;
      apply = this.processMoveApplyValue({
        from: moment.utc(from).valueOf() + deltaTime,
        to: moment.utc(to).valueOf() + deltaTime,
      });
    }

    if (this.state.isMove) {
      this.setState({ isMove: false, currentValue: null, resizeTarget: null, deltaTime: null });
    }

    if (startValue.from !== apply.from || startValue.to !== apply.to) {
      onChange(apply);
    }
  };

  processMoveApplyValue = (range: any) => {
    const timeRound = 30;
    const { limitRange } = this.props;
    const outsideFrom = moment.utc(limitRange.from).valueOf() - moment.utc(range.from).valueOf();
    const outsideTo = moment.utc(range.to).valueOf() - moment.utc(limitRange.to).valueOf();

    if (outsideFrom > 0) {
      range = { from: range.from + outsideFrom, to: range.to + outsideFrom };
    } else if (outsideTo > 0) {
      range = { from: range.from - outsideTo, to: range.to - outsideTo };
    }
    return { from: this.roundApplyValue(range.from, timeRound), to: this.roundApplyValue(range.to, timeRound) };
  };

  processResizeApplyValue = (currentValue: any, resizeTarget: any) => {
    const { boxRange, limitRange } = this.props;

    currentValue = this.resolveResizeCurrentValue(currentValue, resizeTarget);
    let value = utils.tValue(_.get(currentValue, resizeTarget));

    if (resizeTarget) {
      const boxValue = moment.utc(boxRange[resizeTarget]).valueOf();
      const limitValue = moment.utc(limitRange[resizeTarget]).valueOf();

      if (resizeTarget === 'from') {
        value = value < boxValue ? boxValue : value;
        value = value < limitValue ? limitValue : value;
      } else {
        value = value > boxValue ? boxValue : value;
        value = value > limitValue ? limitValue : value;
      }
    }
    return this.roundApplyValue(value);
  };

  clearResize = () => {
    window.removeEventListener('mousemove', this.mouseMove);
    window.removeEventListener('click', this.applyHorizontalMove);
  };

  resolveResizeCurrentValue = (currentValue: any, resizeTarget: any) => {
    if (utils.tValue(currentValue.from) > utils.tValue(currentValue.to)) {
      const step = 30 * 60 * 1000;
      const changeValue = resizeTarget === 'from' ? currentValue.to - step : currentValue.from + step;
      currentValue = { ...currentValue, [resizeTarget]: changeValue };
    }
    return currentValue;
  };

  roundApplyValue = (value: number, step: number = 30) => {
    step = step * 60 * 1000;
    const tail = value % step;
    const body = value - tail;
    value = step / 2 >= tail ? body : body + step;
    return value;
  };

  getDayPercents = (value: number) => {
    return (value / MSECONDS_OF_DAY) * 100;
  };

  getDiffPercents = (from: number, to: number) => {
    return this.getDayPercents(to - from);
  };

  getCurrentValue = () => {
    return this.state.currentValue;
  };

  getValueInBoxRange = (valueRange: any, boxRange: any) => {
    const valueFrom = moment.utc(valueRange.from).valueOf();
    const valueTo = moment.utc(valueRange.to).valueOf();
    const boxFrom = moment.utc(boxRange.from).valueOf();
    const boxTo = moment.utc(boxRange.to).valueOf();
    return { from: valueFrom < boxFrom ? boxFrom : valueFrom, to: valueTo > boxTo ? boxTo : valueTo };
  };

  onClick = (e: any) => {
    e.stopPropagation();

    if (this.state.isMove) {
      this.applyHorizontalMove();
    }

    const { isSelected = false, onSelect = _.identity, onDeselect = _.identity, onClick = _.identity } = this.props;
    if (this.props.isSelected) {
      onDeselect(e);
    } else if (['ctrlKey', 'altKey', 'cmdKey', 'metaKey'].some((key) => e[key])) {
      onSelect(e);
    } else {
      onClick(e);
    }
  };

  render() {
    const {
      boxRange,
      valueRange,
      color,
      childColor,
      isEdit = false,
      isSelected = false,
      groupElements = null,
      onUnlink = _.identity,
      barType,
    } = this.props;

    const valueInBox = this.getCurrentValue() || this.getValueInBoxRange(valueRange, boxRange);

    const intersectionFrom = moment.utc(valueRange.from).valueOf() < moment.utc(boxRange.from).valueOf();
    const intersectionTo = moment.utc(valueRange.to).valueOf() > moment.utc(boxRange.to).valueOf();

    const indentPercents = this.getDiffPercents(
      moment.utc(boxRange.from).valueOf(),
      moment.utc(valueInBox.from).valueOf()
    );
    const valuePercents = this.getDiffPercents(valueInBox.from, valueInBox.to);

    const isTransportType = barType === BarTypes.Transport;

    const intersectionColor = isTransportType ? color : 'white';

    const beforeIntersectionStyle = intersectionFrom ? [styles.intersectionBefore(intersectionColor)] : [];
    const afterIntersectionStyle = intersectionTo ? [styles.intersectionAfter(intersectionColor)] : [];

    const valueStyle = [
      styles.timelineValue({ width: valuePercents, color, isSelected, isTransportType }),
      beforeIntersectionStyle,
      afterIntersectionStyle,
    ];

    const timelineValueBoxStyle = this.state.currentValue
      ? [styles.timelineValueBox, styles.timelineValueBoxResize]
      : styles.timelineValueBox;

    return (
      <div ref={this.timelineValueBox} css={timelineValueBoxStyle}>
        <div css={styles.timelineValueIndent(indentPercents)} />
        <div ref={this.timelineValue} onClick={this.onClick} css={valueStyle}>
          {isTransportType && <div css={styles.transportBar({ color })} />}

          {isEdit && (
            <div
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();

                this.startHorizontalMove(e);
              }}
              css={styles.moveHandler}
            />
          )}

          {isEdit && !intersectionFrom && (
            <div
              onMouseDown={(e) => {
                this.startHorizontalMove(e, RESIZE_TARGETS.From);
              }}
              css={styles.resizeHandlerLeft}
            />
          )}
          {isEdit && !intersectionTo && (
            <div
              onMouseDown={(e) => {
                this.startHorizontalMove(e, RESIZE_TARGETS.To);
              }}
              css={styles.resizeHandlerRight}
            />
          )}
          {groupElements && (
            <div css={styles.linkPosition}>
              {_.map(groupElements, (element, index) => {
                return (
                  <TimelineLink
                    key={index}
                    fromEl={this.timelineValue.current}
                    toEl={element}
                    color={color}
                    isEdit={isEdit}
                    onUnlink={onUnlink}
                    unlinkId={index}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TimelineValue;
