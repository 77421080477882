/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FunctionComponent, ReactElement } from "react";
import * as styles from "./styles";

jsx;

const Content: FunctionComponent<{}> = ({ children }): ReactElement<{}> => {
  return <div css={styles.contentBox}>{children}</div>;
};

export default Content;
