/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, Fragment } from 'react';
import * as styles from './styles';
import { ICell } from './CellView';

interface IProps {
  shema: ICell[];
  isPrintView?: boolean;
  additionalPrintHeader?: any;
  isSticky?: boolean;
}

jsx;

export const Header: FunctionComponent<IProps> = ({
  shema,
  isPrintView,
  additionalPrintHeader = null,
  isSticky = false,
}): ReactElement<IProps> => {
  const mainView = (
    <div className={'grid-header'} css={isSticky ? [styles.row, styles.headSticky] : styles.row}>
      {shema.map((item, index) => {
        const headStyle = item.headStyle || [];
        return (
          <div css={[styles.headCell(item.width || 10), headStyle]} key={index}>
            {item.title}
          </div>
        );
      })}
    </div>
  );

  const printView = (
    <Fragment>
      {additionalPrintHeader}
      <tr className={'grid-header'} css={[styles.row, { borderBottom: 0 }]}>
        {shema.map((item, index) => {
          const headStyle = item.headStyle || [];
          return (
            <th css={[styles.headCell(item.width || 10), headStyle]} key={index}>
              {item.title}
            </th>
          );
        })}
      </tr>
    </Fragment>
  );

  return isPrintView ? printView : mainView;
};

export default Header;
