/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import * as styles from '../../../../../styles/styles';

export const formBox = css({ width: '100%' });

export const formButtonsBox = css({ width: '300px' });

export const formTitle = styles.H2;

export const logo = css({
  maxHeight: '45px',
});
