import { createAction } from 'redux-actions';

import reducersRegister from '../../../../../../utils/reducersRegister';
import sagasRegister from '../../../../../../utils/sagasRegister';
import { Action } from '../../../../../../store/types';
import { createAsyncAction, createPromisifyAsyncAction } from '../../../../../../utils/async/actions';

import redusers from './reducers';
import sagas from './sagas';

reducersRegister.register('production', redusers);
sagasRegister.register(sagas);

/******************************************************************************/
/**************************** ALL TRANSPORTS **********************************/
/******************************************************************************/

export const GET_ALL_TRANSPORTS_REQ: string = 'GET_ALL_TRANSPORTS_REQ';
export const GET_ALL_TRANSPORTS_REQ_SUCCESS: string = 'GET_ALL_TRANSPORTS_REQ_SUCCESS';
export const GET_ALL_TRANSPORTS_REQ_ERROR: string = 'GET_ALL_TRANSPORTS_REQ_ERROR';

export const SET_ALL_TRANSPORTS: string = 'SET_ALL_TRANSPORTS';
export const REFRESH_ALL_TRANSPORTS: string = 'REFRESH_ALL_TRANSPORTS';
export const SET_ALL_TRANSPORTS_PARAMS: string = 'SET_ALL_TRANSPORTS_PARAMS';
export const RESET_ALL_TRANSPORTS_PARAMS: string = 'RESET_ALL_TRANSPORTS_PARAMS';

export const getAllTransportsReq: Action<{}> = createPromisifyAsyncAction(GET_ALL_TRANSPORTS_REQ);
export const getAllTransportsReqSuccess: Action<{}> = createAction(GET_ALL_TRANSPORTS_REQ_SUCCESS);
export const getAllTransportsReqError: Action<{}> = createAction(GET_ALL_TRANSPORTS_REQ_ERROR);

export const setAllTransports: Action<{}> = createAction(SET_ALL_TRANSPORTS);
export const refreshAllTransports: Action<{}> = createAction(REFRESH_ALL_TRANSPORTS);
export const setAllTransportsParams: Action<{}> = createAction(SET_ALL_TRANSPORTS_PARAMS);
export const resetAllTransportsParams: Action<{}> = createAction(RESET_ALL_TRANSPORTS_PARAMS);

/******************************************************************************/
/**************************** ALL ACTIVITIES **********************************/
/******************************************************************************/

export const GET_ALL_ACTIVITIES_REQ: string = 'GET_ALL_ACTIVITIES_REQ';
export const GET_ALL_ACTIVITIES_REQ_SUCCESS: string = 'GET_ALL_ACTIVITIES_REQ_SUCCESS';
export const GET_ALL_ACTIVITIES_REQ_ERROR: string = 'GET_ALL_ACTIVITIES_REQ_ERROR';

export const SET_ALL_ACTIVITIES: string = 'SET_ALL_ACTIVITIES';
export const REFRESH_ALL_ACTIVITIES: string = 'REFRESH_ALL_ACTIVITIES';
export const SET_ALL_ACTIVITIES_PARAMS: string = 'SET_ALL_ACTIVITIES_PARAMS';
export const RESET_ALL_ACTIVITIES_PARAMS: string = 'RESET_ALL_ACTIVITIES_PARAMS';

export const ADD_ITEM_OF_ALL_ACTIVITIES: string = 'ADD_ITEM_OF_ALL_ACTIVITIES';
export const UPDATE_ITEM_OF_ALL_ACTIVITIES: string = 'UPDATE_ITEM_OF_ALL_ACTIVITIES';
export const UPDATE_ITEM_RANGE_OF_ALL_ACTIVITIES: string = 'UPDATE_ITEM_RANGE_OF_ALL_ACTIVITIES';

export const SELECT_ACTIVITY: string = 'SELECT_ACTIVITY';
export const SELECT_ALL_ACTIVITIES: string = 'SELECT_ALL_ACTIVITIES';
export const SELECT_DAY_ACTIVITIES: string = 'SELECT_DAY_ACTIVITIES';
export const UNSELECT_FROM_DAY_ACTIVITIES: string = 'UNSELECT_FROM_DAY_ACTIVITIES';
export const CLEAR_SELECTED_ACTIVITIES: string = 'CLEAR_SELECTED_ACTIVITIES';

export const getAllActivitiesReq: Action<{}> = createAsyncAction(GET_ALL_ACTIVITIES_REQ);
export const getAllActivitiesReqSuccess: Action<{}> = createAction(GET_ALL_ACTIVITIES_REQ_SUCCESS);
export const getAllActivitiesReqError: Action<{}> = createAction(GET_ALL_ACTIVITIES_REQ_ERROR);

export const setAllActivities: Action<{}> = createAction(SET_ALL_ACTIVITIES);
export const refreshAllActivities: Action<{}> = createAction(REFRESH_ALL_ACTIVITIES);
export const setAllActivitiesParams: Action<{}> = createAction(SET_ALL_ACTIVITIES_PARAMS);
export const resetAllActivitiesParams: Action<{}> = createAction(RESET_ALL_ACTIVITIES_PARAMS);

export const addItemOfAllActivities: Action<{}> = createAction(ADD_ITEM_OF_ALL_ACTIVITIES);
export const updateItemOfAllActivities: Action<{}> = createAction(UPDATE_ITEM_OF_ALL_ACTIVITIES);

export const selectActivity: Action<{}> = createAction(SELECT_ACTIVITY);
export const selectAllActivities: Action<{}> = createAction(SELECT_ALL_ACTIVITIES);
export const selectDayActivities: Action<{}> = createAction(SELECT_DAY_ACTIVITIES);
export const unselectFromDayActivities: Action<{}> = createAction(UNSELECT_FROM_DAY_ACTIVITIES);
export const clearSelectedActivities: Action<{}> = createAction(CLEAR_SELECTED_ACTIVITIES);

/******************************************************************************/
/******************************* CREATE TRANSPORT ******************************/
/******************************************************************************/

export const CREATE_TRANSPORT_REQ: string = 'CREATE_TRANSPORT_REQ';
export const CREATE_TRANSPORT_REQ_SUCCESS: string = 'CREATE_TRANSPORT_REQ_SUCCESS';
export const CREATE_TRANSPORT_REQ_ERROR: string = 'CREATE_TRANSPORT_REQ_ERROR';

export const createTransportReq: Action<{}> = createPromisifyAsyncAction(CREATE_TRANSPORT_REQ);
export const createTransportReqSuccess: Action<{}> = createAction(CREATE_TRANSPORT_REQ_SUCCESS);
export const createTransportReqError: Action<{}> = createAction(CREATE_TRANSPORT_REQ_ERROR);

/******************************************************************************/
/******************************* UPDATE TRANSPORT **************************/
/******************************************************************************/

export const UPDATE_TRANSPORT_REQ: string = 'UPDATE_TRANSPORT_REQ';
export const UPDATE_TRANSPORT_REQ_SUCCESS: string = 'UPDATE_TRANSPORT_REQ_SUCCESS';
export const UPDATE_TRANSPORT_REQ_ERROR: string = 'UPDATE_TRANSPORT_REQ_ERROR';
export const UPDATE_TRANSPORT: string = 'UPDATE_TRANSPORT';

export const updateTransportReq: Action<{}> = createPromisifyAsyncAction(UPDATE_TRANSPORT_REQ);
export const updateTransportReqSuccess: Action<{}> = createAction(UPDATE_TRANSPORT_REQ_SUCCESS);
export const updateTransportReqError: Action<{}> = createAction(UPDATE_TRANSPORT_REQ_ERROR);
export const updateTransport: Action<{}> = createAction(UPDATE_TRANSPORT);

/******************************************************************************/
/******************************* CREATE ACTIVITY ******************************/
/******************************************************************************/

export const CREATE_ACTIVITY_REQ: string = 'CREATE_ACTIVITY_REQ';
export const CREATE_ACTIVITY_REQ_SUCCESS: string = 'CREATE_ACTIVITY_REQ_SUCCESS';
export const CREATE_ACTIVITY_REQ_ERROR: string = 'CREATE_ACTIVITY_REQ_ERROR';
export const COPY_ACTIVITY_REQ: string = 'COPY_ACTIVITY_RANGE_REQ';

export const createActivityReq: Action<{}> = createPromisifyAsyncAction(CREATE_ACTIVITY_REQ);
export const createActivityReqSuccess: Action<{}> = createAsyncAction(CREATE_ACTIVITY_REQ_SUCCESS);
export const createActivityReqError: Action<{}> = createAsyncAction(CREATE_ACTIVITY_REQ_ERROR);

export const copyActivityReq: Action<{}> = createAsyncAction(COPY_ACTIVITY_REQ);

/******************************************************************************/
/******************************* GET ONE ACTIVITY *****************************/
/******************************************************************************/

export const GET_ACTIVITY_REQ: string = 'GET_ACTIVITY_REQ';
export const GET_ACTIVITY_REQ_SUCCESS: string = 'GET_ACTIVITY_REQ_SUCCESS';
export const GET_ACTIVITY_REQ_ERROR: string = 'GET_ACTIVITY_REQ_ERROR';
export const SET_ACTIVITI: string = 'SET_ACTIVITI';

export const getActivityReq: Action<{}> = createAsyncAction(GET_ACTIVITY_REQ);
export const getActivityReqSuccess: Action<{}> = createAction(GET_ACTIVITY_REQ_SUCCESS);
export const getActivityReqError: Action<{}> = createAction(GET_ACTIVITY_REQ_ERROR);
export const setActivity: Action<{}> = createAction(SET_ACTIVITI);

/******************************************************************************/
/******************************* UPDATE ONE ACTIVITY **************************/
/******************************************************************************/

export const UPDATE_ACTIVITY_REQ: string = 'UPDATE_ACTIVITY_REQ';
export const LINK_ACTIVITY_REQ: string = 'LINK_ACTIVITY_REQ';
export const UNLINK_ACTIVITY_REQ: string = 'UNLINK_ACTIVITY_REQ';
export const UPDATE_ACTIVITY_RANGE_REQ: string = 'UPDATE_ACTIVITY_RANGE_REQ';
export const UPDATE_ACTIVITY_REQ_SUCCESS: string = 'UPDATE_ACTIVITY_REQ_SUCCESS';
export const UPDATE_ACTIVITY_REQ_ERROR: string = 'UPDATE_ACTIVITY_REQ_ERROR';
export const UPDATE_ACTIVITY: string = 'UPDATE_ACTIVITY';

export const updateActivityReq: Action<{}> = createPromisifyAsyncAction(UPDATE_ACTIVITY_REQ);
export const linkActivityReq: Action<{}> = createPromisifyAsyncAction(LINK_ACTIVITY_REQ);
export const unlinkActivityReq: Action<{}> = createPromisifyAsyncAction(UNLINK_ACTIVITY_REQ);
export const updateActivityReqSuccess: Action<{}> = createAction(UPDATE_ACTIVITY_REQ_SUCCESS);
export const updateActivityReqError: Action<{}> = createAction(UPDATE_ACTIVITY_REQ_ERROR);
export const updateActivityRangeReq: Action<{}> = createPromisifyAsyncAction(UPDATE_ACTIVITY_RANGE_REQ);
export const updateActivity: Action<{}> = createAction(UPDATE_ACTIVITY);

/******************************************************************************/
/******************************* DELETE ONE ACTIVITY **************************/
/******************************************************************************/

export const DELETE_ACTIVITY_REQ: string = 'DELETE_ACTIVITY_REQ';
export const DELETE_ACTIVITY_REQ_SUCCESS: string = 'DELETE_ACTIVITY_REQ_SUCCESS';
export const DELETE_ACTIVITY_REQ_ERROR: string = 'DELETE_ACTIVITY_REQ_ERROR';
export const DELETE_ACTIVITY: string = 'DELETE_ACTIVITY';

export const deleteActivityReq: Action<{}> = createAsyncAction(DELETE_ACTIVITY_REQ);

export const deleteActivityReqSuccess: Action<{}> = createAction(DELETE_ACTIVITY_REQ_SUCCESS);

export const deleteActivityReqError: Action<{}> = createAction(DELETE_ACTIVITY_REQ_ERROR);

export const deleteActivity: Action<{}> = createAction(DELETE_ACTIVITY);

/******************************************************************************/
/******************************* CLONE PRODUCTION *****************************/
/******************************************************************************/
export const CLONE_PRODUCTION_REQ: string = 'CLONE_PRODUCTION_REQ';

export const cloneProductionReq: Action<{}> = createAsyncAction(CLONE_PRODUCTION_REQ);

export const ACTIVITY_GROUP_UPDATE: string = 'ACTIVITY_GROUP_UPDATE';

export const activityGroupUpdate: Action<{}> = createAsyncAction(ACTIVITY_GROUP_UPDATE);

/******************************************************************************/
/******************************* VERSION LIST *********************************/
/******************************************************************************/
export const GET_VERSION_LIST_REQ: string = 'GET_VERSION_LIST_REQ';
export const GET_VERSION_LIST_REQ_SUCCESS: string = 'GET_VERSION_LIST_REQ_SUCCESS';
export const GET_VERSION_LIST_REQ_ERROR: string = 'GET_VERSION_LIST_REQ_ERROR';

export const SET_VERSION_LIST: string = 'SET_VERSION_LIST';
export const REFRESH_VERSION_LIST: string = 'REFRESH_VERSION_LIST';

export const getVersionListReq: Action<{}> = createAsyncAction(GET_VERSION_LIST_REQ);
export const getVersionListReqSuccess: Action<{}> = createAction(GET_VERSION_LIST_REQ_SUCCESS);
export const getVersionListReqError: Action<{}> = createAction(GET_VERSION_LIST_REQ_ERROR);

export const setVersionList: Action<{}> = createAction(SET_VERSION_LIST);
export const refreshVersionList: Action<{}> = createAction(REFRESH_VERSION_LIST);

/******************************************************************************/
/******************************* CREATE VERSION   *****************************/
/******************************************************************************/
export const CREATE_VERSION_REQ: string = 'CREATE_VERSION_REQ';
export const CREATE_VERSION_REQ_SUCCESS: string = 'CREATE_VERSION_REQ_SUCCESS';
export const CREATE_VERSION_REQ_ERROR: string = 'CREATE_VERSION_REQ_ERROR';
export const ADD_VERSION: string = 'ADD_VERSION';

export const createVersionReq: Action<{}> = createPromisifyAsyncAction(CREATE_VERSION_REQ);
export const createVersionReqSuccess: Action<{}> = createAction(CREATE_VERSION_REQ_SUCCESS);
export const createVersionReqError: Action<{}> = createAction(CREATE_VERSION_REQ_ERROR);
export const addVersion: Action<{}> = createAction(ADD_VERSION);

/******************************************************************************/
/******************************* DELETE ACTIVITIES ********************************/
/******************************************************************************/

export const DELETE_ACTIVITIES_REQ: string = 'DELETE_ACTIVITIES_REQ';
export const DELETE_ACTIVITIES_REQ_SUCCESS: string = 'DELETE_ACTIVITIES_REQ_SUCCESS';
export const DELETE_ACTIVITIES_REQ_ERROR: string = 'DELETE_ACTIVITIES_REQ_ERROR';
export const DELETE_ACTIVITIES: string = 'DELETE_ACTIVITIES';

export const deleteActivitiesReq: Action<{}> = createAsyncAction(DELETE_ACTIVITIES_REQ);

export const deleteActivitiesReqSuccess: Action<{}> = createAction(DELETE_ACTIVITIES_REQ_SUCCESS);

export const deleteActivitiesReqError: Action<{}> = createAction(DELETE_ACTIVITIES_REQ_ERROR);

export const deleteActivities: Action<{}> = createAction(DELETE_ACTIVITIES);

/******************************************************************************/
/******************************* CLONE ACTIVITIES ********************************/
/******************************************************************************/

export const CLONE_ACTIVITIES_REQ: string = 'CLONE_ACTIVITIES_REQ';
export const CLONE_ACTIVITIES_REQ_SUCCESS: string = 'CLONE_ACTIVITIES_REQ_SUCCESS';

export const cloneActivitiesReq: Action<{}> = createAsyncAction(CLONE_ACTIVITIES_REQ);

export const cloneActivitiesReqSuccess: Action<{}> = createAction(CLONE_ACTIVITIES_REQ_SUCCESS);

/******************************************************************************/
/******************************* SHIFT ACTIVITIES ********************************/
/******************************************************************************/

export const SHIFT_ACTIVITIES_REQ: string = 'SHIFT_ACTIVITIES_REQ';
export const SHIFT_ACTIVITIES_REQ_SUCCESS: string = 'SHIFT_ACTIVITIES_REQ_SUCCESS';

export const shiftActivitiesReq: Action<{}> = createAsyncAction(SHIFT_ACTIVITIES_REQ);

export const shiftActivitiesReqSuccess: Action<{}> = createAction(SHIFT_ACTIVITIES_REQ_SUCCESS);
