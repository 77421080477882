/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FunctionComponent, ReactElement } from "react";
import { Field, FieldAttributes } from "formik";
import * as styles from "./styles";

jsx;

export const FormField: FunctionComponent<FieldAttributes<{}>> = ({
  children,
  ...props
}: FieldAttributes<{}>): ReactElement<FieldAttributes<{}>> => {
  return (
    <div css={styles.formFieldBox}>
      <Field {...props}>{children}</Field>
    </div>
  );
};

export default FormField;
