/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, Fragment } from 'react';
import * as styles from './styles';

export interface IProps {
  errors: any;
}

jsx;

export const Errors: FunctionComponent<IProps> = ({ errors }): ReactElement<IProps> => {
  return <Fragment>Errors!</Fragment>;
};

export default Errors;
