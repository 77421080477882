/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import * as styles from './styles';

const COLL_WIDTH = 25;
const COLL_PARTS = 4;

export interface IProps {
  width?: 1 | 2 | 3 | 4;
}

jsx;

export const FormRowSections: FunctionComponent<IProps> = ({ children, width = 2 }): ReactElement<IProps> => {
  width = (COLL_PARTS / width) * COLL_WIDTH;

  return (
    <div className="form-row-section" css={styles.formRowSections(width)}>
      {children}
    </div>
  );
};

export default FormRowSections;
