/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactElement, MouseEvent, FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import CheckAcl from '../../CheckAcl';

interface INavItem {
  link: string;
  acl: string;
  title: string;
}

interface IProps {
  items: INavItem[];
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

jsx;
const Nav: FunctionComponent<IProps> = ({ items, onClick }): ReactElement<IProps> => {
  return (
    <ul>
      {items.map((item) => (
        <CheckAcl key={item.title} resources={item.acl} actions={['R']}>
          {() => (
            <li key={item.title}>
              <NavLink to={item.link} onClick={onClick}>
                {item.title}
              </NavLink>
            </li>
          )}
        </CheckAcl>
      ))}
    </ul>
  );
};

export default Nav;
