/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, forwardRef } from 'react';
import modal from '../../../../../common/modal';
import CreateSupplier from './CreateSupplier';
import OutlineBtn from '../../../../../common/buttons/OutlineBtn';
import PrimaryBtn from '../../../../../common/buttons/PrimaryBtn';

import { SupplierFunction } from '../../../SupplierFunctions/store/types';
import MultiSelectInputWithTabs from '../../../../../common/form/selectWithTabs/MultiSelectInputWithTabs';

import { ISupplier } from '../../../Suppliers/store/types';
// utils
import _ from 'lodash';

import MultiselectModal, { IProps as MultiSelectProps } from '../../../../../common/form/multiselect/MultiselectModal';

interface IProps extends MultiSelectProps {
  forwardRef?: any;
  activity: any;
  connectFunctionsToSupplier: (id: number, funcForAdding: number[]) => void;
  onCreate: any;
  allSuppliers: ISupplier[];

  hasPermissionToAdd?: boolean;
  supplierFunctions: SupplierFunction[];
  updateSupplier: any;
}

interface IState {
  checked: boolean;
  isOpenModal: boolean;
  funcForAdding: number[];
  supplierIdToAddFunc: number | null;
}

jsx;

class SupplierSelector extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      checked: false,
      isOpenModal: false,
      supplierIdToAddFunc: null,
      funcForAdding: [],
    };
  }

  onCheckAllFunctions = (e: any) => {
    this.setState({ checked: e.currentTarget.checked });
  };

  getSuppliersWithSelectedFunctions = () => {
    const { allSuppliers, activity } = this.props;

    return _.get(activity, 'functions.length')
      ? _.reduce(
          allSuppliers,
          (result: any, supplier) => {
            supplier.functions.forEach(({ id }) => {
              if (activity.functions.includes(id)) {
                const supplierId = supplier.id;
                const supplierName = supplier.name;
                if (!result.find((sup: any) => sup.id === supplierId)) {
                  result.push({ id: supplierId, name: supplierName });
                }
              }
            });
            return result;
          },
          []
        )
      : null;
  };

  getSuppliersForChosing = () => {
    const suppliersWithSelectedFunctions = this.getSuppliersWithSelectedFunctions();
    const { items, allSuppliers } = this.props;
    const { checked } = this.state;
    if (!suppliersWithSelectedFunctions) {
      return items;
    } else {
      return checked ? allSuppliers : suppliersWithSelectedFunctions;
    }
  };

  checkAccess = (id: number) => {
    const { allSuppliers, activity } = this.props;
    const selectedFunctions = _.get(activity, 'functions');
    const supplier = allSuppliers.find((supplier) => supplier.id === id);
    if (
      supplier &&
      selectedFunctions.length > 0 &&
      !supplier.functions.some((func) => selectedFunctions.includes(func.id))
    ) {
      this.setState({ isOpenModal: true, supplierIdToAddFunc: id, funcForAdding: selectedFunctions });
    }
  };

  handleAddFuncToSuppliers = () => {
    const { supplierIdToAddFunc, funcForAdding } = this.state;
    supplierIdToAddFunc && this.props.connectFunctionsToSupplier(supplierIdToAddFunc, funcForAdding);
    this.closeModal();
  };

  closeModal = () => {
    this.setState({ isOpenModal: false, supplierIdToAddFunc: null, funcForAdding: [] });
  };

  render() {
    const { Modal, ModalHeader, ModalFooter, ModalBody, ModalActions } = modal;
    const {
      hasPermissionToAdd,
      activity,
      allSuppliers,
      onCreate,
      supplierFunctions,
      updateSupplier,
      ...props
    } = this.props;

    const suppliersForChosing = this.getSuppliersForChosing();

    return (
      <div>
        {hasPermissionToAdd ? (
          <MultiSelectInputWithTabs
            {...props}
            ref={this.props.forwardRef}
            onCheckAll={this.onCheckAllFunctions}
            items={suppliersForChosing}
            checkAccess={this.checkAccess}
            shoudOpenOnFocus
            isVisibilityHight={false}
            noSelectedMsg="Not selected"
            searchInAll="suppliers"
            selected={_.get(activity, 'suppliers', [])}
            scheme={{ id: 'id', title: 'name' }}
            selectAllBtn={false}
            elementToCreate={({ init, backToSelect, onCreateAndSelect }) => (
              <CreateSupplier
                onSave={onCreate}
                allSuppliers={allSuppliers}
                supplierFunctions={supplierFunctions}
                backToSelect={backToSelect}
                onCreateAndSelect={onCreateAndSelect}
                updateSupplier={updateSupplier}
              />
            )}
          />
        ) : (
          <MultiselectModal
            {...props}
            ref={this.props.forwardRef}
            onSwitchSearch={this.onCheckAllFunctions}
            items={suppliersForChosing}
            checkAccess={this.checkAccess}
            deepSearch={true}
            shoudOpenOnFocus
            isVisibilityHight={false}
          />
        )}

        <Modal isOpen={this.state.isOpenModal}>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>
            <p>This supplier doesn't have such functions. Do you want to add selected functions to the supplier?</p>
          </ModalBody>
          <ModalFooter>
            <ModalActions>
              <OutlineBtn type="button" onClick={this.closeModal} title="Cancel" />
              <PrimaryBtn title="Add" type="submit" onClick={this.handleAddFuncToSuppliers} />
            </ModalActions>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default forwardRef<HTMLInputElement, IProps>((props, ref) => <SupplierSelector {...props} forwardRef={ref} />);
