/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  FunctionComponent,
  ReactElement,
  InputHTMLAttributes,
  Component,
  Fragment,
  createRef,
  TextareaHTMLAttributes,
} from 'react';
import * as styles from './styles';

jsx;

interface IProps extends TextareaHTMLAttributes<{}> {}

export interface IState {
  text: string;
}

class TextArea extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    const { ...props } = this.props;
    return (
      <div css={styles.formFieldBox}>
        <textarea {...props} />
      </div>
    );
  }
}

export default TextArea;
