/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';
// types
import { SupplierListState } from '../Suppliers/store/types';
import { SupplierFunctionListState } from '../SupplierFunctions/store/types';
// components
import ModalBody from '../../../common/modal/ModalBody';
import ModalFooter from '../../../common/modal/ModalFooter';
import ModalHeader from '../../../common/modal/ModalHeader';
import OutlineBtn from '../../../common/buttons/OutlineBtn';
import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import Select from '../../../common/form/select/Select';
import Option from '../../../common/form/select/Option';
import IconBtn from '../../../common/buttons/IconBtn';
import FormError from '../../../common/form/FormError';
import Form from '../../../common/form/Form';
// styles
import removeIcon from '../../../common/grid/images/cancel.svg';
// utils
import _ from 'lodash';
// other

interface IProps {
  supplierFunctions: SupplierFunctionListState;
  suppliersForSelect: SupplierListState;
  onSubmit: (values: any) => void;
  closeModal: () => void;
}

interface FormValues {
  supplier: number | null;
  agents: object[];
}

interface FormProps {}

jsx;
const CreateProductionSupplierForm = (props: IProps & FormikProps<FormValues>) => {
  const { closeModal, suppliersForSelect, setFieldValue, values, errors, touched } = props;
  const suppliersOptions = _.map(suppliersForSelect.data, (item) => ({ id: item.id, title: item.name }));
  const selectedSupplier = _.find(suppliersForSelect.data, { id: values.supplier });
  const agents = _.get(selectedSupplier, 'agents', []);
  const agentsOptions = agents.length > 0 ? agents : [{ id: null, name: 'There are no agents' }];
  const functions = _.get(selectedSupplier, 'functions', []);
  const functionsOptions = functions.length > 0 ? functions : [{ id: null, name: 'There are no functions' }];

  const onSupplierChange = (value: any) => {
    setFieldValue('supplier', value);
    setFieldValue('agents', [
      {
        agent: null,
        function: null,
      },
    ]);
  };

  const onAgentChange = (index: number, key: string) => (value: any) => {
    setFieldValue('agents', [
      ...values.agents.slice(0, index),
      { ...values.agents[index], [key]: value },
      ...values.agents.slice(index + 1),
    ]);
  };

  const addMoreAgents = () => {
    setFieldValue('agents', [
      ...values.agents,
      {
        agent: null,
        function: null,
      },
    ]);
  };

  const removeAgent = (index: number) => {
    setFieldValue('agents', [...values.agents.slice(0, index), ...values.agents.slice(index + 1)]);
  };

  return (
    <div css={style.modal}>
      <Form>
        <ModalHeader>Add production supplier</ModalHeader>

        <ModalBody>
          <div css={style.row}>
            <div css={style.formControl}>
              <label css={style.label}>Select supplier:</label>
              <Select
                items={suppliersOptions}
                onChange={onSupplierChange}
                selected={values.supplier}
                unselectedMsg="Select supplier"
              >
                {(item: any, selected: boolean) => {
                  return <Option key={item.id} title={item.title} selected={selected} />;
                }}
              </Select>
              <FormError name="supplier" errors={errors} touched={touched} />
            </div>
            <div css={style.formControl} style={{ marginLeft: '15px' }} />
            <IconBtn icon={removeIcon} iconSize="15px" css={style.removeLineBtn} style={{ visibility: 'hidden' }} />
          </div>
          {values.agents.map((agent: any, index) => (
            <div css={style.row} key={index}>
              <div css={style.formControl}>
                {index === 0 && <label css={style.label}>Select agent:</label>}
                <Select
                  items={agentsOptions}
                  scheme={{ id: 'id', title: 'name' }}
                  onChange={onAgentChange(index, 'agent')}
                  selected={agent.agent}
                  unselectedMsg="Select agent"
                >
                  {(item: any, selected: boolean) => {
                    return <Option key={item.id} title={item.name} selected={selected} />;
                  }}
                </Select>
                <FormError name={`agents[${index}].agent`} errors={errors} touched={touched} />
              </div>
              <div css={style.formControl} style={{ marginLeft: '15px' }}>
                {index === 0 && <label css={style.label}>Select function:</label>}
                <Select
                  items={functionsOptions}
                  scheme={{ id: 'id', title: 'name' }}
                  onChange={onAgentChange(index, 'function')}
                  selected={agent.function}
                  unselectedMsg="Select function"
                >
                  {(item: any, selected: boolean) => {
                    return <Option key={item.id} title={item.name} selected={selected} />;
                  }}
                </Select>
                <FormError name={`agents[${index}].function`} errors={errors} touched={touched} />
              </div>
              <IconBtn icon={removeIcon} iconSize="14px" css={style.removeLineBtn} onClick={() => removeAgent(index)} />
            </div>
          ))}
          <OutlineBtn type="button" title="+ add agent" onClick={addMoreAgents} isSmall />
        </ModalBody>

        <ModalFooter css={style.footer}>
          <OutlineBtn type="button" title="Cancel" style={{ marginRight: '10px' }} onClick={closeModal} />
          <PrimaryBtn type="submit" title="Create" />
        </ModalFooter>
      </Form>
    </div>
  );
};

const formikHoc = withFormik<IProps & FormProps, FormValues>({
  mapPropsToValues: (props: IProps & FormProps) => {
    return {
      supplier: null,
      agents: [{ function: null, agent: null }],
    };
  },
  validationSchema: yup.object({}).shape({
    supplier: yup
      .string()
      .nullable(true)
      .required('Supplier is required!'),
    agents: yup.array(
      yup.object().shape({
        agent: yup
          .number()
          .nullable(true)
          .test('agentTest', 'Required, if you select function', function test(value) {
            return !(typeof this.parent.function === 'number' && typeof value !== 'number');
          }),
        function: yup
          .number()
          .nullable(true)
          .test('functionTest', 'Required, if you select agent', function test(value) {
            return !(typeof this.parent.agent === 'number' && typeof value !== 'number');
          }),
      })
    ),
  }),
  handleSubmit: (values: FormValues, { props: { onSubmit, closeModal }, setSubmitting }) => {
    onSubmit(values);
    closeModal();
  },
  displayName: 'CreateProductionSupplierForm',
});

const style = {
  modal: css`
    width: 650px;
    max-width: 100%;
  `,
  footer: css`
    text-align: right;
  `,
  row: css`
    margin-bottom: 25px;
    display: flex;
    align-items; center;
    justify-content: space-between;
    position: relative;    
  `,
  label: css`
    display: block;
    margin-bottom: 5px;
  `,
  formControl: css`
    display: block;
    flex: 1 0 100px;
    position: relative;
  `,
  removeLineBtn: css`
    margin-left: 15px;
    align-self: flex-end;
  `,
};

export default formikHoc(CreateProductionSupplierForm);
