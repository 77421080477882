/** @jsx jsx */
import { jsx } from '@emotion/core';
import { forwardRef, ReactNode } from 'react';
import * as styles from '../styles';

export interface IFormRowProps {
  children: ReactNode | ReactNode[];
}

jsx;
export const Actions = forwardRef<HTMLDivElement, IFormRowProps>(({ children }, ref) => {
  return (
    <div css={[styles.actionsBox]} ref={ref} tabIndex={-1}>
      {children}
    </div>
  );
});

export default Actions;
