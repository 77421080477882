/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import _ from 'lodash';
import { withFormik, Form, Field, FormikProps } from 'formik';
import * as yup from 'yup';
import { RouteComponentProps } from 'react-router';

import { connect } from '../../../../utils/redux';
import { createProducersReq, updateProducerReq } from './store/actions';

import { Action } from '../../../../store/types';

import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../common/buttons/OutlineBtn';
import FormRow from '../../../common/form/FormRow';
import FormField from '../../../common/form/FormField';
import FormError from '../../../common/form/FormError';
import * as validatinShemas from '../../../../utils/validatinShemas';

import { IProducer } from './store/types';
import { compose } from 'recompose';
import FormRadio from '../../../common/form/FormRadio';
import form from '../../../common/form';
import page from '../../../common/page';
import { Fragment } from 'react';
import routes from '../../../../routes';
import { Link } from 'react-router-dom';
import { Colors } from '../../../../styles/styles';

interface FormValues extends Partial<IProducer> {}

interface MatchParams {
  id?: string;
}

interface OtherProps extends RouteComponentProps<MatchParams> {
  producer: IProducer;
  updateProducerReq: Action<{}>;
}

jsx;

const EditForm = (props: OtherProps & FormikProps<FormValues>) => {
  const { errors, touched, values } = props;
  const { FormRow, FormField, FormRadio, FormError, FormBlock, FieldsSet, ActionsRow, Form, FieldsArea } = form;
  const { Page, Breadcrumbs, PageHeader } = page;

  return (
    <Fragment>
      <PageHeader title="Edit producer">
        <Breadcrumbs
          path={[
            { title: 'Producers', link: routes.Producers },
            { title: 'Edit producer', link: routes.ProducersCreate },
          ]}
        />
      </PageHeader>
      <Page>
        <Form>
          <FieldsArea>
            <FormBlock>
              <FieldsSet>
                <FormRow>
                  <label htmlFor="email">Email</label>
                  <FormField name="email" placeholder="email" />
                  <FormError name="email" errors={errors} touched={touched} />
                </FormRow>

                <FormRow>
                  <label htmlFor="name">Name</label>
                  <FormField name="name" placeholder="name" />
                  <FormError name="name" errors={errors} touched={touched} />
                </FormRow>

                <FormRow>
                  <label htmlFor="phone">Phone</label>
                  <FormField name="phone" placeholder="+1234567890" />
                  <FormError name="phone" errors={errors} touched={touched} />
                  <p css={style.description}>
                    Please add phone number in international format. Use only '+' and numbers.
                  </p>
                </FormRow>

                <FormRow>
                  <label htmlFor="isFreelancer">Freelancer</label>
                  <FormRadio name="isFreelancer" value={true} values={values} title="Yes" />
                  <FormRadio name="isFreelancer" value={false} values={values} title="No" />
                </FormRow>

                <FormRow>
                  <label htmlFor="isActive">Active</label>
                  <FormRadio name="isActive" value={true} values={values} title="Yes" />
                  <FormRadio name="isActive" value={false} values={values} title="No" />
                </FormRow>
              </FieldsSet>
            </FormBlock>
          </FieldsArea>

          <ActionsRow>
            <Link to={routes.Producers}>
              <OutlineBtn title="Cancel" isWide={false} />
            </Link>
            <PrimaryBtn title="Update producer" isWide={false} />
          </ActionsRow>
        </Form>
      </Page>
    </Fragment>
  );
};

interface FormProps {
  initialEmail?: string;
}

const entSchema = yup.object({}).shape({
  email: validatinShemas.vEmailReq,
  name: validatinShemas.vNameReq,
  phone: validatinShemas.vPhoneReq,
});

export const exchangeFormic = withFormik<FormProps & OtherProps, FormValues>({
  enableReinitialize: true,
  mapPropsToValues: ({ producer }) => {
    const map = {
      id: 0,
      email: '',
      name: '',
      phone: '',
      isFreelancer: true,
      isActive: true,
    };

    return {
      ...map,
      ..._.pickBy(_.pick(producer, _.keys(map)), _.negate(_.isNil)),
    };
  },

  validationSchema: entSchema,

  handleSubmit: (values, { props, setSubmitting }) => {
    props.updateProducerReq(values);
  },
  displayName: 'EditForm',
});

export default compose<OtherProps & FormikProps<FormValues>, {}>(
  connect(
    {},
    { updateProducerReq }
  ),
  exchangeFormic
)(EditForm);

const style = {
  description: css`
    font-size: 12px;
    color: ${Colors.LightBlack};
    margin: 5px;
  `,
};
