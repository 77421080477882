import si, { ImmutableObject } from 'seamless-immutable';
import * as ru from '../../../../../utils/redux';
import { IProducer } from './types';
import _ from 'lodash';
import * as actions from './actions';

interface IListParams {
  page: number;
}

interface IState {
  // LIST
  list: IProducer[];
  listLoading: boolean;
  listError: string;
  page: number;
  search: string;
  total: number;
  // ITEM
  item: IProducer | null;
  itemLoading: boolean;
  itemError: string;
}

const INITIAL_STATE: ImmutableObject<IState> = si({
  // LIST
  list: [],
  listLoading: false,
  listError: '',
  page: 0,
  search: '',
  total: 0,
  // ITEM
  item: null,
  itemLoading: false,
  itemError: '',
});

export default ru.processActions(
  () => ({
    [actions.GET_PRODUCERS_REQ]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('listLoading', true);
    },
    [actions.GET_PRODUCERS_REQ_SUCCESS]: (store: ImmutableObject<IState>, action: any) => {
      const {
        payload: { data, params },
        meta,
      } = action;
      const page = params.page || 0;

      return store
        .set('list', page > 0 ? store.list.concat(data) : data)
        .set('page', page)
        .set('total', meta.total || 0)
        .set('listLoading', false);
    },
    [actions.GET_PRODUCERS_REQ_ERROR]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('listLoading', false).set('listError', action.payload);
    },
    [actions.ADD_PRODUCER_TO_LIST]: (store: ImmutableObject<IState>, action: any) => {
      return store.updateIn(['list'], (list) => list.concat({ ...action.payload, isActive: true }));
    },
    [actions.SET_PRODUCER]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('item', _.head(action.payload));
    },
    [actions.CLEAR_PRODUCER]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('item', null);
    },
    [actions.UPDATE_PRODUCER]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('item', _.head(action.payload));
    },
    [actions.DELETE_PRODUCER_REQ_SUCCESS]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('listLoading', false);
    },
    [actions.DELETE_PRODUCER_REQ_ERROR]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('listLoading', false);
    },
  }),
  INITIAL_STATE
);
