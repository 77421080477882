/** @jsx jsx */
import { Fragment, Component } from 'react';
import { jsx, css } from '@emotion/core';

// redux
import { connect, STATUS_ACTUAL } from '../../../../utils/redux';
import { info } from './store/selectors';
import { getGeneralInfoReq, updateGeneralInfoReq } from './store/actions';

// types
import { GeneralInfoItemState } from './store/types';

//components
import PageHeader from '../../../common/page/PageHeader';
import Widget from '../../../common/widget/Widget';
import Form from './Form';
import Info from './Info';
import AsyncData from '../../../common/async/AsyncData';
import Loader from '../../../common/loader/Loader';

import { Action } from '../../../../store/types';

import * as styles from './styles';

interface IProps {
  info: GeneralInfoItemState;
  getGeneralInfoReq: Action<{}>;
  updateGeneralInfoReq: Action<{}>;
}

interface IState {
  isEditMode: boolean;
}

jsx;
class GeneralInfo extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isEditMode: false,
    };
  }

  componentDidMount(): void {
    const { getGeneralInfoReq } = this.props;
    getGeneralInfoReq({});
  }

  onSave = (values: any) => {
    const { updateGeneralInfoReq } = this.props;
    updateGeneralInfoReq(values).then(() => {
      this.onCloseEdit();
    });
  };

  onEditStart = () => {
    this.setState({ isEditMode: true });
  };

  onCloseEdit = () => {
    this.setState({ isEditMode: false });
  };

  render() {
    const { info } = this.props;
    const { isEditMode } = this.state;
    let currentView;

    if (info.status === STATUS_ACTUAL) {
      const view = isEditMode ? (
        <Form onSave={this.onSave} onClose={this.onCloseEdit} item={info.data} />
      ) : (
        <Info onEditStart={this.onEditStart} item={info.data} />
      );
      currentView = <div css={styles.mainBox}>{view}</div>;
    } else {
      currentView = <Loader isLoading={true} />;
    }

    return (
      <Fragment>
        <PageHeader title="General info" separateTitle={false} />
        <Widget>{currentView}</Widget>
      </Fragment>
    );
  }
}

export default connect(
  { info },
  { getGeneralInfoReq, updateGeneralInfoReq }
)(GeneralInfo);
