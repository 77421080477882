import { createAction } from 'redux-actions';

import reducersRegister from '../../../../../../../utils/reducersRegister';
import sagasRegister from '../../../../../../../utils/sagasRegister';
import { Action } from '../../../../../../../store/types';
import { createAsyncAction, createPromisifyAsyncAction } from '../../../../../../../utils/async/actions';

import redusers from './reducers';
import sagas from './sagas';

reducersRegister.register('machinery', redusers);
sagasRegister.register(sagas);

/******************************************************************************/
/**************************** GET MACHINERIES **********************************/
/******************************************************************************/

export const GET_MACHINERIES_REQ: string = 'GET_MACHINERIES_REQ';
export const GET_MACHINERIES_REQ_SUCCESS: string = 'GET_MACHINERIES_REQ_SUCCESS';
export const GET_MACHINERIES_REQ_ERROR: string = 'GET_MACHINERIES_REQ_ERROR';
export const SET_MACHINERIES: string = 'SET_MACHINERIES';
export const SET_MACHINERIES_PARAMS: string = 'SET_MACHINERIES_PARAMS';
export const PRINT_MACHINERIES_REQ: string = 'PRINT_MACHINERIES_REQ';
export const DOWNLOAD_MACHINERIES_REQ: string = 'DOWNLOAD_MACHINERIES_REQ';
export const CLEAR_MACHINERIES_DATA: string = 'CLEAR_MACHINERIES_DATA';

export const getMachineriesReq: Action<{}> = createAsyncAction(GET_MACHINERIES_REQ);
export const getMachineriesReqSuccess: Action<{}> = createAction(GET_MACHINERIES_REQ_SUCCESS);
export const getMachineriesReqError: Action<{}> = createAction(GET_MACHINERIES_REQ_ERROR);
export const setMachineries: Action<{}> = createAction(SET_MACHINERIES);
export const setMachineriesParams: Action<{}> = createAction(SET_MACHINERIES_PARAMS);
export const printMachineriesReq: Action<{}> = createAction(PRINT_MACHINERIES_REQ);
export const downloadMachineriesReq: Action<{}> = createAction(DOWNLOAD_MACHINERIES_REQ);

export const clearMachineriesData: Action<{}> = createAction(CLEAR_MACHINERIES_DATA);

/******************************************************************************/
/******************************* CREATE MACHINERY ******************************/
/******************************************************************************/

export const CREATE_MACHINERY_REQ: string = 'CREATE_MACHINERY_REQ';
export const CREATE_MACHINERY_REQ_SUCCESS: string = 'CREATE_MACHINERY_REQ_SUCCESS';
export const CREATE_MACHINERY_REQ_ERROR: string = 'CREATE_MACHINERY_REQ_ERROR';
export const ADD_MACHINERY: string = 'ADD_MACHINERY';

export const createMachineryReq: Action<{}> = createPromisifyAsyncAction(CREATE_MACHINERY_REQ);
export const createMachineryReqSuccess: Action<{}> = createAction(CREATE_MACHINERY_REQ_SUCCESS);
export const createMachineryReqError: Action<{}> = createAction(CREATE_MACHINERY_REQ_ERROR);
export const addMachinery: Action<{}> = createAction(ADD_MACHINERY);

/******************************************************************************/
/******************************* GET ONE MACHINERY *****************************/
/******************************************************************************/

export const GET_MACHINERY_REQ: string = 'GET_MACHINERY_REQ';
export const GET_MACHINERY_REQ_SUCCESS: string = 'GET_MACHINERY_REQ_SUCCESS';
export const GET_MACHINERY_REQ_ERROR: string = 'GET_MACHINERY_REQ_ERROR';
export const SET_ACTIVITI: string = 'SET_ACTIVITI';

export const getMachineryReq: Action<{}> = createAsyncAction(GET_MACHINERY_REQ);
export const getMachineryReqSuccess: Action<{}> = createAction(GET_MACHINERY_REQ_SUCCESS);
export const getMachineryReqError: Action<{}> = createAction(GET_MACHINERY_REQ_ERROR);
export const setMachinery: Action<{}> = createAction(SET_ACTIVITI);

/******************************************************************************/
/******************************* UPDATE ONE MACHINERY **************************/
/******************************************************************************/

export const UPDATE_MACHINERY_REQ: string = 'UPDATE_MACHINERY_REQ';
export const MOVE_MACHINERY_USER_REQ: string = 'MOVE_MACHINERY_USER_REQ';
export const UPDATE_MACHINERY_REQ_SUCCESS: string = 'UPDATE_MACHINERY_REQ_SUCCESS';
export const UPDATE_MACHINERY_REQ_ERROR: string = 'UPDATE_MACHINERY_REQ_ERROR';
export const UPDATE_MACHINERY: string = 'UPDATE_MACHINERY';

export const updateMachineryReq: Action<{}> = createPromisifyAsyncAction(UPDATE_MACHINERY_REQ);
export const moveMachineryUserReq: Action<{}> = createAsyncAction(MOVE_MACHINERY_USER_REQ);
export const updateMachineryReqSuccess: Action<{}> = createAction(UPDATE_MACHINERY_REQ_SUCCESS);
export const updateMachineryReqError: Action<{}> = createAction(UPDATE_MACHINERY_REQ_ERROR);
export const updateMachinery: Action<{}> = createAction(UPDATE_MACHINERY);

/******************************************************************************/
/******************************* DELETE ONE MACHINERY **************************/
/******************************************************************************/

export const DELETE_MACHINERY_REQ: string = 'DELETE_MACHINERY_REQ';
export const DELETE_MACHINERY_REQ_SUCCESS: string = 'DELETE_MACHINERY_REQ_SUCCESS';
export const DELETE_MACHINERY_REQ_ERROR: string = 'DELETE_MACHINERY_REQ_ERROR';
export const DELETE_MACHINERY: string = 'DELETE_MACHINERY';

export const deleteMachineryReq: Action<{}> = createAsyncAction(DELETE_MACHINERY_REQ);
export const deleteMachineryReqSuccess: Action<{}> = createAction(DELETE_MACHINERY_REQ_SUCCESS);
export const deleteMachineryReqError: Action<{}> = createAction(DELETE_MACHINERY_REQ_ERROR);
export const deleteMachinery: Action<{}> = createAction(DELETE_MACHINERY);

/******************************************************************************/
/********************************* CLONE MACHINERY ****************************/
/******************************************************************************/

export const CLONE_MACHINERY_REQ: string = 'CLONE_MACHINERY_REQ';
export const CLONE_MACHINERY_REQ_SUCCESS: string = 'CLONE_MACHINERY_REQ_SUCCESS';
export const CLONE_MACHINERY_REQ_ERROR: string = 'CLONE_MACHINERY_REQ_ERROR';

export const cloneMachineryReq: Action<{}> = createAsyncAction(CLONE_MACHINERY_REQ);
export const cloneMachineryReqSuccess: Action<{}> = createAction(CLONE_MACHINERY_REQ_SUCCESS);
export const cloneMachineryReqError: Action<{}> = createAction(CLONE_MACHINERY_REQ_ERROR);
