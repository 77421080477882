/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import routes from '../../../../routes';

import React from 'react';
import List from './List';
import Create from './Create';
import Edit from './Edit';
import withAcl, { IWrappedProps } from '../withAcl';

interface IProps extends IWrappedProps {}

jsx;

const Producers: FunctionComponent<IProps> = ({ test }): ReactElement<IProps> => {
  return (
    <Fragment>
      <Switch>
        <Route exact path={routes.Producers} component={List} />
        {test('producer', 'C') && <Route exact path={routes.ProducersCreate} component={Create} />}
        {test('producer', 'U') && <Route exact path={routes.ProducersEdit} component={Edit} />}
        <Route component={() => <Redirect to={routes.Producers} />} />
      </Switch>
    </Fragment>
  );
};

export default withAcl(Producers);
