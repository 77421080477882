/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import * as styles from './styles';

export interface IProps {}

jsx;

export const MainInfoCol: FunctionComponent<IProps> = ({ children }): ReactElement<IProps> => {
  return (
    <div className="info-col" css={styles.mainInfoColBox}>
      {children}
    </div>
  );
};

export default MainInfoCol;
