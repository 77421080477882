import { createAction } from 'redux-actions';
import { Action } from '../../../../../store/types';
import { createAsyncAction } from '../../../../../utils/async/actions';

import reducersRegister from '../../../../../utils/reducersRegister';
import sagasRegister from '../../../../../utils/sagasRegister';
import reducers from './reducers';
import sagas from './sagas';

reducersRegister.register('categories', reducers);
sagasRegister.register(sagas);

// ACTION TYPES
export const GET_ALL_CATEGORIES_REQUEST = 'GET_ALL_CATEGORIES_REQUEST';
export const GET_ALL_CATEGORIES_REQUEST_SUCCESS = 'GET_ALL_CATEGORIES_REQUEST_SUCCESS';
export const GET_ALL_CATEGORIES_REQUEST_ERROR = 'GET_ALL_CATEGORIES_REQUEST_ERROR';
export const SET_ALL_CATEGORIES = 'SET_ALL_CATEGORIES';

export const CATEGORIES_LOAD_REQUEST = 'CATEGORIES_LOAD_REQUEST';
export const CATEGORIES_LOAD_REQUEST_SUCCESS = 'CATEGORIES_LOAD_REQUEST_SUCCESS';
export const CATEGORIES_LOAD_REQUEST_ERROR = 'CATEGORIES_LOAD_REQUEST_ERROR';

export const CATEGORY_ADD_REQUEST = 'CATEGORY_ADD_REQUEST';
export const CATEGORY_ADD_REQUEST_SUCCESS = 'CATEGORY_ADD_REQUEST_SUCCESS';
export const CATEGORY_ADD_REQUEST_ERROR = 'CATEGORY_ADD_REQUEST_ERROR';

export const CATEGORY_UPDATE_REQUEST = 'CATEGORY_UPDATE_REQUEST';
export const CATEGORY_UPDATE_REQUEST_SUCCESS = 'CATEGORY_UPDATE_REQUEST_SUCCESS';
export const CATEGORY_UPDATE_REQUEST_ERROR = 'CATEGORY_UPDATE_REQUEST_ERROR';

export const CATEGORY_EDIT_REQUEST = 'CATEGORY_EDIT_REQUEST';
export const CATEGORY_EDIT_REQUEST_SUCCESS = 'CATEGORY_EDIT_REQUEST_SUCCESS';
export const CATEGORY_EDIT_REQUEST_ERROR = 'CATEGORY_EDIT_REQUEST_ERROR';

export const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST';
export const CATEGORY_DELETE_REQUEST_SUCCESS = 'CATEGORY_DELETE_REQUEST_SUCCESS';
export const CATEGORY_DELETE_REQUEST_ERROR = 'CATEGORY_DELETE_REQUEST_ERROR';

export const CATEGORY_LOAD_REQUEST = 'CATEGORY_LOAD_REQUEST';
export const CATEGORY_LOAD_REQUEST_SUCCESS = 'CATEGORY_LOAD_REQUEST_SUCCESS';
export const CATEGORY_LOAD_REQUEST_ERROR = 'CATEGORY_LOAD_REQUEST_ERROR';

// ACTIONS

// GET ALL CATEGORIES
export const getAllCategoriesRequest: Action<{}> = createAsyncAction(GET_ALL_CATEGORIES_REQUEST);
export const getAllCategoriesRequestSuccess: Action<{}> = createAction(GET_ALL_CATEGORIES_REQUEST_SUCCESS);
export const getAllCategoriesRequestError: Action<{}> = createAction(GET_ALL_CATEGORIES_REQUEST_ERROR);
export const setAllCategories: Action<{}> = createAction(SET_ALL_CATEGORIES);

// GET CATEGORIES LIST
export const categoriesLoadRequest: Action<{}> = createAsyncAction(CATEGORIES_LOAD_REQUEST);
export const categoriesLoadRequestSuccess: Action<{}> = createAction(
  CATEGORIES_LOAD_REQUEST_SUCCESS,
  (...args) => args[0],
  (...args) => args[1]
);
export const categoriesLoadRequestError: Action<{}> = createAction(CATEGORIES_LOAD_REQUEST_ERROR);

// ADD CATEGORY
export const categoryAddRequest: Action<{}> = createAsyncAction(CATEGORY_ADD_REQUEST);
export const categoryAddRequestSuccess: Action<{}> = createAction(CATEGORY_ADD_REQUEST_SUCCESS);
export const categoryAddRequestError: Action<{}> = createAction(CATEGORY_ADD_REQUEST_ERROR);

// EDIT CATEGORY
export const categoryEditRequest: Action<{}> = createAsyncAction(CATEGORY_EDIT_REQUEST);
export const categoryEditRequestSuccess: Action<{}> = createAction(CATEGORY_EDIT_REQUEST_SUCCESS);
export const categoryEditRequestError: Action<{}> = createAction(CATEGORY_EDIT_REQUEST_ERROR);

// GET CATEGORY
export const oneCategoryLoadRequest: Action<{}> = createAsyncAction(CATEGORY_LOAD_REQUEST);
export const oneCategoryLoadRequestSuccess: Action<{}> = createAction(CATEGORY_LOAD_REQUEST_SUCCESS);
export const oneCategoryLoadRequestError: Action<{}> = createAction(CATEGORY_LOAD_REQUEST_ERROR);

// DELETE CATEGORY
export const categoryDeleteRequest: Action<{}> = createAsyncAction(CATEGORY_DELETE_REQUEST);
export const categoryDeleteRequestSuccess: Action<{}> = createAction(CATEGORY_DELETE_REQUEST_SUCCESS);
export const categoryDeleteRequestError: Action<{}> = createAction(CATEGORY_DELETE_REQUEST_ERROR);
