/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { compose } from 'recompose';
import _ from 'lodash';

import { connect } from '../../../utils/redux';
import * as collection from '../../../utils/collection';

import { profile } from './User/store/selectors';
import { UserItemState } from './User/store/types';
import * as configAcl from '../../../configAcl';

interface IProps {
  profile: UserItemState;
}

export interface IWrappedProps {
  someResources: any;
  everyResources: any;
  someActions: any;
  everyActions: any;
  test: any;
  isAdmin: any;
  testRole: any;
}

jsx;
const withACL = (WrappedComponent: any) => {
  class WithACL extends Component<any, {}> {
    constructor(props: any) {
      super(props);
    }

    mergePermissions = (permissionA: any, permissionB: any) => {
      permissionA = _.mapValues(permissionA, (value: string[], key: string) => {
        const resourcePermissionsB = permissionB[key] ? permissionB[key] : [];
        return _.uniq([...resourcePermissionsB, ...value]);
      });

      return { ...permissionB, ...permissionA };
    };

    getUserPermissions = () => {
      const {
        profile: { data },
      } = this.props;

      if (data) {
        const { roles } = data;
        return roles.reduce((result: any, role: string) => {
          return this.mergePermissions(result, configAcl.getRolePermissions(role));
        }, {});
      }
      return false;
    };

    getEntityRoleResourcePermissions = (entity: any, role: any, resource: any) => {
      const relatedResource = _.get(configAcl.RELATED_RESOURCES, resource);
      const relatedRoleResource = collection.selectOneBy(relatedResource, { roles: role });
      const resolver = _.get(relatedRoleResource, 'resolver');
      return resolver ? resolver(entity, this.props.profile.data) : [];
    };

    getRoles = () => {
      const profile = _.get(this.props, 'profile.data');
      const roles = _.get(profile, 'roles', []);
      return roles.map((role: any) => configAcl.defineRole(role, profile));
    };

    getUserResourcePermissions = (resource: string, entity: any = null) => {
      const roles = this.getRoles();
      //const roles = ['PRODUCER'];

      return roles.reduce((result: any, role: string) => {
        //role = 'CLIENT';
        let permissions = entity
          ? this.getEntityRoleResourcePermissions(entity, role, resource)
          : configAcl.getRoleResourcePermissions(role, resource);

        if (!_.isArray(permissions)) {
          permissions = permissions === true ? configAcl.getRoleResourcePermissions(role, resource) : permissions;
          permissions = [false, null].includes(permissions) ? [] : permissions;
        }

        return _.uniq([...result, ...permissions]);
      }, []);
    };

    testPair = (resource: string, action = 'R', entity: any = null) => {
      const permissions = this.getUserResourcePermissions(resource, entity);

      if (resource === 'production' && action === 'E') {
        console.log(permissions);
      }

      return permissions.includes(action);
    };

    someResources = (resource: string) => (entity: any = null) => {
      return _.flow(
        this.testResources(this.asArrayCommonUtil(resource), entity),
        _.some
      );
    };

    everyResources = (resources: string) => (entity: any = null) => {
      return _.flow(
        this.testResources(this.asArrayCommonUtil(resources), entity),
        _.every
      );
    };

    testResources = (resources: any, entity: any = null) => (actions: any) => {
      if (!resources.length) return [false];
      return _.map(resources, (resource) => {
        return actions(resource);
      });
    };

    someActions = (actions: string | string[]) => (entity: any = null) => {
      return _.flow(
        this.testActions(this.asArrayCommonUtil(actions), entity),
        _.some
      );
    };

    everyActions = (actions: string | string[]) => (entity: any = null) => {
      return _.flow(
        this.testActions(this.asArrayCommonUtil(actions), entity),
        _.every
      );
    };

    testActions = (actions: string | string[], entity: any = null) => (resource: any) => {
      return _.map(actions, _.partial(this.testPair, resource, _, entity));
    };

    //TODO move to common utils
    asArrayCommonUtil = (value: any) => {
      return _.filter(_.castArray(value), _.identity);
    };

    test = (resources: any, actions: any, entity: any = null) => {
      resources = this.resolveResources(resources)(entity);
      actions = this.resolveActions(actions)(entity);
      return resources(actions);
    };

    resolveResources = (resources: any) => {
      return _.isFunction(resources) ? resources : this.someResources(resources);
    };

    resolveActions = (actions: string | string[] = ['R']) => {
      return _.isFunction(actions) ? actions : this.someActions(actions);
    };

    isAdmin = () => {
      return this.getRoles().includes(configAcl.ADMIN_ROLE);
    };

    testRole = (role: string) => {
      return this.getRoles().includes(role);
    };

    publicFunctions = () => {
      return {
        someResources: this.someResources,
        everyResources: this.everyResources,
        someActions: this.someActions,
        everyActions: this.everyActions,
        test: this.test,
        isAdmin: this.isAdmin,
        testRole: this.testRole,
      };
    };

    render() {
      return <WrappedComponent {...this.props} {...this.publicFunctions()} />;
    }
  }

  return WithACL;
};

export default compose<any, any>(
  connect({ profile }),
  withACL
);
