import { createAction } from 'redux-actions';

import reducersRegister from '../../../../../../utils/reducersRegister';
import sagasRegister from '../../../../../../utils/sagasRegister';
import { Action } from '../../../../../../store/types';
import { createAsyncAction } from '../../../../../../utils/async/actions';

import redusers from './reducers';
import sagas from './sagas';

reducersRegister.register('clientAgents', redusers);
sagasRegister.register(sagas);

/******************************************************************************/
/******************************* AGENTS ***************************************/
/******************************************************************************/

export const GET_CLIENT_AGENTS_REQ: string = 'GET_CLIENT_AGENTS_REQ';
export const GET_CLIENT_AGENTS_REQ_SUCCESS: string = 'GET_CLIENT_AGENTS_REQ_SUCCESS';
export const GET_CLIENT_AGENTS_REQ_ERROR: string = 'GET_CLIENT_AGENTS_REQ_ERROR';

export const SET_CLIENT_AGENTS: string = 'SET_CLIENT_AGENTS';
export const REFRESH_CLIENT_AGENTS: string = 'REFRESH_CLIENT_AGENTS';
export const CLEAR_CLIENT_AGENTS: string = 'CLEAR_CLIENT_AGENTS';
export const SET_CLIENT_AGENTS_PARAMS: string = 'SET_CLIENT_AGENTS_PARAMS';

export const getClientAgentsReq: Action<{}> = createAsyncAction(GET_CLIENT_AGENTS_REQ);
export const getClientAgentsReqSuccess: Action<{}> = createAction(
  GET_CLIENT_AGENTS_REQ_SUCCESS,
  (...args) => args[0],
  (...args) => args[1]
);
export const getClientAgentsReqError: Action<{}> = createAction(GET_CLIENT_AGENTS_REQ_ERROR);

export const setClientAgents: Action<{}> = createAction(SET_CLIENT_AGENTS);
export const refreshClientAgents: Action<{}> = createAction(REFRESH_CLIENT_AGENTS);
export const clearClientAgents: Action<{}> = createAction(CLEAR_CLIENT_AGENTS);
export const setClientAgentsParams: Action<{}> = createAction(SET_CLIENT_AGENTS_PARAMS);

/******************************************************************************/
/******************************* CREATE CLIENT_AGENT *********************************/
/******************************************************************************/

export const CREATE_CLIENT_AGENT_REQ: string = 'CREATE_CLIENT_AGENT_REQ';
export const CREATE_CLIENT_AGENT_IN_TAB_REQ: string = 'CREATE_CLIENT_AGENT_IN_TAB_REQ';
export const ADD_CLIENT_AGENT: string = 'ADD_CLIENT_AGENT';

export const createClientAgentReq: Action<{}> = createAsyncAction(CREATE_CLIENT_AGENT_REQ);
export const createClientAgentInTabReq: Action<{}> = createAsyncAction(CREATE_CLIENT_AGENT_IN_TAB_REQ);
export const addClientAgent: Action<{}> = createAsyncAction(ADD_CLIENT_AGENT);

/******************************************************************************/
/******************************* GET ONE CLIENT_AGENT   ******************************/
/******************************************************************************/

export const GET_CLIENT_AGENT_REQ: string = 'GET_CLIENT_AGENT_REQ';
export const GET_CLIENT_AGENT_REQ_SUCCESS: string = 'GET_CLIENT_AGENT_REQ_SUCCESS';
export const GET_CLIENT_AGENT_REQ_ERROR: string = 'GET_CLIENT_AGENT_REQ_ERROR';
export const SET_CLIENT_AGENT: string = 'SET_CLIENT_AGENT';
export const CLEAR_CLIENT_AGENT: string = 'CLEAR_CLIENT_AGENT';

export const getClientAgentReq: Action<{}> = createAsyncAction(GET_CLIENT_AGENT_REQ);
export const getClientAgentReqSuccess: Action<{}> = createAction(GET_CLIENT_AGENT_REQ_SUCCESS);
export const getClientAgentReqError: Action<{}> = createAction(GET_CLIENT_AGENT_REQ_ERROR);
export const setClientAgent: Action<{}> = createAction(SET_CLIENT_AGENT);
export const clearClientAgent: Action<{}> = createAction(CLEAR_CLIENT_AGENT);

/******************************************************************************/
/******************************* UPDATE ONE CLIENT_AGENT   ***************************/
/******************************************************************************/

export const UPDATE_CLIENT_AGENT_REQ: string = 'UPDATE_CLIENT_AGENT_REQ';
export const UPDATE_CLIENT_AGENT_REQ_SUCCESS: string = 'UPDATE_CLIENT_AGENT_REQ_SUCCESS';
export const UPDATE_CLIENT_AGENT_REQ_ERROR: string = 'UPDATE_CLIENT_AGENT_REQ_ERROR';
export const UPDATE_CLIENT_AGENT: string = 'UPDATE_CLIENT_AGENT';

export const updateClientAgentReq: Action<{}> = createAsyncAction(UPDATE_CLIENT_AGENT_REQ);

export const updateClientAgentReqSuccess: Action<{}> = createAction(UPDATE_CLIENT_AGENT_REQ_SUCCESS);

export const updateClientAgentReqError: Action<{}> = createAction(UPDATE_CLIENT_AGENT_REQ_ERROR);

export const updateClientAgent: Action<{}> = createAction(UPDATE_CLIENT_AGENT);

/******************************************************************************/
/******************************* DELETE ONE CLIENT_AGENT   ***************************/
/******************************************************************************/

export const DELETE_CLIENT_AGENT_REQ: string = 'DELETE_CLIENT_AGENT_REQ';
export const DELETE_CLIENT_AGENT_REQ_SUCCESS: string = 'DELETE_CLIENT_AGENT_REQ_SUCCESS';
export const DELETE_CLIENT_AGENT_REQ_ERROR: string = 'DELETE_CLIENT_AGENT_REQ_ERROR';
export const DELETE_CLIENT_AGENT: string = 'DELETE_CLIENT_AGENT';

export const deleteClientAgentReq: Action<{}> = createAsyncAction(DELETE_CLIENT_AGENT_REQ);

export const deleteClientAgentReqSuccess: Action<{}> = createAction(DELETE_CLIENT_AGENT_REQ_SUCCESS);

export const deleteClientAgentReqError: Action<{}> = createAction(DELETE_CLIENT_AGENT_REQ_ERROR);

export const deleteClientAgent: Action<{}> = createAction(DELETE_CLIENT_AGENT);

/******************************************************************************/
/******************************* SEND CLIENT_AGENT INVITATION   **********************/
/******************************************************************************/

export const SEND_CLIENT_AGENT_INVITATION_REQ: string = 'SEND_CLIENT_AGENT_INVITATION_REQ';
export const SEND_CLIENT_AGENT_INVITATION_SUCCESS: string = 'SEND_CLIENT_AGENT_INVITATION_SUCCESS';
export const SEND_CLIENT_AGENT_INVITATION_ERROR: string = 'SEND_CLIENT_AGENT_INVITATION_ERROR';

export const sendClientAgentInvitationReq: Action<{}> = createAsyncAction(SEND_CLIENT_AGENT_INVITATION_REQ);
export const sendClientAgentInvitationSuccess: Action<{}> = createAction(SEND_CLIENT_AGENT_INVITATION_SUCCESS);
export const sendClientAgentInvitationError: Action<{}> = createAction(SEND_CLIENT_AGENT_INVITATION_ERROR);
