import si from 'seamless-immutable';
import _ from 'lodash';
import * as redux from './redux';
import * as collection from './collection';

export const log = (...params) => {
  console.log(params);
};

export const createReducer = (stateKey) => (resolvers) => (state, { payload }) => {
  return si.updateIn(state, _.castArray(stateKey), (keyState) => {
    return runResolvers(_.castArray(resolvers), keyState, payload);
  });
};

export const runResolvers = (resolvers, prevState, changeState) => {
  return resolvers.reduce((result, resolver) => {
    return resolver(result, changeState);
  }, prevState);
};

export const setStaticValue = (field) => (value) => (state) => {
  return state.setIn(_.castArray(field), value);
};

export const setPayloadValue = (stateField, payloadField = null, resolvers = directResolver) => (state, payload) => {
  const payloadValue = payloadField ? _.get(payload, payloadField) : payload;
  return si.updateIn(state, _.castArray(stateField), (keyState) => {
    return runResolvers(_.castArray(resolvers), keyState, payloadValue);
  });
};

//**********************RESOLVERS*******************
export const setStatus = setStaticValue(redux.STATUS_FIELD);
export const setStatusNew = setStatus(redux.STATUS_NEW);
export const setStatusEmpty = setStatus(redux.STATUS_EMPTY);
export const setStatusOld = setStatus(redux.STATUS_OLD);
export const setStatusRequest = setStatus(redux.STATUS_REQUEST);
export const setStatusActual = setStatus(redux.STATUS_ACTUAL);
export const setStatusError = setStatus(redux.STATUS_ERROR);
export const setStatusSuccess = setStatus(redux.STATUS_SUCCESS);
export const setStatusCreated = setStatus(redux.STATUS_CREATED);
export const setStatusUpdated = setStatus(redux.STATUS_UPDATED);
export const setStatusDeleted = setStatus(redux.STATUS_DELETED);

export const clearDataField = setPayloadValue(redux.DATA_FIELD, null, () => null);
export const clearMetaField = setPayloadValue(redux.META_FIELD, null, () => ({}));
export const setDataField = (dataResolver) => setPayloadValue(redux.DATA_FIELD, redux.DATA_FIELD, dataResolver);

export const setUnlimitedDataField = setPayloadValue(redux.DATA_FIELD, null, (stateData, payload) => {
  const page = _.get(payload, [redux.PARAMS_FIELD, redux.PARAMS_PAGE], null);
  const data = _.get(payload, redux.DATA_FIELD, []);
  return !stateData || [null, 0].includes(page) ? data : [...stateData, ...data];
});

export const asHash = (key = 'id') => (state, payload) => _.keyBy(payload, key);
export const asSingle = (state, payloadValue) => _.head(payloadValue);
export const asEmptyArray = _.constant([]);
export const asEmptyObjectResolver = _.constant({});
export const directResolver = (state, payloadValue) => payloadValue;
export const mergeResolver = (state, payloadValue) => si.merge(state, payloadValue);

export const addDataResolver = (key = 'id') => (state, payloadValue) => {
  payloadValue = _.castArray(payloadValue);
  const stateIds = collection.extractKeyValue(state, key);
  state = updateDataResolver(key)(state, payloadValue);
  payloadValue = payloadValue.filter((item) => {
    return !stateIds.includes(item[key]);
  });

  return [...state, ...payloadValue];
};

export const updateDataResolver = (key = 'id') => (state, payloadValue) => {
  payloadValue = _.keyBy(_.castArray(payloadValue), key);
  return state.map((item) => {
    const newValue = payloadValue[item[key]];
    return newValue ? { ...item, ...newValue } : item;
  });
};

export const deleteDataResolver = (key = 'id') => (state, payloadValue) => {
  payloadValue = _.castArray(payloadValue);
  return state.filter((item) => {
    return !payloadValue.includes(_.get(item, key));
  });
};

export const setMetaField = setPayloadValue(redux.META_FIELD, redux.META_FIELD);
export const setErrorField = setPayloadValue(redux.ERROR_FIELD);

export const setParam = (paramName) => setPayloadValue([redux.PARAMS_FIELD, paramName]);

export const setParamsPage = setParam(redux.PARAMS_PAGE);
export const setParamsOffset = setParam(redux.PARAMS_OFFSET);
export const setParamsSearch = setParam(redux.PARAMS_SEARCH);
export const setParamsFilter = setParam(redux.PARAMS_FILTER);

export const setParams = setPayloadValue(redux.PARAMS_FIELD, null, mergeResolver);
export const setQueryParams = setPayloadValue(redux.PARAMS_FIELD, redux.PARAMS_FIELD, mergeResolver);

export const resetPage = (state) => {
  return state.setIn([redux.PARAMS_FIELD, redux.PARAMS_PAGE], 0);
};
export const resetSearch = (state) => {
  return state.setIn([redux.PARAMS_FIELD, redux.PARAMS_SEARCH], '');
};
export const resetFilter = (state) => {
  return state.setIn([redux.PARAMS_FIELD, redux.PARAMS_FILTER], 0);
};

//**********************REDUCERS*******************

// COMMON
export const errorReducer = [setErrorField, setStatusError];
export const successReducer = [setStatusSuccess];
export const loadingReducer = [setStatusRequest];

// CLEAR
export const clearData = [clearDataField, setStatusEmpty];
export const resetReducer = [clearDataField, clearMetaField, resetPage, resetSearch, resetFilter, setStatusEmpty];

//GET
export const refreshReducer = [setStatusOld];
export const listDataReducer = [setDataField(directResolver), setMetaField, setStatusActual];
export const unlimitedListDataReducer = [setUnlimitedDataField, setQueryParams, setMetaField, setStatusActual];
export const itemDataReducer = [setDataField(asSingle), setMetaField, setStatusActual, setParams];

//GET PARAMS
export const paramReducer = (paramName) => setParam(paramName);
export const paramsPageReducer = [setParamsPage, setStatusOld];
export const paramsSearchReducer = [setParamsSearch, setStatusOld, resetPage];
export const paramsFilterReducer = [setParamsFilter, setStatusOld, resetPage];

export const paramsReducer = [setParams, setStatusOld];

//ADD
export const addDataReducer = (key = 'id') => [setDataField(addDataResolver(key)), setStatusActual];

//CREATE
export const startCreateReducer = [setDataField(asEmptyObjectResolver), setStatusNew];
export const createdReducer = [setDataField(asSingle), setStatusCreated];

//UPDATE
export const updatedReducer = [setStatusUpdated];
export const updateDataReducer = (key = 'id') => [setDataField(updateDataResolver(key)), setStatusActual];

//DELETE
export const deletedReducer = [setStatusDeleted];
export const deleteDataReducer = (key = 'id') => [setPayloadValue(redux.DATA_FIELD, null, deleteDataResolver(key))];
