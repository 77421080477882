/** @jsx jsx */
import { jsx } from '@emotion/core';

import _ from 'lodash';
import { Component, ReactNode, MouseEvent, createRef } from 'react';

import * as styles from '../multiselect/styles';
import SelectedBtn from '../../buttons/ForwardedSelectedItem';
import OutlineBtn from '../../buttons/OutlineForwarded';
import * as object from '../../../../utils/object';
import * as collection from '../../../../utils/collection';
import * as array from '../../../../utils/array';
import Text from '../TextWithRef';
import TabRow from '../TabRow';
import Checkbox from '../InputCheckbox';
import { IListForSelect } from './ListForSelect';
import ListForSelect from './UnselectedItemsBlock';

export interface IScheme {
  id?: string;
  title: string;
}
export interface IElementToCreate {
  init: string;
  backToSelect: (e: any) => void;
  onCreateAndSelect: (name: string) => void;
}

export interface IProps {
  selected: number[];
  elementToCreate: (a: IElementToCreate) => ReactNode;
  listToSelect?: (a: IListForSelect) => void;
  actionCreate?: () => void;
  items: any[];
  scheme: IScheme;
  onChange: (a: number[]) => void;
  onResetForm?: () => void;
  noSelectedMsg?: string;
  selectAllBtn?: boolean;
  className?: string;
  onApply?: (e: any) => void;
  onPressEscape?: (e: any) => void;
  searchInAll?: string;
  onCheckAll?: (e: any) => void;
  checkAccess?: (id: number) => void;
}

export interface IState {
  search: string;
  tab: number;
}

export const NO_SELECTED_MESSAGE = 'No selected items';

export const convert = (items: any[], scheme: IScheme) => {
  return items.map((item) => object.buildData(item, scheme));
};

export const select = (convertedItems: any[], selected: number[]) => {
  return collection.selectBy(convertedItems, { id: selected });
};

export const exclude = (convertedItems: any[], selected: number[]) => {
  return collection.excludeBy(convertedItems, { id: selected });
};

jsx;
class MultiSelectWithTabs extends Component<IProps, IState> {
  private inputRef: any;
  private selectedItemRef: any;
  private applyBtnRef: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      search: '',
      tab: 0,
    };
    this.inputRef = createRef();
    this.applyBtnRef = createRef();
    this.selectedItemRef = [...Array(this.props.items.length)].map((r) => createRef());
  }
  componentDidMount() {
    setTimeout(() => {
      this.inputRef && this.inputRef.current && this.inputRef.current.focus();
    }, 0);
  }
  getSelectedItems = () => {
    const { scheme, selected, items } = this.props;
    const selectedItems: any[] = select(convert(items, scheme), selected);
    return selectedItems;
  };
  searchFilter = (items: any[], text: string) => {
    return items.filter((item) => item.title.toLowerCase().includes(text.toLowerCase()));
  };

  getUnselectedItems = () => {
    const { scheme, selected, items } = this.props;
    const { search } = this.state;
    const unselectedItems: any[] = exclude(convert(items, scheme), selected);
    return search ? this.searchFilter(unselectedItems, search) : unselectedItems;
  };

  removeSelected = (id: number) => {
    const { selected, onChange } = this.props;
    onChange(array.exclude(selected, id));
  };

  addNew = (ids: any) => {
    this.props.checkAccess && this.props.checkAccess(ids);
    this.props.onChange([...this.props.selected, ..._.castArray(ids)]);
    this.setState({ search: '' });
    this.inputRef && this.inputRef.current && this.inputRef.current.focus();
  };

  selectAll = () => {
    this.addNew(collection.extractKeyValue(this.getUnselectedItems(), 'id'));
  };

  clearAll = () => {
    this.props.onChange([]);
  };

  search = (e: any) => {
    this.setState({ search: e.currentTarget.value });
  };
  goToAddNew = (e: any) => {
    e.stopPropagation();
    this.setState({ tab: 1 });
  };
  goToSelect = (e: any) => {
    e && e.stopPropagation();
    this.setState({ tab: 0 });
  };

  handleClick = (e: MouseEvent | KeyboardEvent, selectedItemId: number, index?: number, maxIndex?: number) => {
    e.stopPropagation();
    e.preventDefault();
    this.removeSelected(selectedItemId);
    if (index === maxIndex) {
      this.inputRef && this.inputRef.current && this.inputRef.current.focus();
    }
  };

  handleKeys = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      !!this.props.onPressEscape && this.props.onPressEscape(event);
    }
    if (event.key === 'ArrowRight' || event.keyCode === 39) {
      this.goToAddNew(event);
    }
    if (event.key === 'ArrowLeft' || event.keyCode === 37) {
      this.goToSelect(event);
      setTimeout(() => {
        this.inputRef && this.inputRef.current && this.inputRef.current.focus();
      }, 0);
    }
  };
  switchFocusFromUnselectedItems = (event: KeyboardEvent) => {
    if (event.key === 'Tab' || event.keyCode === 9) {
      event.preventDefault();
      event.stopPropagation();
      if (this.selectedItemRef[0] && this.selectedItemRef[0].current) {
        this.selectedItemRef[0].current.focus();
      } else {
        this.inputRef && this.inputRef.current && this.inputRef.current.focus();
      }
    }
  };
  handleKeysOnApply = (event: KeyboardEvent) => {
    const { onApply, onPressEscape } = this.props;
    if (event.key === 'Enter' || event.keyCode === 13) {
      onApply && onApply(event);
    }
    if (event.key === 'Escape' || event.keyCode === 27) {
      !!onPressEscape && onPressEscape(event);
    }
  };
  handleKeysOnSearch = (event: KeyboardEvent) => {
    if (event.shiftKey === true && event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      if (this.selectedItemRef[0] && this.selectedItemRef[0].current) {
        this.selectedItemRef[0].current.focus();
      } else {
        this.applyBtnRef && this.applyBtnRef.current && this.applyBtnRef.current.focus();
      }
    }
  };
  handleAddNew = (e: KeyboardEvent) => {
    e.preventDefault();
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.setState({ tab: 1 });
    }
  };
  handleKeysOnSelectedItems = (event: KeyboardEvent, index: number, maxIndex: number) => {
    const { onPressEscape } = this.props;
    if (index === maxIndex && (event.key === 'Tab' || event.keyCode === 9)) {
      event.preventDefault();
      event.stopPropagation();
      this.inputRef && this.inputRef.current && this.inputRef.current.focus();
    }
    if (event.key === 'Escape' || event.keyCode === 27) {
      !!onPressEscape && onPressEscape(event);
    }
    if (event.shiftKey === true && event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      index === maxIndex
        ? this.inputRef && this.inputRef.current && this.inputRef.current.focus()
        : this.selectedItemRef[index + 1] &&
          this.selectedItemRef[index + 1].current &&
          this.selectedItemRef[index + 1].current.focus();
    }
  };

  applyAddingNew = (name: string) => {
    this.setState({ tab: 0, search: name });
    setTimeout(() => {
      const items = this.getUnselectedItems();
      if (items.length === 1) {
        this.selectAll();
      }
    }, 1600);
  };
  getNotFoundMessage = () => {
    const { search } = this.state;
    const { searchInAll } = this.props;
    return searchInAll
      ? `No results. You can add ${search} instead \n or search through all ${searchInAll}`
      : `No results. You can add ${search} instead`;
  };

  render() {
    const { noSelectedMsg, scheme, elementToCreate, onApply, onPressEscape, searchInAll, onCheckAll } = this.props;
    const { search, tab } = this.state;

    const selectedItems = this.getSelectedItems();
    const idKey = scheme.id || 'id';
    const noMatchesInSearch = this.getUnselectedItems().length < 1;
    const oneMatchInSearch = this.getUnselectedItems().length === 1;

    return (
      <div css={styles.multiSelectBox} onKeyDown={(e: any) => this.handleKeys(e)}>
        <div>
          <TabRow>
            <div onClick={this.goToSelect} css={styles.tabItem(tab === 0)}>
              Select
            </div>
            <div onClick={this.goToAddNew} css={styles.tabItem(tab === 1)}>
              Add new
            </div>
          </TabRow>
          {tab === 0 && (
            <div>
              <div css={styles.selectedBox}>
                {selectedItems.length > 0 ? (
                  selectedItems.map((item, index) => (
                    <SelectedBtn
                      key={item.id}
                      title={item.title}
                      onClick={(e: any) => this.handleClick(e, item.id, index, selectedItems.length - 1)}
                      tabIndex={1}
                      ref={this.selectedItemRef[index]}
                      onKeyDown={(e: any) => this.handleKeysOnSelectedItems(e, index, selectedItems.length - 1)}
                    />
                  ))
                ) : (
                  <div css={styles.multiSelectMsg}>{noSelectedMsg || NO_SELECTED_MESSAGE}</div>
                )}
              </div>
              <div css={styles.searchBox}>
                <Text
                  onChange={this.search}
                  value={search}
                  ref={this.inputRef}
                  onKeyDown={(e: any) => this.handleKeysOnSearch(e)}
                />
                {searchInAll && (
                  <label css={styles.checkboxLabel}>
                    <Checkbox onChange={onCheckAll} />
                    {`Search through all ${searchInAll}`}
                  </label>
                )}
              </div>
              {noMatchesInSearch && (
                <p
                  onClick={this.goToAddNew}
                  css={styles.addNewMessage}
                  tabIndex={0}
                  onKeyDown={(e: any) => this.handleAddNew(e)}
                >
                  {`No results. You can add ${search} instead`}
                </p>
              )}
              <div css={styles.actionsBox}>
                <OutlineBtn
                  type="button"
                  isSmall={true}
                  title="Apply"
                  onClick={onApply}
                  tabIndex={0}
                  ref={this.applyBtnRef}
                  onKeyPress={(e) => this.handleKeysOnApply(e)}
                />
                <OutlineBtn
                  type="button"
                  isSmall={true}
                  title="Add new"
                  onClick={this.goToAddNew}
                  tabIndex={oneMatchInSearch ? -1 : 0}
                />

                <OutlineBtn
                  type="button"
                  isSmall={true}
                  title="Clear all"
                  onClick={this.clearAll}
                  tabIndex={oneMatchInSearch ? -1 : 0}
                />
              </div>
              <div css={styles.variantsBox}>
                <ListForSelect
                  selectAllListItems={this.selectAll}
                  getUnselectedItemsList={this.getUnselectedItems}
                  addNewItemToList={this.addNew}
                  idKey={idKey}
                  onPressEscape={onPressEscape}
                />
              </div>
              <div tabIndex={0} onKeyDown={(e: any) => this.switchFocusFromUnselectedItems(e)} css={styles.hiddenBox}>
                {''}
              </div>
            </div>
          )}
          {tab === 1 && (
            <div>
              {!!elementToCreate &&
                elementToCreate({
                  init: search,
                  backToSelect: this.goToSelect,
                  onCreateAndSelect: (name) => this.applyAddingNew(name),
                })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MultiSelectWithTabs;
