/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link as RouteLink } from 'react-router-dom';
import { compose } from 'recompose';

// redux
import { connect } from '../../../../../../utils/redux';
import { production } from '../../store/selectors';
import { getProductionReq } from '../../store/actions';
import { getGatesReq, deleteGateReq, clearGates } from './store/actions';
import { gates } from './store/selectors';

// types
import { Action } from '../../../../../../store/types';
import { IProduction } from '../../store/types';
import { GatesListState } from './store/types';

// components
import GateCard from './GateCard';
import { Waypoint } from 'react-waypoint';
import Loader from '../../../../../common/loader/Loader';
import PageHeader from '../../../../../common/page/PageHeader';
import Breadcrumbs from '../../../../../common/page/Breadcrumbs';
import OutlineBtn from '../../../../../common/buttons/OutlineBtn';

// styles
import * as styles from './styles';

// utils
import _ from 'lodash';

// other
import Routes from '../../../../../../routes';
import { ListStatuses } from '../../../../../../utils/store/state/state';
import withAcl, { IWrappedProps } from '../../../withAcl';

interface MatchParams {
  id: string;
  gateId?: string;
}

interface IProps extends RouteComponentProps<MatchParams>, IWrappedProps {
  getProductionReq: Action<{}>;
  getGatesReq: Action<{}>;
  clearGates: Action<{}>;
  deleteGateReq: Action<{}>;
  clearProduction: Action<{}>;
  production: IProduction;
  gates: GatesListState;
}

jsx;

class GatesList extends Component<IProps> {
  componentDidMount() {
    const {
      production,
      getProductionReq,
      getGatesReq,
      clearGates,
      match: { params },
    } = this.props;

    clearGates();
    if (params.id !== String(_.get(production, 'id'))) getProductionReq(params.id);
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const {
      production,
      gates,
      getGatesReq,
      match: { params },
    } = this.props;

    if (production && !gates.data) {
      const productionId = _.get(production, 'main.id', production.id);
      getGatesReq({ productionId: productionId, size: 12, page: 0 });
    }
  }

  fetchMoreGates = () => {
    const {
      gates,
      getGatesReq,
      match: { params },
    } = this.props;
    const { page } = gates.params;

    getGatesReq({ ...gates.params, page: page + 1, size: 9, productionId: params.id });
  };

  onWaypointEnter = () => {
    const { gates } = this.props;

    if (gates.status === ListStatuses.Request) return;
    this.fetchMoreGates();
  };

  deleteGate = (id: number) => {
    const { deleteGateReq, production } = this.props;

    deleteGateReq({
      productionId: production.id,
      gateId: id,
    });
  };

  render() {
    const {
      gates,
      production,
      match: { params },
      test,
    } = this.props;
    const gatesList = gates.data;
    const isLoading = gates.status === ListStatuses.Request;
    const total = _.get(gates, 'meta.total', 0);
    const hasMoreSuppliers = total > _.get(gates.data, 'length', 0);

    return (
      <div>
        {production && (
          <PageHeader title="Gates">
            <Breadcrumbs
              path={[
                { title: 'Productions', link: Routes.Productions },
                { title: production.name, link: Routes.Production.replace(':id', `${params.id}`) },
                { title: 'Gates', link: Routes.ProductionGates.replace(':id', `${params.id}`) },
              ]}
            />
            {test('gates', 'C') && (
              <div>
                <RouteLink to={Routes.ProductionGatesCreate.replace(':id', `${params.id}`)}>
                  <OutlineBtn title="Add gate" />
                </RouteLink>
              </div>
            )}
          </PageHeader>
        )}

        <div css={styles.gatesWrap}>
          {gatesList &&
            (gatesList.length ? (
              gatesList.map((gate) => (
                <GateCard
                  key={gate.id}
                  gate={gate}
                  productionId={params.id}
                  active={params.gateId ? gate.id === +params.gateId : false}
                  onDeleteGate={this.deleteGate}
                />
              ))
            ) : (
              <div css={styles.noGatesTip}>No gates to show</div>
            ))}
        </div>

        <Loader isLoading={isLoading} />

        {hasMoreSuppliers && !isLoading && <Waypoint onEnter={this.onWaypointEnter} />}
      </div>
    );
  }
}

const enhance = compose<IProps, {}>(
  withRouter,
  connect(
    { production, gates },
    {
      getProductionReq,
      getGatesReq,
      clearGates,
      deleteGateReq,
    }
  )
);

export default enhance(withAcl(GatesList));
