/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import * as styles from './styles';

export interface IProps {
  title: string;
}

jsx;

export const WidgetHeader: FunctionComponent<IProps> = ({ children, title }): ReactElement<IProps> => {
  return (
    <div css={styles.widgetHeader}>
      <h3>{title}</h3>
      <div>{children}</div>
    </div>
  );
};

export default WidgetHeader;
