/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';

import { Component, Fragment } from 'react';
import TimelineValue from './TimelineValue';

import * as styles from '../styles';

export enum BarTypes {
  Activity = 'Activity',
  Transport = 'Transport',
}

export interface IProps {
  activity: any;
  scheme: string[];
  limitRange: any;
  workDay: any;
  color: string;
  barType: BarTypes;
  childColor?: string;
  isEditMode: boolean;
  onChange?: (range: any) => void;
  isSelected?: boolean;
  onSelect?: (selectedId: number) => void;
  onDeselect?: (e: any) => void;
  onClick?: (e: any) => void;
  htmlElementCollector?: (el: any) => void;
  onUnlink?: (e: any) => void;
  groupElements?: any;
}

export interface IState {}

const SECONDS_OF_DAY = 86400;

jsx;
class Timeline extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {}

  onSelect = (e: any) => {
    const { onSelect = _.identity, isEditMode } = this.props;
    if (isEditMode) {
      onSelect(e);
    }
  };

  onDeselect = (e: any) => {
    const { onDeselect = _.identity, isEditMode } = this.props;
    if (isEditMode) {
      onDeselect(e);
    }
  };

  onClick = (e: any) => {
    const { onClick = _.identity, isEditMode } = this.props;
    if (isEditMode) {
      onClick(e);
    }
  };

  render() {
    const {
      activity,
      scheme,
      workDay,
      limitRange,
      color,
      childColor,
      isEditMode = false,
      onChange,
      htmlElementCollector,
      groupElements,
      barType,
    } = this.props;

    const groupChildrenElements = _.omitBy(groupElements, (item, key) => {
      return parseInt(key) === activity.id;
    });

    return (
      <div css={styles.timelineBox}>
        {scheme.map((item, index) => {
          return <div key={index} className="timeline-cell" />;
        })}

        <TimelineValue
          limitRange={limitRange}
          boxRange={workDay}
          valueRange={activity.range}
          isEdit={isEditMode}
          color={color}
          barType={barType}
          childColor={childColor}
          onChange={onChange}
          isSelected={this.props.isSelected}
          onSelect={this.onSelect}
          onDeselect={this.onDeselect}
          onClick={this.onClick}
          onUnlink={this.props.onUnlink}
          htmlElementCollector={htmlElementCollector}
          groupElements={groupChildrenElements}
        />
      </div>
    );
  }
}

export default Timeline;
