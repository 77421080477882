import axios from 'axios';
import _ from 'lodash';
import { getToken } from './auth';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.release.atlasproductionplanning.com/api/v1';

export const query = (errorHandlers = {}) => {
  const instance = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {},
  });

  instance.interceptors.request.use(
    (config) => {
      return { ...config, headers: { ...config.headers, Authorization: `JWT ${getToken()}` } };
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        const handler = errorHandlers[error.response.status];
        if (typeof handler === 'function') {
          handler(error.response);
        }
        throw error.response;
      }
      throw error;
    }
  );

  return instance;
};

export const serialize = function(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
};

export const search = function(obj) {
  return _.values(obj).length ? `?${serialize(obj)}` : '';
};
