/** @jsx jsx */
import { Fragment, FunctionComponent, ReactElement } from 'react';
import { jsx } from '@emotion/core';

import { Route, Switch } from 'react-router';
import routes from '../../../../../../routes';

import List from './List';
import Edit from './Edit';
import Create from './Create';
import withAcl, { IWrappedProps } from '../../../withAcl';

interface IProps extends IWrappedProps {}

jsx;
const Gates: FunctionComponent<IProps> = ({ test }): ReactElement<IProps> => {
  return (
    <Fragment>
      <Switch>
        <Route exact path={routes.ProductionGates} component={List} />
        {test('gates', 'C') && <Route exact path={routes.ProductionGatesCreate} component={Create} />}
        {test('gates', 'U') && <Route exact path={routes.ProductionGatesEdit} component={Edit} />}
        <Route exact path={routes.ProductionGate} component={List} />
      </Switch>
    </Fragment>
  );
};

export default withAcl(Gates);
