/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Component, ReactElement } from 'react';
import * as redux from '../../../utils/redux';
import * as collection from '../../../utils/collection';
// components
import Loader from '../loader/Loader';
import Error from '../info/Error';
import AsyncDataCheckStatus from './AsyncDataCheckStatus';
// utils
import _ from 'lodash';

export interface IAsincDataItem {
  asyncData: any;
  asyncGetAction?: any;
  dependsOn?: string[];
  isHideContent?: boolean;
  params?: any;
}

export interface IProps {
  data: IAsincDataItem[];
  loadingComponent?: any;
  errorComponent?: any;
  wrappContent?: boolean;
  children: ReactElement<any> | any;
}

jsx;
class AsyncData extends Component<IProps, {}> {
  componentDidMount = () => {
    this.loadAsyncData();
  };

  componentDidUpdate = () => {
    this.loadAsyncData();
  };

  getAsyncDataByStatus = (statuses: string | string[]) => {
    return this.props.data.filter(({ asyncData }) => {
      return _.castArray(statuses).includes(asyncData.status);
    });
  };

  loadAsyncData = () => {
    this.getAsyncDataByStatus([redux.STATUS_EMPTY, redux.STATUS_OLD]).map(({ asyncData, asyncGetAction }) => {
      const action = asyncGetAction || _.identity;
      action(asyncData.params);
    });
  };

  isHideContent = () => {
    return this.getAsyncDataByStatus([redux.STATUS_REQUEST]).some((item) => !!_.get(item, 'isHideContent'));
  };

  isEmptyData = () => {
    const { data } = this.props;
    return data.some((item) => item.asyncGetAction && !item.asyncData.data);
  };

  render() {
    const { children, errorComponent, data, wrappContent = true } = this.props;
    const asyncData = collection.extractKeyValue(data, 'asyncData');

    const errors = asyncData.filter((item: any) => item.status === redux.STATUS_ERROR);
    const AsyncDataError = errorComponent || Error;

    const child = () => (typeof children === 'function' ? children() : children);

    return (
      <div css={[wrappContent && style.wrapper]}>
        <AsyncDataCheckStatus asyncData={asyncData} statuses={[redux.STATUS_REQUEST]}>
          <Loader isLoading />
        </AsyncDataCheckStatus>

        {/* proper place for handling errors is in sagas */}
        {/* {!!errors.length && <AsyncDataError errors={errors} />} */}

        {!this.isEmptyData() && !this.isHideContent() && child()}
      </div>
    );
  }
}

export default AsyncData;

const style = {
  wrapper: css`
    position: relative;
  `,
};
