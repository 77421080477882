/** @jsx jsx */
import { css } from '@emotion/core';

export const formSize = css({
    width: '400px',
});

export const formButtons = css({
    display: 'flex',
    marginTop: '70px',
    width: '500px',
    '& button': {
        flexGrow: 1,
        marginLeft: '10px',
        marginRight: '10px',
    }
});