/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import * as styles from '../../../styles/styles';

import vectorR from './images/vectorR.svg';
import moveUpArrow from '../../../images/icons/moveUpArrow.svg';

export const breadcrumbsBox = css({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  height: '100%',
  '& .breabcrumbs-item:after': {
    content: '""',
    position: 'absolute',
    top: '9px',
    right: '-20px',
    width: '20px',
    height: '20px',
    backgroundImage: `url(${vectorR})`,
    backgroundRepeat: 'no-repeat',
    transform: `scale(1.3)`,
  },

  '& .breabcrumbs-item:last-child::after': {
    content: '""',
    display: 'none',
    backgroundColor: 'red',
  },
});

export const breabcrumbsItem = [
  styles.LinkIn,
  css({
    position: 'relative',
    padding: '0 20px',
  }),
];

export const headerBox = css({
  display: 'flex',
  padding: '10px 0 30px 0',
});

export const headerTitle = (separateTitle = true) => [
  styles.H1,
  css({
    lineHeight: '40px',
    paddingRight: '30px',
    borderRight: separateTitle ? `2px solid ${styles.Colors.Grey}` : 0,
  }),
];

export const headerContent = css({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const moveUpBtn = css({
  width: '42px',
  height: '42px',
  borderRadius: '50%',
  backgroundColor: '#fff',
  border: '1px solid #fff',
  boxShadow: '2px 2px 7px 0px #96969640',
  position: 'relative',
  marginRight: '15px',
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '21px',
    height: '21px',
    backgroundImage: `url(${moveUpArrow})`,
    backgroundRepeat: 'no-repeat',
    top: '13px',
    left: '12px',
    transition: 'transform 0.25s ease',
  },
  '&:hover': {
    '&::after': {
      transform: 'translateY(-5px)',
    },
  },
});

export const scheduleType = css({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '1.05',
  color: styles.Colors.HardGrey,
});

export const scheduleName = css({
  fontSize: '11px',
  fontWeight: 400,
  lineHeight: '1.5',
  color: styles.Colors.HardGrey,
});

export const productionHeaderBox = css({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 0 30px 0',
  position: 'relative',
  zIndex: 4,
});

export const productionHeaderContent = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  paddingTop: '2px',
  columnGap: '10px',
});
export const dayTitle = css({
  fontSize: '17px',
  lineHeight: '1.05',
  color: styles.Colors.HardGrey,
  cursor: 'pointer',
  position: 'relative',
  marginRight: '15px',
});
export const leftSideProductionHeader = css({
  display: 'flex',
  alignItems: 'center',
});
export const datePicker = css({
  cursor: 'pointer',
});
export const dateBtn = css({
  background: `${styles.Colors.Green}`,
  color: 'white',
  width: '100%',
  borderRadius: 0,
  '&:hover': {
    background: `${styles.Colors.AltGreen}`,
  },
});
export const formBox = css({});
