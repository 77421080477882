import moment from 'moment';
import _ from 'lodash';

interface TimeRange {
  from: number;
  to: number;
}

export const datetimeToTimestamp = (dateTime: string) => {
  return moment.utc(dateTime).valueOf();
};

export const getStartRangeWorkDays = (start: any, key: any) => {
  start = moment.utc(start);
  const startWorkDay = moment
    .utc(start)
    .startOf(key)
    .add(6, 'hours');
  const result = start.isBefore(startWorkDay) ? startWorkDay.subtract(1, key) : startWorkDay;
  return result;
};

export const getRangeOfWorkDays = (start: any, end: any, key: any, arr?: any[] | null): any => {
  const startWorkDay = arr ? moment.utc(start) : moment.utc(getStartRangeWorkDays(start, key));
  const endWorkDay = moment.utc(startWorkDay).add(1, 'day');
  const workDayRange = { from: startWorkDay, to: endWorkDay };
  arr = arr ? arr.concat(workDayRange) : [workDayRange];
  return endWorkDay.isAfter(end) ? arr : getRangeOfWorkDays(endWorkDay, end, key, arr);
};

interface Intersection {
  before?: number;
  after?: number;
  inside?: boolean;
}

export const isIncludeRange = (range: TimeRange, includeRange: TimeRange) => {
  const from = moment.utc(includeRange.from).isSameOrAfter(moment.utc(range.from));
  const to = moment.utc(includeRange.to).isSameOrBefore(moment.utc(range.to));
  return from && to;
};

export const getIntersectionRanges = (intersectionWithRange: any, range: any) => {
  intersectionWithRange = _.mapValues(intersectionWithRange, (value) => moment.utc(value));
  range = _.mapValues(range, (value) => moment.utc(value));

  let result: Intersection | null = null;
  const isBefore = intersectionWithRange.from.isBetween(range.from, range.to);
  const isAfter = intersectionWithRange.to.isBetween(range.from, range.to);

  if (isBefore || isAfter) {
    result = { inside: false, before: 0, after: 0 };
    if (isBefore) {
      result.before = Math.ceil(intersectionWithRange.from.diff(range.from, 'hour') / 24);
    }

    if (isAfter) {
      result.after = Math.ceil(range.to.diff(intersectionWithRange.to, 'hour') / 24);
    }
  } else if (isIncludeRange(intersectionWithRange, range)) {
    result = { inside: true, before: 0, after: 0 };
  }

  return result;
};

export const checkRangesIntersection = (ranges: TimeRange[], result: boolean = false): boolean => {
  ranges = [...ranges];
  if (result || !ranges.length) {
    return result;
  }

  const range = ranges.pop();
  result = ranges.reduce((result: boolean, item: any) => {
    return result ? result : !!getIntersectionRanges(item, range);
  }, result);

  return checkRangesIntersection(ranges, result);
};

type SplitRangeByDay = (a: TimeRange) => TimeRange[];
export const splitRangeByDay: SplitRangeByDay = (rangeDays) => {
  return [];
};

export const limitRange = (range: any, limitRange: any) => {
  const from = limitFrom(range.from, limitRange.from);
  const to = limitTo(range.to, limitRange.to);
  return {
    from: from,
    to: to > from ? to : from,
  };
};

export const limitFrom = (from: any, limitFrom: any) => {
  from = tValue(from);
  limitFrom = tValue(limitFrom);
  return limitFrom - from > 0 ? limitFrom : from;
};

export const limitTo = (to: any, limitTo: any) => {
  to = tValue(to);
  limitTo = tValue(limitTo);
  return to - limitTo > 0 ? limitTo : to;
};

export const tValue = (time: any) => {
  return _.isNumber(time) ? time : moment.utc(time).valueOf();
};
