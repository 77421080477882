/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FunctionComponent, ReactElement } from "react";
import * as styles from './styles';

jsx;

interface IProps {
    header: string;
}

const FormHeader: FunctionComponent<IProps> = (props: IProps): ReactElement<IProps> => {
    return (
        <h3 css={styles.header}>{props.header}</h3>
    );
};

export default FormHeader;
