/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import * as styles from '../../../styles/styles';

export const privateBox = css({
  backgroundColor: styles.Colors.LightGreyBlue,
  minHeight: '100vh',
  width: '100%',
});
