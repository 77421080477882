/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import _ from 'lodash';

import { Component, Fragment } from 'react';

import * as styles from '../../../../../../../styles/styles';
import TimelineLinkBullet from './TimelineLinkBullet';

enum VERTICAL_DIRECTION {
  Top = 'top',
  Bottom = 'bottom',
}

enum HORIZONTAL_DIRECTION {
  Left = 'left',
  Right = 'right',
}

export interface IProps {
  fromEl: any;
  toEl: any;
  color?: any;
  isEdit?: boolean;
  unlinkId: any;
  onUnlink?: (unlinkId?: any) => void;
}

export interface IState {
  showUnlink: boolean;
}

jsx;
class TimelineLink extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showUnlink: false,
    };
  }

  componentDidMount(): void {
    window.addEventListener('resize', this.reRender);
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.reRender);
  }

  reRender = (): void => {
    this.forceUpdate();
  };

  mouseEnter = (e: any) => {
    this.setState({ showUnlink: true });
  };

  mouseLeave = (e: any) => {
    this.setState({ showUnlink: false });
  };

  getCenterPosition = (elInfo: any) => {
    return { top: elInfo.top + elInfo.height / 2, left: elInfo.left + elInfo.width / 2 };
  };

  getVerticalLength = (startPosition: any, toElInfo: any) => {
    const toElCenterPosition = this.getCenterPosition(toElInfo);
    const long = Math.abs(startPosition.top - toElCenterPosition.top);
    return this.isIntersection(startPosition, toElInfo) ? long - toElInfo.height / 2 : long;
  };

  getHorizontalLength = (startPosition: any, toElInfo: any, verticalDirection: any, horizontalDirection: any) => {
    const toElInfoRight = toElInfo.left + toElInfo.width;
    let length = 0;

    if (verticalDirection === VERTICAL_DIRECTION.Bottom) {
      length =
        horizontalDirection === HORIZONTAL_DIRECTION.Left
          ? startPosition.left + 1 - toElInfoRight
          : toElInfo.left - 1 - startPosition.left;
    } else {
      length =
        horizontalDirection === HORIZONTAL_DIRECTION.Left
          ? toElInfo.left - 1 - startPosition.left
          : startPosition.left + 1 - toElInfoRight;
    }

    return Math.abs(length);
  };

  getVerticalDirection = (from: number, to: number) => {
    return from < to ? VERTICAL_DIRECTION.Bottom : VERTICAL_DIRECTION.Top;
  };

  getHorizontalDirection = (startPosition: any, toElInfo: any, verticalDirection: VERTICAL_DIRECTION) => {
    const toElInfoRight = toElInfo.left + toElInfo.width;
    let result;

    if (startPosition.left > toElInfoRight) {
      result = VERTICAL_DIRECTION.Bottom === verticalDirection ? HORIZONTAL_DIRECTION.Left : HORIZONTAL_DIRECTION.Right;
    } else if (toElInfo.left > startPosition.left) {
      result = VERTICAL_DIRECTION.Bottom === verticalDirection ? HORIZONTAL_DIRECTION.Right : HORIZONTAL_DIRECTION.Left;
    }

    return result;
  };

  isIntersection = (startPosition: any, toElInfo: any) => {
    return startPosition.left > toElInfo.left && startPosition.left < toElInfo.left + toElInfo.width;
  };

  isRealElement = (toEl: any) => {
    const toElInfo = toEl.getBoundingClientRect();
    return !(toElInfo.x === 0 && toElInfo.y === 0);
  };

  getLinkParams = (fromEl: any, toEl: any) => {
    const fromElInfo = fromEl.getBoundingClientRect();
    const toElInfo = toEl.getBoundingClientRect();
    const startPosition = this.getCenterPosition(fromElInfo);
    const isIntersection = this.isIntersection(startPosition, toElInfo);

    const verticalLength = this.getVerticalLength(startPosition, toElInfo);
    const verticalDirection = this.getVerticalDirection(startPosition.top, toElInfo.top);
    const verticalRotate = verticalDirection === VERTICAL_DIRECTION.Top ? 180 : 0;

    const horizontalDirection = this.getHorizontalDirection(startPosition, toElInfo, verticalDirection);
    const horizontalLength = isIntersection
      ? 0
      : this.getHorizontalLength(startPosition, toElInfo, verticalDirection, horizontalDirection);

    const predicate = VERTICAL_DIRECTION.Bottom ? HORIZONTAL_DIRECTION.Left : HORIZONTAL_DIRECTION.Right;
    const horizontalRotate = horizontalDirection === HORIZONTAL_DIRECTION.Left ? 90 : -90;

    return { verticalLength, verticalRotate, horizontalLength, horizontalRotate };
  };

  render() {
    const { fromEl, toEl, color, isEdit = false, unlinkId, onUnlink = _.identity } = this.props;
    if (fromEl && toEl && this.isRealElement(toEl)) {
      const { verticalLength, verticalRotate, horizontalLength, horizontalRotate } = this.getLinkParams(fromEl, toEl);
      const isHorizontalLink = !!horizontalLength;
      const isHorizontalEnough = horizontalLength > 14;
      const showUnlink = this.state.showUnlink && isEdit;

      if (verticalLength) {
        return (
          <div
            css={linkBox(verticalLength, verticalRotate, color)}
            onMouseEnter={this.mouseEnter}
            onMouseLeave={this.mouseLeave}
          >
            {showUnlink && !isHorizontalEnough && isHorizontalLink && (
              <div
                css={unlinkButton(verticalLength)}
                onClick={(e: any) => {
                  onUnlink(unlinkId);
                }}
              >
                <div css={unlinkButtonLabel(0)} />
              </div>
            )}
            <div css={startBullet}>
              <TimelineLinkBullet color={color} />
            </div>

            {isHorizontalLink && (
              <div css={verticalLinkBox}>
                <div css={verticalLink(horizontalLength, horizontalRotate, color)}>
                  {showUnlink && isHorizontalEnough && (
                    <div
                      css={unlinkButton()}
                      onClick={(e: any) => {
                        onUnlink(unlinkId);
                      }}
                    >
                      <div css={unlinkButtonLabel(90)} />
                    </div>
                  )}
                  <div css={endBullet}>
                    <TimelineLinkBullet color={color} />
                  </div>
                </div>
              </div>
            )}

            {showUnlink && !isHorizontalLink && (
              <div
                css={unlinkButton()}
                onClick={(e: any) => {
                  onUnlink(unlinkId);
                }}
              >
                <div css={unlinkButtonLabel(0)} />
              </div>
            )}

            <div css={endBullet}>
              <TimelineLinkBullet color={color} />
            </div>
          </div>
        );
      }
      return null;
    }
    return null;
  }
}

export default TimelineLink;

const linkBox = (height: number, rotate: number, color: any) =>
  css({
    position: 'absolute',
    height: `${height}px`,
    width: '2px',
    backgroundColor: color,
    transformOrigin: 'top',
    transform: `rotate(${rotate}deg)`,
  });

const verticalLinkBox = css({
  position: 'absolute',
  bottom: 0,
  height: '0px',
  width: '0px',
  overflow: 'visible',
});

const verticalLink = (height: number, rotate: number, color: any) =>
  css({
    height: `${height}px`,
    width: '2px',
    backgroundColor: color,
    transform: `rotate(${rotate}deg)`,
    transformOrigin: 'top',
  });

const startBullet = css({
  position: 'absolute',
  top: 0,
  marginTop: '-5px',
  marginLeft: '-4px',
});

const endBullet = css({
  position: 'absolute',
  bottom: 0,
  marginBottom: '-5px',
  marginLeft: '-4px',
});

const unlinkButton = (height: number = 0) =>
  css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: styles.Colors.White,
    border: `2px solid ${styles.Colors.DarkGrey}`,
    borderRadius: '50%',
    height: '12px',
    width: '12px',
    marginLeft: '-7px',
    textAlign: 'center',
    position: 'absolute',
    bottom: '-9px',
    zIndex: 20,
    top: height > 0 ? `${height / 2 - 6}px` : 'auto',
  });

const unlinkButtonLabel = (rotate: number) =>
  css({
    backgroundColor: 'red',
    width: '10px',
    height: '4px',
    transform: `rotate(${rotate}deg)`,
  });
