/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FunctionComponent, ComponentProps, ReactElement, Props } from "react";
import { FormikProps } from "formik";
import _ from "lodash";

import { Field, FieldAttributes } from "formik";
import * as styles from "./styles";

jsx;

interface IProps extends Pick<FormikProps<{}>, "errors" | "touched"> {
  name: string | string[];
}

export const FormError: FunctionComponent<IProps> = ({
  errors,
  touched,
  name
}: IProps): ReactElement<IProps> => {
  const fieldError = _.get(errors, name);
  const message = _.get(touched, name) && fieldError ? fieldError : "";
  return <div css={styles.errorMessage}><span css={[styles.errorText, message && styles.errorTextVisible]}>{message}</span></div>;
};

export default FormError;
