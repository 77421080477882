import { nsSelector } from '../../../../../utils/redux';
import _ from 'lodash';

const productionsSelector = nsSelector('productions');
export const productions = productionsSelector('items');
export const allProductions = productionsSelector('allItems');
export const print = productionsSelector('print');
export const printInfo = productionsSelector('printInfo');

//deprecated
export const productionsSearchText = productionsSelector('searchText');
export const productionsPage = productionsSelector('page');
export const productionsOffset = productionsSelector('offset');
export const productionsTotal = productionsSelector('total');

export const production = productionsSelector('item');
export const productionLoading = productionsSelector('itemLoading');
export const subproduction = productionsSelector('subitem');
