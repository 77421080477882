import { put, call, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
// actions
import * as actions from './actions';
// utils
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getResponseMessage } from '../../../../../../utils/response';
import Routes from '../../../../../../routes';
import { deleteDataReducer } from '../../../../../../utils/reducers';

export default function* watchReducersAsync() {
  yield takeEvery(actions.GET_ALL_ACTIVITIES_REQ, getAllActivitiesAsync);
  yield takeEvery(actions.GET_ALL_TRANSPORTS_REQ, getAllTransportsAsync);
  yield takeEvery(actions.CREATE_TRANSPORT_REQ, createTransportAsync);
  yield takeEvery(actions.CREATE_ACTIVITY_REQ, createActivityAsync);
  yield takeEvery(actions.COPY_ACTIVITY_REQ, copyActivityAsync);
  yield takeEvery(actions.LINK_ACTIVITY_REQ, linkActivityAsync);
  yield takeEvery(actions.UNLINK_ACTIVITY_REQ, unlinkActivityAsync);
  yield takeEvery(actions.UPDATE_ACTIVITY_REQ, updateActivityAsync);
  yield takeEvery(actions.UPDATE_TRANSPORT_REQ, updateTransportAsync);
  yield takeEvery(actions.UPDATE_ACTIVITY_RANGE_REQ, updateActivityRangeAsync);
  yield takeEvery(actions.DELETE_ACTIVITY_REQ, deleteActivityAsync);
  yield takeEvery(actions.CLONE_PRODUCTION_REQ, cloneProductionAsync);
  yield takeEvery(actions.ACTIVITY_GROUP_UPDATE, activitiesGroupUpdate);
  yield takeEvery(actions.GET_VERSION_LIST_REQ, getVersionListAsync);
  yield takeEvery(actions.CREATE_VERSION_REQ, createVersionAsync);
  yield takeEvery(actions.DELETE_ACTIVITIES_REQ, deleteActivitiesAsync);
  yield takeEvery(actions.CLONE_ACTIVITIES_REQ, cloneActivitiesAsync);
  yield takeEvery(actions.SHIFT_ACTIVITIES_REQ, shiftActivitiesAsync);
}

export function* getAllTransportsAsync({ api, payload: { ownerId, ...params } }: any) {
  try {
    const path = `/productions/${ownerId}/activities`;
    const response = yield call(api.get, path, { params });

    yield put(actions.getAllActivitiesReqSuccess({}));
    yield put(actions.setAllTransports(response.data));
  } catch (err) {
    yield put(actions.getAllTransportsReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
export function* getAllActivitiesAsync({ api, payload: { ownerId, ...params } }: any) {
  try {
    const path = `/productions/${ownerId}/activities`;
    const response = yield call(api.get, path, { params });
    yield put(actions.getAllActivitiesReqSuccess({}));
    yield put(actions.setAllActivities(response.data));
  } catch (err) {
    yield put(actions.getAllActivitiesReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* createActivityAsync({ api, payload: { ownerId, ...data } }: any) {
  try {
    const path = `/productions/${ownerId}/activities`;
    const response = yield call(api.post, path, data);
    yield put(actions.createActivityReqSuccess({}));
    yield put(actions.addItemOfAllActivities(response.data));
    //yield call([toast, 'info'], 'Activity successfully created!');
  } catch (err) {
    yield put(actions.createActivityReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* createTransportAsync({ api, payload: { ownerId, ...data } }: any) {
  try {
    const path = `/productions/${ownerId}/transports`;
    const response = yield call(api.post, path, data);
    yield put(actions.createTransportReqSuccess({}));
    yield put(actions.addItemOfAllActivities(response.data));
    //yield call([toast, 'info'], 'Transport successfully created!');
  } catch (err) {
    yield put(actions.createTransportReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* copyActivityAsync({ api, payload: { ownerId, id, ...data } }: any) {
  try {
    const path = `/productions/${ownerId}/activities/${id}/clone`;
    const response = yield call(api.put, path, data);
    yield put(actions.createActivityReqSuccess());
    yield put(actions.addItemOfAllActivities(response.data));
    //yield call([toast, 'info'], 'Activity successfully cloned!');
  } catch (err) {
    yield put(actions.createActivityReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* updateActivityAsync({ api, payload: { ownerId, id, ...data } }: any) {
  try {
    const path = `/productions/${ownerId}/activities/${id}`;
    const response = yield call(api.post, path, data);
    yield put(actions.updateActivityReqSuccess({}));
    yield put(actions.addItemOfAllActivities(response.data));
    //yield call([toast, 'info'], 'Activity successfully updated!');
  } catch (err) {
    yield put(actions.updateActivityReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* updateTransportAsync({ api, payload: { ownerId, id, ...data } }: any) {
  try {
    const path = `/productions/${ownerId}/transports/${id}`;
    const response = yield call(api.post, path, data);
    yield put(actions.updateTransportReqSuccess({}));
    yield put(actions.addItemOfAllActivities(response.data));
    //yield call([toast, 'info'], 'Transport successfully updated!');
  } catch (err) {
    yield put(actions.updateTransportReqSuccess(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* updateActivityRangeAsync({ api, payload: { ownerId, id, ...data } }: any) {
  try {
    const path = `/productions/${ownerId}/activities/${id}/move`;
    const response = yield call(api.patch, path, data);
    yield put(actions.updateActivityReqSuccess({}));
    yield put(actions.addItemOfAllActivities(response.data));
    //yield call([toast, 'info'], 'Successfully updated!');
  } catch (err) {
    yield put(actions.updateActivityReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* linkActivityAsync({ api, payload: { ownerId, parentActivityId, activityId, ...data } }: any) {
  try {
    const path = `/productions/${ownerId}/activities/${activityId}/link/${parentActivityId}`;
    const response = yield call(api.patch, path, data);
    yield put(actions.updateActivityReqSuccess({}));
    yield put(actions.addItemOfAllActivities(response.data));
    //yield call([toast, 'info'], 'Activity successfully linked!');
  } catch (err) {
    yield put(actions.updateActivityReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* unlinkActivityAsync({ api, payload: { ownerId, activityId } }: any) {
  try {
    const path = `/productions/${ownerId}/activities/${activityId}/unlink`;
    const response = yield call(api.patch, path, {});
    yield put(actions.updateActivityReqSuccess({}));
    yield put(actions.addItemOfAllActivities(response.data));
    //yield call([toast, 'info'], 'Activity successfully unlinked!');
  } catch (err) {
    yield put(actions.updateActivityReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* deleteActivityAsync({ api, payload: { ownerId, id } }: any) {
  try {
    const path = `/productions/${ownerId}/activities/${id}`;
    yield call(api.delete, path);
    yield put(actions.deleteActivityReqSuccess());
    yield put(actions.deleteActivity(id));
    //yield call([toast, 'info'], 'Activity successfully deleted!');
  } catch (err) {
    yield put(actions.updateActivityReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* cloneProductionAsync({ api, payload: { id, data } }: any) {
  try {
    const path = `/productions/${id}/clone`;
    const response = yield call(api.put, path, data);
    const production = _.get(response, 'data.data[0]');

    if (data.main) {
      yield put(
        push(
          Routes.SubProductionsEdit.replace(':production_id', production.main.id).replace(
            ':subproduction_id',
            production.id
          )
        )
      );
    } else {
      yield put(push(Routes.ProductionsEdit.replace(':id', production.id)));
    }

    yield call([toast, 'info'], 'Production successfully cloned!');
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* activitiesGroupUpdate({ api, payload: { id, data } }: any) {
  try {
    const path = `/productions/${id}/activities/batch/update`;
    yield call(api.patch, path, data);
    yield put(actions.refreshAllActivities());
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* getVersionListAsync({ api, payload: { id } }: any) {
  try {
    const path = `/productions/${id}/versions`;
    const response = yield call(api.get, path);
    yield put(actions.getVersionListReqSuccess());
    yield put(actions.setVersionList(response.data));
  } catch (err) {
    yield put(actions.getVersionListReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* createVersionAsync({ api, payload: { id, name } }: any) {
  try {
    const path = `/productions/${id}/versions`;
    const response = yield call(api.post, path, { name });
    yield put(actions.createVersionReqSuccess());
    yield put(actions.addVersion(response.data));

    const version = _.find(response.data.data, { isCurrent: true });

    yield put(push(Routes.Production.replace(':id', _.get(version, 'production.id'))));
  } catch (err) {
    yield put(actions.createVersionReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
export function* deleteActivitiesAsync({ api, payload: { ownerId, ids } }: any) {
  try {
    const path = `/productions/${ownerId}/activities/batch/delete`;
    yield call(api.post, path, { ids });
    yield put(actions.deleteActivitiesReqSuccess({ ids }));
    yield put(actions.deleteActivities(ids));
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
export function* cloneActivitiesAsync({ api, payload: { ownerId, data } }: any) {
  try {
    const path = `/productions/${ownerId}/activities/batch/clone`;
    const response = yield call(api.post, path, data);
    yield put(actions.cloneActivitiesReqSuccess());
    yield put(actions.addItemOfAllActivities(response.data));
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
export function* shiftActivitiesAsync({ api, payload: { ownerId, data } }: any) {
  try {
    const path = `/productions/${ownerId}/activities/batch/shift`;
    const response = yield call(api.patch, path, data);
    yield put(actions.shiftActivitiesReqSuccess());
    yield put(actions.addItemOfAllActivities(response.data));
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
