import { createAction } from 'redux-actions';

import sagasRegister from '../../../../../../../utils/sagasRegister';
import { createAsyncAction } from '../../../../../../../utils/async/actions';
import { Action } from '../../../../../../../store/types';

import sagas from './sagas';

sagasRegister.register(sagas);

/******************************************************************************/
/**************************** UPDATE CALL SHEET   *****************************/
/******************************************************************************/

export const UPDATE_CALLSHEET_REQ: string = 'UPDATE_CALLSHEET_REQ';
export const UPDATE_CALLSHEET_REQ_SUCCESS: string = 'UPDATE_CALLSHEET_REQ_SUCCESS';
export const UPDATE_CALLSHEET_REQ_ERROR: string = 'UPDATE_CALLSHEET_REQ_ERROR';

export const updateCallSheetReq: Action<{}> = createAsyncAction(UPDATE_CALLSHEET_REQ);
export const updateCallSheetReqSuccess: Action<{}> = createAction(UPDATE_CALLSHEET_REQ_SUCCESS);
export const updateCallSheetReqError: Action<{}> = createAction(UPDATE_CALLSHEET_REQ_ERROR);
