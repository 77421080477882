/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import * as styles from '../../../styles/styles';

export const widgetBox = css({
  backgroundColor: styles.Colors.White,
  boxShadow: '1px 1px 10px rgba(150, 150, 150, 0.25)',
  marginBottom: '30px',
  borderRadius: '2px',
});

export const row = css({
  padding: '0 30px',
});

export const underline = css({
  borderBottom: `1px solid ${styles.Colors.Grey}`,
});

export const widgetHeader = [
  underline,
  row,
  css({
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h3': [styles.H3, { fontSize: '17px' }],
  }),
];

export const widgetKeyValueRow = [
  styles.MainText,
  styles.LinkIn,
  underline,
  row,
  css({
    height: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
];

export const widgetCenterContent = css({});
