import _ from 'lodash';

import { setActionApi } from './actions';
import { query } from '../API';
import { clearToken } from '../auth';
import { createAction } from 'redux-actions';
import { push } from 'connected-react-router';
import routes from '../../routes';

export const LOGOUT_ACTION_TYPE = 'LOGOUT';

export const asyncMidleware = (store) => (next) => (action) => {
  action = setActionApi(action, query(getApiErrorHandlers(store)));
  next(action);
};

export const getApiErrorHandlers = (store) => {
  const handlers = {
    401: unauthorized,
    404: notfound,
  };

  return _.mapValues(handlers, (handler) => handler(store));
};

const unauthorized = ({ dispatch }) => () => {
  dispatch(createAction(LOGOUT_ACTION_TYPE)());
  clearToken();
};

const notfound = ({ dispatch }) => () => {
  dispatch(push(routes.Root));
};
