/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import * as styles from './styles';

export interface IFormRowProps {}

jsx;

export const Actions: FunctionComponent<IFormRowProps> = ({ children }): ReactElement<IFormRowProps> => {
  return <div css={[styles.actionsBox]}>{children}</div>;
};

export default Actions;
