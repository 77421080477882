/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, createRef, KeyboardEvent, forwardRef } from 'react';
import * as styles from './styles';

interface IProps {
  select: (a: any) => void;
  items: any[];
  selected?: string | number | null;
  resultScheme: {
    id: string;
    title: string;
  };
  onPressEscape?: (e: any) => void;
  forwardRef?: any;
  onOptionsBlur?: (e: any) => void;
}
jsx;
class SelectOption extends Component<IProps> {
  private optionRef: any;
  constructor(props: IProps) {
    super(props);
    this.optionRef = [...Array(this.props.items.length)].map((r) => createRef());
    this.optionRef[0] = this.props.forwardRef;
  }

  changeFocusBelow = (index: number, maxIndex: number) => {
    if (index < maxIndex) {
      index + 1 >= maxIndex
        ? this.optionRef[maxIndex] && this.optionRef[maxIndex].current && this.optionRef[maxIndex].current.focus()
        : this.optionRef[index + 1] && this.optionRef[index + 1].current && this.optionRef[index + 1].current.focus();
    }
  };
  changeFocusToUp = (index: number, maxIndex: number) => {
    index - 1 <= 0
      ? this.optionRef[0] && this.optionRef[0].current && this.optionRef[0].current.focus()
      : this.optionRef[index - 1] && this.optionRef[index - 1].current && this.optionRef[index - 1].current.focus();
  };
  handleKeyPress = (event: KeyboardEvent<HTMLDivElement>, key: any, index: number, maxIndex: number) => {
    const { select, onPressEscape } = this.props;
    if ((event.key === 'Tab' || event.keyCode === 9) && index === maxIndex) {
      event.preventDefault();
      event.stopPropagation();
      if (!!this.props.onOptionsBlur) {
        this.props.onOptionsBlur(event);
      } else {
        this.optionRef[0] && this.optionRef[0].current && this.optionRef[0].current.focus();
      }
    }
    if (event.key === 'Enter' || event.keyCode === 13) {
      select(key);
      event.preventDefault();
      event.stopPropagation();
    }
    if (event.key === 'ArrowUp' || event.keyCode === 38) {
      this.changeFocusToUp(index, maxIndex);
    }
    if (event.key === 'ArrowDown' || event.keyCode === 40) {
      this.changeFocusBelow(index, maxIndex);
    }
    if (event.key === 'Escape' || event.keyCode === 27) {
      !!onPressEscape && onPressEscape(event);
    }
  };
  render() {
    const { select, selected, items, resultScheme, onOptionsBlur } = this.props;
    const [firstItem, ...restItems] = items;
    const firstKey = firstItem ? firstItem[resultScheme.id] : null;
    const maxIndex: number = restItems.length > 0 ? restItems.length : 0;
    return (
      <div>
        {firstItem && (
          <div
            onClick={(e: any) => {
              select(firstKey);
              e.stopPropagation();
            }}
            key={firstKey}
            onKeyDown={(e) => this.handleKeyPress(e, firstKey, 0, maxIndex)}
            ref={this.optionRef[0]}
            tabIndex={0}
            css={styles.selectOption}
          >
            <div css={styles.option(firstKey === selected)}>{firstItem.name}</div>
          </div>
        )}
        {restItems &&
          restItems.map((item: any, index: number) => {
            const key = item[resultScheme.id];
            const selectedItem = key === selected;
            return (
              <div
                onClick={(e: any) => {
                  select(key);
                  e.stopPropagation();
                }}
                key={key}
                onKeyDown={(e) => this.handleKeyPress(e, key, index + 1, maxIndex)}
                ref={this.optionRef[index + 1]}
                tabIndex={0}
                css={styles.selectOption}
              >
                <div css={styles.option(selectedItem)}>{item.name}</div>
              </div>
            );
          })}
      </div>
    );
  }
}
export default forwardRef<HTMLDivElement, IProps>((props, ref) => <SelectOption {...props} forwardRef={ref} />);
