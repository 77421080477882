import _ from 'lodash';

export const ADMIN_ROLE = 'ADMIN';

export enum RESOURCES {
  production = 'production',
  machinery = 'machinery',
  gates = 'gates',
  callSheet = 'callSheet',
  producer = 'producer',
  client = 'client',
  clientAgent = 'clientAgent',
  supplier = 'supplier',
  supplierAgent = 'supplierAgent',
  supplierFunction = 'supplierFunction',
  category = 'category',
}

export const ACTIONS = [
  {
    name: 'Read All',
    shortcut: 'Ra',
  },
  {
    name: 'Read',
    shortcut: 'R',
  },
  {
    name: 'Create',
    shortcut: 'C',
  },
  {
    name: 'Update',
    shortcut: 'U',
  },
  {
    name: 'Delete',
    shortcut: 'D',
  },
  {
    name: 'Print',
    shortcut: 'P',
  },
];

export const PERMISSIONS = {
  production: ['Ra', 'R', 'C', 'U', 'D', 'P'],
  machinery: ['R', 'C', 'U', 'D', 'P'],
  gates: ['R', 'C', 'U', 'D', 'P'],
  callSheet: ['R', 'C', 'U', 'D', 'P'],
  producer: ['R', 'C', 'U', 'D'],
  client: ['R', 'C', 'U', 'D'],
  clientAgent: ['R', 'C', 'U', 'D'],
  supplier: ['R', 'C', 'U', 'D'],
  supplierAgent: ['R', 'C', 'U', 'D'],
  supplierFunction: ['R', 'C', 'U', 'D'],
  transportType: ['R', 'C', 'U', 'D'],
  category: ['R', 'C', 'U', 'D'],
  generalInfo: ['R', 'C', 'U', 'D'],
};

const BACKBONE_ROLES = [
  { name: 'ADMIN', permissions: PERMISSIONS },
  {
    name: 'PRODUCER',
    permissions: {
      production: ['Ra', 'R', 'C', 'U', 'D', 'P'],
      machinery: ['R', 'C', 'U', 'D', 'P'],
      gates: ['R', 'C', 'U', 'D', 'P'],
      callSheet: ['R', 'U', 'P'],
      client: ['R', 'C', 'U', 'D'],
      clientAgent: ['R', 'C', 'U', 'D'],
      supplier: ['R', 'C', 'U', 'D'],
      supplierAgent: ['R', 'C', 'U', 'D'],
      supplierFunction: ['R'],
      category: ['R'],
      transportType: ['R'],
      generalInfo: ['R'],
    },
  },
  {
    name: 'FREELANCER',
    permissions: {
      production: ['R', 'U', 'P'],
      machinery: ['R', 'C', 'U', 'D', 'P'],
      gates: ['R', 'P'],
      callSheet: ['R', 'P', 'U'],
      supplier: ['R', 'C', 'U'],
      supplierAgent: ['R', 'C', 'U'],
      supplierFunction: ['R'],
      category: ['R'],
      transportType: ['R'],
      generalInfo: ['R'],
    },
  },
  {
    name: 'SUPPLIER',
    permissions: {
      production: ['R', 'P'],
      machinery: ['R', 'C', 'U', 'D'],
      callSheet: ['R', 'P'],
      supplierAgent: ['R', 'C', 'U', 'D'],
      gates: ['R'],
    },
  },
  {
    name: 'CLIENT',
    permissions: {
      production: ['R', 'P'],
      machinery: ['R'],
      clientAgent: ['R', 'C', 'U', 'D'],
      callSheet: ['R'],
      gates: ['R'],
    },
  },
];
const COPY_ROLES = [
  { name: 'ADMIN', permissions: PERMISSIONS },
  {
    name: 'PRODUCER',
    permissions: {
      production: ['Ra', 'R', 'C', 'U', 'D', 'P'],
      machinery: ['R', 'C', 'U', 'D', 'P'],
      gates: ['R', 'C', 'U', 'D', 'P'],
      callSheet: ['R', 'U', 'P'],
      client: ['R', 'C', 'U', 'D'],
      clientAgent: ['R', 'C', 'U', 'D'],
      supplier: ['R', 'C', 'U', 'D'],
      supplierAgent: ['R', 'C', 'U', 'D'],
      supplierFunction: ['R', 'C', 'U', 'D'],
      category: ['R'],
      transportType: ['R'],
      generalInfo: ['R'],
    },
  },
  {
    name: 'FREELANCER',
    permissions: {
      production: ['R', 'U', 'P'],
      machinery: ['R', 'C', 'U', 'D', 'P'],
      gates: ['R', 'P'],
      callSheet: ['R', 'P', 'U'],
      supplier: ['R', 'C', 'U', 'D'],
      supplierAgent: ['R', 'C', 'U', 'D'],
      supplierFunction: ['R', 'C', 'U', 'D'],
      category: ['R'],
      transportType: ['R'],
      generalInfo: ['R'],
    },
  },
  {
    name: 'SUPPLIER',
    permissions: {
      production: ['R', 'P'],
      machinery: ['R', 'C', 'U', 'D'],
      callSheet: ['R', 'P'],
      supplierAgent: ['R', 'C', 'U', 'D'],
      gates: ['R'],
    },
  },
  {
    name: 'CLIENT',
    permissions: {
      production: ['R', 'P'],
      machinery: ['R'],
      clientAgent: ['R', 'C', 'U', 'D'],
      callSheet: ['R'],
      gates: ['R'],
    },
  },
];

export const ROLES =
  document.URL.includes('https://backbone-beta-app.atlasproductionplanning.com') ||
  document.URL.includes('https://atlas.backbone-international.com') ||
  document.URL.includes('http://localhost:3000')
    ? BACKBONE_ROLES
    : COPY_ROLES;

export const RELATED_RESOURCES = {
  production: [
    {
      roles: ['ADMIN'],
      resolver: (ent: any, profile: any) => true,
    },
    {
      roles: ['PRODUCER'],
      resolver: (ent: any, profile: any) => true,
    },
    {
      roles: ['FREELANCER'],
      resolver: (ent: any, profile: any) => true,
    },

    {
      roles: ['SUPPLIER'],
      resolver: (ent: any, profile: any) => true,
    },

    {
      roles: ['CLIENT'],
      resolver: (ent: any, profile: any) => true,
    },
  ],
};

export const getRoleResourcePermissions = (role: string, resource: string) => {
  return _.get(getRolePermissions(role), resource, []);
};

export const getRolePermissions = (name: string) => {
  return _.get(_.find(ROLES, { name }), 'permissions');
};

export const defineRole = (role: any, profile: any) => {
  if (role === 'PRODUCER' && profile.isFreelancer) {
    role = 'FREELANCER';
  }
  return role;
};
