/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, Children } from 'react';
import { Form as FormicForm } from 'formik';
import * as styles from './styles';

export interface IProps {}

jsx;

export const FieldsArea: FunctionComponent<IProps> = ({ children }): ReactElement<IProps> => {
  return <div css={styles.fieldsArea}>{children}</div>;
};

export default FieldsArea;
