/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Component, createRef, Fragment } from 'react';
import { RemoveScroll } from 'react-remove-scroll';

export interface IProps {
  children?: (isSticky: boolean) => any;
}

export interface IState {
  scrollPositionY: number;
}

jsx;

class NoWindowScroll extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      scrollPositionY: 0,
    };
  }

  componentDidMount(): void {
    this.setState({ scrollPositionY: window.scrollY });
    window.addEventListener('scroll', this.stop);
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.stop);
  }

  stop = () => {
    const { scrollPositionY } = this.state;
    window.scrollTo(0, scrollPositionY);
  };

  render() {
    const { children } = this.props;
    return children || <Fragment />;
  }
}

export default NoWindowScroll;
