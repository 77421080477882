/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactElement, FunctionComponent, MouseEvent } from 'react';
import _ from 'lodash';

import * as styles from './styles';

interface IProps {
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

jsx;

const Row: FunctionComponent<IProps> = ({ children, onClick }): ReactElement<IProps> => {
  const clickHandler = onClick || _.identity;
  return (
    <div css={styles.rowBox} onClick={clickHandler}>
      <div css={styles.row}>{children}</div>
    </div>
  );
};

export default Row;
