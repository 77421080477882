/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { withFormik, FormikProps } from 'formik';
import * as yup from 'yup';
import { compose } from 'recompose';

import { connect } from '../../../../utils/redux';

import { createProducersReq } from './store/actions';
import { Action } from '../../../../store/types';

import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../common/buttons/OutlineBtn';

import form from '../../../common/form';
import page from '../../../common/page';

import routes from '../../../../routes';
import { Link } from 'react-router-dom';
import * as validatinShemas from '../../../../utils/validatinShemas';

interface FormValues {
  name: string;
  email: string;
  isFreelancer: boolean;
}

interface OtherProps {
  createProducersReq: Action<{}>;
}

interface FormProps {
  initialEmail?: string;
}

jsx;

const CreateForm = ({ errors, touched, values }: OtherProps & FormikProps<FormValues>) => {
  const { FormRow, FormField, FormRadio, FormError, FormBlock, FieldsSet, ActionsRow, Form, FieldsArea } = form;
  const { Page, Breadcrumbs, PageHeader } = page;

  return (
    <Fragment>
      <PageHeader title="New producer">
        <Breadcrumbs
          path={[
            { title: 'Producers', link: routes.Producers },
            { title: 'New Producer', link: routes.ProducersCreate },
          ]}
        />
      </PageHeader>
      <Page>
        <Form>
          <FieldsArea>
            <FormBlock>
              <FieldsSet>
                <FormRow>
                  <label htmlFor="name">Name</label>
                  <FormField name="name" placeholder="name" />
                  <FormError name="name" errors={errors} touched={touched} />
                </FormRow>

                <FormRow>
                  <label htmlFor="email">Email</label>
                  <FormField name="email" placeholder="email" />
                  <FormError name="email" errors={errors} touched={touched} />
                </FormRow>

                <FormRow>
                  <label htmlFor="isFreelancer">Freelancer</label>
                  <FormRadio name="isFreelancer" value={true} values={values} title="Yes" />
                  <FormRadio name="isFreelancer" value={false} values={values} title="No" />
                </FormRow>
              </FieldsSet>
            </FormBlock>
          </FieldsArea>

          <ActionsRow>
            <Link to={routes.Producers}>
              <OutlineBtn title="Cancel" isWide={false} />
            </Link>
            <PrimaryBtn title="Create producer" isWide={false} />
          </ActionsRow>
        </Form>
      </Page>
    </Fragment>
  );
};

export const exchangeFormic = withFormik<FormProps & OtherProps, FormValues>({
  mapPropsToValues: (props) => {
    return {
      name: '',
      email: '',
      isFreelancer: true,
    };
  },

  validationSchema: yup.object({}).shape({
    email: validatinShemas.vEmailReq,
    name: validatinShemas.vNameReq,
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.createProducersReq(values);
  },
  displayName: 'LoginForm',
});

export default compose<OtherProps & FormikProps<FormValues>, {}>(
  connect(
    {},
    { createProducersReq }
  ),
  exchangeFormic
)(CreateForm);
