import { createAction } from 'redux-actions';

import reducersRegister from '../../../../../../utils/reducersRegister';
import sagasRegister from '../../../../../../utils/sagasRegister';
import { Action } from '../../../../../../store/types';
import { createAsyncAction } from '../../../../../../utils/async/actions';

import redusers from './reducers';
import sagas from './sagas';

reducersRegister.register('supplierAgents', redusers);
sagasRegister.register(sagas);

/******************************************************************************/
/******************************* AGENTS ***************************************/
/******************************************************************************/

export const GET_SUPPLIER_AGENTS_REQ: string = 'GET_SUPPLIER_AGENTS_REQ';
export const GET_SUPPLIER_AGENTS_REQ_SUCCESS: string = 'GET_SUPPLIER_AGENTS_REQ_SUCCESS';
export const GET_SUPPLIER_AGENTS_REQ_ERROR: string = 'GET_SUPPLIER_AGENTS_REQ_ERROR';

export const SET_SUPPLIER_AGENTS: string = 'SET_SUPPLIER_AGENTS';
export const REFRESH_SUPPLIER_AGENTS: string = 'REFRESH_SUPPLIER_AGENTS';
export const CLEAR_SUPPLIER_AGENTS: string = 'CLEAR_SUPPLIER_AGENTS';

export const SET_SUPPLIER_AGENTS_PARAMS: string = 'SET_SUPPLIER_AGENTS_PARAMS';

export const getSupplierAgentsReq: Action<{}> = createAsyncAction(GET_SUPPLIER_AGENTS_REQ);
export const getSupplierAgentsReqSuccess: Action<{}> = createAction(
  GET_SUPPLIER_AGENTS_REQ_SUCCESS,
  (...args) => args[0],
  (...args) => args[1]
);
export const getSupplierAgentsReqError: Action<{}> = createAction(GET_SUPPLIER_AGENTS_REQ_ERROR);

export const setSupplierAgents: Action<{}> = createAction(SET_SUPPLIER_AGENTS);
export const refreshSupplierAgents: Action<{}> = createAction(REFRESH_SUPPLIER_AGENTS);
export const clearSupplierAgents: Action<{}> = createAction(CLEAR_SUPPLIER_AGENTS);

export const setSupplierAgentsParams: Action<{}> = createAction(SET_SUPPLIER_AGENTS_PARAMS);

/******************************************************************************/
/******************************* CREATE SUPPLIER_AGENT *********************************/
/******************************************************************************/

export const CREATE_SUPPLIER_AGENT_REQ: string = 'CREATE_SUPPLIER_AGENT_REQ';

export const createSupplierAgentReq: Action<{}> = createAsyncAction(CREATE_SUPPLIER_AGENT_REQ);

/******************************************************************************/
/******************************* GET ONE SUPPLIER_AGENT   ******************************/
/******************************************************************************/

export const GET_SUPPLIER_AGENT_REQ: string = 'GET_SUPPLIER_AGENT_REQ';
export const GET_SUPPLIER_AGENT_REQ_SUCCESS: string = 'GET_SUPPLIER_AGENT_REQ_SUCCESS';
export const GET_SUPPLIER_AGENT_REQ_ERROR: string = 'GET_SUPPLIER_AGENT_REQ_ERROR';
export const SET_SUPPLIER_AGENT: string = 'SET_SUPPLIER_AGENT';
export const CLEAR_SUPPLIER_AGENT: string = 'CLEAR_SUPPLIER_AGENT';

export const getSupplierAgentReq: Action<{}> = createAsyncAction(GET_SUPPLIER_AGENT_REQ);
export const getSupplierAgentReqSuccess: Action<{}> = createAction(GET_SUPPLIER_AGENT_REQ_SUCCESS);
export const getSupplierAgentReqError: Action<{}> = createAction(GET_SUPPLIER_AGENT_REQ_ERROR);
export const setSupplierAgent: Action<{}> = createAction(SET_SUPPLIER_AGENT);
export const clearSupplierAgent: Action<{}> = createAction(CLEAR_SUPPLIER_AGENT);

/******************************************************************************/
/******************************* UPDATE ONE SUPPLIER_AGENT   ******************/
/******************************************************************************/

export const UPDATE_SUPPLIER_AGENT_REQ: string = 'UPDATE_SUPPLIER_AGENT_REQ';
export const UPDATE_SUPPLIER_AGENT_REQ_SUCCESS: string = 'UPDATE_SUPPLIER_AGENT_REQ_SUCCESS';
export const UPDATE_SUPPLIER_AGENT_REQ_ERROR: string = 'UPDATE_SUPPLIER_AGENT_REQ_ERROR';
export const UPDATE_SUPPLIER_AGENT: string = 'UPDATE_SUPPLIER_AGENT';

export const updateSupplierAgentReq: Action<{}> = createAsyncAction(UPDATE_SUPPLIER_AGENT_REQ);

export const updateSupplierAgentReqSuccess: Action<{}> = createAction(UPDATE_SUPPLIER_AGENT_REQ_SUCCESS);

export const updateSupplierAgentReqError: Action<{}> = createAction(UPDATE_SUPPLIER_AGENT_REQ_ERROR);

export const updateSupplierAgent: Action<{}> = createAction(UPDATE_SUPPLIER_AGENT);

/******************************************************************************/
/******************************* DELETE ONE SUPPLIER_AGENT   ***************************/
/******************************************************************************/

export const DELETE_SUPPLIER_AGENT_REQ: string = 'DELETE_SUPPLIER_AGENT_REQ';
export const DELETE_SUPPLIER_AGENT_REQ_SUCCESS: string = 'DELETE_SUPPLIER_AGENT_REQ_SUCCESS';
export const DELETE_SUPPLIER_AGENT_REQ_ERROR: string = 'DELETE_SUPPLIER_AGENT_REQ_ERROR';
export const DELETE_SUPPLIER_AGENT: string = 'DELETE_SUPPLIER_AGENT';

export const deleteSupplierAgentReq: Action<{}> = createAsyncAction(DELETE_SUPPLIER_AGENT_REQ);

export const deleteSupplierAgentReqSuccess: Action<{}> = createAction(DELETE_SUPPLIER_AGENT_REQ_SUCCESS);

export const deleteSupplierAgentReqError: Action<{}> = createAction(DELETE_SUPPLIER_AGENT_REQ_ERROR);

export const deleteSupplierAgent: Action<{}> = createAction(DELETE_SUPPLIER_AGENT);

/******************************************************************************/
/******************************* SEND SUPPLIER_AGENT INVITATION   **********************/
/******************************************************************************/

export const SEND_SUPPLIER_AGENT_INVITATION_REQ: string = 'SEND_SUPPLIER_AGENT_INVITATION_REQ';
export const SEND_SUPPLIER_AGENT_INVITATION_SUCCESS: string = 'SEND_SUPPLIER_AGENT_INVITATION_SUCCESS';
export const SEND_SUPPLIER_AGENT_INVITATION_ERROR: string = 'SEND_SUPPLIER_AGENT_INVITATION_ERROR';

export const sendSupplierAgentInvitationReq: Action<{}> = createAsyncAction(SEND_SUPPLIER_AGENT_INVITATION_REQ);

export const sendSupplierAgentInvitationSuccess: Action<{}> = createAction(SEND_SUPPLIER_AGENT_INVITATION_SUCCESS);

export const sendSupplierAgentInvitationError: Action<{}> = createAction(SEND_SUPPLIER_AGENT_INVITATION_ERROR);
