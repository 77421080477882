/** @jsx jsx */
import { Fragment, FunctionComponent, ReactElement } from 'react';
import { jsx } from '@emotion/core';
// components
import { Route, Switch } from 'react-router';
import CategoriesList from './CategoriesList';
import CreateCategory from './CreateCategory';
import EditCategory from './EditCategory';
// other
import routes from '../../../../routes';
import withAcl, { IWrappedProps } from '../withAcl';

interface IProps extends IWrappedProps {}

jsx;
const CategoriesRoutes: FunctionComponent<IProps> = ({ test }): ReactElement<IProps> => {
  return (
    <Switch>
      <Route exact path={routes.Categories} component={CategoriesList} />
      {test('category', 'C') && <Route exact path={routes.CategoriesCreate} component={CreateCategory} />}
      {test('category', 'U') && <Route exact path={routes.CategoriesEdit} component={EditCategory} />}
    </Switch>
  );
};

export default withAcl(CategoriesRoutes);
