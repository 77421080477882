/** @jsx jsx */
import { jsx } from '@emotion/core';

import _ from 'lodash';
import { Component, Fragment, createRef } from 'react';

import * as styles from './styles';
import SelectedBtn from '../../buttons/SelectedBtn';
import OutlineBtn from '../../buttons/OutlineBtn';
import * as object from '../../../../utils/object';
import * as collection from '../../../../utils/collection';
import * as array from '../../../../utils/array';
import Text from '../TextWithRef';
import { IListForSelect } from './ListForSelect';
import ListForSelect from './ListForSelect';

export interface IScheme {
  id?: string;
  title: string;
}

export interface IProps {
  selected: number[];
  items: any[];
  scheme: IScheme;
  onChange: (a: number[]) => void;
  noSelectedMsg?: string;
  selectAllBtn?: boolean;
  className?: string;
  onPressEscape?: (e: any) => void;
  listToSelect?: (a: IListForSelect) => void;
}

export interface IState {
  search: string;
}

export const NO_SELECTED_MESSAGE = 'No selected items';

export const convert = (items: any[], scheme: IScheme) => {
  return items.map((item) => object.buildData(item, scheme));
};

export const select = (convertedItems: any[], selected: number[]) => {
  return collection.selectBy(convertedItems, { id: selected });
};

export const exclude = (convertedItems: any[], selected: number[]) => {
  return collection.excludeBy(convertedItems, { id: selected });
};

jsx;
class MultiSelect extends Component<IProps, IState> {
  private inputRef: any;
  constructor(props: IProps) {
    super(props);

    this.inputRef = createRef();
    this.state = {
      search: '',
    };
  }

  getSelectedItems = () => {
    const { scheme, selected, items } = this.props;
    const selectedItems: any[] = select(convert(items, scheme), selected);
    return selectedItems;
  };

  searchFilter = (items: any[], text: string) => {
    return items.filter((item) => item.title.toLowerCase().startsWith(text.toLowerCase()));
  };

  getUnselectedItems = () => {
    const { scheme, selected, items } = this.props;
    const { search } = this.state;
    const unselectedItems: any[] = exclude(convert(items, scheme), selected);
    return search ? this.searchFilter(unselectedItems, search) : unselectedItems;
  };

  removeSelected = (id: number) => {
    const { selected, onChange } = this.props;
    onChange(array.exclude(selected, id));
  };

  addNew = (ids: any) => {
    this.props.onChange([...this.props.selected, ..._.castArray(ids)]);
    this.setState({ search: '' });
    this.inputRef && this.inputRef.current && this.inputRef.current.focus();
  };

  selectAll = () => {
    this.addNew(collection.extractKeyValue(this.getUnselectedItems(), 'id'));
  };

  clearAll = () => {
    this.props.onChange([]);
  };

  search = (e: any) => {
    this.setState({ search: e.currentTarget.value });
  };

  handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      !!this.props.onPressEscape && this.props.onPressEscape(event);
    }
  };

  render() {
    const { selectAllBtn = true, scheme } = this.props;

    const selectedItems = this.getSelectedItems();
    const selectedListRender = (
      <Fragment>
        {selectedItems.map((item) => (
          <SelectedBtn
            key={item.id}
            title={item.title}
            onClick={(e: any) => {
              e.stopPropagation();
              this.removeSelected(item.id);
            }}
          />
        ))}
      </Fragment>
    );

    const selectedList = selectedItems.length ? selectedListRender : null;
    const unselected = this.getUnselectedItems();
    const idKey = scheme.id || 'id';

    return (
      <div onKeyDown={(e: any) => this.handleEscapeKey(e)}>
        <div css={styles.selectedBox}>{selectedList}</div>
        <div css={styles.searchBox}>
          <Text onChange={this.search} value={this.state.search} ref={this.inputRef} />
        </div>
        <div css={styles.actionsBox}>
          {selectAllBtn && (
            <OutlineBtn
              type="button"
              isSmall
              title="Select all"
              onClick={this.selectAll}
              tabIndex={unselected.length === 1 ? -1 : 0}
            />
          )}
          <OutlineBtn
            type="button"
            isSmall={true}
            title="Clear all"
            onClick={this.clearAll}
            tabIndex={unselected.length === 1 ? -1 : 0}
          />
        </div>
        <div css={styles.variantsBox}>
          <ListForSelect
            selectAllListItems={this.selectAll}
            getUnselectedItemsList={this.getUnselectedItems}
            addNewItemToList={this.addNew}
            idKey={idKey}
          />
        </div>
      </div>
    );
  }
}

export default MultiSelect;
