/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import * as styles from './styles';

export interface IProps {}

jsx;

export const Widget: FunctionComponent<IProps> = ({ children, ...props }): ReactElement<IProps> => {
  return (
    <div css={styles.widgetBox} {...props}>
      {children}
    </div>
  );
};

export default Widget;
