export interface ILabel {
  name: string;
  Function: string;
  Functions: string;
  function: string;
  functions: string;
  Sub: string;
  Subproduction: string;
  subproduction: string;
  SubSub: string;
  SubSubproduction: string;
  subSubproduction: string;
}

export const LABELS: ILabel = {
  name: 'Backbone',
  Function: 'Function',
  Functions: 'Functions',
  function: 'function',
  functions: 'functions',
  Sub: 'Sub',
  Subproduction: 'Subproduction',
  subproduction: 'subproduction',
  SubSub: 'SubSub',
  SubSubproduction: 'Sub-subproduction',
  subSubproduction: 'sub-subproduction',
};

export const LABELS_COPY: ILabel = {
  name: 'TIG Sports',
  Function: 'Department',
  Functions: 'Departments',
  function: 'department',
  functions: 'departments',
  Sub: 'Area',
  Subproduction: 'Area',
  subproduction: 'area',
  SubSub: 'Location',
  SubSubproduction: 'Location',
  subSubproduction: 'location',
};

export default (document.URL.includes('https://backbone-beta-app.atlasproductionplanning.com') ||
document.URL.includes('https://atlas.backbone-international.com') ||
document.URL.includes('http://localhost:3000')
  ? LABELS
  : LABELS_COPY);

export const isSiteOrigin =
  document.URL.includes('https://backbone-beta-app.atlasproductionplanning.com') ||
  document.URL.includes('https://atlas.backbone-international.com') ||
  document.URL.includes('http://localhost:3000');
