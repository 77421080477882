/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PureComponent } from 'react';
import _ from 'lodash';
import { DragSourceMonitor, DragSource } from 'react-dnd';
import CellView, { ICell } from './CellView';
import * as styles from './styles';

export interface IState {
  isDropCopy: boolean;
}

export interface IGridRow {
  shema: ICell[];
  data?: any;
  isDisable?: boolean;
  onEdit?: (id: any) => void;
  drag?: boolean;
  isDragging?: any;
  connectDragSource?: any;
  dragGridData?: any;
  isPrintView?: boolean;
  onRowClick?: (e: any, data: any) => void;
  onRowDoubleClick?: (e: any, data: any) => void;
  afterRowContent?: any;
  isDifferent?: boolean;
}

jsx;

const rowSource = {
  beginDrag({ data, dragGridData = {} }: any, monitor: any) {
    const item = { rowData: data, gridData: dragGridData };
    return item;
  },

  canDrag: ({ drag = true }: any, monitor: DragSourceMonitor) => {
    return drag;
  },

  endDrag(props: any, monitor: any, component: any) {
    const dropResult = monitor.getDropResult();
    if (_.get(dropResult, 'afterDrop')) {
      dropResult.afterDrop(dropResult);
    }

    if (!monitor.didDrop()) {
      return;
    }
  },
};

function collect(connect: any, monitor: any) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connect.dragSource(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging(),
  };
}

class Row extends PureComponent<IGridRow, IState> {
  constructor(props: IGridRow) {
    super(props);

    this.state = {
      isDropCopy: false,
    };
  }

  mouseDown = (e: any) => {
    const isDropCopy = ['ctrlKey', 'altKey', 'cmdKey', 'metaKey'].some((key) => e[key]);
    this.setState({ isDropCopy });
    return e;
  };
  calculateStyles = () => {
    const { isDisable, isDifferent } = this.props;
    if (isDisable) return [styles.row, styles.rowDisable];
    if (isDifferent) return styles.rowEmphasis;
    return styles.row;
  };

  render() {
    const {
      data,
      shema,
      isDisable,
      onEdit = _.identity,
      isDragging,
      connectDragSource,
      isPrintView = false,
      onRowClick = _.identity,
      onRowDoubleClick = _.identity,
      afterRowContent,
      isDifferent,
    } = this.props;
    const { isDropCopy } = this.state;

    // const rowStyle = isDisable ? [styles.row, styles.rowDisable] : isDifferent ? styles.rowEmphasis : styles.row;
    const rowStyle = this.calculateStyles();

    const cells = shema.map((shemaItem, index) => {
      return (
        <CellView key={`view-${index}`} data={data} isDisable={isDisable} {...shemaItem} isPrintView={isPrintView} />
      );
    });

    const mainView = connectDragSource(
      <div
        className="grid-row"
        onClick={(e) => {
          onRowClick(e, data);
        }}
        onDoubleClick={(e) => {
          onRowDoubleClick(e, data);
        }}
        draggable
      >
        <div
          css={rowStyle}
          onMouseDown={this.mouseDown}
          onDoubleClick={() => {
            if (!isDisable) {
              onEdit(data);
            }
          }}
        >
          {cells}
        </div>
        {afterRowContent}
      </div>,
      { dropEffect: isDropCopy ? 'copy' : 'move' }
    );

    if (isPrintView) {
      return (
        <tr css={styles.tr}>
          <td css={styles.td}>{mainView}</td>
        </tr>
      );
    } else {
      return mainView;
    }
  }
}

const DndRow = DragSource('activity', rowSource, collect)(Row);

export default DndRow;
