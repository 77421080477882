import { createAction } from 'redux-actions';
import { createAsyncAction } from '../utils/async/actions';
import { Action } from '../store/types';
import redusers from './reducers';
import sagas from './sagas';
import reducersRegister from '../utils/reducersRegister';
import sagasRegister from '../utils/sagasRegister';

reducersRegister.register('main', redusers);
sagasRegister.register(sagas);

// LOGIN
export const LOGIN_REQUEST: string = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCSESS: string = 'LOGIN_REQUEST_SUCCSESS';
export const LOGIN_REQUEST_ERROR: string = 'LOGIN_REQUEST_ERROR';
export const SET_TOKEN: string = 'SET_TOKEN';
export const LOGIN: string = 'LOGIN';
export const LOGOUT: string = 'LOGOUT';

export const loginRequest: Action<{}> = createAsyncAction(LOGIN_REQUEST);
export const loginRequestSuccsess: Action<{}> = createAction(LOGIN_REQUEST_SUCCSESS);
export const loginRequestError: Action<{}> = createAction(LOGIN_REQUEST_ERROR);
export const setToken: Action<{}> = createAction(SET_TOKEN);
export const login: Action<{}> = createAction(LOGIN);
export const logout: Action<{}> = createAction(LOGOUT);

// RESTORE
export const RESTORE_EMAIL_REQ: string = 'RESTORE_EMAIL_REQ';
export const RESTORE_PASSWORD_REQ: string = 'RESTORE_PASSWORD_REQ';
export const restoreEmailReq: Action<{}> = createAsyncAction(RESTORE_EMAIL_REQ);
export const restorePasswordReq: Action<{}> = createAsyncAction(RESTORE_PASSWORD_REQ);

// INVITATION
export const INVITATION_REQ: string = 'INVITATION_REQ';
export const invinantionReq: Action<{}> = createAsyncAction(INVITATION_REQ);
