/** @jsx jsx */
import { jsx } from '@emotion/core';

import _ from 'lodash';
import { Component, Fragment, createRef } from 'react';

import * as styles from './styles';
import SelectedBtn from '../../buttons/ForwardedSelectedItem';
import OutlineBtn from '../../buttons/OutlineForwarded';
import Checkbox from '../InputCheckbox';
import LABELS from '../../../../constants';
import * as collection from '../../../../utils/collection';
import * as array from '../../../../utils/array';
import Text from '../TextWithRef';
import ListForSelect, { IListForSelect } from '../selectWithTabs/ListForSelect';
import { IProps as MultiSelectProps, convert, select, exclude, NO_SELECTED_MESSAGE } from './MultiSelect';

export interface IScheme {
  id?: string;
  title: string;
}

export interface IState {
  search: string;
}

export interface IProps extends MultiSelectProps {
  onApply?: (e: any) => void;
  onPressEscape?: (e: any) => void;
  listToSelect?: (a: IListForSelect) => void;
  onCheckboxChange?: (e: any) => void;
  checkAccess?: (id: number) => void;
  deepSearch?: boolean;
  searchType?: string;
}

jsx;
class MultiselectBlock extends Component<IProps, IState> {
  private inputRef: any;
  private selectedItemRef: any;
  private applyBtnRef: any;
  private checkboxRef: any;
  constructor(props: IProps) {
    super(props);

    this.inputRef = createRef();
    this.applyBtnRef = createRef();
    this.checkboxRef = createRef();
    this.selectedItemRef = [...Array(30)].map((r) => createRef());

    this.state = {
      search: '',
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.inputRef && this.inputRef.current && this.inputRef.current.focus();
    }, 0);
  }

  getSelectedItems = () => {
    const { scheme, selected, items } = this.props;
    const selectedItems: any[] = select(convert(items, scheme), selected);
    return selectedItems;
  };

  searchFilter = (items: any[], text: string) => {
    return items.filter((item) => item.title.toLowerCase().includes(text.toLowerCase()));
  };

  getUnselectedItems = () => {
    const { scheme, selected, items } = this.props;
    const { search } = this.state;
    const unselectedItems: any[] = exclude(convert(items, scheme), selected);
    const result = search ? this.searchFilter(unselectedItems, search) : unselectedItems;
    if (result && result.length === 1) {
      this.checkboxRef && this.checkboxRef.current && this.checkboxRef.current.focus();
    }
    return result;
  };

  removeSelected = (id: number) => {
    const { selected, onChange } = this.props;
    onChange(array.exclude(selected, id));
  };

  addNew = (ids: any) => {
    this.props.checkAccess && this.props.checkAccess(ids);
    this.props.onChange([...this.props.selected, ..._.castArray(ids)]);
    this.setState({ search: '' });
    this.inputRef && this.inputRef.current && this.inputRef.current.focus();
  };

  selectAll = () => {
    this.addNew(collection.extractKeyValue(this.getUnselectedItems(), 'id'));
  };

  clearAll = () => {
    this.props.onChange([]);
  };

  search = (e: any) => {
    this.setState({ search: e.currentTarget.value });
  };

  handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      !!this.props.onPressEscape && this.props.onPressEscape(event);
    }
  };

  handleKeysOnApply = (event: KeyboardEvent) => {
    const { onApply, onPressEscape } = this.props;
    if (event.key === 'Enter' || event.keyCode === 13) {
      onApply && onApply(event);
    }
    if (event.key === 'Escape' || event.keyCode === 27) {
      !!onPressEscape && onPressEscape(event);
    }
  };

  handleKeysOnSearch = (event: KeyboardEvent) => {
    if (event.shiftKey === true && event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      if (this.selectedItemRef[0] && this.selectedItemRef[0].current) {
        this.selectedItemRef[0].current.focus();
      } else {
        this.applyBtnRef && this.applyBtnRef.current && this.applyBtnRef.current.focus();
      }
    }
  };

  handleKeysOnSelectedItems = (event: KeyboardEvent, index: number, maxIndex: number) => {
    if (index === maxIndex && (event.key === 'Tab' || event.keyCode === 9)) {
      event.preventDefault();
      event.stopPropagation();
      this.inputRef && this.inputRef.current && this.inputRef.current.focus();
    }
    if (event.shiftKey === true && event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      index === maxIndex
        ? this.inputRef && this.inputRef.current && this.inputRef.current.focus()
        : this.selectedItemRef[index + 1] &&
          this.selectedItemRef[index + 1].current &&
          this.selectedItemRef[index + 1].current.focus();
    }
  };
  switchFocusFromUnselectedItems = (event: KeyboardEvent) => {
    if (event.key === 'Tab' || event.keyCode === 9) {
      event.preventDefault();
      event.stopPropagation();
      if (this.selectedItemRef[0] && this.selectedItemRef[0].current) {
        this.selectedItemRef[0].current.focus();
      } else {
        this.inputRef && this.inputRef.current && this.inputRef.current.focus();
      }
    }
  };

  render() {
    const {
      noSelectedMsg,
      selectAllBtn = true,
      scheme,
      onApply,
      onCheckboxChange,
      deepSearch = false,
      searchType,
    } = this.props;

    const selectedItems = this.getSelectedItems();
    const oneMatchInSearch = this.getUnselectedItems().length === 1;
    const idKey = scheme.id || 'id';
    const searchItems = searchType || LABELS.functions;

    return (
      <div css={styles.multiSelectBox} onKeyDown={(e: any) => this.handleEscapeKey(e)}>
        <div css={styles.selectedBox}>
          {selectedItems.length > 0 ? (
            selectedItems.map((item, index) => (
              <SelectedBtn
                key={item.id}
                title={item.title}
                onClick={(e: any) => {
                  e.stopPropagation();
                  this.removeSelected(item.id);
                }}
                tabIndex={1}
                ref={this.selectedItemRef[index]}
                onKeyDown={(e: any) => this.handleKeysOnSelectedItems(e, index, selectedItems.length - 1)}
              />
            ))
          ) : (
            <div css={styles.multiSelectMsg}>{noSelectedMsg || NO_SELECTED_MESSAGE}</div>
          )}
        </div>
        <div css={styles.searchBox}>
          <Text
            onChange={this.search}
            ref={this.inputRef}
            value={this.state.search}
            onKeyDown={(e: any) => this.handleKeysOnSearch(e)}
          />
          {deepSearch && (
            <label css={styles.checkboxLabel}>
              <Checkbox onChange={onCheckboxChange} />
              {`Search through all ${searchItems}`}
            </label>
          )}
        </div>

        <div css={styles.actionsBox}>
          <OutlineBtn
            type="button"
            isSmall={true}
            title="Apply"
            onClick={onApply}
            tabIndex={0}
            ref={this.applyBtnRef}
            onKeyPress={(e) => this.handleKeysOnApply(e)}
          />
          {selectAllBtn && (
            <OutlineBtn
              type="button"
              isSmall
              title="Select all"
              onClick={this.selectAll}
              tabIndex={oneMatchInSearch ? -1 : 0}
            />
          )}
          <OutlineBtn
            type="button"
            isSmall={true}
            title="Clear all"
            onClick={this.clearAll}
            tabIndex={oneMatchInSearch ? -1 : 0}
          />
        </div>
        <div css={styles.variantsBox}>
          <ListForSelect
            selectAllListItems={this.selectAll}
            getUnselectedItemsList={this.getUnselectedItems}
            addNewItemToList={this.addNew}
            idKey={idKey}
            ref={this.checkboxRef}
          />
        </div>
        <div tabIndex={0} onKeyDown={(e: any) => this.switchFocusFromUnselectedItems(e)} css={styles.hiddenBox}>
          {''}
        </div>
      </div>
    );
  }
}

export default MultiselectBlock;
