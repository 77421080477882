/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';

import { Component } from 'react';
import { IProduction, IActivity, ActivityTypes } from '../../store/types';
import * as config from '../../../../../../config';

import widget from '../../../../../common/widget';
import DayDropTarget from './DayDropTarget';

import DayHeader from './DayHeader';
import DayTable from './DayTable';
import { extractKeyValue } from '../../../../../../utils/collection';

import { updateActivityRangeReq, copyActivityReq, createTransportReq, updateTransportReq } from '../store/actions';
import { supplierFunctionsAddRequest } from '../../../SupplierFunctions/store/actions';
import { createSupplierReqFromTabs } from '../../../Suppliers/store/actions';
import { Action } from '../../../../../../store/types';
import { connect } from '../../../../../../utils/redux';
import { ViewModes } from '../Item';
import withAcl, { IWrappedProps } from '../../../withAcl';
import TransportForm from './TaransportForm';

export interface IProps extends IWrappedProps {
  workDay: any;
  activities: any[];
  production: IProduction;
  subproductions?: IProduction[];
  isOver: any;
  canDrop: any;
  connectDropTarget: any;
  prodVersion?: any;

  supplierFunctionsAddRequest: Action<{}>;
  createSupplierReq: Action<{}>;

  updateActivityRangeReq?: Action<{}>;
  copyActivityReq?: Action<{}>;
  createTransportReq?: Action<{}>;
  updateTransportReq?: Action<{}>;
  selectDayActivities?: Action<{}>;
  unselectFromDayActivities?: Action<{}>;
  getSupplierReq?: Action<{}>;
  isSelectMode: boolean;
  isEditMode: boolean;
  viewMode: ViewModes;
  onMoveUp?: () => void;

  allSuppliers: any;
  allFunctions: any;
  isStickyGridHeader?: boolean;
}

export interface IState {
  isOpen: boolean;
  freezeOrder: number[] | null;
  isEditTransport: boolean;
  editItem: IActivity | null;
}

jsx;
class DateItem extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isOpen: true,
      freezeOrder: null,
      isEditTransport: false,
      editItem: null,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    if (prevProps.viewMode && prevProps.viewMode !== this.props.viewMode) {
      this.listSort();
    }
  }

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
    if (!nextProps.isEditMode) {
      return { freezeOrder: null };
    }
    return null;
  }

  sortActivitiesByTime = (activies: any[]) => {
    return [...activies].sort((a: any, b: any) => {
      return a.range.from.valueOf() - b.range.from.valueOf();
    });
  };

  getFreezeSortMap = (activies: any[]) => {
    const { freezeOrder } = this.state;
    const result = freezeOrder || this.getSortMap(activies);
    if (!freezeOrder) {
      this.setState({ freezeOrder: result });
    }
    return result;
  };

  getActivityChildren = (activities: any, activity: any, accum: any = []): any => {
    const child = activity.child ? _.find(activities, { id: activity.child.id }) : null;
    return child ? this.getActivityChildren(activities, child, [...accum, child]) : accum;
  };

  findChildren = (activities: any, parentId: any) => {
    return activities.filter((activity: any) => _.get(activity, ['parent', 'id']) === parentId);
  };

  buildActivityHierarchy = (activities: any) => {
    return activities.map((activity: any) => {
      return { ...activity, children: this.findChildren(activities, activity.id) };
    });
  };

  listSort = () => {
    this.setState({ freezeOrder: null });
  };

  getSortMap = (activies: any[]) => {
    return extractKeyValue(this.sortActivitiesByTime(activies), 'id');
  };

  getRootActivities = (activitiesHash: any) => {
    return _.pickBy(activitiesHash, (activity: any) => !(activity.parent && activitiesHash[activity.parent.id]));
  };

  getGroupActivities = (parentActivity: any, activitiesHash: any, accum: any = {}) => {
    accum = { ...accum, [parentActivity.id]: parentActivity };
    return parentActivity.children
      .map((activity: any) => activitiesHash[activity.id])
      .reduce((accum: any[], activity: any) => {
        return { ...this.getGroupActivities(activity, activitiesHash, accum) };
      }, accum);
  };

  extractActivitiesGroups = (activitiesRoot: any, activitiesHash: any) => {
    return _.mapValues(activitiesRoot, (activity) => {
      return this.getGroupActivities(activity, activitiesHash);
    });
  };

  joinActivitiesWithGroups = (activitiesRoot: any, groups: any) => {
    return _.mapValues(activitiesRoot, (activity) => {
      return { ...activity, group: groups[activity.id] };
    });
  };

  sortActivities = (activies: any[], sortMap: any) => {
    const activitiesHash = _.keyBy(activies, 'id');
    const activitiesRoot = this.getRootActivities(activitiesHash);
    const groups = this.extractActivitiesGroups(activitiesRoot, activitiesHash);
    const activitiesRootWithGroups = this.joinActivitiesWithGroups(activitiesRoot, groups);
    const sortedActivities = this.sortActivitiesBySortMap(activitiesRootWithGroups, sortMap);

    return sortedActivities.reduce((result, activity) => {
      const group = groups[activity.id];
      const add = group ? this.sortActivitiesBySortMap(group, sortMap) : [activity];
      return [...result, ...add];
    }, []);
  };

  sortActivitiesBySortMap = (activitiesHash: any, sortMap: any[]) => {
    const sorted = sortMap.reduce((result: any[], sortId: any) => {
      const activity = activitiesHash[sortId];
      return activity ? [...result, activity] : result;
    }, []);

    const other = Object.values(_.omit(activitiesHash, sortMap));
    return [...sorted, ...other];
  };

  startEditTransport = (transport: any = null) => {
    this.setState({ isEditTransport: true, editItem: transport });
  };

  closeEditTransport = () => {
    this.setState({ isEditTransport: false, editItem: null });
  };

  onSave = (values: any) => {
    const { production, createTransportReq, updateTransportReq } = this.props;
    const action = values.id ? updateTransportReq : createTransportReq;
    action({ ownerId: production.id, ...values }).then(() => {
      this.closeEditTransport();
    });
  };
  selectActivitiesOfDay = () => {
    const { activities, selectDayActivities, production } = this.props;
    const ids = activities.reduce((result: number[], activity: any) => {
      return production.id === activity.production.id && activity.type === ActivityTypes.ACTIVITY
        ? [...result, activity.id]
        : result;
    }, []);
    selectDayActivities(ids);
  };
  unselectActivitiesFromDay = () => {
    const { activities, unselectFromDayActivities, production } = this.props;
    const ids = activities.reduce((result: number[], activity: any) => {
      return production.id === activity.production.id && activity.type === ActivityTypes.ACTIVITY
        ? [...result, activity.id]
        : result;
    }, []);
    unselectFromDayActivities(ids);
  };

  render() {
    const {
      workDay,
      activities,
      production,
      connectDropTarget,
      isSelectMode,
      allFunctions,
      allSuppliers,
      isEditMode,
      viewMode,
      onMoveUp,
      isStickyGridHeader,
      subproductions,
      test,
    } = this.props;

    const hasPermission = test('supplier', 'C');

    const { isOpen, isEditTransport, editItem } = this.state;
    const { Widget } = widget;

    const sortMap = isEditMode ? this.getFreezeSortMap(activities) : this.getSortMap(activities);
    const fullSortedActivities = this.sortActivities(this.buildActivityHierarchy(activities), sortMap);

    return connectDropTarget(
      <div>
        <Widget>
          <DayHeader
            onOpen={() => this.setState({ isOpen: true })}
            onClose={() => this.setState({ isOpen: false })}
            onSort={this.listSort}
            isOpen={isOpen}
            isEditMode={isEditMode}
            isSelectMode={isSelectMode}
            title={`${workDay.from.format(`dddd, ${config.GRID_DATE_FORMAT} YYYY`)}`}
            viewMode={viewMode}
            onMoveUp={onMoveUp}
            onAddTransportStart={this.startEditTransport}
            selectDayActivities={this.selectActivitiesOfDay}
            unselectActivitiesFromDay={this.unselectActivitiesFromDay}
          />
          {isOpen && (
            <DayTable
              allSuppliers={allSuppliers}
              addSupplierFunction={this.props.supplierFunctionsAddRequest}
              getSupplierReq={this.props.getSupplierReq}
              createSupplierFunction={this.props.createSupplierReq}
              allFunctions={allFunctions}
              production={production}
              activities={fullSortedActivities}
              workDay={workDay}
              isEditMode={isEditMode}
              isSelectMode={isSelectMode}
              viewMode={viewMode}
              isStickyGridHeader={isStickyGridHeader}
              subproductions={subproductions}
              onEditTransportStart={this.startEditTransport}
              hasPermissionToAdd={hasPermission}
            />
          )}

          {isEditTransport && (
            <TransportForm
              item={editItem}
              isOpen={true}
              onClose={this.closeEditTransport}
              onSave={this.onSave}
              day={workDay}
            />
          )}
        </Widget>
      </div>
    );
  }
}

export default connect(
  {},
  {
    updateActivityRangeReq,
    supplierFunctionsAddRequest,
    copyActivityReq,
    createTransportReq,
    updateTransportReq,
    createSupplierReq: createSupplierReqFromTabs,
  }
)(withAcl(DayDropTarget(DateItem)));
