import Widget from './Widget';
import WidgetCenterContent from './WidgetCenterContent';
import WidgetHeader from './WidgetHeader';
import WidgetBody from './WidgetBody';
import WidgetKeyValueRow from './WidgetKeyValueRow';

export default {
  Widget,
  WidgetCenterContent,
  WidgetHeader,
  WidgetBody,
  WidgetKeyValueRow,
};
