/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PureComponent } from 'react';
import { compose } from 'recompose';
// redux
import { connect } from '../../../../../utils/redux';
import {
  getSupplierAgentsReq,
  clearSupplierAgents,
  deleteSupplierAgentReq,
  sendSupplierAgentInvitationReq,
} from './store/actions';
import { supplierAgents } from './store/selectors';
import { getSuppliersForSelectReq } from '../store/actions';
import { suppliersForSelect } from '../store/selectors';
// types
import { Action } from '../../../../../store/types';
import { SupplierAgentListState } from './store/types';
import { SupplierListState } from '../store/types';
// components
import { Link as RouteLink, RouteComponentProps } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import PrimaryBtn from '../../../../common/buttons/PrimaryBtn';
import Loader from '../../../../common/loader/Loader';
import GRID from '../../../../common/grid';
import GRIDActions from '../../../../common/grid/actions/index';
import GRIDToolbarActions from '../../../../common/grid/toolbarActions';
// utils
import _ from 'lodash';
import { push } from 'connected-react-router';
// other
import routes from '../../../../../routes';
import { ListStatuses } from '../../../../../utils/store/state/state';
import withAcl, { IWrappedProps } from '../../withAcl';
import CheckAcl from '../../CheckAcl';

const { Actions, ToolBar, Footer, Grid, ActionList, DeleteAction, EditAction, styles } = GRID;
const { Button } = GRIDActions;
const { Filter } = GRIDToolbarActions;

interface MatchParams {
  owner_id?: string;
}

interface IProps extends RouteComponentProps<MatchParams>, IWrappedProps {
  ownerId: string;
  supplierAgents: SupplierAgentListState;
  getSupplierAgentsReq: Action<{}>;
  clearSupplierAgents: Action<{}>;
  deleteSupplierAgentReq: Action<{}>;
  sendSupplierAgentInvitationReq: Action<{}>;
  suppliersForSelect: SupplierListState;
  getSuppliersForSelectReq: Action<{}>;
  push: Action<{}>;
}

interface IState {}

jsx;
class SupplierAgentsList extends PureComponent<IProps, IState> {
  componentDidMount() {
    const {
      getSupplierAgentsReq,
      getSuppliersForSelectReq,
      suppliersForSelect,
      match: {
        params: { owner_id },
      },
    } = this.props;
    getSuppliersForSelectReq();
    getSupplierAgentsReq({ ownerId: owner_id, page: 0, size: 20 });
  }

  componentWillUnmount() {
    const { clearSupplierAgents } = this.props;

    clearSupplierAgents();
  }

  fetchMoreAgents = () => {
    const { getSupplierAgentsReq, supplierAgents } = this.props;
    const { page } = supplierAgents.params;
    getSupplierAgentsReq({ ...supplierAgents.params, page: page + 1, size: 20 });
  };

  onWaypointEnter = () => {
    const { supplierAgents } = this.props;
    if (supplierAgents.status === ListStatuses.Request) return;
    this.fetchMoreAgents();
  };

  onFilterChange = (ownerId: number | number[]) => {
    const { getSupplierAgentsReq } = this.props;
    getSupplierAgentsReq({ ownerId, page: 0 });
  };

  render() {
    const {
      ownerId,
      deleteSupplierAgentReq,
      sendSupplierAgentInvitationReq,
      supplierAgents,
      suppliersForSelect,
      test,
      push,
    } = this.props;

    const currentOwnerId = supplierAgents.params.ownerId || ownerId;
    const isLoading = supplierAgents.status === ListStatuses.Request;
    const total = _.get(supplierAgents, 'meta.total', 0);
    const hasMoreAgents = total > _.get(supplierAgents.data, 'length', 0);

    return (
      <div css={styles.gridBox}>
        <ToolBar
          title="Agents"
          actionsLeft={
            suppliersForSelect.data && (
              <Filter
                title="Filter"
                items={suppliersForSelect.data}
                scheme={{ title: 'name' }}
                selected={parseInt(currentOwnerId)}
                onChange={this.onFilterChange}
              />
            )
          }
          actionsRight={
            <CheckAcl resources={'supplier'} actions={'C'}>
              <RouteLink to={routes.SupplierAgentCreate.replace(':owner_id', `${currentOwnerId}`)}>
                <PrimaryBtn title="Create agent" />
              </RouteLink>
            </CheckAcl>
          }
        />

        <Grid
          onRowDoubleClick={(e: any, { id }: any) => {
            push(routes.SupplierAgentEdit.replace(':owner_id', `${currentOwnerId}`).replace(':id', `${id}`));
          }}
          shema={[
            { title: 'Name', path: 'name' },
            { title: 'Email', path: 'email' },
            { title: 'Phone', path: 'phone' },
          ]}
          data={supplierAgents.data || []}
          actions={({ id, isActive }) => {
            return (
              <Actions>
                {test('supplier', 'U') && (
                  <RouteLink
                    to={routes.SupplierAgentEdit.replace(':owner_id', `${currentOwnerId}`).replace(':id', `${id}`)}
                  >
                    <EditAction />
                  </RouteLink>
                )}
                {test('supplier', 'D') && (
                  <DeleteAction
                    onConfirm={(e) => {
                      deleteSupplierAgentReq({ ownerId: currentOwnerId, id: id });
                    }}
                  />
                )}
                {!isActive && test('supplier', 'U') && (
                  <ActionList>
                    <Button
                      title="Send invitation"
                      onClick={(e) => {
                        sendSupplierAgentInvitationReq({ ownerId: currentOwnerId, id: id });
                      }}
                    />
                  </ActionList>
                )}
              </Actions>
            );
          }}
        />
        <Footer>
          <Loader isLoading={isLoading} />
        </Footer>
        {hasMoreAgents && !isLoading && <Waypoint onEnter={this.onWaypointEnter} />}
      </div>
    );
  }
}

export default compose<IProps, IState>(
  connect(
    { supplierAgents, suppliersForSelect },
    {
      getSupplierAgentsReq,
      clearSupplierAgents,
      deleteSupplierAgentReq,
      sendSupplierAgentInvitationReq,
      getSuppliersForSelectReq,
      push,
    }
  ),
  withAcl
)(SupplierAgentsList);
