import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
// actions
import * as actions from './actions';

// utils
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getResponseMessage } from '../../../../../utils/response';

export default function* watchMainAsync() {
  yield takeLatest(actions.GET_TRANSPORT_TYPES_REQ, getTransportTypesAsync);
  yield takeEvery(actions.CREATE_TRANSPORT_TYPE_REQ, createTransportTypesAsync);
  yield takeEvery(actions.UPDATE_TRANSPORT_TYPE_REQ, updateTransportTypesAsync);
  yield takeEvery(actions.DELETE_TRANSPORT_TYPE_REQ, deleteTransportTypesAsync);
}

export function* getTransportTypesAsync({ api, payload }: any) {
  try {
    const path = '/transports/types';
    const response = yield call(api.get, path, { params: payload });
    yield put(actions.getTransportTypesReqSuccess());
    yield put(actions.setTransportTypes({ ...response.data, params: payload }));
  } catch (e) {
    yield put(actions.getTransportTypesReqError(e));
  }
}

export function* createTransportTypesAsync({ api, payload }: any) {
  try {
    const path = '/transports/types';
    const response = yield call(api.post, path, payload);
    yield put(actions.createTransportTypeReqSuccess());
  } catch (err) {
    yield put(actions.createTransportTypeReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* updateTransportTypesAsync({ api, payload: { id, ...data } }: any) {
  try {
    const path = `/transports/types/${id}`;
    const response = yield call(api.post, path, data);
    yield put(actions.updateTransportTypeReqSuccess());
  } catch (err) {
    yield put(actions.updateTransportTypeReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* deleteTransportTypesAsync({ api, payload }: any) {
  try {
    const path = `/transports/types/${payload}`;
    const response = yield call(api.delete, path);
    yield put(actions.deleteTransportTypeReqSuccess());
  } catch (err) {
    yield put(actions.deleteTransportTypeReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
