/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withFormik, Form, FormikProps } from 'formik';
import * as yup from 'yup';
import { compose } from 'recompose';
import * as React from 'react';

import { passwordUpdateRequest, UpdatePasswordRequest } from './store/actions';
import { token } from '../../../../store/selectors';
import { connect } from '../../../../utils/redux';

import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import FormRow from '../../../common/form/FormRow';
import FormField from '../../../common/form/FormField';
import FormError from '../../../common/form/FormError';
import FormHeader from '../Dashboard/FormHeader';
import * as validatinShemas from '../../../../utils/validatinShemas';

import * as styles from './styles';
import OutlineBtn from '../../../common/buttons/OutlineBtn';

interface FormValues {
  currentPassword: string;
  password: string;
}

interface OtherProps {
  passwordUpdateRequest: UpdatePasswordRequest;
  token: string;
}

jsx;

const ChangePasswordForm = (props: OtherProps & FormikProps<FormValues>) => {
  return (
    <div css={styles.formSize}>
      <FormHeader header="Change password" />
      <Form>
        <FormRow>
          <label htmlFor="currentPassword">Current password</label>
          <FormField type="password" name="currentPassword" />
          <FormError name="currentPassword" errors={props.errors} touched={props.touched} />
        </FormRow>
        <FormRow>
          <label htmlFor="password">New password</label>
          <FormField type="password" name="password" />
          <FormError name="password" errors={props.errors} touched={props.touched} />
        </FormRow>
        <div css={styles.formButtons}>
          <PrimaryBtn title="Save" />
          <OutlineBtn type="button" onClick={() => window.history.back()} title="Cancel" />
        </div>
      </Form>
    </div>
  );
};

const updatePasswordSchema = yup.object({}).shape({
  currentPassword: yup.string().required('Current password is a required field'),
  password: yup.string().required('New password is a required field'),
});

export const exchangeFormic = withFormik<OtherProps, FormValues>({
  mapPropsToValues: (props) => {
    return {
      currentPassword: '',
      password: '',
    };
  },

  validationSchema: updatePasswordSchema,

  handleSubmit: (values, { props }) => {
    props.passwordUpdateRequest(values);
  },
  displayName: 'ChangePasswordForm',
});

export default compose<OtherProps & FormikProps<FormValues>, {}>(
  connect(
    { token },
    { passwordUpdateRequest }
  ),
  exchangeFormic
)(ChangePasswordForm);
