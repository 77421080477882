/** @jsx jsx */
import { Fragment, Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { jsx, css } from '@emotion/core';
// redux
import { connect } from '../../../../../utils/redux';
import { Action } from '../../../../../store/types';

import { production, productionLoading } from '../store/selectors';
import { getProductionReq, setProduction } from '../store/actions';
import { IProduction } from '../store/types';

import { client } from '../../Clients/store/selectors';
import { IClient } from '../../Clients/store/types';

import { producer } from '../../Producers/store/selectors';
import { IProducer } from '../../Producers/store/types';

import { allActivities } from './store/selectors';
import { getAllActivitiesReq, setAllActivitiesParams } from './store/actions';

import { getSupplierFunctionsReq } from '../../SupplierFunctions/store/actions';
import { supplierFunctions } from '../../SupplierFunctions/store/selectors';

import { getSuppliersForSelectReq } from '../../Suppliers/store/actions';
import { suppliersForSelect } from '../../Suppliers/store/selectors';

import { ActivitiesListState } from './store/types';

import { getProductionsReq, updateProductionDaysReq } from '../store/actions';
import { productions } from '../store/selectors';
import { ProductionListState } from '../store/types';

import AsyncData from '../../../../common/async/AsyncData';
import DateListPrint from './DateList/DateListPrint';

import _ from 'lodash';

import { SupplierFunctionListState } from '../../SupplierFunctions/store/types';
import { SupplierListState } from '../../Suppliers/store/types';

import * as mainStyles from '../../../../../styles/styles';

interface MatchParams {
  id?: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  getProductionReq: Action<{}>;
  setProduction: Action<{}>;
  production: IProduction;
  productionLoading: Boolean;
  client: IClient;
  producer: IProducer;
  setAllActivitiesParams: Action<{}>;
  getAllActivitiesReq: Action<{}>;
  allActivities: ActivitiesListState;
  getSupplierFunctionsReq: Action<{}>;
  supplierFunctions: SupplierFunctionListState;
  getSuppliersForSelectReq: Action<{}>;
  suppliersForSelect: SupplierListState;
  cloneProductionReq: Action<{}>;
  productions: ProductionListState;
  getProductionsReq: Action<{}>;
  updateProductionDaysReq: Action<{}>;
}

interface IState {
  cloneProductionModalOpen: boolean;
}

jsx;
class Item extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const {
      getProductionReq,
      setProduction,
      match: { params },
    } = props;

    setProduction(null);
    getProductionReq(params.id, { fetchRelatedData: true });

    this.state = {
      cloneProductionModalOpen: false,
    };
  }

  componentWillReceiveProps(nextProps: IProps) {
    const {
      getProductionReq,
      setProduction,
      production,
      productionLoading,
      match: { params },
    } = nextProps;

    const isCurrentProduction = production && _.get(production, 'id') === Number(params.id);
    if (!isCurrentProduction && !productionLoading) {
      getProductionReq(params.id, { fetchRelatedData: true });
    }
  }

  render() {
    const {
      production,
      client,
      producer,
      allActivities,
      getAllActivitiesReq,
      supplierFunctions,
      getSupplierFunctionsReq,
      suppliersForSelect,
      getSuppliersForSelectReq,
      productions,
      getProductionsReq,
    }: IProps = this.props;
    const { cloneProductionModalOpen } = this.state;

    if (_.every([production, client, producer], Boolean)) {
      return (
        <AsyncData
          data={[
            { asyncData: allActivities, asyncGetAction: getAllActivitiesReq },
            { asyncData: supplierFunctions, asyncGetAction: getSupplierFunctionsReq },
            { asyncData: suppliersForSelect, asyncGetAction: getSuppliersForSelectReq },
            { asyncData: productions, asyncGetAction: getProductionsReq },
          ]}
          wrappContent={false}
        >
          {() => {
            return (
              <div className={'print-box'} css={style.printBox}>
                <DateListPrint
                  production={production}
                  activities={allActivities.data}
                  allFunctions={suppliersForSelect.data}
                  allSuppliers={supplierFunctions.data}
                />
              </div>
            );
          }}
        </AsyncData>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

export default connect(
  {
    production,
    productionLoading,
    client,
    producer,
    allActivities,
    supplierFunctions,
    suppliersForSelect,
    productions,
  },
  {
    getProductionReq,
    setProduction,
    getProductionsReq,
    updateProductionDaysReq,
    getAllActivitiesReq,
    setAllActivitiesParams,
    getSupplierFunctionsReq,
    getSuppliersForSelectReq,
  }
)(Item);

const style = {
  printBox: css({
    backgroundColor: mainStyles.Colors.White,
    position: 'absolute',
    left: '0px',
    top: '0px',
    width: '100%',
    padding: '0 20px',
    boxSizing: 'border-box',
  }),
};
