/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { compose, lifecycle } from 'recompose';
import { RouteComponentProps } from 'react-router';

import { connect } from '../../../../utils/redux';

import { getProducerReq, clearProducer } from './store/actions';
import { Action } from '../../../../store/types';
import { producer } from './store/selectors';
import EditForm from './EditForm';

import * as styles from './styles';
import { IProducer } from './store/types';

interface FormValues extends Partial<IProducer> {}

interface MatchParams {
  id?: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  getProducerReq: Action<{}>;
  clearProducer: Action<{}>;
  producer: IProducer;
}

jsx;

const withLifecycle = lifecycle<IProps, {}>({
  componentDidMount() {
    const { getProducerReq, clearProducer } = this.props;
    clearProducer();
    this.props.getProducerReq(this.props.match.params.id);
  },
});

const Edit = (props: IProps) => {
  const form = props.producer ? <EditForm {...props} /> : <div>Loading...</div>;
  return <Fragment>{form}</Fragment>;
};

interface FormProps {
  initialEmail?: string;
}

export default compose<IProps, {}>(
  connect(
    { producer },
    { getProducerReq, clearProducer }
  ),
  withLifecycle
)(Edit);
