/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent } from 'react';
// types
import { ISupplier } from '../Suppliers/store/types';
// components
import IconBtn from '../../../common/buttons/IconBtn';
import ConfirmButton from '../../../common/buttons/ConfirmButton';
// styles
import editIcon from '../../../common/grid/images/edit.svg';
import deleteIcon from '../../../common/grid/images/delete.svg';
// utils
import _ from 'lodash';
// other
import Routes from '../../../../routes';
import { Link } from 'react-router-dom';
import { style } from './ProductionSuppliers';
import withAcl, { IWrappedProps } from '../withAcl';

interface IRowProps extends IWrappedProps {
  rowIndex: number;
  id: number;
  supplier: ISupplier;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  children: any;
}

jsx;
const ProductionSupplierRow: FunctionComponent<IRowProps> = ({
  rowIndex,
  id,
  supplier,
  onEdit,
  onDelete,
  children,
  test,
  testRole,
}: IRowProps) => (
  <div css={style.innerRow}>
    <div css={style.cell('150px')}>
      {!rowIndex && (
        <Link to={Routes.SuppliersEdit.replace(':id', String(supplier.id))} css={style.link}>
          {supplier.name}
        </Link>
      )}
    </div>
    {children}

    {test('production', ['U', 'D']) && (
      <div css={[style.cell('50px'), { minHeight: '40px' }]}>
        {!rowIndex && (
          <div>
            {test('supplier', 'U') && <IconBtn icon={editIcon} iconSize="20px" onClick={() => onEdit(id)} />}
            {(test('supplier', 'D') || testRole('FREELANCER')) && (
              <ConfirmButton
                btn={<IconBtn icon={deleteIcon} iconSize="16px" />}
                confirmMessage="Are you sure want to delete this item?"
                onConfirm={() => onDelete(id)}
              />
            )}
          </div>
        )}
      </div>
    )}
  </div>
);

export default withAcl(ProductionSupplierRow);
