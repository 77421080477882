import { put, call, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as actions from './actions';
// utils
import { search } from '../../../../../utils/API';
import { getResponseMessage } from '../../../../../utils/response';
import _ from 'lodash';
// other
import { toast } from 'react-toastify';
import routes from '../../../../../routes';

export default function* watchReducersAsync() {
  yield takeEvery(actions.GET_PRODUCERS_REQ, getProdusersAsync);
  yield takeEvery(actions.CREATE_PRODUCERS_REQ, createProduserAsync);
  yield takeEvery(actions.CREATE_PRODUCERS_REQ_IN_TAB, createProduserInTabAsync);
  yield takeEvery(actions.UPDATE_PRODUCER_REQ, updateProduserAsync);
  yield takeEvery(actions.DELETE_PRODUCER_REQ, deleteProduserAsync);
  yield takeEvery(actions.GET_PRODUCER_REQ, getProduserAsync);
}

export function* getProduserAsync({ api, payload }: any) {
  try {
    const path = `/producers/${payload}`;
    const response = yield call(api.get, path);
    yield put(actions.getProducerReqSuccess());
    yield put(actions.setProducer(response.data.data));
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* deleteProduserAsync({ api, payload }: any) {
  try {
    const path = `/producers/${payload}`;
    yield call(api.delete, path);
    yield put(actions.deleteProducerReqSuccess());
    //yield call([toast, 'info'], 'Produсer successfully deleted.');
  } catch (err) {
    yield put(actions.deleteProducerReqError());
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* updateProduserAsync({ api, payload }: any) {
  try {
    const path = `/producers/${payload.id}`;
    const response = yield call(api.post, path, payload);
    yield put(actions.getProducerReqSuccess());
    yield put(actions.setProducer(response.data.data));
    yield put(push(routes.Producers));
    //yield call([toast, 'success'], 'Producer data succesfully updated.');
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* createProduserAsync({ api, payload }: any) {
  try {
    const response = yield call(api.post, '/producers/invitations', payload);
    yield put(push(routes.Producers));
    //yield call([toast, 'success'], getResponseMessage(response));
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* createProduserInTabAsync({ api, payload }: any) {
  try {
    const response = yield call(api.post, '/producers/invitations', payload);
    yield put(actions.addProducerToList(response.data.data[0].user));
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* getProdusersAsync({ api, payload }: any) {
  try {
    const path = `/producers`;
    const response = yield call(api.get, path, { params: payload });
    const data = _.get(response, 'data.data');
    const meta = _.get(response, 'data.meta');
    yield put(actions.getProducersReqSuccess({ data, params: payload }, meta));
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
