/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withFormik, Form, FormikProps } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';
import { compose, lifecycle } from 'recompose';
import { connect } from '../../../../../utils/redux';
import FormRow from '../../../../common/form/FormRow';
import FormField from '../../../../common/form/FormField';
import Checkbox from '../../../../common/form/Checkbox';
import FormError from '../../../../common/form/FormError';
import PrimaryBtn from '../../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../../common/buttons/OutlineBtn';

import select from '../../../../common/form/select';

import { Action } from '../../../../../store/types';
import { print, printInfo } from '../store/selectors';
import { getProductionPrintReq, getProductionPrintInfoReq, setProductionPrintInfoParams } from '../store/actions';

import modal from '../../../../common/modal';
import routes from '../../../../../routes';

import AsyncData from '../../../../common/async/AsyncData';

interface FormValues {
  version: string;
  format: string;
  landscape: boolean;
}

interface OtherProps {
  onPrint?: () => void;
  onClose: () => void;
  production?: any;
  getProductionPrintReq?: Action<{}>;
  getProductionPrintInfoReq?: Action<{}>;
  setProductionPrintInfoParams?: Action<{}>;
  print?: any;
  printInfo?: any;
}

jsx;

const withLifecycle = lifecycle<OtherProps, {}>({
  componentDidMount() {
    const { setProductionPrintInfoParams = _.identity, production = null } = this.props;
    setProductionPrintInfoParams({ ownerId: production.id });
  },
});

const FunctionForm = ({
  onPrint,
  onClose,
  production,
  errors,
  touched,
  values,
  print,
  printInfo,
  setFieldValue,
  getProductionPrintInfoReq,
  setProductionPrintInfoParams,
}: OtherProps & FormikProps<FormValues>) => {
  const { Modal, ModalHeader, ModalFooter, ModalBody, ModalActions } = modal;
  const { Select, Option } = select;

  return (
    <div>
      <Modal isOpen={true}>
        <Form>
          <ModalHeader>Print production</ModalHeader>
          <AsyncData data={[{ asyncData: printInfo, asyncGetAction: getProductionPrintInfoReq }, { asyncData: print }]}>
            {() => {
              const formats = printInfo.data.formats.map((format: string) => ({ format }));

              return (
                <ModalBody>
                  <FormRow>
                    <label htmlFor="version">Version</label>
                    <FormField name="version" />
                    <FormError name="version" errors={errors} touched={touched} />
                  </FormRow>
                  <FormRow>
                    <label htmlFor="format">Paper format</label>
                    <Select
                      scheme={{ id: 'format', title: 'format' }}
                      items={formats}
                      selected={values.format}
                      onChange={(value) => {
                        setFieldValue('format', value);
                      }}
                    >
                      {(item: any, selected: boolean) => {
                        return <Option title={item.format} selected={selected} />;
                      }}
                    </Select>
                    <FormError name="producer" errors={errors} touched={touched} />
                  </FormRow>

                  <FormRow>
                    <label htmlFor="format">Landscape view</label>
                    <Checkbox
                      name="landscape"
                      checked={values.landscape}
                      onChange={(e: any) => {
                        setFieldValue('landscape', e.currentTarget.checked);
                      }}
                    />
                  </FormRow>
                </ModalBody>
              );
            }}
          </AsyncData>

          <ModalFooter>
            <ModalActions>
              <OutlineBtn type="button" onClick={onClose} title="Cancel" />
              <PrimaryBtn title="Print" type="submit" />
            </ModalActions>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

const functionSchema = yup.object({}).shape({
  version: yup.string().required('Required'),
  format: yup.string().required('Required'),
});

export const exchangeFormic = withFormik<OtherProps, FormValues>({
  enableReinitialize: true,
  mapPropsToValues: ({ printInfo: { data } }) => {
    return {
      version: data ? data.currentVersion : '',
      format: data ? data.formats[0] : '',
      landscape: _.get(data, 'landscape', true),
    };
  },

  validationSchema: functionSchema,

  handleSubmit: (values, { props: { getProductionPrintReq = _.identity, production }, setSubmitting }) => {
    const scale = values.format === 'A4' && values.landscape === false ? 0.7 : 1;

    getProductionPrintReq({
      ...values,
      scale,
      page: routes.ProductionPrint.replace(':id', production.id),
      productionId: production.id,
      margin: {},
    });
  },
  displayName: 'AddFunctionForm',
});

export default compose<OtherProps & FormikProps<FormValues>, OtherProps>(
  connect(
    { print, printInfo },
    { getProductionPrintReq, getProductionPrintInfoReq, setProductionPrintInfoParams }
  ),
  withLifecycle,
  exchangeFormic
)(FunctionForm);
