/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { withFormik, FormikProps } from 'formik';
import * as yup from 'yup';
import { compose, lifecycle } from 'recompose';

// redux
import { connect } from '../../../../../../utils/redux';
import { createGateReq } from './store/actions';
import { getProductionReq } from '../../store/actions';
import { production } from '../../store/selectors';

// types
import { Action } from '../../../../../../store/types';
import { IProduction } from '../../store/types';
import { RouteComponentProps } from 'react-router';

// components
import { Link } from 'react-router-dom';
import TextArea from '../../../../../common/form/TextArea';
import PrimaryBtn from '../../../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../../../common/buttons/OutlineBtn';
import form from '../../../../../common/form';
import page from '../../../../../common/page';

// styles
import { Link as LinkStyle } from '../../../../../../styles/styles';

// utils
import _ from 'lodash';

// other
import routes from '../../../../../../routes';
import * as validatinShemas from '../../../../../../utils/validatinShemas';

interface MatchParams {
  id?: string;
}

interface FormValues {
  name: string;
  address: string;
  remark: string;
  coordinates: {
    latitude: string | number;
    longitude: string | number;
  };
}

interface FormProps {}

interface IProps extends RouteComponentProps<MatchParams> {
  createGateReq: Action<{}>;
  clearProduction: Action<{}>;
  getProductionReq: Action<{}>;
  production: IProduction;
}

jsx;

const withLifecycle = lifecycle<IProps, {}>({
  componentDidMount() {
    const {
      getProductionReq,
      match: { params },
    } = this.props;

    if (params.id !== String(_.get(production, 'id'))) getProductionReq(params.id);
  },
});

const CreateForm = ({
  production,
  errors,
  touched,
  values,
  setFieldValue,
  setFieldTouched,
  match: { params },
}: IProps & FormikProps<FormValues>) => {
  const { FormRow, FormRowSections, FormField, FormError, FormBlock, FieldsSet, ActionsRow, Form, FieldsArea } = form;
  const { Page, Breadcrumbs, PageHeader } = page;
  const showMapLink = !(
    (!values.coordinates.latitude && !values.coordinates.longitude) ||
    (_.get(errors, 'coordinates.longitude') || _.get(errors, 'coordinates.latitude'))
  );

  return (
    <Fragment>
      {production && (
        <PageHeader title="Create new gate">
          <Breadcrumbs
            path={[
              { title: 'Productions', link: routes.Productions },
              { title: production.name, link: routes.Production.replace(':id', `${params.id}`) },
            ]}
          />
        </PageHeader>
      )}
      <Page>
        <Form>
          <FieldsArea>
            <FormBlock>
              <FieldsSet>
                <FormRow>
                  <FormRowSections width={1}>
                    <label htmlFor="name">Name</label>
                    <FormField name="name" placeholder="name" />
                    <FormError name="name" errors={errors} touched={touched} />
                  </FormRowSections>
                </FormRow>

                <FormRow>
                  <FormRowSections width={1}>
                    <label htmlFor="address">Address</label>
                    <FormField name="address" placeholder="address" />
                    <FormError name="address" errors={errors} touched={touched} />
                  </FormRowSections>
                </FormRow>

                <FormRow>
                  <label htmlFor="latitude">GPS coordinates (DD - decimal degrees)</label>
                  <FormRowSections width={2}>
                    <FormField name="coordinates.latitude" placeholder="latitude" />
                    <FormError name="coordinates.latitude" errors={errors} touched={touched} />
                  </FormRowSections>

                  <FormRowSections width={2}>
                    <FormField name="coordinates.longitude" placeholder="longitude" />
                    <FormError name="coordinates.longitude" errors={errors} touched={touched} />
                  </FormRowSections>
                </FormRow>

                {showMapLink && (
                  <FormRow>
                    <FormRowSections width={1}>
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${values.coordinates.latitude},${
                          values.coordinates.longitude
                        }`}
                        target="_blank"
                        css={[LinkStyle, { color: '#4465E0' }]}
                      >
                        See on the Map
                      </a>
                    </FormRowSections>
                  </FormRow>
                )}

                <FormRow>
                  <FormRowSections width={1}>
                    <label htmlFor="remark">Remark</label>
                    <TextArea
                      name="remark"
                      rows={4}
                      placeholder="enter your remark"
                      value={values.remark}
                      onChange={(e) => {
                        setFieldValue('remark', _.get(e, 'currentTarget.value'));
                        setFieldTouched('remark', true);
                      }}
                    />
                    <FormError name="remark" errors={errors} touched={touched} />
                  </FormRowSections>
                </FormRow>
              </FieldsSet>
            </FormBlock>
          </FieldsArea>

          <ActionsRow>
            <Link to={routes.ProductionGates.replace(':id', `${params.id}`)}>
              <OutlineBtn title="Cancel" isWide={false} />
            </Link>
            <PrimaryBtn title="Create gate" isWide={false} />
          </ActionsRow>
        </Form>
      </Page>
    </Fragment>
  );
};

export const formicHoc = withFormik<IProps & FormProps, FormValues>({
  isInitialValid: false,
  validationSchema: yup.object({}).shape({
    name: yup.string().required(validatinShemas.REQUIRE_MESSAGE),
    address: yup.string(),
    coordinates: yup.object().shape({
      latitude: validatinShemas.vCoordinates(9, -90, 90).required(validatinShemas.REQUIRE_MESSAGE),
      longitude: validatinShemas.vCoordinates(9, -180, 180).required(validatinShemas.REQUIRE_MESSAGE),
    }),
    remark: yup.string(),
  }),
  mapPropsToValues: () => {
    return {
      name: '',
      address: '',
      remark: '',
      coordinates: {
        latitude: '',
        longitude: '',
      },
    };
  },
  handleSubmit: (values: any, { props: { createGateReq, production } }) => {
    const { coordinates, ...formValues } = values;
    let data = new FormData();

    data.append('coordinates[latitude]', coordinates.latitude);
    data.append('coordinates[longitude]', coordinates.longitude);

    Object.keys(formValues).forEach((key) => data.append(key, formValues[key]));

    const productionId = _.get(production, 'main.id', production.id);
    createGateReq({
      productionId,
      data,
    });
  },
  displayName: 'CreateGateForm',
});

export default compose<IProps & FormikProps<FormValues>, {}>(
  connect(
    { production },
    { createGateReq, getProductionReq }
  ),
  withLifecycle,
  formicHoc
)(CreateForm);
