/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import * as styles from './styles';

export interface IProps {
  title: string;
  separateTitle?: boolean;
}

jsx;

export const PageHeader: FunctionComponent<IProps> = ({
  children,
  title,
  separateTitle = true,
}): ReactElement<IProps> => {
  return (
    <div css={styles.headerBox}>
      <div css={styles.headerTitle(separateTitle)}>{title}</div>
      <div css={styles.headerContent}>{children}</div>
    </div>
  );
};

export default PageHeader;
