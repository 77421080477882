/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import _ from 'lodash';

import { Fragment } from 'react';
import { withFormik, FormikProps } from 'formik';
import * as yup from 'yup';
import { compose } from 'recompose';

import { connect } from '../../../../../utils/redux';

import { updateClientAgentReq } from './store/actions';
import { Action } from '../../../../../store/types';
import * as validatinShemas from '../../../../../utils/validatinShemas';

import {} from './store/selectors';

import PrimaryBtn from '../../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../../common/buttons/OutlineBtn';
import form from '../../../../common/form';
import page from '../../../../common/page';
import routes from '../../../../../routes';

import { Link } from 'react-router-dom';
import { IClientAgent } from './store/types';
import { Colors } from '../../../../../styles/styles';

interface FormValues {
  name: string;
  phone: string;
  email: string;
}

interface OtherProps {
  updateClientAgentReq: Action<{}>;
  clientAgent: IClientAgent;
  ownerId: string;
}

interface FormProps {
  initialEmail?: string;
}

jsx;

const CreateForm = ({
  errors,
  touched,
  values,
  setFieldValue,
  ownerId,
  clientAgent,
}: OtherProps & FormikProps<FormValues>) => {
  const { FormRow, FormField, FormRadio, FormError, FormBlock, FieldsSet, ActionsRow, Form, FieldsArea } = form;
  const { Page, Breadcrumbs, PageHeader } = page;

  return (
    <Fragment>
      <PageHeader title="Edit agent">
        <Breadcrumbs
          path={[
            { title: 'Clients', link: routes.Clients },
            { title: 'Agents', link: routes.ClientAgents.replace(':owner_id', ownerId) },
            {
              title: 'Edit agent',
              link: routes.ClientAgentEdit.replace(':owner_id', ownerId).replace(':id', `${clientAgent.id}`),
            },
          ]}
        />
      </PageHeader>
      <Page>
        <Form>
          <div>
            <FieldsArea>
              <FormBlock>
                <FieldsSet>
                  <FormRow>
                    <label htmlFor="name">Name</label>
                    <FormField name="name" placeholder="name" />
                    <FormError name="name" errors={errors} touched={touched} />
                  </FormRow>

                  <FormRow>
                    <label htmlFor="email">Email</label>
                    <FormField name="email" placeholder="email" />
                    <FormError name="email" errors={errors} touched={touched} />
                  </FormRow>

                  <FormRow>
                    <label htmlFor="phone">Phone</label>
                    <FormField name="phone" placeholder="+1234567890" />
                    <FormError name="phone" errors={errors} touched={touched} />
                    <p css={style.description}>
                      Please add phone number in international format. Use only '+' and numbers.
                    </p>
                  </FormRow>
                </FieldsSet>
              </FormBlock>
            </FieldsArea>

            <ActionsRow>
              <Link to={routes.ClientAgents.replace(':owner_id', ownerId)}>
                <OutlineBtn title="Cancel" isWide={false} />
              </Link>
              <PrimaryBtn title="Update agent" isWide={false} />
            </ActionsRow>
          </div>
        </Form>
      </Page>
    </Fragment>
  );
};

export const exchangeFormic = withFormik<FormProps & OtherProps, FormValues>({
  mapPropsToValues: ({ clientAgent }) => {
    const map = {
      name: '',
      phone: '',
      email: '',
    };

    return {
      ...map,
      ..._.pickBy(_.pick(clientAgent, _.keys(map)), _.negate(_.isNil)),
    };
  },

  validationSchema: yup.object({}).shape({
    name: validatinShemas.vNameReq,
    phone: validatinShemas.vPhoneReq,
    email: validatinShemas.vEmailReq,
  }),

  handleSubmit: (
    values,
    {
      props: {
        ownerId,
        updateClientAgentReq,
        clientAgent: { id },
      },
      setSubmitting,
    }
  ) => {
    updateClientAgentReq({ values, ownerId, id });
  },
  displayName: 'LoginForm',
});

export default compose<OtherProps & FormikProps<FormValues>, {}>(
  connect(
    {},
    { updateClientAgentReq }
  ),
  exchangeFormic
)(CreateForm);

const style = {
  description: css`
    font-size: 12px;
    color: ${Colors.LightBlack};
    margin: 5px;
  `,
};
