import { createAction } from 'redux-actions';
import { Action } from '../../../../../store/types';
import { createAsyncAction, createPromisifyAsyncAction } from '../../../../../utils/async/actions';
import reducersRegister from '../../../../../utils/reducersRegister';
import sagasRegister from '../../../../../utils/sagasRegister';
import reducers from './reducers';
import sagas from './sagas';

reducersRegister.register('transportTypes', reducers);
sagasRegister.register(sagas);

/******************************************************************************/
/****************************** TRANSPORT_TYPES *******************************/
/******************************************************************************/

export const GET_TRANSPORT_TYPES_REQ: string = 'GET_TRANSPORT_TYPES_REQ';
export const GET_TRANSPORT_TYPES_REQ_SUCCESS: string = 'GET_TRANSPORT_TYPES_REQ_SUCCESS';
export const GET_TRANSPORT_TYPES_REQ_ERROR: string = 'GET_TRANSPORT_TYPES_REQ_ERROR';

export const SET_TRANSPORT_TYPES: string = 'SET_TRANSPORT_TYPES';
export const REFRESH_TRANSPORT_TYPES: string = 'REFRESH_TRANSPORT_TYPES';

export const SET_TRANSPORT_TYPES_PAGE: string = 'SET_TRANSPORT_TYPES_PAGE';
export const SET_TRANSPORT_TYPES_SEARCH_TEXT: string = 'SET_TRANSPORT_TYPES_SEARCH_TEXT';
export const SET_TRANSPORT_TYPES_FILTER: string = 'SET_TRANSPORT_TYPES_FILTER';

export const getTransportTypesReq: Action<{}> = createAsyncAction(GET_TRANSPORT_TYPES_REQ);
export const getTransportTypesReqSuccess: Action<{}> = createAction(GET_TRANSPORT_TYPES_REQ_SUCCESS);
export const getTransportTypesReqError: Action<{}> = createAction(GET_TRANSPORT_TYPES_REQ_ERROR);

export const setTransportTypes: Action<{}> = createAction(SET_TRANSPORT_TYPES);
export const refreshTransportTypes: Action<{}> = createAction(REFRESH_TRANSPORT_TYPES);

export const setTransportTypesPage: Action<{}> = createAction(SET_TRANSPORT_TYPES_PAGE);
export const setTransportTypesSearchText: Action<{}> = createAction(SET_TRANSPORT_TYPES_SEARCH_TEXT);
export const setTransportTypesFilter: Action<{}> = createAction(SET_TRANSPORT_TYPES_FILTER);

/******************************************************************************/
/******************************* CREATE SUPPLIER ********************************/
/******************************************************************************/

export const CREATE_TRANSPORT_TYPE_REQ: string = 'CREATE_TRANSPORT_TYPE_REQ';
export const CREATE_TRANSPORT_TYPE_REQ_SUCCESS: string = 'CREATE_TRANSPORT_TYPE_REQ_SUCCESS';
export const CREATE_TRANSPORT_TYPE_REQ_ERROR: string = 'CREATE_TRANSPORT_TYPE_REQ_ERROR';

export const createTransportTypeReq: Action<{}> = createPromisifyAsyncAction(CREATE_TRANSPORT_TYPE_REQ);
export const createTransportTypeReqSuccess: Action<{}> = createAction(CREATE_TRANSPORT_TYPE_REQ_SUCCESS);
export const createTransportTypeReqError: Action<{}> = createAction(CREATE_TRANSPORT_TYPE_REQ_ERROR);

/******************************************************************************/
/******************************* UPDATE SUPPLIER ********************************/
/******************************************************************************/

export const UPDATE_TRANSPORT_TYPE_REQ: string = 'UPDATE_TRANSPORT_TYPE_REQ';
export const UPDATE_TRANSPORT_TYPE_REQ_SUCCESS: string = 'UPDATE_TRANSPORT_TYPE_REQ_SUCCESS';
export const UPDATE_TRANSPORT_TYPE_REQ_ERROR: string = 'UPDATE_TRANSPORT_TYPE_REQ_ERROR';

export const updateTransportTypeReq: Action<{}> = createPromisifyAsyncAction(UPDATE_TRANSPORT_TYPE_REQ);
export const updateTransportTypeReqSuccess: Action<{}> = createAction(UPDATE_TRANSPORT_TYPE_REQ_SUCCESS);
export const updateTransportTypeReqError: Action<{}> = createAction(UPDATE_TRANSPORT_TYPE_REQ_ERROR);

/******************************************************************************/
/******************************* UPDATE SUPPLIER ********************************/
/******************************************************************************/

export const DELETE_TRANSPORT_TYPE_REQ: string = 'DELETE_TRANSPORT_TYPE_REQ';
export const DELETE_TRANSPORT_TYPE_REQ_SUCCESS: string = 'DELETE_TRANSPORT_TYPE_REQ_SUCCESS';
export const DELETE_TRANSPORT_TYPE_REQ_ERROR: string = 'DELETE_TRANSPORT_TYPE_REQ_ERROR';

export const deleteTransportTypeReq: Action<{}> = createPromisifyAsyncAction(DELETE_TRANSPORT_TYPE_REQ);
export const deleteTransportTypeReqSuccess: Action<{}> = createAction(DELETE_TRANSPORT_TYPE_REQ_SUCCESS);
export const deleteTransportTypeReqError: Action<{}> = createAction(DELETE_TRANSPORT_TYPE_REQ_ERROR);
