/** @jsx jsx */
import { Fragment, Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { jsx, css } from '@emotion/core';

// redux
import { connect } from '../../../../../../utils/redux';

// production
import { getProductionReq, updateProductionDaysReq, setProduction, clearProduction } from '../../store/actions';
import { production, productionLoading } from '../../store/selectors';

// producer
import { producer } from '../../../Producers/store/selectors';
import { clearProducer } from '../../../Producers/store/actions';

// client
import { client } from '../../../Clients/store/selectors';
import { clearClient } from '../../../Clients/store/actions';
import { clientAgents } from '../../../Clients/Agents/store/selectors';
import { clearClientAgent } from '../../../Clients/Agents/store/actions';

// gates
import { gates } from '../Gates/store/selectors';
import { getGatesReq, clearGates } from '../Gates/store/actions';

// transport types
import { transportTypesList } from '../../../TransportTypes/store/selectors';
import { getTransportTypesReq } from '../../../TransportTypes/store/actions';

// production activities
import {
  getAllActivitiesReq,
  clearSelectedActivities,
  setAllActivitiesParams,
  resetAllActivitiesParams,
  getAllTransportsReq,
  setAllTransportsParams,
  resetAllTransportsParams,
} from '../store/actions';
import { allActivities, allTransports } from '../store/selectors';

// supplier functions
import { getSupplierFunctionsReq, refreshSupplierFunctions } from '../../../SupplierFunctions/store/actions';
import { supplierFunctions } from '../../../SupplierFunctions/store/selectors';

// suppliers for select
import { getSuppliersForSelectReq, refreshSuppliersForSelect } from '../../../Suppliers/store/actions';
import { suppliersForSelect } from '../../../Suppliers/store/selectors';

// categories
import { getAllCategoriesRequest } from '../../../Categories/store/actions';
import { allCategoriesList } from '../../../Categories/store/selectors';

// machineries
import { getMachineriesReq } from '../Machinery/store/actions';
import { machineries } from '../Machinery/store/selectors';

// types
import { Action } from '../../../../../../store/types';
import { IProduction } from '../../store/types';
import { IClient } from '../../../Clients/store/types';
import { IClientAgent } from '../../../Clients/Agents/store/types';
import { IProducer } from '../../../Producers/store/types';
import { ActivitiesListState, ActivityTypes } from '../store/types';
import { SupplierFunctionListState } from '../../../SupplierFunctions/store/types';
import { SupplierListState } from '../../../Suppliers/store/types';
import { GatesListState } from '../Gates/store/types';
import { TransportTypeListState } from '../../../TransportTypes/store/types';
import { CategoriesListState } from '../../../Categories/store/types';
import { MachineriesListState } from '../Machinery/store/types';

// page blocks
import GatesBlock from './GatesBlock';
import MachineryBlock from './MachineryBlock';

// components
import Breadcrumbs from '../../../../../common/page/Breadcrumbs';
import PageHeader from '../../../../../common/page/PageHeader';
import AsyncData from '../../../../../common/async/AsyncData';
import PopoverBtn from '../../../../../common/popover/PopoverBtn';
import DropdownButton from '../../../../../common/popover/DropdownButton';
import DropdownMenu from '../../../../../common/popover/DropdownMenu';
import MainInfoBox from '../MainInfoBox';
import MainInfoColBox from '../MainInfoColBox';
import ProductionInfoWidget from '../Widgets/ProductionInfoWidget';
import RelatedProductionsWidget from '../Widgets/RelatedProductionsWidget';
import ClientInfoWidget from '../Widgets/ClientInfoWidget/ClientInfoWidget';
import ProductionLogoWidget from '../Widgets/ProductionLogoWidget';
import ProducerInfoWidget from '../Widgets/ProducerInfoWidget';
import MainActivitiesWidget from '../Widgets/MainActivitiesWidget';
import DateList from '../DateList/DateList';
import Loader from '../../../../../common/loader/Loader';
import widget from '../../../../../common/widget';
import FileThumb from '../../../../../common/form/fileviewer/FileThumb';
import withAcl, { IWrappedProps } from '../../../withAcl';
import ActivityFilters from '../ActivityFilters';

// styles
import * as styles from './styles';

// other
import routes from '../../../../../../routes';
import { ViewModes } from '../Item';

// utils
import _ from 'lodash';
import { extractKeyValue, selectBy } from '../../../../../../utils/collection';
import { splitByColumns } from '../../../../../../utils/array';
const { Widget, WidgetHeader, WidgetBody } = widget;

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams>, IWrappedProps {
  getProductionReq: Action<{}>;
  productionLoading: Boolean;
  setProduction: Action<{}>;
  updateProductionDaysReq: Action<{}>;
  production: IProduction;
  clearProduction: Action<{}>;

  getAllActivitiesReq: Action<{}>;
  setAllActivitiesParams: Action<{}>;
  allActivities: ActivitiesListState;

  getAllTransportsReq: Action<{}>;
  setAllTransportsParams: Action<{}>;
  allTransports: ActivitiesListState;

  client: IClient;
  clientAgents: IClientAgent;
  clearClient: Action<{}>;
  clearClientAgent: Action<{}>;

  producer: IProducer;
  clearProducer: Action<{}>;

  getSupplierFunctionsReq: Action<{}>;
  supplierFunctions: SupplierFunctionListState;
  refreshSupplierFunctions: Action<{}>;

  getSuppliersForSelectReq: Action<{}>;
  suppliersForSelect: SupplierListState;
  refreshSuppliersForSelect: Action<{}>;

  gates: GatesListState;
  getGatesReq: Action<{}>;
  clearGates: Action<{}>;

  transportTypesList: TransportTypeListState;
  getTransportTypesReq: Action<{}>;

  allCategoriesList: CategoriesListState;
  getAllCategoriesRequest: Action<{}>;

  machineries: MachineriesListState;
  getMachineriesReq: Action<{}>;
}

interface IState {
  isPrintModalOpen: boolean;
}

jsx;
class CallSheet extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount = () => {
    const {
      getProductionReq,
      clearProduction,
      clearProducer,
      clearClientAgent,
      clearClient,
      clearGates,
      refreshSupplierFunctions,
      refreshSuppliersForSelect,
      getAllActivitiesReq,
      getAllTransportsReq,
      match: { params },
    } = this.props;

    clearProducer();
    clearClientAgent();
    clearClient();
    clearProduction();
    clearGates();
    refreshSupplierFunctions();
    refreshSuppliersForSelect();

    getProductionReq(params.id, { fetchRelatedData: true });
    const source = 'call-sheet';
    getAllActivitiesReq({ ownerId: params.id, size: 1000, source });
    getAllTransportsReq({
      ownerId: params.id,
      size: 1000,
      'filter.activity.type': 'Transport',
      source,
    });
  };

  componentWillReceiveProps(nextProps: IProps) {
    const {
      getProductionReq,
      production,
      productionLoading,
      match: { params },
    } = nextProps;

    const isCurrentProduction = production && _.get(production, 'id') === Number(params.id);
    if (!isCurrentProduction && !productionLoading) {
      getProductionReq(params.id, { fetchRelatedData: true });
    }
  }

  renderGatesTitle = () => {
    const {
      match: { params },
      test,
    } = this.props;

    return test('gates', 'R') ? (
      <Link to={routes.ProductionGates.replace(':id', `${params.id}`)} css={styles.blockTitle}>
        Gates
      </Link>
    ) : (
      <div css={styles.blockTitleWithoutLink}>Gates</div>
    );
  };

  render() {
    const {
      production,
      client,
      clientAgents,
      producer,
      allActivities,
      getAllActivitiesReq,
      setAllActivitiesParams,
      getAllTransportsReq,
      setAllTransportsParams,
      allTransports,
      supplierFunctions,
      getSupplierFunctionsReq,
      suppliersForSelect,
      getSuppliersForSelectReq,
      gates,
      getGatesReq,
      transportTypesList,
      getTransportTypesReq,
      getAllCategoriesRequest,
      allCategoriesList,
      getMachineriesReq,
      machineries,
      match: { params },
      test,
    }: IProps = this.props;

    const isCurrentProduction = production && _.get(production, 'id') === Number(params.id);
    const clientAgentsData = _.get(clientAgents, 'data');
    const aboutBlock = production
      ? _.find(_.get(production, 'general.blocks', []), (block: any) => block.name === 'about-compan')
      : null;
    const rulesBlock = production
      ? _.find(_.get(production, 'general.blocks', []), (block: any) => block.alias === 'safety-rules')
      : null;

    if (
      _.every(
        [production, client, producer, clientAgentsData, gates, transportTypesList, allCategoriesList, machineries],
        Boolean
      )
    ) {
      const selectedClientData = selectBy(clientAgentsData, { id: extractKeyValue(production.clientAgents, 'id') });
      return (
        <AsyncData
          data={[
            { asyncData: supplierFunctions, asyncGetAction: getSupplierFunctionsReq },
            { asyncData: suppliersForSelect, asyncGetAction: getSuppliersForSelectReq },
            {
              asyncData: gates,
              asyncGetAction: (params: any) => {
                const productionId = _.get(production, 'main.id', production.id);
                return getGatesReq({ ...params, productionId, size: 1000 });
              },
            },
            {
              asyncData: transportTypesList,
              asyncGetAction: (params: any) => {
                return getTransportTypesReq({ ...params, size: 1000 });
              },
            },
            { asyncData: allCategoriesList, asyncGetAction: getAllCategoriesRequest },
            {
              asyncData: machineries,
              asyncGetAction: (params: any) => getMachineriesReq({ ...params, ownerId: production.id }),
            },
          ]}
        >
          {() => {
            const dpActions = [
              {
                label: 'Edit',
                link: routes.ProductionCallSheetEdit.replace(':id', String(production.id)),
                acl: ['callSheet', 'U'],
              },
            ].filter((action) => {
              const acl = _.get(action, 'acl');
              return acl ? test(...acl) : true;
            });

            const blocks = _.chunk(
              [
                ..._.get(production, 'general.blocks', []),
                {
                  name: 'Files',
                  files: _.get(production, 'general.attachments', []),
                  type: 'Attachments',
                },
              ],
              2
            );

            return (
              <Fragment>
                <div css={styles.headerBox}>
                  <PageHeader title="Call sheet">
                    <Breadcrumbs
                      path={[
                        { title: 'Productions', link: routes.Productions },
                        {
                          title: production.name,
                          link: routes.Production.replace(':id', `${production.id}`),
                        },
                        {
                          title: 'Call sheet',
                          link: routes.ProductionCallSheet.replace(':id', `${production.id}`),
                        },
                      ]}
                    />

                    {!!dpActions.length && (
                      <div css={styles.callSheetHeaderBox}>
                        <PopoverBtn
                          btn={<DropdownButton />}
                          align="end"
                          renderContent={(closeDropdown) => (
                            <DropdownMenu closeDropdown={closeDropdown} actions={dpActions} />
                          )}
                        />
                      </div>
                    )}
                  </PageHeader>
                </div>

                <MainInfoBox>
                  <MainInfoColBox>
                    <ProductionInfoWidget production={production} />
                    {production.canHaveSub && <RelatedProductionsWidget production={production} showCreateBtn={false} />}
                  </MainInfoColBox>

                  {test('client', 'R') ? (
                    <MainInfoColBox>
                      <ClientInfoWidget
                        client={client}
                        productionLogo={production.file}
                        clientAgents={selectedClientData}
                      />
                    </MainInfoColBox>
                  ) : (
                    production.file && (
                      <MainInfoColBox>
                        <ProductionLogoWidget productionLogo={production.file} />
                      </MainInfoColBox>
                    )
                  )}

                  <MainInfoColBox>
                    <ProducerInfoWidget producer={producer} />
                    <MainActivitiesWidget production={production} />
                  </MainInfoColBox>
                </MainInfoBox>

                <MainInfoBox>
                  {splitByColumns(blocks).map((coll: any[], key) => (
                    <MainInfoColBox key={key}>
                      {coll.map((block: any, key) => {
                        return block ? (
                          <Widget key={key}>
                            <WidgetHeader title={block.name} />
                            <WidgetBody maxHeight={250}>
                              <div css={styles.widgetContent}>
                                {block.type === 'General' &&
                                  _.get(block, 'description', 'Please go to edit and update this block')}

                                {block.type === 'Attachments' && (
                                  <div css={styles.filesBox}>
                                    {block.files.map((file: any) => {
                                      return (
                                        <div key={file.id} css={styles.iconWrapper}>
                                          <FileThumb
                                            file={file}
                                            isShowName={true}
                                            isLoadableFile={true}
                                            isImagePreview={true}
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </WidgetBody>
                          </Widget>
                        ) : null;
                      })}
                    </MainInfoColBox>
                  ))}
                </MainInfoBox>

                {/* ----------- Production schedule ----------------- */}

                <div css={styles.blockTitleWrap}>
                  <Link to={routes.Production.replace(':id', `${params.id}`)} css={styles.blockTitle}>
                    Production schedule
                  </Link>
                </div>

                <ActivityFilters
                  activities={allActivities}
                  productionId={production.id}
                  suppliers={suppliersForSelect.data}
                  supplierFunctions={supplierFunctions.data}
                  subproductions={Object.values(production.sub)}
                  onChangeParams={(filters: any) => {
                    const currentParams = { ...allActivities.params, ...filters };
                    getAllActivitiesReq({ ...currentParams, ownerId: params.id, size: 1000 });
                    setAllActivitiesParams(currentParams);
                  }}
                  gates={null}
                />

                <DateList
                  allSuppliers={suppliersForSelect.data}
                  allFunctions={supplierFunctions.data}
                  production={production}
                  activities={allActivities.data.filter((item: any) => {
                    return item.type !== ActivityTypes.TRANSPORT;
                  })}
                  isSelectMode={false}
                  isEditMode={false}
                  viewMode={ViewModes.activity}
                  isShowEmpty={false}
                />

                {/* ----------- Transport schedule ----------------- */}

                {production.showTransport && (
                  <Fragment>
                    <div css={styles.blockTitleWrap}>
                      <Link
                        to={routes.Production.replace(':id', `${params.id}`) + '?mode=transport'}
                        css={styles.blockTitle}
                      >
                        Transport schedule
                      </Link>
                    </div>

                    <ActivityFilters
                      activities={allTransports}
                      productionId={production.id}
                      suppliers={suppliersForSelect.data}
                      supplierFunctions={supplierFunctions.data}
                      subproductions={[]}
                      onChangeParams={(filters: any) => {
                        const currentParams = { ...allTransports.params, ...filters };
                        getAllTransportsReq({
                          ...currentParams,
                          ownerId: params.id,
                          size: 1000,
                          'filter.activity.type': 'Transport',
                        });
                        setAllTransportsParams(currentParams);
                      }}
                      gates={gates.data}
                    />

                    <DateList
                      allSuppliers={suppliersForSelect.data}
                      allFunctions={supplierFunctions.data}
                      production={production}
                      activities={allTransports.data}
                      isSelectMode={false}
                      isEditMode={false}
                      viewMode={ViewModes.transport}
                      isShowEmpty={false}
                    />
                  </Fragment>
                )}

                {/* ----------- Machinery schedule ----------------- */}

                {production.showMachinery && (
                  <Fragment>
                    <div css={styles.blockTitleWrap}>
                      <Link to={routes.ProductionMachinery.replace(':id', `${params.id}`)} css={styles.blockTitle}>
                        Machinery schedule
                      </Link>
                    </div>
                    <Widget>
                      <MachineryBlock
                        production={production}
                        machineries={machineries}
                        allCategoriesList={allCategoriesList}
                        suppliersForSelect={suppliersForSelect}
                      />
                    </Widget>
                  </Fragment>
                )}
                {/* -----------  ----------------- */}

                {production.showTransport && (
                  <Fragment>
                    <div css={styles.blockTitleWrap}>{this.renderGatesTitle()}</div>
                    <GatesBlock gates={gates} production={production} />
                  </Fragment>
                )}
              </Fragment>
            );
          }}
        </AsyncData>
      );
    } else {
      return <Loader isLoading />;
    }
  }
}

export default connect(
  {
    production,
    productionLoading,
    client,
    clientAgents,
    producer,
    allActivities,
    allTransports,
    supplierFunctions,
    suppliersForSelect,
    gates,
    transportTypesList,
    allCategoriesList,
    machineries,
  },
  {
    getProductionReq,
    clearProduction,
    setProduction,
    updateProductionDaysReq,
    getAllActivitiesReq,
    setAllActivitiesParams,
    getAllTransportsReq,
    setAllTransportsParams,
    getSupplierFunctionsReq,
    getSuppliersForSelectReq,
    refreshSuppliersForSelect,
    refreshSupplierFunctions,
    clearClient,
    clearClientAgent,
    clearProducer,
    clearSelectedActivities,
    getGatesReq,
    clearGates,
    getTransportTypesReq,
    getAllCategoriesRequest,
    getMachineriesReq,
  }
)(withAcl(CallSheet));
