import si, { ImmutableObject } from 'seamless-immutable';
import _ from 'lodash';
import * as config from '../../../../../../../config';
import * as ru from '../../../../../../../utils/redux';
import * as r from '../../../../../../../utils/reducers';

import { getListState, ListStatuses } from '../../../../../../../utils/store/state/state';

import { MachineriesListState } from './types';

import * as actions from './actions';

interface IState {
  list: MachineriesListState;
}

const INITIAL_STATE: ImmutableObject<any> = si({
  list: getListState({
    ownerId: null,
    size: config.UNLIMITED_LIST_SIZE,
  }),
});

const listReducer = r.createReducer('list');

export default ru.processActions(
  () => ({
    //LIST
    [actions.GET_MACHINERIES_REQ]: listReducer(r.loadingReducer),
    [actions.GET_MACHINERIES_REQ_SUCCESS]: listReducer(r.successReducer),
    [actions.GET_MACHINERIES_REQ_ERROR]: listReducer(r.errorReducer),
    [actions.SET_MACHINERIES]: listReducer(r.listDataReducer),
    [actions.SET_MACHINERIES_PARAMS]: listReducer(r.paramsReducer),
    [actions.ADD_MACHINERY]: listReducer(r.addDataReducer('id')),
    [actions.UPDATE_MACHINERY]: listReducer(r.updateDataReducer('id')),
    [actions.DELETE_MACHINERY]: listReducer(r.deleteDataReducer('id')),

    [actions.CREATE_MACHINERY_REQ]: listReducer(r.loadingReducer),
    [actions.CREATE_MACHINERY_REQ_SUCCESS]: listReducer(r.successReducer),
    [actions.CREATE_MACHINERY_REQ_ERROR]: listReducer(r.errorReducer),

    [actions.UPDATE_MACHINERY_REQ]: listReducer(r.loadingReducer),
    [actions.UPDATE_MACHINERY_REQ_SUCCESS]: listReducer(r.successReducer),
    [actions.UPDATE_MACHINERY_REQ_ERROR]: listReducer(r.errorReducer),

    [actions.DELETE_MACHINERY_REQ]: listReducer(r.loadingReducer),
    [actions.DELETE_MACHINERY_REQ_SUCCESS]: listReducer(r.successReducer),
    [actions.DELETE_MACHINERY_REQ_ERROR]: listReducer(r.errorReducer),

    [actions.CLONE_MACHINERY_REQ]: listReducer(r.loadingReducer),
    [actions.CLONE_MACHINERY_REQ_SUCCESS]: (store: any, { payload: { clonedId, machinery } }: any) => {
      const index = _.findIndex(store.list.data, { id: clonedId }) + 1;

      return store
        .setIn(
          ['list', 'data'],
          store.list.data // insert new machinery in list after the cloned
            .slice(0, index)
            .concat(machinery)
            .concat(store.list.data.slice(index).asMutable())
        )
        .setIn(['list', 'status'], ListStatuses.Actual);
    },
    [actions.CLONE_MACHINERY_REQ_ERROR]: listReducer(r.successReducer),

    [actions.CLEAR_MACHINERIES_DATA]: () => INITIAL_STATE,
  }),
  INITIAL_STATE
);
