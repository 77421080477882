import si, { ImmutableObject } from 'seamless-immutable';
import * as ru from '../../../../../utils/redux';
import { getListState, getItemState } from '../../../../../utils/store/state/state';
import * as r from '../../../../../utils/reducers';
import {
  SUPPLIER_FUNCTIONS_LOAD_REQUEST,
  SUPPLIER_FUNCTIONS_LOAD_REQUEST_ERROR,
  SUPPLIER_FUNCTIONS_LOAD_REQUEST_SUCCESS,
  SUPPLIER_FUNCTIONS_ADD_REQUEST,
  SUPPLIER_FUNCTIONS_ADD_REQUEST_ERROR,
  SUPPLIER_FUNCTIONS_ADD_REQUEST_SUCCESS,
  SUPPLIER_FUNCTIONS_DELETE_REQUEST,
  SUPPLIER_FUNCTIONS_DELETE_REQUEST_ERROR,
  SUPPLIER_FUNCTIONS_DELETE_REQUEST_SUCCESS,
  SUPPLIER_FUNCTIONS_UPDATE_REQUEST,
  SUPPLIER_FUNCTIONS_UPDATE_REQUEST_ERROR,
  SUPPLIER_FUNCTIONS_UPDATE_REQUEST_SUCCESS,
  SUPPLIER_FUNCTIONS_SHOW_MODAL_UPDATE,
  SUPPLIER_FUNCTIONS_SHOW_MODAL_DELETE,
} from './actions';
import * as actions from './actions';
import { SupplierFunctions } from './types';
// utils
import _ from 'lodash';

interface IState {
  suppliersLoading: boolean;
  suppliersList: SupplierFunctions;
  suppliersUpdateLoading: boolean;
  suppliersDeleteLoading: boolean;
  suppliersAddLoading: boolean;
  suppliersUpdateModal: boolean;
  suppliersDeleteModal: boolean;
}

const INITIAL_STATE: ImmutableObject<IState> = si({
  // LIST
  list: getListState({
    size: 1000,
  }),
  // ITEM
  item: getItemState({ id: 0 }),
  // DEPRECATED
  suppliersLoading: false,
  suppliersList: [],
  suppliersListMeta: null,
  suppliersListParams: null,
  suppliersUpdateLoading: false,
  suppliersDeleteLoading: false,
  suppliersAddLoading: false,
  suppliersUpdateModal: false,
  suppliersDeleteModal: false,
});

const listReducer = r.createReducer('list');
const itemReducer = r.createReducer('item');

export default ru.processActions(
  () => ({
    // LIST
    [actions.GET_SUPPLIER_FUNCTIONS_REQ]: listReducer(r.loadingReducer),
    [actions.GET_SUPPLIER_FUNCTIONS_REQ_SUCCESS]: listReducer(r.successReducer),
    [actions.GET_SUPPLIER_FUNCTIONS_REQ_ERROR]: listReducer(r.errorReducer),
    [actions.SET_SUPPLIER_FUNCTIONS]: listReducer(r.listDataReducer),

    [actions.GET_SUPPLIER_FUNCTION_REQ]: itemReducer(r.loadingReducer),
    [actions.REFRESH_SUPPLIER_FUNCTIONS]: listReducer(r.refreshReducer),
    [actions.SET_SUPPLIER_FUNCTIONS_PAGE]: listReducer(r.paramsPageReducer),
    [actions.SET_SUPPLIER_FUNCTIONS_SEARCH_TEXT]: listReducer(r.paramsSearchReducer),
    [actions.SET_SUPPLIER_FUNCTIONS_FILTER]: listReducer(r.paramsFilterReducer),

    // ITEM GET
    [actions.GET_SUPPLIER_FUNCTION_REQ]: itemReducer(r.loadingReducer),
    [actions.GET_SUPPLIER_FUNCTION_REQ_SUCCESS]: itemReducer(r.successReducer),
    [actions.GET_SUPPLIER_FUNCTION_REQ_ERROR]: itemReducer(r.errorReducer),
    [actions.SET_SUPPLIER]: itemReducer(r.itemDataReducer),

    // ITEM CREATE
    [actions.CREATE_SUPPLIER_FUNCTION_REQ]: itemReducer(r.loadingReducer),
    [actions.CREATE_SUPPLIER_FUNCTION_REQ_SUCCESS]: itemReducer(r.successReducer),
    [actions.CREATE_SUPPLIER_FUNCTION_REQ_ERROR]: itemReducer(r.errorReducer),
    [actions.CREATE_SUPPLIER_START]: itemReducer(r.startCreateReducer),
    [actions.CREATE_SUPPLIER]: itemReducer(r.createdReducer),

    // ITEM UPDATE
    [actions.UPDATE_SUPPLIER_FUNCTION_REQ]: itemReducer(r.loadingReducer),
    [actions.UPDATE_SUPPLIER_FUNCTION_REQ_SUCCESS]: itemReducer(r.successReducer),
    [actions.UPDATE_SUPPLIER_FUNCTION_REQ_ERROR]: itemReducer(r.errorReducer),
    [actions.UPDATE_SUPPLIER]: itemReducer(r.updatedReducer),

    // ITEM DELETE
    [actions.DELETE_SUPPLIER]: itemReducer(r.loadingReducer),
    [actions.DELETE_SUPPLIER_FUNCTION_REQ_SUCCESS]: itemReducer(r.successReducer),
    [actions.DELETE_SUPPLIER_FUNCTION_REQ_ERROR]: itemReducer(r.errorReducer),
    [actions.DELETE_SUPPLIER]: itemReducer(r.deletedReducer),

    // DEPRECATED
    // list
    [SUPPLIER_FUNCTIONS_LOAD_REQUEST]: (store: ImmutableObject<IState>) => {
      return store.set('suppliersLoading', true);
    },
    [SUPPLIER_FUNCTIONS_LOAD_REQUEST_SUCCESS]: (store: ImmutableObject<IState>, action: any) => {
      const page = _.get(action.payload, 'params.page', 0);
      return store
        .set('suppliersLoading', false)
        .set('suppliersList', page > 0 ? store.suppliersList.concat(action.payload.data) : action.payload.data)
        .set('suppliersListParams', action.payload.params)
        .set('suppliersListMeta', action.meta);
    },
    [SUPPLIER_FUNCTIONS_LOAD_REQUEST_ERROR]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('suppliersLoading', false);
    },

    // add
    [SUPPLIER_FUNCTIONS_ADD_REQUEST]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('suppliersAddLoading', true);
    },
    [SUPPLIER_FUNCTIONS_ADD_REQUEST_SUCCESS]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('suppliersAddLoading', false);
    },
    [SUPPLIER_FUNCTIONS_ADD_REQUEST_ERROR]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('suppliersAddLoading', false);
    },

    // delete
    [SUPPLIER_FUNCTIONS_DELETE_REQUEST]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('suppliersDeleteLoading', true);
    },
    [SUPPLIER_FUNCTIONS_DELETE_REQUEST_SUCCESS]: (store: ImmutableObject<IState>, action: any) => {
      const suppliersList = store.suppliersList.filter((func) => func.id !== action.payload.id);
      return store
        .set('suppliersDeleteLoading', false)
        .set('suppliersList', suppliersList)
        .set('suppliersDeleteModal', false);
    },
    [SUPPLIER_FUNCTIONS_DELETE_REQUEST_ERROR]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('suppliersDeleteLoading', false);
    },

    // update
    [SUPPLIER_FUNCTIONS_UPDATE_REQUEST]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('suppliersUpdateLoading', true);
    },
    [SUPPLIER_FUNCTIONS_UPDATE_REQUEST_SUCCESS]: (store: ImmutableObject<IState>, action: any) => {
      const index = store.suppliersList.findIndex((func) => func.id === action.payload.id);
      // @ts-ignore
      const suppliersList = store.suppliersList.set(index, action.payload);

      return store
        .set('suppliersUpdateLoading', false)
        .set('suppliersList', suppliersList)
        .set('suppliersUpdateModal', false);
    },
    [SUPPLIER_FUNCTIONS_UPDATE_REQUEST_ERROR]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('suppliersUpdateLoading', false);
    },
    [SUPPLIER_FUNCTIONS_SHOW_MODAL_UPDATE]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('suppliersUpdateModal', action.payload);
    },
    [SUPPLIER_FUNCTIONS_SHOW_MODAL_DELETE]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('suppliersDeleteModal', action.payload);
    },
  }),
  INITIAL_STATE
);
