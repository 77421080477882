/** @jsx jsx */
import { jsx } from '@emotion/core';
import { InputHTMLAttributes, Component } from 'react';
import * as styles from './styles';

interface IProps extends InputHTMLAttributes<{}> {}

export interface IState {
  text: string;
}

jsx;
class Text extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    const { ...props } = this.props;
    return (
      <div css={styles.formFieldBox}>
        <input type="text" {...props} />
      </div>
    );
  }
}

export default Text;
