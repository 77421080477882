import _ from 'lodash';

import * as string from './string';
import * as func from './func';

export const buildData = (object, scheme) => {
  return _.mapValues(scheme, (path) => _.get(object, path));
};

export const isSimilarObjects = (obj1, obj2, keys) => {
  return keys.every((key) => func.isSimilarValues(_.get(obj1, key), _.get(obj2, key)));
};

export const keysRemap = (data, scheme) => {
  return _.reduce(
    scheme,
    (res, newKey, key) => {
      const dataItemValue = _.get(res, key);
      if (!_.isUndefined(dataItemValue)) {
        res = { ...res, ...{ [newKey]: dataItemValue } };
        delete res[key];
      }
      return res;
    },
    data
  );
};

export const resolvePath = (path) => {
  return func.typeCheck([func.isString, func.isArray], path, 'path must be string or array of string')
    ? string.stringToPath(path)
    : [];
};

export const prop = (object, path, defaultValue = null) => {
  return func.isObject(object) ? object[path] || defaultValue : defaultValue;
};

export const get = (object, path, defaultValue = null) => {
  return resolvePath(path).reduce((result, currentProp) => {
    return prop(result, currentProp, defaultValue);
  }, object);
};

export const set = (object, path, value) => {
  return update(object, path, () => value);
};

export const update = (object, path, handler) => {
  path = resolvePath(path);
  const currentProp = path.shift();
  return currentProp
    ? { ...object, ...{ [currentProp]: update(prop(object, currentProp), path, handler) } }
    : handler(object);
};
