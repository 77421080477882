/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { withFormik, Form, FormikProps } from 'formik';
import * as yup from 'yup';
import { compose, lifecycle } from 'recompose';
// redux
import { connect } from '../../../../utils/redux';
import { profileUpdateRequest, ProfileUpdateRequest, profileLoadRequest, ProfileLoadRequest } from './store/actions';
import { userProfile } from './store/selectors';
import { User } from '../../../../store/types';
// components
import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import FormRow from '../../../common/form/FormRow';
import FormField from '../../../common/form/FormField';
import FormError from '../../../common/form/FormError';
import Checkbox from '../../../common/form/Checkbox';
import FormHeader from '../Dashboard/FormHeader';
import OutlineBtn from '../../../common/buttons/OutlineBtn';
// other
import * as styles from './styles';
import _ from 'lodash';
import { Colors } from '../../../../styles/styles';

interface FormValues {
  name: string;
  phone: string;
  email: string;
  amPm: boolean;
}

interface IProps {
  profileUpdateRequest: ProfileUpdateRequest;
  profileLoadRequest: ProfileLoadRequest;
  userProfile: User;
}

jsx;
const withLifecycle = lifecycle<IProps, {}>({
  componentDidMount() {
    this.props.profileLoadRequest();
  },
});

const UpdateProfileForm = ({
  userProfile,
  errors,
  touched,
  handleChange,
  handleBlur,
  values,
}: IProps & FormikProps<FormValues>) => {
  return (
    <div css={styles.formSize}>
      {userProfile ? (
        <div>
          <FormHeader header="Edit my profile" />
          <Form>
            <FormRow>
              <label htmlFor="name">Name</label>
              <FormField name="name" placeholder="name" />
              <FormError name="name" errors={errors} touched={touched} />
            </FormRow>
            <FormRow>
              <label htmlFor="phone">Phone</label>
              <FormField name="phone" placeholder="+1234567890" />
              <FormError name="phone" errors={errors} touched={touched} />
              <p css={style.description}>Please add phone number in international format. Use only '+' and numbers.</p>
            </FormRow>
            <FormRow>
              <label htmlFor="email">Email</label>
              <FormField name="email" placeholder="email" />
              <FormError name="email" errors={errors} touched={touched} />
            </FormRow>
            <br />
            <FormRow>
              <label>
                <Checkbox name="amPm" checked={values.amPm} onChange={handleChange} />
                <span style={{ marginLeft: '5px' }}>Use 12 hours (AM/PM) time format</span>
              </label>
              <FormError name="amPm" errors={errors} touched={touched} />
            </FormRow>
            <div css={styles.formButtons}>
              <PrimaryBtn title="Save" />
              <OutlineBtn type="button" onClick={() => window.history.back()} title="Cancel" />
            </div>
          </Form>
        </div>
      ) : (
        'Loading...'
      )}
    </div>
  );
};

const validationSchema = yup.object({}).shape({
  name: yup.string().required('Required'),
  phone: yup
    .string()
    .min(8, 'Min numbers 8')
    .required('Required'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Required'),
});

export const exchangeFormic = withFormik<IProps, FormValues>({
  enableReinitialize: true,
  mapPropsToValues: ({ userProfile }) => {
    return {
      email: userProfile ? userProfile.email : '',
      phone: userProfile ? userProfile.phone : '',
      name: userProfile ? userProfile.name : '',
      amPm: _.get(userProfile, 'amPm', false),
    };
  },
  validationSchema,
  handleSubmit: (values, { props: { profileUpdateRequest } }) => {
    profileUpdateRequest(values);
  },
  displayName: 'UpdateProfileForm',
});

export default compose<IProps & FormikProps<FormValues>, {}>(
  connect(
    { userProfile },
    { profileUpdateRequest, profileLoadRequest }
  ),
  withLifecycle,
  exchangeFormic
)(UpdateProfileForm);

const style = {
  description: css`
    font-size: 12px;
    color: ${Colors.LightBlack};
    margin: 5px;
  `,
};
