/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { InputHTMLAttributes, Component } from 'react';
import * as styles from './styles';

interface IProps extends InputHTMLAttributes<{}> {}

export interface IState {
  text: string;
}
jsx;
export const Text = React.forwardRef<HTMLInputElement, IProps>((props, ref) => {
  return (
    <div css={styles.formFieldBox}>
      <input type="text" {...props} ref={ref} tabIndex={0} />
    </div>
  );
});

export default Text;
