/** @jsx jsx */
import { FunctionComponent, ReactElement } from 'react';
// components
import widget from '../../../../../common/widget';
// styles
import { jsx, css } from '@emotion/core';

export interface IProps {
  productionLogo: string;
}

jsx;
export const ProductionLogoWidget: FunctionComponent<IProps> = ({ productionLogo }): ReactElement<IProps> => {
  const { Widget, WidgetHeader } = widget;

  return (
    <Widget>
      <WidgetHeader title="Production" />

      <div css={style.contentBox}>
        <img
          src={productionLogo}
          alt="Production image"
          css={style.image}
        />
      </div>
    </Widget>
  );
};

export default ProductionLogoWidget;

const style = {
  image: css`
    max-width: 250px;
    max-height: 250px;
    margin: 5px auto;
    display: block;
  `,
  contentBox: css`
    max-height: 300px;
    overflow-y: auto;
  `,
};
