/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, Fragment } from 'react';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router';
import routes from '../../../../../routes';

import React from 'react';
import List from './List';
import Create from './Create';
import Edit from './Edit';
import { Action } from '../../../../../store/types';
import { ISupplier } from '../store/types';
import withAcl, { IWrappedProps } from '../../withAcl';

interface MatchParams {
  owner_id?: string;
}

interface IProps extends RouteComponentProps<MatchParams>, IWrappedProps {}

jsx;

const Agents: FunctionComponent<IProps> = ({ match, test }): ReactElement<IProps> => {
  const ownerId = match.params.owner_id;

  return (
    <Fragment>
      <Switch>
        <Route exact path={routes.SupplierAgents} component={(props: any) => <List {...props} ownerId={ownerId} />} />

        {test('supplier', 'C') && (
          <Route
            exact
            path={routes.SupplierAgentCreate.replace(':owner_id', `${ownerId}`)}
            component={(props: any) => <Create {...props} ownerId={ownerId} />}
          />
        )}
        {test('supplier', 'U') && (
          <Route
            exact
            path={routes.SupplierAgentEdit}
            component={(props: any) => <Edit {...props} ownerId={ownerId} />}
          />
        )}

        <Route component={() => <Redirect to={routes.SupplierAgents.replace(':owner_id', `${ownerId}`)} />} />
      </Switch>
    </Fragment>
  );
};

export default withAcl(Agents);
