import moment from 'moment';
import _ from 'lodash';

export const getFreeSpaces = (otherUserRanges: any[], limitRange: any) => {
  const timings = [
    limitRange.from,
    ...otherUserRanges.reduce((result, { from, to }) => {
      return [...result, from, to];
    }, []),
    limitRange.to,
  ];

  return _.chunk(timings, 2).map(([from, to]) => {
    return { from, to };
  });
};

export const getRangeCenter = ({ from, to }: any) => {
  return (moment.utc(from).valueOf() + moment.utc(to).valueOf()) / 2;
};

export const getRangeSize = ({ from, to }: any) => {
  return getTimeDiff(from, to);
};

export const getTimeDiff = (x: any, y: any) => {
  return Math.abs(moment.utc(x).valueOf() - moment.utc(y).valueOf());
};

export const correctRange = (range: any, otherUserRanges: any[], limitBox: any) => {
  const rangeCenter = getRangeCenter(range);
  const rangeSize = getRangeSize(range);
  const freeSpaces = getFreeSpaces(otherUserRanges, limitBox).filter((space: any) => {
    return getRangeSize(space) >= rangeSize;
  });

  const [newPosition, direction] = freeSpaces.reduce(
    ([resultPosition, resultDirection]: any, { from, to }: any) => {
      const [position, direction] =
        getTimeDiff(from, rangeCenter) < getTimeDiff(to, rangeCenter) ? [from, 'to'] : [to, 'from'];

      return resultPosition === null || getTimeDiff(resultPosition, rangeCenter) > getTimeDiff(position, rangeCenter)
        ? [position, direction]
        : [resultPosition, resultDirection];
    },
    [null, null]
  );

  if (!newPosition) {
    return null;
  }

  return direction === 'from'
    ? { from: moment.utc(newPosition).valueOf() - rangeSize, to: newPosition }
    : { from: newPosition, to: moment.utc(newPosition).valueOf() + rangeSize };
};
