/** @jsx jsx */
import { Fragment, Component } from 'react';
import { jsx, css } from '@emotion/core';

//components
import ActionsRow from '../../../common/form/ActionsRow';
import modal from '../../modal';
import RemoveBox from '../../../common/form/RemoveBox';

//utils
import { selectOneBy } from '../../../../utils/collection';

import * as styles from './styles';

//icons
import excel from './images/excel.svg';
import word from './images/word.svg';
import file from './images/file.svg';

import _ from 'lodash';
import OutlineBtn from '../../buttons/OutlineBtn';

interface IProps {
  file: any;
  isShowName?: boolean;
  isLoadableFile?: boolean;
  isImagePreview?: boolean;
}

interface IState {
  src: any;
  ext: string;
  isImage: boolean;
  isShowPreview: boolean;
  name: string;
}

export const IMAGE_FILE_TYPES = ['png', 'gif', 'jpeg', 'jpg', 'svg'];

export const ALLOWABLE_FILE_TYPES = ['xls', 'xlsx', 'doc', 'docx', 'pdf', ...IMAGE_FILE_TYPES];

export const FILE_ICONS = [
  {
    ext: ['xls', 'xlsx'],
    icon: excel,
  },
  {
    ext: ['doc', 'docx'],
    icon: word,
  },
];

export const getFileIcon = (ext: string) => {
  return _.get(selectOneBy(FILE_ICONS, { ext }), 'icon') || file;
};

jsx;
class FileViewer extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      src: null,
      ext: '',
      isImage: false,
      isShowPreview: false,
      name: '',
    };
  }

  componentDidMount(): void {
    this.initFile(this.props.file);
  }

  initFile = (file: any) => {
    this.setSrc(file);
    this.setExtension(file);
    this.setIsImage(file);
    this.setName(file);
  };

  setName = (file: any) => {
    const name = _.isString(file) ? file : file.name || file.originalname;
    this.setState({ name });
  };

  setSrc = (file: any) => {
    if (_.isString(file)) {
      this.setState({ src: file });
    } else if (_.isString(_.get(file, 'file'))) {
      this.setState({ src: _.get(file, 'file') });
    } else {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({ src: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  setExtension = (file: any) => {
    this.setState({ ext: this.getFileExt(file) });
  };

  setIsImage = (file: any) => {
    this.setState({ isImage: IMAGE_FILE_TYPES.includes(this.getFileExt(file)) });
  };

  getFileExt = (file: any): string => {
    const type = _.isString(file) ? file : file.file || file.name || '';
    return type
      .split('.')
      .pop()
      .toLowerCase();
  };

  onClose = () => {
    this.setState({ isShowPreview: false });
  };

  onOpen = () => {
    this.setState({ isShowPreview: true });
  };

  renderFileIcon = () => {
    const { src, ext, name } = this.state;
    const { isLoadableFile = false } = this.props;

    const icon = <div css={styles.fileIcon()} style={{ backgroundImage: `url(${getFileIcon(ext)})` }} />;
    return isLoadableFile ? (
      <a href={src} download={name}>
        {icon}
      </a>
    ) : (
      icon
    );
  };

  renderImageIcon = () => {
    const { src, isShowPreview } = this.state;
    const { isImagePreview = false } = this.props;

    const { Modal, ModalBody } = modal;

    const onOpen = isImagePreview ? this.onOpen : _.identity;
    const image = (
      <div css={styles.imageIconBox}>
        <img src={src} css={styles.imageIcon(isImagePreview ? { cursor: 'pointer' } : {})} onClick={onOpen} />
      </div>
    );

    return !isShowPreview ? (
      image
    ) : (
      <Fragment>
        {image}
        <Modal isOpen={true} shouldCloseOnOverlayClick={true} onRequestClose={this.onClose}>
          <RemoveBox onRemove={this.onClose}>
            <ModalBody>
              <div css={styles.imagePreviewBox}>
                <img src={src} />
              </div>
            </ModalBody>
          </RemoveBox>
        </Modal>
      </Fragment>
    );
  };

  render() {
    const { isImage, name } = this.state;
    const { isShowName = false } = this.props;
    const icon = isImage ? this.renderImageIcon() : this.renderFileIcon();

    return (
      <div className="thumb-box" css={styles.thumbBox}>
        <div>{icon}</div>
        {isShowName && <div css={styles.name}>{name}</div>}
      </div>
    );
  }
}

export default FileViewer;
