/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import * as styles from '../../../styles/styles';

export const reactModal = {
  content: {
    minWidth: '450px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    border: 0,
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '2px 2px 7px rgba(150, 150, 150, 0.25)',
    borderRadius: '2px',
    backgroundColor: styles.Colors.White,
    overflow: 'visible',
  },

  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
};

export const reactModalTopPlaced = {
  content: {
    minWidth: '450px',
    top: '27%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    border: 0,
    padding: 0,
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '2px 2px 7px rgba(150, 150, 150, 0.25)',
    borderRadius: '2px',
    backgroundColor: styles.Colors.White,
    overflow: 'visible',
  },

  overlay: {
    backgroundColor: 'red',
  },
};

export const header = [
  styles.H2,
  css({
    textAlign: 'center',
    padding: '20px 30px',
    borderBottom: `1px solid ${styles.Colors.Grey}`,
  }),
];

export const body = [
  styles.MainText,
  css({
    padding: '20px 30px',
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
  }),
];
export const bodyVisibleOverflowY = [
  styles.MainText,
  css({
    padding: '20px 30px',
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'visible',
    position: 'relative',
  }),
];

export const footer = [
  styles.MainText,
  css({
    padding: '13px 30px',
    borderTop: `1px solid ${styles.Colors.Grey}`,
  }),
];

export const actionsBox = css({
  '&  button': {
    marginLeft: '20px',
  },
  display: 'flex',
  justifyContent: 'flex-end',
});

export const xxxx = css({});
