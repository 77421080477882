/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { withFormik, Form, FormikProps } from 'formik';
// redux
import { compose } from 'recompose';
import { SupplierListState } from '../../Suppliers/store/types';
// types
import { IProduction } from '../store/types';
import { Action } from '../../../../../store/types';
// components
import modal from '../../../../common/modal';
import FormRow from '../../../../common/form/FormRow';
import FormError from '../../../../common/form/FormError';
import PrimaryBtn from '../../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../../common/buttons/OutlineBtn';
import Datepicker from '../../../../common/form/datepicker/Datepicker';
import dateIcon from '../../../../../images/icons/date.svg';
// utils
import * as yup from 'yup';
import { SupplierFunctionListState } from '../../SupplierFunctions/store/types';

const { Modal, ModalHeader, ModalFooter, ModalBody, ModalActions } = modal;

interface FormValues {
  shiftDate: any;
}

interface OtherProps {
  isOpen: boolean;
  onClose: () => void;
  production: IProduction;
  activities: Number[];
  activitiesShift: Action<{}>;
  suppliersForSelect?: SupplierListState;
  getSuppliersForSelectReq?: Action<{}>;
  createSupplierReq?: Action<{}>;
  supplierFunctions?: SupplierFunctionListState;
  supplierFunctionsLoadRequest?: Action<{}>;
}

jsx;
const ActivitiesMoveModal = ({
  isOpen,
  onClose,
  production,
  activities,
  setFieldValue,
  resetForm,
  errors,
  touched,
  values,
}: OtherProps & FormikProps<FormValues>) => {
  const productionRange = { from: production.loadIn.from, to: production.loadOut.to };

  const onCancelClick = () => {
    resetForm();
    onClose();
  };

  return (
    <div>
      <Modal isOpen={isOpen}>
        <Form css={style.modal}>
          <ModalHeader>{`Move activities (${activities.length})`}</ModalHeader>
          <ModalBody overflowShown="visible">
            <div css={style.modalBody}>
              <FormRow>
                <label css={style.dateLabel}>Date</label>
                <Datepicker
                  value={values.shiftDate}
                  onChange={(value: number) => setFieldValue('shiftDate', value)}
                  dateFormat={'DD.MM.YYYY'}
                  timeFormat={false}
                  autoFocus
                  range={productionRange}
                  css={style.datePicker}
                />
                <FormError name="shiftDate" errors={errors} touched={touched} />
              </FormRow>
            </div>
          </ModalBody>

          <ModalFooter>
            <ModalActions css={style.modalActions}>
              <OutlineBtn type="button" onClick={onCancelClick} title="Cancel" />
              <PrimaryBtn type="submit" title="Update" style={{ marginRight: 0 }} />
            </ModalActions>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

const validationSchema = yup.object({}).shape({
  shiftDate: yup.number(),
});
export default compose<OtherProps & FormikProps<FormValues>, OtherProps>(
  withFormik<OtherProps, FormValues>({
    mapPropsToValues: ({production}) => ({
      shiftDate: production.loadIn.from,
    }),
    validationSchema,
    handleSubmit: ({ shiftDate }, { props, resetForm }) => {
      const { activitiesShift, production, activities, onClose } = props;
      activitiesShift({
        ownerId: production.id,
        data: {
          ids: activities,
          data: { from: shiftDate },
        },
      });
      onClose();
      resetForm();
    },
    displayName: 'ActivitiesMoveModal',
  })
)(ActivitiesMoveModal);

const style = {
  modal: css`
    width: 500px;
  `,
  modalActions: css`
    text-align: right;
  `,
  modalBody: css`
    padding: 10px 20px 30px;
  `,
  datePicker: css`
    height: 30%;
  `,
  dateLabel: css`
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 36px;
      transform: translate(75%, -20%);
      width: 36px;
      background-image: url(${dateIcon});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 15px;
    }
  `,
};
