/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import * as styles from '../../../../styles/styles';

export const actionText = [
  styles.MainText,
  css({
    '& a': [
      styles.MainText,
      {
        textDecoration: 'none',
        whiteSpace: 'nowrap',
      },
    ],
  }),
];

export const xxx = css({});
