export const LOGIN_TOKEN = "login_token";

export const getToken = () => {
  return localStorage.getItem(LOGIN_TOKEN) || null;
};

export const setToken = hash => {
  return localStorage.setItem(LOGIN_TOKEN, hash);
};

export const clearToken = () => {
  return setToken("");
};
