/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { FunctionComponent, ReactElement } from 'react';
import _ from 'lodash';
import * as styles from './styles';

const COLL_WIDTH = 25;

export interface IProps {}

jsx;
export const FieldsSetHorizontal: FunctionComponent<IProps> = ({ children }): ReactElement<IProps> => {
  const width = 100 / React.Children.count(children);

  return (
    <div css={box}>
      {React.Children.map(children, (child) => {
        return (
          <div className="fieldset-item" css={itemBox(width)}>
            {child}
          </div>
        );
      })}
    </div>
  );
};

export default FieldsSetHorizontal;

const box = css({
  display: 'flex',
  flexWrap: 'nowrap',
  width: '100%',
  '& :first-of-type.fieldset-item': {
    paddingLeft: '0px',
  },
});

const itemBox = (width: number) =>
  css({
    flexGrow: 1,
    paddingLeft: '30px',
    minWidth: `${width}%`,
    boxSizing: 'border-box',
  });
