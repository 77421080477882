/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';

// components
import { Link as RouteLink } from 'react-router-dom';
import IconBtn from '../../../../../common/buttons/IconBtn';
import ConfirmButton from '../../../../../common/buttons/ConfirmButton';

// types
import { Action } from '../../../../../../store/types';
import { IGate } from './store/types';

// styles
import * as styles from './styles';
import editIcon from '../../../../../common/grid/images/edit.svg';
import deleteIcon from '../../../../../common/grid/images/delete.svg';

// other
import Routes from '../../../../../../routes';
import withAcl, { IWrappedProps } from '../../../withAcl';

interface IProps extends IWrappedProps {
  productionId: string | number;
  gate: IGate;
  active?: boolean;
  showManageButtons?: boolean;
  onDeleteGate?: Action<{}>;
}

jsx;

const GateCard: FunctionComponent<IProps> = ({
  gate,
  productionId,
  active,
  showManageButtons = true,
  onDeleteGate,
  test,
}): ReactElement<IProps> => {
  return (
    <div className="gate-col" css={styles.gateBox(active ? active : false)}>
      <div css={styles.gateHeader}>
        <h3 title={gate.name}>{gate.name}</h3>
        {showManageButtons && (
          <div css={styles.gateManageBtn}>
            {test('gates', 'U') && (
              <RouteLink
                to={Routes.ProductionGatesEdit.replace(':id', `${productionId}`).replace(':gateId', `${gate.id}`)}
              >
                <IconBtn icon={editIcon} iconSize="20px" />
              </RouteLink>
            )}
            {test('gates', 'D') && (
              <ConfirmButton
                btn={<IconBtn icon={deleteIcon} iconSize="16px" />}
                confirmMessage="Are you sure want to delete this item?"
                onConfirm={() => onDeleteGate(gate.id)}
              />
            )}
          </div>
        )}
      </div>

      <div css={styles.gateContent}>
        <div css={styles.gateContentRow}>
          <div css={styles.gateContentCell}>
            <div css={styles.cellTitle}>Address</div>
            <div css={styles.cellContent}>{gate.address}</div>
          </div>
          <div css={styles.gateContentCell}>
            <div css={styles.cellTitle}>GPS coordinates (DD)</div>
            <div css={styles.cellContent}>
              <span>{gate.coordinates.latitude}</span>
              <span>{gate.coordinates.longitude}</span>
            </div>
          </div>
        </div>
        <div css={styles.gateContentRow}>
          <div css={styles.cellTitle}>Remark</div>
          <div css={styles.cellContent}>{gate.remark}</div>
        </div>
        <div css={styles.gateContentRow}>
          <div css={styles.cellContent}>
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${gate.coordinates.latitude},${
                gate.coordinates.longitude
              }`}
              target="_blank"
              title="Look at the place in Google Maps"
              css={styles.coordinatesLink}
            >
              See on the Map
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAcl(GateCard);
