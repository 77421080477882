/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FunctionComponent, ReactElement, Fragment } from 'react';
import _ from 'lodash';

import * as styles from '../../../../../../../styles/styles';

export interface IProps {
  color: any;
}

jsx;

export const TimelineLinkBullet: FunctionComponent<IProps> = ({ color }): ReactElement<IProps> => {
  return (
    <div css={bulletBox}>
      <div css={bulletBody(color)} />
    </div>
  );
};

export default TimelineLinkBullet;

const bulletBox = css({
  border: `1px solid ${styles.Colors.White}`,
  //border: `1px solid green`,
  borderRadius: '50%',
  width: '8px',
  height: '8px',
  zIndex: 10,
});

const bulletBody = (color: number) =>
  css({
    backgroundColor: styles.Colors.White,
    border: `2px solid ${color}`,
    borderRadius: '50%',
    width: '4px',
    height: '4px',
    zIndex: 10,
  });
