/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import BurgerButton from './BurgerButton';
import _ from 'lodash';
import { compose, withStateHandlers, StateHandlerMap, StateHandler } from 'recompose';

import routes from '../../../../../routes';
import withLogout from '../../../../common/auth/withLogout';
import Nav from './Nav';

import * as styles from './styles';
import { isSiteOrigin } from '../../../../../constants';

interface IProps {}

interface IState {
  isOpen: boolean | null;
}

type CounterStateHandlerProps = StateHandlerMap<IState> & {
  openHandler: StateHandler<IState>;
  closeHandler: StateHandler<IState>;
};

type InnerProps = IState & CounterStateHandlerProps;

export const enhance = compose<InnerProps, {}>(
  withLogout,
  withStateHandlers<IState, CounterStateHandlerProps, {}>(
    { isOpen: null },
    {
      openHandler: (state) => () => {
        state.isOpen = true;
        return state;
      },
      closeHandler: (state) => () => {
        state.isOpen = false;
        return state;
      },
    }
  )
);

jsx;

const topNav = [
  {
    link: routes.Producers,
    title: 'Producers',
    acl: 'producer',
  },
  {
    link: routes.Clients,
    title: 'Clients',
    acl: 'client',
  },
  {
    link: routes.Suppliers,
    title: 'Suppliers',
    acl: 'supplier',
  },
  {
    link: routes.Productions,
    title: 'Productions',
    acl: 'production',
  },
  {
    link: routes.SupplierFunctions,
    title: isSiteOrigin ? 'Supplier functions' : 'Departments',
    acl: 'supplierFunction',
  },
  {
    link: routes.Categories,
    title: 'Machinery categories',
    acl: 'category',
  },
  {
    link: routes.TransportTypes,
    title: 'Transport types',
    acl: 'transportType',
  },

  {
    link: routes.GeneralInfo,
    title: 'General info',
    acl: 'generalInfo',
  },
];

export const MainMenuDisplay: FunctionComponent<InnerProps> = ({
  isOpen,
  openHandler,
  closeHandler,
  logOut,
}: InnerProps): ReactElement<InnerProps> => {
  var menuBoxStyle = _.cond([
    [_.isNull, _.constant(styles.menuBox)],
    [_.identity, _.constant(styles.openMenuBox)],
    [_.stubTrue, _.constant(styles.closeMenuBox)],
  ])(isOpen);

  return (
    <div>
      <BurgerButton type="black" onClick={openHandler} />
      <div css={menuBoxStyle}>
        <div css={styles.menuHeader}>
          <BurgerButton type="white" onClick={closeHandler} />
        </div>
        <nav css={styles.navBox}>
          <Nav items={topNav} onClick={closeHandler} />
        </nav>
      </div>

      <div onClick={closeHandler} css={isOpen ? styles.menuOwerlay : ''} />
    </div>
  );
};

export default enhance(MainMenuDisplay);
