/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, ReactNode } from 'react';

export interface IProps {
  children: ReactNode[];
}

jsx;

export const TabRow: FunctionComponent<IProps> = ({ children }): ReactElement<IProps> => {
  return (
    <div
      css={{
        display: 'flex',
      }}
    >
      {children}
    </div>
  );
};

export default TabRow;
