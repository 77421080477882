/** @jsx jsx */
import { jsx, css, Global } from '@emotion/core';
import { Fragment, FunctionComponent, ReactElement } from 'react';
import ReactModal from 'react-modal';
import { RemoveScroll } from 'react-remove-scroll';
import NoWindowScroll from '../page/NoWindowScroll';

import * as styles from './styles';

interface IProps extends ReactModal.Props {
  isOpen: boolean;
  className?: string;
  shouldPlaceAbove?: boolean;
}

jsx;

export const Modal: FunctionComponent<IProps> = ({
  children,
  isOpen,
  className,
  shouldPlaceAbove = false,
  ...props
}): ReactElement<IProps> => {
  return (
    <Fragment>
      <RemoveScroll enabled={isOpen} noIsolation removeScrollBar={false}>
        <ReactModal
          isOpen={isOpen}
          style={shouldPlaceAbove ? styles.reactModalTopPlaced : styles.reactModal}
          className={className}
          {...props}
        >
          <Fragment>
            <NoWindowScroll />
            {children}
          </Fragment>
        </ReactModal>
        <Global
          styles={css`
            .ReactModal__Overlay {
              background-color: rgba(0, 0, 0, 0.4) !important;
              z-index: 5;
            }
          `}
        />
      </RemoveScroll>
    </Fragment>
  );
};

export default Modal;
