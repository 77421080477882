/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, Children } from 'react';
import { Form as FormicForm } from 'formik';
import * as styles from './styles';
import { Link } from 'react-router-dom';

export interface IPath {
  title: string;
  link: string;
}

export interface IProps {
  path: IPath[];
  pathname?: string;
}

jsx;

export const Breabcrumbs: FunctionComponent<IProps> = ({ path, pathname }): ReactElement<IProps> => {
  return (
    <div css={styles.breadcrumbsBox}>
      {path.map((pathPart) => (
        <div className="breabcrumbs-item" css={styles.breabcrumbsItem} key={pathPart.title}>
          {pathname && pathname === pathPart.link ? <span>{pathPart.title}</span> : <Link replace to={pathPart.link}>{pathPart.title}</Link>}
        </div>
      ))}
    </div>
  );
};

export default Breabcrumbs;
