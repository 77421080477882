import si, { ImmutableObject } from 'seamless-immutable';
import * as ru from '../../../../../utils/redux';

import { UserItemState } from './types';

import {
  PASSWORD_UPDATE_REQUEST,
  PASSWORD_UPDATE_REQUEST_ERROR,
  PASSWORD_UPDATE_REQUEST_SUCCESS,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_REQUEST_ERROR,
  PROFILE_UPDATE_REQUEST_SUCCESS,
  PROFILE_LOAD_REQUEST,
  PROFILE_LOAD_REQUEST_ERROR,
  PROFILE_LOAD_REQUEST_SUCCESS,
} from './actions';
import { User } from '../../../../../store/types';
import * as actions from './actions';
import * as r from '../../../../../utils/reducers';
import { getItemState } from '../../../../../utils/store/state/state';

interface IState {
  profile: UserItemState;

  //deprecated
  profileLoading: boolean;
  userProfile: User | null;
}

const INITIAL_STATE: ImmutableObject<IState> = si({
  profile: getItemState({}),

  profileLoading: false,
  userProfile: null,
});

const itemReducer = r.createReducer('profile');

export default ru.processActions(
  () => ({
    //ITEM GET
    [actions.GET_PROFILE_REQ]: itemReducer(r.loadingReducer),
    [actions.GET_PROFILE_REQ_SUCCESS]: itemReducer(r.successReducer),
    [actions.GET_PROFILE_REQ_ERROR]: itemReducer(r.errorReducer),
    [actions.SET_PROFILE]: itemReducer(r.itemDataReducer),

    //ITEM UPDATE
    [actions.UPDATE_PROFILE_REQ]: itemReducer(r.loadingReducer),
    [actions.UPDATE_PROFILE_REQ_SUCCESS]: itemReducer(r.successReducer),
    [actions.UPDATE_PROFILE_REQ_ERROR]: itemReducer(r.errorReducer),
    [actions.UPDATE_PROFILE]: itemReducer(r.updatedReducer),

    [PROFILE_LOAD_REQUEST]: (store: ImmutableObject<IState>) => {
      return store.set('profileLoading', true);
    },
    [PROFILE_LOAD_REQUEST_SUCCESS]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('userProfile', action.payload[0]).set('profileLoading', false);
    },
  }),
  INITIAL_STATE
);
