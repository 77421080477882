/** @jsx jsx */
import { Fragment, Component } from 'react';
import { jsx, css } from '@emotion/core';

// redux
import { connect, STATUS_REQUEST } from '../../../../utils/redux';
import { transportTypesList } from './store/selectors';
import {
  getTransportTypesReq,
  createTransportTypeReq,
  updateTransportTypeReq,
  deleteTransportTypeReq,
} from './store/actions';

// types
import { Action } from '../../../../store/types';
import { TransportTypeListState, TransportType } from './store/types';

// components
import { Waypoint } from 'react-waypoint';
import ReactModal from 'react-modal';
import DataWaypoin from '../../../common/async/DataWaypoin';
import GRID from '../../../common/grid';
import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import Form from './Form';
import withAcl, { IWrappedProps } from '../withAcl';
import CheckAcl from '../CheckAcl';

// utils
import _ from 'lodash';

interface IProps extends IWrappedProps {
  transportTypesList: TransportTypeListState;
  getTransportTypesReq: Action<{}>;
  createTransportTypeReq: Action<{}>;
  updateTransportTypeReq: Action<{}>;
  deleteTransportTypeReq: Action<{}>;
}

interface IState {
  isOpen: boolean;
  editItem: any;
}

const gridShema = [{ title: 'Name', path: 'name' }];

ReactModal.setAppElement('#root');

jsx;
class TransportTypesList extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      editItem: null,
      isOpen: false,
    };
  }

  componentDidMount(): void {
    this.refreshData();
  }

  refreshData = () => {
    const { getTransportTypesReq, transportTypesList } = this.props;
    getTransportTypesReq({ ...transportTypesList.params, page: 0 });
  };

  onWaypointEnter = () => {
    const { getTransportTypesReq, transportTypesList } = this.props;
    getTransportTypesReq({ ...transportTypesList.params, page: transportTypesList.params.page + 1 });
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };

  closeModal = () => {
    this.setState({ isOpen: false });
    this.clearEditItem();
  };

  delete = ({ id }: TransportType) => {
    this.props.deleteTransportTypeReq(id).then(() => {
      this.refreshData();
    });
  };

  openCreateForm = () => {
    const { test } = this.props;
    if (test('transportType', 'C')) {
      this.openModal();
    }
  };

  clearEditItem = () => {
    this.setState({ editItem: null });
  };

  edit = (item: any) => {
    const { test } = this.props;
    if (test('supplierFunction', 'U')) {
      this.setState({ editItem: item });
      this.openModal();
    }
  };

  onSave = (values: TransportType) => {
    const { editItem } = this.state;
    values = editItem ? { ...editItem, ...values } : values;
    const { createTransportTypeReq, updateTransportTypeReq } = this.props;
    const handler = values.id ? updateTransportTypeReq : createTransportTypeReq;
    handler(values).then(() => {
      this.closeModal();
      this.refreshData();
    });
  };

  render() {
    const { transportTypesList } = this.props;
    const { isOpen, editItem } = this.state;
    const { Actions, DeleteAction, EditAction, ToolBar, Footer, Grid, GridBox } = GRID;

    const data = _.get(transportTypesList, 'data');

    return (
      <Fragment>
        {data && (
          <GridBox css={style.gridBox}>
            <ToolBar
              title="Transport types"
              actionsRight={
                <CheckAcl resources="transportType" actions="C">
                  <PrimaryBtn onClick={this.openCreateForm} title="Create transport type" />
                </CheckAcl>
              }
            />

            <Grid
              onRowDoubleClick={(e: any, item: any) => {
                this.edit(item);
              }}
              shema={gridShema}
              data={data}
              actions={(item) => (
                <Actions>
                  <CheckAcl resources="transportType" actions="U">
                    <EditAction
                      onClick={(e) => {
                        this.edit(item);
                      }}
                    />
                  </CheckAcl>
                  <CheckAcl resources="transportType" actions="D">
                    <DeleteAction
                      onConfirm={(e) => {
                        this.delete(item);
                      }}
                    />
                  </CheckAcl>
                </Actions>
              )}
            />
            <Footer>
              <DataWaypoin data={transportTypesList} onEnter={this.onWaypointEnter} />
              {isOpen && <Form isOpen={true} onSave={this.onSave} onClose={this.closeModal} item={editItem} />}
            </Footer>
          </GridBox>
        )}
      </Fragment>
    );
  }
}

export default connect(
  { transportTypesList },
  {
    getTransportTypesReq,
    createTransportTypeReq,
    updateTransportTypeReq,
    deleteTransportTypeReq,
  }
)(withAcl(TransportTypesList));

const style = {
  gridBox: css`
    margin-bottom: 100px;
  `,
};
