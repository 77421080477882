/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import React, { ReactElement, Component, ReactNode } from 'react';

// types
import { ContentLocationGetter, ContentLocation, Align } from 'react-tiny-popover';

import * as styles from './styles';

import Popover from '../popover/Popover';

export interface IProps {
  btn: ReactElement<any> | ((openPopover: (e: any) => void, isOpen: boolean) => ReactNode);
  onOpen?: () => void;
  onClose?: () => void;
  disableReposition?: boolean;
  autoHeight?: boolean;
  align?: Align;
  tabIndex?: number;
  contentLocation?: ContentLocationGetter | ContentLocation;
  renderContent: (closeDropdown: (e: any) => void) => ReactNode;
  isVisibilityHight?: boolean;
}

export interface IState {
  isOpen: boolean;
  isActive: boolean;
}

jsx;
class PopoverBtn extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isOpen: false,
      isActive: false,
    };
  }

  open = (e: any) => {
    setTimeout(() => {
      this.setState({
        isOpen: true,
      });
      const { onOpen = _.identity } = this.props;
      onOpen();
    }, 0);
  };

  close = (e: any) => {
    this.setState({
      isOpen: false,
    });
    const { onClose = _.identity } = this.props;
    onClose();
  };

  render() {
    const {
      renderContent,
      disableReposition,
      contentLocation,
      align,
      btn,
      autoHeight,
      tabIndex,
      isVisibilityHight = true,
    } = this.props;
    const { isOpen } = this.state;

    const popoverBtn = () => {
      if (typeof btn === 'function') {
        return btn(this.open, isOpen);
      } else {
        return React.cloneElement(btn, {
          onClick: this.open,
        });
      }
    };

    return (
      <div tabIndex={tabIndex}>
        <div>{popoverBtn()}</div>
        <div css={styles.actionListPopoverPosition}>
          <Popover
            isOpen={isOpen}
            disableReposition={disableReposition}
            contentLocation={contentLocation}
            onClickOutside={this.close}
            align={align}
            autoHeight={autoHeight}
            isVisibilityHight={isVisibilityHight}
          >
            <div>{renderContent(this.close)}</div>
          </Popover>
        </div>
      </div>
    );
  }
}

export default PopoverBtn;
