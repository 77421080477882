/** @jsx jsx */
import { css } from '@emotion/core';
import _ from 'lodash';

import * as styles from '../../../styles/styles';

export const container = {
  overflow: 'visible',
  zIndex: '6',
};
export const containerLowVisibility = {
  overflow: 'visible',
  zIndex: '4',
};

export const arrow = {};

export const contentBox = (width: number | string, autoHeight?: boolean) => {
  return css({
    backgroundColor: styles.Colors.White,
    border: 0,
    minWidth: _.isNumber(width) ? `${width}px` : width,
    borderRadius: '2px',
    boxShadow: '2px 2px 7px rgba(150, 150, 150, 0.50)',
    zIndex: 99,
    maxHeight: autoHeight ? 'auto' : '420px',
    overflow: 'auto',
  });
};

export const rowBox = [
  css({
    width: '100%',
    '& :hover': {
      backgroundColor: styles.Colors.HoveredWhite,
      cursor: 'pointer',
    },
  }),
];

export const row = css({
  padding: '10px 20px',
  boxSizing: 'border-box',
});

export const actionListPopoverPosition = css({
  height: '0px',
  width: '0px',
  overflow: 'visible',
  margin: '0 auto',
});

export const xxx = css({});
