/** @jsx jsx */
import { Fragment, Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { jsx, css } from '@emotion/core';
import { Redirect } from 'react-router';

// types
import { MachineriesListState } from '../Machinery/store/types';
import { IProduction } from '../../store/types';
import { SupplierListState } from '../../../Suppliers/store/types';
import { CategoriesListState } from '../../../Categories/store/types';
// components
import List from '../Machinery/List';
import ListHeader from '../Machinery/ListHeader';

// utils
import * as utils from '../utils';

interface IProps {
  production: IProduction;
  suppliersForSelect: SupplierListState;
  allCategoriesList: CategoriesListState;
  machineries: MachineriesListState;
}

interface IState {
  selectDay: any;
  isEditMode: boolean;
  editItemId: any;
}

jsx;
class MachineryBlock extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectDay: null,
      isEditMode: false,
      editItemId: null,
    };
  }

  render() {
    const { production, suppliersForSelect, allCategoriesList, machineries } = this.props;
    const { selectDay, isEditMode, editItemId } = this.state;
    {
      const rangeRepresentation = utils.getRangeOfWorkDays(production.loadIn.from, production.loadOut.to, 'day');

      return (
        <Fragment>
          <ListHeader
            day={selectDay}
            days={rangeRepresentation}
            onBack={() => {
              this.setState({ selectDay: null });
            }}
            onSelectDay={(selectDay: any) => {
              this.setState({ selectDay });
              this.setState({ editItemId: null });
            }}
          />
          <List
            onSelectDay={(selectDay: any) => {
              this.setState({ selectDay });
            }}
            day={selectDay}
            days={rangeRepresentation}
            production={production}
            isEditMode={isEditMode}
            suppliersForSelect={suppliersForSelect}
            allCategoriesList={allCategoriesList}
            machineries={Object.values(machineries.data)}
            editItemId={editItemId}
          />
        </Fragment>
      );
    }
  }
}

export default MachineryBlock;

const style = {};
