/** @jsx jsx */
import { jsx } from '@emotion/core';
import { compose, lifecycle } from 'recompose';
import { RouteComponentProps } from 'react-router';

import { connect } from '../../../../utils/redux';

import { getSubproductionReq, clearSubproduction } from './store/actions';
import { Action } from '../../../../store/types';
import { subproduction } from './store/selectors';

import EditSubForm from './EditSubForm';

import * as styles from './styles';
import { IProduction } from './store/types';

interface FormValues extends Partial<IProduction> {}

interface MatchParams {
  production_id?: string;
  subproduction_id?: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  getSubproductionReq: Action<{}>;
  clearSubproduction: Action<{}>;
  subproduction: IProduction;
}

jsx;

const withLifecycle = lifecycle<IProps, {}>({
  componentDidMount() {
    const { production_id, subproduction_id } = this.props.match.params;
    const { getSubproductionReq, clearSubproduction } = this.props;
    clearSubproduction();
    getSubproductionReq(subproduction_id);
  },
});

const Edit = (props: IProps) => {
  const { subproduction } = props;

  return <div css={styles.formBox}>{subproduction ? <EditSubForm {...props} /> : <div>Loading...</div>}</div>;
};

interface FormProps {
  initialEmail?: string;
}

export default compose<IProps, {}>(
  connect(
    { subproduction },
    { getSubproductionReq, clearSubproduction }
  ),
  withLifecycle
)(Edit);
