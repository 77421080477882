/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { withFormik, Form, FormikProps, FieldArray } from 'formik';
// redux
import { compose } from 'recompose';
// types
import { IProduction } from '../store/types';
import { Action } from '../../../../../store/types';
// components
import modal from '../../../../common/modal';
import PrimaryBtn from '../../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../../common/buttons/OutlineBtn';
import DatePicker from 'react-multi-date-picker';
// import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import 'react-multi-date-picker/styles/colors/green.css';
import SelectedBtn from '../../../../common/buttons/ForwardedSelectedItem';
import FormRow from '../../../../common/form/FormRow';
import FormError from '../../../../common/form/FormError';
import removeIcon from '../../../../../images/icons/remove.svg';
import plusIcon from '../../../../../images/icons/plus.svg';
import dateIcon from '../../../../../images/icons/date.svg';
import { Colors } from '../../../../../styles/styles';
// utils
import moment from 'moment';
import * as utils from './utils';

const { Modal, ModalHeader, ModalFooter, ModalBody, ModalActions } = modal;

interface FormValues {
  dates: number[];
}

interface OtherProps {
  isOpen: boolean;
  onClose: () => void;
  production: IProduction;
  activities: Number[];
  activitiesClone: Action<{}>;
}

jsx;
const ActivitiesCloneModal = ({
  isOpen,
  onClose,
  production,
  setFieldValue,
  activities,
  resetForm,
  touched,
  errors,
  values,
}: OtherProps & FormikProps<FormValues>) => {
  const onCancelClick = () => {
    resetForm();
    onClose();
  };
  const rangeRepresentation = utils.getRangeOfWorkDays(production.loadIn.from, production.loadOut.to, 'day');
  const dates = rangeRepresentation.map((day: any) => moment.utc(day.from).valueOf());

  return (
    <div>
      <Modal isOpen={isOpen}>
        <Form css={style.modal}>
          <ModalHeader>{`Clone activities (${activities.length})`}</ModalHeader>
          <ModalBody overflowShown="visible">
            <div css={style.modalBody}>
              <FormRow css={style.dateBox}>
                <span>Date</span>
                <DatePicker
                  value={values.dates}
                  onChange={(values) => {
                    if (Array.isArray(values)) {
                      const newDates = values.map((value) => value.valueOf());
                      setFieldValue('dates', newDates);
                    }
                  }}
                  multiple
                  format="MM/DD/YYYY"
                  className="green"
                  type="icon"
                  minDate={moment.utc(utils.getStartRangeWorkDays(production.loadIn.from, 'day')).valueOf()}
                  maxDate={moment.utc(rangeRepresentation[rangeRepresentation.length - 1].from).valueOf()}
                  plugins={[
                    <DatePanel />,
                    // <TimePicker position="bottom" />
                  ]}
                />
                <FormError name="dates" errors={errors} touched={touched} />
              </FormRow>
              <label css={style.label}>
                <input
                  type="checkbox"
                  name="allDaysChecked"
                  css={style.checkbox}
                  onChange={(value) => {
                    setFieldValue('dates', value.currentTarget.checked ? dates : []);
                  }}
                />
                Select all days
              </label>
              <div css={style.dates}>
                <FieldArray
                  name="dates"
                  render={(arrayHelpers) => (
                    <div>
                      {values.dates &&
                        values.dates.length > 0 &&
                        values.dates.map((item, index) => (
                          <SelectedBtn
                            key={item}
                            title={String(moment.utc(item).format('MMMM DD'))}
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        ))}
                    </div>
                  )}
                />
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <ModalActions css={style.modalActions}>
              <OutlineBtn type="button" onClick={onCancelClick} title="Cancel" />
              <PrimaryBtn type="submit" title="Update" style={{ marginRight: 0 }} />
            </ModalActions>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default compose<OtherProps & FormikProps<FormValues>, OtherProps>(
  withFormik<OtherProps, FormValues>({
    mapPropsToValues: () => ({
      dates: [],
    }),
    handleSubmit: ({ dates }, { props, resetForm }) => {
      const { activitiesClone, production, activities, onClose } = props;
      activitiesClone({
        ownerId: production.id,
        data: {
          ids: activities,
          data: {
            from: dates,
          },
        },
      });
      onClose();
      resetForm();
    },
    displayName: 'ActivitiesCloneModal',
  })
)(ActivitiesCloneModal);

const style = {
  modal: css`
    width: 500px;
  `,
  modalActions: css`
    text-align: right;
  `,
  modalBody: css`
    padding: 10px 20px 30px;
  `,
  dateBox: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 15px;
  `,
  datePicker: css`
    width: calc(100% - 50px);
  `,
  datapickerBox: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 15px;
  `,
  plusIcon: css`
    background: no-repeat url(${plusIcon}) center;
    display: inline-block;
    width: 16px;
    height: 16px;
    opacity: 0.8;
    border: none;
  `,
  removeBtn: css`
    background: no-repeat url(${removeIcon}) center;
    display: inline-block;
    width: 16px;
    height: 16px;
    opacity: 0.8;
    border: none;
    margin-right: 9px;
    margin-left: 9px;
  `,
  addDateBtn: css`
    border: none;
    color: ${Colors.AltGreen};
    font-size: 13px;
    font-weight: 400;
    font-family: Poppins;
    background-color: transparent;
    cursor: pointer;
    margin-top: 15px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      right: -24px;
      top: 0;
      bottom: 0;
      width: 36px;
      background-image: url(${dateIcon});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 15px;
    }
  `,
  dates: css`
    overflow-y: scroll;
    max-height: 64vh;
  `,
  checkbox: css`
    margin-right: 7px;
    &:focus {
      outlinecolor: ${Colors.LightGreenGrey};
      webkitboxshadow: 0 0 0px 1000px ${Colors.LightGreenGrey} inset;
    }
  `,
  label: css`
    position: absolute;
    top: 22px;
    right: 60px;
    font-size: 13px;
    font-weight: 400;
    font-family: Poppins;
    display: flex;
  `,
};
