/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, Children, Component } from 'react';

import * as styles from './styles';
import noImage from './images/noImage.png';

export interface IProps {
  file: any;
  defaultImage?: any;
  alt?: string;
}

export interface IState {}
jsx;
class Thumb extends Component<IProps, IState> {
  render() {
    const { file, defaultImage = noImage, alt = 'Image' } = this.props;

    const src = file ? file : defaultImage;
    return <div css={styles.thumbImg} style={{ backgroundImage: `url(${src})` }} />;
  }
}

export default Thumb;
