/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import * as styles from '../../../../styles/styles';
import * as formStyles from '../styles';

export const datapickerBox = [
  formStyles.formFieldBox,
  css({
    '& .rdtPicker': {
      boxShadow: '2px 2px 7px rgba(150, 150, 150, 0.25)',
      opacity: 1,
      visibility: 'visible',
    },
    '& td': styles.MainText,
    '& td.rdtActive': {
      backgroundColor: styles.Colors.Green,
      '&:hover': {
        backgroundColor: styles.Colors.Green,
      },
    },
    '& th': styles.H3,
    '& th.rdtSwitch': styles.H2,
  }),
];
export const datapickerStaticBox = [
  formStyles.formFieldBox,
  css({
    '& .rdtPicker': {
      boxShadow: '2px 2px 7px rgba(150, 150, 150, 0.25)',
      position: 'static',
    },
    '& td': styles.MainText,
    '& td.rdtActive': {
      backgroundColor: styles.Colors.Green,
      '&:hover': {
        backgroundColor: styles.Colors.Green,
      },
    },
    '& th': styles.H3,
    '& th.rdtSwitch': styles.H2,
  }),
];
