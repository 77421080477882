import si, { ImmutableObject } from 'seamless-immutable';
import _ from 'lodash';
import * as config from '../../../../../../config';
import * as ru from '../../../../../../utils/redux';
import * as r from '../../../../../../utils/reducers';

import { getListState } from '../../../../../../utils/store/state/state';

import { ActivitiesListState, VersionsListState } from './types';

import * as actions from './actions';

interface IState {
  allItemsList: ActivitiesListState;
  versionList: VersionsListState;
}

const INITIAL_STATE: ImmutableObject<any> = si({
  versionList: getListState({
    size: config.UNLIMITED_LIST_SIZE,
  }),

  allItemsList: getListState(
    {
      ownerId: null,
      size: config.UNLIMITED_LIST_SIZE,
    },
    { selectedIds: [] }
  ),

  allTransportsList: getListState({
    ownerId: null,
    size: config.UNLIMITED_LIST_SIZE,
  }),
});

const allItemsListReducer = r.createReducer('allItemsList');
const allTransportsListReducer = r.createReducer('allTransportsList');
const versionListReducer = r.createReducer('versionList');

export default ru.processActions(
  () => ({
    //VERSIONS LIST
    [actions.GET_VERSION_LIST_REQ]: versionListReducer(r.loadingReducer),
    [actions.GET_VERSION_LIST_REQ_SUCCESS]: versionListReducer(r.successReducer),
    [actions.GET_VERSION_LIST_REQ_ERROR]: versionListReducer(r.errorReducer),
    [actions.SET_VERSION_LIST]: versionListReducer(r.listDataReducer),

    [actions.CREATE_VERSION_REQ]: versionListReducer(r.loadingReducer),
    [actions.CREATE_VERSION_REQ_SUCCESS]: versionListReducer(r.successReducer),
    [actions.CREATE_VERSION_REQ_ERROR]: versionListReducer(r.errorReducer),
    [actions.ADD_VERSION]: versionListReducer(r.addDataReducer('id')),

    //ALL TRANSPORTS LIST
    [actions.GET_ALL_TRANSPORTS_REQ]: allTransportsListReducer(r.loadingReducer),
    [actions.GET_ALL_TRANSPORTS_REQ_SUCCESS]: allTransportsListReducer(r.successReducer),
    [actions.GET_ALL_TRANSPORTS_REQ_SUCCESS]: allTransportsListReducer(r.errorReducer),
    [actions.SET_ALL_TRANSPORTS]: allTransportsListReducer(r.listDataReducer),
    [actions.REFRESH_ALL_TRANSPORTS]: allTransportsListReducer(r.refreshReducer),
    [actions.SET_ALL_TRANSPORTS_PARAMS]: allTransportsListReducer(r.paramsReducer),
    [actions.RESET_ALL_TRANSPORTS_PARAMS]: (store: ImmutableObject<IState>, action: any) => {
      let params: any = {};

      Object.keys(store.allItemsList.params).forEach((paramKey: any) => {
        if (paramKey.indexOf('filter') === -1) params[paramKey] = store.allItemsList.params[paramKey];
      });

      return store.setIn(['allTransportsList', 'params'], params);
    },

    //ALL ITEMS LIST
    [actions.GET_ALL_ACTIVITIES_REQ]: allItemsListReducer(r.loadingReducer),
    [actions.GET_ALL_ACTIVITIES_REQ_SUCCESS]: allItemsListReducer(r.successReducer),
    [actions.GET_ALL_ACTIVITIES_REQ_ERROR]: allItemsListReducer(r.errorReducer),
    [actions.SET_ALL_ACTIVITIES]: allItemsListReducer(r.listDataReducer),
    [actions.REFRESH_ALL_ACTIVITIES]: allItemsListReducer(r.refreshReducer),
    [actions.SET_ALL_ACTIVITIES_PARAMS]: allItemsListReducer(r.paramsReducer),
    [actions.RESET_ALL_ACTIVITIES_PARAMS]: (store: ImmutableObject<IState>, action: any) => {
      let params: any = {};

      Object.keys(store.allItemsList.params).forEach((paramKey: any) => {
        if (paramKey.indexOf('filter') === -1) params[paramKey] = store.allItemsList.params[paramKey];
      });

      return store.setIn(['allItemsList', 'params'], params);
    },

    [actions.CREATE_TRANSPORT_REQ]: allItemsListReducer(r.loadingReducer),
    [actions.CREATE_TRANSPORT_REQ_SUCCESS]: allItemsListReducer(r.successReducer),
    [actions.CREATE_TRANSPORT_REQ_ERROR]: allItemsListReducer(r.errorReducer),

    [actions.UPDATE_TRANSPORT_REQ]: allItemsListReducer(r.loadingReducer),
    [actions.UPDATE_TRANSPORT_REQ_SUCCESS]: allItemsListReducer(r.successReducer),
    [actions.UPDATE_ACTIVITY_REQ_ERROR]: allItemsListReducer(r.errorReducer),

    [actions.ADD_ITEM_OF_ALL_ACTIVITIES]: allItemsListReducer(r.addDataReducer('id')),
    [actions.UPDATE_ITEM_OF_ALL_ACTIVITIES]: allItemsListReducer(r.updateDataReducer('id')),
    [actions.DELETE_ACTIVITY]: allItemsListReducer(r.deleteDataReducer('id')),

    [actions.CREATE_ACTIVITY_REQ]: allItemsListReducer(r.loadingReducer),
    [actions.CREATE_ACTIVITY_REQ_SUCCESS]: allItemsListReducer(r.successReducer),
    [actions.CREATE_ACTIVITY_REQ_ERROR]: allItemsListReducer(r.errorReducer),

    [actions.UPDATE_ACTIVITY_REQ]: allItemsListReducer(r.loadingReducer),
    [actions.LINK_ACTIVITY_REQ]: allItemsListReducer(r.loadingReducer),
    [actions.UNLINK_ACTIVITY_REQ]: allItemsListReducer(r.loadingReducer),
    [actions.UPDATE_ACTIVITY_RANGE_REQ]: allItemsListReducer(r.loadingReducer),
    [actions.UPDATE_ACTIVITY_REQ_SUCCESS]: allItemsListReducer(r.successReducer),
    [actions.UPDATE_ACTIVITY_REQ_ERROR]: allItemsListReducer(r.errorReducer),

    [actions.DELETE_ACTIVITY_REQ]: allItemsListReducer(r.loadingReducer),
    [actions.DELETE_ACTIVITY_REQ_SUCCESS]: allItemsListReducer(r.successReducer),
    [actions.DELETE_ACTIVITY_REQ_ERROR]: allItemsListReducer(r.errorReducer),

    [actions.SELECT_ACTIVITY]: (store: ImmutableObject<IState>, action: any) => {
      const id = Number(action.payload);
      const selectedIds = store.getIn(['allItemsList', 'selectedIds']);
      return store.setIn(
        ['allItemsList', 'selectedIds'],
        selectedIds.includes(id) ? _.without(selectedIds, id) : [...selectedIds, id]
      );
    },
    [actions.SELECT_ALL_ACTIVITIES]: (store: ImmutableObject<IState>, action: any) => {
      return store.setIn(['allItemsList', 'selectedIds'], action.payload);
    },
    [actions.CLEAR_SELECTED_ACTIVITIES]: (store: ImmutableObject<IState>, action: any) => {
      return store.setIn(['allItemsList', 'selectedIds'], []);
    },
    [actions.SELECT_DAY_ACTIVITIES]: (store: ImmutableObject<IState>, action: any) => {
      const ids = action.payload;
      const selectedIds = store.getIn(['allItemsList', 'selectedIds']);
      const newSet = new Set([...selectedIds, ...ids]);

      return store.setIn(['allItemsList', 'selectedIds'], Array.from(newSet));
    },

    // DELETE ACTIVITIES
    [actions.DELETE_ACTIVITIES_REQ]: allItemsListReducer(r.loadingReducer),
    [actions.DELETE_ACTIVITIES_REQ_SUCCESS]: allItemsListReducer(r.successReducer),
    [actions.DELETE_ACTIVITIES_REQ_ERROR]: allItemsListReducer(r.errorReducer),
    [actions.DELETE_ACTIVITIES]: (store: ImmutableObject<IState>, action: any) => {
      const ids: number[] = action.payload;
      return store.updateIn(['allItemsList', 'data'], (list) =>
        list.filter(({ id }: { id: number }) => !ids.includes(id))
      );
    },
    [actions.UNSELECT_FROM_DAY_ACTIVITIES]: (store: ImmutableObject<IState>, action: any) => {
      const ids: number[] = action.payload;
      return store.updateIn(['allItemsList', 'selectedIds'], (list) =>
      list.filter((activityId: number ) => !ids.includes(activityId))
      );
    },

    [actions.SHIFT_ACTIVITIES_REQ]: allItemsListReducer(r.loadingReducer),
  }),
  INITIAL_STATE
);
