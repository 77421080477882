import React from 'react';
import ReactDOM from 'react-dom';
// redux
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { connectRouter, routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory, History } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
// utils
import reducerRegistry from './utils/reducersRegister';
import sagasRegistry from './utils/sagasRegister';
import { asyncMidleware } from './utils/async/midleware';
// other
import * as serviceWorker from './serviceWorker';
// component
import App from './App';
import './index.css';

export const history = createBrowserHistory();

const registeredReducers = reducerRegistry.getReducers();
const createRootReducer = (history: History) =>
  combineReducers({
    ...registeredReducers,
    router: connectRouter(history),
  });

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  createRootReducer(history),
  composeWithDevTools(applyMiddleware(asyncMidleware, sagaMiddleware, routerMiddleware(history)))
);

sagaMiddleware.run(sagasRegistry.getSagas());

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA!!!
serviceWorker.unregister();
