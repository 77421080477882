/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FunctionComponent } from 'react';
// components
import PopoverBtn from '../../../../common/popover/PopoverBtn';
import DropdownMenu from '../../../../common/popover/DropdownMenu';
// actions
import { connect } from '../../../../../utils/redux';
import { logout } from '../../../../../store/actions';
import { clearToken } from '../../../../../utils/auth';
import { Action } from '../../../../../store/types';
// other
import routes from '../../../../../routes';
import userLogo from './images/userDefaultLogo.svg';

type IProps = {
  logout: Action<{}>;
};

jsx;
const UserDropdown: FunctionComponent<IProps> = (props: IProps) => {
  const userImg = <img css={styles.userImg} src={userLogo} alt="User" />;
  const onLogout = () => {
    props.logout();
    clearToken();
  };

  return (
    <PopoverBtn
      btn={userImg}
      disableReposition
      align='end'
      renderContent={(closeDropdown) => (
        <DropdownMenu
          closeDropdown={closeDropdown}
          actions={[
            {
              link: routes.ChangePassword,
              label: 'Change password',
            },
            {
              link: routes.UpdateProfile,
              label: 'Edit profile',
            },
            {
              handler: onLogout,
              label: 'Logout',
            },
          ]}
        />
      )}
    />
  );
};

const enhance = connect(
  null,
  { logout }
);

export default enhance(UserDropdown);

const styles = {
  userImg: css`
    cursor: pointer;
    height: 20px;
    padding: 15px;
  `,
};
