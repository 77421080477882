import { createAction } from 'redux-actions';

import reducersRegister from '../../../../../utils/reducersRegister';
import sagasRegister from '../../../../../utils/sagasRegister';
import { Action } from '../../../../../store/types';
import { createAsyncAction, createPromisifyAsyncAction } from '../../../../../utils/async/actions';

import redusers from './reducers';
import sagas from './sagas';

reducersRegister.register('producers', redusers);
sagasRegister.register(sagas);

/******************************************************************************/
/******************************* PRODUCERS ************************************/
/******************************************************************************/

export const GET_PRODUCERS_REQ: string = 'GET_PRODUCERS_REQ';
export const GET_PRODUCERS_REQ_SUCCESS: string = 'GET_PRODUCERS_REQ_SUCCESS';
export const GET_PRODUCERS_REQ_ERROR: string = 'GET_PRODUCERS_REQ_ERROR';

export const SET_PRODUCERS: string = 'SET_PRODUCERS';
export const SET_PRODUCERS_PAGE: string = 'SET_PRODUCERS_PAGE';

export const getProducersReq: Action<{}> = createAsyncAction(GET_PRODUCERS_REQ);
export const getProducersReqSuccess: Action<{}> = createAction(
  GET_PRODUCERS_REQ_SUCCESS,
  (...args) => args[0],
  (...args) => args[1]
);
export const getProducersReqError: Action<{}> = createAction(GET_PRODUCERS_REQ_ERROR);

export const setProducers: Action<{}> = createAction(SET_PRODUCERS);
export const setProducersPage: Action<{}> = createAction(SET_PRODUCERS_PAGE);

/******************************************************************************/
/******************************* GET ONE PRODUCER *****************************/
/******************************************************************************/

export const GET_PRODUCER_REQ: string = 'GET_PRODUCER_REQ';
export const GET_PRODUCER_REQ_SUCCESS: string = 'GET_PRODUCER_REQ_SUCCESS';
export const GET_PRODUCER_REQ_ERROR: string = 'GET_PRODUCER_REQ_ERROR';
export const SET_PRODUCER: string = 'SET_PRODUCER';
export const CLEAR_PRODUCER: string = 'CLEAR_PRODUCER';

export const getProducerReq: Action<{}> = createAsyncAction(GET_PRODUCER_REQ);
export const getProducerReqSuccess: Action<{}> = createAction(GET_PRODUCER_REQ_SUCCESS);
export const getProducerReqError: Action<{}> = createAction(GET_PRODUCER_REQ_ERROR);
export const setProducer: Action<{}> = createAction(SET_PRODUCER);
export const clearProducer: Action<{}> = createAction(CLEAR_PRODUCER);

/******************************************************************************/
/******************************* UPDATE ONE PRODUCER **************************/
/******************************************************************************/

export const UPDATE_PRODUCER_REQ: string = 'UPDATE_PRODUCER_REQ';
export const UPDATE_PRODUCER_REQ_SUCCESS: string = 'UPDATE_PRODUCER_REQ_SUCCESS';
export const UPDATE_PRODUCER_REQ_ERROR: string = 'UPDATE_PRODUCER_REQ_ERROR';
export const UPDATE_PRODUCER: string = 'UPDATE_PRODUCER';

export const updateProducerReq: Action<{}> = createAsyncAction(UPDATE_PRODUCER_REQ);
export const updateProducerReqSuccess: Action<{}> = createAction(UPDATE_PRODUCER_REQ_SUCCESS);
export const updateProducerReqError: Action<{}> = createAction(UPDATE_PRODUCER_REQ_ERROR);
export const updateProducer: Action<{}> = createAction(UPDATE_PRODUCER);

/******************************************************************************/
/******************************* DELETE ONE PRODUCER **************************/
/******************************************************************************/

export const DELETE_PRODUCER_REQ: string = 'DELETE_PRODUCER_REQ';
export const DELETE_PRODUCER_REQ_SUCCESS: string = 'DELETE_PRODUCER_REQ_SUCCESS';
export const DELETE_PRODUCER_REQ_ERROR: string = 'DELETE_PRODUCER_REQ_ERROR';
export const DELETE_PRODUCER: string = 'DELETE_PRODUCER';

export const deleteProducerReq: Action<{}> = createPromisifyAsyncAction(DELETE_PRODUCER_REQ);
export const deleteProducerReqSuccess: Action<{}> = createAction(DELETE_PRODUCER_REQ_SUCCESS);
export const deleteProducerReqError: Action<{}> = createAction(DELETE_PRODUCER_REQ_ERROR);
export const deleteProducer: Action<{}> = createAction(DELETE_PRODUCER);

/******************************************************************************/
/******************************* OTHER ****************************************/
/******************************************************************************/

export const CREATE_PRODUCERS_REQ: string = 'CREATE_PRODUCERS_REQ';
export const createProducersReq: Action<{}> = createAsyncAction(CREATE_PRODUCERS_REQ);
export const CREATE_PRODUCERS_REQ_IN_TAB: string = 'CREATE_PRODUCERS_REQ_IN_TAB';
export const createProducersReqInTab: Action<{}> = createAsyncAction(CREATE_PRODUCERS_REQ_IN_TAB);
export const ADD_PRODUCER_TO_LIST: string = 'ADD_PRODUCER_TO_LIST';
export const addProducerToList: Action<{}> = createAsyncAction(ADD_PRODUCER_TO_LIST);
