import si, { ImmutableObject } from 'seamless-immutable';
import _ from 'lodash';
import * as ru from '../../../../../utils/redux';
import * as r from '../../../../../utils/reducers';
import { getListState, getItemState, ListStatuses } from '../../../../../utils/store/state/state';
import { IProduction, ProductionListState, ProductionPrintState, ProductionPrintInfoState } from './types';
import * as actions from './actions';

interface IState {
  allItems: ProductionListState;
  items: ProductionListState;

  // ITEM
  item: IProduction | null;
  itemLoading: boolean;
  itemError: string | null;

  subitem: IProduction | null;
  subitemLoading: boolean;
  subitemError: string | null;

  print: ProductionPrintState;
  printInfo: ProductionPrintInfoState;
}

const INITIAL_STATE: ImmutableObject<IState> = si({
  allItems: getListState({
    page: 0,
    size: 1000,
    archive: false,
  }),

  // LIST
  items: getListState({
    'search.production.name': '',
    'search.production.location': '',
    page: 0,
    size: 20,
    archive: false,
    filterKey: 'producer.id',
  }),

  // ITEM
  item: null,
  itemLoading: false,
  itemError: null,

  // SUB ITEM
  subitem: null,
  subitemLoading: false,
  subitemError: null,

  print: getItemState({}),
  printInfo: getItemState({ ownerId: null }),
});

const itemsReducer = r.createReducer('items');
const allItemsReducer = r.createReducer('allItems');
const printReducer = r.createReducer('print');
const printInfoReducer = r.createReducer('printInfo');

export default ru.processActions(
  () => ({
    [actions.CREATE_PRODUCTION_REQ]: (store: ImmutableObject<IState>, action: any) => {
      return store.setIn(['itemLoading'], true).setIn(['itemError'], null);
    },
    [actions.CREATE_PRODUCTION_REQ_SUCCESS]: (store: ImmutableObject<IState>, action: any) => {
      return store.setIn(['itemLoading'], false).setIn(['itemError'], null);
    },
    [actions.CREATE_PRODUCTION_REQ_ERROR]: (store: ImmutableObject<IState>, action: any) => {
      return store.setIn(['itemLoading'], false).setIn(['itemError'], action.payload);
    },

    [actions.GET_PRODUCTIONS_REQ]: itemsReducer(r.loadingReducer),
    [actions.GET_PRODUCTIONS_REQ_SUCCESS]: (store: ImmutableObject<IState>, action: any) => {
      const {
        payload: { data, params },
        meta,
      } = action;
      const page = params.page || 0;

      return store
        .setIn(['items', 'data'], page > 0 ? store.items.data.concat(data) : data)
        .setIn(['items', 'status'], ListStatuses.Actual)
        .setIn(['items', 'params'], params)
        .setIn(['items', 'meta'], meta);
    },
    [actions.GET_PRODUCTIONS_REQ_ERROR]: itemsReducer(r.errorReducer),

    [actions.SET_PRODUCTIONS]: itemsReducer(r.listDataReducer),
    [actions.REFRESH_PRODUCTIONS]: itemsReducer(r.refreshReducer),
    [actions.SET_PRODUCTIONS_PARAMS]: itemsReducer(r.paramsReducer),

    [actions.GET_ALL_PRODUCTIONS_REQ]: allItemsReducer(r.loadingReducer),
    [actions.GET_ALL_PRODUCTIONS_REQ_SUCCESS]: allItemsReducer(r.successReducer),
    [actions.GET_ALL_PRODUCTIONS_REQ_ERROR]: allItemsReducer(r.errorReducer),
    [actions.SET_ALL_PRODUCTIONS]: allItemsReducer(r.listDataReducer),

    [actions.GET_PRODUCTION_PRINT_REQ]: printReducer(r.loadingReducer),
    [actions.GET_PRODUCTION_PRINT_REQ_SUCCESS]: printReducer(r.successReducer),
    [actions.GET_PRODUCTION_PRINT_REQ_ERROR]: printReducer(r.errorReducer),
    [actions.SET_PRODUCTION_PRINT]: printReducer(r.itemDataReducer),

    [actions.GET_PRODUCTION_PRINT_INFO_REQ]: printInfoReducer(r.loadingReducer),
    [actions.GET_PRODUCTION_PRINT_INFO_REQ_SUCCESS]: printInfoReducer(r.successReducer),
    [actions.GET_PRODUCTION_PRINT_INFO_REQ_ERROR]: printInfoReducer(r.errorReducer),
    [actions.SET_PRODUCTION_PRINT_INFO]: printInfoReducer(r.itemDataReducer),
    [actions.SET_PRODUCTION_PRINT_INFO_PARAMS]: printInfoReducer(r.paramsReducer),

    // DELETE PRODUCTION
    [actions.DELETE_PRODUCTION_REQ_SUCCESS]: (store: ImmutableObject<IState>, { payload: { id } }: any) => {
      return store.setIn(
        ['items', 'data'],
        store.items.data.filter((production) => {
          return !(production.id === id || _.get(production, 'main.id') === id);
        })
      );
    },

    // DEPRECATED
    [actions.SET_PRODUCTION]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('item', _.head(action.payload));
    },

    [actions.CLEAR_PRODUCTION]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('item', null);
    },

    [actions.UPDATE_PRODUCTION]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('item', _.head(action.payload));
    },

    [actions.SET_SUBPRODUCTION]: (store: ImmutableObject<IState>, action: any) => {
      const subproduction = _.head(action.payload);
      return store.set('subitem', subproduction);
    },

    [actions.CLEAR_SUBPRODUCTION]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('subitem', null);
    },
  }),
  INITIAL_STATE
);
