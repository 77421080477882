/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core';
// redux
import { connect } from '../../../../utils/redux';
import { compose } from 'recompose';
import { getProducersReq, deleteProducerReq, setProducersPage } from './store/actions';
import { Action } from '../../../../store/types';
import { producers, producersPage, producersTotal, producersLoading, producersSearch } from './store/selectors';
import { IProducer } from './store/types';
// components
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import Loader from '../../../common/loader/Loader';
import GRID from '../../../common/grid';
const { Actions, DeleteAction, EditAction, ToolBar, Footer, Grid, GridBox } = GRID;
import GRIDToolbarActions from '../../../common/grid/toolbarActions';
// utils
import _ from 'lodash';
import moment from 'moment';
import { push } from 'connected-react-router';
// config
import routes from '../../../../routes';
import { getDateTimeFormat } from '../../../../utils/timeFormatter';
import CheckAcl from '../CheckAcl';
import withAcl, { IWrappedProps } from '../withAcl';

interface IProps extends IWrappedProps {
  producers: IProducer[];
  getProducersReq: Action<{}>;
  deleteProducerReq: Action<{}>;
  push: Action<{}>;
  producersPage: number;
  producersSearch: string;
  producersOffset: number;
  producersTotal: number;
  setProducersPage: Action<{}>;
  producersLoading: boolean;
}

interface IState {}

const boolSerializer = (value: boolean) => {
  return value ? 'yes' : 'no';
};

const dateTimeSerizlizer = (value: string) => {
  return value
    ? moment
        .utc(value)
        .local()
        .format(getDateTimeFormat())
    : '-';
};

const gridShema = [
  {
    title: 'Name',
    path: 'name',
  },
  {
    title: 'Email',
    path: 'email',
  },
  {
    title: 'Phone',
    path: 'phone',
  },
  {
    title: 'Last logged in',
    path: 'lastLoggedAt',
    serializer: dateTimeSerizlizer,
  },
  {
    title: 'Freelancer',
    path: 'isFreelancer',
    serializer: boolSerializer,
  },
  {
    title: 'Active',
    path: 'isActive',
    serializer: boolSerializer,
  },
];

jsx;
export class ProducersList extends Component<IProps, IState> {
  componentDidMount() {
    this.initFetch();
  }

  initFetch = () => {
    const { getProducersReq, producersSearch } = this.props;
    getProducersReq({ page: 0, size: 20, search: producersSearch, searchKey: 'name' });
  };

  fetchMoreProducers = () => {
    const { getProducersReq, producersPage, producersSearch } = this.props;
    getProducersReq({ page: producersPage + 1, size: 20, search: producersSearch, searchKey: 'name' });
  };

  onWaypointEnter = () => {
    const { producersLoading } = this.props;
    if (producersLoading) return;
    this.fetchMoreProducers();
  };

  onSearchChange = (value: string) => {
    const { getProducersReq, producersPage } = this.props;
    getProducersReq({ page: 0, size: 20, search: value, searchKey: 'name' });
  };

  render() {
    const { producers, deleteProducerReq, producersLoading, producersTotal, push, test } = this.props;
    const hasMoreProducers = producersTotal > producers.length;
    const { Search } = GRIDToolbarActions;

    return (
      <GridBox css={style.gridBox}>
        <ToolBar
          title="Producers"
          actionsLeft={<Search title={'Search by name'} onChange={this.onSearchChange} />}
          actionsRight={
            <CheckAcl resources="producer" actions="C">
              <Link to={routes.ProducersCreate}>
                <PrimaryBtn title="Create producer" />
              </Link>
            </CheckAcl>
          }
        />
        <Grid
          onRowDoubleClick={(e: any, { id }: any) => {
            if (test('producer', 'U')) {
              push(routes.ProducersEdit.replace(':id', `${id}`));
            }
          }}
          shema={gridShema}
          data={producers}
          actions={(producer) => (
            <Actions>
              <CheckAcl resources="producer" actions="U">
                <Link to={routes.ProducersEdit.replace(':id', `${producer.id}`)}>
                  <EditAction />
                </Link>
              </CheckAcl>
              <CheckAcl resources="producer" actions="D">
                <DeleteAction
                  onConfirm={(e) => {
                    deleteProducerReq(producer.id).then(() => {
                      this.initFetch();
                    });
                  }}
                />
              </CheckAcl>
            </Actions>
          )}
        />
        <Footer>
          <Loader css={style.loader} isLoading={producersLoading} />
        </Footer>
        {hasMoreProducers && !producersLoading && <Waypoint onEnter={this.onWaypointEnter} />}
      </GridBox>
    );
  }
}

export default compose<IProps, IState>(
  connect(
    { producers, producersPage, producersTotal, producersLoading, producersSearch },
    { getProducersReq, deleteProducerReq, setProducersPage, push }
  )
)(withAcl(ProducersList));

const style = {
  loader: css`
    margin: 5px 0;
  `,
  gridBox: css`
    margin-bottom: 100px;
  `,
};
