/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { compose, lifecycle } from 'recompose';
import { RouteComponentProps } from 'react-router';

// redux
import { connect } from '../../../../../../utils/redux';
import { getGateReq, clearGate, updateGateReq } from './store/actions';
import { gate } from './store/selectors';
import { getProductionReq } from '../../store/actions';
import { production } from '../../store/selectors';

// types
import { Action } from '../../../../../../store/types';
import { GateItemState } from './store/types';
import { IProduction } from '../../store/types';

// components
import EditForm from './EditForm';

// utils
import _ from 'lodash';

interface MatchParams {
  id: string;
  gateId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  getProductionReq: Action<{}>;
  clearGate: Action<{}>;
  getGateReq: Action<{}>;
  updateGateReq: Action<{}>;
  gate: GateItemState;
  production: IProduction;
}

jsx;

const withLifecycle = lifecycle<IProps, {}>({
  componentDidMount() {
    const {
      getProductionReq,
      getGateReq,
      clearGate,
      match: { params },
    } = this.props;

    clearGate();
    if (params.id !== String(_.get(production, 'id'))) getProductionReq(params.id);
    getGateReq({ productionId: params.id, id: params.gateId });
  },
});

const Edit = (props: IProps) => {
  const form = props.gate.data ? <EditForm {...props} /> : <div>Loading...</div>;
  return <div css={styles.formBox}>{form}</div>;
};

const styles = {
  formBox: css`
    width: 100%;
  `,
};

export default compose<IProps, {}>(
  connect(
    { gate, production },
    { getProductionReq, getGateReq, clearGate, updateGateReq }
  ),
  withLifecycle
)(Edit);
