/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, InputHTMLAttributes, ChangeEvent } from 'react';
import { Field, FieldAttributes } from 'formik';
import * as styles from './styles';

jsx;

interface IProps {
  title: string;
  selected?: boolean;
  className?: string;
}

export const Text: FunctionComponent<IProps> = ({ title, selected = false, className }): ReactElement<IProps> => {
  return (
    <div css={styles.option(selected)} className={className}>
      {title}
    </div>
  );
};

export default Text;
