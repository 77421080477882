import { put, call, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
// actions
import * as actions from './actions';
// utils
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getResponseMessage } from '../../../../../../../utils/response';
import Routes from '../../../../../../../routes';

export default function* watchReducersAsync() {
  yield takeEvery(actions.GET_MACHINERIES_REQ, getMachineriesAsync);
  yield takeEvery(actions.CREATE_MACHINERY_REQ, createMachineryAsync);
  yield takeEvery(actions.UPDATE_MACHINERY_REQ, updateMachineryAsync);
  yield takeEvery(actions.MOVE_MACHINERY_USER_REQ, moveMachineryUserAsync);
  yield takeEvery(actions.CLONE_MACHINERY_REQ, cloneMachineryAsync);
  yield takeEvery(actions.DELETE_MACHINERY_REQ, deleteMachineryAsync);
  yield takeEvery(actions.PRINT_MACHINERIES_REQ, printMachineriesAsync);
  yield takeEvery(actions.DOWNLOAD_MACHINERIES_REQ, downloadMachineriesAsync);
}

export function* getMachineriesAsync({ api, payload: { ownerId, ...params } }: any) {
  try {
    const path = `/productions/${ownerId}/machineries`;
    const response = yield call(api.get, path, { params });
    yield put(actions.getMachineriesReqSuccess({}));
    yield put(actions.setMachineries(response.data));
  } catch (err) {
    yield put(actions.getMachineriesReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* createMachineryAsync({ api, payload: { ownerId, ...data } }: any) {
  try {
    const path = `/productions/${ownerId}/machineries`;
    const response = yield call(api.post, path, data);
    yield put(actions.createMachineryReqSuccess({}));
    //yield put(actions.addMachinery(response.data));
    //yield call([toast, 'info'], 'Machinery successfully created!');
  } catch (err) {
    yield put(actions.createMachineryReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* updateMachineryAsync({ api, payload: { ownerId, id, ...data } }: any) {
  try {
    const path = `/productions/${ownerId}/machineries/${id}`;
    const response = yield call(api.post, path, data);
    yield put(actions.updateMachineryReqSuccess({}));
    yield put(actions.updateMachinery(response.data));
    //yield call([toast, 'info'], 'Machinery successfully updated!');
  } catch (err) {
    yield put(actions.updateMachineryReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* moveMachineryUserAsync({ api, payload: { ownerId, id, userId, ...data } }: any) {
  try {
    const path = `/productions/${ownerId}/machineries/${id}/users/${userId}/move`;

    const response = yield call(api.patch, path, data);
    yield put(actions.updateMachineryReqSuccess({}));
    yield put(actions.updateMachinery(response.data));
    //yield call([toast, 'info'], 'Machinery successfully updated!');
  } catch (err) {
    yield put(actions.updateMachineryReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* cloneMachineryAsync({ api, payload: { ownerId, id, ...data } }: any) {
  try {
    const path = `/productions/${ownerId}/machineries/${id}/clone`;
    const response = yield call(api.put, path, data);
    const machinery = _.get(response, 'data.data[0]');
    yield put(actions.cloneMachineryReqSuccess({ clonedId: id, machinery }));
    //yield call([toast, 'info'], 'Machinery successfully cloned!');
  } catch (err) {
    yield put(actions.cloneMachineryReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* deleteMachineryAsync({ api, payload: { ownerId, id } }: any) {
  try {
    const path = `/productions/${ownerId}/machineries/${id}`;
    yield call(api.delete, path);
    yield put(actions.deleteMachineryReqSuccess());
    yield put(actions.deleteMachinery(id));
    //yield call([toast, 'info'], 'Machinery successfully deleted!');
  } catch (err) {
    yield put(actions.deleteMachineryReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* printMachineriesAsync({ api, payload }: any) {
  try {
    const path = `/print/productions/${payload}/machineries/preview`;
    const response = yield call(api.post, path, payload, { responseType: 'blob' });
    const file = window.URL.createObjectURL(response.data);
    window.open(file, '_blank');
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* downloadMachineriesAsync({ api, payload: {id, name} }: any) {
  try {
    const path = `/print/productions/${id}/machineries/preview`;
    const response = yield call(api.post, path, id, { responseType: 'blob' });
    
    const url = window.URL.createObjectURL(response.data);

  let tempLink = document.createElement('a');
  tempLink.href = url;
  tempLink.setAttribute('download', name);
  tempLink.click();
  tempLink.remove();

  window.URL.revokeObjectURL(url)

  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
