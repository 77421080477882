/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FunctionComponent, MouseEvent, ReactElement } from "react";
import * as styles from "./styles";

export interface IFormRowProps {
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

jsx;

export const EditAction: FunctionComponent<IFormRowProps> = ({
  onClick
}): ReactElement<IFormRowProps> => {
  return <button css={styles.editAction} onClick={onClick} />;
};

export default EditAction;
