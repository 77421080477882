import { nsSelector } from '../../../../../utils/redux';

const clientsSelector = nsSelector('clients');

export const clientsForSelect = clientsSelector('listForSelect');

// deprecated
export const clients = clientsSelector('list');
export const clientsLoading = clientsSelector('listLoading');
export const clientsParams = clientsSelector('params');
export const clientsMeta = clientsSelector('meta');

export const client = clientsSelector('item');
