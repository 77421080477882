/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import * as styles from '../../../../../../../styles/styles';
import arrowBlackTop from '../images/arrowBlackTop.svg';
import arrowBlackBottom from '../images/arrowBlackBottom.svg';
import arrowWhiteRight from '../images/arrowWhiteRight.svg';
import { Colors } from '../../../../../../../styles/styles';

export const header = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 30px',
};

export const headerLeftSide = css({});

export const headerRighrSide = css({});

export const title = (isOpen: boolean) => {
  const bgPosition = isOpen ? 'center 60%, center 40%' : 'center 40%, center 60%';

  return [
    styles.H3,
    css({
      cursor: 'pointer',
      position: 'relative',
      paddingLeft: '30px',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '-22px',
        left: '-14px',
        width: '30px',
        height: '60px',
        backgroundImage: `url(${arrowBlackTop}), url(${arrowBlackBottom})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: bgPosition,
        transform: `scale(0.8)`,
      },
    }),
  ];
};

export const dayTableBox = css({
  borderTop: `1px solid ${styles.Colors.Grey}`,
});

export const startDateTimeCell = css({
  position: 'relative',
  display: 'inline-block',
  whiteSpace: 'nowrap',
});

export const startDateTimeMark = css({
  position: 'absolute',
  top: '-11px',
  right: '0px',
});

export const timelineBox = (isOverCurrent: boolean) =>
  css({
    position: 'relative',
    width: '100%',
    backgroundColor: isOverCurrent ? styles.Colors.LightGreenGrey : styles.Colors.White,
    minHeight: '40px',
  });

export const timelineGridBox = (minHeight: number) =>
  css({
    minHeight: `${minHeight}px`,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',

    '& .timeline-cell': {
      flexGrow: 1,
      borderRight: `1px solid ${styles.Colors.LightGreyMainBG}`,
      minHeight: '100%',
    },

    '& .timeline-cell:first-of-type': {
      borderLeft: `1px solid ${styles.Colors.LightGreyMainBG}`,
    },
  });

export const timelineGridCell = (width: number) =>
  css({
    minWidth: `${width}%`,
    boxSizing: 'border-box',
  });

export const timelineCell = css({ padding: '0px !important', width: '40%', overflow: 'visible !important' });

export const timelineValueBox = css({
  position: 'absolute',
  top: 0,
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  '& .timeline-part-box': {
    display: 'flex',
    alignItems: 'center',
  },
});

export const timelinePartBox = css({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  width: '100%',
});

export const timelinePart = (indentWidth: any, width: any) =>
  css({
    marginLeft: `${indentWidth}%`,
    width: `${width}%`,
    height: '20px',
    position: 'relative',
  });

export const timelinePartColor = (color: any) =>
  css({
    backgroundColor: color,
  });

export const timelineValueBoxResize = css({
  cursor: 'col-resize',
});

export const timelineValueIndent = (value: number) => {
  return css({
    width: `${value}%`,
  });
};

/*export const timelineValue = ({ width, color = styles.Colors.DarkGrey, isSelected = false }: any) => {
  return css({
    width: `${width}%`,
    height: '20px',
    margin: '10px 0',
    backgroundColor: color,
    position: 'relative',
    border: isSelected ? '1px solid black' : 0,
    //boxSizing: 'border-box',
  });
};*/

export const resizeHandler = css({
  zIndex: 2,
  position: 'absolute',
  height: '100%',
  width: '5px',
  cursor: 'col-resize',
});

export const resizeHandlerLeft = [resizeHandler, css({})];

export const resizeHandlerRight = [
  resizeHandler,
  css({
    right: 0,
  }),
];

export const moveHandler = css({
  cursor: 'move',
  width: '100%',
  position: 'absolute',
  height: '100%',
  zIndex: 1,
});

export const linkPosition = css({
  left: '50%',
  top: '50%',
  position: 'absolute',
  zIndex: 1,
});

export const intersection = css({
  content: '""',
  position: 'absolute',
  width: '20px',
  height: '20px',
  backgroundImage: `url(${arrowWhiteRight}), url(${arrowWhiteRight})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '37% center, 63% center',
});

export const intersectionBefore = css({
  '&::before': [
    intersection,
    css({
      transform: `scale(1) rotate(180deg)`,
    }),
  ],
});

export const intersectionAfter = css({
  '&::after': [
    intersection,
    css({
      right: 0,
    }),
  ],
});

//HEADER

export const timelineHeaderBox = [
  styles.TimelineText,
  css({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  }),
];

export const timelineHeaderScaleItemBox = css({
  flexGrow: 1,
  position: 'relative',
});

export const timelineHeaderScaleItemTitle = css({
  position: 'absolute',
  left: '-13px',
  top: '15px',
});

export const timelineHeaderScaleEndItemBox = [
  timelineHeaderScaleItemBox,
  css({
    flexGrow: 0,
  }),
];
