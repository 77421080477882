/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, PureComponent } from 'react';
import { compose } from 'recompose';
// redux
import { connect } from '../../../../../utils/redux';
import {
  getClientAgentsReq,
  clearClientAgents,
  deleteClientAgentReq,
  sendClientAgentInvitationReq,
} from './store/actions';
import { clientAgents } from './store/selectors';
import { getClientsForSelectReq } from '../store/actions';
import { clientsForSelect } from '../store/selectors';
// components
import { Link as RouteLink, RouteComponentProps } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { Widget } from '../../../../common/widget/Widget';
import PrimaryBtn from '../../../../common/buttons/PrimaryBtn';
import GRID from '../../../../common/grid';
import GRIDActions from '../../../../common/grid/actions/index';
import GRIDToolbarActions from '../../../../common/grid/toolbarActions';
import Loader from '../../../../common/loader/Loader';
// types
import { Action } from '../../../../../store/types';
import { ClientListState } from '../store/types';
import { ClientAgentListState } from './store/types';
// utils
import _ from 'lodash';
import { push } from 'connected-react-router';
// other
import routes from '../../../../../routes';
import { ListStatuses } from '../../../../../utils/store/state/state';
import CheckAcl from '../../CheckAcl';
import withAcl, { IWrappedProps } from '../../withAcl';
import * as styles from './styles';

const { Actions, ToolBar, Footer, Grid, ActionList, DeleteAction, EditAction } = GRID;
const { Button } = GRIDActions;
const { Filter } = GRIDToolbarActions;

interface MatchParams {
  owner_id?: string;
}

interface IProps extends RouteComponentProps<MatchParams>, IWrappedProps {
  ownerId: string;
  clientAgents: ClientAgentListState;
  getClientAgentsReq: Action<{}>;
  clearClientAgents: Action<{}>;
  deleteClientAgentReq: Action<{}>;
  sendClientAgentInvitationReq: Action<{}>;
  clientsForSelect: ClientListState;
  getClientsForSelectReq: Action<{}>;
  push: Action<{}>;
}

interface IState {}

jsx;
class SupplierAgentsList extends PureComponent<IProps, IState> {
  componentDidMount() {
    const {
      getClientAgentsReq,
      getClientsForSelectReq,
      clientsForSelect,
      match: {
        params: { owner_id },
      },
    } = this.props;
    getClientsForSelectReq();
    getClientAgentsReq({ ownerId: owner_id, page: 0, size: 20 });
  }

  componentWillUnmount() {
    const { clearClientAgents } = this.props;

    clearClientAgents();
  }

  fetchMoreAgents = () => {
    const { getClientAgentsReq, clientAgents } = this.props;
    const { page } = clientAgents.params;
    getClientAgentsReq({ ...clientAgents.params, page: page + 1, size: 20 });
  };

  onWaypointEnter = () => {
    const { clientAgents } = this.props;
    if (clientAgents.status === ListStatuses.Request) return;
    this.fetchMoreAgents();
  };

  onFilterChange = (ownerId: number | number[]) => {
    const { getClientAgentsReq } = this.props;
    getClientAgentsReq({ ownerId, page: 0 });
  };

  render() {
    const {
      ownerId,
      clientAgents,
      deleteClientAgentReq,
      sendClientAgentInvitationReq,
      clientsForSelect,
      test,
      push,
    } = this.props;

    const currentOwnerId = clientAgents.params.ownerId || ownerId;
    const isLoading = clientAgents.status === ListStatuses.Request;
    const total = _.get(clientAgents, 'meta.total', 0);
    const hasMoreAgents = total > _.get(clientAgents.data, 'length', 0);
    const client = _.find(clientsForSelect.data, { id: currentOwnerId });
    const logo = _.get(client, 'file');

    return (
      <Widget>
        <Fragment>
          <ToolBar
            title="Agents"
            actionsLeft={
              <Fragment>
                {logo && <img css={styles.logo} src={logo} alt="" />}
                {clientsForSelect.data && (
                  <Filter
                    title="Filter"
                    items={clientsForSelect.data}
                    scheme={{ title: 'name' }}
                    selected={parseInt(currentOwnerId)}
                    onChange={this.onFilterChange}
                  />
                )}
              </Fragment>
            }
            actionsRight={
              <CheckAcl resources={'client'} actions={'C'}>
                <RouteLink to={routes.ClientAgentCreate.replace(':owner_id', `${currentOwnerId}`)}>
                  <PrimaryBtn title="Create agent" />
                </RouteLink>
              </CheckAcl>
            }
          />
          <Grid
            onRowDoubleClick={(e: any, { id }: any) => {
              if (test('clientAgent', 'U')) {
                push(routes.ClientAgentEdit.replace(':owner_id', `${currentOwnerId}`).replace(':id', `${id}`));
              }
            }}
            shema={[
              { title: 'Name', path: 'name' },
              { title: 'Email', path: 'email' },
              { title: 'Phone', path: 'phone' },
            ]}
            data={clientAgents.data || []}
            actions={({ id, isActive }) => {
              return (
                <Actions>
                  {test('client', 'U') && (
                    <RouteLink
                      to={routes.ClientAgentEdit.replace(':owner_id', `${currentOwnerId}`).replace(':id', `${id}`)}
                    >
                      <EditAction />
                    </RouteLink>
                  )}
                  {test('client', 'D') && (
                    <DeleteAction
                      onConfirm={(e) => {
                        deleteClientAgentReq({ ownerId: currentOwnerId, id: id });
                      }}
                    />
                  )}
                  {!isActive && test('client', 'U') && (
                    <ActionList>
                      <Button
                        title="Send invitation"
                        onClick={(e) => {
                          sendClientAgentInvitationReq({ ownerId: currentOwnerId, id: id });
                        }}
                      />
                    </ActionList>
                  )}
                </Actions>
              );
            }}
          />
          <Footer>
            <Loader isLoading={isLoading} />
          </Footer>
        </Fragment>

        {hasMoreAgents && !isLoading && <Waypoint onEnter={this.onWaypointEnter} />}
      </Widget>
    );
  }
}

export default compose<IProps, IState>(
  connect(
    { clientAgents, clientsForSelect },
    {
      getClientAgentsReq,
      clearClientAgents,
      deleteClientAgentReq,
      sendClientAgentInvitationReq,
      getClientsForSelectReq,
      push,
    }
  ),
  withAcl
)(SupplierAgentsList);
