/** @jsx jsx */
import { FunctionComponent, ReactElement } from 'react';
import { jsx } from '@emotion/core';
import * as yup from 'yup';
import { withFormik, Form, FormikProps } from 'formik';
import { Action } from '../../../store/types';
// components
import PrimaryBtn from '../../common/buttons/PrimaryBtn';
import FormRow from '../../common/form/FormRow';
import FormField from '../../common/form/FormField';
import FormError from '../../common/form/FormError';
// other
import * as styles from './styles';
import * as validatinShemas from '../../../utils/validatinShemas';
import { Colors } from '../../../styles/styles';

interface FormValues {
  password: string;
}

interface IProps {
  token: string;
  restorePasswordReq: Action<{}>;
  isForCopy: boolean;
}

jsx;
const LoginForm: FunctionComponent<IProps & FormikProps<FormValues>> = ({
  errors,
  touched,
  isForCopy,
}): ReactElement<IProps & FormikProps<FormValues>> => {
  const buttonBorder = isForCopy ? `1px solid ${Colors.White}` : '0px solid transparent';

  return (
    <div css={styles.formBox}>
      <Form>
        <FormRow>
          <FormField type="password" name="password" placeholder="password" />
          <FormError name="password" errors={errors} touched={touched} />
        </FormRow>
        <FormRow>
          <PrimaryBtn title="Restore" isWide={true} style={{ border: buttonBorder }} />
        </FormRow>
      </Form>
    </div>
  );
};

interface FormProps extends IProps {
  initialEmail?: string;
}

const entSchema = yup.object({}).shape({
  password: validatinShemas.vPasswordReq,
});

export const exchangeFormic = withFormik<FormProps & IProps, FormValues>({
  mapPropsToValues: (props) => {
    return {
      password: '',
    };
  },

  validationSchema: entSchema,
  handleSubmit: (values, { props, setSubmitting }) => {
    props.restorePasswordReq({ data: values, token: props.token });
  },
  displayName: 'LoginForm',
});

export default exchangeFormic(LoginForm);
