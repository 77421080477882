import { put, call, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
// actions
import * as actions from './actions';
// utils
import { toast } from 'react-toastify';
import { getResponseMessage } from '../../../../../../../utils/response';
import routes from '../../../../../../../routes';
import _ from 'lodash';

export default function* watchReducersAsync() {
  yield takeEvery(actions.GET_GATES_REQ, getGatesAsync);
  yield takeEvery(actions.GET_GATE_REQ, getGateAsync);
  yield takeEvery(actions.CREATE_GATE_REQ, createGateAsync);
  yield takeEvery(actions.UPDATE_GATE_REQ, updateGateAsync);
  yield takeEvery(actions.DELETE_GATE_REQ, deleteGateAsync);
}

export function* getGatesAsync({ api, payload: { productionId, ...params } }: any) {
  try {
    const path = `/productions/${productionId}/gates`;
    const response = yield call(api.get, path, { params });
    const data = _.get(response, 'data.data');
    const meta = _.get(response, 'data.meta');
    yield put(actions.getGatesReqSuccess({ data, params }, meta));
  } catch (err) {
    yield put(actions.getGatesReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* getGateAsync({ api, payload: { productionId, id } }: any) {
  try {
    const path = `/productions/${productionId}/gates/${id}`;
    const response = yield call(api.get, path);
    const data = _.get(response, 'data');
    yield put(actions.getGateReqSuccess({}));
    yield put(actions.setGate(data));
  } catch (err) {
    yield put(actions.getGateReqError(err));
  }
}

export function* createGateAsync({ api, payload: { productionId, ...values } }: any) {
  try {
    const path = `/productions/${productionId}/gates`;
    const response = yield call(api.post, path, values.data, { headers: { 'Content-Type': 'multipart/form-data' } });
    yield put(actions.createGateReqSuccess({}));
    yield put(actions.createGate(response.data));
    yield put(push(routes.ProductionGates.replace(':id', productionId)));
    //yield call([toast, 'info'], 'Gate successfully created!');
  } catch (err) {
    const messageToShow = getResponseMessage(err);
    yield put(actions.createGateReqError(err));
    yield call([toast, 'error'], messageToShow ? messageToShow : 'Something went wrong, try again or reload the page.');
  }
}

export function* updateGateAsync({ api, payload: { productionId, id, ...values } }: any) {
  try {
    const path = `/productions/${productionId}/gates/${id}`;
    const response = yield call(api.post, path, values.data, { headers: { 'Content-Type': 'multipart/form-data' } });
    yield put(actions.updateGateReqSuccess({}));
    yield put(actions.updateGate(response.data));
    yield put(push(routes.ProductionGates.replace(':id', productionId)));
    //yield call([toast, 'info'], 'Gate successfully updated!');
  } catch (err) {
    const messageToShow = getResponseMessage(err);
    yield put(actions.updateGateReqError(err));
    yield call([toast, 'error'], messageToShow ? messageToShow : 'Something went wrong, try again or reload the page.');
  }
}

export function* deleteGateAsync({ api, payload: { productionId, gateId } }: any) {
  try {
    const path = `/productions/${productionId}/gates/${gateId}`;
    yield call(api.delete, path);
    yield put(actions.deleteGateReqSuccess());
    yield put(actions.deleteGate(gateId));
    //yield call([toast, 'info'], 'Gate successfully deleted!');
  } catch (err) {
    yield put(actions.deleteGateReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
