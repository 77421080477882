/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment, createRef } from 'react';
import { withFormik, FormikProps, Form } from 'formik';
import * as yup from 'yup';
import { compose } from 'recompose';

import { Action } from '../../../../../store/types';
import * as validatinShemas from '../../../../../utils/validatinShemas';

import PrimaryBtn from '../../../../common/buttons/OutlineGreen';
import OutlineBtn from '../../../../common/buttons/OutlineForwarded';
import form from '../../../../common/form';

import { Colors } from '../../../../../styles/styles';

interface FormValues {
  name: string;
  phone: string;
  email: string;
  sendInvitation: boolean;
}

interface OtherProps {
  createAgentForClient: Action<{}>;
  ownerId: number | null;
  backToSelect: (e: any) => void;
  onCreateAndSelect: (value: any) => void;
  initName: string;
}

interface FormProps {
  initialEmail?: string;
}

jsx;

const CreateAgent = ({
  errors,
  touched,
  values,
  setFieldValue,
  backToSelect,
  ...props
}: OtherProps & FormikProps<FormValues>) => {
  const { FormRow, FormField, FormError, FormBlock, FieldsArea, RadioBtn } = form;
  const sendInvitationRef: any = createRef();
  const rejectInvitationRef: any = createRef();
  const firstFieldRef: any = createRef();
  const btnSubmitRef: any = createRef();
  const cancelRef: any = createRef();
  const handleLeaveFocusFromBtn = (event: KeyboardEvent) => {
    if (event.key === 'Tab' || event.keyCode === 9) {
      event.preventDefault();
      event.stopPropagation();
      firstFieldRef && firstFieldRef.current && firstFieldRef.current.focus();
    }
    if (event.shiftKey === true) {
      if (event.key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
        cancelRef && cancelRef.current && cancelRef.current.focus();
      }
    }
  };
  const handleLeaveFocus = (event: KeyboardEvent) => {
    if (event.shiftKey === true) {
      if (event.key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
        btnSubmitRef && btnSubmitRef.current && btnSubmitRef.current.focus();
      }
    }
  };

  return (
    <Fragment>
      <Form>
        <FieldsArea>
          <FormBlock>
            <FormRow>
              <label htmlFor="name">Name</label>
              <FormField
                name="name"
                placeholder="name"
                autoFocus
                innerRef={firstFieldRef}
                onKeyDown={(e: any) => handleLeaveFocus(e)}
              />
              <FormError name="name" errors={errors} touched={touched} />
            </FormRow>

            <FormRow>
              <label htmlFor="email">Email</label>
              <FormField name="email" placeholder="email" />
              <FormError name="email" errors={errors} touched={touched} />
            </FormRow>

            <FormRow>
              <label htmlFor="phone">Phone</label>
              <FormField name="phone" placeholder="+1234567890" />
              <FormError name="phone" errors={errors} touched={touched} />
              <p css={style.description}>Please add phone number in international format. Use only '+' and numbers.</p>
            </FormRow>

            <FormRow>
              <div role="group">
                <label htmlFor="sendInvitation">Send invitation</label>
                <RadioBtn
                  name="sendInvitation"
                  value={false}
                  values={values}
                  title="No"
                  ref={rejectInvitationRef}
                  tabIndex={0}
                  onChange={(value: any) => setFieldValue('sendInvitation', false)}
                  onKeyDown={(event: any) => {
                    if (event.key === 'ArrowRight' || event.keyCode === 39) {
                      event.preventDefault();
                      event.stopPropagation();
                      sendInvitationRef && sendInvitationRef.current && sendInvitationRef.current.focus();
                    }
                  }}
                />
                <RadioBtn
                  name="sendInvitation"
                  value={true}
                  values={values}
                  title="Yes"
                  tabIndex={0}
                  ref={sendInvitationRef}
                  onChange={(value: any) => setFieldValue('sendInvitation', true)}
                  onKeyDown={(event: any) => {
                    if (event.key === 'ArrowLeft' || event.keyCode === 37) {
                      event.preventDefault();
                      event.stopPropagation();
                      rejectInvitationRef && rejectInvitationRef.current && rejectInvitationRef.current.focus();
                    }
                  }}
                />
              </div>
              <FormError name="sendInvitation" errors={errors} touched={touched} />
            </FormRow>
          </FormBlock>
        </FieldsArea>

        <OutlineBtn
          type="button"
          isSmall={true}
          title="Back to Select"
          onClick={backToSelect}
          tabIndex={-1}
          ref={cancelRef}
          style={{ marginRight: '10px' }}
        />
        <PrimaryBtn
          type="submit"
          title="Create and Select"
          isWide={false}
          tabIndex={0}
          onKeyPress={handleLeaveFocusFromBtn}
          ref={btnSubmitRef}
        />
      </Form>
    </Fragment>
  );
};

export const exchangeFormic = withFormik<FormProps & OtherProps, FormValues>({
  enableReinitialize: true,

  mapPropsToValues: (props) => {
    return {
      name: props.initName,
      phone: '',
      email: '',
      sendInvitation: false,
    };
  },

  validationSchema: yup.object({}).shape({
    name: validatinShemas.vNameReq,
    phone: validatinShemas.vPhoneReq,
    email: validatinShemas.vEmailReq,
  }),

  handleSubmit: (values, { props: { ownerId, createAgentForClient, onCreateAndSelect }, setSubmitting }) => {
    createAgentForClient({ values, ownerId });
    onCreateAndSelect(values.name);
  },
  displayName: 'CreateAgentForm',
});

export default compose<OtherProps & FormikProps<FormValues>, OtherProps>(exchangeFormic)(CreateAgent);

const style = {
  description: css`
    font-size: 12px;
    color: ${Colors.LightBlack};
    margin: 5px;
  `,
};
