import { createAction } from 'redux-actions';

import reducersRegister from '../../../../../utils/reducersRegister';
import sagasRegister from '../../../../../utils/sagasRegister';
import { Action } from '../../../../../store/types';
import { createAsyncAction } from '../../../../../utils/async/actions';

import redusers from './reducers';
import sagas from './sagas';

reducersRegister.register('clients', redusers);
sagasRegister.register(sagas);

/******************************************************************************/
/******************************* CLIENTS **************************************/
/******************************************************************************/

export const GET_CLIENTS_REQ: string = 'GET_CLIENTS_REQ';
export const GET_CLIENTS_REQ_SUCCESS: string = 'GET_CLIENTS_REQ_SUCCESS';
export const GET_CLIENTS_REQ_ERROR: string = 'GET_CLIENTS_REQ_ERROR';

export const SET_CLIENTS: string = 'SET_CLIENTS';
export const SET_CLIENTS_PAGE: string = 'SET_CLIENTS_PAGE';
export const SET_CLIENTS_SEARCH_TEXT: string = 'SET_CLIENTS_SEARCH_TEXT';

export const getClientsReq: Action<{}> = createAsyncAction(GET_CLIENTS_REQ);
export const getClientsReqSuccess: Action<{}> = createAction(
  GET_CLIENTS_REQ_SUCCESS,
  (...args) => args[0],
  (...args) => args[1]
);
export const getClientsReqError: Action<{}> = createAction(GET_CLIENTS_REQ_ERROR);

export const setClients: Action<{}> = createAction(SET_CLIENTS);
export const setClientsPage: Action<{}> = createAction(SET_CLIENTS_PAGE);
export const setClientsSearchText: Action<{}> = createAction(SET_CLIENTS_SEARCH_TEXT);

/******************************************************************************/
/******************************* CLIENTS FOR SELECT****************************/
/******************************************************************************/

export const GET_CLIENTS_FOR_SELECT_REQ: string = 'GET_CLIENTS_FOR_SELECT_REQ';
export const GET_CLIENTS_FOR_SELECT_REQ_SUCCESS: string = 'GET_CLIENTS_FOR_SELECT_REQ_SUCCESS';
export const GET_CLIENTS_FOR_SELECT_REQ_ERROR: string = 'GET_CLIENTS_FOR_SELECT_REQ_ERROR';

export const SET_CLIENTS_FOR_SELECT: string = 'SET_CLIENTS_FOR_SELECT';
export const REFRESH_CLIENTS_FOR_SELECT: string = 'REFRESH_CLIENTS_FOR_SELECT';

export const getClientsForSelectReq: Action<{}> = createAsyncAction(GET_CLIENTS_FOR_SELECT_REQ);
export const getClientsForSelectReqSuccess: Action<{}> = createAction(GET_CLIENTS_FOR_SELECT_REQ_SUCCESS);
export const getClientsForSelectReqError: Action<{}> = createAction(GET_CLIENTS_FOR_SELECT_REQ_ERROR);

export const setClientForSelect: Action<{}> = createAction(SET_CLIENTS_FOR_SELECT);
export const refreshClientForSelect: Action<{}> = createAction(REFRESH_CLIENTS_FOR_SELECT);

/******************************************************************************/
/******************************* CREATE CLIENT ********************************/
/******************************************************************************/

export const CREATE_CLIENT_REQ: string = 'CREATE_CLIENT_REQ';
export const CREATE_CLIENT_REQ_IN_TAB: string = 'CREATE_CLIENT_REQ_IN_TAB';

export const createClientReq: Action<{}> = createAsyncAction(CREATE_CLIENT_REQ);
export const createClientReqInTab: Action<{}> = createAsyncAction(CREATE_CLIENT_REQ_IN_TAB);

/******************************************************************************/
/******************************* GET ONE CLIENT   *****************************/
/******************************************************************************/

export const GET_CLIENT_REQ: string = 'GET_CLIENT_REQ';
export const GET_CLIENT_REQ_SUCCESS: string = 'GET_CLIENT_REQ_SUCCESS';
export const GET_CLIENT_REQ_ERROR: string = 'GET_CLIENT_REQ_ERROR';
export const SET_CLIENT: string = 'SET_CLIENT';
export const ADD_CLIENT: string = 'ADD_CLIENT';
export const CLEAR_CLIENT: string = 'CLEAR_CLIENT';

export const getClientReq: Action<{}> = createAsyncAction(GET_CLIENT_REQ);
export const getClientReqSuccess: Action<{}> = createAction(GET_CLIENT_REQ_SUCCESS);
export const getClientReqError: Action<{}> = createAction(GET_CLIENT_REQ_ERROR);
export const setClient: Action<{}> = createAction(SET_CLIENT);
export const addClient: Action<{}> = createAction(ADD_CLIENT);
export const clearClient: Action<{}> = createAction(CLEAR_CLIENT);

/******************************************************************************/
/******************************* UPDATE ONE CLIENT   **************************/
/******************************************************************************/

export const UPDATE_CLIENT_REQ: string = 'UPDATE_CLIENT_REQ';
export const UPDATE_CLIENT_REQ_SUCCESS: string = 'UPDATE_CLIENT_REQ_SUCCESS';
export const UPDATE_CLIENT_REQ_ERROR: string = 'UPDATE_CLIENT_REQ_ERROR';
export const UPDATE_CLIENT: string = 'UPDATE_CLIENT';

export const updateClientReq: Action<{}> = createAsyncAction(UPDATE_CLIENT_REQ);
export const updateClientReqSuccess: Action<{}> = createAction(UPDATE_CLIENT_REQ_SUCCESS);
export const updateClientReqError: Action<{}> = createAction(UPDATE_CLIENT_REQ_ERROR);
export const updateClient: Action<{}> = createAction(UPDATE_CLIENT);

/******************************************************************************/
/******************************* DELETE ONE CLIENT   **************************/
/******************************************************************************/

export const DELETE_CLIENT_REQ: string = 'DELETE_CLIENT_REQ';
export const DELETE_CLIENT_REQ_SUCCESS: string = 'DELETE_CLIENT_REQ_SUCCESS';
export const DELETE_CLIENT_REQ_ERROR: string = 'DELETE_CLIENT_REQ_ERROR';
export const DELETE_CLIENT: string = 'DELETE_CLIENT';

export const deleteClientReq: Action<{}> = createAsyncAction(DELETE_CLIENT_REQ);
export const deleteClientReqSuccess: Action<{}> = createAction(DELETE_CLIENT_REQ_SUCCESS);
export const deleteClientReqError: Action<{}> = createAction(DELETE_CLIENT_REQ_ERROR);
export const deleteClient: Action<{}> = createAction(DELETE_CLIENT);
