/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
// component
import { Link } from 'react-router-dom';
import widget from '../../../../../common/widget';
// types
import { IProduction } from '../../store/types';
// styles
import * as generalStyles from '../../../../../../styles/styles';
// other
import routes from '../../../../../../routes';
import withAcl, { IWrappedProps } from '../../../withAcl';

export interface IProps extends IWrappedProps {
  production: IProduction;
}

jsx;

export const AdditionalInfoWidget: FunctionComponent<IProps> = ({ production, test }): ReactElement<IProps> => {
  const { Widget, WidgetHeader, WidgetBody } = widget;

  return (
    <Widget>
      <WidgetHeader title="Additional info" />
      <WidgetBody maxHeight={230}>
        {test('machinery', 'R') && production.showMachinery && (
          <div css={styles.contentRow}>
            <Link to={routes.ProductionMachinery.replace(':id', `${production.id}`)}>Machinery schedule</Link>
          </div>
        )}
        {test('gates', 'R') && production.showTransport && (
          <div css={styles.contentRow}>
            <Link to={routes.ProductionGates.replace(':id', `${production.id}`)}>Gates</Link>
          </div>
        )}
        {test('callSheet', 'R') && (
          <div css={styles.contentRow}>
            <Link to={routes.ProductionCallSheet.replace(':id', `${production.id}`)} key={production.id}>
              Call sheet
            </Link>
          </div>
        )}
        <div css={styles.contentRow}>
          <Link to={routes.ProductionSuppliers.replace(':id', String(production.id))} key={production.id}>
            Show suppliers
          </Link>
        </div>
      </WidgetBody>
    </Widget>
  );
};

const styles = {
  contentRow: [
    generalStyles.MainText,
    generalStyles.LinkIn,
    css({
      display: 'flex',
      boxSizing: 'border-box',
      height: '40px',
      width: '100%',
      alignItems: 'center',
      padding: '0 30px',
      borderBottom: `1px solid ${generalStyles.Colors.Grey}`,

      '& a': {
        color: '#4465E0',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    }),
  ],
};

export default withAcl(AdditionalInfoWidget);
