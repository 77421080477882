/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import { IBaseBtnProps } from './BaseBtn';

export interface IconBtnProps extends IBaseBtnProps {
  icon: string;
  iconSize?: string;
  className?: string;
}

jsx;
export const IconBtn: FunctionComponent<IconBtnProps> = ({
  icon,
  iconSize,
  className = '',
  ...props
}): ReactElement<IconBtnProps> => {
  return <button type="button" {...props} className={className} css={style.iconBtn(icon, iconSize)} />;
};

export default IconBtn;

const style = {
  iconBtn: (icon: string, size: string = '50%') => css`
    border: none;
    height: 40px;
    width: 40px;
    background-image: url(${icon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${size};
    background-color: transparent;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    border-radius: 50%;

    &:hover {
      background-color: #f5f5f5;
    }
  `,
};
