/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import * as styles from './styles';
import FormFile, { IProps as FormFileProps } from '../FormFile';
import Thumb, { IProps as ThumbProps } from '../Thumb';
import _ from 'lodash';

interface IProps {
  supportedFormats?: string[];
  onSelectUnsupported?: (file: any) => void;
  onBlur?: () => void;
}

type Props = IProps & FormFileProps & ThumbProps;

jsx;

export const ImageUpload: FunctionComponent<Props> = ({
  name,
  onSelect,
  alt,
  defaultImage,
  file,
  onBlur,
  supportedFormats = [],
  onSelectUnsupported = _.identity,
}): ReactElement<Props> => {
  const checkFile = (file: any) => {
    const handler =
      supportedFormats.length === 0 || supportedFormats.includes(file.type) ? onSelect : onSelectUnsupported;
    handler(file);
  };

  return (
    <div css={styles.fileUploadBox} onBlur={onBlur}>
      <Thumb alt={alt} defaultImage={defaultImage} file={file} />
      <FormFile name={name} onSelect={checkFile} onBlur={onBlur} />
    </div>
  );
};

export default ImageUpload;
