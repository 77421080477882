/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { PureComponent } from 'react';
// utils
import _ from 'lodash';
import moment from 'moment';
import cn from 'classnames';
// other
import { Colors, TimelineText } from '../../../../../../../styles/styles';

const PIXEL_DAY_WIDTH = 40;

export interface IProps {
  days: Array<any>;
  onSelectDay: Function;
}

export interface IState {}

jsx;
class ScrollableTimelineHeader extends PureComponent<IProps, IState> {
  private wrapper: any;
  private onScrollDebounced: any;

  constructor(props: IProps) {
    super(props);
    this.onScrollDebounced = _.throttle(this.onScroll, 250);
  }

  componentDidMount() {
    this.handleScrollableSides();
  }

  handleScrollableSides = () => {
    const { scrollLeft, offsetWidth, scrollWidth } = this.wrapper;
    const isScrollableLeft = scrollLeft > 0;
    const isScrollableRight = scrollLeft + offsetWidth < scrollWidth;
    const scrollableGrid = window.document.getElementsByClassName('scrollable-grid')[0];
    if (!scrollableGrid) return;
    scrollableGrid.classList[isScrollableLeft ? 'add' : 'remove']('scrollable-left');
    scrollableGrid.classList[isScrollableRight ? 'add' : 'remove']('scrollable-right');
  };

  onScroll = () => {
    this.handleScrollableSides();
  };

  render() {
    const { days, onSelectDay } = this.props;

    return (
      <div css={style.headerDaysBox} ref={(node) => (this.wrapper = node)} onScroll={this.onScrollDebounced}>
        {days.map((day: any) => {
          return (
            <div key={day.from} css={style.headerDayBox}>
              <div
                onClick={(e: any) => {
                  onSelectDay(day);
                }}
                css={style.headerDay}
              >
                {moment.utc(day.from).format('DD.M')}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default ScrollableTimelineHeader;

const style = {
  headerDaysBox: css({
    display: 'flex',
    justifyContent: 'flex-start',
    height: '100%',
    overflow: 'hidden',
  }),
  headerDayBox: css({
    flex: `0 0 ${PIXEL_DAY_WIDTH}px`,
    padding: '2px',
    boxSizing: 'border-box',
    marginTop: '30px',
  }),
  headerDay: [
    TimelineText,
    css({
      lineHeight: '20px',
      height: '20px',
      backgroundColor: Colors.LightGrey,
      borderRadius: '4px',
      textAlign: 'center',
      cursor: 'pointer',
      color: Colors.DarkGrey,
      '&:hover': {
        backgroundColor: Colors.DarkGrey,
        color: Colors.White,
      },
    }),
  ],
};
