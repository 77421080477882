/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';

import { Component } from 'react';
import { IProduction } from '../../store/types';

import DayDropTarget from './DayDropTarget';

import DayTable from './DayTable';

import { updateActivityRangeReq, copyActivityReq } from '../store/actions';
import { Action } from '../../../../../../store/types';
import { connect } from '../../../../../../utils/redux';
import { extractKeyValue } from '../../../../../../utils/collection';

export interface IProps {
  workDay: any;
  activities: any[];
  production: IProduction;
  canDrop: any;
  connectDropTarget: any;
  isPrintView?: boolean;

  updateActivityRangeReq?: Action<{}>;
  copyActivityReq?: Action<{}>;
  additionalPrintHeader?: any;

  allFunctions: any;
  allSuppliers: any;
}

export interface IState {
  isOpen: boolean;
  isEditMode: boolean;
  freezeOrder: number[] | null;
}

jsx;
class DateItem extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isOpen: true,
      isEditMode: false,
      freezeOrder: null,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    if (!this.state.isEditMode && this.state.freezeOrder) {
      this.setState({ freezeOrder: null });
    }
  }

  sortActivitiesByTime = (activies: any[]) => {
    return [...activies].sort((a: any, b: any) => {
      return a.range.from.valueOf() - b.range.from.valueOf();
    });
  };

  getFreezeSortMap = (activies: any[]) => {
    const { freezeOrder } = this.state;
    const result = freezeOrder || this.getSortMap(activies);
    if (!freezeOrder) {
      this.setState({ freezeOrder: result });
    }
    return result;
  };

  getActivityChildren = (activities: any, activity: any, accum: any = []): any => {
    const child = activity.child ? _.find(activities, { id: activity.child.id }) : null;
    return child ? this.getActivityChildren(activities, child, [...accum, child]) : accum;
  };

  findChildren = (activities: any, parentId: any) => {
    return activities.filter((activity: any) => _.get(activity, ['parent', 'id']) === parentId);
  };

  buildActivityHierarchy = (activities: any) => {
    return activities.map((activity: any) => {
      return { ...activity, children: this.findChildren(activities, activity.id) };
    });
  };

  editExit = () => {
    this.setState({ isEditMode: false });
  };

  listSort = () => {
    this.setState({ freezeOrder: null });
  };

  getSortMap = (activies: any[]) => {
    return extractKeyValue(this.sortActivitiesByTime(activies), 'id');
  };

  getRootActivities = (activitiesHash: any) => {
    return _.pickBy(activitiesHash, (activity: any) => !(activity.parent && activitiesHash[activity.parent.id]));
  };

  getGroupActivities = (parentActivity: any, activitiesHash: any, accum: any = {}) => {
    accum = { ...accum, [parentActivity.id]: parentActivity };
    return parentActivity.children
      .map((activity: any) => activitiesHash[activity.id])
      .reduce((accum: any[], activity: any) => {
        return { ...this.getGroupActivities(activity, activitiesHash, accum) };
      }, accum);
  };

  extractActivitiesGroups = (activitiesRoot: any, activitiesHash: any) => {
    return _.mapValues(activitiesRoot, (activity) => {
      return this.getGroupActivities(activity, activitiesHash);
    });
  };

  joinActivitiesWithGroups = (activitiesRoot: any, groups: any) => {
    return _.mapValues(activitiesRoot, (activity) => {
      return { ...activity, group: groups[activity.id] };
    });
  };

  sortActivities = (activies: any[], sortMap: any) => {
    const activitiesHash = _.keyBy(activies, 'id');
    const activitiesRoot = this.getRootActivities(activitiesHash);
    const groups = this.extractActivitiesGroups(activitiesRoot, activitiesHash);
    const activitiesRootWithGroups = this.joinActivitiesWithGroups(activitiesRoot, groups);
    const sortedActivities = this.sortActivitiesBySortMap(activitiesRootWithGroups, sortMap);

    return sortedActivities.reduce((result, activity) => {
      const group = groups[activity.id];
      const add = group ? this.sortActivitiesBySortMap(group, sortMap) : [activity];
      return [...result, ...add];
    }, []);
  };

  sortActivitiesBySortMap = (activitiesHash: any, sortMap: any[]) => {
    const sorted = sortMap.reduce((result: any[], sortId: any) => {
      const activity = activitiesHash[sortId];
      return activity ? [...result, activity] : result;
    }, []);

    const other = Object.values(_.omit(activitiesHash, sortMap));
    return [...sorted, ...other];
  };

  render() {
    const {
      workDay,
      activities,
      production,
      connectDropTarget,
      allFunctions,
      allSuppliers,
      additionalPrintHeader = null,
    } = this.props;

    const sortMap = this.getSortMap(activities);
    const fullSortedActivities = this.sortActivities(this.buildActivityHierarchy(activities), sortMap);

    return connectDropTarget(
      <div>
        <DayTable
          isPrint={true}
          production={production}
          activities={fullSortedActivities}
          workDay={workDay}
          isEditMode={false}
          additionalPrintHeader={additionalPrintHeader}
          allFunctions={allFunctions}
          allSuppliers={allSuppliers}
        />
      </div>
    );
  }
}

export default connect(
  {},
  { updateActivityRangeReq, copyActivityReq }
)(DayDropTarget(DateItem));
