/** @jsx jsx */
import { Component, CSSProperties } from 'react';
import { jsx } from '@emotion/core';
import _ from 'lodash';

//components
import Dropzone, { DropzoneProps } from 'react-dropzone';

import * as styles from './styles';

interface IProps extends DropzoneProps {
  fileFormats?: string[];
  maxSize?: number; //Mb
  style?: CSSProperties;
}

interface IState {
  files: any;
}

jsx;
class FormDropzone extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    const { onDrop, fileFormats, maxSize, ...props } = this.props;

    const fileFormat = fileFormats ? fileFormats.map(_.toUpper).join(',') : '';
    const size = maxSize ? `up tp ${maxSize}Mb` : maxSize;

    return (
      <Dropzone onDrop={onDrop} {...props}>
        {({ getRootProps, getInputProps }) => (
          <section className="container">
            <input {...getInputProps()} />

            <div css={styles.dropzoneBox} {...getRootProps({ className: 'dropzone' })} style={props.style}>
              <div>Drag and drop some files here, or click to select files</div>
              <div css={styles.fileFormatsInfo}>{`${fileFormat} ${size}`}</div>
            </div>
          </section>
        )}
      </Dropzone>
    );
  }
}

export default FormDropzone;
