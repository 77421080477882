/** @jsx jsx */
import { jsx } from '@emotion/core';
import { compose, lifecycle } from 'recompose';
import { RouteComponentProps } from 'react-router';

import { connect } from '../../../../utils/redux';

import { getClientReq, clearClient } from './store/actions';
import { Action } from '../../../../store/types';
import { client } from './store/selectors';
import EditForm from './EditForm';

import * as styles from './styles';
import { IClient } from './store/types';

interface FormValues extends Partial<IClient> {}

interface MatchParams {
  id?: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  getClientReq: Action<{}>;
  clearClient: Action<{}>;
  client: IClient;
}

jsx;

const withLifecycle = lifecycle<IProps, {}>({
  componentDidMount() {
    const { getClientReq, clearClient } = this.props;
    clearClient();
    getClientReq(this.props.match.params.id);
  },
});

const Edit = (props: IProps) => {
  const form = props.client ? <EditForm {...props} /> : <div>Loading...</div>;
  return <div css={styles.formBox}>{form}</div>;
};

interface FormProps {
  initialEmail?: string;
}

export default compose<IProps, {}>(
  connect(
    { client },
    { getClientReq, clearClient }
  ),
  withLifecycle
)(Edit);
