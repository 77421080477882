import si, { ImmutableObject } from 'seamless-immutable';
import * as ru from '../../../../../utils/redux';
import { getListState, getItemState } from '../../../../../utils/store/state/state';
import * as r from '../../../../../utils/reducers';

import * as actions from './actions';
import { TransportTypeListState } from './types';
// utils
import _ from 'lodash';

interface IState {
  list: TransportTypeListState;
  listAll: TransportTypeListState;
}

const INITIAL_STATE: ImmutableObject<IState> = si({
  // LIST
  list: getListState({
    size: 20,
    page: 0,
  }),

  listAll: getListState({
    size: 1000,
  }),
});

const listReducer = r.createReducer('list');
const listAllReducer = r.createReducer('listAll');

export default ru.processActions(
  () => ({
    // LIST
    [actions.GET_TRANSPORT_TYPES_REQ]: listReducer(r.loadingReducer),
    [actions.GET_TRANSPORT_TYPES_REQ_SUCCESS]: listReducer(r.successReducer),
    [actions.GET_TRANSPORT_TYPES_REQ_ERROR]: listReducer(r.errorReducer),
    [actions.SET_TRANSPORT_TYPES]: listReducer(r.unlimitedListDataReducer),
  }),
  INITIAL_STATE
);
