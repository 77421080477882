/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import { Field, FieldAttributes } from 'formik';
import * as styles from './styles';

jsx;

type TProps = FieldAttributes<any>;

export const FormField: FunctionComponent<TProps> = ({ ...props }): ReactElement<TProps> => {
  const checked = props.value === props.values[props.name];
  return (
    <span css={styles.formRadioBox}>
      <Field type="radio" {...props} defaultChecked={checked} style={{ accentColor: 'green' }} />
      {props.title}
    </span>
  );
};

export default FormField;
