/** @jsx jsx */
import { jsx } from '@emotion/core';
import { createRef, Fragment, PureComponent, KeyboardEvent } from 'react';
// types
import { IProduction } from '../../store/types';
import { IActivity } from '../store/types';
// components
import Day from './Day';
// utils
import moment from 'moment';
import * as config from '../../../../../../config';
import * as utils from '../utils';
import { ViewModes } from '../Item';
// types
import { Action } from '../../../../../../store/types';
import _ from 'lodash';
// styles
import * as styles from './styles';

interface IDayData {
  dayName: string;
}
export interface IProps {
  production: IProduction;
  activities: IActivity[];
  isSelectMode: boolean;
  isEditMode: boolean;
  allSuppliers: any;
  allFunctions: any;
  prodVersion?: any;
  viewMode: ViewModes;
  isShowEmpty: boolean;
  isStickyGridHeader?: boolean;
  selectDayActivities?: Action<{}>;
  unselectFromDayActivities?: Action<{}>;
  subproductions?: IProduction[];
  onMoveUp?: () => void;
  setDayInFocus?: Action<IDayData>;
  selectedDay?: number | null;
}

export interface IState {
  dayIndex: number;
  days: number[];
}

jsx;
class DateList extends PureComponent<IProps, IState> {
  private dayRef: any;
  private observer: any;
  private prevDayRef = null;

  constructor(props: IProps) {
    super(props);

    this.state = {
      dayIndex: 0,
      days: this.getRange().map((workDay: any) =>
        moment
          .utc(workDay.from)
          .startOf('day')
          .valueOf()
      ),
    };

    this.dayRef = [...Array(this.getRange().length)].map((r) => createRef());
  }

  componentDidUpdate(prevProps: any) {
    const { isEditMode } = this.props;
    const { dayIndex } = this.state;

    if (prevProps.selectedDay != this.props.selectedDay) {
      const { days } = this.state;
      const { selectedDay } = this.props;
      if (selectedDay) {
        const index = days.findIndex((day) => day === selectedDay);

        index !== -1 &&
          this.dayRef &&
          this.dayRef[index] &&
          this.dayRef[index].current &&
          this.dayRef[index].current.scrollIntoView({
            block: 'start',
            behavior: 'auto',
          });
      }
    } else if (isEditMode && !prevProps.isEditMode) {
      if (dayIndex > 0) {
        this.dayRef && this.dayRef[dayIndex] && this.dayRef[dayIndex].current && this.dayRef[dayIndex].current.focus();
      } else {
        this.dayRef && this.dayRef[0].current && this.dayRef[0].current.focus();
      }
    } else if (this.prevDayRef !== this.dayRef[0].current) {
      this.prevDayRef = this.dayRef[0].current;
      this.createDaysObserver();
    }
  }

  componentWillUnmount(): void {
    this.getRange().forEach((_: any, index: number) => {
      this.dayRef && this.dayRef[index].current && this.observer && this.observer.unobserve(this.dayRef[index].current);
    });
  }

  getRange = () => utils.getRangeOfWorkDays(this.props.production.loadIn.from, this.props.production.loadOut.to, 'day');
  createDaysObserver = () => {
    let options = {
      root: null,
      rootMargin: '-100px 0px -75%',
    };
    const callbackForIntersection = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting && entry.target.hasAttribute('title') && this.props.setDayInFocus) {
          const dayName = entry.target.getAttribute('title');
          this.props.setDayInFocus({ dayName });
          const dayIndex = Number(entry.target.dataset.index);
          if (dayIndex != this.state.dayIndex && !isNaN(dayIndex)) {
            this.setState({
              dayIndex: dayIndex,
            });
          }
        }
      });
    };

    this.observer = new IntersectionObserver(callbackForIntersection, options);
    this.getRange().forEach((_: any, index: number) => {
      this.dayRef && this.dayRef[index].current && this.observer.observe(this.dayRef[index].current);
    });
  };
  convertActivitesRangeToTimestamp = (activities: IActivity[]): IActivity[] => {
    return activities.map((activity) => {
      const range = { from: moment.utc(activity.range.from).valueOf(), to: moment.utc(activity.range.to).valueOf() };

      return Object.assign({}, activity, { range: range });
    });
  };

  getActivityByDate = (activities: any[], date: any) => {
    const result = activities.reduce((result, activity) => {
      const intersection = utils.getIntersectionRanges(date, activity.range);
      return intersection ? [...result, { ...activity, intersection }] : result;
    }, []);

    return result;
  };
  setFocusOnFirstItem() {
    this.dayRef && this.dayRef[0].current && this.dayRef[0].current.focus();
  }
  changeFocusBelow = (index: number, arrLength: number) => {
    if (index <= arrLength - 1) {
      this.dayRef[index + 1] && this.dayRef[index + 1].current && this.dayRef[index + 1].current.focus();
    }
  };
  changeFocusToUp = (index: number, arrLength: number) => {
    if (index <= arrLength - 1) {
      this.dayRef[index - 1] && this.dayRef[index - 1].current && this.dayRef[index - 1].current.focus();
    }
  };

  handleKeyPress = (e: KeyboardEvent, idx: number, arrLength: number) => {
    if (e.key === 'ArrowRight' || e.keyCode === 39) {
      this.changeFocusBelow(idx, arrLength);
    }
    if (e.key === 'ArrowLeft' || e.keyCode === 37) {
      this.changeFocusToUp(idx, arrLength);
    }
  };

  render() {
    const {
      production,
      activities,
      isSelectMode,
      allSuppliers,
      allFunctions,
      isEditMode,
      viewMode,
      onMoveUp,
      prodVersion,
      selectDayActivities,
      unselectFromDayActivities,
      subproductions,
      isStickyGridHeader,
      isShowEmpty = true,
    } = this.props;
    const rangeRepresentation = this.getRange();

    const activitiesWithConvertRange = this.convertActivitesRangeToTimestamp(activities);

    return (
      <Fragment>
        {rangeRepresentation
          // wrap with activities
          .map((workDay: any, index: number) => ({
            index,
            workDay,
            activities: this.getActivityByDate(activitiesWithConvertRange, workDay),
          }))
          // filter by condition
          .filter((item: any) => isShowEmpty || item.activities.length > 0)
          .map(({ workDay, index, activities }: any) => (
            <div
              tabIndex={-1}
              key={index}
              css={styles.day}
              data-index={index}
              title={`${workDay.from.format(`ddd, ${config.GRID_DATE_FORMAT}, YYYY`)}`}
              ref={this.dayRef[index]}
              // onKeyDown={(e: KeyboardEvent) => this.handleKeyPress(e, index, rangeRepresentation.length)}
            >
              <Day
                allSuppliers={allSuppliers}
                allFunctions={allFunctions}
                production={production}
                subproductions={subproductions}
                key={index}
                isEditMode={isEditMode}
                workDay={workDay}
                viewMode={viewMode}
                activities={activities}
                prodVersion={prodVersion}
                isSelectMode={isSelectMode}
                isStickyGridHeader={isStickyGridHeader}
                onMoveUp={onMoveUp}
                selectDayActivities={selectDayActivities}
                unselectFromDayActivities={unselectFromDayActivities}
              />
            </div>
          ))}
      </Fragment>
    );
  }
}

export default DateList;
