/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';

import React from 'react';
import PopoverBtn from '../../../../common/popover/PopoverBtn';
import ActionsBtn from '../../../../common/buttons/ActionsBtn';
import * as selectStyles from '../../../../common/form/select/styles';

export const NOT_SELECTED = 'Not selected';

export interface IScheme {
  id?: string;
  title?: string;
}
interface ILocateContent {
  targetRect: ClientRect;
  popoverRect: ClientRect;
  nudgedTop: number;
  nudgedLeft: number;
}

export interface ISelectProps {
  onUpdate: () => void;
  onDelete: () => void;
  onMove: () => void;
  onClone: () => void;
  disableReposition?: boolean;
  tabIndex?: number;
}

export interface IState {
  isOpen: boolean;
  isHover: boolean;
  boxWidth: number;
}

export const getSelectedItem = (convertItem: any, id: number | string | null) => {
  return id === null ? null : _.find(convertItem, { id });
};

jsx;
export const SelectActions = React.forwardRef<HTMLDivElement, ISelectProps>((props, ref) => {
  const { onUpdate, onMove, onClone, onDelete, tabIndex, disableReposition } = props;

  const locateContent = ({ targetRect, popoverRect, nudgedTop, nudgedLeft }: ILocateContent) => {
    const pageYOffset = window.pageYOffset;
    const pageXOffset = window.pageXOffset;

    if (targetRect.top <= 0) {
      return { top: targetRect.top + pageYOffset, left: nudgedLeft + pageXOffset };
    }

    if (window.innerHeight - targetRect.top <= 0) {
      return { top: targetRect.top - popoverRect.height + pageYOffset, left: nudgedLeft + pageXOffset };
    }

    return { top: nudgedTop + pageYOffset, left: nudgedLeft + pageXOffset };
  };

  return (
    <PopoverBtn
      btn={(openPopover) => (
        <div onClick={openPopover}>
          <ActionsBtn onClick={openPopover} type="button" title="Actions" />
        </div>
      )}
      disableReposition={disableReposition}
      tabIndex={tabIndex}
      contentLocation={({ targetRect, popoverRect, nudgedTop, nudgedLeft }) =>
        locateContent({ targetRect, popoverRect, nudgedTop, nudgedLeft })
      }
      renderContent={(closeDropdown) => (
        <div id="options" tabIndex={-1} onClick={(e) => closeDropdown(e)}>
          <div css={selectStyles.selectOption}>
            <div css={selectStyles.optionBase} onClick={onUpdate}>
              Update
            </div>
          </div>
          <div css={selectStyles.selectOption}>
            <div css={selectStyles.optionBase} onClick={onClone}>
              Clone
            </div>
          </div>
          <div css={selectStyles.selectOption}>
            <div css={selectStyles.optionBase} onClick={onMove}>
              Move
            </div>
          </div>
          <div css={selectStyles.selectOption}>
            <div css={selectStyles.optionBase} onClick={onDelete}>
              Delete
            </div>
          </div>
        </div>
      )}
    />
  );
});

export default SelectActions;
