import { DATE_FORMAT, TIME_FORMAT, TIME_FORMAT_12 } from '../config';
import moment, { MomentInput } from 'moment';

export const isAmPmTimeFormat = () => {
  const result = JSON.parse(localStorage.getItem('amPmTime') || 'false') === true;
  return result;
};

export const getTimeFormat = () => {
  return isAmPmTimeFormat() ? TIME_FORMAT_12 : TIME_FORMAT;
};

export const getDateTimeFormat = () => {
  return `${DATE_FORMAT} ${getTimeFormat()}`;
};

export const formatTime = (date: MomentInput) => {
  return moment(date).format(getTimeFormat());
};

export const formatDateTime = (date: MomentInput) => {
  return moment(date).format(getDateTimeFormat());
};
