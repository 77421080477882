/** @jsx jsx */
import { jsx, css } from '@emotion/core';

// components
import GateCard from '../Gates/GateCard';

// types
import { GatesListState } from '../Gates/store/types';
import { IProduction } from '../../store/types';

// styles
import { MainText } from '../../../../../../styles/styles';

// other
import { FunctionComponent, ReactElement } from 'react';

interface IProps {
  production: IProduction;
  gates: GatesListState;
}

jsx;

const GatesBlock: FunctionComponent<IProps> = ({ gates, production }): ReactElement<IProps> => {
  const gatesList = gates.data;

  return (
    <div css={styles.gatesWrap}>
      {gatesList &&
        (gatesList.length ? (
          gatesList.map((gate) => (
            <GateCard key={gate.id} gate={gate} showManageButtons={false} productionId={production.id} />
          ))
        ) : (
          <div css={MainText}>No gates to show</div>
        ))}
    </div>
  );
};

const styles = {
  gatesWrap: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  }),
};

export default GatesBlock;
