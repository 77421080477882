/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactElement } from 'react';
// components
import Select from 'react-select';
import { Props as ReactSelectProps } from 'react-select/lib/Select';
import { Colors, FontFamilies } from '../../../styles/styles';

export interface IProps extends ReactSelectProps {
  value: any;
}

jsx;
export default (props: IProps): ReactElement<IProps> => (
  <Select
    className="react-select"
    classNamePrefix="select"
    styles={customStyles} // tune up styling here
    {...props}
  />
);

const customStyles = {
  option: (provided: any, { isFocused, isSelected, isDisabled }: any) => ({
    ...provided,
    borderBottom: '1px solid #e6e6e6',
    background: isFocused || isSelected ? Colors.HoveredWhite : 'white',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    whiteSpace: 'nowrap',
    lineHeight: '30px',
    fontFamily: FontFamilies.Main,
    fontSize: '13px',
    fontWeight: 400,
    color: Colors.RegularBlack,
    outline: 'none',
  }),
  control: (provided: any, { isFocused }: any) => ({
    ...provided,
    outline: 'none',
    border: isFocused ? `1px solid ${Colors.Green}` : `1px solid ${Colors.Grey}`,
    '&:hover': {
      borderColor: Colors.Green,
      outlineColor: 'transparent',
    },
    '&:focus': {
      borderColor: Colors.Green,
      outlineColor: 'transparent',
    },
  }),
  menuPortal: (provided: any) => ({
    ...provided,
    zIndex: '6',
  }),
  placeholder: () => ({
    color: Colors.RegularBlack,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};
