/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, InputHTMLAttributes, ChangeEvent } from 'react';
import * as styles from './styles';
import { IProps as MultiSelectProps, IScheme, convert, select, NO_SELECTED_MESSAGE } from './MultiSelect';
import PopoverBtn from '../../popover/PopoverBtn';
import MultiSelect from './MultiSelect';
import * as collect from '../../../../utils/collection';

jsx;

interface IProps extends MultiSelectProps {
  noSelectedMsg?: string;
  disableReposition?: boolean;
}

export const MultiSelectArea: FunctionComponent<IProps> = (props): ReactElement<IProps> => {
  const { scheme, selected, items, disableReposition, noSelectedMsg = NO_SELECTED_MESSAGE } = props;

  const selectedItemTitles = collect.extractKeyValue(select(convert(items, scheme), selected), 'title');
  const areaText = selectedItemTitles.length ? selectedItemTitles.join(', ') : noSelectedMsg;

  const area = <div css={styles.multiSelectArea}>{areaText}</div>;
  return (
    <PopoverBtn
      btn={(openPopover, isOpen) => (
        <div onClick={openPopover} css={[styles.multiSelectArea, styles.multiSelectInputWrapper(isOpen)]}>
          {areaText}
        </div>
      )}
      disableReposition={disableReposition}
      contentLocation={({ targetRect, popoverRect, nudgedTop, nudgedLeft }) => {
        const pageYOffset = window.pageYOffset;
        const pageXOffset = window.pageXOffset;

        if (targetRect.top <= 0) {
          return { top: targetRect.top + pageYOffset, left: nudgedLeft + pageXOffset }
        }

        if (window.innerHeight - targetRect.top <= 0) {
          return { top: targetRect.top - popoverRect.height + pageYOffset, left: nudgedLeft + pageXOffset };
        }

        return { top: nudgedTop + pageYOffset, left: nudgedLeft + pageXOffset };
      }}
      renderContent={() => (
        <div css={styles.multiSelectPopoverBox}>
          <MultiSelect {...props} />
        </div>
      )}
    />
  );
};

export default MultiSelectArea;
