import * as React from 'react';
import { Global } from '@emotion/core';
import { Route, Switch } from 'react-router-dom';
// routes
import Login from './components/layouts/Public/Login';
import Restore from './components/layouts/Public/Restore';
import Invitation from './components/layouts/Public/Invitation';
import Private from './components/layouts/Private/Private';
import routes from './routes';
// toasts
import { ToastContainer, ToastPosition } from 'react-toastify';
// styles
import './App.css';
import './styles/fonts/roboto/roboto.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-hint/css/index.css';
import { toastify, reactHint } from './styles/overrides/toastify';
import VisualContext from './contexts/VisualContext';
import * as style from './styles/styles';

import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContextProvider } from 'react-dnd';
// react-hint
const ReactHintFactory = require('react-hint');
const ReactHint = ReactHintFactory(React);

const App = () => {
  return (
    <VisualContext>
      <DragDropContextProvider backend={HTML5Backend}>
        <div className="App">
          <Global styles={[style.globalStyle, toastify, reactHint]} />
          <Switch>
            <Route exact path={routes.Login} component={Login} />
            <Route exact path={routes.Restore} component={Restore} />
            <Route exact path={routes.Invitation} component={Invitation} />
            <Route path={routes.Root} component={Private} />
          </Switch>
          <ToastContainer position={ToastPosition.BOTTOM_RIGHT} autoClose={4000} />
          <ReactHint autoPosition events delay={{ show: 100, hide: 100 }} />
        </div>
      </DragDropContextProvider>
    </VisualContext>
  );
};

export default App;
