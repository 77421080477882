import { nsSelector } from '../../../../../utils/redux';

const suppliers = nsSelector('supplierFunctions');

export const supplierFunctions = suppliers('list');
export const supplierFunction = suppliers('item');

// DEPRECATED
export const suppliersList = suppliers('suppliersList');
export const suppliersListMeta = suppliers('suppliersListMeta');
export const suppliersListParams = suppliers('suppliersListParams');
export const suppliersLoading = suppliers('suppliersLoading');

export const suppliersAddLoading = suppliers('suppliersAddLoading');
export const suppliersUpdateLoading = suppliers('suppliersUpdateLoading');
export const suppliersDeleteLoading = suppliers('suppliersDeleteLoading');

export const suppliersUpdateModal = suppliers('suppliersUpdateModal');
export const suppliersDeleteModal = suppliers('suppliersDeleteModal');
