/** @jsx jsx */
import { jsx, css } from "@emotion/core";

import * as styles from "../../../../styles/styles";

export const header = css({
  boxSizing: "border-box",
  minHeight: "70px",
  padding: "10px 30px 0 30px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  boxShadow: "2px 2px 7px rgba(150, 150, 150, 0.25)"
});

export const logo = css({
  height: "51px"
});
