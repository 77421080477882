/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, forwardRef, createRef, KeyboardEvent, MouseEvent } from 'react';
import _ from 'lodash';
import PopoverBtn from '../../popover/PopoverBtn';
import SelectOption from './SelectOption';
import * as collection from '../../../../utils/collection';
import * as object from '../../../../utils/object';
import * as styles from './styles';
import * as inputStyles from '../../form/styles';

jsx;
export const NOT_SELECTED = 'Not selected';

export interface IScheme {
  id?: string;
  title?: string;
}

export interface IState {
  isOpen: boolean;
  boxWidth: number;
  search: string;
}

export interface ISelectProps {
  inputProps?: object;
  autoHeight?: boolean;
  scheme: IScheme;
  selected: string | number | null;
  items: any[];
  onChange?: (a: number[] | number) => void;
  onBlur?: () => void;
  disabled?: boolean;
  disableReposition?: boolean;
  shoudOpenOnFocus?: boolean;
  unselectedMsg?: string;
  className?: string;
  styles?: any;
  tabIndex?: number;
  noSelectedMsg?: string;
  forwardRef?: any;
}

interface ILocateContent {
  target: ClientRect;
  popover: ClientRect;
  nudgedTop: number;
  nudgedLeft: number;
}
export const convert = (items: any[], scheme: IScheme) => {
  return items.map((item) => {
    return object.buildData(item, scheme);
  });
};

export const getSelectedItem = (convertItem: any, id: number | string | null) => {
  return id === null ? null : _.find(convertItem, { id });
};

export const exclude = (convertedItems: any[], selected: number[]) => {
  return collection.excludeBy(convertedItems, { id: selected });
};

class SearchableSelect extends Component<ISelectProps, IState> {
  private optionsRef: any;
  private searchInputRef: any;
  constructor(props: ISelectProps) {
    super(props);
    this.optionsRef = createRef();
    this.searchInputRef = createRef();
    this.state = {
      isOpen: false,
      boxWidth: 0,
      search: '',
    };
  }

  select = (key: any) => {
    const onChange = this.props.onChange || _.identity;
    onChange(key);
  };
  handleClick = (e: MouseEvent<HTMLDivElement>, cb: any) => {
    const disabled = _.get(this.props, 'disabled', false);
    if (!disabled) {
      cb(e);
    }
  };
  handleSelect = (e: any, cb: any) => {
    this.select(e);
    cb(e);
    this.setState({ search: '' });
  };
  searchFilter = (items: any[], text: string) => {
    return items.filter((item) => item.name.toLowerCase().includes(text.toLowerCase()));
  };
  handleInputChange = (e: any) => {
    this.setState({ search: e.currentTarget.value });
  };
  changeFocusToOptions = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.keyCode === 13 || event.key === 'ArrowDown' || event.keyCode === 40) {
      event.stopPropagation();
      event.preventDefault();
      if (this.optionsRef && this.optionsRef.current) {
        this.optionsRef.current.focus();
      } else {
        return;
      }
    }
    if (!(this.optionsRef && this.optionsRef.current) && (event.key === 'Tab' || event.keyCode === 9)) {
      event.stopPropagation();
      event.preventDefault();
    }
  };
  escapePopover = (event: KeyboardEvent<HTMLInputElement>, cb: (e: any) => void) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      cb(event);
    }
  };
  handleOpenPopover = () => {
    setTimeout(() => {
      this.searchInputRef && this.searchInputRef.current && this.searchInputRef.current.focus();
    }, 0);
  };
  handleOptionsBlur = (e: any) => {
    this.searchInputRef && this.searchInputRef.current && this.searchInputRef.current.focus();
  };
  locateContent = ({ target, popover, nudgedTop, nudgedLeft }: ILocateContent) => {
    {
      const pageYOffset = window.pageYOffset ? window.pageYOffset : 400;
      const pageXOffset = window.pageXOffset;

      if (target.top <= 0) {
        return { top: target.top + pageYOffset, left: nudgedLeft + pageXOffset };
      }

      if (window.innerHeight - target.top <= 0) {
        return { top: target.top - popover.height + pageYOffset, left: nudgedLeft + pageXOffset };
      }

      return { top: nudgedTop + pageYOffset, left: nudgedLeft + pageXOffset };
    }
  };
  render() {
    const {
      selected,
      items,
      disableReposition,
      autoHeight = false,
      onBlur,
      unselectedMsg = 'Not selected',
    } = this.props;

    const { search } = this.state;

    const resultScheme = { id: 'id', title: 'title', ...this.props.scheme };
    const title =
      _.isNumber(selected) || _.isString(selected)
        ? _.get(getSelectedItem(convert(items, resultScheme), selected), 'title')
        : unselectedMsg;
    const searchedItems = this.searchFilter(items, search);

    return (
      <PopoverBtn
        btn={(openPopover, isOpen) => (
          <div
            onClick={openPopover}
            css={styles.simpleSelectInputWrapper(isOpen)}
            onKeyPress={(e) => {
              openPopover(e);
              this.props.forwardRef && this.props.forwardRef.current && this.props.forwardRef.current.focus();
            }}
          >
            <div css={inputStyles.formFieldBox}>
              <input
                type="text"
                ref={this.props.forwardRef}
                onChange={openPopover}
                // onFocus={shoudOpenOnFocus ? openPopover : undefined}
                // onClick={openPopover}
                onClick={(e) => {
                  setTimeout(() => {
                    e.stopPropagation();
                    e.preventDefault();
                    openPopover(e);
                    this.handleOpenPopover();
                  }, 1000);
                }}
                onFocus={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setTimeout(() => {
                    openPopover(e);
                    this.handleOpenPopover();
                  }, 200);
                }}
                value={title}
                css={styles.simpleSelectInput}
              />
            </div>
          </div>
        )}
        disableReposition={disableReposition}
        autoHeight={autoHeight}
        onClose={onBlur}
        contentLocation={({ targetRect, popoverRect, nudgedTop, nudgedLeft }) =>
          this.locateContent({ target: targetRect, popover: popoverRect, nudgedTop, nudgedLeft })
        }
        renderContent={(closeDropdown) => (
          <div css={styles.simpleSelectPopoverBox}>
            <input
              type="text"
              ref={this.searchInputRef}
              value={search}
              // autoFocus
              onChange={(e) => this.handleInputChange(e)}
              css={styles.searchText}
              onKeyDown={(e: any) => {
                this.changeFocusToOptions(e);
                this.escapePopover(e, closeDropdown);
              }}
            />
            {searchedItems.length > 0 ? (
              <div tabIndex={-1}>
                <SelectOption
                  items={searchedItems}
                  selected={selected}
                  resultScheme={resultScheme}
                  select={(e) => this.handleSelect(e, closeDropdown)}
                  onPressEscape={closeDropdown}
                  onOptionsBlur={this.handleOptionsBlur}
                  ref={this.optionsRef}
                />
              </div>
            ) : (
              <div>
                <p css={styles.addNewMessage} tabIndex={-1}>
                  No results
                </p>
              </div>
            )}
          </div>
        )}
      />
    );
  }
}

export default forwardRef<HTMLInputElement, ISelectProps>((props, ref) => (
  <SearchableSelect {...props} forwardRef={ref} />
));
