/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router';
// pages
import List from './List';
import Item from './Item/Item';
import CallSheet from './Item/CallSheet/CallSheet';
import EditCallSheet from './Item/CallSheet/Edit';
import Machinery from './Item/Machinery/Machinery';
import MachineryPrint from './Item/Machinery/PlatePrint';
import ItemPrint from './Item/ItemPrint';
import Create from './Create';
import CreateSub from './CreateSub';
import Edit from './Edit';
import EditSub from './EditSub';
import ProductionSuppliers from '../ProductionSuppliers/ProductionSuppliers';
import Gates from './Item/Gates/Gates';
// other
import routes from '../../../../routes';
import withAcl, { IWrappedProps } from '../withAcl';

interface IProps extends IWrappedProps {}

jsx;

const Productions: FunctionComponent<IProps> = ({ test }): ReactElement<IProps> => {
  return (
    <Fragment>
      <Switch>
        <Route exact path={routes.Productions} component={List} />
        {test('production', 'C') && <Route exact path={routes.ProductionsCreate} component={Create} />}
        {test('production', 'U') && <Route exact path={routes.ProductionsEdit} component={Edit} />}
        <Route exact path={routes.Production} component={Item} />
        {test('callSheet', 'U') && <Route exact path={routes.ProductionCallSheetEdit} component={EditCallSheet} />}
        {test('callSheet', 'R') && <Route exact path={routes.ProductionCallSheet} component={CallSheet} />}
        {test('production', 'P') && <Route exact path={routes.ProductionPrint} component={ItemPrint} />}
        {test('machinery', 'R') && <Route exact path={routes.ProductionMachinery} component={Machinery} />}
        {test('machinery', 'P') && <Route exact path={routes.ProductionMachineryPrint} component={MachineryPrint} />}
        <Route exact path={routes.ProductionSuppliers} component={ProductionSuppliers} />
        {test('gates', 'R') && <Route path={routes.ProductionGates} component={Gates} />}

        {test('production', 'U') && <Route exact path={routes.SubProductionsCreate} component={CreateSub} />}
        {test('production', 'U') && <Route exact path={routes.SubProductionsEdit} component={EditSub} />}
        <Route component={() => <Redirect to={routes.Productions} />} />
      </Switch>
    </Fragment>
  );
};

export default withAcl(Productions);
