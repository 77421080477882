/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, forwardRef } from 'react';
import modal from '../../../../../common/modal';
import OutlineBtn from '../../../../../common/buttons/OutlineBtn';
import PrimaryBtn from '../../../../../common/buttons/PrimaryBtn';
import CreateSupplierForm, {
  ISupplierFormValues,
  FormProps,
} from '../../../../../common/form/selectWithTabs/CreateSupplierForm';
// types
import { ISupplier } from '../../../Suppliers/store/types';
import LABELS from '../../../../../../constants';

interface IProps extends FormProps {
  forwardRef?: any;
  allSuppliers: any;
  updateSupplier: any;
}

interface IState {
  isOpenModal: boolean;
  newSupplierData: any;
  existingSupplier: ISupplier | null;
}

jsx;

class CreateSupplier extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isOpenModal: false,
      newSupplierData: null,
      existingSupplier: null,
    };
  }

  checkIsSupplierAlreadyExists = (values: ISupplierFormValues, cbSave: any, cbSelect: any) => {
    const { allSuppliers } = this.props;
    const isSupplierAlredyExist: ISupplier | null = allSuppliers.find((supplier: any) => supplier.name === values.name);
    if (!isSupplierAlredyExist) {
      cbSave(values);
      cbSelect(values.name);
    } else {
      this.setState({ isOpenModal: true, newSupplierData: values, existingSupplier: isSupplierAlredyExist });
    }
  };
  setRenounceUpdatingSupplier = () => {
    this.setState({ isOpenModal: false });
  };

  updateSupplier = () => {
    const { existingSupplier, newSupplierData } = this.state;

    if (existingSupplier) {
      const { id, name, functions: existingSupplierFunc } = existingSupplier;
      const existingSupplierFuncIds =
        existingSupplierFunc && existingSupplierFunc.length > 0
          ? existingSupplierFunc.map((func: any) => func.id)
          : null;
      const functions = existingSupplierFuncIds
        ? [...existingSupplierFuncIds, ...newSupplierData.functions]
        : [...newSupplierData.functions];
      this.props.updateSupplier({ id, name, functions });
    }
    this.setState({ isOpenModal: false });
  };

  render() {
    const { Modal, ModalHeader, ModalFooter, ModalBody, ModalActions } = modal;

    return (
      <div>
        <CreateSupplierForm
          {...this.props}
          ref={this.props.forwardRef}
          checkIsSupplierAlreadyExists={this.checkIsSupplierAlreadyExists}
        />
        <Modal isOpen={this.state.isOpenModal} shouldPlaceAbove>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>
            <p>{`The supplier with this name is already exist. Would you like to add these ${
              LABELS.functions
            } to the supplier?`}</p>
          </ModalBody>
          <ModalFooter>
            <ModalActions>
              <OutlineBtn type="button" onClick={this.setRenounceUpdatingSupplier} title="Cancel" />
              <PrimaryBtn title="Update" type="submit" onClick={this.updateSupplier} />
            </ModalActions>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default forwardRef<HTMLInputElement, IProps>((props, ref) => <CreateSupplier {...props} forwardRef={ref} />);
