/** @jsx jsx */
import { Fragment } from 'react';
import { jsx } from '@emotion/core';
import { withFormik, Form, FormikProps } from 'formik';
import * as yup from 'yup';
import { compose } from 'recompose';
import { Link, RouteComponentProps } from 'react-router-dom';

// components
import FormRow from '../../../../../common/form/FormRow';
import ActionsRow from '../../../../../common/form/ActionsRow';
import FieldsArea from '../../../../../common/form/FieldsArea';
import FormBlock from '../../../../../common/form/FormBlock';
import FormDropzone from '../../../../../common/form/FormDropzone';
import FieldsSet from '../../../../../common/form/FieldsSet';
import TextArea from '../../../../../common/form/TextArea';
import FormError from '../../../../../common/form/FormError';
import RemoveBox from '../../../../../common/form/RemoveBox';
import FileThumb, { ALLOWABLE_FILE_TYPES } from '../../../../../common/form/fileviewer/FileThumb';
import PrimaryBtn from '../../../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../../../common/buttons/OutlineBtn';
import page from '../../../../../common/page';

// types
import { Action } from '../../../../../../store/types';
import { IProduction } from '../../store/types';

// styles
import * as styles from './styles';

// other
import routes from '../../../../../../routes';
import { toast } from 'react-toastify';
import uniqid from 'uniqid';
import { MAX_FILE_UPLOAD_MB } from '../../../../../../config';

interface FormValues {
  blocks: any[];
  attachments: any[];
}

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  updateCallSheetReq: Action<{}>;
  production: IProduction;
}

jsx;

const CallSheetEdit = ({
  production,
  match: { params },
  errors,
  touched,
  values,
  setFieldValue,
}: IProps & FormikProps<FormValues>) => {
  const { Page, Breadcrumbs, PageHeader } = page;

  return (
    <Fragment>
      <PageHeader title="Edit call sheet">
        <Breadcrumbs
          path={[
            { title: 'Productions', link: routes.Productions },
            { title: production.name, link: routes.Production.replace(':id', `${params.id}`) },
            { title: 'Call sheet', link: routes.ProductionCallSheet.replace(':id', `${params.id}`) },
            { title: 'Edit call sheet', link: routes.ProductionCallSheetEdit.replace(':id', `${params.id}`) },
          ]}
        />
      </PageHeader>
      <Page>
        <Form>
          <FieldsArea>
            <FormBlock>
              <FieldsSet width={2}>
                {values.blocks.map((value: any, index: number) => {
                  const name = `blocks.${index}`;
                  return (
                    <FormRow key={value.id}>
                      <label htmlFor="name">
                        <div css={styles.formLabel}>{value.name}</div>
                      </label>

                      <TextArea
                        name={name}
                        value={value.description}
                        cols={200}
                        rows={5}
                        onChange={(e: any) => {
                          setFieldValue(name, { ...value, description: e.currentTarget.value });
                        }}
                      />

                      <FormError name={`${name}.description`} errors={errors} touched={touched} />
                    </FormRow>
                  );
                })}
              </FieldsSet>
            </FormBlock>

            <FormBlock>
              <FieldsSet width={2}>
                <div css={styles.filesListBox}>
                  {values.attachments.map((attachment: any) => {
                    const file = attachment.isNew ? attachment.file : attachment;
                    return (
                      <div key={attachment.id} css={styles.iconWrapper}>
                        <RemoveBox
                          onRemove={() => {
                            const attachments = values.attachments.filter((attach) => attach.id !== attachment.id);
                            setFieldValue('attachments', attachments);
                          }}
                        >
                          <FileThumb file={file} isShowName={true} isImagePreview={true} isLoadableFile={true} />
                        </RemoveBox>
                      </div>
                    );
                  })}
                </div>

                <FormDropzone
                  onDrop={(files: any) => {
                    files = validateFiles(files);
                    files = files.map((file: any) => ({ file, isNew: true, id: uniqid('new-') }));
                    setFieldValue('attachments', [...values.attachments, ...files]);
                  }}
                  maxSize={MAX_FILE_UPLOAD_MB}
                  fileFormats={ALLOWABLE_FILE_TYPES}
                />
              </FieldsSet>
            </FormBlock>

            <ActionsRow>
              <Link to={routes.ProductionCallSheet.replace(':id', `${params.id}`)}>
                <OutlineBtn title="Cancel" isWide={false} />
              </Link>
              <PrimaryBtn title="Save changes" isWide={false} />
            </ActionsRow>
          </FieldsArea>
        </Form>
      </Page>
    </Fragment>
  );
};

const validateFiles = (files: any[]) => {
  files = files.filter((file: any) => {
    if (!checkFileFormat(file)) {
      toast.error(`File ${file.name} is incorrect`);
      return false;
    }

    if (!checkFileSize(file)) {
      toast.error(`File ${file.name} is more then ${MAX_FILE_UPLOAD_MB}Mb`);
      return false;
    }

    return true;
  });

  return files;
};

const checkFileFormat = (file: any) => {
  // see src/components/layouts/Private/GeneralInfo/Form.tsx:checkFileFormat
  const ext = /(?:\.([^.]+))?$/.exec(file.name)![1] || '';
  return ALLOWABLE_FILE_TYPES.includes(ext.toLowerCase());
};

const checkFileSize = (file: any) => {
  return file.size <= MAX_FILE_UPLOAD_MB * 1024 * 1024;
};

const validationSchema = yup.object({}).shape({
  blocks: yup.array().of(
    yup.object({}).shape({
      description: yup.string().required('This field is required'),
    })
  ),
});

export const FormikHoc = withFormik<IProps, FormValues>({
  mapPropsToValues: ({ production }) => {
    return {
      blocks: production.general.blocks.map((block: any) => ({
        id: block.id,
        name: block.name,
        description: block.description,
      })),
      attachments: production.general.attachments || [],
    };
  },
  validationSchema,
  handleSubmit: (
    { blocks, attachments }: any,
    {
      props: {
        updateCallSheetReq,
        match: { params },
        production,
      },
    }
  ) => {
    let data = new FormData();

    for (let key in blocks) {
      data.append('blocks[]', JSON.stringify(blocks[key]));
    }

    const newFiles = attachments.filter((attach: any) => attach.isNew);
    for (let key in newFiles) {
      const { file } = newFiles[key];
      data.append('attachments', file, file.name);
    }

    const oldFiles = attachments.filter((attach: any) => !attach.isNew);
    for (let key in oldFiles) {
      const file = oldFiles[key];
      data.append('attachments[]', JSON.stringify(file));
    }

    updateCallSheetReq({ productionId: params.id, generalId: production.general.id, data });
  },
  displayName: 'CallSheetEditForm',
});

export default compose<IProps & FormikProps<FormValues>, IProps>(FormikHoc)(CallSheetEdit);
