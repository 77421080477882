import { put, call, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
// actions
import {
  PASSWORD_UPDATE_REQUEST,
  passwordUpdateRequestError,
  passwordUpdateRequestSuccess,
  passwordUpdate,
  PROFILE_UPDATE_REQUEST,
  profileUpdate,
  profileUpdateRequestError,
  profileUpdateRequestSuccess,
  PROFILE_LOAD_REQUEST,
  profileLoad,
  profileLoadRequestSuccess,
  profileLoadRequestError,
} from './actions';
import * as actions from './actions';
// utils
import _ from 'lodash';
import { query } from '../../../../../utils/API';
import { getResponseMessage } from '../../../../../utils/response';
// other
import { toast } from 'react-toastify';
import routes from '../../../../../routes';

export default function* watchMainAsync() {
  yield takeEvery(actions.GET_PROFILE_REQ, getProfileAsync);
  yield takeEvery(actions.UPDATE_PROFILE_REQ, updateProfileAsync);

  // DEPRECATED
  yield takeEvery(PASSWORD_UPDATE_REQUEST, updatePasswordRequest);
  yield takeEvery(PROFILE_UPDATE_REQUEST, profileUpdateRequest);
  yield takeEvery(PROFILE_LOAD_REQUEST, profileLoadRequest);
}

// GET ITEM
export function* getProfileAsync({ api, payload }: any) {
  try {
    const path = `/auth`;
    const response = yield call(api.post, path);
    yield put(actions.getProfileReqSuccess({}));
    yield put(actions.setProfile(response.data));
    localStorage.setItem('amPmTime', String(_.get(response, 'data.data[0].amPm', false)));
  } catch (err) {
    yield put(actions.getProfileReqError(err));
  }
}

// UPDATE ITEM
export function* updateProfileAsync({ api, payload }: any) {
  const data = payload;
  try {
    const path = `/users/profile`;
    const response = yield call(api.post, path, data);
    yield put(actions.updateProfileReqSuccess({}));
    yield put(actions.updateProfile(response.data));
  } catch (err) {
    yield put(actions.updateProfileReqError());
  }
}

// DEPRECATED
export function* profileLoadRequest(action: any) {
  try {
    const response = yield call(query().post, `/auth`, _.get(action, 'payload'));
    yield put(profileLoadRequestSuccess(response.data.data));
    yield put(profileLoad(response.data.data));
  } catch (err) {
    put(profileLoadRequestError(err));
  }
}

export function* updatePasswordRequest(action: any) {
  try {
    const response = yield call(query().put, '/users/profile/password', _.get(action, 'payload'));
    yield put(passwordUpdateRequestSuccess(response.data.data));
    yield put(passwordUpdate(response.data.data));
    yield put(push(routes.Root));
    //yield call([toast, 'success'], 'Password successfully updated!');
  } catch (err) {
    put(passwordUpdateRequestError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* profileUpdateRequest(action: any) {
  try {
    const response = yield call(query().put, '/users/profile', _.get(action, 'payload'));
    yield put(profileUpdateRequestSuccess(response.data.data));
    yield put(profileUpdate(response.data.data));
    const profile = _.get(response, 'data.data.0', {});
    localStorage.setItem('amPmTime', String(profile.amPm));
    yield put(push(routes.Root));
    //yield call([toast, 'success'], 'Profile info successfully updated.');
  } catch (err) {
    put(profileUpdateRequestError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
