/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FunctionComponent, MouseEvent, ReactElement, Fragment } from 'react';
import moment from 'moment';
import _ from 'lodash';
import * as collection from '../../../../../../utils/collection';
import * as array from '../../../../../../utils/array';

import OutlineBtn from '../../../../../common/buttons/OutlineBtn';
import withAcl, { IWrappedProps } from '../../../withAcl';

import * as mainStyles from '../../../../../../styles/styles';
import arrow from './images/arrow.svg';

export interface IProps {
  day: any;
  days: any[];
  onBack: () => void;
  onSelectDay: (selectDay: any) => void;
  isEditMode?: boolean;
  onEditStart?: (e: any) => void;
  onEditEnd?: (e: any) => void;
  test?: any;
}

jsx;

const prevDay = (days: any[], day: any) => {
  const result = array.prev([...days], (item: any) => {
    return item.from.valueOf() === day.from.valueOf();
  });
  return result;
};

const nextDay = (days: any[], day: any) => {
  const result = array.next([...days], (item: any) => {
    return item.from.valueOf() === day.from.valueOf();
  });
  return result;
};

export const MachineryListHeader: FunctionComponent<IProps> = ({
  day,
  days,
  onBack,
  onSelectDay,
  isEditMode = false,
  onEditStart = null,
  onEditEnd = null,
  test = _.identity,
}): ReactElement<IProps> => {
  const editBtnTitle = isEditMode ? 'Close edit mode' : 'Edit mode';
  const editBtnHandler = onEditStart && onEditStart ? (isEditMode ? onEditEnd : onEditStart) : null;

  const firstDay = _.first(days);
  const lastDay = _.last(days);

  return (
    <div css={styles.headerBox}>
      <div css={styles.leftSide}>
        {day && (
          <Fragment>
            <div css={styles.title}>{moment.utc(day.from).format('MMMM DD, YYYY')}</div>
            <div css={styles.navBox}>
              <div
                css={styles.navPrev}
                onClick={() => {
                  onSelectDay(prevDay(days, day));
                }}
              />
              <div
                css={styles.navNext}
                onClick={() => {
                  onSelectDay(nextDay(days, day));
                }}
              />
            </div>
          </Fragment>
        )}

        {!day && (
          <div css={styles.title}>{`${moment.utc(firstDay.from).format('MMMM DD, YYYY')} - ${moment
            .utc(lastDay.from)
            .format('MMMM DD, YYYY')}`}</div>
        )}
      </div>

      <div css={styles.rightSide}>
        {!!editBtnHandler && test('production', 'U') && (
          <div css={styles.editBtn}>
            <OutlineBtn type="button" title={editBtnTitle} onClick={editBtnHandler} />
          </div>
        )}

        {day && (
          <div>
            <OutlineBtn
              type="button"
              title="Back to all dates"
              onClick={(e: any) => {
                onBack();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default withAcl(MachineryListHeader);

const styles = {
  leftSide: css({
    display: 'flex',
  }),

  rightSide: css({
    display: 'flex',
  }),

  editBtn: css({
    marginRight: '20px',
  }),

  headerBox: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 30px',
    borderBottom: `1px solid ${mainStyles.Colors.Grey}`,
  }),

  title: [
    mainStyles.H2,
    css({
      lineHeight: '30px',
    }),
  ],

  navBox: css({
    height: '30px',
    display: 'flex',
    marginLeft: '30px',
    border: `1px solid ${mainStyles.Colors.LightGreyMainBG}`,
    borderRadius: '20px',
    cursor: 'pointer',
    overflow: 'hidden',
  }),

  navPrev: css({
    width: '30px',
    borderLeft: `1px solid ${mainStyles.Colors.LightGreyMainBG}`,
    background: `url( ${arrow} ) 50% 50% no-repeat`,
    transform: 'rotate(180deg)',
    '&:hover': {
      backgroundColor: mainStyles.Colors.Grey,
    },
  }),

  navNext: css({
    width: '30px',
    border: '',
    background: `url( ${arrow} ) 50% 50% no-repeat`,
    '&:hover': {
      backgroundColor: mainStyles.Colors.Grey,
    },
  }),
};
