/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, ReactElement } from 'react';
import _ from 'lodash';
import { extractKeyValue } from '../../../utils/collection';

import * as styles from './styles';
import { ICell, Visibility } from './CellView';
import Header from './Header';
import RowView, { IGridRow } from './RowView';
import RowEdit from './RowEdit';

export interface IShema extends ICell {}

export interface IState {}

export interface IProps extends IGridRow {
  primaryKey?: string;
  data: any[];
  isEditMode?: boolean;
  isSelectMode?: boolean;
  isTransportMode?: boolean;
  editItemId?: number | string | null;
  onEditStart?: (data: any) => void;
  onEditEnd?: () => void;
  actions?: (x: any) => ReactElement<any> | null;
  canDrag?: any;
  dragGridData?: any;
  additionalPrintHeader?: any;
  hideIf?: boolean;
  onRowClick?: (e: any, data: any) => void;
  onRowDoubleClick?: (e: any, data: any) => void;
  className?: string;
  renderAfterRowContent?: any;
  isStickyGridHeader?: boolean;
  emphasisRows?: number[];
}

jsx;
class Grid extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  editStart = (data: any) => {
    const { isEditMode, onEditStart = _.identity } = this.props;
    if (isEditMode) {
      onEditStart(data);
    }
  };

  editEnd = () => {
    const { onEditEnd = _.identity } = this.props;
    onEditEnd();
  };

  addActionsToScheme = (scheme: ICell[], actions: any) => {
    actions = actions ? [{ title: 'Actions', render: actions, width: 10 }] : [];
    return [...scheme, ...actions];
  };

  getCurrentScheme = (scheme: any[], isEditMode: boolean): ICell[] => {
    return scheme.filter(({ visible, hideIf }) => {
      if (hideIf) return false;
      if (visible) {
        if (_.isString(visible)) {
          if (isEditMode && visible !== Visibility.Edit) return false;
          if (!isEditMode && visible !== Visibility.View) return false;
        } else if (_.isFunction(visible)) {
          return visible();
        }
      }
      return true;
    });
  };

  processWidth = (scheme: any[]) => {
    const withDefinitionWidth = _.compact(extractKeyValue(scheme, 'width'));
    let notDefinitionWidth = (100 - _.sum(withDefinitionWidth)) / (scheme.length - withDefinitionWidth.length);

    return scheme.map((schemeItem) => {
      return schemeItem.width ? schemeItem : _.set(schemeItem, 'width', notDefinitionWidth);
    });
  };

  render() {
    const {
      primaryKey = 'id',
      data = [],
      shema,
      actions,
      isEditMode = false,
      dragGridData = {},
      canDrag = false,
      isPrintView = false,
      isTransportMode,
      additionalPrintHeader = null,
      editItemId = null,
      onRowClick = _.identity,
      onRowDoubleClick = _.identity,
      className,
      isSelectMode,
      isStickyGridHeader,
      emphasisRows,
      renderAfterRowContent = _.constant(null),
    } = this.props;

    const currentShema = this.processWidth(this.getCurrentScheme(this.addActionsToScheme(shema, actions), isEditMode));

    const rows = data.map((dataItem, index) => {
      const rowCanDrag = _.isFunction(canDrag) ? canDrag(dataItem) : canDrag;
      const key = _.get(dataItem, primaryKey);
      const isEditItem = isEditMode && editItemId ? key === editItemId : false;
      const isDisable = isEditMode && editItemId ? key !== editItemId : false;
      const isDifferent = emphasisRows ? emphasisRows.includes(key) : false;
      const rowProps = {
        data: dataItem,
        shema: currentShema,
        actions,
        isDisable,
        isRowBgdDifferent: isDifferent,
        isDifferent,
      };

      return isEditItem ? (
        <RowEdit {...rowProps} onClose={this.editEnd} key={`row-${key}`} />
      ) : (
        <RowView
          {...rowProps}
          key={`row-${key}`}
          onEdit={this.editStart}
          drag={rowCanDrag}
          dragGridData={dragGridData}
          isPrintView={isPrintView}
          onRowClick={onRowClick}
          onRowDoubleClick={onRowDoubleClick}
          afterRowContent={renderAfterRowContent(dataItem, index)}
        />
      );
    });

    const mainView = (
      <div css={styles.table} className={className}>
        <Header shema={currentShema} isSticky={isStickyGridHeader} />
        {rows}
        {isEditMode && !isSelectMode && !isTransportMode && (
          <RowEdit shema={currentShema} data={{}} isDisable={!!editItemId} />
        )}
      </div>
    );

    const printView = (
      <table css={styles.table}>
        <thead>
          <Header shema={currentShema} isPrintView={isPrintView} additionalPrintHeader={additionalPrintHeader} />
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
    return isPrintView ? printView : mainView;
  }
}

export default Grid;
