/** @jsx jsx */
import { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { jsx, css } from '@emotion/core';
import { compose } from 'recompose';
// redux
import { connect, STATUS_ACTUAL } from '../../../../../../utils/redux';
import { production, productionLoading } from '../../store/selectors';
import { getProductionReq } from '../../store/actions';
import { getMachineriesReq } from './store/actions';
import { machineries } from './store/selectors';
import { allCategoriesList } from '../../../Categories/store/selectors';
import { getAllCategoriesRequest } from '../../../Categories/store/actions';
import withVisualContext from '../../../../../../contexts/withVisualContext';

import * as mainStyles from '../../../../../../styles/styles';

// types
import { IProduction } from '../../store/types';
import { MachineriesListState } from './store/types';
import { CategoriesListState } from '../../../Categories/store/types';

import Loader from '../../../../../common/loader/Loader';

// utils
import _ from 'lodash';
import moment from 'moment';
import { Action } from '../../../../../../store/types';
import { DATE_FORMAT } from '../../../../../../config';
import { getTimeFormat } from '../../../../../../utils/timeFormatter';

interface MatchParams {
  id?: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  production: IProduction;
  getProductionReq: Action<{}>;
  machineries: MachineriesListState;
  getMachineriesReq: Action<{}>;
  allCategoriesList: CategoriesListState;
  getAllCategoriesRequest: Action<{}>;
  productionLoading: Boolean;
  visual: { logo: string };
}

jsx;
class MachineryPlatePrint extends Component<IProps> {
  componentDidMount = () => {
    const {
      getProductionReq,
      match: { params },
      getMachineriesReq,
      machineries,
      getAllCategoriesRequest,
      allCategoriesList,
    } = this.props;

    getMachineriesReq({ ...machineries.params, ownerId: params.id });
    getProductionReq(params.id, { fetchRelatedData: true });
    getAllCategoriesRequest({ ...allCategoriesList.params });
  };

  componentWillReceiveProps(nextProps: IProps) {
    const {
      getProductionReq,
      production,
      productionLoading,
      match: { params },
    } = nextProps;

    const isCurrentProduction = production && _.get(production, 'id') === Number(params.id);
    if (!isCurrentProduction && !productionLoading) {
      getProductionReq(params.id, { fetchRelatedData: true });
    }
  }
  getCategory = (catId: number) => {
    const { allCategoriesList } = this.props;
    return _.find(allCategoriesList.data, { id: catId });
  };

  getCategoryType = (catId: number, typeId: number) => {
    const category = this.getCategory(catId);
    return _.find(_.get(category, 'types', []), { id: typeId });
  };

  getCategoryName = (catId: number) => {
    const category = this.getCategory(catId);
    return _.get(category, 'name');
  };

  getCategoryTypeName = (catId: number, typeId: number) => {
    const type = this.getCategoryType(catId, typeId);
    return _.get(type, 'name');
  };

  sortUsers = (users: any) => {
    return [...users].sort((userOne: any, userTwo: any) => {
      const fromOne = moment.utc(userOne.range.from).valueOf();
      const fromTwo = moment.utc(userTwo.range.from).valueOf();
      return fromOne - fromTwo;
    });
  };

  render() {
    const { production, machineries, allCategoriesList } = this.props;
    if (
      !(
        _.every([production], Boolean) &&
        [machineries.status, allCategoriesList.status].every((status) => status === STATUS_ACTUAL)
      )
    ) {
      return <Loader isLoading />;
    }

    const prodLogo = production.file || production.client.file;

    const dtFormat = `${DATE_FORMAT} ${getTimeFormat()}`;
    return (
      <div>
        <table css={styles.printBox}>
          <tbody>
            {machineries.data.flatMap((machinery: any) => {
              const users = this.sortUsers(machinery.users);
              return users.length === 1 ? (
                <tr className="machinery-box" key={machinery.id}>
                  <td>
                    <div css={styles.itemBox}>
                      <div css={styles.header}>
                        <div id="machinery">
                          <p css={styles.machinery}>{`${this.getCategoryName(
                            machinery.category.id
                          )} ${this.getCategoryTypeName(machinery.category.id, machinery.type.id)} `}</p>

                          {users.map((user: any) => {
                            return (
                              <p key={user.id} css={styles.timeDuration}>
                                {`${moment.utc(user.range.from).format(dtFormat)} till ${moment
                                  .utc(user.range.to)
                                  .format(dtFormat)}`}
                              </p>
                            );
                          })}
                        </div>
                        <div css={styles.logoImages}>
                          {prodLogo && <img src={prodLogo} css={styles.logo} alt="" />}
                          <img src={this.props.visual.logo} css={styles.logo} alt="" />
                        </div>
                      </div>
                      <div css={styles.users}>
                        {users.map(({ supplier }) => (
                          <p css={styles.userName} key={supplier.id}>
                            {supplier.name}
                          </p>
                        ))}
                      </div>
                      <div css={styles.safetyIconsBox}>
                        {machinery.category.safetyIcons &&
                          machinery.category.safetyIcons.map((icon: any) => (
                            <img src={icon} css={styles.safetyIcons} alt="" key={icon} />
                          ))}
                      </div>
                      {/* </div> */}
                      <div className="cell" css={styles.footer}>{`${production.code} | ${production.name} | ${
                        machinery.label
                      }`}</div>
                    </div>
                  </td>
                </tr>
              ) : (
                users.map((user: any) => (
                  <tr className="machinery-box" key={user.id}>
                    <td>
                      <div css={styles.itemBox}>
                        <div css={styles.header}>
                          <div id="machinery">
                            <p css={styles.machinery}>{`${this.getCategoryName(
                              machinery.category.id
                            )} ${this.getCategoryTypeName(machinery.category.id, machinery.type.id)} `}</p>

                            <p key={user.id} css={styles.timeDuration}>
                              {`${moment.utc(user.range.from).format(dtFormat)} till ${moment
                                .utc(user.range.to)
                                .format(dtFormat)}`}
                            </p>
                          </div>
                          <div css={styles.logoImages}>
                            {prodLogo && <img src={prodLogo} css={styles.logo} alt="" />}
                            <img src={this.props.visual.logo} css={styles.logo} alt="" />
                          </div>
                        </div>
                        <div css={styles.users}>
                          <p css={styles.userName} key={user.supplier.id}>
                            {user.supplier.name}
                          </p>
                        </div>
                        <div css={styles.safetyIconsBox}>
                          {machinery.category.safetyIcons &&
                            machinery.category.safetyIcons.map((icon: any) => (
                              <img src={icon} css={styles.safetyIcons} alt="" key={icon} />
                            ))}
                        </div>
                        <div className="cell" css={styles.footer}>{`${production.code} | ${production.name} | ${
                          machinery.label
                        }`}</div>
                      </div>
                    </td>
                  </tr>
                ))
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default compose<IProps, {}>(
  connect(
    {
      production,
      productionLoading,
      machineries,
      allCategoriesList,
    },
    {
      getProductionReq,
      getMachineriesReq,
      getAllCategoriesRequest,
    }
  ),
  withVisualContext
)(MachineryPlatePrint);

const styles = {
  printBox: css({
    position: 'absolute',
    boxSizing: 'border-box',
    left: 0,
    top: 0,

    minWidth: '100%',
    minHeight: '100vh',
    color: mainStyles.Colors.AltBlack,
    backgroundColor: mainStyles.Colors.White,
  }),

  itemBox: css({
    boxSizing: 'border-box',
    position: 'relative',
    minHeight: '100vh',
    padding: '60px 60px 40px 60px',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }),

  header: css({
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '3px solid #06142D',
  }),
  logoImages: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }),
  logo: css({
    height: '100px',
    marginRight: '4px',
    position: 'relative',

    '&::before': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '10px',
      height: '10px',
      backgroundColor: '#fff',
      opacity: 1,
    },
  }),
  timeDuration: css({
    fonFamily: 'Poppins',
    fontSize: '28px',
    fontWeight: 400,
    lineHeight: '30px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#06142D',
    marginTop: 0,
  }),
  machinery: css({
    fontFamily: 'Roboto',
    fontSize: '76px',
    fontWeight: 600,
    lineHeight: '80px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#06142D',
    margin: '15px 0',
  }),
  users: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  userName: css({
    fontFamily: 'Roboto',
    fontSize: '128px',
    fontWeight: 600,
    lineHeight: '132px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#06142D',
    marginTop: 0,
    marginBottom: '30px',
  }),
  safetyIconsBox: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingBottom: '45px',
  }),
  safetyIcons: css({
    height: '128px',
    width: 'auto',
    marginRight: '12px',
    borderRadius: '10px',
  }),

  label: css({
    marginTop: '20px',
    fontSize: '100px',
    fontWeight: 500,
    marginRight: '70px',
  }),
  footer: css({
    position: 'absolute',
    bottom: '40px',
    right: '58px',
  }),
};
