/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, ComponentClass } from 'react';
import _ from 'lodash';
// redux
import { compose } from 'redux';
import { connect } from '../../../../utils/redux';
import { oneCategoryLoadRequest, categoryEditRequest } from './store/actions';
import { categoriesItem } from './store/selectors';
// types
import { Action } from '../../../../store/types';
// components
import Page from '../../../common/page/Page';
import Breadcrumbs from '../../../common/page/Breadcrumbs';
import PageHeader from '../../../common/page/PageHeader';
import CreateCategoryForm from './components/CreateCategoryForm';
import Loader from '../../../common/loader/Loader';
// other
import routes from '../../../../routes';
import { withRouter, RouteComponentProps } from 'react-router';
import { CategoryItemState } from './store/types';
import { ItemStatuses } from '../../../../utils/store/state/state';

interface IRouteParams {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
  categoriesItem: CategoryItemState;
  categoryAddRequest: Action<{}>;
  oneCategoryLoadRequest: Action<{}>;
  categoryEditRequest: Action<{}>;
}

interface IState {}

jsx;
class EditCategory extends Component<IProps, IState> {
  componentDidMount() {
    const {
      oneCategoryLoadRequest,
      match: { params },
    } = this.props;
    oneCategoryLoadRequest({ id: params.id });
  }

  render() {
    const {
      categoriesItem,
      categoryEditRequest,
      match: { params },
    } = this.props;

    const isLoading = categoriesItem.status === ItemStatuses.Request;

    return (
      <div>
        <PageHeader title="Edit category">
          <Breadcrumbs
            path={[
              { title: 'Categories', link: routes.Categories },
              { title: 'Edit category', link: routes.CategoriesEdit.replace(':id', String(params.id)) },
            ]}
          />
        </PageHeader>
        <Page>
          {categoriesItem.data && (
            <CreateCategoryForm
              editCategory={categoryEditRequest}
              category={_.get(categoriesItem, 'data[0]')}
              editMode
            />
          )}
          <Loader isLoading={isLoading} />
        </Page>
      </div>
    );
  }
}

export default compose<ComponentClass<IProps, IState>>(
  connect(
    { categoriesItem },
    { oneCategoryLoadRequest, categoryEditRequest }
  ),
  withRouter
)(EditCategory);
