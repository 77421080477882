/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, InputHTMLAttributes, ChangeEvent } from 'react';
import { Field, FieldAttributes } from 'formik';
import * as styles from './styles';

jsx;

interface IProps extends InputHTMLAttributes<{}> {
  onChange: any;
}

export const Text: FunctionComponent<IProps> = (props): ReactElement<IProps> => {
  const handleAddNew = (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      props.onChange()
    }
  };
  return (
    <input type="checkbox" css={styles.checkbox} {...props} onChange={props.onChange} onKeyDown={(e: any)=> handleAddNew(e)} />
  );
};

export default Text;
