/** @jsx jsx */
import { FunctionComponent, ReactElement } from 'react';
import { jsx, css } from '@emotion/core';

export interface IProps {
  maxHeight?: number;
}

jsx;
export const WidgetBody: FunctionComponent<IProps> = ({ children, maxHeight = 300 }): ReactElement<IProps> => {
  const css = [style.widgetBody, { maxHeight: `${maxHeight}px` }];
  return <div css={css}>{children}</div>;
};

export default WidgetBody;

const style = {
  widgetBody: css`
    overflow: auto;
  `,
};
