/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';

import { Component, Fragment } from 'react';

import * as selectStyles from './styles';

import Header from './Header';
import Popover from '../../popover/Popover';
import React from 'react';
import * as object from '../../../../utils/object';

export const NOT_SELECTED = 'Not selected';

export interface IScheme {
  id?: string;
  title?: string;
}

export interface ISelectProps {
  scheme?: IScheme;
  selected: number | string | null;
  items: any[];
  children: (a: any, b: boolean) => any;
  onChange?: (a: number[] | number) => void;
  disabled?: boolean;
  disableReposition?: boolean;
  unselectedMsg?: string;
  header?: any;
  beforeOptions?: any;
  afterOptions?: any;
  className?: string;
  styles?: any;
}

export interface IState {
  isOpen: boolean;
  isHover: boolean;
  boxWidth: number;
}

export const convert = (items: any[], scheme: IScheme) => {
  return items.map((item) => {
    return object.buildData(item, scheme);
  });
};

export const getSelectedItem = (convertItem: any, id: number | string | null) => {
  return id === null ? null : _.find(convertItem, { id });
};

jsx;
class Select extends Component<ISelectProps, IState> {
  private widthRuler: any;

  constructor(props: ISelectProps) {
    super(props);
    this.widthRuler = React.createRef();

    this.state = {
      isOpen: false,
      isHover: false,
      boxWidth: 0,
    };
  }

  setWidth = (width: number) => {
    this.setState({ boxWidth: width });
  };

  open = () => {
    const disabled = _.get(this.props, 'disabled', false);

    if (!disabled) {
      this.setState({ isOpen: true });
    }
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  toggle = () => {
    const { isOpen } = this.state;
    setTimeout(() => {
      const handler = isOpen ? this.close : this.open;
      handler();
    }, 0);
  };

  select = (key: any) => {
    this.close();
    const onChange = this.props.onChange || _.identity;
    onChange(key);
  };

  clickOutside = () => {
    this.close();
  };

  render() {
    const {
      selected,
      items,
      scheme = {},
      children,
      unselectedMsg = NOT_SELECTED,
      header = Header,
      beforeOptions = Fragment,
      afterOptions = Fragment,
      onChange,
      disabled,
      disableReposition,
      className,
      styles = [],
    } = this.props;

    const resultScheme = { id: 'id', title: 'title', ...scheme };
    const { isOpen, boxWidth } = this.state;

    const title =
      _.isNumber(selected) || _.isString(selected)
        ? _.get(getSelectedItem(convert(items, resultScheme), selected), 'title')
        : unselectedMsg;

    return (
      <Fragment>
        <div ref={this.widthRuler} className={className}>
          {header({
            title,
            isOpen,
            disabled,
            onClick: this.toggle,
            styles: styles,
          })}

          <div css={selectStyles.popoverBox}>
            <Popover
              fixedWidth={boxWidth}
              contentLocation={({ targetRect, popoverRect, nudgedTop, nudgedLeft }) => {
                const pageYOffset = window.pageYOffset;
                const pageXOffset = window.pageXOffset;

                if (targetRect.top <= 0) {
                  return { top: targetRect.top + pageYOffset, left: nudgedLeft + pageXOffset };
                }

                if (window.innerHeight - targetRect.top <= 0) {
                  return { top: targetRect.top - popoverRect.height + pageYOffset, left: nudgedLeft + pageXOffset };
                }

                return { top: nudgedTop + pageYOffset, left: nudgedLeft + pageXOffset };
              }}
              disableReposition={disableReposition}
              isOpen={isOpen}
              onClickOutside={this.clickOutside}
            >
              <div id="options">
                {beforeOptions}
                {!!items.length &&
                  items.map((item: any) => {
                    const key = item[resultScheme.id];
                    const selectedItem = key === selected;
                    return (
                      <div
                        onClick={(e) => {
                          this.select(key);
                        }}
                        key={key}
                        tabIndex={0}
                      >
                        {children(item, selectedItem)}
                      </div>
                    );
                  })}
                {afterOptions}
              </div>
            </Popover>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Select;
