/** @jsx jsx */
import { jsx, SerializedStyles } from '@emotion/core';
import { MouseEvent, forwardRef } from 'react';
import * as styles from './styles';

jsx;

export interface IBaseBtnProps {
  id?: string;
  title?: string;
  type?: any;
  onClick?: (e: MouseEvent<HTMLElement>) => any;
  isDisabled?: boolean;
  isSmall?: boolean;
  isWide?: boolean;
  btnStyle?: SerializedStyles | SerializedStyles[];
  className?: string;
  style?: object;
  onBlur?: () => void;
  onKeyPress?: (event: any) => void;
  tabIndex?: number;
}

export default forwardRef<HTMLButtonElement, IBaseBtnProps>(
  (
    {
      id,
      title,
      type = 'submit',
      onClick = (e: any) => e,
      isDisabled = false,
      isSmall = false,
      isWide = false,
      btnStyle = styles.outlineGreen,
      className,
      style,
      children,
      onBlur,
      tabIndex = -1,
      onKeyPress,
    },
    ref
  ) => {
    const wide = isWide ? styles.wide : [];
    const small = isSmall ? styles.small : [];
    const css = [btnStyle, wide, small];

    return (
      <button
        id={id}
        onClick={onClick}
        css={css}
        type={type}
        disabled={isDisabled}
        className={className}
        style={style}
        onBlur={onBlur}
        tabIndex={tabIndex}
        ref={ref}
        onKeyDown={onKeyPress}
      >
        {children || title}
      </button>
    );
  }
);
