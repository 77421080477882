import { createAction } from 'redux-actions';

import reducersRegister from '../../../../../utils/reducersRegister';
import sagasRegister from '../../../../../utils/sagasRegister';
import { Action } from '../../../../../store/types';
import { createAsyncAction } from '../../../../../utils/async/actions';

import redusers from './reducers';
import sagas from './sagas';

reducersRegister.register('suppliers', redusers);
sagasRegister.register(sagas);

/******************************************************************************/
/******************************* SUPPLIERS **************************************/
/******************************************************************************/

export const GET_SUPPLIERS_REQ: string = 'GET_SUPPLIERS_REQ';
export const GET_SUPPLIERS_REQ_SUCCESS: string = 'GET_SUPPLIERS_REQ_SUCCESS';
export const GET_SUPPLIERS_REQ_ERROR: string = 'GET_SUPPLIERS_REQ_ERROR';
export const UPDATE_SUPPLIERS_REQ: string = 'UPDATE_SUPPLIERS_REQ';


export const SET_SUPPLIERS: string = 'SET_SUPPLIERS';
export const UPDATE_SUPPLIER_IN_LIST: string = 'UPDATE_SUPPLIER_IN_LIST';
export const REFRESH_SUPPLIERS: string = 'REFRESH_SUPPLIERS';
export const RESET_SUPPLIERS: string = 'RESET_SUPPLIERS';

export const SET_SUPPLIERS_PAGE: string = 'SET_SUPPLIERS_PAGE';
export const SET_SUPPLIERS_SEARCH_TEXT: string = 'SET_SUPPLIERS_SEARCH_TEXT';
export const SET_SUPPLIERS_FILTER: string = 'SET_SUPPLIERS_FILTER';
export const SET_SUPPLIERS_PARAMS: string = 'SET_SUPPLIERS_PARAMS';

export const getSuppliersReq: Action<{}> = createAsyncAction(GET_SUPPLIERS_REQ);
export const getSuppliersReqSuccess: Action<{}> = createAction(
  GET_SUPPLIERS_REQ_SUCCESS,
  (...args) => args[0],
  (...args) => args[1]
);
export const getSuppliersReqError: Action<{}> = createAction(GET_SUPPLIERS_REQ_ERROR);
export const updateSuppliersReq: Action<{}> = createAction(UPDATE_SUPPLIERS_REQ);

export const setSuppliers: Action<{}> = createAction(SET_SUPPLIERS);
export const updateSupplierInList: Action<{}> = createAction(UPDATE_SUPPLIER_IN_LIST);
export const refreshSuppliers: Action<{}> = createAction(REFRESH_SUPPLIERS);
export const resetSuppliers: Action<{}> = createAction(RESET_SUPPLIERS);

export const setSuppliersPage: Action<{}> = createAction(SET_SUPPLIERS_PAGE);
export const setSuppliersSearchText: Action<{}> = createAction(SET_SUPPLIERS_SEARCH_TEXT);
export const setSuppliersFilter: Action<{}> = createAction(SET_SUPPLIERS_FILTER);
export const setSuppliersParams: Action<{}> = createAction(SET_SUPPLIERS_PARAMS);

/******************************************************************************/
/******************************* SUPPLIERS FOR SELECT**************************************/
/******************************************************************************/

export const GET_SUPPLIERS_FOR_SELECT_REQ: string = 'GET_SUPPLIERS_FOR_SELECT_REQ';
export const GET_SUPPLIERS_FOR_SELECT_REQ_SUCCESS: string = 'GET_SUPPLIERS_FOR_SELECT_REQ_SUCCESS';
export const GET_SUPPLIERS_FOR_SELECT_REQ_ERROR: string = 'GET_SUPPLIERS_FOR_SELECT_REQ_ERROR';
export const SET_SUPPLIERS_FOR_SELECT: string = 'SET_SUPPLIERS_FOR_SELECT';
export const REFRESH_SUPPLIERS_FOR_SELECT: string = 'REFRESH_SUPPLIERS_FOR_SELECT';

export const refreshSuppliersForSelect: Action<{}> = createAction(REFRESH_SUPPLIERS_FOR_SELECT);
export const getSuppliersForSelectReq: Action<{}> = createAsyncAction(GET_SUPPLIERS_FOR_SELECT_REQ);
export const getSuppliersForSelectReqSuccess: Action<{}> = createAction(GET_SUPPLIERS_FOR_SELECT_REQ_SUCCESS);
export const getSuppliersForSelectReqError: Action<{}> = createAction(GET_SUPPLIERS_FOR_SELECT_REQ_ERROR);
export const setSuppliersForSelect: Action<{}> = createAction(SET_SUPPLIERS_FOR_SELECT);

/******************************************************************************/
/******************************* FILTERED SUPPLIERS****************************/
/******************************************************************************/

export const GET_SUPPLIERS_FILTERED_REQ: string = 'GET_SUPPLIERS_FILTERED_REQ';
export const GET_SUPPLIERS_FILTERED_REQ_SUCCESS: string = 'GET_SUPPLIERS_FILTERED_REQ_SUCCESS';
export const GET_SUPPLIERS_FILTERED_REQ_ERROR: string = 'GET_SUPPLIERS_FILTERED_REQ_ERROR';
export const SET_SUPPLIERS_FILTERED: string = 'SET_SUPPLIERS_FILTERED';
export const REFRESH_SUPPLIERS_FILTERED: string = 'REFRESH_SUPPLIERS_FILTERED';

export const refreshSuppliersFiltered: Action<{}> = createAction(REFRESH_SUPPLIERS_FILTERED);
export const getSuppliersFilteredReq: Action<{}> = createAsyncAction(GET_SUPPLIERS_FILTERED_REQ);
export const getSuppliersFilteredReqSuccess: Action<{}> = createAction(GET_SUPPLIERS_FILTERED_REQ_SUCCESS);
export const getSuppliersFilteredReqError: Action<{}> = createAction(GET_SUPPLIERS_FILTERED_REQ_ERROR);
export const setSuppliersFiltered: Action<{}> = createAction(SET_SUPPLIERS_FILTERED);

/******************************************************************************/
/******************************* CREATE SUPPLIER ********************************/
/******************************************************************************/

export const CREATE_SUPPLIER_REQ: string = 'CREATE_SUPPLIER_REQ';
export const CREATE_SUPPLIER_REQ_FROM_TABS: string = 'CREATE_SUPPLIER_REQ_FROM_TABS';
export const CREATE_SUPPLIER_REQ_SUCCESS: string = 'CREATE_SUPPLIER_REQ_SUCCESS';
export const CREATE_SUPPLIER_REQ_ERROR: string = 'CREATE_SUPPLIER_REQ_ERROR';

export const CREATE_SUPPLIER_START: string = 'CREATE_SUPPLIER_START';
export const CREATE_SUPPLIER: string = 'CREATE_SUPPLIER';

export const createSupplierReq: Action<{}> = createAsyncAction(CREATE_SUPPLIER_REQ);
export const createSupplierReqFromTabs: Action<{}> = createAsyncAction(CREATE_SUPPLIER_REQ_FROM_TABS);
export const createSupplierReqSuccess: Action<{}> = createAction(CREATE_SUPPLIER_REQ_SUCCESS);
export const createSupplierReqError: Action<{}> = createAction(CREATE_SUPPLIER_REQ_ERROR);
export const createSupplierStart: Action<{}> = createAction(CREATE_SUPPLIER_START);
export const createSupplier: Action<{}> = createAction(CREATE_SUPPLIER);

/******************************************************************************/
/******************************* GET ONE SUPPLIER   *****************************/
/******************************************************************************/

export const GET_SUPPLIER_REQ: string = 'GET_SUPPLIER_REQ';
export const GET_SUPPLIER_REQ_SUCCESS: string = 'GET_SUPPLIER_REQ_SUCCESS';
export const GET_SUPPLIER_REQ_ERROR: string = 'GET_SUPPLIER_REQ_ERROR';
export const SET_SUPPLIER: string = 'SET_SUPPLIER';
export const CLEAR_SUPPLIER: string = 'CLEAR_SUPPLIER';

export const getSupplierReq: Action<{}> = createAsyncAction(GET_SUPPLIER_REQ);
export const getSupplierReqSuccess: Action<{}> = createAction(GET_SUPPLIER_REQ_SUCCESS);
export const getSupplierReqError: Action<{}> = createAction(GET_SUPPLIER_REQ_ERROR);
export const setSupplier: Action<{}> = createAction(SET_SUPPLIER);
export const clearSupplier: Action<{}> = createAction(CLEAR_SUPPLIER);

/******************************************************************************/
/******************************* UPDATE ONE SUPPLIER   **************************/
/******************************************************************************/

export const UPDATE_SUPPLIER_REQ: string = 'UPDATE_SUPPLIER_REQ';
export const UPDATE_SUPPLIER_REQ_SUCCESS: string = 'UPDATE_SUPPLIER_REQ_SUCCESS';
export const UPDATE_SUPPLIER_REQ_ERROR: string = 'UPDATE_SUPPLIER_REQ_ERROR';
export const UPDATE_SUPPLIER_IN_TAB_REQ: string = 'UPDATE_SUPPLIER_IN_TAB_REQ';
export const UPDATE_SUPPLIER: string = 'UPDATE_SUPPLIER';

export const updateSupplierReq: Action<{}> = createAsyncAction(UPDATE_SUPPLIER_REQ);
export const updateSupplierReqSuccess: Action<{}> = createAction(UPDATE_SUPPLIER_REQ_SUCCESS);
export const updateSupplierReqError: Action<{}> = createAction(UPDATE_SUPPLIER_REQ_ERROR);
export const updateSupplierInTabReq: Action<{}> = createAsyncAction(UPDATE_SUPPLIER_IN_TAB_REQ);
export const updateSupplier: Action<{}> = createAction(UPDATE_SUPPLIER);

/******************************************************************************/
/******************************* DELETE ONE SUPPLIER   **************************/
/******************************************************************************/

export const DELETE_SUPPLIER_REQ: string = 'DELETE_SUPPLIER_REQ';
export const DELETE_SUPPLIER_REQ_SUCCESS: string = 'DELETE_SUPPLIER_REQ_SUCCESS';
export const DELETE_SUPPLIER_REQ_ERROR: string = 'DELETE_SUPPLIER_REQ_ERROR';
export const DELETE_SUPPLIER: string = 'DELETE_SUPPLIER';

export const deleteSupplierReq: Action<{}> = createAsyncAction(DELETE_SUPPLIER_REQ);
export const deleteSupplierReqSuccess: Action<{}> = createAction(DELETE_SUPPLIER_REQ_SUCCESS);
export const deleteSupplierReqError: Action<{}> = createAction(DELETE_SUPPLIER_REQ_ERROR);
export const deleteSupplier: Action<{}> = createAction(DELETE_SUPPLIER);
