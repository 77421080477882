/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import { FunctionComponent, createRef, ReactElement } from 'react';
import OutlineBtn from '../buttons/OutlineForwarded';

import * as styles from './styles';

export interface IProps {
  name: string;
  onSelect: any;
  onLoadStart?: any;
  onBlur?: () => void;
}

jsx;

export const FormFile: FunctionComponent<IProps> = ({ name, onSelect, onBlur }): ReactElement<IProps> => {
  const inputRef: any = createRef();
  const selectBtnRef: any = createRef();
  const handleChange = (event: any) => {
    const file = event.currentTarget.files[0];

    if (file) {
      const type = file.name
        .split('.')
        .pop()
        .toLowerCase();
      const reader = new FileReader();
      reader.onloadend = () => {
        onSelect({ file: reader.result, type });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      event.preventDefault();
      inputRef && inputRef.current && inputRef.current.click();
    }
    if (event.key === 'Tab' || event.keyCode === 9) {
      onBlur && onBlur();
    }
  };
  return (
    <div css={styles.formFileBox}>
      <label htmlFor={name}>
        <OutlineBtn
          title="Select file"
          isWide={true}
          tabIndex={0}
          onKeyPress={(event: any) => handleKeyDown(event)}
          ref={selectBtnRef}
        />
      </label>

      <input
        id="file"
        name={name}
        type="file"
        onChange={handleChange}
        onBlur={() => selectBtnRef && selectBtnRef.current && selectBtnRef.current.focus()}
        className="form-control"
        ref={inputRef}
        tabIndex={-1}
      />
    </div>
  );
};

export default FormFile;
