/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, Fragment } from 'react';
import * as styles from './styles';

enum Types {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export interface IFormRowProps {
  type?: Types;
}

jsx;
export const Info: FunctionComponent<IFormRowProps> = ({
  type = Types.Info,
  children,
}): ReactElement<IFormRowProps> => {
  return <Fragment>{children}</Fragment>;
};

export default Info;
