/** @jsx jsx */
import { Component } from 'react';
import { FormikProps, withFormik } from 'formik';
import * as yup from 'yup';
import { jsx, css } from '@emotion/core';
// types
import { ISupplierAgentLink } from '../Suppliers/Agents/store/types';
import { IProduction } from '../Productions/store/types';
import { IProducer } from '../Producers/store/types';
import { IProductionSupplier } from './store/types';
import { SupplierFunctions } from '../SupplierFunctions/store/types';
import { Action } from '../../../../store/types';
// components
import Select from '../../../common/form/select/Select';
import MultiselectModal from '../../../common/form/multiselect/MultiselectModal';
import Option from '../../../common/form/select/Option';
import IconBtn from '../../../common/buttons/IconBtn';
import FormError from '../../../common/form/FormError';
import { Form as FormicForm } from 'formik';
// styles
import { Colors } from '../../../../styles/styles';
import { style as tableStyles } from './ProductionSuppliers';
import applyIcon from '../../../common/grid/images/apply.svg';
import cancelIcon from '../../../common/grid/images/cancel.svg';
// utils
import _ from 'lodash';
import LABELS from '../../../../constants';

interface IProps {
  productionSupplier: IProductionSupplier;
  production: IProduction;
  producers: IProducer[];
  supplierFunctions: SupplierFunctions;
  updateSupplier: Action<{}>;
  cancelEdit: () => void;
  onSubmit?: (values: any) => void;
}

interface FormValues {
  supplierAgents: any;
  producers: any;
}

interface FormProps {}

jsx;
const ProductionSupplierEditRow = (props: IProps & FormikProps<FormValues>) => {
  const {
    productionSupplier,
    producers,
    supplierFunctions,
    cancelEdit,
    setFieldValue,
    values,
    errors,
    touched,
    submitForm,
  } = props;

  const onAgentChange = (index: number, key: string) => (value: any) => {
    setFieldValue('supplierAgents', [
      ...values.supplierAgents.slice(0, index),
      { ...values.supplierAgents[index], [key]: value },
      ...values.supplierAgents.slice(index + 1),
    ]);
  };

  const addAgentRow = () => {
    setFieldValue('supplierAgents', [
      ...values.supplierAgents,
      {
        agent: null,
        function: null,
      },
    ]);
  };

  const removeAgent = (index: number) => {
    setFieldValue('supplierAgents', [
      ...values.supplierAgents.slice(0, index),
      ...values.supplierAgents.slice(index + 1),
    ]);
  };

  return (
    <div css={[tableStyles.row, style.editRow]}>
      <FormicForm>
        {values.supplierAgents.length > 0 ? (
          values.supplierAgents.map((stateItem: ISupplierAgentLink, index: number, array: ISupplierAgentLink[]) => {
            const optionsMapper = (option: any) => ({ id: option.id, title: option.name });
            const agents = _.map(productionSupplier.supplier.agents, optionsMapper);
            const agentsList = agents.length > 0 ? agents : [{ id: null, title: 'There are no agents' }];
            const functionsList =
              agents.length > 0
                ? _.map(supplierFunctions, optionsMapper)
                : [{ id: null, title: `There are no ${LABELS.functions}` }];
            const selectedFunction = _.find(supplierFunctions, { id: stateItem.function });
            const selectedAgent = _.find(productionSupplier.supplier.agents, { id: stateItem.agent });

            return (
              <div key={`${productionSupplier.id}-${index}`} css={tableStyles.innerRow}>
                <div css={tableStyles.cell('150px')}>{!index && productionSupplier.supplier.name}</div>
                <div
                  css={[
                    tableStyles.cellSelect('120px'),
                    { display: 'flex', overflow: 'visible', paddingBottom: '15px' },
                  ]}
                >
                  <div css={style.selectWrap}>
                    <Select
                      items={agentsList}
                      onChange={onAgentChange(index, 'agent')}
                      selected={_.get(selectedAgent, 'id') || null}
                      unselectedMsg="Select agent"
                    >
                      {(item: any, selected: boolean) => {
                        return <Option key={item.id} title={item.title} selected={selected} />;
                      }}
                    </Select>
                    <FormError name={`supplierAgents[${index}].agent`} errors={errors} touched={touched} />
                  </div>
                </div>
                <div
                  css={[
                    tableStyles.cellSelect('150px'),
                    { display: 'flex', overflow: 'visible', paddingBottom: '15px' },
                  ]}
                >
                  <div css={style.selectWrap}>
                    <Select
                      items={functionsList}
                      onChange={onAgentChange(index, 'function')}
                      selected={_.get(selectedFunction, 'id') || null}
                      unselectedMsg={`Select ${LABELS.function}`}
                    >
                      {(item: any, selected: boolean) => {
                        return <Option key={item.id} title={item.title} selected={selected} />;
                      }}
                    </Select>
                    <FormError name={`supplierAgents[${index}].function`} errors={errors} touched={touched} />
                  </div>
                  {array.length && (
                    <IconBtn
                      icon={cancelIcon}
                      iconSize="14px"
                      css={style.removeLineBtn}
                      onClick={() => removeAgent(index)}
                    />
                  )}
                </div>

                <div css={tableStyles.cell('30px')}>{_.get(selectedAgent, 'phone', '-')}</div>
                <div css={tableStyles.cell('30px')}>{_.get(selectedAgent, 'email', '-')}</div>
                <div css={tableStyles.cellSelect('150px')}>
                  <div css={style.selectWrap}>
                    <MultiselectModal
                      items={producers.filter((item) => item.isActive)}
                      selected={values.producers}
                      scheme={{ id: 'id', title: 'name' }}
                      onChange={(value) => {
                        setFieldValue('producers', value);
                      }}
                    />
                    <FormError name="producers" errors={errors} touched={touched} />
                  </div>
                </div>
                <div css={tableStyles.cell('50px')}>
                  {!index && (
                    <div>
                      <IconBtn icon={applyIcon} iconSize="20px" onClick={submitForm} />
                      <IconBtn icon={cancelIcon} iconSize="16px" onClick={cancelEdit} />
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div css={tableStyles.innerRow}>
            <div css={tableStyles.cell('150px')}>{productionSupplier.supplier.name}</div>
            <div css={tableStyles.cell('150px')}>-</div>
            <div css={tableStyles.cell('150px')}>-</div>
            <div css={tableStyles.cell('100px')}>-</div>
            <div css={tableStyles.cell('100px')}>-</div>
            <div css={tableStyles.cell('150px')}>-</div>
            <div css={tableStyles.cell('150px')}>-</div>
            <div css={tableStyles.cell('50px')}>
              <IconBtn icon={applyIcon} iconSize="20px" onClick={submitForm} />
              <IconBtn icon={cancelIcon} iconSize="16px" onClick={cancelEdit} />
            </div>
          </div>
        )}
      </FormicForm>
      <div css={tableStyles.innerRow}>
        <div css={tableStyles.cell('200px')} />
        <div css={[tableStyles.cell('150px'), { paddingTop: '5px' }]}>
          <span css={style.addAgentBtn} onClick={addAgentRow}>
            + add supplier agent
          </span>
        </div>
        <div css={tableStyles.cell('150px')} />
        <div css={tableStyles.cell('100px')} />
        <div css={tableStyles.cell('100px')} />
        <div css={tableStyles.cell('50px')} />
      </div>
    </div>
  );
};

const formikHoc = withFormik<IProps & FormProps, FormValues>({
  mapPropsToValues: (props: IProps & FormProps) => {
    const { agents, producers } = props.productionSupplier;
    return {
      supplierAgents: !!agents.length
        ? agents.map(({ agent, function: func }) => ({
            agent: agent.id,
            function: func.id,
          }))
        : [{ agent: null, function: null }],
      producers: !!producers.length ? producers.map((item) => item.producer.id) : [],
    };
  },
  validationSchema: yup.object({}).shape({
    supplierAgents: yup.array(
      yup.object().shape({
        agent: yup
          .number()
          .nullable(true)
          .test('agentTest', `Required, if you select ${LABELS.function}`, function test(value) {
            return !(typeof this.parent.function === 'number' && typeof value !== 'number');
          }),
        function: yup
          .number()
          .nullable(true)
          .test('functionTest', 'Required, if you select agent', function test(value) {
            return !(typeof this.parent.agent === 'number' && typeof value !== 'number');
          }),
      })
    ),
  }),
  handleSubmit: (values: FormValues, { props: { productionSupplier, updateSupplier, cancelEdit } }) => {
    updateSupplier(productionSupplier, values);
    cancelEdit();
  },
  displayName: 'EditProductionSupplierForm',
});

const style = {
  editRow: css`
    padding: 10px 0;
  `,
  selectWrap: css`
    position: relative;
    width: 100%;
    min-width: 150px;
    max-width: 250px;
  `,
  addAgentBtn: css`
    color: ${Colors.DarkGreen};
    padding: 0 13px;
    cursor: pointer;
  `,
  removeLineBtn: css`
    margin-left: 15px;
    align-self: flex-end;
  `,
};

export default formikHoc(ProductionSupplierEditRow);
