import { createAction } from 'redux-actions';
import { Action } from '../../../../../store/types';
import { createAsyncAction, createPromisifyAsyncAction } from '../../../../../utils/async/actions';
import reducersRegister from '../../../../../utils/reducersRegister';
import sagasRegister from '../../../../../utils/sagasRegister';
import reducers from './reducers';
import sagas from './sagas';

reducersRegister.register('generalInfo', reducers);
sagasRegister.register(sagas);

/******************************************************************************/
/****************************** GET GENERAL INFO ******************************/
/******************************************************************************/

export const GET_GENERAL_INFO_REQ: string = 'GET_GENERAL_INFO_REQ';
export const GET_GENERAL_INFO_REQ_SUCCESS: string = 'GET_GENERAL_INFO_REQ_SUCCESS';
export const GET_GENERAL_INFO_REQ_ERROR: string = 'GET_GENERAL_INFO_REQ_ERROR';

export const SET_GENERAL_INFO: string = 'SET_GENERAL_INFO';

export const getGeneralInfoReq: Action<{}> = createAsyncAction(GET_GENERAL_INFO_REQ);
export const getGeneralInfoReqSuccess: Action<{}> = createAction(GET_GENERAL_INFO_REQ_SUCCESS);
export const getGeneralInfoReqError: Action<{}> = createAction(GET_GENERAL_INFO_REQ_ERROR);

export const setGeneralInfo: Action<{}> = createAction(SET_GENERAL_INFO);

/******************************************************************************/
/******************************* UPDATE GENERAL INFO **************************/
/******************************************************************************/

export const UPDATE_GENERAL_INFO_REQ: string = 'UPDATE_GENERAL_INFO_REQ';
export const UPDATE_GENERAL_INFO_REQ_SUCCESS: string = 'UPDATE_GENERAL_INFO_REQ_SUCCESS';
export const UPDATE_GENERAL_INFO_REQ_ERROR: string = 'UPDATE_GENERAL_INFO_REQ_ERROR';

export const updateGeneralInfoReq: Action<{}> = createPromisifyAsyncAction(UPDATE_GENERAL_INFO_REQ);
export const updateGeneralInfoReqSuccess: Action<{}> = createAction(UPDATE_GENERAL_INFO_REQ_SUCCESS);
export const updateGeneralInfoReqError: Action<{}> = createAction(UPDATE_GENERAL_INFO_REQ_ERROR);
