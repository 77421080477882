/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as styles from './styles';
import { withProps } from 'recompose';
import { BaseBtn, IBaseBtnProps } from './BaseBtn';

const update: Partial<IBaseBtnProps> = {
  btnStyle: styles.actions,
};

jsx;
const ActionsBtn = withProps(
  (props: IBaseBtnProps): IBaseBtnProps => {
    return { ...props, ...update };
  }
)(BaseBtn);

export default ActionsBtn;
