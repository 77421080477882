/** @jsx jsx */
import { jsx } from '@emotion/core';
import { compose, lifecycle } from 'recompose';
import { RouteComponentProps } from 'react-router';
import { FunctionComponent, ReactElement } from 'react';

import { connect } from '../../../../utils/redux';

import AsyncData from '../../../common/async/AsyncData';

import { getProductionReq, clearProduction } from './store/actions';
import { Action } from '../../../../store/types';
import { production } from './store/selectors';

import EditForm from './EditForm';

import { ProductionItemState } from './store/types';

interface MatchParams {
  id?: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  getProductionReq: Action<{}>;
  clearProduction: Action<{}>;
  production: ProductionItemState;
}

jsx;

const withLifecycle = lifecycle<IProps, {}>({
  componentDidMount() {
    const { clearProduction, getProductionReq, match } = this.props;
    clearProduction();
    getProductionReq(match.params.id);
  },
});

const Edit = (props: IProps) => {
  const form = props.production ? <EditForm {...props} /> : <div>Loading...</div>;
  return <div>{form}</div>;
};

interface FormProps {
  initialEmail?: string;
}

export default compose<IProps, {}>(
  connect(
    { production },
    { getProductionReq, clearProduction }
  ),
  withLifecycle
)(Edit);
