/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import _ from 'lodash';
import * as styles from './styles';

const COLL_WIDTH = 25;

export interface IProps {
  width?: 1 | 2 | 3 | 4;
}

jsx;

export const FieldSet: FunctionComponent<IProps> = ({ children, width = 1 }): ReactElement<IProps> => {
  return <div css={styles.fieldsSet(width * COLL_WIDTH)}>{children}</div>;
};

export default FieldSet;
