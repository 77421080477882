import { put, call, takeEvery, takeLatest, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as actions from './actions';
// utils
import _ from 'lodash';
import { getResponseMessage } from '../../../../../utils/response';
// other
import { toast } from 'react-toastify';
import routes from '../../../../../routes';

export default function* watchReducersAsync() {
  yield takeLatest(actions.GET_SUPPLIERS_REQ, getSuppliersAsync);
  yield takeEvery(actions.GET_SUPPLIERS_FOR_SELECT_REQ, getSuppliersForSelectAsync);
  yield takeEvery(actions.GET_SUPPLIERS_FILTERED_REQ, getSuppliersFilteredAsync);
  yield takeEvery(actions.CREATE_SUPPLIER_REQ_FROM_TABS, createSuppliersFromTabsAsync);
  yield takeEvery(actions.CREATE_SUPPLIER_REQ, createSuppliersAsync);
  yield takeEvery(actions.UPDATE_SUPPLIER_REQ, updateSupplierAsync);
  yield takeEvery(actions.DELETE_SUPPLIER_REQ, deleteSupplierAsync);
  yield takeEvery(actions.GET_SUPPLIER_REQ, getSupplierAsync);
  yield takeEvery(actions.UPDATE_SUPPLIER_IN_TAB_REQ, updateSupplierInTabAsync);
  yield takeEvery(actions.UPDATE_SUPPLIERS_REQ, updateSuppliersAsync);
}

// GET LIST
export function* getSuppliersAsync({ api, payload }: any) {
  try {
    const path = '/suppliers';
    const params = { size: 20, page: 0, ...payload };
    const response = yield call(api.get, path, { params: _.pickBy(params, _.identity) });
    const { data, meta } = response.data;
    yield put(actions.getSuppliersReqSuccess({ data, params }, meta));
    // yield put(actions.setSuppliers(response.data));
  } catch (err) {
    yield put(actions.getSuppliersReqError(err));
  }
}

// GET LIST FOR SELECT
export function* getSuppliersForSelectAsync({ api, payload }: any) {
  try {
    const path = '/suppliers';
    const response = yield call(api.get, path, { params: { ...payload, size: 1000 } });
    yield put(actions.getSuppliersForSelectReqSuccess());
    yield put(actions.setSuppliersForSelect(response.data));
  } catch (err) {
    yield put(actions.getSuppliersForSelectReqError(err));
  }
}

// GET FILTERED LIST
export function* getSuppliersFilteredAsync({ api, payload }: any) {
  try {
    const path = '/suppliers';
    const response = yield call(api.get, path, { params: { ...payload, size: 1000 } });
    yield put(actions.getSuppliersFilteredReqSuccess());
    yield put(actions.setSuppliersFiltered(response.data));
  } catch (err) {
    yield put(actions.getSuppliersFilteredReqError(err));
  }
}

// CREATE ITEM
export function* createSuppliersAsync({ api, payload }: any) {
  try {
    const response = yield call(api.post, '/suppliers', payload);
    const supplier = _.get(response, 'data.data[0]');

    // removed this code because the list may be empty
    // yield put(actions.createSupplierReqSuccess(supplier));

    yield put(push(routes.Suppliers));
    //yield call([toast, 'success'], 'Supplier successfully created!');
  } catch (err) {
    yield put(actions.createSupplierReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
export function* createSuppliersFromTabsAsync({ api, payload }: any) {
  try {
    const response = yield call(api.post, '/suppliers', payload.data);
    yield put(actions.getSuppliersForSelectReq({ size: 1000 }));
    if (payload.cb)
      payload.cb(() => {
        return _.get(response, 'data.data[0]');
      });
  } catch (err) {
    yield put(actions.createSupplierReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

// GET ITEM
export function* getSupplierAsync({ api, payload }: any) {
  try {
    const path = `/suppliers/${payload}`;
    const response = yield call(api.get, path);
    yield put(actions.getSupplierReqSuccess({}));
    yield put(actions.setSupplier(response.data));
  } catch (err) {
    yield put(actions.getSupplierReqError(err));
  }
}

// UPDATE ITEM
export function* updateSupplierAsync({ api, payload }: any) {
  const data = payload;
  try {
    const path = `/suppliers/${data.id}`;
    const response = yield call(api.post, path, data);
    const supplier = response.data.data[0];
    yield put(actions.updateSupplierReqSuccess());

    // removed the call of this action because the list may be empty
    // yield put(actions.updateSupplierInList(supplier));

    yield put(push(routes.Suppliers));
    //yield call([toast, 'success'], 'Supplier successfully updated!');
  } catch (err) {
    yield put(actions.updateSupplierReqError());
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
// UPDATE ITEM IN TAB
export function* updateSupplierInTabAsync({ api, payload }: any) {
  const data = payload;
  try {
    const path = `/suppliers/${data.id}`;
    yield call(api.post, path, data);
    yield put(actions.updateSupplierReqSuccess());
  } catch (err) {
    yield put(actions.updateSupplierReqError());
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

// DELETE ITEM
export function* deleteSupplierAsync({ api, payload }: any) {
  try {
    const path = `/suppliers/${payload}`;
    yield call(api.delete, path);
    yield put(actions.deleteSupplierReqSuccess({ id: +payload }));
    //yield call([toast, 'info'], 'Supplier successfully deleted!');
  } catch (err) {
    yield put(actions.deleteSupplierReqError());
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
// UPDATE FUNCTIONS FOR ONE SUPPLIER
export function* updateSuppliersAsync({ api, payload }: any) {
  try {
    const { supplierId, newFunctions } = payload;
    const path = `/suppliers/${supplierId}`;
    const response = yield call(api.get, path);
    const { id, name, functions: prevFunc } = response.data.data[0];
    const prevFuncArr = prevFunc.map((f: any) => f.id);
    const uniqNewFunctions = newFunctions.filter((f: any) => !prevFuncArr.includes(f));
    const functions = [...prevFuncArr, ...uniqNewFunctions];
    yield call(api.post, path, { id, name, functions });
    const responseForAll = yield call(api.get, '/suppliers', { params: { size: 1000 } });
    yield put(actions.getSuppliersForSelectReqSuccess());
    yield put(actions.setSuppliersForSelect(responseForAll.data));
  } catch (err) {
    yield put(actions.updateSupplierReqError());
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
