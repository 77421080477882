/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, Component } from 'react';
// redux
import { compose } from 'recompose';
import { connect } from '../../../../utils/redux';
import { getClientsReq, deleteClientReq } from './store/actions';
import { clients, clientsLoading, clientsParams, clientsMeta } from './store/selectors';
import { Action } from '../../../../store/types';
import { IClient } from './store/types';
import { IListParams, IMeta } from './store/reducers';
// components
import { Link as RouteLink } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import Loader from '../../../common/loader/Loader';
// grid
import GRID from '../../../common/grid';
import GRIDActions from '../../../common/grid/actions/index';
import GRIDToolbarActions from '../../../common/grid/toolbarActions';
const { Actions, ActionList, DeleteAction, EditAction, ToolBar, Footer, Grid, styles } = GRID;
const { Link } = GRIDActions;
const { Search } = GRIDToolbarActions;
// utils
import _ from 'lodash';
import { push } from 'connected-react-router';
// other
import routes from '../../../../routes';
import withAcl, { IWrappedProps } from '../withAcl';

interface IProps extends IWrappedProps {
  clients: IClient[];
  clientsLoading: boolean;
  getClientsReq: Action<{}>;
  deleteClientReq: Action<{}>;
  push: Action<{}>;
  clientsParams: IListParams;
  clientsMeta: IMeta;
  setClientsPage: Action<{}>;
}

interface IState {}

jsx;
export class ClientsList extends Component<IProps, IState> {
  componentDidMount() {
    const { getClientsReq } = this.props;
    getClientsReq({ page: 0, size: 20 });
  }

  loadMoreClients = () => {
    const { getClientsReq, clientsParams } = this.props;
    const page = _.get(clientsParams, 'page') || 0;
    getClientsReq({
      ...clientsParams,
      page: page + 1,
    });
  };

  onWaypointEnter = () => {
    if (this.props.clientsLoading) return;
    this.loadMoreClients();
  };

  onSearchChange = (search: string = '') => {
    const { getClientsReq, clientsParams } = this.props;
    getClientsReq({
      ...clientsParams,
      page: 0,
      search,
      searchKey: 'name',
    });
  };

  render() {
    const { clients, clientsMeta, clientsLoading, deleteClientReq, test, push } = this.props;
    const total = _.get(clientsMeta, 'total') || 0;
    const hasMoreClients = total > clients.length;

    return (
      <div css={styles.gridBox}>
        <ToolBar
          title="Clients"
          actionsLeft={<Search title={'Search by name'} onChange={this.onSearchChange} />}
          actionsRight={
            <Fragment>
              {test('client', 'C') && (
                <RouteLink to={routes.ClientsCreate}>
                  <PrimaryBtn title="Create client" />
                </RouteLink>
              )}
            </Fragment>
          }
        />
        <Grid
          onRowDoubleClick={(e: any, { id }: any) => {
            if (test('clientAgent', 'R')) {
              push(routes.ClientAgents.replace(':owner_id', `${id}`));
            }
          }}
          shema={[{ title: 'Name', path: 'name' }]}
          data={clients}
          actions={({ id }) => (
            <Actions>
              {test('client', 'U') && (
                <RouteLink to={routes.ClientsEdit.replace(':id', `${id}`)}>
                  <EditAction />
                </RouteLink>
              )}
              {test('client', 'D') && (
                <DeleteAction
                  onConfirm={(e) => {
                    deleteClientReq(id);
                  }}
                />
              )}

              {test('clientAgent', 'R') && (
                <ActionList>
                  <Link to={routes.ClientAgents.replace(':owner_id', `${id}`)} title="View agents" />
                </ActionList>
              )}
            </Actions>
          )}
        />
        <Footer>
          <Loader isLoading={clientsLoading} />
        </Footer>
        {hasMoreClients && !clientsLoading && <Waypoint onEnter={this.onWaypointEnter} />}
      </div>
    );
  }
}

export default compose<IProps, {}>(
  connect(
    { clients, clientsLoading, clientsParams, clientsMeta },
    { getClientsReq, deleteClientReq, push }
  ),
  withAcl
)(ClientsList);
