/** @jsx jsx */
import { css } from '@emotion/core';
import * as styles from '../../../../styles/styles';

export const mainBox = css({});

export const infoBox = css({
  padding: '0 30px 30px 30px',
});

export const blockBox = css({
  paddingTop: '40px',
});

export const blockTitle = [styles.H2, css({})];

export const label = [
  styles.H2,
  css({
    fontSize: '17px',
    color: styles.Colors.RegularBlack,
  }),
];

export const preContent = styles.MainText;

export const blockContent = [
  styles.MainText,
  css({
    marginTop: '10px',
  }),
];

export const btnBox = css({
  display: 'flex',
  paddingRight: '10px',
  justifyContent: 'flex-end',
  '& button': {
    marginLeft: '20px',
  },
});

export const filesListBox = css({
  display: 'flex',
  flexWrap: 'wrap',
  paddingRight: '40px',
});

export const iconWrapper = css({
  margin: '0  40px 40px 0',
  boxSizing: 'border-box',
});

export const infoFilesListBox = [
  filesListBox,
  css({
    marginTop: '50px',
  }),
];

export const xxx = css({});
