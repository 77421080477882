/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Fragment } from 'react';
import { withFormik, FormikProps } from 'formik';
import * as yup from 'yup';
import { compose } from 'recompose';

import { connect } from '../../../../utils/redux';
import { isNumbersArray } from '../../../../utils/typeGuards';

import { updateSupplierReq } from './store/actions';
import { Action } from '../../../../store/types';
import * as validatinShemas from '../../../../utils/validatinShemas';

import {} from './store/selectors';

import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../common/buttons/OutlineBtn';
import form from '../../../common/form';
import MultiSelectArea from '../../../common/form/multiselect/MultiSelectArea';
import page from '../../../common/page';
import routes from '../../../../routes';

import { Link } from 'react-router-dom';
import { SupplierItemState } from './store/types';
import withVisualContext from '../../../../contexts/withVisualContext';
import { IVisualContext } from '../../../../contexts/VisualContext';

interface FormValues {
  id: number;
  name: string;
  functions: number[];
}

interface OtherProps {
  updateSupplierReq: Action<{}>;
  supplier: SupplierItemState;
  functions: number[];
  visual: IVisualContext;
}

interface FormProps {}

jsx;

const EditForm = ({
  errors,
  touched,
  values,
  setFieldValue,
  functions,
  visual,
}: OtherProps & FormikProps<FormValues>) => {
  const { FormRow, FormField, FormError, FormBlock, FieldsSet, ActionsRow, Form, FieldsArea } = form;
  const { Page, Breadcrumbs, PageHeader } = page;

  return (
    <Fragment>
      <PageHeader title="Edit supplier">
        <Breadcrumbs
          path={[
            { title: 'Suppliers', link: routes.Suppliers },
            { title: 'Edit supplier', link: routes.SuppliersCreate },
          ]}
        />
      </PageHeader>
      <Page>
        <Form>
          <div>
            <FieldsArea>
              <FormBlock>
                <FieldsSet>
                  <FormRow>
                    <label htmlFor="name">Name</label>
                    <FormField name="name" placeholder="name" />
                    <FormError name="name" errors={errors} touched={touched} />
                  </FormRow>

                  <FormRow>
                    <label htmlFor="functions">{visual.labels.Functions}</label>
                    <MultiSelectArea
                      items={functions}
                      selected={values.functions}
                      scheme={{ id: 'id', title: 'name' }}
                      onChange={(values) => {
                        setFieldValue('functions', values);
                      }}
                    />
                    <FormError name="functions" errors={errors} touched={touched} />
                  </FormRow>
                </FieldsSet>
              </FormBlock>
            </FieldsArea>

            <ActionsRow>
              <Link to={routes.Suppliers}>
                <OutlineBtn title="Cancel" isWide={false} />
              </Link>
              <PrimaryBtn title="Update supplier" isWide={false} />
            </ActionsRow>
          </div>
        </Form>
      </Page>
    </Fragment>
  );
};

export const exchangeFormic = withFormik<FormProps & OtherProps, FormValues>({
  mapPropsToValues: ({ supplier }) => {
    const { id, name, functions = [] } = supplier.data;
    const fns = functions.map((item) => item.id);
    return {
      id,
      name,
      functions: isNumbersArray(fns) ? fns : [],
    };
  },

  validationSchema: yup.object({}).shape({
    name: validatinShemas.vNameReq,
    functions: validatinShemas.vIdListReq,
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.updateSupplierReq(values);
  },
  displayName: 'EditForm',
});

export default compose<OtherProps & FormikProps<FormValues>, {}>(
  connect(
    {},
    { updateSupplierReq }
  ),
  exchangeFormic,
  withVisualContext
)(EditForm);
