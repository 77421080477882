/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { PureComponent } from 'react';
// utils
import _ from 'lodash';
// other
import { Colors } from '../../../../../../../styles/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
//component
import TimelineValue from './TimelineValue';
import DropTarget from './dropTarget';

// scrolling
const { ScrollSyncPane } = require('react-scroll-sync');

const PIXEL_DAY_WIDTH = 40;

export interface IProps {
  machinery: any;
  days: any[];
  limitRange: any;
  boxRange: any;
  isEditMode: boolean;
  timeRound?: number;
  onChange?: (id: any, range: any) => void;
  onClick?: (e: any) => void;
  onAddUser?: (user: any, previousParent: any) => void;
  onRemoveUser?: (user: any) => void;
  connectDropTarget: any;
  isOverCurrent?: boolean;
}

export interface IState {}

jsx;
class ScrollableTimeline extends PureComponent<IProps, IState> {
  render() {
    const {
      machinery,
      days,
      isEditMode,
      limitRange,
      boxRange,
      onChange = _.identity,
      timeRound = 30,
      connectDropTarget,
      isOverCurrent = false,
    } = this.props;

    const timelineWidth = days.length * PIXEL_DAY_WIDTH;

    return connectDropTarget(
      <div className="timeline" style={{ width: '100%', height: '100%' }}>
        <div css={style.wrapper(isOverCurrent)}>
          <ScrollSyncPane>
            <PerfectScrollbar>
              <div css={style.timelineGrid(timelineWidth)}>
                {/* grid cells */}
                {days.map((date, dateIndex) => {
                  return <div key={dateIndex} css={style.dayCell} />;
                })}

                {/* value bars */}

                <TimelineValue
                  machinery={machinery}
                  users={machinery.users}
                  isEdit={isEditMode}
                  onChange={onChange}
                  boxRange={boxRange}
                  limitRange={limitRange}
                  timeRound={timeRound}
                />
              </div>
            </PerfectScrollbar>
          </ScrollSyncPane>
        </div>
      </div>
    );
  }
}

export default DropTarget(ScrollableTimeline);

const style = {
  wrapper: (isOverCurrent: boolean = false) => {
    const bgColor = isOverCurrent ? Colors.LightGreenGrey : Colors.White;

    return css`
      height: 100%;
      position: relative;
      border-left: 1px solid ${Colors.LightGreyMainBG};

      .ps__rail-x {
        opacity: 0;
      }

      &:hover {
        .ps__rail-x {
          opacity: 1;
        }
      }
      background-color: ${bgColor};
    `;
  },
  timelineBarBox: css({
    position: 'relative',
    width: '100%',
  }),
  timelineGrid: (timelineWidth: number) => css`
    position: relative;
    display: flex;
    height: 100%;
    width: ${timelineWidth}px;
  `,
  dayCell: css`
    flex: 0 0 ${PIXEL_DAY_WIDTH}px;
    border-right: 1px solid ${Colors.LightGreyMainBG};
    box-sizing: border-box;
  `,
  timelineBars: css`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  `,
  userBar: css`
    position: absolute;
    align-self: center;
    height: 20px;
  `,
};
