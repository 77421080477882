import { css } from '@emotion/core';
import { IProduction } from './store/types';
import values from 'lodash/values';
import moment from 'moment';
import arrow from '../../../../images/icons/arrow.svg';
import letterM from '../../../../images/icons/letterM.svg';
import letterA from '../../../../images/icons/letterA.svg';
import letterL from '../../../../images/icons/letterL.svg';

interface IVisibleProduction extends IProduction {
  mainProdId?: number;
  sub: IVisibleProduction[];
}
export const getProductionIds = (productions: IProduction[]) => {
  if (productions && productions.length > 0) {
    return productions.map((prod) => prod.id);
  } else return [];
};

export const getSortedProductions = (data: IProduction[], openedProductions: number[]) => {
  const visibleProductions: IVisibleProduction[] = [];
  const productionIds = getProductionIds(data);
  data.forEach((prod: IProduction) => {
    visibleProductions.push(prod);
    const addSub = (subArr: IVisibleProduction[]) => {
      [...subArr]
        .sort((p1, p2) => p1.name.localeCompare(p2.name))
        .forEach((sub: IVisibleProduction) => {
          //   if (productionIds.includes(sub.id)) {
          visibleProductions.push({ ...sub, mainProdId: prod.id });
          if (sub.sub && sub.sub.length > 0 && openedProductions.includes(sub.id)) {
            [...sub.sub]
              .sort((p1, p2) => p1.name.localeCompare(p2.name))
              .forEach((subSub: any) => {
                visibleProductions.push({ ...subSub, mainProdId: sub.id });
              });
          }
          //   }
        });
    };
    if (prod.sub && prod.sub.length > 0 && openedProductions.includes(prod.id)) {
      addSub(prod.sub);
    }
  });
  return visibleProductions;
};

export const getUsersProductions = (data: IProduction[], openedProductions: number[]) => {
  const mainProductions = values(data).filter((p) => p.isMain);
  const productions = getSortedProductions(mainProductions, openedProductions);
  const visibleProductionIds = productions.map((prod) => prod.id);
  const productionIds = getProductionIds(data);
  const notIncludedSubProductions = values(data).filter(
    (prod) => !prod.isMain && prod.canHaveSub && !visibleProductionIds.includes(prod.main.id)
  );
  const addedSubProductions: IVisibleProduction[] = [];
  const addSubProductions = (data: IProduction[]) =>
    data.forEach((prod: IProduction) => {
      addedSubProductions.push(prod);
      const addSub = (subArr: IVisibleProduction[]) =>
        [...subArr]
          .sort((p1, p2) => p1.name.localeCompare(p2.name))
          .forEach((sub: IVisibleProduction) => {
            // if (productionIds.includes(sub.id)) {
            addedSubProductions.push({ ...sub, mainProdId: prod.id });
            // }
          });
      if (prod.sub && prod.sub.length > 0 && openedProductions.includes(prod.id)) {
        addSub(prod.sub);
      }
    });
  addSubProductions(notIncludedSubProductions);
  const addedSubProductionIds = addedSubProductions.map((prod) => prod.id);
  const notIncludedSubSubProductions = values(data).filter(
    (prod) =>
      !prod.isMain &&
      !prod.canHaveSub &&
      !visibleProductionIds.includes(prod.main.id) &&
      !addedSubProductionIds.includes(prod.id) &&
      !addedSubProductionIds.includes(prod.main.id)
  );
  const allUsersProductions = [...productions, ...addedSubProductions, ...notIncludedSubSubProductions];
  return allUsersProductions;
};

export const calculateRowStyle = ({
  production,
  openedProductions,
  allProductions,
}: {
  production: IProduction;
  openedProductions: number[];
  allProductions: IProduction[];
}) => {
  const productionIds = getProductionIds(allProductions);
  if (!(production.sub && production.sub.length > 0)) {
    if (production.isMain) return style.mainCell;
    if (production.canHaveSub) return style.subCell;
    return style.subSubCell;
  } else if (openedProductions.includes(production.id)) {
    return production.isMain
      ? [style.mainCell, style.nestedNameCell(true)]
      : [style.subCell, style.nestedNameCell(true)];
  }
  return production.isMain
    ? [style.mainCell, style.nestedNameCell(false)]
    : [style.subCell, style.nestedNameCell(false)];
};

export const showtimeRangesRenderer = (item: any) => {
  return Object.values(item.shows)
    .map((show: any) =>
      [show.range.from, show.range.to].map((date: string) => ({
        dayOfYear: +moment(date).format('DDD'),
        dayOfMonth: +moment(date).format('D'),
        month: moment(date).format('MMM'),
        date: +new Date(date),
      }))
    )
    .sort((a: any, b: any) => a[0].date - b[0].date)
    .reduce((acc: any, [from, to]: any) => {
      if (
        acc &&
        acc.length > 0 &&
        acc[acc.length - 1] &&
        acc[acc.length - 1][1] &&
        acc[acc.length - 1][1].dayOfYear - from.dayOfYear < 2
      ) {
        const position = acc.length - 1;
        acc.splice(position, 1, [acc[acc.length - 1][0], to]);
        return acc;
      }
      return [...acc, [from, to]];
    }, [])
    .map(([from, to]: any) => {
      if (to.dayOfYear - from.dayOfYear < 2) return `${from.month} ${from.dayOfMonth}`;
      if (to.month === from.month) return `${from.month} ${from.dayOfMonth}-${to.dayOfMonth}`;
      return `${from.month} ${from.dayOfMonth} - ${to.month} ${to.dayOfMonth}`;
    })
    .join(', ');
};

const style = {
  nameCell: css`
    cursor: pointer;
    padding-left: 25px;
    display: block;
    margin-left: 20px;
    position: relative;
  `,
  mainCell: css({
    marginLeft: '-30px',
    paddingLeft: '35px',
    position: 'relative',
    '&::before': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '26px',
      height: '26px',
      opacity: 1,
      backgroundImage: `url(${letterM})`,
      backgroundRepeat: 'no-repeat',
    },
  }),
  subSubCell: css({
    marginLeft: '30px',
    paddingLeft: '35px',
    position: 'relative',
    '&::before': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '26px',
      height: '26px',
      opacity: 1,
      backgroundImage: `url(${letterL})`,
      backgroundRepeat: 'no-repeat',
    },
  }),
  subCell: css({
    paddingLeft: '35px',
    position: 'relative',
    '&::before': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '26px',
      height: '26px',
      opacity: 1,
      backgroundImage: `url(${letterA})`,
      backgroundRepeat: 'no-repeat',
    },
  }),
  nestedNameCell: (isOpen: boolean = false) =>
    css({
      position: 'relative',
      '&::after': {
        content: '" "',
        display: 'block',
        position: 'absolute',
        top: isOpen ? '6px' : '8px',
        bottom: 0,
        left: '-22px',
        width: '10px',
        height: '6px',
        opacity: 1,
        backgroundImage: `url(${arrow})`,
        transform: isOpen ? 'rotateX(180deg)' : '',
        backgroundRepeat: 'no-repeat',
      },
    }),
};
