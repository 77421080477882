import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
// actions
import * as actions from './actions';
// utils
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getResponseMessage } from '../../../../../../utils/response';
import routes from '../../../../../../routes';
import { error } from 'util';

export default function* watchReducersAsync() {
  yield takeLatest(actions.GET_SUPPLIER_AGENTS_REQ, getSupplierAgentsAsync);
  yield takeEvery(actions.CREATE_SUPPLIER_AGENT_REQ, createSupplierAgentAsync);
  yield takeEvery(actions.UPDATE_SUPPLIER_AGENT_REQ, updateSupplierAgentAsync);
  yield takeEvery(actions.DELETE_SUPPLIER_AGENT_REQ, deleteSupplierAgentAsync);
  yield takeEvery(actions.GET_SUPPLIER_AGENT_REQ, getSupplierAgentAsync);
  yield takeEvery(actions.SEND_SUPPLIER_AGENT_INVITATION_REQ, sendSupplierAgentInvitationAsync);
}

// GET LIST
export function* getSupplierAgentsAsync({ api, payload }: any) {
  const params = { size: 20, page: 0, ...payload };
  if (params.ownerId) {
    try {
      const path = `/suppliers/${params.ownerId}/agents`;
      const response = yield call(api.get, path, { params });
      const data = _.get(response, 'data.data');
      const meta = _.get(response, 'data.meta');
      yield put(actions.getSupplierAgentsReqSuccess({ data, params }, meta));
    } catch (err) {
      yield put(actions.getSupplierAgentsReqError(err));
    }
  }
}

// CREATE ITEM
export function* createSupplierAgentAsync({ api, payload: { ownerId, values } }: any) {
  try {
    const path = `/suppliers/${ownerId}/agents`;
    const response = yield call(api.post, path, values);
    yield put(push(routes.SupplierAgents.replace(':owner_id', ownerId)));
    //yield call([toast, 'success'], 'Agent succesfully created.');
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

// GET ITEM
export function* getSupplierAgentAsync({ api, payload }: any) {
  try {
    const path = `/suppliers/${payload.owner_id}/agents/${payload.id}`;
    const response = yield call(api.get, path);
    yield put(actions.getSupplierAgentReqSuccess({}));
    yield put(actions.setSupplierAgent(response.data.data));
  } catch (err) {}
}

// UPDATE ITEM
export function* updateSupplierAgentAsync({ api, payload: { values, ownerId, id } }: any) {
  try {
    const path = `/suppliers/${ownerId}/agents/${id}`;
    const response = yield call(api.post, path, values);
    yield put(actions.getSupplierAgentReqSuccess({}));
    yield put(actions.setSupplierAgent(response.data.data));
    yield put(push(routes.SupplierAgents.replace(':owner_id', ownerId)));
    //yield call([toast, 'success'], 'Agent succesfully updated.');
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

// DELETE ITEM
export function* deleteSupplierAgentAsync({ api, payload: { id, ownerId } }: any) {
  try {
    const path = `/suppliers/${ownerId}/agents/${id}`;
    const response = yield call(api.delete, path);
    const supplier = _.get(response, 'data.data[0]');
    yield put(actions.deleteSupplierAgentReqSuccess(supplier));
    //yield call([toast, 'info'], 'Agent succesfully deleted.');
  } catch (err) {
    yield put(actions.deleteSupplierAgentReqSuccess(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

// INVITATION
export function* sendSupplierAgentInvitationAsync({ api, payload: { ownerId, id } }: any) {
  try {
    const path = `/suppliers/${ownerId}/agents/${id}/invitations`;
    const response = yield call(api.post, path);
    yield put(actions.sendSupplierAgentInvitationSuccess());
    //yield call([toast, 'info'], 'Invitation successfully sent!');
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
