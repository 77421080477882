/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Component, Fragment, forwardRef } from 'react';
import Datepicker, { IDatepickerValue } from './Datepicker';
import form from '../index';

export interface IDataRangeValue {
  from: IDatepickerValue;
  to: IDatepickerValue;
}

export interface IDataRangeProps {
  value: IDataRangeValue;
  fromLabel: string;
  toLabel: string;
  onChange: (a: IDataRangeValue) => void;
  collSize?: 1 | 2 | 3 | 4;
  disabledFrom?: boolean;
  disabledTo?: boolean;
  forwardRef?: any;
  shoudSetDefaultValues?: boolean;
}

export interface IState {}

jsx;
class DateRange extends Component<IDataRangeProps, IState> {
  constructor(props: IDataRangeProps) {
    super(props);

    this.state = {};
  }

  change = (oneOfValue: Partial<IDataRangeValue>) => {
    const { value, onChange } = this.props;
    onChange({ ...value, ...oneOfValue });
  };

  render() {
    const { FormRowSections } = form;
    const {
      fromLabel,
      toLabel,
      value,
      collSize = 2,
      disabledFrom = false,
      disabledTo = false,
      shoudSetDefaultValues = false,
    } = this.props;
    return (
      <Fragment>
        <FormRowSections width={collSize}>
          <label>{fromLabel}</label>
          <Datepicker
            disabled={disabledFrom}
            onChange={(value: number) => {
              this.change({ from: value });
            }}
            value={shoudSetDefaultValues ? (value.from ? value.from : new Date().getTime()) : value.from}
            closeOnSelect
            ref={this.props.forwardRef}
          />
        </FormRowSections>

        <FormRowSections width={collSize}>
          <label>{toLabel}</label>
          <Datepicker
            disabled={disabledTo}
            value={shoudSetDefaultValues ? (value.to ? value.to : new Date().getTime()) : value.to}
            onChange={(value: number) => {
              this.change({ to: value });
            }}
            closeOnSelect
          />
        </FormRowSections>
      </Fragment>
    );
  }
}

export default forwardRef<HTMLInputElement, IDataRangeProps>((props, ref) => <DateRange {...props} forwardRef={ref} />);
