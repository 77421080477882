/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import * as styles from '../../../styles/styles';
import cross from './images/cross.svg';
import arrow from './images/whiteArrow.svg';

export const btn = css({
  borderRadius: '20px',
  cursor: 'pointer',
  textAlign: 'center',
  lineHeight: '38px',
  maxHeight: '40px',
  padding: '0 25px 0 25px',
  width: 'auto',
  fontFamily: styles.FontFamilies.Main,
  transition: 'background-color .2s ease',
  ':focus': {
    outline: 0,
  },
});

export const primary = [
  btn,
  css({
    color: styles.Colors.White,
    border: `0px`,
    backgroundColor: styles.Colors.Green,
    ':hover': {
      backgroundColor: styles.Colors.DarkGreen,
    },
    ':focus': {
      backgroundColor: styles.Colors.DarkGreen,
    },
    ':disabled': {
      backgroundColor: styles.Colors.LightGreen,
    },
  }),
];

export const outline = [
  btn,
  css({
    color: styles.Colors.RegularBlack,
    border: `1px solid ${styles.Colors.Grey}`,
    backgroundColor: styles.Colors.White,
    ':hover': {
      backgroundColor: styles.Colors.AltGrey,
    },
    ':disabled': {
      backgroundColor: styles.Colors.AltGrey,
      opacity: 0.5,
    },
    ':focus': {
      backgroundColor: styles.Colors.AltGrey,
      borderColor: styles.Colors.Green,
    },
  }),
];
export const outlineGreen = [
  btn,
  css({
    color: styles.Colors.White,
    border: 'none',
    backgroundColor: styles.Colors.Green,
    ':hover': {
      backgroundColor: styles.Colors.DarkGreen,
    },
    ':disabled': {
      backgroundColor: styles.Colors.AltGrey,
      opacity: 0.5,
    },
    ':focus': {
      backgroundColor: styles.Colors.DarkGreen,
      borderColor: styles.Colors.Green,
    },
  }),
];

export const actions = [
  btn,
  css({
    background: styles.Colors.Green,
    color: 'white',
    position: 'relative',
    paddingRight: '50px',
    outline: 'none',
    border: 'none',
    '&:hover': {
      background: styles.Colors.DarkGreen,
    },
    '&:focus': {
      backgroundColor: styles.Colors.DarkGreen,
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      right: '10px',
      top: '-4px',
      bottom: 0,
      width: '36px',
      backgroundImage: `url(${arrow})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '15px',
    },
  }),
];

export const selectedBtn = (color: string = styles.Colors.LightGreenGrey) => {
  return [
    styles.MainText,
    css({
      display: 'inline-block',
      margin: '10px 10px 0 0',
      position: 'relative',
      backgroundColor: color,
      borderRadius: '6px',
      cursor: 'pointer',
      lineHeight: '30px',
      height: '30px',
      padding: '0 30px 0 7px',
      border: 0,
      width: 'auto',
      overflow: 'visible',

      ':focus': {
        backgroundColor: styles.Colors.AltGrey,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: styles.Colors.Green,
        outline: `1px solid ${styles.Colors.Green}`,
      },
      ':hover': {
        opacity: 0.5,
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '0',
        right: '0',
        width: '30px',
        height: '30px',
        backgroundImage: `url(${cross})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        transform: `scale(0.6)`,
      },
    }),
  ];
};
export const small = css({
  lineHeight: '32px',
});

export const wide = css({
  width: '100%',
  margin: 'auto',
});
