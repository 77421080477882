/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, InputHTMLAttributes, ChangeEvent, MouseEvent } from 'react';
import { Field, FieldAttributes } from 'formik';
import * as selectStyles from './styles';

jsx;

interface IProps {
  title: string;
  isOpen: boolean;
  onClick: (e: MouseEvent<HTMLElement>) => void;
  onMouseEnter: (e: MouseEvent<HTMLElement>) => void;
  onMouseLeave: (e: MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  styles?: any;
}

export const Header: FunctionComponent<IProps> = ({
  title,
  isOpen,
  onClick,
  onMouseLeave,
  onMouseEnter,
  disabled,
  styles = {},
}): ReactElement<IProps> => {
  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      css={[selectStyles.header(disabled), styles]}
    >
      <div css={selectStyles.headerText}>{title}</div>
      <div css={selectStyles.headerArrow(isOpen)} />
    </div>
  );
};

export default Header;
