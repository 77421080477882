import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
// actions
import * as actions from './actions';

// utils
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getResponseMessage } from '../../../../../utils/response';

export default function* watchMainAsync() {
  yield takeLatest(actions.GET_GENERAL_INFO_REQ, getGeneralInfoAsync);
  yield takeEvery(actions.UPDATE_GENERAL_INFO_REQ, updateGeneralInfoAsync);
}

export function* getGeneralInfoAsync({ api, payload }: any) {
  try {
    const path = '/generals';

    const response = yield call(api.get, path);

    yield put(actions.getGeneralInfoReqSuccess());
    yield put(actions.setGeneralInfo(response.data));
  } catch (e) {
    yield put(actions.getGeneralInfoReqError(e));
  }
}

export function* updateGeneralInfoAsync({ api, payload }: any) {
  try {
    const path = '/generals';
    const response = yield call(api.post, path, payload, { headers: { 'Content-Type': 'multipart/form-data' } });
    yield put(actions.setGeneralInfo(response.data));
    yield put(actions.updateGeneralInfoReqSuccess());
  } catch (err) {
    yield put(actions.updateGeneralInfoReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
