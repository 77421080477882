import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
// actions
import * as actions from './actions';
// utils
import { toast } from 'react-toastify';
import _ from 'lodash';
import { getResponseMessage } from '../../../../../../utils/response';
import routes from '../../../../../../routes';

export default function* watchReducersAsync() {
  yield takeLatest(actions.GET_CLIENT_AGENTS_REQ, getClientAgentsAsync);
  yield takeEvery(actions.CREATE_CLIENT_AGENT_REQ, createClientAgentAsync);
  yield takeEvery(actions.CREATE_CLIENT_AGENT_IN_TAB_REQ, createClientAgentInTabAsync);
  yield takeEvery(actions.UPDATE_CLIENT_AGENT_REQ, updateClientAgentAsync);
  yield takeEvery(actions.DELETE_CLIENT_AGENT_REQ, deleteClientAgentAsync);
  yield takeEvery(actions.GET_CLIENT_AGENT_REQ, getClientAgentAsync);
  yield takeEvery(actions.SEND_CLIENT_AGENT_INVITATION_REQ, sendClientAgentInvitationAsync);
}

// GET LIST
export function* getClientAgentsAsync({ api, payload }: any) {
  const params = { size: 20, page: 0, ...payload };
  if (params.ownerId) {
    try {
      const path = `/clients/${payload.ownerId}/agents`;
      const response = yield call(api.get, path, { params });
      const data = _.get(response, 'data.data');
      const meta = _.get(response, 'data.meta');
      yield put(actions.getClientAgentsReqSuccess({ data, params }, meta));
    } catch (err) {
      yield put(actions.getClientAgentsReqError(err));
    }
  }
}

// CREATE ITEM
export function* createClientAgentAsync({ api, payload: { ownerId, values } }: any) {
  try {
    const path = `/clients/${ownerId}/agents`;
    const response = yield call(api.post, path, values);
    yield put(push(routes.ClientAgents.replace(':owner_id', ownerId)));
    //yield call([toast, 'success'], 'Agent succesfully created.');
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* createClientAgentInTabAsync({ api, payload: { ownerId, values } }: any) {
  try {
    if (ownerId) {
      const path = `/clients/${ownerId}/agents`;
      const response = yield call(api.post, path, values);
      yield put(actions.addClientAgent(response.data.data));
    } else {
      yield call([toast, 'error'], 'Please select client first');
    }
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

// GET ITEM
export function* getClientAgentAsync({ api, payload }: any) {
  try {
    const path = `/clients/${payload.owner_id}/agents/${payload.id}`;
    const response = yield call(api.get, path);
    yield put(actions.getClientAgentReqSuccess({}));
    yield put(actions.setClientAgent(response.data.data));
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

// UPDATE ITEM
export function* updateClientAgentAsync({ api, payload: { values, ownerId, id } }: any) {
  try {
    const path = `/clients/${ownerId}/agents/${id}`;
    const response = yield call(api.post, path, values);
    yield put(actions.getClientAgentReqSuccess({}));
    yield put(actions.setClientAgent(response.data.data));
    yield put(push(routes.ClientAgents.replace(':owner_id', ownerId)));
    //yield call([toast, 'success'], 'Agent succesfully updated.');
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

// DELETE ITEM
export function* deleteClientAgentAsync({ api, payload: { id, ownerId } }: any) {
  try {
    const path = `/clients/${ownerId}/agents/${id}`;
    const response = yield call(api.delete, path);
    const user = response.data.data[0];
    yield put(actions.deleteClientAgentReqSuccess(user));
    //yield call([toast, 'info'], 'Agent succesfully deleted.');
  } catch (err) {
    yield put(actions.deleteClientAgentReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

// INVITATION
export function* sendClientAgentInvitationAsync({ api, payload: { ownerId, id } }: any) {
  try {
    const path = `/clients/${ownerId}/agents/${id}/invitations`;
    const response = yield call(api.post, path);
    yield put(actions.sendClientAgentInvitationSuccess());
    //yield call([toast, 'info'], 'Invitation successfully sent!');
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
