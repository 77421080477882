/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, forwardRef, createRef, KeyboardEvent, MouseEvent, ReactNode } from 'react';
import _ from 'lodash';
import PopoverBtn from '../../popover/PopoverBtn';
import SelectWithTab from './SelectWithTab';
import * as object from '../../../../utils/object';
import * as styles from './styles';
import * as inputStyles from '../../form/styles';

jsx;
export const NOT_SELECTED = 'Not selected';

export interface IScheme {
  id?: string;
  title?: string;
}

export interface IState {
  isOpen: boolean;
  isHover: boolean;
  boxWidth: number;
  tab: number;
  search: string;
}
export interface IElementToCreate {
  init: string;
  backToSelect: (e: any) => void;
  onCreateAndSelect: (name: string) => void;
}

export interface ISelectProps {
  autoHeight?: boolean;
  scheme: IScheme;
  selected: string | number | null;
  items: any[];
  noSelectedMsg: string;
  onChange?: (a: number[] | number) => void;
  onBlur?: () => void;
  onFocusLose?: () => void;
  disabled?: boolean;
  disableReposition?: boolean;
  shoudOpenOnFocus?: boolean;
  className?: string;
  styles?: any;
  tabIndex?: number;
  forwardRef?: any;
  elementToCreate?: (a: IElementToCreate) => ReactNode;
  actionCreate?: () => void;
}

interface ILocateContent {
  target: ClientRect;
  popover: ClientRect;
  nudgedTop: number;
  nudgedLeft: number;
}
export const convert = (items: any[], scheme: IScheme) => {
  return items.map((item) => {
    return object.buildData(item, scheme);
  });
};

export const getSelectedItem = (convertItem: any, id: number | string | null) => {
  return id === null ? null : _.find(convertItem, { id });
};

class Select extends Component<ISelectProps, IState> {
  private optionsRef: any;
  constructor(props: ISelectProps) {
    super(props);
    this.optionsRef = createRef();
    this.state = {
      isOpen: false,
      isHover: false,
      boxWidth: 0,
      tab: 0,
      search: '',
    };
  }

  select = (key: any) => {
    const onChange = this.props.onChange || _.identity;
    onChange(key);
  };
  handleClick = (e: MouseEvent<HTMLDivElement>, cb: any) => {
    const disabled = _.get(this.props, 'disabled', false);
    if (!disabled) {
      cb(e);
    }
  };
  handleSelect = (e: any, cb: any) => {
    this.select(e);
    cb(e);
  };
  searchFilter = (items: any[], text: string) => {
    return items.filter((item) => item.name.toLowerCase().includes(text.toLowerCase()));
  };
  handleChange = (e: any, openPopover: (e: any) => void) => {
    openPopover(e);
    this.setState({ search: e.currentTarget.value });
  };
  changeFocusToOptions = (event: KeyboardEvent<HTMLElement>) => {
    const { search } = this.state;
    const { items } = this.props;
    const searchedItems = this.searchFilter(items, search);
    if (event.key === 'Enter' || searchedItems.length > 0) {
      event.stopPropagation();
      event.preventDefault();
      if (this.optionsRef && this.optionsRef.current) {
        this.optionsRef.current.focus();
      }
    }
  };
  handleKeyPress = (e: KeyboardEvent<HTMLInputElement>, cb: any) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      cb(e);
      this.changeFocusToOptions(e);
    }
    if (e.key === 'Tab' && e.shiftKey !== true) {
      e.stopPropagation();
      e.preventDefault();
      this.props.onBlur && this.props.onBlur();
      this.props.onFocusLose && this.props.onFocusLose();
    }
  };
  locateContent = ({ target, popover, nudgedTop, nudgedLeft }: ILocateContent) => {
    {
      const pageYOffset = window.pageYOffset;
      const pageXOffset = window.pageXOffset;

      if (target.top <= 0) {
        return { top: target.top + pageYOffset, left: nudgedLeft + pageXOffset };
      }

      if (window.innerHeight - target.top <= 0) {
        return { top: target.top - popover.height + pageYOffset, left: nudgedLeft + pageXOffset };
      }

      return { top: nudgedTop + pageYOffset, left: nudgedLeft + pageXOffset };
    }
  };
  render() {
    const {
      selected,
      items,
      disableReposition,
      autoHeight = false,
      onBlur,
      onFocusLose,
      noSelectedMsg,
      shoudOpenOnFocus = false,
    } = this.props;

    const { search } = this.state;

    const resultScheme = { id: 'id', title: 'title', ...this.props.scheme };
    const title =
      _.isNumber(selected) || _.isString(selected)
        ? _.get(getSelectedItem(convert(items, resultScheme), selected), 'title')
        : noSelectedMsg;

    return (
      <PopoverBtn
        btn={(openPopover, isOpen) => (
          <div
            onClick={openPopover}
            css={styles.simpleSelectInputWrapper(isOpen)}
            onKeyPress={(e: any) => {
              this.changeFocusToOptions(e);
            }}
          >
            <div css={inputStyles.formFieldBox}>
              <input
                type="text"
                tabIndex={0}
                ref={this.props.forwardRef}
                onChange={(e) => this.handleChange(e, openPopover)}
                value={title ? title : search}
                // onClick={openPopover}
                // onFocus={shoudOpenOnFocus ? openPopover : undefined}
                onClick={(e) => {
                  setTimeout(() => {
                    e.stopPropagation();
                    e.preventDefault();
                    openPopover(e);
                  }, 1000);
                }}
                onFocus={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setTimeout(() => {
                    openPopover(e);
                  }, 200);
                }}
                css={styles.simpleSelectInput}
                onKeyDown={(e: any) => this.handleKeyPress(e, openPopover)}
              />
            </div>
          </div>
        )}
        disableReposition={disableReposition}
        autoHeight={autoHeight}
        onClose={() => {
          onBlur && onBlur();
          onFocusLose && onFocusLose();
        }}
        contentLocation={({ targetRect, popoverRect, nudgedTop, nudgedLeft }) =>
          this.locateContent({ target: targetRect, popover: popoverRect, nudgedTop, nudgedLeft })
        }
        renderContent={(closeDropdown) => (
          <div css={styles.multiSelectPopoverBox}>
            <SelectWithTab
              onApply={closeDropdown}
              onPressEscape={(e: any) => {
                closeDropdown(e);
                onFocusLose && onFocusLose();
              }}
              itemsToDisplay={items}
              selected={selected}
              resultScheme={resultScheme}
              handleSelect={(e: any) => this.handleSelect(e, closeDropdown)}
              closeDropdown={closeDropdown}
              search={search}
              ref={this.optionsRef}
              {...this.props}
            />
          </div>
        )}
      />
    );
  }
}

export default forwardRef<HTMLInputElement, ISelectProps>((props, ref) => <Select {...props} forwardRef={ref} />);
