/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, createRef } from 'react';
import { withFormik, FormikProps, Form } from 'formik';
import * as yup from 'yup';
import { compose } from 'recompose';

import { Action } from '../../../../store/types';

import PrimaryBtn from '../../../common/buttons/OutlineGreen';
import OutlineBtn from '../../../common/buttons/OutlineForwarded';

import form from '../../../common/form';
import * as validatinShemas from '../../../../utils/validatinShemas';

interface FormValues {
  name: string;
  email: string;
  isFreelancer: boolean;
}

interface OtherProps {
  isFreelancer: boolean;
  initialName: string;
  backToSelect: (e: any) => void;
  onCreateAndSelect: (value: any) => void;
  createProducer: Action<{}>;
}

interface FormProps {
  initialEmail?: string;
}

jsx;

const CreateProducer = ({ errors, touched, values, ...props }: OtherProps & FormikProps<FormValues>) => {
  const { FormRow, FormField, FormRadio, FormError, ActionsRow, FormBlock, FieldsArea } = form;
  const firstFieldRef: any = createRef();
  const btnCancelRef: any = createRef();
  const btnSubmitRef: any = createRef();
  const handleLeaveFocusFromBtn = (event: KeyboardEvent) => {
    if (event.key === 'Tab' || event.keyCode === 9) {
      event.preventDefault();
      event.stopPropagation();
      firstFieldRef && firstFieldRef.current && firstFieldRef.current.focus();
    }
    if (event.shiftKey === true) {
      if (event.key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
        btnCancelRef && btnCancelRef.current && btnCancelRef.current.focus();
      }
    }
  };
  const handleLeaveFocus = (event: KeyboardEvent) => {
    if (event.shiftKey === true) {
      if (event.key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
        btnSubmitRef && btnSubmitRef.current && btnSubmitRef.current.focus();
      }
    }
  };
  return (
    <Form>
      <FieldsArea>
        <FormBlock>
          <FormRow>
            <label htmlFor="name">Name</label>
            <FormField
              name="name"
              placeholder="name"
              autoFocus
              innerRef={firstFieldRef}
              onKeyDown={(e: any) => handleLeaveFocus(e)}
            />
            <FormError name="name" errors={errors} touched={touched} />
          </FormRow>

          <FormRow>
            <label htmlFor="email">Email</label>
            <FormField name="email" placeholder="email" />
            <FormError name="email" errors={errors} touched={touched} />
          </FormRow>

          <FormRow>
            <label htmlFor="isFreelancer">Freelancer</label>
            <FormRadio name="isFreelancer" value={true} values={values} title="Yes" disabled />
            <FormRadio name="isFreelancer" value={false} values={values} title="No" disabled />
          </FormRow>
        </FormBlock>
      </FieldsArea>
      <ActionsRow>
        <OutlineBtn
          title="Back to Select"
          isWide={false}
          onClick={props.backToSelect}
          tabIndex={-1}
          ref={btnCancelRef}
        />
        <PrimaryBtn
          title="Create and Select"
          isWide={false}
          tabIndex={0}
          onKeyPress={handleLeaveFocusFromBtn}
          ref={btnSubmitRef}
        />
      </ActionsRow>
    </Form>
  );
};

export const exchangeFormic = withFormik<FormProps & OtherProps, FormValues>({
  mapPropsToValues: (props) => {
    return {
      name: props.initialName,
      email: '',
      isFreelancer: props.isFreelancer,
    };
  },

  validationSchema: yup.object({}).shape({
    email: validatinShemas.vEmailReq,
    name: validatinShemas.vNameReq,
  }),

  handleSubmit: (values, { props }) => {
    props.createProducer(values);
    props.onCreateAndSelect(values.name);
  },
  displayName: 'CreateProducer',
});
export default compose<OtherProps & FormikProps<FormValues>, OtherProps>(exchangeFormic)(CreateProducer);
