/** @jsx jsx */
import { FunctionComponent, ReactElement, Fragment } from 'react';
// components
import widget from '../../../../../../common/widget';
// styles
import { jsx, css } from '@emotion/core';
// types
import { IClient } from '../../../../Clients/store/types';
import { IClientAgent } from '../../../../Clients/Agents/store/types';
// other
import placeholder from './placeholder.png';

export interface IProps {
  client: IClient;
  clientAgents: IClientAgent[];
  productionLogo?: string;
}

jsx;
export const ClientInfoWidget: FunctionComponent<IProps> = ({
  children,
  client,
  clientAgents,
  productionLogo,
}): ReactElement<IProps> => {
  const { Widget, WidgetHeader, WidgetKeyValueRow } = widget;

  return (
    <Widget>
      <WidgetHeader title="Production client" />

      <div css={style.contentBox}>
        <img
          src={productionLogo ? productionLogo : client.file || placeholder}
          alt={client.name}
          css={style.clientImage}
        />
        <WidgetKeyValueRow title="Client name:">{client.name}</WidgetKeyValueRow>

        {clientAgents.map((clientAgent) => {
          return (
            <Fragment key={clientAgent.id}>
              <WidgetKeyValueRow title="Contact name:">{clientAgent.name}</WidgetKeyValueRow>
              <WidgetKeyValueRow title="Phone:">
                <a href={`tel:${clientAgent.phone}`}>{clientAgent.phone}</a>
              </WidgetKeyValueRow>
              <WidgetKeyValueRow title="E-mail:">
                <a href={`mailto:${clientAgent.email}`}>{clientAgent.email}</a>
              </WidgetKeyValueRow>
            </Fragment>
          );
        })}
      </div>
    </Widget>
  );
};

export default ClientInfoWidget;

const style = {
  clientImage: css`
    max-width: 250px;
    max-height: 250px;
    margin: 5px auto;
    display: block;
  `,
  contentBox: css`
    max-height: 550px;
    overflow-y: auto;
  `,
};
