/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withFormik, Form, FormikProps } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';
import { compose } from 'recompose';
import { connect } from '../../../../utils/redux';
import FormRow from '../../../common/form/FormRow';
import FormField from '../../../common/form/FormField';
import FormError from '../../../common/form/FormError';
import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../common/buttons/OutlineBtn';
import { TransportType } from './store/types';
import modal from '../../../common/modal';

interface FormValues {
  name: string;
}

interface OtherProps {
  isOpen: boolean;
  onSave: (transportType: TransportType) => void;
  onClose: () => void;
  item?: TransportType;
}

jsx;

const EditForm = ({ onClose, item, errors, touched, isOpen, values }: OtherProps & FormikProps<FormValues>) => {
  const { Modal, ModalHeader, ModalFooter, ModalBody, ModalActions } = modal;
  const actionTitle = _.get(item, 'id') ? 'Edit' : 'Create';

  return (
    <div>
      <Modal isOpen={isOpen}>
        <Form>
          <ModalHeader>{actionTitle} transport type</ModalHeader>
          <ModalBody>
            <FormRow>
              <label htmlFor="name">Name</label>
              <FormField name="name" />
              <FormError name="name" errors={errors} touched={touched} />
            </FormRow>
          </ModalBody>
          <ModalFooter>
            <ModalActions>
              <PrimaryBtn title={actionTitle} type="submit" />
              <OutlineBtn type="button" onClick={onClose} title="Cancel" />
            </ModalActions>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

const validationSchema = yup.object({}).shape({
  name: yup.string().required('Required'),
});

export const FormikHoc = withFormik<OtherProps, FormValues>({
  enableReinitialize: true,
  mapPropsToValues: ({ item }) => {
    return {
      name: item ? item.name : '',
    };
  },
  validationSchema,
  handleSubmit: (values, { props: { onSave }, setSubmitting }) => {
    onSave(values);
  },
  displayName: 'AddFunctionForm',
});

export default compose<OtherProps & FormikProps<FormValues>, OtherProps>(FormikHoc)(EditForm);
