/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { compose, lifecycle } from 'recompose';
import { RouteComponentProps } from 'react-router';

// redux
import { connect } from '../../../../../../utils/redux';
import { getProductionReq } from '../../store/actions';
import { updateCallSheetReq } from './store/actions';
import { production } from '../../store/selectors';

// types
import { Action } from '../../../../../../store/types';
import { IProduction } from '../../store/types';

// components
import EditForm from './EditForm';

// utils
import _ from 'lodash';

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  getProductionReq: Action<{}>;
  updateCallSheetReq: Action<{}>;
  production: IProduction;
}

jsx;

const withLifecycle = lifecycle<IProps, {}>({
  componentDidMount() {
    const {
      getProductionReq,
      match: { params },
    } = this.props;

    if (params.id !== String(_.get(production, 'id'))) getProductionReq(params.id);
  }
});

const Edit = (props: IProps) => {
  const form = props.production ? <EditForm {...props} /> : <div>Loading...</div>;
  return <div css={styles.formBox}>{form}</div>;
};

const styles = {
  formBox: css`
    width: 100%;
  `,
};

export default compose<IProps, {}>(
  connect(
    { production },
    { getProductionReq, updateCallSheetReq }
  ),
  withLifecycle
)(Edit);
