/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import * as styles from '../../../../styles/styles';

export const actionBox = css({
  marginLeft: '30px',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  flexGrow: 1,
});
export const actionTitle = [
  styles.MainText,
  css({
    paddingRight: '20px',
  }),
];
export const action = css({
  minWidth: '200px',
});

export const xxx = css({});
