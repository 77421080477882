/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, Fragment } from 'react';
import * as styles from './styles';

import _ from 'lodash';

const defaultActions = _.identity;

export interface IProps {
  title?: ReactElement<{}> | string;
  actionsLeft?: ReactElement<{}>;
  actionsRight?: ReactElement<{}>;
}

jsx;

export const ToolBar: FunctionComponent<IProps> = ({
  title = '',
  actionsLeft = null,
  actionsRight = null,
}): ReactElement<IProps> => {
  return (
    <div css={styles.toolbarBox}>
      <div css={styles.toolbarTitleBox}>{title}</div>
      <div css={styles.toolbarActionsBox}>
        <div css={styles.toolbarActionsLeft}>{actionsLeft}</div>
        <div css={styles.toolbarActionsRight}>{actionsRight}</div>
      </div>
    </div>
  );
};

export default ToolBar;
