/** @jsx jsx */
import { jsx } from '@emotion/core';
import { MouseEvent, FunctionComponent, ReactElement } from 'react';

import * as styles from './styles';

import ConfirmButton from '../buttons/ConfirmButton';

export interface IProps {
  onConfirm: (e: MouseEvent<HTMLElement>) => void;
  confirmMessage?: string;
}

jsx;

export const DeleteAction: FunctionComponent<IProps> = (props): ReactElement<IProps> => {
  return <ConfirmButton {...props} btn={<button css={styles.deleteAction} />} />;
};

export default DeleteAction;
