/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FunctionComponent, ReactElement, InputHTMLAttributes, ChangeEvent } from 'react';
import * as styles from './styles';
import { IProps as MultiSelectProps, IScheme, convert, select, NO_SELECTED_MESSAGE } from './MultiSelect';
import PopoverBtn from '../../popover/PopoverBtn';
import MultiSelect from './MultiSelect';
import Text from '../Text';
import * as collect from '../../../../utils/collection';

jsx;

interface IProps extends MultiSelectProps {
  noSelectedMsg?: string;
  inputProps?: object;
  disableReposition?: boolean;
}

export const MultiSelectInput: FunctionComponent<IProps> = (props): ReactElement<IProps> => {
  const { scheme, selected, items, disableReposition, noSelectedMsg = NO_SELECTED_MESSAGE, inputProps = {} } = props;

  const selectedItemTitles = collect.extractKeyValue(select(convert(items, scheme), selected), 'title');
  const areaText = selectedItemTitles.length ? selectedItemTitles.join(', ') : noSelectedMsg;

  return (
    <PopoverBtn
      btn={(openPopover, isOpen) => (
        <div onClick={openPopover} css={styles.multiSelectInputWrapper(isOpen)}>
          <Text
            value={areaText}
            onClick={openPopover}
            onChange={() => {}}
            css={styles.multiSelectInput}
            {...inputProps}
          />
        </div>
      )}
      disableReposition={disableReposition}
      contentLocation={({ targetRect, popoverRect, nudgedTop, nudgedLeft }) => {
        const pageYOffset = window.pageYOffset;
        const pageXOffset = window.pageXOffset;

        if (targetRect.top <= 0) {
          return { top: targetRect.top + pageYOffset, left: nudgedLeft + pageXOffset };
        }

        if (window.innerHeight - targetRect.top <= 0) {
          return { top: targetRect.top - popoverRect.height + pageYOffset, left: nudgedLeft + pageXOffset };
        }

        return { top: nudgedTop + pageYOffset, left: nudgedLeft + pageXOffset };
      }}
      renderContent={(closeDropdown) => (
        <div css={styles.multiSelectPopoverBox}>
          <MultiSelect {...props} onPressEscape={closeDropdown} />
        </div>
      )}
    />
  );
};

export default MultiSelectInput;
