import { DropTarget } from 'react-dnd';
import _ from 'lodash';
import { extractKeyValue } from '../../../../../../../utils/collection';
import * as utils from '../../utils';
import { correctRange } from './range';
import { toast } from 'react-toastify';

export interface IProps {
  item: any;
  itemType: any;
  isDragging: any;
  currentOffset: any;
}

export interface IState {}

const timelineTarget = {
  canDrop(props: any, monitor: any) {
    return true;
  },

  hover(props: any, monitor: any, component: any) {
    const isJustOverThisOne = monitor.isOver({ shallow: true });
    const canDrop = monitor.canDrop();
  },

  drop(
    {  }: any,
    monitor: any,
    {
      props: {
        machinery: { id, users },
        onAddUser = _.identity,
        limitRange,
      },
    }: any
  ) {
    if (!monitor.didDrop()) {
      return {
        afterDrop: (user: any, previousParentMachinery: any) => {
          const userRanges = extractKeyValue(users, 'range');
          const isIntersection = utils.checkRangesIntersection([...userRanges, user.range]);

          const range = isIntersection ? correctRange(user.range, userRanges, limitRange) : user.range;

          if (range) {
            user = { ...user, range };

            onAddUser(user, previousParentMachinery);
          } else {
            toast.error("You can't move bar to selected machinery");
          }
        },
        moved: true,
      };
    }
  },
};

function collect(connect: any, monitor: any) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
  };
}

export default DropTarget('machinery-timeline-bar', timelineTarget, collect);
