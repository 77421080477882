/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import * as styles from '../../../../styles/styles';

export const fileUploadBox = css({
  width: '150px',
  margin: 'auto',
});

export const multiselectBox = css({
  width: '150px',
  margin: 'auto',
});

export const checkbox = css({});
