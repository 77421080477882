import { createAction, ActionFunctionAny } from 'redux-actions';
import reducersRegister from '../../../../../utils/reducersRegister';
import sagasRegister from '../../../../../utils/sagasRegister';
import reducers from './reducers';
import sagas from './sagas';
import { createAsyncAction } from '../../../../../utils/async/actions';
import { Action } from '../../../../../store/types';

reducersRegister.register('user', reducers);
sagasRegister.register(sagas);

/******************************************************************************/
/******************************* GET ONE USER   *****************************/
/******************************************************************************/

export const GET_PROFILE_REQ: string = 'GET_PROFILE_REQ';
export const GET_PROFILE_REQ_SUCCESS: string = 'GET_PROFILE_REQ_SUCCESS';
export const GET_PROFILE_REQ_ERROR: string = 'GET_PROFILE_REQ_ERROR';
export const SET_PROFILE: string = 'SET_USER';

export const getProfileReq: Action<{}> = createAsyncAction(GET_PROFILE_REQ);

export const getProfileReqSuccess: Action<{}> = createAction(GET_PROFILE_REQ_SUCCESS);

export const getProfileReqError: Action<{}> = createAction(GET_PROFILE_REQ_ERROR);

export const setProfile: Action<{}> = createAction(SET_PROFILE);

/******************************************************************************/
/******************************* UPDATE ONE USER   **************************/
/******************************************************************************/

export const UPDATE_PROFILE_REQ: string = 'UPDATE_PROFILE_REQ';
export const UPDATE_PROFILE_REQ_SUCCESS: string = 'UPDATE_PROFILE_REQ_SUCCESS';
export const UPDATE_PROFILE_REQ_ERROR: string = 'UPDATE_PROFILE_REQ_ERROR';
export const UPDATE_PROFILE: string = 'UPDATE_USER';

export const updateProfileReq: Action<{}> = createAsyncAction(UPDATE_PROFILE_REQ);

export const updateProfileReqSuccess: Action<{}> = createAction(UPDATE_PROFILE_REQ_SUCCESS);

export const updateProfileReqError: Action<{}> = createAction(UPDATE_PROFILE_REQ_ERROR);

export const updateProfile: Action<{}> = createAction(UPDATE_PROFILE);

//DEPRECATED
// requests definitions

export type ProfileLoadRequest = Action<{}>;
export type ProfileLoadRequestSuccess = Action<{}>;
export type ProfileLoadRequestError = Action<{}>;

export type ProfileUpdateRequest = Action<{}>;
export type ProfileUpdateRequestSuccess = Action<{}>;
export type ProfileUpdateRequestError = Action<{}>;

export type UpdatePasswordRequest = Action<{}>;
export type UpdatePasswordRequestSuccess = Action<{}>;
export type UpdatePasswordRequestError = Action<{}>;

export type ProfileLoad = Action<{}>;
export type ProfileUpdate = Action<{}>;
export type PasswordUpdate = Action<{}>;

// requests definitions names

export const PROFILE_LOAD_REQUEST = 'PROFILE_LOAD_REQUEST_REQUEST';
export const PROFILE_LOAD_REQUEST_SUCCESS = 'PROFILE_LOAD_REQUEST_SUCCESS';
export const PROFILE_LOAD_REQUEST_ERROR = 'PROFILE_LOAD_REQUEST_ERROR';

export const PROFILE_UPDATE_REQUEST: string = 'PROFILE_REQUEST_REQUEST';
export const PROFILE_UPDATE_REQUEST_SUCCESS: string = 'PROFILE_UPDATE_REQUEST_SUCCESS';
export const PROFILE_UPDATE_REQUEST_ERROR: string = 'PROFILE_UPDATE_REQUEST_ERROR';

export const PASSWORD_UPDATE_REQUEST: string = 'UPDATE_PASSWORD_REQUEST_REQUEST';
export const PASSWORD_UPDATE_REQUEST_SUCCESS: string = 'PASSWORD_UPDATE_REQUEST_SUCCESS';
export const PASSWORD_UPDATE_REQUEST_ERROR: string = 'PASSWORD_UPDATE_REQUEST_ERROR';

export const PROFILE_LOAD = 'PROFILE_LOAD';
export const PROFILE_UPDATE: string = 'PROFILE_UPDATE';
export const PASSWORD_UPDATE: string = 'PASSWORD_UPDATE';

// ACTIONS

export const profileLoadRequest: ProfileLoadRequest = createAction(PROFILE_LOAD_REQUEST);
export const profileLoadRequestSuccess: ProfileLoadRequestSuccess = createAction(PROFILE_LOAD_REQUEST_SUCCESS);
export const profileLoadRequestError: ProfileLoadRequestError = createAction(PROFILE_LOAD_REQUEST_ERROR);

export const profileUpdateRequest: ProfileUpdateRequest = createAction(PROFILE_UPDATE_REQUEST);
export const profileUpdateRequestSuccess: ProfileUpdateRequestSuccess = createAction(PROFILE_UPDATE_REQUEST_SUCCESS);
export const profileUpdateRequestError: ProfileUpdateRequestError = createAction(PROFILE_UPDATE_REQUEST_ERROR);

export const passwordUpdateRequest: UpdatePasswordRequest = createAction(PASSWORD_UPDATE_REQUEST);
export const passwordUpdateRequestSuccess: UpdatePasswordRequestSuccess = createAction(PASSWORD_UPDATE_REQUEST_SUCCESS);
export const passwordUpdateRequestError: UpdatePasswordRequestError = createAction(PASSWORD_UPDATE_REQUEST_ERROR);

export const profileLoad: ProfileLoad = createAction(PROFILE_LOAD);
export const passwordUpdate: PasswordUpdate = createAction(PASSWORD_UPDATE);
export const profileUpdate: ProfileUpdate = createAction(PROFILE_UPDATE);
