/** @jsx jsx */
import { jsx } from '@emotion/core';
import { forwardRef, ReactNode } from 'react';
import * as styles from './styles';

export interface IProps {
  children: ReactNode | ReactNode[];
}

jsx;
export default forwardRef<HTMLDivElement, IProps>(({ children }, ref) => {
  return (
    <div css={styles.mainInfoBox} ref={ref}>
      {children}
    </div>
  );
});
