/** @jsx jsx */
import { jsx, css } from '@emotion/core';

export const reset = css({
  margin: '0px',
  padding: 0,
});

export enum Color {
  Black = '#000',
  RegularBlack = '#505050',
  LightBlack = '#666666',
  AltBlack = '#575962',
  DarkGreen = '#324F0A',
  Green = '#48671C',
  AltGreen = '#517222',
  LightGreen = '#8DA071',
  LightGreenGrey = '#E8ECE3',
  InsetBoxShadow = '#E8ECE3',
  DarkGrey = '#B1B1B1',
  Grey = '#E4E4E4',
  AltGrey = '#F5F5F5',
  LightGrey = '#F2F2F2',
  LightGreyMainBG = '#E5E5E5',
  LightGreyBlue = '#F2F3F8',
  ThemeRed = '#E77B7B',
  ThemeOrange = '#EAB64D',
  White = '#FFF',
  HoveredWhite = '#F5F5F5',
  ToastSuccess = '#6B9A27',
  ToastInfo = '#59B3D9',
  ToastWarn = '#EAB64D',
  ToastError = '#D97F59',
  HardGrey = '#3F4047',
  DarkBG = '',
  Background = '#FAF9FC',
}

export enum ColorsForCopy {
  Black = '#000',
  RegularBlack = '#505050',
  LightBlack = '#666666',
  AltBlack = '#575962',
  DarkGreen = '#00172D',
  Green = '#205681',
  AltGreen = '#205681',
  LightGreen = '#8DA071',
  LightGreenGrey = '#8CCAE9',
  InsetBoxShadow = '#FFF',
  DarkGrey = '#B1B1B1',
  Grey = '#E4E4E4',
  AltGrey = '#F5F5F5',
  LightGrey = '#F2F2F2',
  LightGreyMainBG = '#E5E5E5',
  DarkBG = '#00172D',
  LightGreyBlue = '#F2F3F8',
  ThemeRed = '#E77B7B',
  ThemeOrange = '#EAB64D',
  White = '#FFF',
  HoveredWhite = '#F5F5F5',
  ToastSuccess = '#6B9A27',
  ToastInfo = '#59B3D9',
  ToastWarn = '#EAB64D',
  ToastError = '#D97F59',
  HardGrey = '#3F4047',
  Background = '#FAF9FC',
}
export const Colors =
  document.URL.includes('https://backbone-beta-app.atlasproductionplanning.com') ||
  document.URL.includes('https://atlas.backbone-international.com') ||
  document.URL.includes('http://localhost:3000')
    ? Color
    : ColorsForCopy;

export enum FontFamilies {
  Title = 'Roboto',
  Main = 'Poppins',
  Input = 'Arial',
}

export const title = css({
  fontWeight: 500,
  fontFamily: FontFamilies.Title,
});

export const H1 = [
  title,
  css({
    fontSize: '18px',
    color: Colors.Black,
  }),
];
export const H1white = [
  title,
  css({
    fontSize: '18px',
    color: Colors.White,
  }),
];

export const H2 = [
  title,
  css({
    fontSize: '17px',
    color: Colors.RegularBlack,
  }),
];

export const H3 = [
  title,
  css({
    fontSize: '13px',
    color: Colors.AltBlack,
  }),
];

export const MainText = css({
  fontWeight: 400,
  fontFamily: FontFamilies.Main,
  fontSize: '13px',
  color: Colors.RegularBlack,
});

export const MainTextLight = css({
  fontWeight: 400,
  fontFamily: FontFamilies.Main,
  fontSize: '13px',
  color: Colors.White,
});

export const ScheduleText = css({
  fontWeight: 400,
  fontFamily: FontFamilies.Main,
  fontSize: '12px',
  color: Colors.RegularBlack,
});

export const TimelineText = css({
  fontWeight: 400,
  fontFamily: FontFamilies.Title,
  fontSize: '11px',
  color: '#BABABA',
});

export const InputText = css({
  fontWeight: 400,
  fontFamily: FontFamilies.Input,
  fontSize: '13px',
  color: Colors.RegularBlack,
});

export const Link = [
  MainText,
  css({
    textDecoration: 'none',
    whiteSpace: 'nowrap',
  }),
];

export const LinkIn = css({
  '& a': Link,
  '& span': Link,
});

export const visuallyHidden = css({
  position: 'absolute',
  width: '1px',
  height: '1px',
  margin: '-1px',
  bodrer: 0,
  padding: 0,
  clip: 'rect(0 0 0 0)',
  overflow: 'hidden',
});

export const globalStyle = [reset, MainText];
