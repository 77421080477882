export const GRID_ROW_PAER_PAGE_DEFAULT = 10;
export const UNLIMITED_LIST_SIZE = 1000;

export const DATE_FORMAT = 'DD.MM.YYYY';
export const YEAR_FORMAT = 'YYYY';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_12 = 'h:mm A';
export const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const GRID_DATE_FORMAT = 'MMMM DD';

export const MAX_FILE_UPLOAD_MB = 2;

export const ACL = {
  resources: {},
};
