/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Component, createRef } from 'react';
import { RemoveScroll } from 'react-remove-scroll';

export interface IProps {
  children: (isSticky: boolean) => any;
}

export interface IState {
  isSticky: boolean;
  height: any;
  width: any;
}

jsx;

class Sticky extends Component<IProps, IState> {
  private boxEl: any;

  constructor(props: IProps) {
    super(props);

    this.boxEl = createRef();

    this.state = {
      isSticky: false,
      height: null,
      width: null,
    };
  }

  componentDidMount(): void {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
  }

  handleSize = () => {
    const params = this.boxEl.current.getBoundingClientRect();
    this.setState({ height: params.height, width: params.width });
  };

  handleScroll = () => {
    const params = this.boxEl.current.getBoundingClientRect();
    const isCurrentSticky = params.y < 0;

    if (!isCurrentSticky) {
      this.handleSize();
    }

    this.setState({ isSticky: isCurrentSticky });
  };

  render() {
    const { children } = this.props;
    const { isSticky, height, width } = this.state;

    return (
      <div css={box} ref={this.boxEl}>
        {isSticky && <div css={stub(height)} />}

        {!!width && (
          <div
            css={stickyBox(isSticky, width)}
            className={isSticky ? `${RemoveScroll.classNames.fullWidth}, ${RemoveScroll.classNames.zeroRight}` : ''}
          >
            {children(isSticky)}
          </div>
        )}
      </div>
    );
  }
}

export default Sticky;

const box = css({
  position: 'relative',
});

const stub = (height: number) =>
  css({
    height: `${height}px`,
    width: '100%',
  });

const stickyBox = (isSticky: boolean, width: number) =>
  css({
    position: isSticky ? 'fixed' : 'relative',
    top: 0,
    right: 0,
    backgroundColor: 'inherit',
    zIndex: isSticky ? 2 : 0,
    width: '100%',
  });
