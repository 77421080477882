/** @jsx jsx */
import { jsx } from '@emotion/core';
import { MouseEvent, FunctionComponent, ReactElement } from 'react';

import Row from '../../popover/Row';
import * as styles from './styles';

export interface IProps {
  title: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

jsx;

export const Button: FunctionComponent<IProps> = ({ title, onClick }): ReactElement<IProps> => {
  return (
    <div css={styles.actionText}>
      <Row onClick={onClick}>{title}</Row>
    </div>
  );
};

export default Button;
