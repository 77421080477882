/** @jsx jsx */
import { jsx } from '@emotion/core';
import { forwardRef, KeyboardEvent } from 'react';
import * as styles from './styles';
import { convert, select, NO_SELECTED_MESSAGE } from './MultiSelect';
import { IProps as MultiSelectProps } from './MultiselectBlock';
import PopoverBtn from '../../popover/PopoverBtn';
import MultiselectBlock from './MultiselectBlock';
import * as collect from '../../../../utils/collection';
import * as inputStyles from '../../form/styles';

jsx;

export interface IProps extends MultiSelectProps {
  inputProps?: object;
  disableReposition?: boolean;
  autoHeight?: boolean;
  onBlur?: () => void;
  onSwitchSearch?: (e: any) => void;
  shoudOpenOnFocus?: boolean;
  handleSubmit?: () => void;
  isVisibilityHight?: boolean;
}

interface ILocateContent {
  target: ClientRect;
  popover: ClientRect;
  nudgedTop: number;
  nudgedLeft: number;
}

export const MultiselectModal = forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const {
    scheme,
    selected,
    items,
    disableReposition,
    noSelectedMsg = NO_SELECTED_MESSAGE,
    autoHeight = false,
    onBlur,
    onSwitchSearch,
    handleSubmit,
    isVisibilityHight = true,
  } = props;

  const selectedItemTitles = collect.extractKeyValue(select(convert(items, scheme), selected), 'title');
  const areaText = selectedItemTitles.length ? selectedItemTitles.join(', ') : noSelectedMsg;
  const locateContent = ({ target, popover, nudgedTop, nudgedLeft }: ILocateContent) => {
    {
      const pageYOffset = window.pageYOffset;
      const pageXOffset = window.pageXOffset;

      if (target.top <= 0) {
        return { top: target.top + pageYOffset, left: nudgedLeft + pageXOffset };
      }

      if (window.innerHeight - target.top <= 0) {
        return { top: target.top - popover.height + pageYOffset, left: nudgedLeft + pageXOffset };
      }

      return { top: nudgedTop + pageYOffset, left: nudgedLeft + pageXOffset };
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>, cb: any) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      cb(e);
    }
  };

  const handleApply = (e: any, close: (e: any) => void) => {
    if (!handleSubmit) {
      close(e);
    } else {
      close(e);
      handleSubmit();
    }
  };

  return (
    <PopoverBtn
      btn={(openPopover, isOpen) => (
        <div onClick={openPopover} css={styles.multiSelectInputWrapper(isOpen)}>
          <div css={inputStyles.formFieldBox}>
            <input
              type="text"
              ref={ref}
              value={areaText}
              onClick={(e) => {
                setTimeout(() => {
                  e.stopPropagation();
                  e.preventDefault();
                  openPopover(e);
                }, 1000);
              }}
              onFocus={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setTimeout(() => {
                  openPopover(e);
                }, 200);
              }}
              onChange={() => {}}
              onKeyPress={(e: any) => handleKeyPress(e, openPopover)}
              css={styles.multiSelectInput}
            />
          </div>
        </div>
      )}
      disableReposition={disableReposition}
      autoHeight={autoHeight}
      onClose={onBlur}
      isVisibilityHight={isVisibilityHight}
      contentLocation={({ targetRect, popoverRect, nudgedTop, nudgedLeft }) =>
        locateContent({ target: targetRect, popover: popoverRect, nudgedTop, nudgedLeft })
      }
      renderContent={(closeDropdown) => (
        <div css={styles.multiSelectPopoverBox}>
          <MultiselectBlock
            {...props}
            onApply={(e: any) => handleApply(e, closeDropdown)}
            onPressEscape={closeDropdown}
            onCheckboxChange={onSwitchSearch}
          />
        </div>
      )}
    />
  );
});

export default MultiselectModal;
