enum Routes {
  Root = '/',

  Producers = '/producers',
  ProducersCreate = '/producers/create',
  ProducersEdit = '/producers/edit/:id',

  Clients = '/clients',
  ClientsCreate = '/clients/create',
  ClientsEdit = '/clients/:id/edit',
  ClientAgents = '/clients/:owner_id/agents',
  ClientAgentCreate = '/clients/:owner_id/agents/create',
  ClientAgentEdit = '/clients/:owner_id/agents/:id/edit',

  Suppliers = '/suppliers',
  SuppliersCreate = '/suppliers/create',
  SuppliersEdit = '/suppliers/:id/edit/',
  SupplierAgents = '/suppliers/:owner_id/agents',
  SupplierAgentCreate = '/suppliers/:owner_id/agents/create',
  SupplierAgentEdit = '/suppliers/:owner_id/agents/:id/edit',

  Productions = '/productions',
  Production = '/productions/:id',
  ProductionPrint = '/productions/:id/print',
  ProductionSuppliers = '/productions/:id/suppliers',
  ProductionsCreate = '/productions/create',
  ProductionsEdit = '/productions/:id/edit',
  SubProductionsCreate = '/productions/:production_id/create',
  SubProductionsEdit = '/productions/:production_id/:subproduction_id/edit',
  ProductionMachinery = '/productions/:id/machinery',
  ProductionMachineryPrint = '/productions/:id/machinery/print',
  ProductionGates = '/productions/:id/gates',
  ProductionGate = '/productions/:id/gates/:gateId',
  ProductionGatesCreate = '/productions/:id/gates/create',
  ProductionGatesEdit = '/productions/:id/gates/:gateId/edit',
  ProductionCallSheet = '/productions/:id/call-sheet',
  ProductionCallSheetEdit= '/productions/:id/call-sheet/edit',

  Login = '/login',
  Restore = '/restore',
  Invitation = '/invitation',

  // user profile
  ChangePassword = '/change-password',
  UpdateProfile = '/update-profile',

  SupplierFunctions = '/supplier-functions',
  TransportTypes = '/transport-types',
  GeneralInfo = '/general-info',

  Categories = '/categories',
  CategoriesCreate = '/categories/create',
  CategoriesEdit = '/categories/:id/edit',
}

export default Routes;
