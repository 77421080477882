import si, { ImmutableObject } from 'seamless-immutable';
import { getListState, getItemState, ListStatuses, ItemStatuses } from '../../../../../utils/store/state/state';
import * as ru from '../../../../../utils/redux';
import * as r from '../../../../../utils/reducers';
import * as actions from './actions';
import { CategoriesListState, CategoryItemState } from './types';
// utils
import _ from 'lodash';

interface IState {
  all: CategoriesListState;
  list: CategoriesListState;
  item: CategoryItemState;
}

const INITIAL_STATE: ImmutableObject<IState> = si({
  all: getListState({ size: 1000, page: 0 }),
  list: getListState({ size: 20, page: 0 }),
  item: getItemState(),
});

const allReducer = r.createReducer('all');
const listReducer = r.createReducer('list');
const itemReducer = r.createReducer('item');

export default ru.processActions(
  () => ({
    //ALL
    [actions.GET_ALL_CATEGORIES_REQUEST]: allReducer(r.loadingReducer),
    [actions.GET_ALL_CATEGORIES_REQUEST_SUCCESS]: allReducer(r.successReducer),
    [actions.GET_ALL_CATEGORIES_REQUEST_ERROR]: allReducer(r.errorReducer),
    [actions.SET_ALL_CATEGORIES]: allReducer(r.listDataReducer),

    // FETCH LIST
    [actions.CATEGORIES_LOAD_REQUEST]: listReducer(r.loadingReducer),
    [actions.CATEGORIES_LOAD_REQUEST_SUCCESS]: (store: ImmutableObject<IState>, action: any) => {
      const {
        payload: { data, params },
        meta,
      } = action;

      return store
        .setIn(['list', 'data'], params.page > 0 ? store.list.data.concat(data) : data)
        .setIn(['list', 'status'], ListStatuses.Actual)
        .setIn(['list', 'meta'], meta)
        .setIn(['list', 'params'], params);
    },
    [actions.CATEGORIES_LOAD_REQUEST_ERROR]: listReducer(r.errorReducer),

    // FETCH ITEM
    [actions.CATEGORY_LOAD_REQUEST]: itemReducer(r.loadingReducer),
    [actions.CATEGORY_LOAD_REQUEST_SUCCESS]: (store: ImmutableObject<IState>, action: any) => {
      return store
        .setIn(['item', 'data'], action.payload) // set item data
        .setIn(['item', 'status'], ListStatuses.Actual); // set actual status
    },
    [actions.CATEGORY_LOAD_REQUEST_ERROR]: itemReducer(r.errorReducer),

    // EDIT ITEM
    [actions.CATEGORY_EDIT_REQUEST]: itemReducer(r.loadingReducer),
    [actions.CATEGORY_EDIT_REQUEST_SUCCESS]: itemReducer(r.successReducer),
    [actions.CATEGORY_EDIT_REQUEST_ERROR]: itemReducer(r.errorReducer),

    // DELETE
    [actions.CATEGORY_DELETE_REQUEST]: listReducer(r.loadingReducer),
    [actions.CATEGORY_DELETE_REQUEST_SUCCESS]: (store: ImmutableObject<IState>, action: any) => {
      const id = action.payload.id;
      const updatedList = store.list.data.filter((item) => item.id !== id);
      return store
        .setIn(['list', 'data'], updatedList) // set updated list
        .setIn(['list', 'status'], ListStatuses.Actual);
    },
    [actions.CATEGORY_DELETE_REQUEST_ERROR]: listReducer(r.errorReducer),
  }),
  INITIAL_STATE
);
