/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import { Colors } from '../../../styles/styles';

export interface IProps {
  isLoading?: boolean;
}

jsx;
export const Loader: FunctionComponent<IProps> = ({ isLoading, ...props }: IProps): ReactElement<IProps> => {
  const css = [style.loader, isLoading && style.isLoading];
  return (
    <div css={css} {...props}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Loader;

const style = {
  loader: css`
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    max-height: 50vh;
    transform: translate(-50%, -50%);
    z-index: 10;
    display: inline-block;
    width: 1px;
    height: 1px;
    transition: opacity 0.25s ease;
    margin: 30px 0;
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 50px;
      height: 50px;
      margin: 5px;
      border: 5px solid #fff;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: ${Colors.Green} transparent transparent transparent;
    }

    div:nth-of-type(1) {
      animation-delay: -0.45s;
    }

    div:nth-of-type(2) {
      animation-delay: -0.3s;
    }

    div:nth-of-type(3) {
      animation-delay: -0.15s;
    }

    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `,
  isLoading: css`
    opacity: 1;
    width: 60px;
    height: 60px;
  `,
};
