/** @jsx jsx */
import { jsx } from '@emotion/core';
import { MouseEvent, Fragment, Component, ReactElement } from 'react';

import ModalConfirm from '../modal/kits/ModalConfirm';
import * as styles from './styles';
import * as React from 'react';

export interface IProps {
  onConfirm: (e: MouseEvent<HTMLElement>) => void;
  btn: ReactElement<any>;
  confirmMessage?: string;
}

export interface IState {
  isOpen: boolean;
}

jsx;

class ConfirmButtonBase extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  open = (e: any) => {
    this.setState({
      isOpen: true,
    });
  };

  close = (e: any) => {
    this.setState({
      isOpen: false,
    });
  };

  render() {
    const { confirmMessage = 'Are you sure?', btn, onConfirm } = this.props;
    const { isOpen } = this.state;

    const Button = () =>
      React.cloneElement(btn, {
        onClick: this.open,
      });

    return (
      <Fragment>
        <Button />

        <ModalConfirm
          isOpen={isOpen}
          onConfirm={(e) => {
            this.close(e);
            onConfirm(e);
          }}
          onDecline={(e) => {
            this.close(e);
          }}
        >
          {confirmMessage}
        </ModalConfirm>
      </Fragment>
    );
  }
}

export default ConfirmButtonBase;
