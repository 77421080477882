import si, { ImmutableObject } from 'seamless-immutable';
import * as ru from '../utils/redux';
import { getToken } from '../utils/auth';

import { LOGIN_REQUEST, LOGIN_REQUEST_SUCCSESS, LOGIN_REQUEST_ERROR, SET_TOKEN, LOGIN, LOGOUT } from './actions';

interface IState {
  loginLoading: boolean;
  loginError: any;
  isLogin: boolean;
}

const INITIAL_STATE: ImmutableObject<IState> = si({
  loginLoading: false,
  loginError: '',
  isLogin: !!getToken(),
});

export default ru.processActions(
  () => ({
    [LOGIN_REQUEST]: (store: ImmutableObject<IState>) => {
      return store.set('loginLoading', true);
    },
    [LOGIN_REQUEST_SUCCSESS]: (store: ImmutableObject<IState>) => {
      return store.set('loginLoading', false);
    },
    [LOGIN_REQUEST_ERROR]: (store: ImmutableObject<IState>, { payload }: any) => {
      return store.set('loginLoading', false).set('loginError', payload);
    },
    [SET_TOKEN]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('token', action.payload);
    },
    [LOGIN]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('isLogin', true);
    },
    [LOGOUT]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('isLogin', false);
    },
  }),
  INITIAL_STATE
);
