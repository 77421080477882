/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import Popover from '../popover/Popover';
import Datepicker from '../form/datepicker/Datepicker';
import * as styles from './styles';
import { ReactComponent as DateIcon } from '../../../images/icons/date.svg';
import { ViewModes } from '../../layouts/Private/Productions/Item/Item';
import moment from 'moment';

export interface IProps {
  onMoveUp: () => void;
  productionName: string;
  viewMode: ViewModes;
  prodVersion: any;
  productionRange: any;
  dayTitle: string;
  setSelectedDay: (day: number) => void;
}
interface IState {
  dataValue: any;
  openDatepicker: boolean;
}

jsx;
export class ProductionPageHeader extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      dataValue: moment.utc(this.props.productionRange.from).valueOf(),
      openDatepicker: false,
    };
  }
  openPopover = () => {
    this.setState({
      openDatepicker: true,
    });
  };
  closePopover = () => {
    this.setState({ openDatepicker: false });
  };
  getScheduleType = () => {
    switch (this.props.viewMode) {
      case ViewModes.activity:
        return 'Production Schedule:  ';

      case ViewModes.mixed:
        return 'Production & Transport Schedule:  ';

      case ViewModes.transport:
        return 'Transport Schedule:  ';
      default:
        return 'Production Schedule: ';
    }
  };

  scheduleTitle = () =>
    this.props.prodVersion ? (
      <h3 css={styles.scheduleType}>
        {this.getScheduleType()}
        <span css={styles.scheduleName}>{`${this.props.productionName} (${this.props.prodVersion})`} </span>
      </h3>
    ) : (
      <h3 css={styles.scheduleType}>
        {this.getScheduleType()} <span css={styles.scheduleName}>{this.props.productionName}</span>
      </h3>
    );

  render() {
    const { children, onMoveUp, dayTitle, productionRange, setSelectedDay } = this.props;
    const { openDatepicker, dataValue } = this.state;
    return (
      <div css={styles.productionHeaderBox}>
        <div css={styles.leftSideProductionHeader}>
          <button css={styles.moveUpBtn} onClick={onMoveUp}>
            {''}
          </button>
          <h3 css={styles.dayTitle}>{dayTitle}</h3>
          <DateIcon
            onClick={() => {
              this.setState({ openDatepicker: true });
            }}
            css={styles.datePicker}
          />
          {openDatepicker && (
            <Popover isOpen onClickOutside={this.closePopover} autoHeight>
              <Datepicker
                input={false}
                value={dataValue}
                onChange={(value: number) => {
                  setSelectedDay(
                    moment
                      .utc(value)
                      .startOf('day')
                      .valueOf()
                  );
                  this.closePopover();
                }}
                timeFormat={false}
                range={productionRange}
              />
            </Popover>
          )}
        </div>
        <div>{this.scheduleTitle()}</div>
        <div css={styles.productionHeaderContent}>{children}</div>
      </div>
    );
  }
}

export default ProductionPageHeader;
