import React from 'react';
import VisualContext from './VisualContext';

const withVisualContext = (WrappedComponent) => {
  return function WithAuthContext(props) {
    return <VisualContext.Consumer>{(ctx) => <WrappedComponent {...props} visual={ctx} />}</VisualContext.Consumer>;
  };
};

export default withVisualContext;
