/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Component, Fragment } from 'react';
import * as styles from '../styles';

export interface IProps {
  scheme: string[];
}

export interface IState {}

jsx;
class Timeline extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { scheme } = this.props;

    return (
      <div css={styles.timelineHeaderBox}>
        {scheme.map((item, index) => {
          return (
            <div key={index} css={styles.timelineHeaderScaleItemBox}>
              <div css={styles.timelineHeaderScaleItemTitle}> {item} </div>
            </div>
          );
        })}

        <div css={styles.timelineHeaderScaleEndItemBox}>
          <div css={styles.timelineHeaderScaleItemTitle}> {scheme[0]} </div>
        </div>
      </div>
    );
  }
}

export default Timeline;
