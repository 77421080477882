/** @jsx jsx */
import { jsx } from '@emotion/core';
import compose from 'recompose/compose';
// import compose from 'recompose'
import { FunctionComponent, ReactElement } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Box from './Box';
import LoginForm from './LoginForm';
import OutlineBtn from '../../common/buttons/OutlineBtn';
import { loginRequest } from '../../../store/actions';
import { Action } from '../../../store/types';
import { isLogin } from '../../../store/selectors';

import { IVisualContext } from '../../../contexts/VisualContext';
import withVisualContext from '../../../contexts/withVisualContext';

import routes from '../../../routes';

import { H1, H1white } from '../../../styles/styles';
import * as styles from './styles';
import { connect } from '../../../utils/redux';

interface IProps {
  loginRequest: Action<{}>;
  isLogin: boolean;
  visual: IVisualContext;
}

jsx;

const Login: FunctionComponent<IProps> = ({ isLogin, loginRequest, visual }): ReactElement<IProps> => {
  const redirectToRoot = <Redirect to={routes.Root} />;

  const loginForm = (
    <Box>
      <section css={styles.ContentSection}>
        <h1 css={visual.isCopy ? H1white : H1}>Login</h1>
        <div css={visual.isCopy ? styles.DescriptionLight : styles.Description}>{`Welcome to ${
          visual.labels.name
        }! Please, login to your account.`}</div>
        <LoginForm loginRequest={loginRequest} />
        <div>
          <Link to={routes.Restore}>
            <OutlineBtn isWide={true} title="Forgot password?" />
          </Link>
        </div>
      </section>
    </Box>
  );

  return isLogin ? redirectToRoot : loginForm;
};

export default compose<any, {}>(
  connect(
    { isLogin },
    { loginRequest }
  ),
  withVisualContext
)(Login);
