/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Component, Fragment, FunctionComponent, ReactElement } from 'react';

// components
import Loader from '../loader/Loader';
import { Waypoint } from 'react-waypoint';

// utils
import _ from 'lodash';
import * as redux from '../../../utils/redux';

export interface IProps extends Waypoint.WaypointProps {
  data: any;
}

jsx;

const DataWaypoin: FunctionComponent<IProps> = ({ data: { data, status, meta }, onEnter }): ReactElement<IProps> => {
  const total = _.get(meta, 'total', 0);
  const hasMoreItems = data ? total > data.length : false;
  const isLoading = status ? status === redux.STATUS_REQUEST : false;

  return (
    <Fragment>
      {hasMoreItems && !isLoading && <Waypoint onEnter={onEnter} />}
      <Loader css={style.loader} isLoading={isLoading} />
    </Fragment>
  );
};

export default DataWaypoin;

const style = {
  loader: css`
    margin: 5px 0;
  `,
};
