import _ from 'lodash';

export function getResponseMessage(response) {
  let message;

  // data.message
  message = _.get(response, 'data.message');
  if (message) return message;

  // data.error
  const error = _.get(response, 'data.error');
  if (error) return error;

  // data.data.message
  const responseData = _.get(response, 'data.data');
  if (responseData) {
    if (Array.isArray(responseData)) {
      message = _.map(responseData, 'message').join();
      return message;
    } else {
      return responseData.message;
    }
  }

  // response.errors
  const responseErrors = _.get(response, 'errors');
  if (responseErrors) {
    if (Array.isArray(responseErrors)) {
      message = _.map(responseErrors, 'message').join();
      return message;
    } else {
      return responseData.message;
    }
  }
}
