import BaseBtn from './BaseBtn';
import OutlineBtn from './OutlineBtn';
import PrimaryBtn from './PrimaryBtn';

import * as styles from './styles';

export default {
  BaseBtn,
  OutlineBtn,
  PrimaryBtn,
  styles,
};
