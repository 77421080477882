import * as redux from '../../redux';

const xxx = redux.STATUS_NEW;

export enum ListStatuses {
  Empty = 'empty',
  Old = 'old',
  Request = 'request',
  Error = 'error',
  Actual = 'actual',
}

export enum ItemStatuses {
  New = 'new',
  Empty = 'empty',
  Old = 'old',
  Request = 'request',
  Error = 'error',
  Actual = 'actual',
  Created = 'created',
  Updated = 'updated',
  Deleted = 'deleted',
}

export interface IListBaseParams {
  page: number;
  offset: number;
  size: number;
}

export interface IItemBaseParams {
  id: number;
}

export interface ISearchParams {
  search: string;
}

export interface IFilterParams {
  filter: number | string;
}

export type Params<ExtParams> = IListBaseParams & ExtParams;

export type Meta<M> = any;

export interface IList<Item = any, Params = IListBaseParams, Meta = any, Errors = any> {
  data: Item[];
  status: ListStatuses;
  error: Errors;
  meta: Meta;
  params: Params;
}

export interface IItem<Item = any, Params = IItemBaseParams, Meta = any, Errors = any> {
  data: Item;
  status: ItemStatuses;
  error: Errors;
  meta: Meta;
  params: Params;
}

export const listState = {
  data: null,
  status: ListStatuses.Empty,
  error: null,
  meta: {},
  params: {},
};

export const itemState = {
  data: null,
  status: ItemStatuses.Empty,
  error: null,
  meta: {},
  params: {},
};

export const getState = (baseState: any, initisl: any) => {
  return { ...baseState, ...initisl };
};

export const getListState = (params: any = {}, extra: any = {}) => {
  return getState(listState, { params, ...extra });
};

export const getItemState = (params: any = {}) => {
  return getState(itemState, { params });
};
