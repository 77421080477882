/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, MouseEvent, ReactElement } from 'react';
import * as styles from './styles';

export interface IFormRowProps {
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  tabIndex?: number;
}

jsx;

export const ApplyAction: FunctionComponent<IFormRowProps> = ({ onClick, tabIndex }): ReactElement<IFormRowProps> => {
  return <button type="button" css={styles.applyAction} onClick={onClick} tabIndex={tabIndex} />;
};

export default ApplyAction;
