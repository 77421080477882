/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import widget from '../../../../../common/widget';
import { IProducer } from '../../../Producers/store/types';

export interface IProps {
  producer: IProducer;
}

jsx;

export const ProducerInfoWidget: FunctionComponent<IProps> = ({ children, producer }): ReactElement<IProps> => {
  const { Widget, WidgetHeader, WidgetKeyValueRow } = widget;

  return (
    <Widget>
      <WidgetHeader title="Producer information" />
      <WidgetKeyValueRow title="Name:">{producer.name}</WidgetKeyValueRow>
      <WidgetKeyValueRow title="Phone:">
        <a href={`tel:${producer.phone}`}>{producer.phone}</a>
      </WidgetKeyValueRow>
      <WidgetKeyValueRow title="Email:">
        <a href={`mailto:${producer.email}`}>{producer.email}</a>
      </WidgetKeyValueRow>
    </Widget>
  );
};

export default ProducerInfoWidget;
