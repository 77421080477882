/** @jsx jsx */
import { jsx } from '@emotion/core';

import _ from 'lodash';
import { Component, Fragment } from 'react';

import * as styles from './styles';
import SelectedBtn from '../../buttons/SelectedBtn';
import OutlineBtn from '../../buttons/OutlineBtn';
import * as object from '../../../../utils/object';
import * as collection from '../../../../utils/collection';
import * as array from '../../../../utils/array';
import Text from '../Text';
import Checkbox from '../Checkbox';

export interface IScheme {
  id?: string;
  title: string;
}

export interface IProps {
  selected: number[];
  items: any[];
  scheme: IScheme;
  onChange: (a: number[]) => void;
  noSelectedMsg?: string;
  selectAllBtn?: boolean;
  className?: string;
  onPressEscape?: (e: any) => void;
}

export interface IState {
  search: string;
}

export const NO_SELECTED_MESSAGE = 'No selected items';

export const convert = (items: any[], scheme: IScheme) => {
  return items.map((item) => object.buildData(item, scheme));
};

export const select = (convertedItems: any[], selected: number[]) => {
  return collection.selectBy(convertedItems, { id: selected });
};

export const exclude = (convertedItems: any[], selected: number[]) => {
  return collection.excludeBy(convertedItems, { id: selected });
};

jsx;
class MultiSelect extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      search: '',
    };
  }

  getSelectedItems = () => {
    const { scheme, selected, items } = this.props;
    const selectedItems: any[] = select(convert(items, scheme), selected);
    return selectedItems;
  };

  searchFilter = (items: any[], text: string) => {
    return items.filter((item) => item.title.toLowerCase().startsWith(text.toLowerCase()));
  };

  getUnselectedItems = () => {
    const { scheme, selected, items } = this.props;
    const { search } = this.state;
    const unselectedItems: any[] = exclude(convert(items, scheme), selected);
    return search ? this.searchFilter(unselectedItems, search) : unselectedItems;
  };

  removeSelected = (id: number) => {
    const { selected, onChange } = this.props;
    onChange(array.exclude(selected, id));
  };

  addNew = (ids: any) => {
    this.props.onChange([...this.props.selected, ..._.castArray(ids)]);
  };

  selectAll = () => {
    this.addNew(collection.extractKeyValue(this.getUnselectedItems(), 'id'));
  };

  clearAll = () => {
    this.props.onChange([]);
  };

  search = (e: any) => {
    this.setState({ search: e.currentTarget.value });
  };

  handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      !!this.props.onPressEscape && this.props.onPressEscape(event);
    }
  };

  render() {
    const { noSelectedMsg, selectAllBtn = true, scheme } = this.props;

    const selectedItems = this.getSelectedItems();
    const selectedListRender = (
      <Fragment>
        {selectedItems.map((item) => (
          <SelectedBtn
            key={item.id}
            title={item.title}
            onClick={(e: any) => {
              e.stopPropagation();
              this.removeSelected(item.id);
            }}
          />
        ))}
      </Fragment>
    );

    const selectedListEmptyMsg = <div css={styles.multiSelectMsg}>{noSelectedMsg || NO_SELECTED_MESSAGE}</div>;
    const selectedList = selectedItems.length ? selectedListRender : selectedListEmptyMsg;

    const idKey = scheme.id || 'id';

    return (
      <div css={styles.multiSelectBox} onKeyDown={(e: any) => this.handleEscapeKey(e)}>
        <div css={styles.selectedBox}>{selectedList}</div>
        <div css={styles.searchBox}>
          <Text onChange={this.search} />
        </div>
        <div css={styles.actionsBox}>
          {/*<OutlineBtn type="button" isSmall={true} title="Add New" onClick={this.addNew} />*/}
          {selectAllBtn && <OutlineBtn type="button" isSmall title="Select all" onClick={this.selectAll} />}
          <OutlineBtn type="button" isSmall={true} title="Clear all" onClick={this.clearAll} />
        </div>
        <div css={styles.variantsBox}>
          {_.chunk(this.getUnselectedItems(), 2).map(([first, second]) => (
            <div css={styles.chunkBox} key={`${_.get(first, idKey, '')} ${_.get(second, idKey, '')}`}>
              {_.compact([first, second]).map((item) => {
                return (
                  <div css={styles.variantBox} key={item[idKey]}>
                    <Checkbox
                      onChange={(e: any) => {
                        e.stopPropagation();
                        this.addNew(item[idKey]);
                      }}
                    />
                    <label
                      onClick={(e) => {
                        e.stopPropagation();
                        this.addNew(item[idKey]);
                      }}
                      htmlFor={item[idKey]}
                    >
                      {item.title}
                    </label>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default MultiSelect;
