import { put, call, takeEvery } from 'redux-saga/effects';
// actions
import * as actions from './actions';
// utils
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getResponseMessage } from '../../../../../utils/response';
import { push } from 'connected-react-router';
import Routes from '../../../../../routes';

export default function* watchMainAsync() {
  yield takeEvery(actions.GET_ALL_CATEGORIES_REQUEST, getAllCategoriesAsync);
  yield takeEvery(actions.CATEGORIES_LOAD_REQUEST, getCategoriesAsync);
  yield takeEvery(actions.CATEGORY_ADD_REQUEST, createCategoryAsync);
  yield takeEvery(actions.CATEGORY_EDIT_REQUEST, updateCategoryAsync);
  yield takeEvery(actions.CATEGORY_DELETE_REQUEST, deleteCategoryAsync);
  yield takeEvery(actions.CATEGORY_LOAD_REQUEST, getOneCategoryAsync);
}

// ALL CATEGORIES

export function* getAllCategoriesAsync({ api, payload }: any) {
  try {
    const params = payload;
    const path = '/machineries/categories';
    const response = yield call(api.get, path, { params });
    yield put(actions.getAllCategoriesRequestSuccess());
    yield put(actions.setAllCategories(response.data));
  } catch (err) {
    yield put(actions.categoriesLoadRequestError(err));
    yield call([toast, 'error'], getResponseMessage(err.data));
  }
}

// CATEGORIES LIST

export function* getCategoriesAsync({ api, payload }: any) {
  try {
    const params = payload;
    const path = '/machineries/categories';
    const response = yield call(api.get, path, { params });
    const { data, meta } = response.data;
    yield put(actions.categoriesLoadRequestSuccess({ data, params }, meta));
  } catch (err) {
    yield put(actions.categoriesLoadRequestError(err));
    yield call([toast, 'error'], getResponseMessage(err.data));
  }
}

export function* createCategoryAsync({ api, payload }: any) {
  try {
    yield call(api.post, '/machineries/categories', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    yield put(push(Routes.Categories));
  } catch (err) {
    yield put(actions.categoryAddRequestError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

// ONE CATEGORY

export function* updateCategoryAsync({ api, payload: { id, data } }: any) {
  try {
    const response = yield call(api.post, `/machineries/categories/${id}`, data);
    const category = _.get(response, 'data.data[0]');
    yield put(actions.categoryEditRequestSuccess(category));
    yield put(push(Routes.Categories));
    //yield call([toast, 'success'], 'Category successfully updated!');
  } catch (err) {
    yield put(actions.categoryEditRequestError(err));
    yield call([toast, 'error'], getResponseMessage(err.data));
  }
}

export function* getOneCategoryAsync({ api, payload: { id } }: any) {
  try {
    const response = yield call(api.get, `/machineries/categories/${id}`);
    const { data } = response.data;
    yield put(actions.oneCategoryLoadRequestSuccess(data));
  } catch (err) {
    yield put(actions.oneCategoryLoadRequestError(err));
    yield call([toast, 'error'], getResponseMessage(err.data));
  }
}

export function* deleteCategoryAsync({ api, payload }: any) {
  try {
    yield call(api.delete, `/machineries/categories/${payload}`);
    yield put(actions.categoryDeleteRequestSuccess({ id: payload }));
    //yield call([toast, 'success'], 'Category successfully deleted.');
  } catch (err) {
    yield put(actions.categoryDeleteRequestError(err));
    yield call([toast, 'error'], getResponseMessage(err.data));
  }
}
