import _ from 'lodash';

import * as array from './array';

export const makeChecker = (value1) => (value2) => {
  return value1 === value2;
};

export const isString = (value) => {
  return typeof value === 'string';
};

export const isArray = (value) => {
  return Array.isArray(value);
};

export const isUndefined = (value) => {
  return typeof value === 'undefined';
};

export const isNull = (value) => {
  return value === null;
};

export const isEmpty = (value) => {
  return isUndefined(value) || isNull(value);
};

export const isObject = (value) => {
  return typeof value === 'object' && !isNull(value);
};

export const isFunction = (value) => {
  return typeof value === 'function';
};

export const typeCheck = (typeCheckers, value, error) => {
  const checkResult = array.asArray(typeCheckers).some((typeChecker) => typeChecker(value));
  if (!checkResult) {
    if (isString(error)) {
      console.error(error);
    } else if (isFunction(error)) {
      error();
    }
  }
  return checkResult;
};

export const isSimilarValues = (value1, value2) => {
  let result;

  if (isArray(value1) && isArray(value2)) {
    result = !!_.difference(value1, value2).length;
  } else if (isArray(value1)) {
    result = value1.includes(value2);
  } else if (isArray(value2)) {
    result = value2.includes(value1);
  } else {
    result = value2 === value1;
  }

  return result;
};
