/** @jsx jsx */
import { jsx, css } from "@emotion/core";

import * as styles from "../../../../styles/styles";

export const contentBox = css({
  width: "100%",
  padding: "30px",
  boxSizing: "border-box"
});
