/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { withFormik, Field, FormikProps } from 'formik';
import * as yup from 'yup';
import { compose } from 'recompose';
import si from 'seamless-immutable';
import _ from 'lodash';

import { connect } from '../../../../utils/redux';

import { updateClientReq } from './store/actions';
import { Action } from '../../../../store/types';
import * as validatinShemas from '../../../../utils/validatinShemas';

import {} from './store/selectors';

import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../common/buttons/OutlineBtn';
import form from '../../../common/form';
import ImageUpload from '../../../common/form/kits/ImageUpload';
import page from '../../../common/page';
import routes from '../../../../routes';

import { Link } from 'react-router-dom';
import { IClient } from './store/types';
import { toast } from 'react-toastify';

interface FormValues {
  name: string;
  file?: string;
}

interface OtherProps {
  updateClientReq: Action<{}>;
  client: IClient;
}

interface FormProps {
  initialEmail?: string;
}

jsx;

const CreateForm = ({ errors, touched, values, setFieldValue, client }: OtherProps & FormikProps<FormValues>) => {
  const { FormRow, FormField, FormRadio, FormError, FormBlock, FieldsSet, ActionsRow, Form, FieldsArea } = form;
  const { Page, Breadcrumbs, PageHeader } = page;

  return (
    <Fragment>
      <PageHeader title="Edit client">
        <Breadcrumbs
          path={[
            { title: 'Clients', link: routes.Clients },
            { title: 'Edit client', link: routes.ClientsEdit.replace(':id', `${client.id}`) },
          ]}
        />
      </PageHeader>
      <Page>
        <Form>
          <div>
            <FieldsArea>
              <FormBlock>
                <FieldsSet>
                  <FormRow>
                    <label htmlFor="name">Name</label>
                    <FormField name="name" placeholder="name" />
                    <FormError name="name" errors={errors} touched={touched} />
                  </FormRow>

                  <FormRow>
                    <ImageUpload
                      name="file"
                      file={_.get(values, 'file')}
                      onSelect={(file: any) => {
                        setFieldValue('file', file.file);
                      }}
                      supportedFormats={validatinShemas.SUPPORTED_IMAGE_FORMATS}
                      onSelectUnsupported={(file: any) => {
                        toast.error(`Unsupported image format "${file.type}"`);
                      }}
                    />
                    <FormError name="file" errors={errors} touched={touched} />
                  </FormRow>
                </FieldsSet>
              </FormBlock>
            </FieldsArea>

            <ActionsRow>
              <Link to={routes.Clients}>
                <OutlineBtn title="Cancel" isWide={false} />
              </Link>
              <PrimaryBtn title="Update client" isWide={false} />
            </ActionsRow>
          </div>
        </Form>
      </Page>
    </Fragment>
  );
};

export const exchangeFormic = withFormik<FormProps & OtherProps, FormValues>({
  mapPropsToValues: ({ client }) => {
    return {
      id: client.id,
      name: client.name || '',
      file: client.file || '',
    };
  },

  validationSchema: yup.object({}).shape({
    name: validatinShemas.vNameReq,
  }),

  handleSubmit: ({ file, ...values }: any, { props: { updateClientReq, client }, setSubmitting }) => {
    file = file === client.file ? undefined : file;
    values = { ...values, file };
    updateClientReq(values);
  },
  displayName: 'EditForm',
});

export default compose<OtherProps & FormikProps<FormValues>, {}>(
  connect(
    {},
    { updateClientReq }
  ),
  exchangeFormic
)(CreateForm);
