/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import _ from 'lodash';

import * as styles from '../multiselect/styles';
import Checkbox from '../CheckboxWithRef';

export interface IListForSelect {
  selectAllListItems: () => void;
  getUnselectedItemsList: () => any[];
  addNewItemToList: (ids: any) => void;
  idKey: string;
}

jsx;

const ListForSelect = React.forwardRef<HTMLInputElement, IListForSelect>(
  ({ getUnselectedItemsList, addNewItemToList, idKey }, ref) => {
    const handleChange = (e: any, pickedItem: any) => {
      e && e.stopPropagation();
      addNewItemToList(pickedItem);
    };
    const unselectedItemsList = _.chunk(getUnselectedItemsList(), 2);

    return (
      <div>
        {unselectedItemsList.map(([first, second]) => (
          <div css={styles.chunkBox} key={`${_.get(first, idKey, '')} ${_.get(second, idKey, '')}`}>
            {_.compact([first, second]).map((item: any) => {
              return (
                <div css={styles.variantBox} key={item[idKey]}>
                  <Checkbox onChange={(e: any) => handleChange(e, item[idKey])} ref={ref} />
                  <label onClick={(e: any) => handleChange(e, item[idKey])} htmlFor={item[idKey]}>
                    {item.title}
                  </label>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  }
);
export default ListForSelect;
