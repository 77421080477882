/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, MouseEvent, ReactElement } from 'react';

import Modal from '../Modal';
import ModalHeader from '../ModalHeader';
import ModalBody from '../ModalBody';
import ModalFooter from '../ModalFooter';
import ModalActions from '../ModalActions';
import buttons from '../../buttons';

export interface IProps {
  title?: string;
  onConfirm: (e: MouseEvent<HTMLElement>) => void;
  onDecline: (e: MouseEvent<HTMLElement>) => void;
  isOpen: boolean;
}

jsx;

export const ModalConfirm: FunctionComponent<IProps> = ({
  children,
  onConfirm,
  onDecline,
  isOpen,
  title = 'Confirm',
}): ReactElement<IProps> => {
  const { PrimaryBtn, OutlineBtn } = buttons;

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <ModalActions>
          <PrimaryBtn title="Yes" onClick={onConfirm} />
          <OutlineBtn title="No" onClick={onDecline} />
        </ModalActions>
      </ModalFooter>
    </Modal>
  );
};

export default ModalConfirm;
