import _ from 'lodash';
import * as func from './func';
import * as object from './object';
import * as collection from './collection';

export const exclude = (array, exclude) => {
  return _.difference(array, _.castArray(exclude));
};

export const prev = (array, resolver, isLoop = true) => {
  const borderItem = isLoop ? _.last(array) : null;
  const { result } = array.reduce(
    ({ result, resolve }, item) => {
      if (resolve) {
        return { result, resolve };
      } else if (resolver(item)) {
        return { result: result, resolve: true };
      } else {
        return { result: item, resolve: false };
      }
    },
    { result: borderItem, resolve: false }
  );
  return result;
};

export const next = (array, resolver, isLoop = true) => {
  return prev(_.reverse([...array]), resolver, isLoop);
};

export const first = (array) => {
  return object.prop(array, 0, undefined);
};

export const last = (array) => {
  const lastIndex = array.length - 1;
  return lastIndex >= 0 ? object.prop(array, lastIndex) : undefined;
};

export const asArray = (value) => {
  return func.isArray(value) ? value : [value];
};

//value: [[1, 2], [3, 4]] => [[1, 3], [2, 4]]
export const splitByColumns = (value, countColl = null) => {
  countColl = countColl || object.get(value, [0, 'length'], 0);
  return Array(countColl)
    .fill([])
    .map((coll, index) => {
      return collection.extractKeyValue(value, index);
    });
};
