/** @jsx jsx */
import { Fragment, FunctionComponent, ReactElement } from 'react';
import { jsx } from '@emotion/core';

import { Route, Switch, Redirect } from 'react-router';
import routes from '../../../../routes';

import List from './List';
import Create from './Create';
import Edit from './Edit';
import Agents from './Agents/Agents';
import withAcl, { IWrappedProps } from '../withAcl';

interface IProps extends IWrappedProps {}

jsx;
const Producers: FunctionComponent<IProps> = ({ test }): ReactElement<IProps> => {
  return (
    <Fragment>
      <Switch>
        <Route exact path={routes.Clients} component={List} />
        {test('client', 'C') && <Route exact path={routes.ClientsCreate} component={Create} />}
        {test('client', 'U') && <Route exact path={routes.ClientsEdit} component={Edit} />}
        {test('client', 'R') && <Route path={routes.ClientAgents} component={(props: any) => <Agents {...props} />} />}
        <Route component={() => <Redirect to={routes.Clients} />} />
      </Switch>
    </Fragment>
  );
};

export default withAcl(Producers);
