import si, { ImmutableObject } from 'seamless-immutable';
import _ from 'lodash';
import { processActions } from '../../../../../utils/redux';
import * as r from '../../../../../utils/reducers';

import { getListState, getItemState, ListStatuses, ItemStatuses } from '../../../../../utils/store/state/state';

import { SupplierListState, SupplierItemState } from './types';

import * as actions from './actions';

interface IState {
  list: SupplierListState;
  listForSelect: SupplierListState;
  listFiltered: SupplierListState;
  item: SupplierItemState;
}

const INITIAL_STATE: ImmutableObject<IState> = si({
  // LIST
  list: getListState({
    page: 0,
    size: 20,
    searchKey: 'name',
    search: '',
    filterKey: 'function.id',
    filter: 0,
  }),
  // LIST FOR SELECT
  listForSelect: getListState({
    page: 0,
    size: 1000,
  }),
  listFiltered: getListState({
    page: 0,
    size: 1000,
  }),
  // ITEM
  item: getItemState({ id: 0 }),
});

const listReducer = r.createReducer('list');
const listForSelectReducer = r.createReducer('listForSelect');
const listFilteredReducer = r.createReducer('listFiltered');
const itemReducer = r.createReducer('item');

export default processActions(
  () => ({
    // LIST
    [actions.GET_SUPPLIERS_REQ]: listReducer(r.loadingReducer),
    [actions.GET_SUPPLIERS_REQ_SUCCESS]: (store: ImmutableObject<IState>, action: any) => {
      const {
        payload: { data, params },
        meta,
      } = action;

      return store
        .setIn(['list', 'data'], params.page > 0 ? store.list.data.concat(data) : data)
        .setIn(['list', 'status'], ListStatuses.Actual)
        .setIn(['list', 'meta'], meta)
        .setIn(['list', 'params'], params);
    },
    [actions.GET_SUPPLIERS_REQ_ERROR]: listReducer(r.errorReducer),
    [actions.SET_SUPPLIERS]: listReducer(r.listDataReducer),
    [actions.UPDATE_SUPPLIER_IN_LIST]: (store: ImmutableObject<IState>, { payload }: any) => {
      const index = _.findIndex(store.list.data, { id: payload.id });
      return store.setIn(['list', 'data', index], payload);
    },

    [actions.REFRESH_SUPPLIERS]: listReducer(r.refreshReducer),
    [actions.RESET_SUPPLIERS]: listReducer(r.resetReducer),
    [actions.SET_SUPPLIERS_PAGE]: listReducer(r.paramsPageReducer),
    [actions.SET_SUPPLIERS_SEARCH_TEXT]: listReducer(r.paramsSearchReducer),
    [actions.SET_SUPPLIERS_FILTER]: listReducer(r.paramsFilterReducer),
    [actions.SET_SUPPLIERS_PARAMS]: listReducer(r.paramsReducer),

    // SELECT LIST
    [actions.REFRESH_SUPPLIERS_FOR_SELECT]: listForSelectReducer(r.refreshReducer),
    [actions.GET_SUPPLIERS_FOR_SELECT_REQ]: listForSelectReducer(r.loadingReducer),
    [actions.GET_SUPPLIERS_FOR_SELECT_REQ_SUCCESS]: listForSelectReducer(r.successReducer),
    [actions.GET_SUPPLIERS_FOR_SELECT_REQ_ERROR]: listForSelectReducer(r.errorReducer),
    [actions.SET_SUPPLIERS_FOR_SELECT]: listForSelectReducer(r.listDataReducer),

    // FILTERED LIST
    [actions.REFRESH_SUPPLIERS_FILTERED]: listFilteredReducer(r.refreshReducer),
    [actions.GET_SUPPLIERS_FILTERED_REQ]: listFilteredReducer(r.loadingReducer),
    [actions.GET_SUPPLIERS_FILTERED_REQ_SUCCESS]: listFilteredReducer(r.successReducer),
    [actions.GET_SUPPLIERS_FILTERED_REQ_ERROR]: listFilteredReducer(r.errorReducer),
    [actions.SET_SUPPLIERS_FILTERED]: listFilteredReducer(r.listDataReducer),

    // ITEM CREATE
    [actions.CREATE_SUPPLIER_REQ]: itemReducer(r.loadingReducer),
    [actions.CREATE_SUPPLIER_REQ_SUCCESS]: (store: ImmutableObject<IState>, { payload }: any) => {
      return store
        .setIn(['list', 'data'], si([payload]).concat(store.list.data))
        .setIn(['item', 'status'], ItemStatuses.Actual);
    },
    [actions.CREATE_SUPPLIER_REQ_ERROR]: itemReducer(r.errorReducer),
    [actions.CREATE_SUPPLIER_START]: itemReducer(r.startCreateReducer),
    [actions.CREATE_SUPPLIER]: itemReducer(r.createdReducer),

    // ITEM CLEAR
    [actions.CLEAR_SUPPLIER]: itemReducer(r.clearData),

    // ITEM GET
    [actions.GET_SUPPLIER_REQ]: itemReducer(r.loadingReducer),
    [actions.GET_SUPPLIER_REQ_SUCCESS]: itemReducer(r.successReducer),
    [actions.GET_SUPPLIER_REQ_ERROR]: itemReducer(r.errorReducer),
    [actions.SET_SUPPLIER]: itemReducer(r.itemDataReducer),

    // ITEM UPDATE
    [actions.UPDATE_SUPPLIER_REQ]: itemReducer(r.loadingReducer),
    [actions.UPDATE_SUPPLIER_REQ_SUCCESS]: itemReducer(r.successReducer),
    [actions.UPDATE_SUPPLIER_REQ_ERROR]: itemReducer(r.errorReducer),
    [actions.UPDATE_SUPPLIER]: itemReducer(r.updatedReducer),

    // ITEM DELETE
    [actions.DELETE_SUPPLIER]: itemReducer(r.loadingReducer),
    [actions.DELETE_SUPPLIER_REQ_SUCCESS]: (store: ImmutableObject<IState>, { payload }: any) => {
      return store
        .setIn(['list', 'data'], store.list.data.filter((item) => item.id !== payload.id))
        .setIn(['item', 'status'], ItemStatuses.Actual);
    },
    [actions.DELETE_SUPPLIER_REQ_ERROR]: itemReducer(r.errorReducer),
    [actions.DELETE_SUPPLIER]: itemReducer(r.deletedReducer),

    /*[actions.SET_SUPPLIER]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('item', _.head(action.payload));
    },*/

    [actions.UPDATE_SUPPLIER]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('item', _.head(action.payload));
    },
  }),
  INITIAL_STATE
);
