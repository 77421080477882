/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core';
// components
import MultiSelectInput from '../../../../common/form/multiselect/MultiSelectInput';
// utils
import _ from 'lodash';
import withAcl, { IWrappedProps } from '../../withAcl';
// types
import { Action } from '../../../../../store/types';
import { SupplierFunction } from '../../SupplierFunctions/store/types';
import { ISupplier } from '../../Suppliers/store/types';
import { IProduction } from '../store/types';

import withVisualContext from '../../../../../contexts/withVisualContext';
import { IVisualContext } from '../../../../../contexts/VisualContext';

interface IProps extends IWrappedProps {
  activities: any;
  supplierFunctions: SupplierFunction[];
  suppliers: ISupplier[];
  productionId: number;
  subproductions?: IProduction[];
  onChangeParams: Action<{}>;
  gates: any[] | null;
  visual: IVisualContext;
}

interface IState {
  selectedSupplier: number[];
  selectedFunction: number[];
  selectedSubproduction: number[];
  selectedGates: number[];
}

jsx;
class ActivityFilters extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedSupplier: [],
      selectedFunction: [],
      selectedSubproduction: [],
      selectedGates: [],
    };
  }

  onSuppliersChange = (value: number[]) => {
    const { onChangeParams } = this.props;

    if (value.length) {
      if (value.includes(0)) {
        const values = _.compact(value);

        onChangeParams({
          'filter.supplier.id': values.length > 0 ? values : null,
          'filter.activity.allSuppliers': true,
        });
      } else {
        onChangeParams({ 'filter.supplier.id': value, 'filter.activity.allSuppliers': null });
      }
    } else {
      onChangeParams({ 'filter.supplier.id': null, 'filter.activity.allSuppliers': null });
    }

    this.setState({
      selectedSupplier: value,
    });
  };

  onFunctionsChange = (value: number[]) => {
    const { onChangeParams } = this.props;

    if (value.length) {
      if (value.includes(0)) {
        const values = _.compact(value);

        onChangeParams({
          'filter.functions.id': values.length > 0 ? values : null,
          'filter.activity.allFunctions': true,
        });
      } else {
        onChangeParams({ 'filter.functions.id': value, 'filter.activity.allFunctions': null });
      }
    } else {
      onChangeParams({ 'filter.functions.id': null, 'filter.activity.allFunctions': null });
    }

    this.setState({
      selectedFunction: value,
    });
  };

  onSubproductionsChange = (value: number[]) => {
    const { productionId, onChangeParams } = this.props;

    const lastValue = _.last(value);
    const isAll = lastValue === 0;
    const stateValue = isAll ? [0] : _.compact(value);

    onChangeParams({ 'filter.production.id': isAll ? [productionId] : _.compact(value) });
    this.setState({
      selectedSubproduction: stateValue,
    });
  };

  onGatesChange = (value: number[]) => {
    const { onChangeParams } = this.props;
    onChangeParams({ 'filter.gate.id': value });
    this.setState({
      selectedGates: value,
    });
  };

  render() {
    const { activities, supplierFunctions, suppliers, subproductions, gates, test, visual } = this.props;
    const { selectedSupplier, selectedFunction, selectedSubproduction } = this.state;

    return (
      <div css={style.wrapper}>
        <div>Filtered by</div>

        <div css={style.filter}>
          <MultiSelectInput
            items={[{ id: 0, name: `All production ${visual.labels.functions}` }, ...supplierFunctions]}
            noSelectedMsg={`Choose ${visual.labels.functions}`}
            selected={selectedFunction}
            scheme={{ id: 'id', title: 'name' }}
            onChange={this.onFunctionsChange}
          />
        </div>

        {test('supplier', 'R') && (
          <div css={style.filter}>
            <MultiSelectInput
              items={[{ id: 0, name: 'All production suppliers' }, ...suppliers]}
              noSelectedMsg="Choose suppliers"
              selected={selectedSupplier}
              scheme={{ id: 'id', title: 'name' }}
              onChange={this.onSuppliersChange}
            />
          </div>
        )}

        {subproductions && !!subproductions.length && (
          <div css={style.filter}>
            <MultiSelectInput
              items={[{ id: 0, name: 'Only main production' }, ...subproductions]}
              noSelectedMsg="Choose subproductions"
              selected={selectedSubproduction}
              selectAllBtn={false}
              scheme={{ id: 'id', title: 'name' }}
              onChange={this.onSubproductionsChange}
            />
          </div>
        )}
        {gates && (
          <div css={style.filter}>
            <MultiSelectInput
              items={gates}
              noSelectedMsg="Choose gates"
              selected={activities.params['filter.gate.id'] || []}
              selectAllBtn={false}
              scheme={{ id: 'id', title: 'name' }}
              onChange={this.onGatesChange}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withAcl(withVisualContext(ActivityFilters));

const style = {
  wrapper: css`
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  filter: css`
    display: flex;
    margin-left: 20px;
    min-width: 180px;
  `,
  option: css`
    position: relative;
    border-bottom: 1px solid #e6e6e6;
  `,
};
