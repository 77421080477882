/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Component, forwardRef, Fragment } from 'react';

import DateRange, { IDataRangeValue, IDataRangeProps } from './DateRange';
import Text from '../Text';
import form from '../index';

export interface IDataRangeWithName {
  name: string;
  range: IDataRangeValue;
}

export interface IDateRangeWithNameProps
  extends Pick<IDataRangeProps, 'fromLabel' | 'toLabel' | 'disabledFrom' | 'disabledTo'> {
  value: IDataRangeWithName;
  nameLabel: string;
  onChange: (a: IDataRangeWithName) => void;
  shoudSetDefaultValues?: boolean;
  onBlur?: () => void;
  forwardRef?: any;
  isFocused?: boolean;
}

export interface IState {}

jsx;
class DateRangeWithName extends Component<IDateRangeWithNameProps, IState> {
  constructor(props: IDateRangeWithNameProps) {
    super(props);
  }

  changeRange = (range: IDataRangeValue) => {
    const { value, onChange } = this.props;
    onChange({ ...value, range });
  };

  changeName = (e: any) => {
    const { value, onChange } = this.props;
    const name = e.currentTarget.value;
    const newValue = { ...value, ...{ name } };
    onChange(newValue);
  };

  render() {
    const { FormRowSections } = form;
    const { nameLabel, value, isFocused, shoudSetDefaultValues = false } = this.props;

    return (
      <Fragment>
        <FormRowSections width={3}>
          <label htmlFor="subProduction">{nameLabel}</label>
          <Text onChange={this.changeName} value={this.props.value.name} autoFocus={isFocused} />
        </FormRowSections>

        <DateRange
          {...this.props}
          value={value.range}
          collSize={3}
          shoudSetDefaultValues={shoudSetDefaultValues}
          onChange={(value) => {
            this.changeRange(value);
          }}
        />
      </Fragment>
    );
  }
}

export default forwardRef<HTMLInputElement, IDateRangeWithNameProps>((props, ref) => (
  <DateRangeWithName {...props} forwardRef={ref} />
));
