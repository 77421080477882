/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import { withFormik, FormikProps } from 'formik';
import * as yup from 'yup';
import { compose } from 'recompose';

import { connect } from '../../../../../utils/redux';

import { createSupplierAgentReq } from './store/actions';
import { Action } from '../../../../../store/types';
import * as validatinShemas from '../../../../../utils/validatinShemas';

import PrimaryBtn from '../../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../../common/buttons/OutlineBtn';
import form from '../../../../common/form';
import page from '../../../../common/page';
import routes from '../../../../../routes';

import { Link } from 'react-router-dom';
import { Colors } from '../../../../../styles/styles';

interface FormValues {
  name: string;
  phone: string;
  email: string;
  sendInvitation: boolean;
}

interface OtherProps {
  createSupplierAgentReq: Action<{}>;
  ownerId: string;
}

interface FormProps {
  initialEmail?: string;
}

jsx;

const CreateForm = ({ errors, touched, values, setFieldValue, ownerId }: OtherProps & FormikProps<FormValues>) => {
  const { FormRow, FormField, FormError, FormBlock, FieldsSet, ActionsRow, Form, FieldsArea, FormRadio } = form;
  const { Page, Breadcrumbs, PageHeader } = page;

  return (
    <Fragment>
      <PageHeader title="New agent">
        <Breadcrumbs
          path={[
            { title: 'Suppliers', link: routes.Suppliers },
            { title: 'Agents', link: routes.SupplierAgents.replace(':owner_id', ownerId) },
            { title: 'New agent', link: routes.SupplierAgentCreate.replace(':owner_id', ownerId) },
          ]}
        />
      </PageHeader>
      <Page>
        <Form>
          <div>
            <FieldsArea>
              <FormBlock>
                <FieldsSet>
                  <FormRow>
                    <label htmlFor="name">Name</label>
                    <FormField name="name" placeholder="name" />
                    <FormError name="name" errors={errors} touched={touched} />
                  </FormRow>

                  <FormRow>
                    <label htmlFor="email">Email</label>
                    <FormField name="email" placeholder="email" />
                    <FormError name="email" errors={errors} touched={touched} />
                  </FormRow>

                  <FormRow>
                    <label htmlFor="phone">Phone</label>
                    <FormField name="phone" placeholder="+1234567890" />
                    <FormError name="phone" errors={errors} touched={touched} />
                    <p css={style.description}>
                      Please add phone number in international format. Use only '+' and numbers.
                    </p>
                  </FormRow>

                  <FormRow>
                    <label htmlFor="sendInvitation">Send invitation</label>
                    <FormRadio name="sendInvitation" value={true} values={values} title="Yes" />
                    <FormRadio name="sendInvitation" value={false} values={values} title="No" />
                    <FormError name="sendInvitation" errors={errors} touched={touched} />
                  </FormRow>
                </FieldsSet>
              </FormBlock>
            </FieldsArea>

            <ActionsRow>
              <Link to={routes.SupplierAgents.replace(':owner_id', ownerId)}>
                <OutlineBtn title="Cancel" isWide={false} />
              </Link>
              <PrimaryBtn type="submit" title="Create agent" isWide={false} />
            </ActionsRow>
          </div>
        </Form>
      </Page>
    </Fragment>
  );
};

export const exchangeFormic = withFormik<FormProps & OtherProps, FormValues>({
  enableReinitialize: true,

  mapPropsToValues: (props) => {
    return {
      name: '',
      phone: '',
      email: '',
      sendInvitation: false,
    };
  },

  validationSchema: yup.object({}).shape({
    name: validatinShemas.vNameReq,
    phone: validatinShemas.vPhoneReq,
    email: validatinShemas.vEmailReq,
  }),

  handleSubmit: (values, { props: { ownerId, createSupplierAgentReq }, setSubmitting }) => {
    createSupplierAgentReq({ values, ownerId });
  },
  displayName: 'CreateSupplierAgentForm',
});

export default compose<OtherProps & FormikProps<FormValues>, {}>(
  connect(
    {},
    { createSupplierAgentReq }
  ),
  exchangeFormic
)(CreateForm);

const style = {
  description: css`
    font-size: 12px;
    color: ${Colors.LightBlack};
    margin: 5px;
  `,
};
