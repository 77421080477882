/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import * as styles from './styles';

export interface IFormRowProps {
  className?: string;
}

jsx;
export const FormRow: FunctionComponent<IFormRowProps> = ({
  children,
  className = '',
}): ReactElement<IFormRowProps> => {
  return (
    <div css={styles.formRow} className={className}>
      {children}
    </div>
  );
};

export default FormRow;
