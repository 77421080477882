import { createAction } from 'redux-actions';
import { Action } from '../../../../../store/types';
import { createAsyncAction } from '../../../../../utils/async/actions';
import reducersRegister from '../../../../../utils/reducersRegister';
import sagasRegister from '../../../../../utils/sagasRegister';
import reducers from './reducers';
import sagas from './sagas';

reducersRegister.register('supplierFunctions', reducers);
sagasRegister.register(sagas);

/******************************************************************************/
/****************************** SUPPLIER_FUNCTIONS ****************************/
/******************************************************************************/

export const GET_SUPPLIER_FUNCTIONS_REQ: string = 'GET_SUPPLIER_FUNCTIONS_REQ';
export const GET_SUPPLIER_FUNCTIONS_REQ_SUCCESS: string = 'GET_SUPPLIER_FUNCTIONS_REQ_SUCCESS';
export const GET_SUPPLIER_FUNCTIONS_REQ_ERROR: string = 'GET_SUPPLIER_FUNCTIONS_REQ_ERROR';

export const SET_SUPPLIER_FUNCTIONS: string = 'SET_SUPPLIER_FUNCTIONS';
export const REFRESH_SUPPLIER_FUNCTIONS: string = 'REFRESH_SUPPLIER_FUNCTIONS';

export const SET_SUPPLIER_FUNCTIONS_PAGE: string = 'SET_SUPPLIER_FUNCTIONS_PAGE';
export const SET_SUPPLIER_FUNCTIONS_SEARCH_TEXT: string = 'SET_SUPPLIER_FUNCTIONS_SEARCH_TEXT';
export const SET_SUPPLIER_FUNCTIONS_FILTER: string = 'SET_SUPPLIER_FUNCTIONS_FILTER';

export const getSupplierFunctionsReq: Action<{}> = createAsyncAction(GET_SUPPLIER_FUNCTIONS_REQ);
export const getSupplierFunctionsReqSuccess: Action<{}> = createAction(GET_SUPPLIER_FUNCTIONS_REQ_SUCCESS);
export const getSupplierFunctionsReqError: Action<{}> = createAction(GET_SUPPLIER_FUNCTIONS_REQ_ERROR);

export const setSupplierFunctions: Action<{}> = createAction(SET_SUPPLIER_FUNCTIONS);
export const refreshSupplierFunctions: Action<{}> = createAction(REFRESH_SUPPLIER_FUNCTIONS);

export const setSupplierFunctionsPage: Action<{}> = createAction(SET_SUPPLIER_FUNCTIONS_PAGE);
export const setSupplierFunctionsSearchText: Action<{}> = createAction(SET_SUPPLIER_FUNCTIONS_SEARCH_TEXT);
export const setSupplierFunctionsFilter: Action<{}> = createAction(SET_SUPPLIER_FUNCTIONS_FILTER);

/******************************************************************************/
/******************************* CREATE SUPPLIER ********************************/
/******************************************************************************/

export const CREATE_SUPPLIER_FUNCTION_REQ: string = 'CREATE_SUPPLIER_FUNCTION_REQ';
export const CREATE_SUPPLIER_FUNCTION_REQ_SUCCESS: string = 'CREATE_SUPPLIER_FUNCTION_REQ_SUCCESS';
export const CREATE_SUPPLIER_FUNCTION_REQ_ERROR: string = 'CREATE_SUPPLIER_FUNCTION_REQ_ERROR';

export const CREATE_SUPPLIER_START: string = 'CREATE_SUPPLIER_START';
export const CREATE_SUPPLIER: string = 'CREATE_SUPPLIER';

export const createSupplierFunctionReq: Action<{}> = createAsyncAction(CREATE_SUPPLIER_FUNCTION_REQ);
export const createSupplierFunctionReqSuccess: Action<{}> = createAction(CREATE_SUPPLIER_FUNCTION_REQ_SUCCESS);
export const createSupplierFunctionReqError: Action<{}> = createAction(CREATE_SUPPLIER_FUNCTION_REQ_ERROR);
export const createSupplierStart: Action<{}> = createAction(CREATE_SUPPLIER_START);
export const createSupplier: Action<{}> = createAction(CREATE_SUPPLIER);

/******************************************************************************/
/******************************* GET ONE SUPPLIER   *****************************/
/******************************************************************************/

export const GET_SUPPLIER_FUNCTION_REQ: string = 'GET_SUPPLIER_FUNCTION_REQ';
export const GET_SUPPLIER_FUNCTION_REQ_SUCCESS: string = 'GET_SUPPLIER_FUNCTION_REQ_SUCCESS';
export const GET_SUPPLIER_FUNCTION_REQ_ERROR: string = 'GET_SUPPLIER_FUNCTION_REQ_ERROR';
export const SET_SUPPLIER: string = 'SET_SUPPLIER';

export const getSupplierFunctionReq: Action<{}> = createAsyncAction(GET_SUPPLIER_FUNCTION_REQ);
export const getSupplierFunctionReqSuccess: Action<{}> = createAction(GET_SUPPLIER_FUNCTION_REQ_SUCCESS);
export const getSupplierFunctionReqError: Action<{}> = createAction(GET_SUPPLIER_FUNCTION_REQ_ERROR);
export const setSupplier: Action<{}> = createAction(SET_SUPPLIER);

/******************************************************************************/
/******************************* UPDATE ONE SUPPLIER   **************************/
/******************************************************************************/

export const UPDATE_SUPPLIER_FUNCTION_REQ: string = 'UPDATE_SUPPLIER_FUNCTION_REQ';
export const UPDATE_SUPPLIER_FUNCTION_REQ_SUCCESS: string = 'UPDATE_SUPPLIER_FUNCTION_REQ_SUCCESS';
export const UPDATE_SUPPLIER_FUNCTION_REQ_ERROR: string = 'UPDATE_SUPPLIER_FUNCTION_REQ_ERROR';
export const UPDATE_SUPPLIER: string = 'UPDATE_SUPPLIER';

export const updateSupplierFunctionReq: Action<{}> = createAsyncAction(UPDATE_SUPPLIER_FUNCTION_REQ);
export const updateSupplierFunctionReqSuccess: Action<{}> = createAction(UPDATE_SUPPLIER_FUNCTION_REQ_SUCCESS);
export const updateSupplierFunctionReqError: Action<{}> = createAction(UPDATE_SUPPLIER_FUNCTION_REQ_ERROR);
export const updateSupplier: Action<{}> = createAction(UPDATE_SUPPLIER);

/******************************************************************************/
/******************************* DELETE ONE SUPPLIER   **************************/
/******************************************************************************/

export const DELETE_SUPPLIER_FUNCTION_REQ: string = 'DELETE_SUPPLIER_FUNCTION_REQ';
export const DELETE_SUPPLIER_FUNCTION_REQ_SUCCESS: string = 'DELETE_SUPPLIER_FUNCTION_REQ_SUCCESS';
export const DELETE_SUPPLIER_FUNCTION_REQ_ERROR: string = 'DELETE_SUPPLIER_FUNCTION_REQ_ERROR';
export const DELETE_SUPPLIER: string = 'DELETE_SUPPLIER';

export const deleteSupplierFunctionReq: Action<{}> = createAsyncAction(DELETE_SUPPLIER_FUNCTION_REQ);
export const deleteSupplierFunctionReqSuccess: Action<{}> = createAction(DELETE_SUPPLIER_FUNCTION_REQ_SUCCESS);
export const deleteSupplierFunctionReqError: Action<{}> = createAction(DELETE_SUPPLIER_FUNCTION_REQ_ERROR);
export const deleteSupplier: Action<{}> = createAction(DELETE_SUPPLIER);

// requests definitions names

export const SUPPLIER_FUNCTIONS_LOAD_REQUEST = 'SUPPLIER_FUNCTIONS_LOAD_REQUEST';
export const SUPPLIER_FUNCTIONS_LOAD_REQUEST_SUCCESS = 'SUPPLIER_FUNCTIONS_LOAD_REQUEST_SUCCESS';
export const SUPPLIER_FUNCTIONS_LOAD_REQUEST_ERROR = 'SUPPLIER_FUNCTIONS_LOAD_REQUEST_ERROR';

export const SUPPLIER_FUNCTIONS_ADD_REQUEST = 'SUPPLIER_FUNCTIONS_ADD_REQUEST';
export const SUPPLIER_FUNCTIONS_ADD_REQUEST_SUCCESS = 'SUPPLIER_FUNCTIONS_ADD_REQUEST_SUCCESS';
export const SUPPLIER_FUNCTIONS_ADD_REQUEST_ERROR = 'SUPPLIER_FUNCTIONS_ADD_REQUEST_ERROR';

export const SUPPLIER_FUNCTIONS_DELETE_REQUEST = 'SUPPLIER_FUNCTIONS_DELETE_REQUEST';
export const SUPPLIER_FUNCTIONS_DELETE_REQUEST_ERROR = 'SUPPLIER_FUNCTIONS_DELETE_REQUEST_ERROR';
export const SUPPLIER_FUNCTIONS_DELETE_REQUEST_SUCCESS = 'SUPPLIER_FUNCTIONS_DELETE_REQUEST_SUCCESS';

export const SUPPLIER_FUNCTIONS_UPDATE_REQUEST = 'SUPPLIER_FUNCTIONS_UPDATE_REQUEST';
export const SUPPLIER_FUNCTIONS_UPDATE_REQUEST_SUCCESS = 'SUPPLIER_FUNCTIONS_UPDATE_REQUEST_SUCCESS';
export const SUPPLIER_FUNCTIONS_UPDATE_REQUEST_ERROR = 'SUPPLIER_FUNCTIONS_UPDATE_REQUEST_ERROR';

export const SUPPLIER_FUNCTIONS_LOAD = 'SUPPLIER_FUNCTIONS_LOAD';
export const SUPPLIER_FUNCTIONS_ADD = 'SUPPLIER_FUNCTIONS_ADD';
export const SUPPLIER_FUNCTIONS_DELETE = 'SUPPLIER_FUNCTIONS_DELETE';
export const SUPPLIER_FUNCTIONS_UPDATE = 'SUPPLIER_FUNCTIONS_UPDATE';
export const SUPPLIER_FUNCTIONS_SHOW_MODAL_UPDATE = 'SUPPLIER_FUNCTIONS_SHOW_MODAL_UPDATE';
export const SUPPLIER_FUNCTIONS_SHOW_MODAL_DELETE = 'SUPPLIER_FUNCTIONS_SHOW_MODAL_DELETE';

// ACTIONS

export const supplierFunctionsLoadRequest: Action<{}> = createAsyncAction(SUPPLIER_FUNCTIONS_LOAD_REQUEST);
export const supplierFunctionsLoadRequestSuccess: Action<{}> = createAction(
  SUPPLIER_FUNCTIONS_LOAD_REQUEST_SUCCESS,
  (...args) => args[0],
  (...args) => args[1]
);
export const supplierFunctionsLoadRequestError: Action<{}> = createAction(SUPPLIER_FUNCTIONS_LOAD_REQUEST_ERROR);
export const supplierFunctionsDeleteRequest: Action<{}> = createAsyncAction(SUPPLIER_FUNCTIONS_DELETE_REQUEST);
export const supplierFunctionsDeleteRequestSuccess: Action<{}> = createAction(
  SUPPLIER_FUNCTIONS_DELETE_REQUEST_SUCCESS
);
export const supplierFunctionsDeleteRequestError: Action<{}> = createAction(SUPPLIER_FUNCTIONS_DELETE_REQUEST_ERROR);
export const supplierFunctionsAddRequest: Action<{}> = createAsyncAction(SUPPLIER_FUNCTIONS_ADD_REQUEST);
export const supplierFunctionsAddRequestSuccess: Action<{}> = createAction(SUPPLIER_FUNCTIONS_ADD_REQUEST_SUCCESS);
export const supplierFunctionsAddRequestError: Action<{}> = createAction(SUPPLIER_FUNCTIONS_ADD_REQUEST_ERROR);
export const supplierFunctionsUpdateRequest: Action<{}> = createAsyncAction(SUPPLIER_FUNCTIONS_UPDATE_REQUEST);
export const supplierFunctionsUpdateRequestSuccess: Action<{}> = createAction(
  SUPPLIER_FUNCTIONS_UPDATE_REQUEST_SUCCESS
);
export const supplierFunctionsUpdateRequestError: Action<{}> = createAction(SUPPLIER_FUNCTIONS_UPDATE_REQUEST_ERROR);
export const supplierFunctionsShowModalUpdate: Action<{}> = createAction(SUPPLIER_FUNCTIONS_SHOW_MODAL_UPDATE);
export const supplierFunctionsShowModalDelete: Action<{}> = createAction(SUPPLIER_FUNCTIONS_SHOW_MODAL_DELETE);

export const supplierFunctionsAdd: Action<{}> = createAction(SUPPLIER_FUNCTIONS_ADD);
export const supplierFunctionsDelete: Action<{}> = createAction(SUPPLIER_FUNCTIONS_DELETE);
export const supplierFunctionsUpdate: Action<{}> = createAction(SUPPLIER_FUNCTIONS_UPDATE);
