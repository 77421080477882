/** @jsx jsx */
import { jsx } from '@emotion/core';
import { compose, lifecycle } from 'recompose';
import { RouteComponentProps } from 'react-router';

import { connect } from '../../../../../utils/redux';

import { getClientAgentReq, clearClientAgent } from './store/actions';
import { Action } from '../../../../../store/types';
import { clientAgent } from './store/selectors';
import EditForm from './EditForm';

import * as styles from './styles';
import { IClientAgent } from './store/types';

interface FormValues extends Partial<IClientAgent> {}

interface MatchParams {
  id?: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  getClientAgentReq: Action<{}>;
  clearClientAgent: Action<{}>;
  clientAgent: IClientAgent;
  ownerId: string;
}

jsx;

const withLifecycle = lifecycle<IProps, {}>({
  componentDidMount() {
    const { getClientAgentReq, clearClientAgent } = this.props;
    clearClientAgent();
    getClientAgentReq(this.props.match.params);
  },
});

const Edit = (props: IProps) => {
  const form = props.clientAgent ? <EditForm {...props} /> : <div>Loading...</div>;
  return <div css={styles.formBox}>{form}</div>;
};

interface FormProps {
  initialEmail?: string;
}

export default compose<IProps, {}>(
  connect(
    { clientAgent },
    { getClientAgentReq, clearClientAgent }
  ),
  withLifecycle
)(Edit);
