/** @jsx jsx */
import { jsx } from '@emotion/core';
import { MouseEvent, Fragment, Component, ReactElement } from 'react';
import * as React from 'react';

import { Omit } from '../../../../utils/typeHandlers';
import Select, { ISelectProps } from '../../form/select/Select';
import Option from '../../form/select/Option';
import ToolbarActionBox from './ToolbarActionBox';

import * as styles from './styles';

export interface IProps extends Omit<ISelectProps, 'children' | 'selected'> {
  title?: string;
  selected: number | string | null;
  defaultId?: number;
  defaultTitle?: string;
  isOptionAll?: boolean;
}

export interface IState {}

jsx;

class Filter extends Component<IProps, IState> {
  render() {
    const { title = 'Filter', items, scheme, onChange, selected } = this.props;
    const resultScheme = { id: 'id', title: 'title', ...scheme };

    return (
      <ToolbarActionBox title={title}>
        <Select items={items} scheme={scheme} selected={selected} onChange={onChange}>
          {(item: any, selected: boolean) => {
            return <Option title={item[resultScheme.title]} selected={selected} />;
          }}
        </Select>
      </ToolbarActionBox>
    );
  }
}

export default Filter;
