import { createAction } from 'redux-actions';

import reducersRegister from '../../../../../../../utils/reducersRegister';
import sagasRegister from '../../../../../../../utils/sagasRegister';
import { createAsyncAction } from '../../../../../../../utils/async/actions';
import { Action } from '../../../../../../../store/types';

import redusers from './reducers';
import sagas from './sagas';

reducersRegister.register('gates', redusers);
sagasRegister.register(sagas);

/******************************************************************************/
/******************************* GET GATES ***********************************/
/******************************************************************************/

export const CLEAR_GATES: string = 'CLEAR_GATES';
export const GET_GATES_REQ: string = 'GET_GATES_REQ';
export const GET_GATES_REQ_SUCCESS: string = 'GET_GATES_REQ_SUCCESS';
export const GET_GATES_REQ_ERROR: string = 'GET_GATES_REQ_ERROR';

export const getGatesReq: Action<{}> = createAsyncAction(GET_GATES_REQ);
export const getGatesReqSuccess: Action<{}> = createAction(
  GET_GATES_REQ_SUCCESS,
  (...args) => args[0],
  (...args) => args[1]
);
export const getGatesReqError: Action<{}> = createAction(GET_GATES_REQ_ERROR);
export const clearGates: Action<{}> = createAction(CLEAR_GATES);

/******************************************************************************/
/******************************* GET ONE GATE *********************************/
/******************************************************************************/

export const GET_GATE_REQ: string = 'GET_GATE_REQ';
export const GET_GATE_REQ_SUCCESS: string = 'GET_GATE_REQ_SUCCESS';
export const GET_GATE_REQ_ERROR: string = 'GET_GATE_REQ_ERROR';
export const SET_GATE: string = 'SET_GATE';
export const CLEAR_GATE: string = 'CLEAR_GATE';

export const getGateReq: Action<{}> = createAsyncAction(GET_GATE_REQ);
export const getGateReqSuccess: Action<{}> = createAction(GET_GATE_REQ_SUCCESS);
export const getGateReqError: Action<{}> = createAction(GET_GATE_REQ_ERROR);
export const setGate: Action<{}> = createAction(SET_GATE, (...args) => args[0], (...args) => args[1]);
export const clearGate: Action<{}> = createAction(CLEAR_GATE);

/******************************************************************************/
/******************************* CREATE GATE **********************************/
/******************************************************************************/

export const CREATE_GATE_REQ: string = 'CREATE_GATE_REQ';
export const CREATE_GATE_REQ_SUCCESS: string = 'CREATE_GATE_REQ_SUCCESS';
export const CREATE_GATE_REQ_ERROR: string = 'CREATE_GATE_REQ_ERROR';
export const CREATE_GATE: string = 'CREATE_GATE';

export const createGateReq: Action<{}> = createAsyncAction(CREATE_GATE_REQ);
export const createGateReqSuccess: Action<{}> = createAction(CREATE_GATE_REQ_SUCCESS);
export const createGateReqError: Action<{}> = createAction(CREATE_GATE_REQ_ERROR);
export const createGate: Action<{}> = createAction(CREATE_GATE);

/******************************************************************************/
/******************************* UPDATE GATE   ********************************/
/******************************************************************************/

export const UPDATE_GATE_REQ: string = 'UPDATE_GATE_REQ';
export const UPDATE_GATE_REQ_SUCCESS: string = 'UPDATE_GATE_REQ_SUCCESS';
export const UPDATE_GATE_REQ_ERROR: string = 'UPDATE_GATE_REQ_ERROR';
export const UPDATE_GATE: string = 'UPDATE_GATE';

export const updateGateReq: Action<{}> = createAsyncAction(UPDATE_GATE_REQ);
export const updateGateReqSuccess: Action<{}> = createAction(UPDATE_GATE_REQ_SUCCESS);
export const updateGateReqError: Action<{}> = createAction(UPDATE_GATE_REQ_ERROR);
export const updateGate: Action<{}> = createAction(UPDATE_GATE);

/******************************************************************************/
/******************************* DELETE GATE **********************************/
/******************************************************************************/

export const DELETE_GATE_REQ: string = 'DELETE_GATE_REQ';
export const DELETE_GATE_REQ_SUCCESS: string = 'DELETE_GATE_REQ_SUCCESS';
export const DELETE_GATE_REQ_ERROR: string = 'DELETE_GATE_REQ_ERROR';
export const DELETE_GATE: string = 'DELETE_GATE';

export const deleteGateReq: Action<{}> = createAsyncAction(DELETE_GATE_REQ);
export const deleteGateReqSuccess: Action<{}> = createAction(DELETE_GATE_REQ_SUCCESS);
export const deleteGateReqError: Action<{}> = createAction(DELETE_GATE_REQ_ERROR);
export const deleteGate: Action<{}> = createAction(DELETE_GATE);
