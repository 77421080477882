/** @jsx jsx */
import { jsx } from '@emotion/core';
import { forwardRef } from 'react';
import { FieldAttributes } from 'formik';
import * as styles from './styles';

jsx;

type TProps = FieldAttributes<any>;

export const RadioBtn = forwardRef<HTMLInputElement, TProps>((props, ref) => {
  let checked = props.value === props.values[props.name];
  const selectItem = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      props.onChange();
      checked = true;
    }
  };
  return (
    <span css={styles.RadioBox}>
      <input
        type="radio"
        {...props}
        checked={checked}
        ref={ref}
        onChange={props.onChange}
        onKeyPress={(e: any) => selectItem(e)}
      />
      {props.title}
    </span>
  );
});

export default RadioBtn;
