/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, Fragment } from 'react';
import OutlineBtn from '../../../../../common/buttons/OutlineBtn';
import PrimaryBtn from '../../../../../common/buttons/PrimaryBtn';
import withAcl, { IWrappedProps } from '../../../withAcl';

import * as styles from './styles';
import { ViewModes } from '../Item';

export interface IProps extends IWrappedProps {
  title: string;
  onOpen: () => void;
  onClose: () => void;
  onSort: () => void;
  isSelectMode?: boolean;
  onAddTransportStart: () => void;
  isOpen: boolean;
  isEditMode: boolean;
  viewMode: ViewModes;
  selectDayActivities?: () => void;
  unselectActivitiesFromDay?: () => void;
  onMoveUp?: () => void;
}

export interface IState {
  isDayActivitiesSelected: boolean;
}

jsx;
class DateItem extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isDayActivitiesSelected: false,
    };
  }

  toogleOpen = (e: any) => {
    const { onOpen, onClose, isOpen } = this.props;
    const handler = isOpen ? onClose : onOpen;
    handler();
  };
  handleClick = (e: any) => {
    const { selectDayActivities, unselectActivitiesFromDay } = this.props;
    const { isDayActivitiesSelected } = this.state;
    if (!isDayActivitiesSelected) {
      selectDayActivities && selectDayActivities();
      this.setState({ isDayActivitiesSelected: true });
    } else {
      unselectActivitiesFromDay && unselectActivitiesFromDay();
      this.setState({ isDayActivitiesSelected: false });
    }
  };

  render() {
    const { title, isOpen = false, onSort, isEditMode, viewMode, onAddTransportStart, isSelectMode } = this.props;
    const { isDayActivitiesSelected } = this.state;
    const viewTransport = [ViewModes.mixed, ViewModes.transport].includes(viewMode);

    const actionBtn = isEditMode ? (
      <div css={styles.editButtons}>
        {viewTransport && <PrimaryBtn type="button" title="Add transport" onClick={onAddTransportStart} />}

        <OutlineBtn onClick={onSort} type="button" title="Sort" />
        {isSelectMode && (
          <OutlineBtn
            type="button"
            onClick={this.handleClick}
            title={isDayActivitiesSelected ? 'Unselect all from the day' : 'Select all from the day'}
          />
        )}
      </div>
    ) : (
      <Fragment />
    );

    return (
      <div css={styles.header}>
        <div css={styles.headerLeftSide}>
          <h3 onClick={this.toogleOpen} css={styles.title(isOpen)}>
            {title}
          </h3>
        </div>
        <div css={styles.headerRighrSide}>{actionBtn}</div>
      </div>
    );
  }
}

export default withAcl(DateItem);
