/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component, FunctionComponent, ReactElement, Fragment } from 'react';

import _ from 'lodash';
import * as redux from '../../../utils/redux';
import * as collection from '../../../utils/collection';

export interface IProps {
  asyncData: any | any[];

  statuses: string | string[];
  isExclude?: boolean;
  isEvery?: boolean;
}

jsx;

export const AsyncDataCheckStatus: FunctionComponent<IProps> = ({
  children,
  asyncData,
  statuses,
  isExclude = false,
  isEvery = false,
}): ReactElement<IProps> => {
  const dataSstatuses = collection.extractKeyValue(_.castArray(asyncData), redux.STATUS_FIELD);
  statuses = _.castArray(statuses);

  const check = dataSstatuses.map((status: string) => {
    const res = statuses.includes(status);
    return isExclude ? !res : res;
  });

  const checkResult = isEvery ? check.every(_.identity) : check.some(_.identity);

  const content = checkResult ? children : '';
  return <Fragment>{content}</Fragment>;
};

export default AsyncDataCheckStatus;
