/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { DragLayer } from 'react-dnd';
import _ from 'lodash';

import { Fragment, Component } from 'react';

export interface IProps {
  item: any;
  itemType: any;
  isDragging: any;
  currentOffset: any;
}

export interface IState {}

jsx;

class CustomDragLayer extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
    return nextProps.isDragging ? Math.random() * 100 > 80 : true;
  }

  render() {
    const { item, itemType, isDragging, currentOffset } = this.props;
    const width = _.get(item, 'timelineValueEl.current.offsetWidth', 0);
    const left = _.get(item, 'timelineValueEl.current.offsetLeft');
    const offset = { ...currentOffset, x: _.get(currentOffset, 'x', 0) + left };
    const color = _.get(item, 'data.user.supplier.color.code', null);

    return isDragging ? (
      <div css={dragBox(offset)}>
        <div css={barBox(width, color)} />
      </div>
    ) : (
      <Fragment />
    );
  }
}

export default DragLayer((monitor) => {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  };
})(CustomDragLayer);

const dragBox = (currentOffset: any) =>
  css({
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: _.get(currentOffset, 'x', 0),
    top: _.get(currentOffset, 'y', 0),
    width: '100%',
    height: '100%',
  });

const barBox = (currentBarWidth: any, color: any) =>
  css({
    width: `${currentBarWidth}px`,
    height: '20px',
    backgroundColor: color,
  });
