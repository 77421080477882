/** @jsx jsx */
import { jsx } from '@emotion/core';
import { compose, lifecycle } from 'recompose';
import { RouteComponentProps } from 'react-router';

import { connect } from '../../../../utils/redux';

import { getSupplierReq, clearSupplier } from './store/actions';
import { Action } from '../../../../store/types';
import { supplier } from './store/selectors';

import { supplierFunctionsLoadRequest } from '../SupplierFunctions/store/actions';
import { suppliersList as functions } from '../SupplierFunctions/store/selectors';
import { SupplierFunction } from '../SupplierFunctions/store/types';
import { SupplierItemState } from './store/types';

import EditForm from './EditForm';

import * as styles from './styles';
import { ISupplier } from './store/types';

interface FormValues extends Partial<ISupplier> {}

interface MatchParams {
  id?: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  getSupplierReq: Action<{}>;
  clearSupplier: Action<{}>;
  supplier: SupplierItemState;

  supplierFunctionsLoadRequest: Action<{}>;
  functions: SupplierFunction[];
}

jsx;

const withLifecycle = lifecycle<IProps, {}>({
  componentDidMount() {
    const { getSupplierReq, clearSupplier, supplierFunctionsLoadRequest } = this.props;
    clearSupplier();
    getSupplierReq(this.props.match.params.id);
    supplierFunctionsLoadRequest({ size: 1000 });
  },
});

const Edit = (props: IProps) => {
  const form = props.supplier.data && props.functions ? <EditForm {...props} /> : <div>Loading...</div>;
  return <div css={styles.formBox}>{form}</div>;
};

interface FormProps {
  initialEmail?: string;
}

export default compose<IProps, {}>(
  connect(
    { supplier, functions },
    { getSupplierReq, clearSupplier, supplierFunctionsLoadRequest }
  ),
  withLifecycle
)(Edit);
