/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';

import React from 'react';
import ChangePasswordForm from './ChangePasswordForm';

interface IProps {
    token: string;
}

jsx;

const ChangePassword: FunctionComponent<IProps> = (
    props: IProps
): ReactElement<IProps> => {
    return (
        <ChangePasswordForm/>
    );
};

export default ChangePassword;
