import { createAction } from 'redux-actions';
import { Action } from '../../../../../store/types';
import { createAsyncAction } from '../../../../../utils/async/actions';
// register
import reducersRegister from '../../../../../utils/reducersRegister';
import sagasRegister from '../../../../../utils/sagasRegister';
import reducers from './reducers';
import sagas from './sagas';

reducersRegister.register('productionSuppliers', reducers);
sagasRegister.register(sagas);

/******************************************************************************/
/**************************** GET PRODUCTION SUPLIERS *************************/
/******************************************************************************/

export const GET_PRODUCTION_SUPPLIERS_REQ: string = 'GET_PRODUCTION_SUPPLIERS_REQ';
export const GET_PRODUCTION_SUPPLIERS_REQ_SUCCESS: string = 'GET_PRODUCTION_SUPPLIERS_REQ_SUCCESS';
export const GET_PRODUCTION_SUPPLIERS_REQ_ERROR: string = 'GET_PRODUCTION_SUPPLIERS_REQ_ERROR';
export const SET_PRODUCTION_SUPPLIERS: string = 'SET_PRODUCTION_SUPPLIERS';

export const getProductionSuppliersReq: Action<{}> = createAsyncAction(GET_PRODUCTION_SUPPLIERS_REQ);
export const getProductionSuppliersReqSuccess: Action<{}> = createAction(
  GET_PRODUCTION_SUPPLIERS_REQ_SUCCESS,
  (...args) => args[0],
  (...args) => args[1]
);
export const getProductionSuppliersReqError: Action<{}> = createAction(GET_PRODUCTION_SUPPLIERS_REQ_ERROR);
export const setProductionSuppliers: Action<{}> = createAction(SET_PRODUCTION_SUPPLIERS);

/******************************************************************************/
/*************************** UPDATE PRODUCTION SUPPLIER ***********************/
/******************************************************************************/

export const UPDATE_PRODUCTION_SUPPLIER_REQ: string = 'UPDATE_PRODUCTION_SUPPLIER_REQ';
export const UPDATE_PRODUCTION_SUPPLIER_REQ_SUCCESS: string = 'UPDATE_PRODUCTION_SUPPLIER_REQ_SUCCESS';
export const UPDATE_PRODUCTION_SUPPLIER_REQ_ERROR: string = 'UPDATE_PRODUCTION_SUPPLIER_REQ_ERROR';

export const updateProductionSupplierReq: Action<{}> = createAsyncAction(UPDATE_PRODUCTION_SUPPLIER_REQ);
export const updateProductionSupplierReqSuccess: Action<{}> = createAction(UPDATE_PRODUCTION_SUPPLIER_REQ_SUCCESS);
export const updateProductionSupplierReqError: Action<{}> = createAction(UPDATE_PRODUCTION_SUPPLIER_REQ_ERROR);

/******************************************************************************/
/*************************** DELETE PRODUCTION SUPPLIER ***********************/
/******************************************************************************/

export const DELETE_PRODUCTION_SUPPLIER_REQ: string = 'DELETE_PRODUCTION_SUPPLIER_REQ';
export const DELETE_PRODUCTION_SUPPLIER_REQ_SUCCESS: string = 'DELETE_PRODUCTION_SUPPLIER_REQ_SUCCESS';
export const DELETE_PRODUCTION_SUPPLIER_REQ_ERROR: string = 'DELETE_PRODUCTION_SUPPLIER_REQ_ERROR';

export const deleteProductionSupplierReq: Action<{}> = createAsyncAction(DELETE_PRODUCTION_SUPPLIER_REQ);
export const deleteProductionSupplierReqSuccess: Action<{}> = createAction(DELETE_PRODUCTION_SUPPLIER_REQ_SUCCESS);
export const deleteProductionSupplierReqError: Action<{}> = createAction(DELETE_PRODUCTION_SUPPLIER_REQ_ERROR);

/******************************************************************************/
/*************************** CREATE PRODUCTION SUPPLIER ***********************/
/******************************************************************************/

export const CREATE_PRODUCTION_SUPPLIER_REQ: string = 'CREATE_PRODUCTION_SUPPLIER_REQ';
export const CREATE_PRODUCTION_SUPPLIER_REQ_SUCCESS: string = 'CREATE_PRODUCTION_SUPPLIER_REQ_SUCCESS';
export const CREATE_PRODUCTION_SUPPLIER_REQ_ERROR: string = 'CREATE_PRODUCTION_SUPPLIER_REQ_ERROR';

export const createProductionSupplierReq: Action<{}> = createAsyncAction(CREATE_PRODUCTION_SUPPLIER_REQ);
export const createProductionSupplierReqSuccess: Action<{}> = createAction(CREATE_PRODUCTION_SUPPLIER_REQ_SUCCESS);
export const createProductionSupplierReqError: Action<{}> = createAction(CREATE_PRODUCTION_SUPPLIER_REQ_ERROR);
