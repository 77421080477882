/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import _ from 'lodash';

import { forwardRef } from 'react';
import * as styles from '../../../../../styles/styles';

import { ViewModes } from './Item';

export interface IProps {
  value: string;
  onSelect: (v: ViewModes) => void;
  shouldShowTransport: boolean;
}

jsx;
const Actions = forwardRef<HTMLDivElement, IProps>(({ value, onSelect, shouldShowTransport }, ref) => {
  const activityIconEl = <div css={activityIcon} />;
  const transportIconEl = <div css={transportIcon} />;

  return (
    <div css={tabsBox} ref={ref}>
      <div
        key="0"
        css={tabBox(value === ViewModes.activity)}
        onClick={(e) => {
          onSelect(ViewModes.activity);
        }}
      >
        <div css={iconBox}>
          {[activityIconEl, activityIconEl].map((el: any, index: number) => {
            return (
              <div className="icon-atom" key={index}>
                {el}
              </div>
            );
          })}
        </div>
        <div css={titleBox}>{'Production'}</div>
      </div>
      {shouldShowTransport && (
        <div
          key="1"
          css={tabBox(value === ViewModes.mixed)}
          onClick={(e) => {
            onSelect(ViewModes.mixed);
          }}
        >
          <div css={iconBox}>
            {[activityIconEl, transportIconEl].map((el: any, index: number) => {
              return (
                <div className="icon-atom" key={index}>
                  {el}
                </div>
              );
            })}
          </div>
          <div css={titleBox}>{'Production & Transport'}</div>
        </div>
      )}
      {shouldShowTransport && (
        <div
          key="2"
          css={tabBox(value === ViewModes.transport)}
          onClick={(e) => {
            onSelect(ViewModes.transport);
          }}
        >
          <div css={iconBox}>
            {[transportIconEl, transportIconEl].map((el: any, index: number) => {
              return (
                <div className="icon-atom" key={index}>
                  {el}
                </div>
              );
            })}
          </div>
          <div css={titleBox}>{'Transport'}</div>
        </div>
      )}
    </div>
  );
});

export default Actions;

const tabsBox = css({
  padding: '40px 0',
  display: 'flex',
  width: '100%',
  scrollMargin: '50px',
});

const tabBox = (isActive: boolean) =>
  css({
    minWidth: `${100 / 3}%`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0',
    backgroundColor: isActive ? styles.Colors.LightGreenGrey : 'inherit',
    cursor: 'pointer',
    borderBottom: `${isActive ? 3 : 1}px solid ${isActive ? styles.Colors.Green : styles.Colors.Grey}`,
    '&:hover': {
      backgroundColor: styles.Colors.LightGreenGrey,
    },
  });

const iconBox = css({
  position: 'relative',
  '& .icon-atom:first-of-type': {
    position: 'absolute',
    top: '-7px',
    left: '-15px',
  },
  '& .icon-atom:last-of-type': {
    position: 'absolute',
    bottom: '-7px',
    right: '-15px',
  },
});

const titleBox = css({
  marginLeft: '20px',
});

const icon = css({
  height: '6px',
  width: '24px',
  boxSizing: 'border-box',
});

const activityIcon = [
  icon,
  css({
    backgroundColor: styles.Colors.DarkGrey,
  }),
];

const transportIcon = [
  icon,
  css({
    backgroundColor: styles.Colors.White,
    border: `1px solid ${styles.Colors.Grey}`,
  }),
];
