/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Component, Fragment, createRef } from 'react';
import { connect } from '../../../../../../utils/redux';

import immutable from 'seamless-immutable';
import * as yup from 'yup';
// components
import GRID from '../../../../../common/grid';
import SimpleSelectModal from '../../../../../common/form/select/SimpleSelectModal';
import SelectModalWithTabs from '../../../../../common/form/selectWithTabs/SelectModalWithTabs';
import CreateNewUser from '../../../../../common/form/selectWithTabs/CreateNewUser';
import TextWithRef from '../../../../../common/form/TextWithRef';
import TextareaWithRef from '../../../../../common/form/TextareaWithRef';
import Datepicker from '../../../../../common/form/datepicker/Datepicker';
import Delete from '../../../../../common/grid/actions/Delete';
import Popover from '../../../../../common/popover/Popover';
import OutlineBtn from '../../../../../common/buttons/OutlineBtn';
import Button from '../../../../../common/grid/actions/Button';
import TimelineHeader from '../DateList/Timeline/TimelineHeader';
import Timeline from './Timeline/Timeline';
import CustomDragLayer from './Timeline/CustomDragLayer';

import ScrollableTimeline from './Timeline/ScrollableTimeline';
import ScrollableTimelineHeader from './Timeline/ScrollableTimelineHeader'; // use require because of module has no type definitions
// redux
import {
  getMachineriesReq,
  createMachineryReq,
  updateMachineryReq,
  deleteMachineryReq,
  updateMachinery,
  moveMachineryUserReq,
  cloneMachineryReq,
} from './store/actions';
import { refreshSuppliersForSelect } from '../../../Suppliers/store/actions';

// types
import { SupplierListState } from '../../../Suppliers/store/types';
import { CategoriesListState } from '../../../Categories/store/types';
import { Action } from '../../../../../../store/types';
import { IMachinery } from './store/types';
import { IProduction } from '../../store/types';
// utils
import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment';
import uniqid from 'uniqid';
import * as utils from '../utils';
import { update, set } from '../../../../../../utils/object';

import { extractKeyValue } from '../../../../../../utils/collection';
import { isAmPmTimeFormat, getTimeFormat } from '../../../../../../utils/timeFormatter';
// styles
import * as mainStyles from '../../../../../../styles/styles';
const { ScrollSync, ScrollSyncPane } = require('react-scroll-sync');

const si: any = immutable;

const { ActionList, ForwardedActions, ForwardedApplyAction, ForwardedCancelAction, Grid } = GRID;

export interface IProps {
  day?: any;
  days: any[];
  production: IProduction;
  onSelectDay: (selectDay: any) => void;
  isEditMode?: boolean;
  suppliersForSelect: SupplierListState;
  allCategoriesList: CategoriesListState;
  refreshSuppliersForSelect: Action<{}>;
  createMachineryReq: Action<{}>;
  getMachineriesReq: Action<{}>;
  updateMachineryReq: Action<{}>;
  moveMachineryUserReq: Action<{}>;
  deleteMachineryReq: Action<{}>;
  updateMachinery: Action<{}>;
  cloneMachineryReq: Action<{}>;
  machineries: IMachinery[];
  onEditStart?: (data: any) => void;
  onEditEnd?: () => void;
  editItemId?: number | string | null;
  params?: [];
}

export interface IState {
  newUsers: any[];
  clonePopoverId: number | null;
  cloneDate: any;
  isFirstItemOpen: boolean;
}

const TIMELINE_SCHEMA = [
  '6:00',
  '8:00',
  '10:00',
  '12:00',
  '14:00',
  '16:00',
  '18:00',
  '20:00',
  '22:00',
  '24:00',
  '2:00',
  '4:00',
];
const TIMELINE_SCHEMA_AM_PM = ['6am', '8am', '10am', '12am', '2pm', '4pm', '6pm', '8pm', '10pm', '12pm', '2am', '4am'];

jsx;
class MachineryList extends Component<IProps, IState> {
  private categoryRef: any;
  private typeRef: any;
  private labelRef: any;
  private startRef: any;
  private endRef: any;
  private userRef: any;
  private applyFirstUserRef: any;
  private userDeleteRef: any;
  private userRemoveRef: any;
  private remarkRef: any;
  private actionsRef: any;
  constructor(props: IProps) {
    super(props);

    this.categoryRef = createRef();
    this.typeRef = createRef();
    this.labelRef = createRef();
    this.startRef = [...Array(30)].map((r) => createRef());
    this.endRef = [...Array(30)].map((r) => createRef());
    this.userRef = [...Array(30)].map((r) => createRef());
    this.applyFirstUserRef = createRef();
    this.userDeleteRef = createRef();
    this.userRemoveRef = [...Array(30)].map((r) => createRef());
    this.remarkRef = createRef();

    this.state = {
      newUsers: [],
      clonePopoverId: null,
      cloneDate: null,
      isFirstItemOpen: true,
    };
  }

  componentDidUpdate() {
    if (this.props.isEditMode) {
      setTimeout(() => {
        if (this.props.isEditMode) {
          this.categoryRef && this.categoryRef.current && this.categoryRef.current.focus();
        }
      }, 0);
    }
  }

  addNewUser(value: string) {
    const newUsers = [{ id: value, name: value }, ...this.state.newUsers];
    this.setState({ newUsers });
  }

  moveMacinery = (machinery: any, id: any, range: any) => {
    const { moveMachineryUserReq, updateMachinery, production } = this.props;
    const users = [...machinery.users].map((user) => {
      return user.id === id ? { ...user, range } : user;
    });
    machinery = { ...machinery, users };
    updateMachinery({ data: [machinery] });

    const values = {
      userId: id,
      id: machinery.id,
      ownerId: production.id,
      range,
    };
    moveMachineryUserReq(values);
  };

  addUser = (newMachinery: any, user: any, prevMachinery: any) => {
    user = _.omit(user, 'intersection');
    const { moveMachineryUserReq, updateMachinery, production } = this.props;
    if (newMachinery.id !== prevMachinery.id) {
      prevMachinery = update(prevMachinery, 'users', (users: any) =>
        users.filter((u: any) => {
          return u.id !== user.id;
        })
      );

      newMachinery = update(newMachinery, 'users', (users: any) => [...users, user]);
      updateMachinery({ data: [newMachinery, prevMachinery] });

      const values = {
        userId: user.id,
        id: prevMachinery.id,
        ownerId: production.id,
        range: user.range,
        replaceId: newMachinery.id,
      };
      moveMachineryUserReq(values);
    }
  };

  clearNewUsers = () => {
    this.setState({ newUsers: [] });
  };

  openClonePopover = (machinery: any) => {
    const initialDate = _.get(machinery, 'users[0].range.from', new Date());
    this.setState({
      clonePopoverId: machinery.id,
      cloneDate: initialDate,
    });
  };

  closeClonePopover = () => {
    this.setState({ clonePopoverId: null });
  };

  onCloneDateChange = (date: any) => {
    this.setState({ cloneDate: date });
  };

  cloneMachinery = (machinery: any) => {
    const { production, cloneMachineryReq } = this.props;
    const { cloneDate } = this.state;

    cloneMachineryReq({
      ownerId: production.id,
      id: machinery.id,
      dates: [cloneDate],
    });

    this.closeClonePopover();
  };

  getTimelineScheme = () => {
    return isAmPmTimeFormat() ? TIMELINE_SCHEMA_AM_PM : TIMELINE_SCHEMA;
  };

  render() {
    const {
      day = null,
      onSelectDay,
      production,
      days,
      isEditMode = false,
      suppliersForSelect,
      refreshSuppliersForSelect,
      allCategoriesList,
      createMachineryReq,
      getMachineriesReq,
      updateMachineryReq,
      deleteMachineryReq,
      machineries,
      onEditStart = _.identity,
      onEditEnd = _.identity,
      editItemId = null,
      params = [],
    } = this.props;
    const { clonePopoverId, cloneDate } = this.state;

    const productionRange = {
      from: moment.utc(production.loadIn.from),
      to: moment.utc(production.loadOut.to),
    };

    const gridPeriodRange = day ? utils.limitRange(day, productionRange) : productionRange;

    const rangeRepresentation = utils.getRangeOfWorkDays(production.loadIn.from, production.loadOut.to, 'day');

    const sortedMachineries = day ? getMachineriesByDay(machineries, day) : sortMachineriesUsersByTime(machineries);

    const boxRange = {
      from: _.get(_.first(rangeRepresentation), 'from'),
      to: _.get(_.last(rangeRepresentation), 'to'),
    };

    const types = allCategoriesList.data.reduce((result, item) => {
      return { ...result, [item.id]: item.types };
    }, {});

    const periodTimeline = {
      width: isEditMode ? 30 : 40,
      headStyle: styles.timelineCell,
      cellStyle: styles.scrolalbleTimelineCell,
      className: 'scrollable-cell',
      title: (
        <ScrollSyncPane>
          <ScrollableTimelineHeader days={days} onSelectDay={onSelectDay} />
        </ScrollSyncPane>
      ),
      render: (machinery: any) => {
        const boxRange = { from: _.first(days).from.valueOf(), to: _.last(days).to.valueOf() };
        return (
          <ScrollableTimeline
            machinery={machinery}
            boxRange={boxRange}
            limitRange={productionRange}
            isEditMode={isEditMode}
            days={days}
            timeRound={120}
            onChange={(id: any, range: any) => {
              this.moveMacinery(machinery, id, range);
            }}
            onAddUser={(user: any, prevMachinery: any) => {
              this.addUser(machinery, user, prevMachinery);
            }}
          />
        );
      },

      edit: {
        render: (activity: any, { onChange }: any = _.identity, error: any) => {
          return <div />;
        },
      },
    };

    const dayTimeline = {
      headStyle: styles.timelineCell,
      cellStyle: styles.timelineCell,
      width: isEditMode ? 30 : 40,
      title: <TimelineHeader scheme={this.getTimelineScheme()} />,
      render: (machinery: any) => {
        const users = filterUsersByTime(machinery.users, getRange(day, productionRange));

        return (
          <Timeline
            machinery={machinery}
            users={users}
            boxRange={day}
            isEditMode={isEditMode}
            limitRange={productionRange}
            scheme={this.getTimelineScheme()}
            onChange={(id: any, range: any) => {
              this.moveMacinery(machinery, id, range);
            }}
            onAddUser={(user: any, prevMachinery: any) => {
              this.addUser(machinery, user, prevMachinery);
            }}
          />
        );
      },

      edit: {
        render: (activity: any, { onChange }: any = _.identity, error: any) => {
          return <div />;
        },
      },
    };

    const timeline = day ? dayTimeline : periodTimeline;

    const gridShema = [
      {
        title: 'Machinery',
        render: ({ category, type }: any) => {
          return <div>{`${_.get(category, 'name')} ${_.get(type, 'name')}`}</div>;
        },

        edit: {
          initValue: ({ category, type }: any) => {
            return { category: _.get(category, 'id', 0), type: _.get(type, 'id', 0) };
          },
          reducer: (machinery: any, value: any) => {
            return { ...machinery, ...value };
          },
          render: ({ category, type }: any, { onChange }: any = _.identity, error: any) => {
            const currentTypes = _.get(types, category, []);
            type = _.find(currentTypes, { id: type }) ? type : 0;

            return (
              <div css={styles.cellRowsBox}>
                <div css={styles.cellRow}>
                  <SimpleSelectModal
                    scheme={{ title: 'name' }}
                    items={allCategoriesList.data}
                    selected={category}
                    onChange={(category) => {
                      onChange({ type, category });
                      this.typeRef && this.typeRef.current && this.typeRef.current.focus();
                    }}
                    styles={styles.selectHeaderStyle}
                    tabIndex={0}
                    ref={this.categoryRef}
                    handleModalBlur={(cb: any) => {
                      if (!this.state.isFirstItemOpen) {
                        cb();
                      }
                    }}
                    onFocusLeave={() => {
                      this.typeRef && this.typeRef.current && this.typeRef.current.focus();
                    }}
                  />
                </div>

                <div css={styles.cellRow}>
                  <SimpleSelectModal
                    disabled={!category}
                    scheme={{ title: 'name' }}
                    items={_.sortBy(currentTypes, (type: any) => type.name)}
                    selected={type}
                    onChange={(type) => {
                      onChange({ type, category });
                      this.labelRef && this.labelRef.current && this.labelRef.current.focus();
                    }}
                    styles={styles.selectHeaderStyle}
                    ref={this.typeRef}
                    onFocusLeave={() => {
                      this.labelRef && this.labelRef.current && this.labelRef.current.focus();
                    }}
                  />
                </div>
              </div>
            );
          },
        },
      },

      {
        title: 'Label',
        width: 7,
        render: ({ label }: any) => {
          return <div>{label}</div>;
        },

        edit: {
          initValue: ({ label = '' }: any) => {
            return label;
          },
          reducer: (machinery: any, value: any) => {
            return set(machinery, 'label', value);
          },
          render: ({ label }: any, { onChange }: any = _.identity, error: any) => {
            const handleChange = (e: any) => {
              onChange(_.get(e, 'currentTarget.value'));
            };
            return <TextWithRef value={label} onChange={(e) => handleChange(e)} ref={this.labelRef} />;
          },
        },
      },

      {
        width: 9,
        visible: 'edit',
        title: 'Start',
        render: ({ users }: any) => {
          const periodRange = day || boxRange;
          users = filterUsersByTime(users, getRange(periodRange, productionRange));
          const timeFormat = getTimeFormat();
          const format = day ? timeFormat : `DD, ${timeFormat}`;

          return (
            <div css={styles.cellRowsBox}>
              {users.map(({ range: { from }, id }: any) => {
                return (
                  <div css={[styles.userRow]} key={id}>
                    <div css={styles.userName}>{moment.utc(from).format(format)}</div>
                  </div>
                );
              })}
            </div>
          );
        },

        edit: {
          initValue: ({ users }: any) => {
            users =
              users && users.length
                ? users.map((user: any) => {
                    return user.supplier
                      ? { ...user, user: user.supplier.id, isNew: false }
                      : updateNewUserRange(user, gridPeriodRange);
                  })
                : addNewUser([], gridPeriodRange);
            return users;
          },

          updateValue: ({ users }: any) => {
            return users.map((user: any) => {
              return user.supplier || user.changeTime ? user : updateNewUserRange(user, gridPeriodRange);
            });
          },

          reducer: (machinery: any, value: any) => {
            return { ...machinery, ...{ users: value } };
          },
          render: ({ users }: any, { onChange }: any, error: any) => {
            return (
              <div css={styles.cellRowsBox}>
                {users.map((user: any, index: number) => {
                  return (
                    <div css={styles.cellRow} key={user.id}>
                      <Datepicker
                        value={_.get(user, 'range.from')}
                        onChange={(value) => {
                          users = updateUsers(users, user.id, ['range', 'from'], value);
                          users = updateUsers(users, user.id, 'changeTime', true);
                          onChange(users);
                        }}
                        tabIndex={0}
                        closeOnSelect
                        dateFormat={'DD-MM,'}
                        range={productionRange}
                        ref={this.startRef[index]}
                        onFocusLeave={() => {
                          this.endRef[index] && this.endRef[index].current && this.endRef[index].current.focus();
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            );
          },
        },
      },

      {
        width: 9,
        visible: 'edit',
        title: 'End',
        render: ({ users }: any) => {
          const periodRange = day || boxRange;
          users = filterUsersByTime(users, getRange(periodRange, productionRange));
          const timeFormat = getTimeFormat();
          const format = day ? timeFormat : `DD, ${timeFormat}`;

          return (
            <div css={styles.cellRowsBox}>
              {users.map(({ range: { to }, id }: any) => {
                return (
                  <div css={[styles.userRow]} key={id}>
                    <div css={styles.userName}>{moment.utc(to).format(format)}</div>
                  </div>
                );
              })}
            </div>
          );
        },

        edit: {
          initValue: ({ users }: any) => {
            users =
              users && users.length
                ? users.map((user: any) => {
                    return user.supplier
                      ? { ...user, user: user.supplier.id, isNew: false }
                      : updateNewUserRange(user, gridPeriodRange);
                  })
                : addNewUser([], gridPeriodRange);
            return users;
          },
          reducer: (machinery: any, value: any) => {
            return { ...machinery, ...{ users: value } };
          },
          render: ({ users }: any, { onChange }: any, error: any) => {
            return (
              <div css={styles.cellRowsBox}>
                {users.map((user: any, index: number) => {
                  return (
                    <div css={styles.cellRow} key={user.id}>
                      <Datepicker
                        value={_.get(user, 'range.to')}
                        onChange={(value) => {
                          users = updateUsers(users, user.id, ['range', 'to'], value);
                          users = updateUsers(users, user.id, 'changeTime', true);
                          onChange(users);
                        }}
                        dateFormat={'DD-MM,'}
                        range={productionRange}
                        closeOnSelect
                        tabIndex={0}
                        ref={this.endRef[index]}
                        onFocusLeave={() => {
                          this.userRef[index] && this.userRef[index].current && this.userRef[index].current.focus();
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            );
          },
        },
      },

      {
        //width: 20,
        title: 'Users',
        render: ({ users }: any) => {
          const periodRange = day || boxRange;
          users = filterUsersByTime(users, getRange(periodRange, productionRange));

          return (
            <div css={styles.cellRowsBox}>
              {users.map(({ supplier: { name, color }, id }: any) => {
                return (
                  <div css={[styles.userRow]} key={id}>
                    <div css={styles.userColorBullet(color.code)} />
                    <div css={styles.userName}>{name}</div>
                  </div>
                );
              })}
            </div>
          );
        },

        edit: {
          initValue: ({ users }: any) => {
            users = users
              ? users.map((user: any) => {
                  return user.supplier ? { ...user, user: user.supplier.id, isNew: false } : user;
                })
              : [];
            return users;
          },
          reducer: (machinery: any, value: any) => {
            return { ...machinery, ...{ users: value } };
          },
          render: ({ users }: any, { onChange }: any, error: any) => {
            const allUsers = [...this.state.newUsers, ...suppliersForSelect.data];
            const handleBlur = (index: number) => {
              if (index === 0) {
                this.applyFirstUserRef && this.applyFirstUserRef.current && this.applyFirstUserRef.current.focus();
              } else {
                index === users.length - 1
                  ? this.userDeleteRef.current.focus()
                  : this.userRemoveRef &&
                    this.userRemoveRef[index] &&
                    this.userRemoveRef[index].current &&
                    this.userRemoveRef[index].current.focus();
              }
            };

            const handleComplete = (value: string, userId: any, index: number) => {
              const isNewUserExists = !!_.find(getNewUsers(users), { user: value });
              if (!isNewUserExists) {
                users = updateUsers(users, userId, 'user', value);
                users = updateUsers(users, userId, 'isNew', true);
                this.addNewUser(value);
                onChange(users);
              } else {
                toast.warn(`New user with name (${value}) is already exists`);
              }
            };
            const handleChange = (value: number | number[], userId: any, index: number) => {
              const isNew = _.isString(value);
              users = updateUsers(users, userId, 'user', value);
              users = updateUsers(users, userId, 'isNew', isNew);

              onChange(users);
              handleBlur(index);
            };
            return (
              <div css={styles.cellRowsBox}>
                {users.map((user: any, index: number) => {
                  return (
                    <div css={styles.cellRow} key={user.id}>
                      <SelectModalWithTabs
                        scheme={{ title: 'name' }}
                        noSelectedMsg="Not selected"
                        items={allUsers}
                        selected={user.user}
                        onChange={(value) => handleChange(value, user.id, index)}
                        styles={styles.selectHeaderStyle}
                        ref={this.userRef[index]}
                        onBlur={() => handleBlur(index)}
                        elementToCreate={({ init, backToSelect, onCreateAndSelect }) => (
                          <CreateNewUser
                            onComplete={(value) => handleComplete(value, user.id, index)}
                            backToSelect={backToSelect}
                            value={init}
                            onCreateAndSelect={onCreateAndSelect}
                            onFocusLeave={() => {
                              this.setState({ isFirstItemOpen: false });
                              handleBlur(index);
                            }}
                          />
                        )}
                      />
                    </div>
                  );
                })}
              </div>
            );
          },
        },
      },

      {
        title: '',
        path: '',
        visible: 'edit',
        width: 1,
        render: () => <div />,
        headStyle: styles.cellWithoutPadding,
        cellStyle: styles.cellWithoutPadding,
        edit: {
          initValue: ({ users }: any) => {
            users = users || [];
            return users;
          },
          reducer: (machinery: any, value: any) => {
            return { ...machinery, ...{ users: value } };
          },
          render: ({ users }: any, { onChange }: any = _.identity, error: any) => {
            const lastUserId = _.get(_.last(users), 'id');

            return (
              <div css={[styles.cellRowsBox]}>
                {users.map((user: any, index: number) => {
                  const handleKeyPress = (e: KeyboardEvent) => {
                    if (!e.shiftKey && (e.key === 'Tab' || e.keyCode === 9)) {
                      e.preventDefault();
                      e.stopPropagation();

                      this.startRef[index + 1] &&
                        this.startRef[index + 1].current &&
                        this.startRef[index + 1].current.focus();
                    } else if (e.key === 'ArrowLeft' || e.keyCode === 37) {
                      this.startRef[index] && this.startRef[index].current && this.startRef[index].current.focus();
                    } else if (e.shiftKey && (e.key === 'Tab' || e.keyCode === 9) && !index) {
                      e.preventDefault();
                      e.stopPropagation();
                      this.userRef[0] && this.userRef[0].current && this.userRef[0].current.focus();
                    } else if ((e.key === 'ArrowUp' || e.keyCode === 38) && index) {
                      this.userRemoveRef &&
                        this.userRemoveRef[index - 1] &&
                        this.userRemoveRef[index - 1].current &&
                        this.userRemoveRef[index - 1].current.focus();
                    } else if ((e.key === 'ArrowDown' || e.keyCode === 40) && index < users.length - 2) {
                      this.userRemoveRef &&
                        this.userRemoveRef[index + 1] &&
                        this.userRemoveRef[index + 1].current &&
                        this.userRemoveRef[index + 1].current.focus();
                    } else if ((e.key === 'ArrowDown' || e.keyCode === 40) && index === users.length - 2) {
                      this.userDeleteRef && this.userDeleteRef.current && this.userDeleteRef.current.focus();
                    }
                  };
                  if (user.id !== lastUserId) {
                    return (
                      <Fragment key={user.id}>
                        <div css={[styles.cellRow, styles.cellRowActionsBox]} key={user.id}>
                          <div css={styles.cellRowsAction}>
                            <ForwardedActions>
                              <ForwardedCancelAction
                                onClick={() => {
                                  onChange(removeUser(users, user.id));
                                }}
                                onKeyDown={handleKeyPress}
                                ref={this.userRemoveRef[index]}
                              />
                            </ForwardedActions>
                          </div>
                        </div>
                      </Fragment>
                    );
                  } else {
                    return (
                      <div css={[styles.cellRow, styles.cellRowActionsBox]} key={user.id}>
                        <div css={styles.cellRowsAction}>
                          <ForwardedActions>
                            {users.length > 1 && (
                              <ForwardedCancelAction
                                onClick={() => {
                                  onChange(removeUser(users, user.id));
                                }}
                                ref={this.userDeleteRef}
                                onKeyDown={(e: any) => {
                                  if (e.key === 'ArrowLeft' || e.keyCode === 37) {
                                    this.startRef[index] &&
                                      this.startRef[index].current &&
                                      this.startRef[index].current.focus();
                                  } else if (e.key === 'ArrowRight' || e.keyCode === 39) {
                                    this.applyFirstUserRef &&
                                      this.applyFirstUserRef.current &&
                                      this.applyFirstUserRef.current.focus();
                                  } else if ((e.key === 'ArrowUp' || e.keyCode === 38) && index) {
                                    this.userRemoveRef &&
                                      this.userRemoveRef[index - 1] &&
                                      this.userRemoveRef[index - 1].current &&
                                      this.userRemoveRef[index - 1].current.focus();
                                  }
                                  if (e.shiftKey && (e.key === 'Tab' || e.keyCode === 9)) {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    this.userRef[index] &&
                                      this.userRef[index].current &&
                                      this.userRef[index].current.focus();
                                  }
                                }}
                              />
                            )}

                            <ForwardedApplyAction
                              ref={this.applyFirstUserRef}
                              onClick={() => {
                                onChange(addNewUser(users, gridPeriodRange));
                              }}
                              onKeyDown={(e) => {
                                if (e.shiftKey && (e.key === 'Tab' || e.keyCode === 9)) {
                                  !index
                                    ? this.userRef[0] && this.userRef[0].current && this.userRef[0].current.focus()
                                    : this.userRemoveRef &&
                                      this.userRemoveRef[index] &&
                                      this.userRemoveRef[index].current &&
                                      this.userRemoveRef[index].current.focus();
                                } else if (e.key === 'Enter') {
                                  onChange(addNewUser(users, gridPeriodRange));
                                  setTimeout(() => {
                                    this.startRef &&
                                      this.startRef[index + 1] &&
                                      this.startRef[index + 1].current &&
                                      this.startRef[index + 1].current.focus();
                                  }, 0);
                                } else if (e.key === 'ArrowLeft' || e.keyCode === 37) {
                                  this.userDeleteRef &&
                                    this.userDeleteRef &&
                                    this.userDeleteRef.current &&
                                    this.userDeleteRef.current.focus();
                                }
                              }}
                            />
                          </ForwardedActions>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            );
          },
        },
      },

      timeline,

      {
        title: 'Remarks',
        width: 15,
        render: ({ remarks }: any) => {
          return <div>{remarks}</div>;
        },
        edit: {
          initValue: ({ remarks = '' }: any) => {
            return remarks;
          },
          reducer: (machinery: any, value: any) => {
            return set(machinery, 'remarks', value);
          },
          render: ({ remarks }: any, { onChange }: any = _.identity, error: any) => {
            const handleChange = (e: any) => {
              onChange(_.get(e, 'currentTarget.value'));
            };
            return <TextareaWithRef value={remarks} onChange={(e) => handleChange(e)} ref={this.remarkRef} />;
          },
        },
      },

      {
        width: 5,
        visible: 'edit',
        title: '',
        render: (machinery: any) => {
          return clonePopoverId === machinery.id ? (
            <Popover isOpen onClickOutside={this.closeClonePopover} autoHeight>
              <div>
                <Datepicker
                  input={false}
                  timeFormat={false}
                  value={cloneDate}
                  onChange={this.onCloneDateChange}
                  dateFormat={'DD-MM'}
                  range={productionRange}
                />
                <OutlineBtn
                  title="Clone machinery"
                  onClick={() => this.cloneMachinery(machinery)}
                  css={styles.cloneButton}
                />
              </div>
            </Popover>
          ) : (
            <ActionList>
              <Delete
                onConfirm={(e) => {
                  deleteMachineryReq({ ownerId: production.id, id: machinery.id });
                }}
              />
              <Button
                title="Edit"
                onClick={(e) => {
                  onEditStart(machinery);
                }}
              />
              <Button
                title="Clone"
                onClick={(e) => {
                  this.openClonePopover(machinery);
                }}
              />
            </ActionList>
          );
        },
        edit: {
          render: (machinery: any, { onClear, onClose }: any, error: any) => {
            const endAction = _.get(machinery, 'id') ? onClose : onClear;
            const handleClick = () => {
              const errorMessage = validate(machinery);
              if (errorMessage) {
                toast.error(errorMessage);
              } else {
                const users = clearUsersData(machinery.users);
                const isNewUsers = !!_.find(users, { isNew: true });
                const values = {
                  ...machinery,
                  users: users,
                  production: production.id,
                  ownerId: production.id,
                };

                const action = values.id ? updateMachineryReq : createMachineryReq;
                const endAction = values.id ? onClose : onClear;

                action(values).then(() => {
                  if (isNewUsers) {
                    this.clearNewUsers();
                    refreshSuppliersForSelect();
                  }
                  getMachineriesReq({ ...params, ownerId: production.id });
                  endAction();
                });
              }
            };
            return (
              <ForwardedActions>
                <ForwardedApplyAction onClick={handleClick} />
                <ForwardedCancelAction
                  onClick={endAction}
                  onFocusLeave={() => {
                    this.categoryRef && this.categoryRef.current && this.categoryRef.current.focus();
                  }}
                />
              </ForwardedActions>
            );
          },
        },
      },
    ];
    return (
      <div>
        <CustomDragLayer />
        <ScrollSync>
          <Grid
            shema={gridShema}
            isEditMode={isEditMode}
            data={[...sortedMachineries]}
            onEditStart={(data) => onEditStart(data)}
            onEditEnd={onEditEnd}
            editItemId={editItemId}
            className="scrollable-grid"
            css={styles.grid}
          />
        </ScrollSync>
      </div>
    );
  }
}

export default connect(
  {},
  {
    createMachineryReq,
    getMachineriesReq,
    updateMachineryReq,
    moveMachineryUserReq,
    deleteMachineryReq,
    updateMachinery,
    cloneMachineryReq,
    refreshSuppliersForSelect,
  }
)(MachineryList);

//********************** HANDLERS **********************//

const getRange = (boxRange: any, limitRange: any) => {
  const boxRangeFrom = moment.utc(boxRange.from).valueOf();
  const boxRangeTo = moment.utc(boxRange.to).valueOf();
  const limitRangeFrom = moment.utc(limitRange.from).valueOf();
  const limitRangeTo = moment.utc(limitRange.to).valueOf();

  return {
    from: boxRangeFrom > limitRangeFrom ? boxRangeFrom : limitRangeFrom,
    to: boxRangeTo < limitRangeTo ? boxRangeTo : limitRangeTo,
  };
};

const filterUsersByTime = (users: any[], range: any) => {
  const result = users.reduce((result: any, user: any) => {
    user = setUserRangeIntersection(user, range);
    return user ? [...result, user] : [...result];
  }, []);

  return result;
};

const setUserRangeIntersection = (user: any, range: any) => {
  let userRange = user.range;
  const intersection = utils.getIntersectionRanges(range, userRange);

  if (intersection) {
    return { ...user, range: userRange, intersection };
  } else {
    return null;
  }
};

const getMachineriesByDay = (machineries: any, day: any) => {
  machineries = machineries.filter((machinery: any) => {
    const users = getMachineryUsersByDay(machinery.users, day);
    return users.length ? { ...machinery, ...{ users } } : null;
  });
  return sortMachineriesUsersByTime(machineries);
};

const getMachineryUsersByDay = (users: any, day: any) => {
  return users.filter((user: any) => {
    const intersection = utils.getIntersectionRanges(day, user.range);
    return intersection ? user : null;
  });
};

const sortMachineriesUsersByTime = (machineries: any) => {
  return machineries.map(sortMachineryUsersByTime);
};

const sortMachineryUsersByTime = (machinery: any) => {
  let users = sortUsersByTime(machinery.users);
  return si({ ...machinery, ...{ users } });
};

const sortUsersByTime = (users: any) => {
  const result = [...users].sort((a: any, b: any) => {
    return moment.utc(a.range.from).valueOf() - moment.utc(b.range.from).valueOf();
  });
  return result;
};

const updateUsers = (users: any, id: any, key: string | string[], value: any) => {
  const result = users.map((user: any) => {
    return user.id === id ? set(user, key, value) : user;
  });
  return result;
};

const addNewUser = (users: any, periodRange: any) => {
  return [
    ...users,
    updateNewUserRange(
      {
        id: uniqid('new-'),
        user: 0,
        isNew: false,
      },
      periodRange
    ),
  ];
};

const getNewUsers = (users: any) => {
  return users.filter((user: any) => user.isNew);
};

const updateNewUserRange = (user: any, periodRange: any) => {
  return {
    ...user,
    range: {
      from: moment
        .utc(periodRange.from.valueOf())
        .add(0, 'hour')
        .valueOf(),
      to: moment
        .utc(periodRange.from.valueOf())
        .add(2, 'hour')
        .valueOf(),
    },
  };
};

const removeUser = (users: any, id: number) => {
  return users.filter((user: any) => {
    return user.id !== id;
  });
};

const clearUsersData = (users: any) => {
  return users.map((user: any) => {
    const omit = ['changeTime'];
    const omitId = _.isString(user.id) ? ['id'] : [];
    return _.omit(user, [...omit, ...omitId]);
  });
};

//********************** VALIDATION **********************//

const validate = (machinery: any) => {
  return validateIntersection(machinery) || validateStartEndOrder(machinery) || validateSchema(machinery);
};

const validateSchema = (machinery: any) => {
  const schema = yup.object().shape({
    type: yup
      .number()
      .min(1, 'Type is required')
      .required('Type is required'),
    category: yup
      .number()
      .nullable(false)
      .min(1, 'Category is required')
      .required('Category is required'),
    label: yup.string().required('Label is required'),
    users: yup.array().of(
      yup.object().shape({
        user: yup.mixed().test('user', 'All users must be selected', (value: any) => {
          return !!value;
        }),
      })
    ),
  });

  let error = null;
  try {
    schema.validateSync(machinery);
  } catch (e) {
    error = e;
  }

  return error ? error.message : null;
};

const validateIntersection = (machinery: any) => {
  const ranges = extractKeyValue(machinery.users, 'range');
  const intersection = utils.checkRangesIntersection(ranges);

  return intersection ? 'All periods must be different' : null;
};

const validateStartEndOrder = (machinery: any) => {
  const ranges = extractKeyValue(machinery.users, 'range');
  const error = ranges.every((item: any) => {
    return moment.utc(item.from).isAfter(moment.utc(item.to));
  });

  return error ? 'End Time must be greater than the start time' : null;
};

//********************** STYLES **********************//

const styles = {
  machineryEditCell: css({
    display: 'flex',
    justifyContent: 'space-around',
  }),
  selectHeaderStyle: css({
    height: '30px',
    lineHeight: '30px',
    '& :hover, :focus': {
      outline: `1px solid ${mainStyles.Colors.Green}`,
    },
  }),
  cellRowsBox: css({
    height: '100%',
    '& :first-of-type': {
      marginTop: 0,
    },
  }),
  cellRow: css({
    marginTop: '5px',
    minHeight: '30px',
  }),
  cellRowsAction: css({
    position: 'absolute',
    width: '60px',
  }),
  cellRowActionsBox: css({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  }),
  userRow: [
    mainStyles.ScheduleText,
    css({
      display: 'flex',
      marginTop: '2px',
      lineHeight: '17px',
    }),
  ],
  userColorBullet: (colorCode: string) =>
    css({
      backgroundColor: colorCode,
      minWidth: '7px',
    }),
  userName: css({
    marginLeft: '5px',
  }),
  cellWithoutPadding: css({
    padding: '0px !important',
  }),
  timelineCell: css({
    padding: '0px !important',
    width: '40%',
    display: 'inline-block',
  }),
  scrolalbleTimelineCell: css`
    padding: 0px !important;
    width: 40%;
    display: block;
    position: relative;
    &::before {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 10px;
      box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.3);
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    &::after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 10px;
      box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.3);
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  `,
  grid: css`
    &.scrollable-right {
      .scrollable-cell::after {
        opacity: 1;
      }
    }
    &.scrollable-left {
      .scrollable-cell::before {
        opacity: 1;
      }
    }
  `,
  cloneButton: css`
    background: ${mainStyles.Colors.Green};
    color: white;
    width: 100%;
    border-radius: 0;
    &:hover {
      background: ${mainStyles.Colors.AltGreen};
    }
  `,
};
