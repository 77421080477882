/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import { FunctionComponent, ReactElement } from 'react';
import withAcl, { IWrappedProps } from './withAcl';

interface IProps extends IWrappedProps {
  resources: any;
  actions: any;
  entity?: any;
  children: any;
  profile: any;
  otherwise?: any;
}

jsx;
const CheckAcl: FunctionComponent<IProps> = ({
  resources = [],
  actions = ['R'],
  entity = null,
  children,
  otherwise = null,
  test,
}): ReactElement<IProps> | null => {
  children = _.isFunction(children) ? children() : children;
  return test(resources, actions, entity) ? children : otherwise;
};

export default withAcl(CheckAcl);
