/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import ReactModal from 'react-modal';

import * as styles from './styles';

export interface IProps {}

jsx;

export const Header: FunctionComponent<IProps> = ({ children }): ReactElement<IProps> => {
  return <div css={styles.header}>{children}</div>;
};

export default Header;
