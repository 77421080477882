/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment, Component } from 'react';
import { compose } from 'recompose';
// redux
import { connect } from '../../../../utils/redux';
import {
  getSuppliersReq,
  deleteSupplierReq,
  setSuppliersPage,
  setSuppliersSearchText,
  setSuppliersFilter,
  refreshSuppliers,
  resetSuppliers,
} from './store/actions';
import { suppliers } from './store/selectors';
import { supplierFunctions } from '../SupplierFunctions/store/selectors';
import { getSupplierFunctionsReq } from '../SupplierFunctions/store/actions';
// types
import { Action } from '../../../../store/types';
import { SupplierListState } from './store/types';
import { SupplierFunctionListState } from '../SupplierFunctions/store/types';
// components
import { Link as RouteLink } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import AsyncData from '../../../common/async/AsyncData';
import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import GRID from '../../../common/grid';
import GRIDActions from '../../../common/grid/actions/index';
import GRIDToolbarActions from '../../../common/grid/toolbarActions';
// other
import routes from '../../../../routes';
import { ListStatuses } from '../../../../utils/store/state/state';
import withAcl, { IWrappedProps } from '../withAcl';
import LABELS from '../../../../constants';
// utils
import _ from 'lodash';
import { push } from 'connected-react-router';

const { Actions, ActionList, DeleteAction, EditAction, ToolBar, Footer, Grid, styles } = GRID;
const { Link } = GRIDActions;
const { Search, Filter } = GRIDToolbarActions;
interface IProps extends IWrappedProps {
  suppliers: SupplierListState;
  refreshSuppliers: Action<{}>;
  resetSuppliers: Action<{}>;
  getSuppliersReq: Action<{}>;
  deleteSupplierReq: Action<{}>;
  setSuppliersSearchText: Action<{}>;
  setSuppliersFilter: Action<{}>;
  setSuppliersPage: Action<{}>;
  getSupplierFunctionsReq: Action<{}>;
  push: Action<{}>;
  supplierFunctions: SupplierFunctionListState;
}

interface IState {}

const gridShema = [
  {
    title: 'Name',
    path: 'name',
  },
  {
    title: LABELS.Function,
    path: 'functions',
    serializer: (functions: any) => {
      const result = functions
        .map((item: any) => {
          return item.name;
        })
        .join(', ');
      return result;
    },
  },
];

jsx;
export class SuppliersList extends Component<IProps, IState> {
  componentDidMount(): void {
    this.props.getSuppliersReq(this.getSuppliersParams());
  }

  loadMoreSuppliers = (page: number = 0) => {
    this.props.getSuppliersReq(this.getSuppliersParams({ page }));
  };

  getSuppliersParams = (
    overrideParams = {},
    defParams = {
      page: 0,
      filter: 0,
      search: '',
      user: 'false',
    }
  ) => {
    const {
      suppliers: { params },
    } = this.props;

    return {
      ...defParams,
      ...params,
      ...overrideParams,
    };
  };

  onWaypointEnter = () => {
    const { suppliers } = this.props;
    if (suppliers.status === ListStatuses.Request) return;
    this.loadMoreSuppliers(Number(suppliers.params.page || 0) + 1);
  };

  componentWillUnmount(): void {
    const { resetSuppliers } = this.props;
    resetSuppliers();
  }

  render() {
    const {
      suppliers,
      deleteSupplierReq,
      setSuppliersSearchText,
      setSuppliersFilter,
      getSuppliersReq,
      getSupplierFunctionsReq,
      supplierFunctions,
      test,
      push,
    } = this.props;
    const isLoading = suppliers.status === ListStatuses.Request;
    const total = _.get(suppliers, 'meta.total', 0);
    const hasMoreSuppliers = total > _.get(suppliers.data, 'length', 0);

    return (
      <div css={styles.gridBox}>
        <AsyncData
          data={[
            { asyncData: suppliers, asyncGetAction: getSuppliersReq },
            { asyncData: supplierFunctions, asyncGetAction: getSupplierFunctionsReq },
          ]}
        >
          {() => {
            const {
              data,
              params: { size },
              meta: { total },
            } = suppliers;

            return (
              <Fragment>
                <ToolBar
                  title="Suppliers"
                  actionsLeft={
                    <Fragment>
                      <Filter
                        scheme={{ id: 'id', title: 'name' }}
                        items={[{ id: 0, name: 'Select all' }, ...supplierFunctions.data]}
                        selected={suppliers.params.filter}
                        onChange={setSuppliersFilter}
                      />
                      <Search title="Search by name" text={suppliers.params.search} onChange={setSuppliersSearchText} />
                    </Fragment>
                  }
                  actionsRight={
                    <Fragment>
                      {test('supplier', 'C') && (
                        <RouteLink to={routes.SuppliersCreate}>
                          <PrimaryBtn title="Create supplier" />
                        </RouteLink>
                      )}
                    </Fragment>
                  }
                />
                <Grid
                  onRowDoubleClick={(e, { id }) => {
                    if (test('supplierAgent', 'R')) {
                      push(routes.SupplierAgents.replace(':owner_id', `${id}`));
                    }
                  }}
                  shema={gridShema}
                  data={data || []}
                  actions={({ id }) => (
                    <Actions>
                      {test('supplier', 'U') && (
                        <RouteLink to={routes.SuppliersEdit.replace(':id', `${id}`)}>
                          <EditAction />
                        </RouteLink>
                      )}
                      {test('supplier', 'D') && (
                        <DeleteAction
                          onConfirm={(e) => {
                            deleteSupplierReq(id);
                          }}
                        />
                      )}

                      {test('supplierAgent', 'R') && (
                        <ActionList>
                          <Link to={routes.SupplierAgents.replace(':owner_id', `${id}`)} title="View agents" />
                        </ActionList>
                      )}
                    </Actions>
                  )}
                />
                <Footer />
              </Fragment>
            );
          }}
        </AsyncData>
        {hasMoreSuppliers && !isLoading && <Waypoint onEnter={this.onWaypointEnter} />}
      </div>
    );
  }
}

export default compose<IProps, {}>(
  connect(
    { suppliers, supplierFunctions },
    {
      refreshSuppliers,
      resetSuppliers,
      getSuppliersReq,
      deleteSupplierReq,
      setSuppliersPage,
      setSuppliersSearchText,
      setSuppliersFilter,
      getSupplierFunctionsReq,
      push,
    }
  ),
  withAcl
)(SuppliersList);

const style = {
  loader: css`
    margin: 5px 0;
  `,
};
