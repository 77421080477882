/** @jsx jsx */
import { FunctionComponent, ReactElement } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { jsx, css } from '@emotion/core';
// components
import { Link } from 'react-router-dom';
import widget from '../../../../../common/widget';
import OutlineBtn from '../../../../../common/buttons/OutlineBtn';
// types
import { IProduction } from '../../store/types';
// utils
import moment from 'moment';
// other
import routes from '../../../../../../routes';
import { DATE_FORMAT } from '../../../../../../config';
import withAcl, { IWrappedProps } from '../../../withAcl';
import withVisualContext from '../../../../../../contexts/withVisualContext';
import { IVisualContext } from '../../../../../../contexts/VisualContext';
import letterA from '../../../../../../images/icons/letterA.svg';
import letterL from '../../../../../../images/icons/letterL.svg';
interface IRouteParams {
  id: string;
}

export interface IProps extends RouteComponentProps<IRouteParams>, IWrappedProps {
  production: IProduction;
  showCreateBtn?: boolean;
  visual: IVisualContext;
}

const convertDate = (date: string | number) => {
  return moment.utc(date).format(DATE_FORMAT);
};

jsx;
export const RelatedProductionsWidget: FunctionComponent<IProps> = ({
  production,
  history,
  match: { params },
  test,
  showCreateBtn = true,
  visual,
}): ReactElement<IProps> => {
  const { Widget, WidgetHeader, WidgetBody, WidgetKeyValueRow } = widget;

  const toCreateSubproduction = () => {
    history.push(routes.SubProductionsCreate.replace(':production_id', params.id));
  };

  const isMain = production.isMain;
  const image = isMain ? letterA : letterL;

  return (
    <Widget>
      <WidgetHeader title={isMain ? `${visual.labels.Subproduction}` : `${visual.labels.SubSubproduction}`}>
        {showCreateBtn && test('production', 'U') && (
          <OutlineBtn title="Create new" css={style.headerButton} onClick={toCreateSubproduction} />
        )}
      </WidgetHeader>

      <WidgetBody maxHeight={200}>
        {production &&
          production.sub &&
          production.sub.length > 0 &&
          production.sub.map((sub) => {
            const date = `${convertDate(sub.loadIn.from)} - ${convertDate(sub.loadOut.to)}`;
            return (
              <Link to={routes.Production.replace(':id', `${sub.id}`)} css={style.link} key={sub.id}>
                <WidgetKeyValueRow
                  title={
                    <div css={style.name}>
                      <img src={image} alt="" />
                      {sub.name}
                    </div>
                  }
                >
                  {date}
                </WidgetKeyValueRow>
              </Link>
            );
          })}
      </WidgetBody>
    </Widget>
  );
};

export default withVisualContext(withRouter(withAcl(RelatedProductionsWidget)));

const style = {
  headerButton: css`
    font-size: 12px;
    line-height: 16px;
    height: 32px;
  `,
  link: css`
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  `,
  name: css`
    display: flex;
    align-items: center;
    column-gap: 10px;
  `,
};
