/** @jsx jsx */
import { css } from '@emotion/core';

import * as styles from '../../../../../../styles/styles';
import { Colors } from '../../../../../../styles/styles';

export const callSheetHeaderBox = css({
  display: 'flex',
  alignItems: 'center',
});

export const headerBox = css({
  backgroundColor: Colors.LightGreyBlue,
});

export const widgetContent = [
  styles.MainText,
  css({
    padding: '10px 30px 30px 30px',
  }),
];

export const blockTitleWrap = css({
  display: 'flex',
  width: '100%',
  margin: '50px 0 30px 0',
});

export const blockTitle = [
  styles.Link,
  styles.H1,
  css({
    margin: '0',
    color: '#4365E0',
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
];

export const blockTitleWithoutLink = [
  styles.H1,
  css({
    '&:hover': {
      textDecoration: 'none',
    },
  }),
];

export const iconWrapper = css({
  margin: '0  40px 20px 40px',
  boxSizing: 'border-box',
});

export const filesBox = css({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
});

export const filesListBox = css({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '10px',
});

export const formLabel = [
  styles.MainText,
  css({
    display: 'block',
    width: '100%',
    marginTop: '10px',
    color: '#06142D',
    fontWeight: 'bold',
  }),
];
