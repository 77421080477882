/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import _ from 'lodash';

import React from 'react';
import PopoverBtn from '../../../../common/popover/PopoverBtn';
import OutlineBtn from '../../../../common/buttons/OutlineBtn';
import * as selectStyles from '../../../../common/form/select/styles';
import arrow from './images/arrow.svg';

export const NOT_SELECTED = 'Not selected';

export interface IScheme {
  id?: string;
  title?: string;
}
interface ILocateContent {
  targetRect: ClientRect;
  popoverRect: ClientRect;
  nudgedTop: number;
  nudgedLeft: number;
}

export interface ISelectProps {
  onPointSelect: () => void;
  onSelectAll: () => void;
  onUnselectAll: () => void;
  disableReposition?: boolean;
  tabIndex?: number;
}

export interface IState {
  isOpen: boolean;
  isHover: boolean;
  boxWidth: number;
}

export const getSelectedItem = (convertItem: any, id: number | string | null) => {
  return id === null ? null : _.find(convertItem, { id });
};

jsx;
export const SelectItems = React.forwardRef<HTMLDivElement, ISelectProps>((props, ref) => {
  const { onPointSelect, onSelectAll, onUnselectAll, tabIndex, disableReposition } = props;

  const locateContent = ({ targetRect, popoverRect, nudgedTop, nudgedLeft }: ILocateContent) => {
    const pageYOffset = window.pageYOffset;
    const pageXOffset = window.pageXOffset;

    if (targetRect.top <= 0) {
      return { top: targetRect.top + pageYOffset, left: nudgedLeft + pageXOffset };
    }

    if (window.innerHeight - targetRect.top <= 0) {
      return { top: targetRect.top - popoverRect.height + pageYOffset, left: nudgedLeft + pageXOffset };
    }

    return { top: nudgedTop + pageYOffset, left: nudgedLeft + pageXOffset };
  };
  return (
    <PopoverBtn
      btn={(openPopover, isOpen) => (
        <div onClick={openPopover}>
          <OutlineBtn onClick={openPopover} type="button" title="Select" css={selectButton} />
        </div>
      )}
      disableReposition={disableReposition}
      tabIndex={tabIndex}
      contentLocation={({ targetRect, popoverRect, nudgedTop, nudgedLeft }) =>
        locateContent({ targetRect, popoverRect, nudgedTop, nudgedLeft })
      }
      renderContent={(closeDropdown) => (
        <div
          id="options"
          tabIndex={-1}
          onClick={(e) => {
            closeDropdown(e);
          }}
        >
          <div css={selectStyles.selectOption}>
            <div css={selectStyles.optionBase} onClick={onPointSelect}>
              Select activities
            </div>
          </div>
          <div css={selectStyles.selectOption}>
            <div css={selectStyles.optionBase} onClick={onSelectAll}>
              Select all
            </div>
          </div>
          <div css={selectStyles.selectOption}>
            <div css={selectStyles.optionBase} onClick={onUnselectAll}>
              Unselect all
            </div>
          </div>
        </div>
      )}
    />
  );
});

export default SelectItems;

const selectButton = () =>
  css({
    position: 'relative',
    paddingRight: '50px',
    marginRight: '10px',
    '&:after': {
      content: '""',
      position: 'absolute',
      right: '3px',
      top: '-3px',
      bottom: '0',
      width: '36px',
      backgroundImage: `url( ${arrow} )`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '15px',
    },
  });
