/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment, Component } from 'react';
// redux
import { connect } from '../../../../utils/redux';
import { compose } from 'recompose';
import { getProductionsReq, deleteProductionReq, setProductionsParams } from './store/actions';
import { getProfileReq } from '../User/store/actions';
import { productions } from './store/selectors';
import { profile } from '../User/store/selectors';
// types
import { Action } from '../../../../store/types';
import { ProductionListState } from './store/types';
import { UserItemState } from '../User/store/types';
import { IProduction } from './store/types';
// components
import { Link as RouteLink } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import AsyncData from '../../../common/async/AsyncData';
import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import GRID from '../../../common/grid';
import GRIDToolbarActions from '../../../common/grid/toolbarActions';

import Search from '../../../common/grid/toolbarActions/Search';
// utils
import _ from 'lodash';
import moment from 'moment';
import { push } from 'connected-react-router';
import { getUsersProductions, getSortedProductions } from './utils';
// other
import routes from '../../../../routes';
import withAcl, { IWrappedProps } from '../withAcl';
import Loader from '../../../common/loader/Loader';
import { ListStatuses } from '../../../../utils/store/state/state';
import withVisualContext from '../../../../contexts/withVisualContext';
import { IVisualContext } from '../../../../contexts/VisualContext';
import arrow from '../../../../images/icons/arrow.svg';
import letterM from '../../../../images/icons/letterM.svg';
import letterA from '../../../../images/icons/letterA.svg';
import letterL from '../../../../images/icons/letterL.svg';

const { Actions, DeleteAction, EditAction, ViewAction, ToolBar, Footer, Grid, styles } = GRID;
const { Filter } = GRIDToolbarActions;

interface IProps extends IWrappedProps {
  productions: ProductionListState;
  getProductionsReq: Action<{}>;
  deleteProductionReq: Action<{}>;
  setProductionsSearchText: Action<{}>;
  setProductionsParams: Action<{}>;
  getProfileReq: Action<{}>;
  push: Action<{}>;
  profile: UserItemState;
  visual: IVisualContext;
}
interface IVisibleProduction extends IProduction {
  mainProdId?: number;
  sub: IVisibleProduction[];
}

interface IState {
  openedProductions: number[];
}

const showtimeRangesRenderer = (item: any) => {
  return Object.values(item.shows)
    .map((show: any) =>
      [show.range.from, show.range.to].map((date: string) => ({
        dayOfYear: +moment(date).format('DDD'),
        dayOfMonth: +moment(date).format('D'),
        month: moment(date).format('MMM'),
        date: +new Date(date),
      }))
    )
    .sort((a: any, b: any) => a[0].date - b[0].date)
    .reduce((acc: any, [from, to]: any) => {
      if (
        acc &&
        acc.length > 0 &&
        acc[acc.length - 1] &&
        acc[acc.length - 1][1] &&
        acc[acc.length - 1][1].dayOfYear - from.dayOfYear < 2
      ) {
        const position = acc.length - 1;
        acc.splice(position, 1, [acc[acc.length - 1][0], to]);
        return acc;
      }
      return [...acc, [from, to]];
    }, [])
    .map(([from, to]: any) => {
      if (to.dayOfYear - from.dayOfYear < 2) return `${from.month} ${from.dayOfMonth}`;
      if (to.month === from.month) return `${from.month} ${from.dayOfMonth}-${to.dayOfMonth}`;
      return `${from.month} ${from.dayOfMonth} - ${to.month} ${to.dayOfMonth}`;
    })
    .join(', ');
};

jsx;
export class ProductionsList extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      openedProductions: [],
    };
  }
  componentDidMount(): void {
    const {
      productions: { params },
      profile,
      testRole,
    } = this.props;

    getProductionsReq();
    const filter = params.filter || (testRole('PRODUCER') && profile.data.id);
    this.onFilterChange(filter);
  }

  componentWillUnmount(): void {
    const { setProductionsParams } = this.props;
    setProductionsParams({
      'search.production.name': '',
      'search.production.location': '',
      archive: false,
      page: 0,
    });
  }

  loadMoreProductions = () => {
    const {
      getProductionsReq,
      productions: { params },
    } = this.props;

    getProductionsReq({
      ...params,
      page: (params.page || 0) + 1,
    });
  };

  onWaypointEnter = () => {
    const { productions } = this.props;
    const isLoading = _.get(productions, 'status') === ListStatuses.Request;
    if (isLoading) return;
    this.loadMoreProductions();
  };

  onFilterChange = (value: any) => {
    const { getProductionsReq, productions } = this.props;
    const params = _.omit(
      { ...productions.params, page: 0, archive: true, filter: value },
      value === 'archive' ? 'filter' : 'archive'
    );
    getProductionsReq(params);
  };

  onSearchName = (name: string) => {
    const { getProductionsReq, productions } = this.props;
    getProductionsReq({ ...productions.params, page: 0, 'search.production.name': name });
  };

  onSearchLocation = (location: string) => {
    const { getProductionsReq, productions } = this.props;
    getProductionsReq({ ...productions.params, page: 0, 'search.production.location': location });
  };

  mainProductionsId = (data: IProduction[]) =>
    _.values(data)
      .filter((p) => p.isMain)
      .map((p) => p.id);

  getMainProductions = (data: IProduction[]) => {
    return _.values(data).filter((p) => p.isMain);
  };

  toggleOpenedProductions = (id: any) => {
    const { openedProductions } = this.state;
    if (openedProductions.includes(id)) {
      return this.setState((prevState) => ({
        openedProductions: [...prevState.openedProductions.filter((production) => production !== id)],
      }));
    } else {
      this.setState((prevState) => ({
        openedProductions: [...prevState.openedProductions, id],
      }));
    }
  };

  calculateRowStyle = (production: IProduction) => {
    const { openedProductions } = this.state;
    if (!(production.sub && production.sub.length > 0)) {
      if (production.isMain) return style.mainCell;
      if (production.canHaveSub) return style.subCell;
      return style.subSubCell;
    } else if (openedProductions.includes(production.id)) {
      return production.isMain
        ? [style.mainCell, style.nestedNameCell(true)]
        : [style.subCell, style.nestedNameCell(true)];
    }
    return production.isMain
      ? [style.mainCell, style.nestedNameCell(false)]
      : [style.subCell, style.nestedNameCell(false)];
  };

  render() {
    const { productions, deleteProductionReq, profile, test, testRole, push } = this.props;
    const { openedProductions } = this.state;

    const {
      data,
      params: { size, filter, page, archive },
      meta: { total = 0 },
    } = productions;

    const gridShema = [
      {
        title: 'Name',
        render: (production: IProduction) => {
          return (
            <div
              css={style.nameCell}
              onClick={() => {
                this.toggleOpenedProductions(production.id);
              }}
            >
              <div css={this.calculateRowStyle(production)}>{production.name}</div>
            </div>
          );
        },
      },
      {
        title: 'Location',
        path: 'location',
      },
      {
        width: 30,
        title: 'Showtime date',
        serializer: showtimeRangesRenderer,
      },
      {
        title: 'Producer',
        path: 'producer.name',
      },
    ];

    const productionAction = (item: any) => (
      <Fragment>
        {testRole('SUPPLIER') ? (
          <RouteLink to={routes.ProductionCallSheet.replace(':id', `${item.id}`)}>
            <ViewAction />
          </RouteLink>
        ) : (
          <RouteLink to={routes.Production.replace(':id', `${item.id}`)}>
            <ViewAction />
          </RouteLink>
        )}
        {test('production', 'U') && (
          <RouteLink to={routes.ProductionsEdit.replace(':id', `${item.id}`)}>
            <EditAction />
          </RouteLink>
        )}
        {test('production', 'D') && (
          <DeleteAction
            onConfirm={(e) => {
              deleteProductionReq(item.id);
            }}
          />
        )}
      </Fragment>
    );

    const subproductionAction = (item: any) => (
      <Fragment>
        {testRole('SUPPLIER') ? (
          <RouteLink to={routes.ProductionCallSheet.replace(':id', `${item.id}`)}>
            <ViewAction />
          </RouteLink>
        ) : (
          <RouteLink to={routes.Production.replace(':id', `${item.id}`)}>
            <ViewAction />
          </RouteLink>
        )}
        {test('production', 'U') && (
          <RouteLink
            to={routes.SubProductionsEdit.replace(':production_id', item.mainProdId).replace(
              ':subproduction_id',
              item.id
            )}
          >
            <EditAction />
          </RouteLink>
        )}
        {test('production', 'D') && (
          <DeleteAction
            onConfirm={() => {
              deleteProductionReq(item.id);
            }}
          />
        )}
      </Fragment>
    );

    const getActions = (item: any) => (item.isMain ? productionAction(item) : subproductionAction(item));

    const myFilter = testRole('PRODUCER') ? [{ id: profile.data.id, title: 'My productions' }] : [];
    const allProductionTitle = testRole('ADMIN') || testRole('PRODUCER') ? 'All productions' : 'My productions';

    const filterItems = [{ id: 0, title: allProductionTitle }, ...myFilter, { id: 'archive', title: 'Archive' }];
    const selectedFilter = archive ? 'archive' : filter || 0;
    const isLoading = _.get(productions, 'status') === ListStatuses.Request;
    const hasMoreProductions = _.get(productions, 'meta.total', 0) > _.get(productions, 'data.length', 0);

    return (
      <div css={styles.gridBox}>
        <AsyncData data={[{ asyncData: productions }]}>
          {() => {
            const mainProductionsId = this.mainProductionsId(data);
            const mainProductions = this.getMainProductions(data);
            const gridData = testRole('ADMIN')
              ? getSortedProductions(mainProductions, openedProductions)
              : getUsersProductions(data, openedProductions);

            return (
              <Fragment>
                <ToolBar
                  title="Productions"
                  actionsLeft={
                    <Fragment>
                      <Filter
                        scheme={{ id: 'id', title: 'title' }}
                        items={filterItems}
                        selected={selectedFilter}
                        onChange={this.onFilterChange}
                      />
                      <Search title={'Search by name'} onChange={this.onSearchName} />
                      <Search title={'Search by location'} onChange={this.onSearchLocation} />
                    </Fragment>
                  }
                  actionsRight={
                    <Fragment>
                      {test('production', 'C') && (
                        <RouteLink to={routes.ProductionsCreate}>
                          <PrimaryBtn title="Create production" />
                        </RouteLink>
                      )}
                    </Fragment>
                  }
                />
                <Grid
                  onRowDoubleClick={(e: any, { id }: any) => {
                    testRole('SUPPLIER')
                      ? push(routes.ProductionCallSheet.replace(':id', `${id}`))
                      : push(routes.Production.replace(':id', `${id}`));
                  }}
                  shema={gridShema}
                  data={gridData}
                  emphasisRows={mainProductionsId}
                  actions={(production) => <Actions>{getActions(production)}</Actions>}
                />
                <Footer>
                  <Loader isLoading={isLoading && !!page} />
                </Footer>
              </Fragment>
            );
          }}
        </AsyncData>
        {hasMoreProductions && !isLoading && <Waypoint onEnter={this.onWaypointEnter} />}
      </div>
    );
  }
}

export default compose<any, {}>(
  connect(
    { productions, profile },
    { getProductionsReq, deleteProductionReq, setProductionsParams, getProfileReq, push }
  ),
  withVisualContext,
  withAcl
)(ProductionsList);

const style = {
  nameCell: css`
    cursor: pointer;
    padding-left: 25px;
    display: block;
    margin-left: 20px;
    position: relative;
  `,
  mainCell: css({
    marginLeft: '-30px',
    paddingLeft: '35px',
    position: 'relative',
    '&::before': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '26px',
      height: '26px',
      opacity: 1,
      backgroundImage: `url(${letterM})`,
      backgroundRepeat: 'no-repeat',
    },
  }),
  subSubCell: css({
    marginLeft: '30px',
    paddingLeft: '35px',
    position: 'relative',
    '&::before': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '26px',
      height: '26px',
      opacity: 1,
      backgroundImage: `url(${letterL})`,
      backgroundRepeat: 'no-repeat',
    },
  }),
  subCell: css({
    paddingLeft: '35px',
    position: 'relative',
    '&::before': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '26px',
      height: '26px',
      opacity: 1,
      backgroundImage: `url(${letterA})`,
      backgroundRepeat: 'no-repeat',
    },
  }),
  nestedNameCell: (isOpen: boolean = false) =>
    css({
      position: 'relative',
      '&::after': {
        content: '" "',
        display: 'block',
        position: 'absolute',
        top: isOpen ? '6px' : '8px',
        bottom: 0,
        left: '-22px',
        width: '10px',
        height: '6px',
        opacity: 1,
        backgroundImage: `url(${arrow})`,
        transform: isOpen ? 'rotateX(180deg)' : '',
        backgroundRepeat: 'no-repeat',
      },
    }),
};
