/** @jsx jsx */
import { ReactElement, FunctionComponent } from 'react';
import { jsx, css } from '@emotion/core';
// components
import { Link } from 'react-router-dom';
// utils
import _ from 'lodash';
// other
import { FontFamilies, Colors } from '../../../styles/styles';

interface IProps {
  actions: IDropdownAction[];
  closeDropdown?: (event: React.MouseEvent<HTMLElement>) => void;
}

interface IDropdownAction {
  label: string;
  link?: string;
  handler?: (event: React.MouseEvent<HTMLElement>) => any;
}

jsx;
const DropdownMenu: FunctionComponent<IProps> = ({ actions, closeDropdown }): ReactElement<IProps> => {
  return (
    <div css={style.dropdownMenu} onClick={closeDropdown}>
      <div>
        {actions.map((action, index) => (
          <div css={style.dropdownItem} key={index}>
            {action.link ? (
              <Link to={action.link} css={style.action} tabIndex={-1}>
                {action.label}
              </Link>
            ) : (
              <div onClick={action.handler} css={style.action}>
                {action.label}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropdownMenu;

const style = {
  dropdownMenu: css`
    width: 200px;
    max-height: 300px;
    overflow: auto;
  `,
  dropdownItem: css`
    font-weight: 400;
    font-family: ${FontFamilies.Main};
    font-size: 13px;
    color: #505050;
  `,
  action: css`
    padding: 10px 20px;
    display: flex;
    flex: 1;
    cursor: pointer;
    text-decoration: none;
    color: black;

    &:hover {
      background-color: ${Colors.HoveredWhite};
    }
  `,
};
