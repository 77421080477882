import { createAction } from 'redux-actions';

import reducersRegister from '../../../../../utils/reducersRegister';
import sagasRegister from '../../../../../utils/sagasRegister';
import { Action } from '../../../../../store/types';
import { createAsyncAction, createPromisifyAsyncAction } from '../../../../../utils/async/actions';

import redusers from './reducers';
import sagas from './sagas';

reducersRegister.register('productions', redusers);
sagasRegister.register(sagas);

/******************************************************************************/
/*******************************GET PRODUCTIONS **************************************/
/******************************************************************************/

export const GET_PRODUCTIONS_REQ: string = 'GET_PRODUCTIONS_REQ';
export const GET_PRODUCTIONS_REQ_SUCCESS: string = 'GET_PRODUCTIONS_REQ_SUCCESS';
export const GET_PRODUCTIONS_REQ_ERROR: string = 'GET_PRODUCTIONS_REQ_ERROR';

export const SET_PRODUCTIONS: string = 'SET_PRODUCTIONS';
export const REFRESH_PRODUCTIONS: string = 'REFRESH_PRODUCTIONS';

export const SET_PRODUCTIONS_PAGE: string = 'SET_PRODUCTIONS_PAGE';
export const SET_PRODUCTIONS_FILTER: string = 'SET_PRODUCTIONS_FILTER';
export const SET_PRODUCTIONS_PARAMS: string = 'SET_PRODUCTIONS_PARAMS';

//createPromisifyAsyncAction experementall function
export const getProductionsReq: any = createAsyncAction(GET_PRODUCTIONS_REQ);
export const getProductionsReqSuccess: Action<{}> = createAction(
  GET_PRODUCTIONS_REQ_SUCCESS,
  (...args) => args[0],
  (...args) => args[1]
);
export const getProductionsReqError: Action<{}> = createAction(GET_PRODUCTIONS_REQ_ERROR);
export const setProductions: Action<{}> = createAction(SET_PRODUCTIONS);
export const refreshProductions: Action<{}> = createAction(REFRESH_PRODUCTIONS);
export const setProductionsParams: Action<{}> = createAction(SET_PRODUCTIONS_PARAMS);

/******************************************************************************/
/*******************************GET ALL PRODUCTIONS **************************************/
/******************************************************************************/
export const GET_ALL_PRODUCTIONS_REQ: string = 'GET_ALL_PRODUCTIONS_REQ';
export const GET_ALL_PRODUCTIONS_REQ_SUCCESS: string = 'GET_ALL_PRODUCTIONS_REQ_SUCCESS';
export const GET_ALL_PRODUCTIONS_REQ_ERROR: string = 'GET_ALL_PRODUCTIONS_REQ_ERROR';

export const SET_ALL_PRODUCTIONS: string = 'SET_ALL_PRODUCTIONS';

export const getAllProductionsReq: any = createAsyncAction(GET_ALL_PRODUCTIONS_REQ);
export const getAllProductionsReqSuccess: Action<{}> = createAction(GET_ALL_PRODUCTIONS_REQ_SUCCESS);
export const getAllProductionsReqError: Action<{}> = createAction(GET_ALL_PRODUCTIONS_REQ_ERROR);
export const setAllProductions: Action<{}> = createAction(SET_ALL_PRODUCTIONS);

/******************************************************************************/
/******************************* CREATE PRODUCTION ********************************/
/******************************************************************************/

export const CREATE_PRODUCTION_REQ: string = 'CREATE_PRODUCTION_REQ';
export const CREATE_PRODUCTION_REQ_SUCCESS: string = 'CREATE_PRODUCTION_REQ_SUCCESS';
export const CREATE_PRODUCTION_REQ_ERROR: string = 'CREATE_PRODUCTION_REQ_ERROR';

export const createProductionReq: Action<{}> = createPromisifyAsyncAction(CREATE_PRODUCTION_REQ);
export const createProductionReqSuccess: Action<{}> = createAction(CREATE_PRODUCTION_REQ_SUCCESS);
export const createProductionReqError: Action<{}> = createAction(CREATE_PRODUCTION_REQ_ERROR);

/******************************************************************************/
/******************************* GET ONE PRODUCTION   *****************************/
/******************************************************************************/

export const GET_PRODUCTION_REQ: string = 'GET_PRODUCTION_REQ';
export const GET_PRODUCTION_REQ_SUCCESS: string = 'GET_PRODUCTION_REQ_SUCCESS';
export const GET_PRODUCTION_REQ_ERROR: string = 'GET_PRODUCTION_REQ_ERROR';
export const SET_PRODUCTION: string = 'SET_PRODUCTION';
export const CLEAR_PRODUCTION: string = 'CLEAR_PRODUCTION';

export const getProductionReq: Action<{}> = createAsyncAction(GET_PRODUCTION_REQ);
export const getProductionReqSuccess: Action<{}> = createAction(
  GET_PRODUCTION_REQ_SUCCESS,
  (...args) => args[0],
  (...args) => args[1]
);
export const getProductionReqError: Action<{}> = createAction(GET_PRODUCTION_REQ_ERROR);
export const setProduction: Action<{}> = createAction(SET_PRODUCTION);
export const clearProduction: Action<{}> = createAction(CLEAR_PRODUCTION);

/******************************************************************************/
/******************************* PRINT ONE PRODUCTION   *****************************/
/******************************************************************************/

export const GET_PRODUCTION_PRINT_REQ: string = 'GET_PRODUCTION_PRINT_REQ';
export const GET_PRODUCTION_PRINT_REQ_SUCCESS: string = 'GET_PRODUCTION_PRINT_REQ_SUCCESS';
export const GET_PRODUCTION_PRINT_REQ_ERROR: string = 'GET_PRODUCTION_PRINT_REQ_ERROR';
export const SET_PRODUCTION_PRINT: string = 'SET_PRODUCTION_PRINT';

export const getProductionPrintReq: Action<{}> = createAsyncAction(GET_PRODUCTION_PRINT_REQ);
export const getProductionPrintReqSuccess: Action<{}> = createAction(GET_PRODUCTION_PRINT_REQ_SUCCESS);
export const getProductionPrintReqError: Action<{}> = createAction(GET_PRODUCTION_PRINT_REQ_ERROR);
export const setProductionPrint: Action<{}> = createAction(SET_PRODUCTION_PRINT);

/******************************************************************************/
/******************************* PRINT INFO ONE PRODUCTION   *****************************/
/******************************************************************************/

export const GET_PRODUCTION_PRINT_INFO_REQ: string = 'GET_PRODUCTION_PRINT_INFO_REQ';
export const GET_PRODUCTION_PRINT_INFO_REQ_SUCCESS: string = 'GET_PRODUCTION_PRINT_INFO_REQ_SUCCESS';
export const GET_PRODUCTION_PRINT_INFO_REQ_ERROR: string = 'GET_PRODUCTION_PRINT_INFO_REQ_ERROR';
export const SET_PRODUCTION_PRINT_INFO: string = 'SET_PRODUCTION_PRINT_INFO';
export const SET_PRODUCTION_PRINT_INFO_PARAMS: string = 'SET_PRODUCTION_PRINT_INFO_PARAMS';

export const getProductionPrintInfoReq: Action<{}> = createAsyncAction(GET_PRODUCTION_PRINT_INFO_REQ);
export const getProductionPrintInfoReqSuccess: Action<{}> = createAction(GET_PRODUCTION_PRINT_INFO_REQ_SUCCESS);
export const getProductionPrintInfoReqError: Action<{}> = createAction(GET_PRODUCTION_PRINT_INFO_REQ_ERROR);
export const setProductionPrintInfo: Action<{}> = createAction(SET_PRODUCTION_PRINT_INFO);
export const setProductionPrintInfoParams: Action<{}> = createAction(SET_PRODUCTION_PRINT_INFO_PARAMS);

/******************************************************************************/
/******************************* UPDATE ONE PRODUCTION   **************************/
/******************************************************************************/

export const UPDATE_PRODUCTION_REQ: string = 'UPDATE_PRODUCTION_REQ';
export const UPDATE_PRODUCTION_DAYS_REQ: string = 'UPDATE_PRODUCTION_DAYS_REQ';
export const UPDATE_PRODUCTION_REQ_SUCCESS: string = 'UPDATE_PRODUCTION_REQ_SUCCESS';
export const UPDATE_PRODUCTION_REQ_ERROR: string = 'UPDATE_PRODUCTION_REQ_ERROR';
export const UPDATE_PRODUCTION: string = 'UPDATE_PRODUCTION';

export const updateProductionReq: Action<{}> = createAsyncAction(UPDATE_PRODUCTION_REQ);
export const updateProductionDaysReq: Action<{}> = createAsyncAction(UPDATE_PRODUCTION_DAYS_REQ);
export const updateProductionReqSuccess: Action<{}> = createAction(UPDATE_PRODUCTION_REQ_SUCCESS);
export const updateProductionReqError: Action<{}> = createAction(UPDATE_PRODUCTION_REQ_ERROR);
export const updateProduction: Action<{}> = createAction(UPDATE_PRODUCTION);

/******************************************************************************/
/******************************* DELETE ONE PRODUCTION   **************************/
/******************************************************************************/

export const DELETE_PRODUCTION_REQ: string = 'DELETE_PRODUCTION_REQ';
export const DELETE_PRODUCTION_REQ_SUCCESS: string = 'DELETE_PRODUCTION_REQ_SUCCESS';
export const DELETE_PRODUCTION_REQ_ERROR: string = 'DELETE_PRODUCTION_REQ_ERROR';
export const DELETE_PRODUCTION: string = 'DELETE_PRODUCTION';

export const deleteProductionReq: Action<{}> = createAsyncAction(DELETE_PRODUCTION_REQ);
export const deleteProductionReqSuccess: Action<{}> = createAction(DELETE_PRODUCTION_REQ_SUCCESS);
export const deleteProductionReqError: Action<{}> = createAction(DELETE_PRODUCTION_REQ_ERROR);
export const deleteProduction: Action<{}> = createAction(DELETE_PRODUCTION);

/******************************************************************************/
/******************************* CREATE SUBPRODUCTION ********************************/
/******************************************************************************/

export const CREATE_SUBPRODUCTION_REQ: string = 'CREATE_SUBPRODUCTION_REQ';

export const createSubproductionReq: Action<{}> = createAsyncAction(CREATE_SUBPRODUCTION_REQ);

/******************************************************************************/
/******************************* GET ONE SUBPRODUCTION   *****************************/
/******************************************************************************/

export const GET_SUBPRODUCTION_REQ: string = 'GET_SUBPRODUCTION_REQ';
export const GET_SUBPRODUCTION_REQ_SUCCESS: string = 'GET_SUBPRODUCTION_REQ_SUCCESS';
export const GET_SUBPRODUCTION_REQ_ERROR: string = 'GET_SUBPRODUCTION_REQ_ERROR';
export const SET_SUBPRODUCTION: string = 'SET_SUBPRODUCTION';
export const CLEAR_SUBPRODUCTION: string = 'CLEAR_SUBPRODUCTION';

export const getSubproductionReq: Action<{}> = createAsyncAction(GET_SUBPRODUCTION_REQ);
export const getSubproductionReqSuccess: Action<{}> = createAction(GET_SUBPRODUCTION_REQ_SUCCESS);
export const getSubproductionReqError: Action<{}> = createAction(GET_SUBPRODUCTION_REQ_ERROR);
export const setSubproduction: Action<{}> = createAction(SET_SUBPRODUCTION);
export const clearSubproduction: Action<{}> = createAction(CLEAR_SUBPRODUCTION);

/******************************************************************************/
/******************************* UPDATE ONE SUBPRODUCTION   **************************/
/******************************************************************************/

export const UPDATE_SUBPRODUCTION_REQ: string = 'UPDATE_SUBPRODUCTION_REQ';
export const UPDATE_SUBPRODUCTION_REQ_SUCCESS: string = 'UPDATE_SUBPRODUCTION_REQ_SUCCESS';
export const UPDATE_SUBPRODUCTION_REQ_ERROR: string = 'UPDATE_SUBPRODUCTION_REQ_ERROR';
export const UPDATE_SUBPRODUCTION: string = 'UPDATE_SUBPRODUCTION';

export const updateSubproductionReq: Action<{}> = createAsyncAction(UPDATE_SUBPRODUCTION_REQ);
export const updateSubproductionReqSuccess: Action<{}> = createAction(UPDATE_SUBPRODUCTION_REQ_SUCCESS);
export const updateSubproductionReqError: Action<{}> = createAction(UPDATE_SUBPRODUCTION_REQ_ERROR);
export const updateSubproduction: Action<{}> = createAction(UPDATE_SUBPRODUCTION);

/***********************************************************************************/
/******************************* GET PRODUCTION SUPPLIERS **************************/
/***********************************************************************************/

export const GET_PRODUCTION_SUPPLIERS_REQ: string = 'GET_PRODUCTION_SUPPLIERS_REQ';
export const getProductionSuppliersAsync: Action<{}> = createAsyncAction(GET_PRODUCTION_SUPPLIERS_REQ);
