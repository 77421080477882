/** @jsx jsx */
import { css } from '@emotion/core';
import * as styles from '../../../../../../styles/styles';

export const gatesWrap = css({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  '& .gate-col:last-child': {
    paddingRight: '0px',
  },
});

export const gateBox = (needHighlight: boolean) =>
  css({
    backgroundColor: styles.Colors.White,
    boxShadow: needHighlight ? '1px 1px 12px rgba(51, 51, 51, 0.65)' : '1px 1px 10px rgba(150, 150, 150, 0.25)',
    borderRadius: '4px',
    flex: '0 24%',
    minWidth: '350px',
    maxWidth: '450px',
    marginBottom: '2%',
    height: '350px',
    marginRight: '15px',
  });

export const gateHeader = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '60px',
  padding: '0 15px 0 25px',
  borderBottom: `1px solid ${styles.Colors.Grey}`,
  overflow: 'hidden',
  '& h3': [styles.H3, { fontSize: '16px', marginRight: '10px' }],
});

export const gateManageBtn = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const gateContent = css({
  maxHeight: '270px',
  overflow: 'auto',
  padding: '10px 30px 10px 25px',
});

export const gateContentRow = css({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  paddingBottom: '15px',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
});

export const gateContentCell = css({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  maxWidth: '150px',
  justifyContent: 'space-between',
});

export const cellTitle = [
  styles.H3,
  css({
    display: 'flex',
    width: '100%',
    padding: '5px 0',
    fontSize: '14px',
  }),
];

export const cellContent = [
  styles.MainText,
  css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '5px 0',
    color: '#898B96',
  }),
];

export const coordinatesLink = [
  styles.Link,
  css({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    color: '#4465E0',
    '&:hover': {
      textDecoration: 'underline'
    }
  })
];

export const noGatesTip = [
  styles.MainText
];
