/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import * as styles from '../../../../styles/styles';
import arrow from './images/arrow.svg';

export const multiSelectBox = css({
  padding: '0 15px 20px',
});

export const selectedBox = [
  styles.MainText,
  css({
    marginTop: '10px',
  }),
];

export const searchBox = css({
  marginTop: '10px',
});

export const actionsBox = css({
  marginTop: '10px',
  display: 'flex',
  justifyContent: 'flex-end',
  '& button': {
    marginLeft: '10px',
  },
});

export const chunkBox = css({
  marginTop: '10px',
  display: 'flex',
});

export const variantsBox = css({});
export const optionsBox = css({
  paddingTop: '15px',
  paddingBottom: '15px',
});
export const options = css({
  paddingTop: '7px',
});
export const variantBox = css({
  display: 'flex',
  alignItems: 'center',
  marginTop: '10px',
  minWidth: '180px',
  '&:first-of-type': {
    marginRight: '10px',
  },

  width: '50%',
  '& input': {
    marginRight: '17px',
    cursor: 'pointer',
  },

  '& label': [
    styles.MainText,
    css({
      display: 'inline',
      cursor: 'pointer',
      overflowX: 'hidden',
    }),
  ],
});

export const multiSelectArea = [
  styles.InputText,
  css({
    backgroundColor: styles.Colors.White,
    padding: '8px 25px 8px 13px',
    border: `1px solid ${styles.Colors.Grey} `,
    cursor: 'pointer',
    lineHeight: '20px',
    '&:hover': {
      border: `1px solid ${styles.Colors.Green} `,
    },
  }),
];

export const multiSelectPopoverBox = css({
  maxWidth: '400px',
});

export const multiSelectMsg = [
  styles.MainText,
  css({
    padding: '10px',
  }),
];

export const multiSelectInputWrapper = (isOpen: boolean) =>
  css({
    position: 'relative',

    '&:after': {
      content: '""',
      position: 'absolute',
      right: '0',
      top: '0',
      bottom: '0',
      width: '36px',
      backgroundImage: `url( ${arrow} )`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '15px',
      transform: `rotate( ${isOpen ? 180 : 0}deg )`,
    },
  });

export const multiSelectInput = [
  css({
    paddingRight: '30px !important',
  }),
];

export const addNewContainer = css({
  paddingTop: '30px',
});

export const tabItem = (isActive: boolean) =>
  css({
    lineHeight: '30px',
    fontSize: '13px',
    fontWeight: 400,
    paddingLeft: '18px',
    cursor: 'pointer',
    paddingRight: '18px',
    paddingTop: '8px',
    paddingBottom: '12px',
    borderBottom: isActive ? '1px solid #48671C' : '1px solid #e6e6e6',
    color: isActive ? '#48671C' : '#505050',
    ':focus': {
      backgroundColor: styles.Colors.AltGrey,
    },
  });

export const addNewMessage = css({
  fontWeight: 400,
  fontSize: '13px',
  color: '#B1B1B1',
  textAlign: 'right',
  paddingBottom: '10px',
  paddingRight: '10px',
  paddingLeft: '12px',
  marginTop: '16px',
  cursor: 'pointer',
  textDecoration: 'underline',
  transition: 'text-decoration 0.5s ease-in-out',
  ':focus': {
    outline: 'none',
    textDecorationThickness: '2px',
    textDecorationStyle: 'double',
    transition: 'text-decoration 0.5s ease-in-out',
  },
});

export const searchText = [
  styles.MainText,
  css({
    fontWeight: 400,
    fontSize: '13px',
    color: '#B1B1B1',
    textAlign: 'left',
    paddingTop: '25px',
    paddingRight: '10px',
    paddingLeft: '12px',
    border: 'none',
    outline: 'none',
    ':hover, :focus-within, :focus': css({
      border: 'none',
      outline: 'none',
    }),
  }),
];

export const searchQuery = css({
  fontWeight: 700,
  fontSize: '13px',
  color: '#B1B1B1',
  textAlign: 'right',
  paddingBottom: '10px',
});

export const simpleSelectInputWrapper = (isOpen: boolean) =>
  css({
    position: 'relative',

    '&:after': {
      content: '""',
      position: 'absolute',
      right: '0',
      top: '0',
      bottom: '0',
      width: '36px',
      backgroundImage: `url( ${arrow} )`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '15px',
      transform: `rotate( ${isOpen ? 180 : 0}deg )`,
    },
  });
export const simpleSelectInput = [
  css({
    paddingRight: '30px !important',
  }),
];
export const simpleSelectPopoverBox = css({
  maxWidth: '400px',
});

export const input = css({
  padding: '8px 0 8px 13px',
  display: 'flex',
});
export const xxx = css({});
