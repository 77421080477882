/** @jsx jsx */
import { jsx } from '@emotion/core';
import { compose, lifecycle } from 'recompose';
import { RouteComponentProps } from 'react-router';

import { connect } from '../../../../../utils/redux';

import { getSupplierAgentReq, clearSupplierAgent } from './store/actions';
import { Action } from '../../../../../store/types';
import { supplierAgent } from './store/selectors';
import EditForm from './EditForm';

import * as styles from './styles';
import { ISupplierAgent } from './store/types';

interface FormValues extends Partial<ISupplierAgent> {}

interface MatchParams {
  id?: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  getSupplierAgentReq: Action<{}>;
  clearSupplierAgent: Action<{}>;
  supplierAgent: ISupplierAgent;
  ownerId: string;
}

jsx;

const withLifecycle = lifecycle<IProps, {}>({
  componentDidMount() {
    const { clearSupplierAgent, getSupplierAgentReq } = this.props;
    clearSupplierAgent();
    getSupplierAgentReq(this.props.match.params);
  },
});

const Edit = (props: IProps) => {
  const form = props.supplierAgent ? <EditForm {...props} /> : <div>Loading...</div>;
  return <div css={styles.formBox}>{form}</div>;
};

interface FormProps {
  initialEmail?: string;
}

export default compose<IProps, {}>(
  connect(
    { supplierAgent },
    { getSupplierAgentReq, clearSupplierAgent }
  ),
  withLifecycle
)(Edit);
