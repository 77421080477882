import si, { ImmutableObject } from 'seamless-immutable';
import _ from 'lodash';
import * as r from '../../../../../utils/reducers';
import * as actions from './actions';
import { processActions } from '../../../../../utils/redux';
import { getListState, ListStatuses } from '../../../../../utils/store/state/state';
import { ProductionSupplierListState } from './types';
import { IProductionSupplier } from '../../Suppliers/store/types';

interface IState {
  list: ProductionSupplierListState;
}

const INITIAL_STATE: ImmutableObject<IState> = si({
  list: getListState({
    page: 0,
  }),
});

const listReducer = r.createReducer('list');

export default processActions(
  () => ({
    // GET SUPLIERS LIST
    [actions.GET_PRODUCTION_SUPPLIERS_REQ]: listReducer(r.loadingReducer),
    [actions.GET_PRODUCTION_SUPPLIERS_REQ_SUCCESS]: (store: ImmutableObject<IState>, action: any) => {
      const {
        payload: { data, params },
        meta,
      } = action;
      return store
        .setIn(['list', 'data'], params.page > 0 ? store.list.data.concat(data) : data)
        .setIn(['list', 'status'], ListStatuses.Actual)
        .setIn(['list', 'meta'], meta)
        .setIn(['list', 'params'], params);
    },
    [actions.GET_PRODUCTION_SUPPLIERS_REQ_ERROR]: listReducer(r.errorReducer),

    // UPDATE SUPLIER
    [actions.UPDATE_PRODUCTION_SUPPLIER_REQ]: listReducer(r.loadingReducer),
    [actions.UPDATE_PRODUCTION_SUPPLIER_REQ_SUCCESS]: (store: any, { payload }: any) => {
      const index = _.findIndex(store.list.data, { id: payload.id });
      return store
        .setIn(['list', 'data', String(index)], payload) // update item in list
        .setIn(['list', 'status'], ListStatuses.Actual);
    },
    [actions.UPDATE_PRODUCTION_SUPPLIER_REQ_ERROR]: listReducer(r.errorReducer),

    // DELETE SUPPLIER
    [actions.DELETE_PRODUCTION_SUPPLIER_REQ]: listReducer(r.loadingReducer),
    [actions.DELETE_PRODUCTION_SUPPLIER_REQ_SUCCESS]: (store: any, { payload }: any) => {
      return store
        .setIn(['list', 'data'], store.list.data.filter((item: any) => item.id !== payload.id))
        .setIn(['list', 'status'], ListStatuses.Actual);
    },
    [actions.DELETE_PRODUCTION_SUPPLIER_REQ_ERROR]: listReducer(r.errorReducer),

    // CREATE SUPPLIER
    [actions.CREATE_PRODUCTION_SUPPLIER_REQ]: listReducer(r.loadingReducer),
    [actions.CREATE_PRODUCTION_SUPPLIER_REQ_SUCCESS]: listReducer(r.successReducer),
    [actions.CREATE_PRODUCTION_SUPPLIER_REQ_ERROR]: listReducer(r.errorReducer),
  }),

  INITIAL_STATE
);
