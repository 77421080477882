/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { MouseEvent } from 'react';
import * as styles from '../styles';

export interface IFormRowProps {
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  onBlur?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  tabIndex?: number;
}

jsx;
export const ApplyAction = React.forwardRef<HTMLButtonElement, IFormRowProps>((props, ref) => {
  return (
    <button
      type="button"
      css={styles.forwardedApplyAction}
      onClick={props.onClick}
      ref={ref}
      tabIndex={props.tabIndex ? props.tabIndex : 0}
      onKeyDown={props.onKeyDown}
      onBlur={props.onBlur}
    />
  );
});

export default ApplyAction;
