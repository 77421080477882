import _ from 'lodash';
import { createAction } from 'redux-actions';

export const ASYNC_ACTION_KEY = 'api';

export const createAsyncAction = (type) => (payload, meta) => {
  const action = createAction(type, (payload) => payload, () => meta)(payload);
  return { ...action, ...{ [ASYNC_ACTION_KEY]: null } };
};

export const createPromisifyAsyncAction = (type) => (payload) => (dispatch) => {
  const promise = new Promise((resolve, reject) => {
    const action = { ...createAction(type)(payload), ...{ [ASYNC_ACTION_KEY]: null, resolve, reject } };
    dispatch(action);
  });
  return promise;
};

//export const api
export const setActionApi = (action, api) => {
  if (isAsyncAction(action)) {
    action[ASYNC_ACTION_KEY] = proxifyApi(api, action.resolve, action.reject);
  }
  return action;
};

export const proxifyApi = (api, resolve, reject) => {
  return new Proxy(api, {
    get(target, prop) {
      let targetProp = _.get(target, prop);
      if (_.isFunction(targetProp) && resolve && reject) {
        return (...params) => {
          const result = targetProp(...params);
          if (result.then) {
            result
              .then((resp) => {
                resolve(resp);
              })
              .catch((err) => {
                reject(err);
              });
          }

          return result;
        };
      }
      return targetProp;
    },
  });
};

export const isAsyncAction = (action) => {
  return !_.isUndefined(action, ASYNC_ACTION_KEY);
};
