/** @jsx jsx */
import { Component } from 'react';
import { jsx } from '@emotion/core';
// components
import MainNav from './MainNav/MainNav';
import UserDropdown from './UserDropdown/UserDropdown';
import { Link } from 'react-router-dom';
//context
import withVisualContext from '../../../../contexts/withVisualContext';
// other
import * as styles from './styles';

interface IProps {
  visual: { logo: string };
}

jsx;
class Header extends Component<IProps> {
  render() {
    return (
      <header css={styles.header}>
        <MainNav />
        <Link to="/">
          <img css={styles.logo} src={this.props.visual.logo} alt="" />
        </Link>
        <UserDropdown />
      </header>
    );
  }
}

export default withVisualContext(Header);
