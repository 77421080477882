/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import { Component, Fragment } from 'react';

//components
import widget from '../../../../common/widget';
import modal from '../../../../common/modal';
import OutlineBtn from '../../../../common/buttons/OutlineBtn';
import PopoverBtn from '../../../../common/popover/PopoverBtn';
import PrimaryBtn from '../../../../common/buttons/PrimaryBtn';
import Text from '../../../../common/form/Text';
import { Link } from 'react-router-dom';
import Loader from '../../../../common/loader/Loader';
import CheckAcl from '../../CheckAcl';

//store
import { VersionsListState } from './store/types';
import { createVersionReq } from './store/actions';

//utils
import { connect } from '../../../../../utils/redux';
import { excludeBy } from '../../../../../utils/collection';
import withAcl from '../../withAcl';
import moment from 'moment';
import { getTimeFormat } from '../../../../../utils/timeFormatter';
import _ from 'lodash';
import { toast } from 'react-toastify';
import * as redux from '../../../../../utils/redux';

//other
import { DATE_FORMAT } from '../../../../../config';
import * as styles from '../../../../../styles/styles';
import routes from '../../../../../routes';

import arrowDown from '../../../../../images/icons/arrow-down.svg';
import { Action } from '../../../../../store/types';

export interface IProps {
  versionList: VersionsListState;
  productionId: number;
  createVersionReq: Action<{}>;
  prodVersion?: any;
}

export interface IState {
  isOpenModal: boolean;
  newVersion: string | null;
}

jsx;
class Versions extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isOpenModal: false,
      newVersion: null,
    };
  }

  openModal = () => {
    this.setState({ isOpenModal: true, newVersion: null });
  };

  closeModal = () => {
    this.setState({ isOpenModal: false });
  };

  addNewVersion = () => {
    const { createVersionReq, productionId } = this.props;
    const { newVersion } = this.state;
    if (newVersion) {
      createVersionReq({ id: productionId, name: newVersion }).then(() => {
        this.closeModal();
      });
    } else {
      toast.warn('Please enter version name!');
    }
  };

  setVersionName = (newVersion: string | null) => {
    this.setState({ newVersion });
  };

  changeVersionName = (e: any) => {
    this.setState({ newVersion: e.current.value });
  };

  render() {
    const {
      versionList: { data, status },
      productionId,
      prodVersion,
    } = this.props;

    if (data) {
      const { isOpenModal, newVersion } = this.state;
      const { Modal, ModalHeader, ModalFooter, ModalBody, ModalActions } = modal;

      const timeFormat = getTimeFormat();
      const otherVersions = excludeBy(data, { 'production.id': productionId });

      return (
        <div css={mainBox}>
          <div css={currentVersionsBox}>
            <div>Version: {prodVersion}</div>
            {!!otherVersions && !!otherVersions.length && (
              <PopoverBtn
                btn={<div css={versionsListTrigger} />}
                renderContent={(closeDropdown) => {
                  return (
                    <div css={versionListBox}>
                      {otherVersions.map(
                        ({ name, isCurrent, updatedBy, prodUpdatedAt, production: { id }, ...xxx }: any) => {
                          return (
                            <Link
                              key={id}
                              onClick={closeDropdown}
                              css={versionRow(isCurrent)}
                              to={routes.Production.replace(':id', id)}
                            >
                              <div css={versionRow(isCurrent)}>
                                <div css={versionCell}>{name}</div>
                                <div css={producerCell}>{updatedBy.name}</div>
                                <div css={timeCell}>
                                  {moment
                                    .utc(prodUpdatedAt)
                                    .local()
                                    .format(`${DATE_FORMAT} ${timeFormat}`)}
                                </div>
                              </div>
                            </Link>
                          );
                        }
                      )}
                    </div>
                  );
                }}
              />
            )}
          </div>
          <div css={newVersionBox}>
            <CheckAcl resources="production" actions="C">
              <OutlineBtn title="Add version" onClick={this.openModal} />
            </CheckAcl>

            <Modal isOpen={isOpenModal}>
              <ModalHeader>New version</ModalHeader>
              <ModalBody>
                <div css={loaderBox}>
                  <Loader isLoading={status === redux.STATUS_REQUEST} />
                </div>
                <Text
                  onChange={(e: any) => {
                    this.setVersionName(e.target.value);
                  }}
                  value={newVersion || ''}
                />
              </ModalBody>
              <ModalFooter>
                <ModalActions>
                  <OutlineBtn type="button" onClick={this.closeModal} title="Cancel" />
                  <PrimaryBtn title="Add" type="submit" onClick={this.addNewVersion} />
                </ModalActions>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default connect(
  {},
  {
    createVersionReq,
  }
)(withAcl(Versions));

const mainBox = css({
  display: 'flex',
  alignItems: 'center',
});

const currentVersionsBox = [
  styles.H3,
  css({
    display: 'flex',
    alignItems: 'center',
    marginRight: '30px',
  }),
];

const versionsListTrigger = css({
  background: `url(${arrowDown}) 50% 50% no-repeat`,
  width: '30px',
  height: '30px',
  cursor: 'pointer',
});

const newVersionBox = css({});

const versionListBox = css({
  //padding: '0 20px 20px 20px',
});

const versionRow = (isCurrent: boolean = false) => [
  styles.MainText,
  css({
    display: 'flex',
    width: '600px',
    padding: '10px 20px',
    boxSizing: 'border-box',
    backgroundColor: isCurrent ? styles.Colors.LightGrey : 'inherit',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: styles.Colors.LightGrey,
    },
    textDecoration: 'none',
  }),
];

const versionCell = css({
  width: '35%',
});
const producerCell = css({
  width: '35%',
});
const timeCell = css({
  width: '30%',
  textAlign: 'right',
});

const loaderBox = css({
  position: 'absolute',
  top: '34%',
  left: '50%',
});
