/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, Component, Children, FunctionComponent, ReactElement } from 'react';

import PopoverBtn from '../popover/PopoverBtn';

import * as styles from './styles';
export interface IProps {}

jsx;
const ActionList: FunctionComponent<IProps> = ({ children }): ReactElement<IProps> => {
  return (
    <PopoverBtn
      contentLocation={({ targetRect, popoverRect, nudgedTop, nudgedLeft }) => {
        const pageYOffset = window.pageYOffset;
        const pageXOffset = window.pageXOffset;

        if (targetRect.top <= 0) {
          return { top: targetRect.top + pageYOffset, left: nudgedLeft + pageXOffset };
        }

        if (window.innerHeight - targetRect.top <= 0) {
          return { top: targetRect.top - popoverRect.height + pageYOffset, left: nudgedLeft + pageXOffset };
        }

        return { top: nudgedTop + pageYOffset, left: nudgedLeft + pageXOffset };
      }}
      btn={<button css={styles.actionListBtn} tabIndex={-1} />}
      renderContent={() => children}
    />
  );
};

export default ActionList;
