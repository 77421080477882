/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, InputHTMLAttributes } from 'react';
import * as styles from './styles';

jsx;

interface IProps extends InputHTMLAttributes<{}> {}

export const Checkbox: FunctionComponent<IProps> = (props): ReactElement<IProps> => {
  return <input type="checkbox" css={styles.checkboxInput} {...props} onKeyPress={props.onChange} />;
};

export default Checkbox;
