/** @jsx jsx */
import { jsx } from '@emotion/core';
import moment from 'moment';
import _ from 'lodash';
import si from 'seamless-immutable';
import * as utils from '../../utils';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { Component, createRef, Fragment, SyntheticEvent } from 'react';
import { DragSourceMonitor, DragSource } from 'react-dnd';

import * as styles from './styles';

import * as array from '../../../../../../../utils/array';

enum RESIZE_TARGETS {
  From = 'from',
  To = 'to',
}

export interface IProps {
  machinery: any;
  data: any;
  isEdit?: boolean;
  onChange?: (range: any) => void;
  onMoveOutside?: (newMachineryId: any, userId: any) => void;
  boxRange: any;
  limitRange: any;
  timelineBoxEl: any;
  timeRound?: number;
  connectDragSource?: any;
  connectDragPreview?: any;
}

export interface IState {
  startValue: any;
  currentBarWidth: any;
  currentValue: any;
  pixelValue: any;
  isVerticalMove: boolean;
  isMove: boolean;
  resizeTarget: RESIZE_TARGETS | null;
  mouseStartPosition: number;
  deltaTime: any;
}

jsx;

class TimelineValuePart extends Component<IProps, IState> {
  private timelineValue: any;

  constructor(props: IProps) {
    super(props);

    this.timelineValue = createRef();

    this.state = {
      startValue: null,
      currentBarWidth: 0,
      currentValue: null,
      pixelValue: null,
      resizeTarget: null,
      isMove: false,
      isVerticalMove: false,
      mouseStartPosition: 0,
      deltaTime: null,
    };
  }

  componentDidMount() {
    const { connectDragPreview } = this.props;
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), { captureDraggingState: true });
    }
  }

  componentWillUnmount(): void {
    this.clearResize();
  }

  getValueRange = (data: any) => {
    return _.get(data, 'user.range');
  };

  startMove = (e: any, target: RESIZE_TARGETS | null = null) => {
    const isVerticalMove = ['ctrlKey', 'altKey', 'cmdKey', 'metaKey'].some((key) => e[key]);
    const handler = isVerticalMove ? this.startVerticalMove : this.startHorizontalMove;
    handler(e, target);
  };

  startHorizontalMove = (e: any, target: RESIZE_TARGETS | null = null) => {
    e.stopPropagation();
    e.preventDefault();

    const { data, timelineBoxEl, boxRange } = this.props;
    const valueRange = this.getValueRange(data);

    window.addEventListener('mousemove', this.mouseMove, false);
    window.addEventListener('click', this.applyHorizontalMove, false);

    const timeDelta = moment.utc(boxRange.to).valueOf() - moment.utc(boxRange.from).valueOf();
    const pixelValue = Math.round(timeDelta / timelineBoxEl.offsetWidth);
    const startValue = this.getValueInBoxRange(valueRange, boxRange);

    this.setState({
      isMove: true,
      mouseStartPosition: e.clientX,
      resizeTarget: target,
      pixelValue,
      currentValue: startValue,
      startValue,
      deltaTime: 0,
    });
  };

  startVerticalMove = (e: any, target: RESIZE_TARGETS | null = null) => {
    this.setState({ isVerticalMove: true });
  };

  applyHorizontalMove = () => {
    const { currentValue, startValue, resizeTarget, deltaTime } = this.state;
    const { onChange = _.identity, data, boxRange } = this.props;
    const valueRange = this.getValueRange(data);

    let apply;

    this.clearResize();
    if (resizeTarget) {
      const valueRangeConverted = _.mapValues(valueRange, (item) => moment.utc(item).valueOf());
      const applyValue = this.processResizeApplyValue(currentValue, resizeTarget);
      apply = { ...valueRangeConverted, [resizeTarget]: applyValue };
    } else {
      const {
        user: {
          range: { from, to },
        },
      } = data;
      apply = this.processMoveApplyValue({
        from: moment.utc(from).valueOf() + deltaTime,
        to: moment.utc(to).valueOf() + deltaTime,
      });
    }

    if (this.state.isMove) {
      this.setState({ isMove: false, currentValue: null, resizeTarget: null, deltaTime: null });
    }

    if (startValue.from !== apply.from || startValue.to !== apply.to) {
      onChange(apply);
    }
  };

  getValueInBoxRange = (valueRange: any, boxRange: any) => {
    const valueFrom = moment.utc(valueRange.from).valueOf();
    const valueTo = moment.utc(valueRange.to).valueOf();
    const boxFrom = moment.utc(boxRange.from).valueOf();
    const boxTo = moment.utc(boxRange.to).valueOf();
    return { from: valueFrom < boxFrom ? boxFrom : valueFrom, to: valueTo > boxTo ? boxTo : valueTo };
  };

  clearResize = () => {
    window.removeEventListener('mousemove', this.mouseMove);
    window.removeEventListener('click', this.applyHorizontalMove);
  };

  mouseMove = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState((state, props) => {
      const deltaTime = this.getDeltaTime(state, e.clientX);
      const currentValue = this.getNewCurrentValue(state, deltaTime);
      return currentValue ? { ...state, currentValue, deltaTime } : state;
    });
  };

  getDeltaTime = ({ pixelValue, mouseStartPosition }: any, currentCursorPositionX: any) => {
    return (currentCursorPositionX - mouseStartPosition) * pixelValue;
  };

  getNewCurrentValue = ({ resizeTarget, currentValue, startValue }: any, deltaTime: number) => {
    if (startValue && currentValue) {
      if (resizeTarget) {
        const newTargetValue = startValue[resizeTarget] + deltaTime;
        return { ...currentValue, [resizeTarget]: newTargetValue };
      } else {
        return { from: startValue['from'] + deltaTime, to: startValue['to'] + deltaTime };
      }
    }
    return null;
  };

  resolveResizeCurrentValue = (currentValue: any, resizeTarget: any) => {
    if (utils.tValue(currentValue.from) > utils.tValue(currentValue.to)) {
      const { timeRound = 30 } = this.props;
      const step = timeRound * 60 * 1000;
      const changeValue = resizeTarget === RESIZE_TARGETS.From ? currentValue.to - step : currentValue.from + step;
      currentValue = { ...currentValue, [resizeTarget]: changeValue };
    }
    return currentValue;
  };

  processResizeApplyValue = (currentValue: any, resizeTarget: any) => {
    const { timeRound = 30 } = this.props;
    currentValue = this.resolveResizeCurrentValue(currentValue, resizeTarget);

    let value = utils.tValue(_.get(currentValue, resizeTarget));
    const {
      boxRange,
      limitRange,
      data: { period },
    } = this.props;

    const range = utils.limitRange(utils.limitRange(boxRange, limitRange), period);
    const rangeValue = _.get(range, resizeTarget);
    value = this.roundApplyValue(value, timeRound);

    if (resizeTarget === RESIZE_TARGETS.From) {
      value = utils.tValue(value) < utils.tValue(rangeValue) ? rangeValue : value;
    } else {
      value = utils.tValue(value) > utils.tValue(rangeValue) ? rangeValue : value;
    }

    return moment.utc(value).valueOf();
  };

  processMoveApplyValue = (range: any) => {
    const { limitRange, timeRound = 30 } = this.props;
    const outsideFrom = moment.utc(limitRange.from).valueOf() - moment.utc(range.from).valueOf();
    const outsideTo = moment.utc(range.to).valueOf() - moment.utc(limitRange.to).valueOf();

    if (outsideFrom > 0) {
      range = { from: range.from + outsideFrom, to: range.to + outsideFrom };
    } else if (outsideTo > 0) {
      range = { from: range.from - outsideTo, to: range.to - outsideTo };
    }
    return { from: this.roundApplyValue(range.from, timeRound), to: this.roundApplyValue(range.to, timeRound) };
  };

  roundApplyValue = (value: number, step: number = 30) => {
    step = step * 60 * 1000;
    const tail = value % step;
    const body = value - tail;
    value = step / 2 >= tail ? body : body + step;
    return value;
  };

  getCurrentValue = () => {
    return this.state.currentValue;
  };

  getPeriodDiff = () => {
    const { boxRange } = this.props;
    return moment.utc(boxRange.to).valueOf() - moment.utc(boxRange.from).valueOf();
  };

  getDayPercents = (value: number) => {
    return (value / this.getPeriodDiff()) * 100;
  };

  getDiffPercents = (from: number, to: number) => {
    return this.getDayPercents(to - from);
  };

  defineLineParts = (start: any, range: any) => {
    const indent = this.getDiffPercents(moment.utc(start).valueOf(), moment.utc(range.from).valueOf());
    const value = this.getDiffPercents(moment.utc(range.from).valueOf(), moment.utc(range.to).valueOf());
    return [indent, value];
  };

  render() {
    const {
      isEdit = false,
      data: { user, period },
      boxRange,
      timelineBoxEl,
      connectDragSource,
    } = this.props;

    const intersectionBefore = _.get(user, 'intersection.before');
    const intersectionAfter = _.get(user, 'intersection.after');
    const beforeIntersectionStyle = intersectionBefore ? [styles.intersectionBefore] : [];
    const afterIntersectionStyle = intersectionAfter ? [styles.intersectionAfter] : [];

    const valueInBox = this.getCurrentValue() || this.getValueInBoxRange(user.range, boxRange);
    const [indentWidth, valueWidth] = this.defineLineParts(boxRange.from, valueInBox);

    return connectDragSource(
      <div className="timeline-part-box" draggable>
        <div css={styles.timelinePartBox}>
          <div
            ref={this.timelineValue}
            css={[
              styles.timelinePart(indentWidth, valueWidth),
              styles.timelinePartColor(user.supplier.color.code),
              beforeIntersectionStyle,
              afterIntersectionStyle,
            ]}
          >
            {isEdit && (
              <div
                onMouseDown={(e) => {
                  this.startMove(e);
                }}
                css={styles.moveHandler}
              />
            )}

            {isEdit && !intersectionBefore && (
              <div
                onMouseDown={(e) => {
                  this.startMove(e, RESIZE_TARGETS.From);
                }}
                css={styles.resizeHandlerLeft}
              />
            )}
            {isEdit && !intersectionAfter && (
              <div
                onMouseDown={(e) => {
                  this.startMove(e, RESIZE_TARGETS.To);
                }}
                css={styles.resizeHandlerRight}
              />
            )}
          </div>
        </div>
      </div>,
      { dropEffect: 'move' }
    );
  }
}

const barSource = {
  beginDrag({ data }: any, monitor: any, { timelineValue }: any) {
    return { data, timelineValueEl: timelineValue };
  },

  canDrag: ({ isEdit = false }: any, monitor: DragSourceMonitor) => {
    return isEdit;
  },

  endDrag({ data: { user }, machinery, onMoveOutside = _.identity }: any, monitor: any, component: any) {
    const dropResult = monitor.getDropResult();

    if (dropResult) {
      const { afterDrop } = dropResult;
      afterDrop(user, machinery);
    }

    if (!monitor.didDrop()) {
      return;
    }
  },
};

function collect(connect: any, monitor: any) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging(),
  };
}

export default DragSource('machinery-timeline-bar', barSource, collect)(TimelineValuePart);
