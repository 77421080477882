import { IList, IItem } from '../../../../../../utils/store/state/state';
import { ISupplier } from '../../../Suppliers/store/types';
import { IProduction } from '../../store/types';

export interface IDataRange {
  from: string | number;
  to: string | number;
}

export enum ActivityTypes {
  ACTIVITY = 'Activity',
  SHOW = 'Show',
  REHEARSAL = 'Rehearsal',
  TRANSPORT = 'Transport',
}

export interface IActivity {
  id: number;
  isMain: boolean;
  name: string;
  range: IDataRange;
  remarks: string;
  type: ActivityTypes;
  functions?: any[];
  suppliers?: (
    | ISupplier
    | {
        id: number;
        name: string;
      })[];
  production?: IProduction;
  transport?: any;
}

export type VersionsListState = IList<IActivity, any>;
export type ActivitiesListState = IList<IActivity, any>;
export type ActivitiesItemState = IItem<IActivity, any>;
