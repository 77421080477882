/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import * as styles from './styles';
import Text from './Search';

export interface IProps {
  title: string;
}

jsx;

export const ToolbarActionBox: FunctionComponent<IProps> = ({ title, children }): ReactElement<IProps> => {
  return (
    <div css={styles.actionBox}>
      <div css={styles.actionTitle}>{title}</div>
      <div css={styles.action}>{children}</div>
    </div>
  );
};

export default ToolbarActionBox;
