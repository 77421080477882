/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
// redux
import { connect } from '../../../../utils/redux';
import { production } from '../Productions/store/selectors';
import { productionSuppliers } from './store/selectors';
import { supplierFunctions } from '../SupplierFunctions/store/selectors';
import { suppliersForSelect, suppliersFiltered } from '../Suppliers/store/selectors';
import { getProductionReq } from '../Productions/store/actions';

import { getProducersReq } from '../Producers/store/actions';
import { producers } from '../Producers/store/selectors';
import { IProducer } from '../Producers/store/types';
import {
  getProductionSuppliersReq,
  updateProductionSupplierReq,
  deleteProductionSupplierReq,
  createProductionSupplierReq,
} from './store/actions';
import { getSupplierFunctionsReq } from '../SupplierFunctions/store/actions';
import { getSuppliersForSelectReq, getSuppliersFilteredReq } from '../Suppliers/store/actions';
// types
import { Action } from '../../../../store/types';
import { ISupplierAgentLink } from '../Suppliers/Agents/store/types';
// import { ProductionSupplierListState, IProductionSupplier, SupplierListState } from '../Suppliers/store/types';
import { ProductionSupplierListState, IProductionSupplier, SupplierListState } from './store/types';
import { IProduction } from '../Productions/store/types';
import { SupplierFunctionListState } from '../SupplierFunctions/store/types';
// components
import { Waypoint } from 'react-waypoint';
import Loader from '../../../common/loader/Loader';
import PageHeader from '../../../common/page/PageHeader';
import Breadcrumbs from '../../../common/page/Breadcrumbs';
import ToolBar from '../../../common/grid/ToolBar';
import Footer from '../../../common/grid/Footer';
import MultiSelectInput from '../../../common/form/multiselect/MultiSelectInput';
import Input from '../../../common/form/Text';
import ProductionSupplierEditRow from './ProductionSupplierEditRow';
import ProductionSupplierRow from './ProductionSupplierRow';
import ProductionSupplierCreate from './ProductionSupplierCreate';
// styles
import { gridBox } from '../../../common/grid/styles';
// utils
import _ from 'lodash';
// other
import Routes from '../../../../routes';
import { Colors, FontFamilies } from '../../../../styles/styles';
import { ListStatuses } from '../../../../utils/store/state/state';
import OutlineBtn from '../../../common/buttons/OutlineBtn';
import Modal from '../../../common/modal/Modal';
import withAcl, { IWrappedProps } from '../withAcl';
import withVisualContext from '../../../../contexts/withVisualContext';
import { IVisualContext } from '../../../../contexts/VisualContext';

interface IRouteParams {
  id: string;
}

export interface IProps extends RouteComponentProps<IRouteParams>, IWrappedProps {
  production: IProduction;
  productionSuppliers: ProductionSupplierListState;
  supplierFunctions: SupplierFunctionListState;
  suppliersForSelect: SupplierListState;
  suppliersFiltered: SupplierListState;
  getProductionReq: Action<{}>;
  getProductionSuppliersReq: Action<{}>;
  updateProductionSupplierReq: Action<{}>;
  deleteProductionSupplierReq: Action<{}>;
  getSupplierFunctionsReq: Action<{}>;
  getSuppliersForSelectReq: Action<{}>;
  getSuppliersFilteredReq: Action<{}>;
  createProductionSupplierReq: Action<{}>;
  getProducersReq: Action<{}>;
  producers: IProducer[];
  visual: IVisualContext;
}

interface IState {
  editSupplierId: number | null;
  supplierFilter: number[];
  functionFilter: number[];
  search: string;
  isCreateModalOpen: boolean;
}

jsx;
class ProductionSuppliers extends Component<IProps, IState> {
  private fetchDataDebounced: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      editSupplierId: null,
      isCreateModalOpen: false,
      supplierFilter: [],
      functionFilter: [],
      search: '',
    };
    this.fetchDataDebounced = _.debounce(this.fetchData, 250);
  }

  componentDidMount() {
    const {
      production,
      supplierFunctions,
      suppliersForSelect,
      getProductionReq,
      getProductionSuppliersReq,
      getSupplierFunctionsReq,
      getSuppliersForSelectReq,
      getProducersReq,
      match: { params },
    } = this.props;
    if (params.id !== String(_.get(production, 'id'))) getProductionReq(params.id);
    if (!_.get(supplierFunctions, 'data')) getSupplierFunctionsReq({ size: 1000 });
    if (!_.get(suppliersForSelect, 'data')) getSuppliersForSelectReq({ size: 1000 });
    getProductionSuppliersReq({ productionId: params.id });
    getProducersReq({ size: 1000 });
  }

  fetchMoreProductionSuppliers = () => {
    const {
      productionSuppliers,
      getProductionSuppliersReq,
      match: { params },
    } = this.props;
    const { page } = productionSuppliers.params;
    getProductionSuppliersReq({ ...productionSuppliers.params, page: page + 1, size: 20, productionId: params.id });
  };

  onWaypointEnter = () => {
    const { productionSuppliers } = this.props;
    if (productionSuppliers.status === ListStatuses.Request) return;
    this.fetchMoreProductionSuppliers();
  };

  editRow = (id: number) => {
    const { test } = this.props;
    if (test('supplier', 'U')) {
      this.setState({ editSupplierId: id });
    }
  };

  cancelEdit = () => {
    this.setState({ editSupplierId: null });
  };

  deleteRow = (id: number) => {
    const { deleteProductionSupplierReq, production } = this.props;
    deleteProductionSupplierReq({
      productionId: production.id,
      prodSupplierId: id,
    });
  };

  onFunctionFilterChange = (values: any) => {
    this.setState({ functionFilter: values }, this.fetchData);
  };

  onSupplierFilterChange = (values: any) => {
    this.setState({ supplierFilter: values }, this.fetchData);
  };

  onSearchChange = (e: any) => {
    this.setState({ search: e.target.value }, this.fetchDataDebounced);
  };

  fetchData = () => {
    const {
      getProductionSuppliersReq,
      match: { params },
    } = this.props;
    const { functionFilter, supplierFilter, search } = this.state;

    getProductionSuppliersReq({
      productionId: params.id,
      page: 0,
      'filter.function.id': functionFilter,
      'filter.supplier.id': supplierFilter,
      searchKey: 'agent.name',
      search,
    });
  };

  openCreateModal = () => {
    const { getSuppliersFilteredReq, production } = this.props;

    getSuppliersFilteredReq({ excludeProductionSuppliers: production.id });

    this.setState({ isCreateModalOpen: true });
  };

  closeCreateModal = () => {
    this.setState({ isCreateModalOpen: false });
  };

  onCreateSupplier = (values: any) => {
    const { createProductionSupplierReq, production } = this.props;

    let data: any = {
      supplier: values.supplier,
    };

    const supplierAgents = _.filter(values.agents, (item) => item.agent !== null && item.function !== null);
    if (supplierAgents.length) data['agents'] = supplierAgents;

    createProductionSupplierReq({
      productionId: production.id,
      data,
    });

    this.closeCreateModal();
  };

  onUpdateSupplier = (productionSupplier: IProductionSupplier, values: any) => {
    const { updateProductionSupplierReq, production } = this.props;

    const agents = values.supplierAgents.filter((item: any) => item.agent || item.function);
    const producers = values.producers;

    updateProductionSupplierReq({
      productionId: production.id,
      prodSupplierId: productionSupplier.id,
      data: {
        supplier: _.get(productionSupplier, 'supplier.id'),
        agents,
        producers,
      },
    });
  };

  render() {
    const {
      production,
      productionSuppliers,
      supplierFunctions,
      suppliersForSelect,
      suppliersFiltered,
      match: { params },
      test,
      producers,
      visual,
    } = this.props;
    const { editSupplierId, functionFilter, supplierFilter, search, isCreateModalOpen } = this.state;
    const suppliers = productionSuppliers.data;
    const title = production ? `${production.name}` : 'Production suppliers';
    const isLoading = productionSuppliers.status === ListStatuses.Request;
    const total = _.get(productionSuppliers, 'meta.total', 0);
    const hasMoreSuppliers = total > _.get(productionSuppliers.data, 'length', 0);

    return (
      <div>
        {production && (
          <PageHeader title="Production suppliers">
            <Breadcrumbs
              path={[
                { title: 'Productions', link: Routes.Productions },
                { title: production.name, link: Routes.Production.replace(':id', `${params.id}`) },
              ]}
            />
          </PageHeader>
        )}

        <div css={style.filters}>
          <span style={{ marginRight: '10px' }}>Suppliers:</span>
          <MultiSelectInput
            items={suppliersForSelect.data || []}
            selected={supplierFilter}
            scheme={{ id: 'id', title: 'name' }}
            onChange={this.onSupplierFilterChange}
          />

          <span style={{ margin: '0 10px 0 20px' }}>{`${visual.labels.Functions}:`}</span>
          <MultiSelectInput
            items={supplierFunctions.data || []}
            selected={functionFilter}
            scheme={{ id: 'id', title: 'name' }}
            onChange={this.onFunctionFilterChange}
          />

          <span style={{ margin: '0 10px 0 20px' }}>Search by name:</span>
          <Input value={search} onChange={this.onSearchChange} />
          {test('supplier', 'C') && (
            <div css={style.rightBar}>
              <OutlineBtn title="Add supplier" onClick={this.openCreateModal} css={style.addBtn} />
            </div>
          )}
        </div>

        {suppliers && (
          <div css={gridBox}>
            <ToolBar title={title} />
            <div css={[style.row, style.header]}>
              <div css={style.cell('150px')}>Supplier</div>

              <div css={style.cell('150px')}>Agent</div>
              <div css={style.cell('150px')}>{visual.labels.Function}</div>
              <div css={style.cell('100px')}>Phone</div>
              <div css={style.cell('100px')}>Email</div>
              <div css={style.cell('150px')}>Producers</div>
              {test('production', ['U', 'D']) && <div css={style.cell('50px')}>Actions</div>}
            </div>
            {suppliers.map((productionSupplier: IProductionSupplier) => {
              const { id, supplier, agents, producers: supplierProducer } = productionSupplier;
              const contactProducers = supplierProducer.reduce((acc, item) => {
                if (item.producer && item.producer.name) {
                  return acc.length > 0 ? `${acc}, ${item.producer.name}` : `${item.producer.name}`;
                }
                return acc;
              }, '');
              return editSupplierId !== id ? (
                <div key={id} css={style.row} onDoubleClick={() => this.editRow(id)}>
                  {agents.map(({ agent, function: func }: ISupplierAgentLink, index) => {
                    return (
                      <ProductionSupplierRow
                        id={id}
                        supplier={supplier}
                        rowIndex={index}
                        onEdit={this.editRow}
                        onDelete={this.deleteRow}
                        key={`${id}-${index}`}
                      >
                        <div css={style.cell('150px')}>{agent.name || '-'}</div>
                        <div css={style.cell('150px')}>{func.name || '-'}</div>
                        <div css={style.cell('100px')}>{agent.phone || '-'}</div>
                        <div css={style.cell('100px')}>{agent.email || '-'}</div>
                        <div css={style.cell('150px')}>{contactProducers || '-'}</div>
                        {/* <div css={style.cell('150px')}>{'-'}</div> */}
                      </ProductionSupplierRow>
                    );
                  })}
                  {/* if no agents assigned */}
                  {agents.length === 0 && (
                    <ProductionSupplierRow
                      id={id}
                      supplier={supplier}
                      rowIndex={0}
                      onEdit={this.editRow}
                      onDelete={this.deleteRow}
                    >
                      <div css={style.cell('150px')}>-</div>
                      <div css={style.cell('150px')}>-</div>
                      <div css={style.cell('100px')}>-</div>
                      <div css={style.cell('100px')}>-</div>
                      <div css={style.cell('150px')}>{contactProducers || '-'}</div>
                    </ProductionSupplierRow>
                  )}
                </div>
              ) : (
                <ProductionSupplierEditRow
                  key={`${id}-edit`}
                  productionSupplier={productionSupplier}
                  production={production}
                  producers={producers}
                  supplierFunctions={supplier.functions}
                  updateSupplier={this.onUpdateSupplier}
                  cancelEdit={this.cancelEdit}
                />
              );
            })}

            <Footer>
              <Loader isLoading={isLoading} />
            </Footer>

            {hasMoreSuppliers && !isLoading && <Waypoint onEnter={this.onWaypointEnter} />}
          </div>
        )}

        <Modal isOpen={isCreateModalOpen}>
          <ProductionSupplierCreate
            suppliersForSelect={suppliersFiltered}
            supplierFunctions={supplierFunctions}
            onSubmit={this.onCreateSupplier}
            closeModal={this.closeCreateModal}
          />
        </Modal>
      </div>
    );
  }
}

const enhance = compose<IProps, IState>(
  withRouter,
  withVisualContext,
  connect(
    { production, producers, productionSuppliers, supplierFunctions, suppliersForSelect, suppliersFiltered },
    {
      getProductionReq,
      getProductionSuppliersReq,
      updateProductionSupplierReq,
      deleteProductionSupplierReq,
      getSupplierFunctionsReq,
      getSuppliersForSelectReq,
      getSuppliersFilteredReq,
      getProducersReq,
      createProductionSupplierReq,
    }
  )
);

export default enhance(withAcl(ProductionSuppliers));

export const style = {
  header: css`
    font-weight: 600;
    align-items: center;
    min-height: 55px;
    flex-direction: row;
  `,
  row: css`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${Colors.LightGreyMainBG};
    color: ${Colors.AltBlack};
    font-family: ${FontFamilies.Main};
    font-size: 13px;
    min-height: 50px;
    transition: padding 0.3s ease;
    padding: 0;

    &:nth-child(even) {
      background-color: #fbfbfb;
    }
  `,
  innerRow: css`
    display: flex;
    align-items: center;
    width: 100%;
  `,
  cell: (basis: string) => css`
    flex: 1 0 ${basis};
    padding: 10px 0px 10px 30px;
    overflow: hidden;
    text-overflow: ellipsis;
  `,

  cellSelect: (basis: string) => css`
    flex: 1 0 ${basis};
    padding: 10px 0px 10px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  link: css`
    color: inherit;
    text-decoration: none;
  `,
  filters: css`
    margin: 20px 0;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-family: ${FontFamilies.Main};
  `,
  filter: css`
    width: 200px;
  `,
  rightBar: css`
    margin-left: auto;
    display: flex;
    align-items: center;
  `,
  addBtn: css`
    flex-shrink: 0;
    margin-left: 20px;
  `,
};
