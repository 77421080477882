/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import * as styles from '../../../../../../styles/styles';
import arrowBlackTop from './images/arrowBlackTop.svg';
import arrowBlackBottom from './images/arrowBlackBottom.svg';
import arrowWhiteRight from './images/arrowWhiteRight.svg';

export const header = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 30px',
};

export const headerLeftSide = css({});

export const headerRighrSide = css({});

export const title = (isOpen: boolean) => {
  const bgPosition = isOpen ? 'center 60%, center 40%' : 'center 40%, center 60%';

  return [
    styles.H3,
    css({
      cursor: 'pointer',
      position: 'relative',
      paddingLeft: '30px',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '-22px',
        left: '-14px',
        width: '30px',
        height: '60px',
        backgroundImage: `url(${arrowBlackTop}), url(${arrowBlackBottom})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: bgPosition,
        transform: `scale(0.8)`,
      },
    }),
  ];
};

export const dayTableBox = css({
  borderTop: `1px solid ${styles.Colors.Grey}`,
});

export const startDateTimeCell = css({
  position: 'relative',
  display: 'inline-block',
  whiteSpace: 'nowrap',
});

export const startDateTimeMark = css({
  position: 'absolute',
  top: '-11px',
  right: '0px',
});

export const timelineBox = css({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-around',
  position: 'relative',
  minHeight: '40px',

  '& .timeline-cell': {
    flexGrow: 1,
    borderRight: `1px solid ${styles.Colors.LightGreyMainBG}`,
  },

  '& .timeline-cell:first-of-type': {
    borderLeft: `1px solid ${styles.Colors.LightGreyMainBG}`,
  },
});

export const timelineCell = css({
  padding: '0px !important',
  width: '40%',
  overflow: 'visible !important',
});

export const timelineValueBox = css({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  width: '100%',
});

export const timelineValueBoxResize = css({
  cursor: 'col-resize',
});

export const timelineValueIndent = (value: number) => {
  return css({
    width: `${value}%`,
  });
};

export const timelineValue = ({
  width,
  color = styles.Colors.DarkGrey,
  isSelected = false,
  isTransportType = false,
}: any) => {
  return css({
    minWidth: '4px',
    width: `${width}%`,
    height: '20px',
    margin: '10px 0',
    backgroundColor: isTransportType ? styles.Colors.White : color,
    position: 'relative',
    border: isSelected ? '1px solid black' : 0,
    // boxSizing: 'border-box',
  });
};

export const transportBar = ({ color }: any) => {
  return css({
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    border: `2px solid ${color}`,
    zIndex: 2,
  });
};

export const timelineValueSlected = css({
  border: '1px solid red',
});

export const resizeHandler = css({
  zIndex: 5,
  position: 'absolute',
  top: 0,
  height: '100%',
  width: '5px',
  cursor: 'col-resize',
});

export const editButtons = css({
  '& button': {
    marginLeft: '15px',
  },
});

export const moveHandler = css({
  cursor: 'move',
  width: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100%',
  zIndex: 1,
});

export const resizeHandlerLeft = [resizeHandler, css({})];

export const resizeHandlerRight = [
  resizeHandler,
  css({
    right: 0,
  }),
];

export const linkPosition = css({
  left: '50%',
  top: '50%',
  position: 'absolute',
  zIndex: 1,
});

export const intersection = (color: any) =>
  css({
    content: '">>"',
    color: color,
    fontWeight: 600,
    fontSize: '19px',
    lineHeight: '20px',
    position: 'absolute',
    textAlign: 'center',
    transform: 'scaleX(0.7) scaleY(1.2)',
    top: '1px',
    maxWidth: '20px',
    maxHeight: '20px',
    //backgroundImage: `url(${arrowWhiteRight}), url(${arrowWhiteRight})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '37% center, 63% center',
  });

export const intersectionBefore = (color: any) =>
  css({
    '&::before': [
      intersection(color),
      css({
        top: '-1px',
        left: '1px',
        transform: `scaleX(0.7) scaleY(1.2) rotate(180deg)`,
      }),
    ],
  });

export const intersectionAfter = (color: any) =>
  css({
    '&::after': [
      intersection(color),
      css({
        top: '2px',
        right: '2px',
      }),
    ],
  });

//HEADER

export const timelineHeaderBox = [
  styles.TimelineText,
  css({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  }),
];

export const timelineHeaderScaleItemBox = css({
  flexGrow: 1,
  position: 'relative',
});

export const timelineHeaderScaleItemTitle = css({
  position: 'absolute',
  left: '-13px',
  top: '15px',
});

export const timelineHeaderScaleEndItemBox = [
  timelineHeaderScaleItemBox,
  css({
    flexGrow: 0,
  }),
];

export const transportAdditionalInfoBox = (margin: number) =>
  css({
    padding: `10px 10px 10px ${margin}%`,
    backgroundColor: styles.Colors.LightGreyMainBG,
    width: '100%',
    boxSizing: 'border-box',
  });

export const transportRow = [
  styles.MainText,
  css({
    display: 'flex',
    width: '400px',
    lineHeight: '25px',
  }),
];

export const transportTitle = css({
  width: '50%',
});

export const transportValue = css({
  width: '50%',
});

export const day = css({
  scrollMargin: '100px',
});
export const gateRow = [
  transportRow,
  css({
    color: styles.Colors.RegularBlack,
  }),
];

export const linkIn = [
  css({
    '& a': [
      styles.Link,
      css({
        whiteSpace: 'normal',
      }),
    ],
  }),
];

export const xxx = css({});
