/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import { compose, lifecycle } from 'recompose';
import { withFormik, FormikProps } from 'formik';
import * as yup from 'yup';
// redux
import { connect, STATUS_CREATED } from '../../../../utils/redux';
import { createSupplierReq, createSupplierStart } from './store/actions';
import { supplier } from './store/selectors';
import { supplierFunctions } from '../SupplierFunctions/store/selectors';
import { getSupplierFunctionsReq, refreshSupplierFunctions } from '../SupplierFunctions/store/actions';
// types
import { Action } from '../../../../store/types';
import { SupplierFunctionListState } from '../SupplierFunctions/store/types';
import { SupplierItemState } from './store/types';
// components
import { Link, Redirect } from 'react-router-dom';
import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../common/buttons/OutlineBtn';
import form from '../../../common/form';
import page from '../../../common/page';
import routes from '../../../../routes';
import MultiSelectArea from '../../../common/form/multiselect/MultiSelectArea';
import AsyncData from '../../../common/async/AsyncData';
import Info from '../../../common/info/Info';
import AsyncDataCheckStatus from '../../../common/async/AsyncDataCheckStatus';
// other
import * as validatinShemas from '../../../../utils/validatinShemas';
import { Colors } from '../../../../styles/styles';
import withVisualContext from '../../../../contexts/withVisualContext';
import { IVisualContext } from '../../../../contexts/VisualContext';

const { FormRow, FormField, FormError, FormBlock, FieldsSet, ActionsRow, Form, FieldsArea } = form;
const { Page, Breadcrumbs, PageHeader } = page;

interface FormValues {
  name: string;
  functions: number[];
  agentName: string;
  email: string;
  phone: string;
}

interface OtherProps {
  createSupplierStart: Action<{}>;
  createSupplierReq: Action<{}>;
  getSupplierFunctionsReq: Action<{}>;
  refreshSupplierFunctions: Action<{}>;
  supplierFunctions: SupplierFunctionListState;
  supplier: SupplierItemState;
  visual: IVisualContext;
}

interface FormProps {
  initialEmail?: string;
}

jsx;
const withLifecycle = lifecycle<OtherProps, {}>({
  componentDidMount() {
    const { createSupplierStart, refreshSupplierFunctions } = this.props;
    createSupplierStart();
    refreshSupplierFunctions();
    window.history.pushState({}, 'Suppliers', routes.Suppliers);
  },
});

const CreateForm = ({
  errors,
  touched,
  values,
  setFieldValue,
  supplierFunctions,
  getSupplierFunctionsReq,
  visual,
  supplier,
}: OtherProps & FormikProps<FormValues>) => {
  const createPage = (
    <Fragment>
      <AsyncDataCheckStatus asyncData={[supplier]} statuses={STATUS_CREATED}>
        <Info>Supplier successfully created</Info>
        <Redirect to={routes.Suppliers} />
      </AsyncDataCheckStatus>

      <PageHeader title="Create supplier">
        <Breadcrumbs
          path={[
            { title: 'Suppliers', link: routes.Suppliers },
            { title: 'Create supplier', link: routes.SuppliersCreate },
          ]}
        />
      </PageHeader>
      <Page>
        <AsyncData
          data={[{ asyncData: supplier }, { asyncData: supplierFunctions, asyncGetAction: getSupplierFunctionsReq }]}
        >
          {() => {
            return (
              <Form>
                <div>
                  <FieldsArea>
                    <FormBlock>
                      <FieldsSet>
                        <FormRow>
                          <label htmlFor="name">Name</label>
                          <FormField name="name" placeholder="name" />
                          <FormError name="name" errors={errors} touched={touched} />
                        </FormRow>

                        <FormRow>
                          <label htmlFor="functions">{visual.labels.Functions}</label>
                          <MultiSelectArea
                            items={supplierFunctions.data}
                            selected={values.functions}
                            scheme={{ id: 'id', title: 'name' }}
                            onChange={(values) => {
                              setFieldValue('functions', values);
                            }}
                          />
                          <FormError name="functions" errors={errors} touched={touched} />
                        </FormRow>
                      </FieldsSet>

                      <FieldsSet>
                        <FormRow>
                          <label htmlFor="agentName">Agent name</label>
                          <FormField name="agentName" placeholder="agent name" />
                          <FormError name="agentName" errors={errors} touched={touched} />
                        </FormRow>

                        <FormRow>
                          <label htmlFor="email">Email</label>
                          <FormField name="email" placeholder="email" />
                          <FormError name="email" errors={errors} touched={touched} />
                        </FormRow>

                        <FormRow>
                          <label htmlFor="phone">Phone</label>
                          <FormField name="phone" placeholder="+1234567890" />
                          <FormError name="phone" errors={errors} touched={touched} />
                          <p css={style.description}>
                            Please add phone number in international format. Use only '+' and numbers.
                          </p>
                        </FormRow>
                      </FieldsSet>
                    </FormBlock>
                  </FieldsArea>

                  <ActionsRow>
                    <Link to={routes.Suppliers}>
                      <OutlineBtn title="Cancel" isWide={false} />
                    </Link>
                    <PrimaryBtn title="Create supplier" isWide={false} />
                  </ActionsRow>
                </div>
              </Form>
            );
          }}
        </AsyncData>
      </Page>
    </Fragment>
  );

  return createPage;
};

export const exchangeFormic = withFormik<FormProps & OtherProps, FormValues>({
  mapPropsToValues: () => {
    return {
      name: '',
      functions: [],
      agentName: '',
      email: '',
      phone: '',
    };
  },

  validationSchema: yup.object({}).shape({
    name: validatinShemas.vNameReq,
    functions: validatinShemas.vIdListReq,
    agentName: validatinShemas.vNameReq,
    email: validatinShemas.vEmailReq,
    phone: validatinShemas.vPhoneReq,
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.createSupplierReq(values);
  },
  displayName: 'EditForm',
});

export default compose<OtherProps & FormikProps<FormValues>, {}>(
  connect(
    { supplier, supplierFunctions },
    { createSupplierReq, createSupplierStart, getSupplierFunctionsReq, refreshSupplierFunctions }
  ),
  withLifecycle,
  exchangeFormic,
  withVisualContext
)(CreateForm);

const style = {
  description: css`
    font-size: 12px;
    color: ${Colors.LightBlack};
    margin: 5px;
  `,
};
