/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { withFormik, Form, FormikProps } from 'formik';
import { Component, createRef } from 'react';
// redux
import { compose } from 'recompose';
import { connect } from '../../../../../utils/redux';
import { suppliersForSelect } from '../../Suppliers/store/selectors';
import { getSuppliersForSelectReq } from '../../Suppliers/store/actions';
import { SupplierListState } from '../../Suppliers/store/types';
import { supplierFunctions } from '../../SupplierFunctions/store/selectors';
import { supplierFunctionsLoadRequest } from '../../SupplierFunctions/store/actions';
// types
import { IProduction } from '../store/types';
import { Action } from '../../../../../store/types';
// components
import AsyncData from '../../../../common/async/AsyncData';
import modal from '../../../../common/modal';
import FormRow from '../../../../common/form/FormRow';
import FormError from '../../../../common/form/FormError';
import Text from '../../../../common/form/Text';
import PrimaryBtn from '../../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../../common/buttons/OutlineForwarded';
import OutlineGreen from '../../../../common/buttons/OutlineGreen';
import ReactSelect from '../../../../common/react-select/Select';
import MultiselectModal from '../../../../common/form/multiselect/MultiselectModal';
import MultiSelectInputWithTabs from '../../../../common/form/selectWithTabs/MultiSelectInputWithTabs';
import CreateSupplier from './DateList/CreateSupplier';
import { ISupplierFormValues } from '../../../../common/form/selectWithTabs/CreateSupplierForm';
// utils
import _ from 'lodash';
import * as yup from 'yup';
import { SupplierFunctionListState } from '../../SupplierFunctions/store/types';

import LABELS from '../../../../../constants';
const { Modal, ModalHeader, ModalFooter, ModalBody, ModalActions } = modal;

interface FormValues {
  suppliers: number[];
  functions: object[];
  label: string;
}

interface OtherProps {
  isOpen: boolean;
  onClose: () => void;
  production: IProduction;
  activities: Number[];
  selectedLabels: any;
  activitiesUpdate: Action<{}>;
  hasPermissionCreateSupplier: boolean;
  suppliersForSelect?: SupplierListState;
  getSuppliersForSelectReq?: Action<{}>;
  supplierFunctions?: SupplierFunctionListState;
  supplierFunctionsLoadRequest?: Action<{}>;
  updateSupplier?: Action<{}>;
  createSupplierFunction: Action<{}>;
}
interface IState {
  isConfirmation: boolean;
  suppliersChanged: boolean;
  functionChanged: boolean;
  labelChanged: boolean;
}
jsx;
class ActivitiesUpdateModal extends Component<OtherProps & FormikProps<FormValues>, IState> {
  private cancelRef: any;
  constructor(props: OtherProps & FormikProps<FormValues>) {
    super(props);

    this.state = {
      isConfirmation: false,
      suppliersChanged: false,
      functionChanged: false,
      labelChanged: false,
    };
    this.cancelRef = createRef();
  }

  onSuppliersMultiChange = (values: any) => {
    const lastValue = _.last(values);
    const isAll = lastValue === 0;
    values = isAll ? [0] : values;
    this.props.setFieldValue('suppliers', values);
    this.setState({ suppliersChanged: true });
  };

  onFunctionsChange = (functions: any) => {
    const lastFunctions = _.last(functions);
    functions = _.get(lastFunctions, 'value') === 0 ? [lastFunctions] : functions.filter((func: any) => !!func.value);
    this.props.setFieldValue('functions', functions);
    this.setState({ functionChanged: true });
  };
  onLabelChange = (e: any) => {
    const label = _.get(e, 'currentTarget.value');
    this.props.setFieldValue('label', label);
    this.setState({ labelChanged: true });
  };
  handleCreateSupplier = (values: ISupplierFormValues) => {
    const { createSupplierFunction } = this.props;
    createSupplierFunction({
      data: { ...values },
    });
  };
  onCancelClick = () => {
    this.props.resetForm();
    this.props.onClose();
    this.setState({
      isConfirmation: false,
      suppliersChanged: false,
      functionChanged: false,
      labelChanged: false,
    });
  };
  onConfirmationClick = () => {
    this.props.submitForm();
    this.setState({
      isConfirmation: false,
    });
  };
  showConfirmationModal = () => {
    this.setState({ isConfirmation: true });
    this.cancelRef && this.cancelRef.current && this.cancelRef.current.focus();
  };
  addConjunction = (str: string) =>
    str
      .trim()
      .split(' ')
      .join(' and ');

  render() {
    const {
      isOpen,
      activities,
      suppliersForSelect,
      getSuppliersForSelectReq,
      supplierFunctions,
      supplierFunctionsLoadRequest,
      errors,
      touched,
      values,
      hasPermissionCreateSupplier,
      updateSupplier,
    } = this.props;
    const { isConfirmation, functionChanged, suppliersChanged, labelChanged } = this.state;
    const subjectForUpdate = `${functionChanged ? `${LABELS.function} ` : ''}${suppliersChanged ? 'supplier ' : ''}${
      labelChanged ? 'label ' : ''
    }`;

    const message = `Are you sure you want to update ${this.addConjunction(
      subjectForUpdate
    )} for all selected activities?`;
    return (
      <div>
        <Modal isOpen={isOpen}>
          <Form css={style.modal}>
            <ModalHeader>{`Update activities (${activities.length})`}</ModalHeader>
            {!isConfirmation ? (
              <AsyncData
                data={[
                  { asyncData: suppliersForSelect, asyncGetAction: getSuppliersForSelectReq },
                  { asyncData: supplierFunctions, asyncGetAction: supplierFunctionsLoadRequest },
                ]}
              >
                {() => {
                  const optionsMapper = (option: any) => ({ value: option.id, label: option.name });
                  const optionsSupplierMapper = (option: any) => ({
                    id: option.id,
                    name: option.name,
                  });

                  const suppliersForUpdate = [
                    { id: 0, name: 'All production suppliers' },
                    ..._.map(_.get(suppliersForSelect, 'data', []), optionsSupplierMapper),
                  ];

                  const functionsList = [
                    { value: 0, label: `All production ${LABELS.functions}` },
                    ..._.map(_.get(supplierFunctions, 'data', []), optionsMapper),
                  ];

                  const functionsForCreatingSupplier = [
                    {
                      id: 0,
                      name: `All production ${LABELS.functions}`,
                    },
                    ..._.map(_.get(supplierFunctions, 'data', []), optionsSupplierMapper),
                  ];

                  return (
                    <ModalBody>
                      <FormRow>
                        <label>Update suppliers</label>
                        {hasPermissionCreateSupplier ? (
                          <MultiSelectInputWithTabs
                            noSelectedMsg="Not selected"
                            items={suppliersForUpdate}
                            selected={values.suppliers}
                            onChange={this.onSuppliersMultiChange}
                            scheme={{ id: 'id', title: 'name' }}
                            selectAllBtn={false}
                            shoudOpenOnFocus
                            elementToCreate={({ backToSelect, onCreateAndSelect }) => (
                              <CreateSupplier
                                onSave={this.handleCreateSupplier}
                                allSuppliers={suppliersForUpdate}
                                supplierFunctions={functionsForCreatingSupplier}
                                backToSelect={backToSelect}
                                onCreateAndSelect={onCreateAndSelect}
                                updateSupplier={updateSupplier}
                              />
                            )}
                          />
                        ) : (
                          <MultiselectModal
                            noSelectedMsg="Not selected"
                            items={suppliersForUpdate}
                            selected={values.suppliers}
                            onChange={this.onSuppliersMultiChange}
                            scheme={{ id: 'id', title: 'name' }}
                            selectAllBtn={false}
                            shoudOpenOnFocus
                          />
                        )}
                        <FormError name="suppliers" errors={errors} touched={touched} />
                      </FormRow>
                      <FormRow>
                        <label>Update functions</label>
                        <ReactSelect
                          name="functions"
                          options={functionsList}
                          onChange={this.onFunctionsChange}
                          value={values.functions}
                          isClearable={false}
                          isMulti
                          openMenuOnFocus
                          menuPlacement="bottom"
                          menuPosition="fixed"
                          menuPortalTarget={document.body}
                        />
                        <FormError name="functions" errors={errors} touched={touched} />
                      </FormRow>
                      <FormRow>
                        <label>Update labels</label>
                        <Text
                          name="labels"
                          value={values.label}
                          onChange={(e: any) => {
                            this.onLabelChange(e);
                          }}
                          style={{ borderRadius: '4px' }}
                          onBlur={() => {
                            this.cancelRef && this.cancelRef.current && this.cancelRef.current.focus();
                          }}
                        />
                        <FormError name="label" errors={errors} touched={touched} />
                      </FormRow>
                    </ModalBody>
                  );
                }}
              </AsyncData>
            ) : (
              <ModalBody>
                <div css={style.confirmationBox}>
                  <p css={style.message}>{message}</p>
                </div>
              </ModalBody>
            )}

            <ModalFooter>
              <ModalActions css={style.modalActions}>
                <OutlineBtn
                  type="button"
                  onClick={this.onCancelClick}
                  title="Cancel"
                  tabIndex={0}
                  ref={this.cancelRef}
                />
                {isConfirmation ? (
                  <PrimaryBtn
                    type="submit"
                    title="Update"
                    style={{ marginRight: 0 }}
                    onClick={this.onConfirmationClick}
                    tabIndex={0}
                  />
                ) : (
                  <OutlineGreen type="button" title="Update" onClick={this.showConfirmationModal} tabIndex={0} />
                )}
              </ModalActions>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}

const validationSchema = yup.object({}).shape({
  suppliers: yup.array(),
  functions: yup.array(),
  label: yup.string(),
});

export default compose<OtherProps & FormikProps<FormValues>, OtherProps>(
  withFormik<OtherProps, FormValues>({
    mapPropsToValues: () => ({
      suppliers: [],
      functions: [],
      label: '',
    }),
    validationSchema,
    handleSubmit: ({ functions = [], suppliers = [], label }, { props, resetForm }) => {
      const { activitiesUpdate, production, activities, onClose } = props;

      const getValue = _.partial(_.get, _, 'value');
      const getLabel = _.partial(_.get, _, 'label');
      const allFunctions = !!_.find(functions, { value: 0 });
      const allSuppliers = suppliers.includes(0);
      suppliers = allSuppliers ? [] : suppliers;
      functions = allFunctions ? [] : functions.map(getValue);
      const newLabel = !!label ? label : getLabel(label);

      activitiesUpdate({
        id: production.id,
        data: {
          activities,
          suppliers,
          functions,
          allSuppliers,
          allFunctions,
          name: newLabel,
        },
      });
      onClose();
      resetForm();
    },
    displayName: 'ActivitiesUpdateModal',
  }),
  connect(
    { suppliersForSelect, supplierFunctions },
    { getSuppliersForSelectReq, supplierFunctionsLoadRequest }
  )
)(ActivitiesUpdateModal);

const style = {
  modal: css`
    width: 500px;
  `,
  modalActions: css`
    text-align: right;
  `,
  message: css`
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.38;
    text-align: center;
  `,
  confirmationBox: css`
    padding: 20px;
  `,
};
