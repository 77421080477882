/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, ReactNode } from 'react';
import * as styles from './styles';

export interface IProps {
  title: string | ReactNode;
}

jsx;

export const WidgetKeyValueRow: FunctionComponent<IProps> = ({ children, title }): ReactElement<IProps> => {
  return (
    <div css={styles.widgetKeyValueRow}>
      <div>{title}</div>
      <div>{children}</div>
    </div>
  );
};

export default WidgetKeyValueRow;
