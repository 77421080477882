/** @jsx jsx */
import { Fragment, FunctionComponent, ReactElement } from 'react';
import { jsx } from '@emotion/core';

import { Route, Switch, Redirect } from 'react-router';
import routes from '../../../../routes';

import List from './List';
import Create from './Create';
import Edit from './Edit';
import Agents from './Agents/Agents';
import withAcl, { IWrappedProps } from '../withAcl';

interface IProps extends IWrappedProps {}

jsx;
const Suppliers: FunctionComponent<IProps> = ({ test }): ReactElement<IProps> => {
  return (
    <Fragment>
      <Switch>
        <Route exact path={routes.Suppliers} component={List} />
        {test('supplier', 'C') && <Route exact path={routes.SuppliersCreate} component={Create} />}
        {test('supplier', 'U') && <Route exact path={routes.SuppliersEdit} component={Edit} />}
        <Route path={routes.SupplierAgents} component={(props: any) => <Agents {...props} />} />
        {/*<Route component={() => <Redirect to={routes.Suppliers} />} />*/}
      </Switch>
    </Fragment>
  );
};

export default withAcl(Suppliers);
