/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import * as styles from './styles';

export interface IProps {
  className?: string;
}

jsx;
export const Footer: FunctionComponent<IProps> = ({ children, className }): ReactElement<IProps> => {
  return (
    <div css={styles.footer} className={className}>
      {children}
    </div>
  );
};

export default Footer;
