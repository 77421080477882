/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactElement, FunctionComponent } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Box from './Box';
import InvitationForm from './InvitationForm';
import OutlineBtn from '../../common/buttons/OutlineBtn';
import { connect } from '../../../utils/redux';

import { invinantionReq } from '../../../store/actions';
import { Action } from '../../../store/types';

import routes from '../../../routes';

import { H1 } from '../../../styles/styles';
import * as styles from './styles';
import { getSearchParam } from '../../../utils/url';
import { withProps } from 'recompose';
import { Base64 } from 'js-base64';
import { IContext } from './InvitationForm';

interface IProps {}

jsx;
const update: Partial<IProps> = {
  context: getSearchParam('context'),
};

export const withHash = withProps(
  (props: IProps): IProps => {
    return { ...props, ...update };
  }
);

interface IProps {
  context: string | null;
  invinantionReq: Action<{}>;
}

const Login: FunctionComponent<IProps> = ({ context, invinantionReq }): ReactElement<IProps> => {
  let form, message;
  if (context) {
    const contextParse: IContext = JSON.parse(Base64.decode(context));
    form = <InvitationForm context={contextParse as IContext} invinantionReq={invinantionReq} />;
    message = `Hi ${contextParse.name}, please complete your registration.`;
  } else {
    form = <Redirect to={routes.Login} />;
    message = '';
  }

  return (
    <Box>
      <section css={styles.ContentSection}>
        <h1 css={H1}>Sign up with invitation</h1>
        <div css={styles.Description}>{message}</div>
        {form}
        <div>
          <Link to={routes.Login}>
            <OutlineBtn isWide={true} title="Back to login" />
          </Link>
        </div>
      </section>
    </Box>
  );
};

export default connect(
  {},
  { invinantionReq }
)(withHash(Login));
