import { put, call, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as actions from './actions';
// utils
import _ from 'lodash';
import { getResponseMessage } from '../../../../../utils/response';
// other
import { toast } from 'react-toastify';
import routes from '../../../../../routes';

export default function* watchReducersAsync() {
  yield takeEvery(actions.GET_CLIENTS_REQ, getClientsAsync);
  yield takeEvery(actions.GET_CLIENTS_FOR_SELECT_REQ, getClientForSelectAsync);
  yield takeEvery(actions.CREATE_CLIENT_REQ, createClientAsync);
  yield takeEvery(actions.CREATE_CLIENT_REQ_IN_TAB, createClientInTabAsync);
  yield takeEvery(actions.UPDATE_CLIENT_REQ, updateClientAsync);
  yield takeEvery(actions.DELETE_CLIENT_REQ, deleteClientAsync);
  yield takeEvery(actions.GET_CLIENT_REQ, getClientAsync);
}

// GET LIST
export function* getClientsAsync({ api, payload }: any) {
  try {
    const path = '/clients';
    const params = { page: 0, size: 20, ...payload };
    const response = yield call(api.get, path, { params });
    const { data, meta } = response.data;
    yield put(actions.getClientsReqSuccess({ data, params }, meta));
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

// GET LIST FOR SELECT
export function* getClientForSelectAsync({ api, payload }: any) {
  try {
    const path = '/clients';
    const response = yield call(api.get, path, { params: { size: 1000, ...payload } });
    yield put(actions.getClientsForSelectReqSuccess());
    yield put(actions.setClientForSelect(response.data));
  } catch (e) {
    yield put(actions.getClientsForSelectReqError(e));
  }
}

// CREATE ITEM
export function* createClientAsync({ api, payload }: any) {
  try {
    yield call(api.post, '/clients', payload);
    yield put(push(routes.Clients));
    //yield call([toast, 'success'], 'Client successfully created!');
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* createClientInTabAsync({ api, payload }: any) {
  try {
    const response = yield call(api.post, '/clients', payload);
    yield put(actions.addClient(response.data.data));
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

// GET ITEM
export function* getClientAsync({ api, payload }: any) {
  try {
    const path = `/clients/${payload}`;
    const response = yield call(api.get, path);
    yield put(actions.getClientReqSuccess({}));
    yield put(actions.setClient(response.data.data));
  } catch (e) {}
}

// UPDATE ITEM
export function* updateClientAsync({ api, payload }: any) {
  const data = payload;
  try {
    const path = `/clients/${data.id}`;
    const response = yield call(api.post, path, data);
    yield put(actions.getClientReqSuccess({}));
    yield put(actions.setClient(response.data.data));
    yield put(push(routes.Clients));
    //yield call([toast, 'success'], 'Client successfully updated!');
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

// DELETE ITEM
export function* deleteClientAsync({ api, payload }: any) {
  try {
    const path = `/clients/${payload}`;
    yield call(api.delete, path);
    yield put(actions.getClientsReq({}));
    //yield call([toast, 'success'], 'Client successfully deleted!');
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
