import _ from 'lodash';

import * as func from './func';

export const selectBy = (items, filter) => {
  return items.filter((item) => {
    return Object.entries(filter).every(([key, value]) => {
      return func.isSimilarValues(value, _.get(item, key));
    });
  });
};

export const selectOneBy = (items, filter) => {
  const result = items.reduce((res, item) => {
    if (!res) {
      res = Object.entries(filter).every(([key, value]) => {
        return func.isSimilarValues(value, _.get(item, key));
      })
        ? item
        : res;
    }
    return res;
  }, null);
  return result;
};

export const excludeBy = (items, filter) => {
  return items.filter((item) => {
    return !Object.entries(filter).every(([key, value]) => {
      return func.isSimilarValues(value, _.get(item, key));
    });
  });
};

export const extractKeyValue = (items, key) => {
  return items.map((item) => _.get(item, key));
};

export const sortByArrayValues = (items, key, values) => {
  return values.map((value) => {
    return _.find(items, { [key]: value });
  });
};
