/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withFormik, Form, FormikProps } from 'formik';
import * as yup from 'yup';
import { Colors } from '../../../styles/styles';

import { Action } from '../../../store/types';

import PrimaryBtn from '../../common/buttons/PrimaryBtn';
import FormRow from '../../common/form/FormRow';
import FormField from '../../common/form/FormField';
import FormError from '../../common/form/FormError';

import { IVisualContext } from '../../../contexts/VisualContext';
import withVisualContext from '../../../contexts/withVisualContext';

import * as styles from './styles';
import * as validatinShemas from '../../../utils/validatinShemas';

interface FormValues {
  email: string;
  password: string;
}

interface OtherProps {
  loginRequest: Action<{}>;
  visual?: IVisualContext;
}

jsx;

const LoginForm = ({ errors, touched, visual }: OtherProps & FormikProps<FormValues>) => {
  const labelTextColor = visual && visual.isCopy ? Colors.White : Colors.RegularBlack;
  const buttonBorder = visual && visual.isCopy ? `1px solid ${Colors.White}` : '0px solid transparent';
  return (
    <div css={styles.formBox}>
      <Form>
        <FormRow>
          <label htmlFor="email" style={{ color: labelTextColor }}>
            Email
          </label>
          <FormField id="email" name="email" placeholder="email" />
          <FormError name="email" errors={errors} touched={touched} />
        </FormRow>
        <FormRow>
          <label htmlFor="password" style={{ color: labelTextColor }}>
            Password
          </label>
          <FormField id="password" name="password" type="password" placeholder="password" />
          <FormError name="password" errors={errors} touched={touched} />
        </FormRow>
        <FormRow>
          <PrimaryBtn id="submit" title="Login" isWide={true} style={{ border: buttonBorder }} />
        </FormRow>
      </Form>
    </div>
  );
};

interface FormProps {
  initialEmail?: string;
}

const loginSchema: any = yup.object({}).shape({
  email: validatinShemas.vEmailReq,
  password: yup.string().required(),
});

export const exchangeFormic = withFormik<FormProps & OtherProps, FormValues>({
  mapPropsToValues: (props) => {
    return {
      email: props.initialEmail || '',
      password: '',
    };
  },

  validationSchema: loginSchema,

  handleSubmit: (values, { props, setSubmitting }) => {
    props.loginRequest(values);
  },
  displayName: 'LoginForm',
});

export default exchangeFormic(withVisualContext(LoginForm));
