import si, { ImmutableObject } from 'seamless-immutable';
import _ from 'lodash';
import * as ru from '../../../../../utils/redux';
import { IClient } from './types';

import * as actions from './actions';
import * as r from '../../../../../utils/reducers';
import { getListState } from '../../../../../utils/store/state/state';
import { ClientListState } from './types';

export interface IListParams {
  page?: number;
  size?: number;
  search?: string;
  searchKey?: string;
}

export interface IMeta {
  total?: number;
}

interface IState {
  listForSelect: ClientListState;
  // LIST
  list: IClient[];
  listLoading: boolean;
  listError: string;
  total: number;
  meta: IMeta;
  params: IListParams;
  // ITEM
  item: IClient | null;
  itemLoading: boolean;
  itemError: string;
}

const INITIAL_STATE: ImmutableObject<IState> = si({
  listForSelect: getListState({
    page: 0,
    size: 1000,
  }),
  // LIST
  list: [],
  listLoading: false,
  listError: '',
  meta: {
    total: 0,
  },
  total: 0,
  params: {
    page: 0,
    size: 20,
  },
  // ITEM
  item: null,
  itemLoading: false,
  itemError: '',
});

const listForSelectReducer = r.createReducer('listForSelect');

export default ru.processActions(
  () => ({
    //SELECT LIST
    [actions.GET_CLIENTS_FOR_SELECT_REQ]: listForSelectReducer(r.loadingReducer),
    [actions.GET_CLIENTS_FOR_SELECT_REQ_SUCCESS]: listForSelectReducer(r.successReducer),
    [actions.GET_CLIENTS_FOR_SELECT_REQ_ERROR]: listForSelectReducer(r.errorReducer),
    [actions.SET_CLIENTS_FOR_SELECT]: listForSelectReducer(r.listDataReducer),
    [actions.REFRESH_CLIENTS_FOR_SELECT]: listForSelectReducer(r.refreshReducer),

    //DEPRECATED
    [actions.GET_CLIENTS_REQ]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('listLoading', true);
    },

    [actions.GET_CLIENTS_REQ_SUCCESS]: (store: ImmutableObject<IState>, action: any) => {
      const {
        payload: { data, params },
        meta,
      } = action;
      const page = params.page || 0;
      return store
        .set('list', page > 0 ? store.list.concat(data) : data)
        .set('listLoading', false)
        .set('params', params)
        .set('meta', meta);
    },
    [actions.GET_CLIENTS_REQ_ERROR]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('listLoading', false).set('listError', action.payload);
    },
    [actions.SET_CLIENTS]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('list', action.payload.data).set('total', action.payload.meta.total);
    },
    [actions.ADD_CLIENT]: (store: ImmutableObject<IState>, action: any) => {
      return store
        .updateIn(['listForSelect', 'data'], (list) => list.concat(action.payload))
        .set('total', store.total + 1);
    },
    [actions.SET_CLIENT]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('item', _.head(action.payload));
    },
    [actions.CLEAR_CLIENT]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('item', null);
    },
    [actions.UPDATE_CLIENT]: (store: ImmutableObject<IState>, action: any) => {
      return store.set('item', _.head(action.payload));
    },
  }),
  INITIAL_STATE
);
