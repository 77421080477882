import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
// actions
import * as actions from './actions';
import {
  SUPPLIER_FUNCTIONS_LOAD_REQUEST,
  supplierFunctionsLoadRequestError,
  supplierFunctionsLoadRequestSuccess,
  SUPPLIER_FUNCTIONS_ADD_REQUEST,
  supplierFunctionsAddRequestSuccess,
  supplierFunctionsAddRequestError,
  SUPPLIER_FUNCTIONS_DELETE_REQUEST,
  supplierFunctionsDelete,
  supplierFunctionsDeleteRequestError,
  supplierFunctionsDeleteRequestSuccess,
  SUPPLIER_FUNCTIONS_UPDATE_REQUEST,
  supplierFunctionsUpdate,
  supplierFunctionsUpdateRequestError,
  supplierFunctionsUpdateRequestSuccess,
} from './actions';
// utils
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getResponseMessage } from '../../../../../utils/response';

export default function* watchMainAsync() {
  yield takeLatest(actions.GET_SUPPLIER_FUNCTIONS_REQ, getSupplierFunctionsAsync);
  yield takeEvery(actions.CREATE_SUPPLIER_FUNCTION_REQ, createSupplierFunctionAsync);
  yield takeEvery(actions.UPDATE_SUPPLIER_FUNCTION_REQ, updateSupplierFunctionAsync);
  yield takeEvery(actions.DELETE_SUPPLIER_FUNCTION_REQ, deleteSupplierFunctionAsync);

  yield takeEvery(SUPPLIER_FUNCTIONS_LOAD_REQUEST, supplierFunctionsLoadRequest);
  yield takeEvery(SUPPLIER_FUNCTIONS_ADD_REQUEST, supplierFunctionsAddRequest);
  yield takeEvery(SUPPLIER_FUNCTIONS_DELETE_REQUEST, supplierFunctionsDeleteRequest);
  yield takeEvery(SUPPLIER_FUNCTIONS_UPDATE_REQUEST, supplierFunctionsUpdateRequest);
}

export function* createSupplierFunctionAsync({ api, payload }: any) {
  try {
    const response = yield call(api.post, '/functions', payload);
    yield put(actions.createSupplierFunctionReqSuccess({}));
    yield put(actions.createSupplier(response.data));
    yield put(actions.refreshSupplierFunctions({}));
    //yield call([toast, 'success'], 'Supplier function successfully created!');
  } catch (err) {
    yield put(actions.createSupplierFunctionReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* getSupplierFunctionsAsync({ api, payload }: any) {
  try {
    const path = '/functions';
    const response = yield call(api.get, path, { params: payload });
    yield put(actions.getSupplierFunctionsReqSuccess(response.data));
    yield put(actions.setSupplierFunctions(response.data));
  } catch (e) {
    yield put(actions.getSupplierFunctionReqError(e));
  }
}

export function* updateSupplierFunctionAsync({ api, payload }: any) {
  const data = payload;
  try {
    const path = `/functions/${data.id}`;
    const response = yield call(api.post, path, data);
    yield put(actions.updateSupplierFunctionReqSuccess({}));
    yield put(actions.setSupplier(response.data));
  } catch (e) {
    yield put(actions.updateSupplierFunctionReqError(e));
  }
}

export function* deleteSupplierFunctionAsync({ api, payload }: any) {
  try {
    const path = `/functions/${payload}`;
    yield call(api.delete, path);
    yield put(actions.updateSupplierFunctionReqSuccess({}));
    yield put(actions.refreshSupplierFunctions({}));
  } catch (e) {
    yield put(actions.updateSupplierFunctionReqError({}));
  }
}

// DEPRECATED

export function* supplierFunctionsLoadRequest({ api, payload }: any) {
  try {
    const params = { page: 0, size: 20, ...payload };
    const response = yield call(api.get, `/functions`, { params });
    yield put(supplierFunctionsLoadRequestSuccess({ data: response.data.data, params }, response.data.meta));
  } catch (err) {
    put(supplierFunctionsLoadRequestError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* supplierFunctionsAddRequest({ api, payload: { values } }: any) {
  try {
    const response = yield call(api.post, `/functions`, values);
    const [entity] = response.data.data;
    yield put(supplierFunctionsAddRequestSuccess(entity));
    // refetch all functions list to drop new function on it's sorted place
    yield put(actions.supplierFunctionsLoadRequest());
    //yield call([toast, 'success'], 'Supplier function successfully created!');
  } catch (err) {
    put(supplierFunctionsAddRequestError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* supplierFunctionsUpdateRequest({ api, payload: { values, item } }: any) {
  try {
    const response = yield call(api.post, `/functions/${item.id}`, values);
    const [entity] = response.data.data;
    yield put(supplierFunctionsUpdateRequestSuccess(entity));
    yield put(supplierFunctionsUpdate(entity));
    //yield call([toast, 'success'], 'Supplier function successfully updated!');
  } catch (err) {
    put(supplierFunctionsUpdateRequestError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* supplierFunctionsDeleteRequest({ api, payload }: any) {
  try {
    yield call(api.delete, `/functions/${payload.id}`, payload);
    yield put(supplierFunctionsDeleteRequestSuccess(payload));
    yield put(supplierFunctionsDelete(payload));
    //yield call([toast, 'info'], 'Supplier function successfully deleted!');
  } catch (err) {
    put(supplierFunctionsDeleteRequestError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}
