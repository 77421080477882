/** @jsx jsx */
import React, { Component } from 'react';
import { jsx } from '@emotion/core';
import _ from 'lodash';

import * as styles from '../multiselect/styles';
import Checkbox from '../CheckboxWithRef';

export interface IListForSelect {
  selectAllListItems: () => void;
  getUnselectedItemsList: () => any[];
  addNewItemToList: (ids: any) => void;
  onPressEscape?: (e: any) => void;
  idKey: string;
  isOnlyOneItem?: boolean;
}

jsx;

class ListForSelect extends Component<IListForSelect> {
  private checkboxRef: any;

  constructor(props: IListForSelect) {
    super(props);
    this.checkboxRef = React.createRef();
  }
  handleChange = (e: any, pickedItem: any) => {
    e && e.stopPropagation();
    e && e.preventDefault();
    this.props.addNewItemToList(pickedItem);
  };
  handleKeysOnVariants = (event: KeyboardEvent, pickedItem: any) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      event && event.stopPropagation();
      event && event.preventDefault();
      this.props.addNewItemToList(pickedItem);
    }
    if (event.key === 'Escape' || event.keyCode === 27) {
      !!this.props.onPressEscape && this.props.onPressEscape(event);
    }
  };
  componentDidUpdate() {
    const unselectedItems = _.chunk(this.props.getUnselectedItemsList(), 1);

    if (unselectedItems && unselectedItems.length === 1) {
      this.checkboxRef && this.checkboxRef.current && this.checkboxRef.current.focus();
    }
  }
  render() {
    const { idKey, getUnselectedItemsList } = this.props;
    const unselectedItemsList = _.chunk(getUnselectedItemsList(), 2);

    return (
      <div>
        {unselectedItemsList.map(([first, second]) => (
          <div css={styles.chunkBox} key={`${_.get(first, idKey, '')} ${_.get(second, idKey, '')}`}>
            {_.compact([first, second]).map((item: any) => {
              return (
                <div css={styles.variantBox} key={item[idKey]}>
                  <Checkbox
                    onChange={(e: any) => this.handleChange(e, item[idKey])}
                    ref={this.checkboxRef}
                    onKeyDown={(e: any) => this.handleKeysOnVariants(e, item[idKey])}
                  />
                  <label onClick={(e: any) => this.handleChange(e, item[idKey])} htmlFor={item[idKey]}>
                    {item.title}
                  </label>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  }
}
export default ListForSelect;
