/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactElement, PureComponent } from 'react';
import _ from 'lodash';
import CellEdit from './CellEdit';
import CellView, { ICell } from './CellView';
import ActionsCell from './ActionsCell';
import * as styles from './styles';

export interface IState {
  editItem: any;
}

export interface IGridRow {
  shema: ICell[];
  actions?: (x: any) => ReactElement<any> | null;
  data?: any;
  isDisable?: boolean;
  onClose?: () => void;

  isDragging?: any;
  connectDragSource?: any;
  dragGridData?: any;
}

jsx;

class Row extends PureComponent<IGridRow, IState> {
  constructor(props: IGridRow) {
    super(props);

    this.state = {
      editItem: null,
    };
  }

  componentDidMount(): void {
    this.initEditItem();
  }

  componentDidUpdate(prevProps: Readonly<IGridRow>, prevState: Readonly<IState>, snapshot?: any): void {
    const { editItem } = this.state;
    if (editItem) {
      this.updateEditItem(editItem);
    }
  }

  initEditItem = () => {
    const { data, shema } = this.props;
    let initValues = shema.reduce((result, schemeItem: any) => {
      const initValue = _.get(schemeItem, 'edit.initValue');
      const editReducer = _.get(schemeItem, 'edit.reducer', _.identity);
      return initValue ? editReducer(result, initValue(result)) : result;
    }, data);

    this.setState({ editItem: initValues });
  };

  updateEditItem = (editItem: any) => {
    const { shema } = this.props;

    const updateValues = shema.reduce((result: any, schemeItem: any) => {
      const updateValue = _.get(schemeItem, 'edit.updateValue');
      const editReducer = _.get(schemeItem, 'edit.reducer', _.identity);
      return updateValue ? editReducer(result, updateValue(result)) : result;
    }, editItem);

    if (!_.isEqual(editItem, updateValues)) {
      this.setState({ editItem: updateValues });
    }
  };

  setEditItem = (value: any, reducer: any) => {
    const { editItem } = this.state;
    this.setState({ editItem: reducer(editItem, value) });
  };

  clearEditItem = () => {
    this.initEditItem();
  };

  render() {
    const { shema, actions, isDisable = false, onClose = _.identity } = this.props;
    const { editItem } = this.state;
    const rowStyle = isDisable ? [styles.rowEdit, styles.rowDisable] : styles.rowEdit;

    if (editItem) {
      return (
        <div css={rowStyle}>
          {shema.map((shemaItem, key) => {
            const hasEdit = shemaItem.edit;
            const editReducer = _.get(shemaItem.edit, 'reducer', _.identity);

            const handlers = {
              onChange: (value: any) => {
                this.setEditItem(value, editReducer);
              },
              onClear: this.clearEditItem,
              onClose: onClose,
            };

            return hasEdit ? (
              <CellEdit key={`edit-${editItem.id}-${key}`} data={editItem} isDisable={isDisable} handlers={handlers} {...shemaItem} />
            ) : (
              <CellView key={`view-${editItem.id}-${key}`} data={editItem} isDisable={isDisable} {...shemaItem} />
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Row;
