import si, { ImmutableObject } from 'seamless-immutable';
import * as ru from '../../../../../utils/redux';
import { getItemState } from '../../../../../utils/store/state/state';
import * as r from '../../../../../utils/reducers';

import * as actions from './actions';
import { GeneralInfoItemState } from './types';

interface IState {
  info: GeneralInfoItemState;
}

const INITIAL_STATE: ImmutableObject<IState> = si({
  // INFO
  info: getItemState({}),
});

const infoReducer = r.createReducer('info');

export default ru.processActions(
  () => ({
    // INFO
    [actions.GET_GENERAL_INFO_REQ]: infoReducer(r.loadingReducer),
    [actions.GET_GENERAL_INFO_REQ_SUCCESS]: infoReducer(r.successReducer),
    [actions.GET_GENERAL_INFO_REQ_ERROR]: infoReducer(r.errorReducer),
    [actions.SET_GENERAL_INFO]: infoReducer(r.itemDataReducer),
  }),
  INITIAL_STATE
);
