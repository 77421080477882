/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, Children, MouseEvent, KeyboardEvent } from 'react';
import { Form as FormicForm } from 'formik';
import * as styles from './styles';

export interface IProps {
  title: string;
  onClick: (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLDivElement>) => void;
  color?: string;
  tabIndex?: number;
}

jsx;

export const FieldsArea: FunctionComponent<IProps> = ({
  title,
  color,
  onClick,
  tabIndex = 0,
}): ReactElement<IProps> => {
  return (
    <div
      css={styles.selectedBtn(color)}
      onClick={onClick}
      onKeyPress={(e: KeyboardEvent<HTMLDivElement>) => onClick(e)}
      tabIndex={tabIndex}
    >
      {title}
    </div>
  );
};

export default FieldsArea;
