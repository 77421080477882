/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import { compose } from 'recompose';

import { Switch, Route, Redirect } from 'react-router';
import { connect } from '../../../utils/redux';
import { isLogin } from '../../../store/selectors';

import { getProfileReq } from './User/store/actions';
import { profile } from './User/store/selectors';
import { UserItemState } from './User/store/types';

import { Action } from '../../../store/types';

import routes from '../../../routes';

import * as styles from './styles';
import Header from './Header/Heeader';
import Content from './Content/Content';
import Producers from './Producers/Producers';
import Clients from './Clients/Clients';
import Suppliers from './Suppliers/Suppliers';
import Productions from './Productions/Productions';

import ChangePassword from './User/ChangePassword';
import UpdateProfile from './User/UpdateProfile';
import withAcl, { IWrappedProps } from './withAcl';
import SupplierFunctionsList from './SupplierFunctions/SupplierFunctions';
import TransportTypes from './TransportTypes/TransportTypes';
import GeneralInfo from './GeneralInfo/GeneralInfo';
import CategoriesRoutes from './Categories/routes';
import AsyncData from '../../common/async/AsyncData';

interface IProps extends IWrappedProps {
  isLogin: boolean;
  profile: UserItemState;
  getProfileReq: Action<{}>;
}

jsx;

const Private: FunctionComponent<IProps> = ({ isLogin, profile, getProfileReq, test }): ReactElement<IProps> => {
  const routesMap: any[] = [
    {
      path: routes.Producers,
      component: Producers,
      acl: 'producer',
    },
    {
      path: routes.Clients,
      component: Clients,
      acl: 'client',
    },
    {
      path: routes.Suppliers,
      component: Suppliers,
      acl: 'supplier',
    },
    {
      path: routes.Productions,
      component: Productions,
      acl: 'production',
    },
    {
      path: routes.SupplierFunctions,
      component: SupplierFunctionsList,
      acl: 'supplier',
    },
    {
      path: routes.TransportTypes,
      component: TransportTypes,
      acl: 'transportType',
    },
    {
      path: routes.GeneralInfo,
      component: GeneralInfo,
      acl: 'generalInfo',
    },

    {
      path: routes.Categories,
      component: CategoriesRoutes,
      acl: 'category',
    },
  ].filter((route: any) => test(route.acl));

  const privatePlace = (
    <AsyncData data={[{ asyncData: profile, asyncGetAction: getProfileReq }]}>
      {() => {
        return (
          <div css={styles.privateBox}>
            <Header />
            <Content>
              <Switch>
                {routesMap.map((params) => {
                  return <Route key={params.path} {...params} />;
                })}
                <Route path={routes.ChangePassword} component={ChangePassword} />
                <Route path={routes.UpdateProfile} component={UpdateProfile} />
                <Route component={() => <Redirect to={routes.Productions} />} />
              </Switch>
            </Content>
          </div>
        );
      }}
    </AsyncData>
  );

  return isLogin ? privatePlace : <Redirect to={routes.Login} />;
};

export default compose<any, {}>(
  connect(
    { isLogin, profile },
    { getProfileReq }
  ),
  withAcl
)(Private);
