/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
// redux
import { connect } from '../../../../utils/redux';
import { categoryAddRequest } from './store/actions';
// types
import { Action } from '../../../../store/types';
// components
import Page from '../../../common/page/Page';
import Breadcrumbs from '../../../common/page/Breadcrumbs';
import PageHeader from '../../../common/page/PageHeader';
import CreateCategoryForm from './components/CreateCategoryForm';
// other
import routes from '../../../../routes';

interface IProps {
  categoryAddRequest: Action<{}>;
}

interface IState {}

jsx;
class CreateCategory extends Component<IProps, IState> {
  render() {
    const { categoryAddRequest } = this.props;

    return (
      <div>
        <PageHeader title="Create category">
          <Breadcrumbs
            path={[
              { title: 'Categories', link: routes.Categories },
              { title: 'Create category', link: routes.CategoriesCreate },
            ]}
          />
        </PageHeader>
        <Page>
          <CreateCategoryForm addCategory={categoryAddRequest} />
        </Page>
      </div>
    );
  }
}

export default connect(
  {},
  { categoryAddRequest }
)(CreateCategory);
