/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { createRef } from 'react';
//utils
import { withFormik, FormikProps } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';
import { compose } from 'recompose';
import moment from 'moment';
import { extractKeyValue } from '../../../../../../utils/collection';
import * as validatinShemas from '../../../../../../utils/validatinShemas';
import { limitFrom, limitTo } from '../utils';
import { toast } from 'react-toastify';

//store
import { connect } from '../../../../../../utils/redux';
import { production } from '../../store/selectors';
import { supplierFunctions } from '../../../SupplierFunctions/store/selectors';
import { suppliersForSelect } from '../../../Suppliers/store/selectors';
import { gates } from '../../../Productions/Item/Gates/store/selectors';
import { transportTypesList } from '../../../TransportTypes/store/selectors';

import { GatesListState } from '../../../Productions/Item/Gates/store/types';
import { IProduction } from '../../../Productions/store/types';
import { TransportTypeListState } from '../../../TransportTypes/store/types';
import { SupplierFunctionListState } from '../../../SupplierFunctions/store/types';
import { SupplierListState } from '../../../Suppliers/store/types';

//components
import PrimaryBtn from '../../../../../common/buttons/OutlineGreen';
import OutlineBtn from '../../../../../common/buttons/OutlineForwarded';
import modal from '../../../../../common/modal';
import form from '../../../../../common/form';
import Datepicker from '../../../../../common/form/datepicker/Datepicker';
import MultiselectModal from '../../../../../common/form/multiselect/MultiselectModal';
import SearchableSelect from '../../../../../common/form/select/SearchableSelect';
import ApplyAction from '../../../../../common/grid/forwarded/ApplyActionWithRef';

import withVisualContext from '../../../../../../contexts/withVisualContext';
import { IVisualContext } from '../../../../../../contexts/VisualContext';

interface FormValues {
  id?: number;
  name: string;
  range: any;
  functions: number[];
  suppliers: number[];
  number: number;
  transport: any;
  remarks: string;
}

interface OtherProps {
  isOpen: boolean;
  onSave: (transportType: any) => void;
  onClose: () => void;
  item?: any;
  production?: IProduction;
  supplierFunctions?: SupplierFunctionListState;
  suppliersForSelect?: SupplierListState;
  gates?: GatesListState;
  transportTypesList?: TransportTypeListState;
  day?: any;
  visual?: IVisualContext;
}

jsx;

const TransportForm = ({
  onClose,
  item,
  production,
  day,
  supplierFunctions,
  suppliersForSelect,
  gates,
  transportTypesList,
  errors,
  touched,
  isOpen,
  values,
  setFieldValue,
  visual,
}: OtherProps & FormikProps<FormValues>) => {
  const { Modal, ModalHeader, ModalFooter, ModalBody, ModalActions } = modal;
  const actionTitle = _.get(item, 'id') ? 'Edit' : 'Create';
  const { FormRow, FormField, FormError, FormBlock, FieldsSetHorizontal, Form, TextArea } = form;

  const productionRange = getProductionRange(production);
  const gatesArray = _.get(gates, 'data', []);
  const selectedGate = values.transport.gate;
  const supplierRef: any = createRef();
  const gateRef: any = createRef();
  const btnSubmitRef: any = createRef();
  const cancelRef: any = createRef();
  const firstFieldRef: any = createRef();
  const vehicleTypeRef: any = [...Array(30)].map((r) => createRef());
  const numberRef: any = createRef();
  const licencePlate: any = [...Array(30)].map((r) => createRef());
  const handleLeaveFocusFromBtn = (event: KeyboardEvent) => {
    if (event.key === 'Tab' || event.keyCode === 9) {
      event.preventDefault();
      event.stopPropagation();
      firstFieldRef && firstFieldRef.current && firstFieldRef.current.focus();
    }
    if (event.shiftKey === true) {
      if (event.key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
        cancelRef && cancelRef.current && cancelRef.current.focus();
      }
    }
  };
  const handleLeaveFocusFromNumber = (event: KeyboardEvent) => {
    // if (event.key === 'Tab' || event.keyCode === 9) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   vehicleTypeRef && vehicleTypeRef[0] && vehicleTypeRef[0].current && vehicleTypeRef[0].current.focus();
    // }
    if (event.shiftKey === true) {
      if (event.key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
        gateRef && gateRef.current && gateRef.current.focus();
      }
    }
  };
  return (
    <div>
      <Modal isOpen={isOpen}>
        <Form>
          <ModalHeader>{actionTitle} transport</ModalHeader>
          <ModalBody>
            <div css={formBox}>
              <FormBlock>
                <FieldsSetHorizontal>
                  <FormRow>
                    <label htmlFor="range.from">Start time</label>
                    <Datepicker
                      range={productionRange}
                      value={values.range.from}
                      onChange={(value) => {
                        setFieldValue('range.from', value);
                      }}
                      ref={firstFieldRef}
                    />
                    <FormError name="range.from" errors={errors} touched={touched} />
                  </FormRow>
                  <FormRow>
                    <label htmlFor="range.to">End time</label>
                    <Datepicker
                      range={productionRange}
                      value={values.range.to}
                      onChange={(value) => {
                        setFieldValue('range.to', value);
                      }}
                    />
                    <FormError name="range.to" errors={errors} touched={touched} />
                  </FormRow>
                </FieldsSetHorizontal>
              </FormBlock>
              <FormBlock>
                <FieldsSetHorizontal>
                  <FormRow>
                    <label htmlFor="functions">{visual ? visual.labels.Function : 'Function'}</label>
                    <MultiselectModal
                      noSelectedMsg={visual ? `Choose ${visual.labels.functions}` : 'Choose functions'}
                      items={_.get(supplierFunctions, 'data', [])}
                      selected={values.functions}
                      scheme={{ id: 'id', title: 'name' }}
                      onChange={(value) => {
                        setFieldValue('functions', value);
                      }}
                      selectAllBtn={false}
                      onBlur={() => {
                        supplierRef && supplierRef.current && supplierRef.current.focus();
                      }}
                    />
                    <FormError name="functions" errors={errors} touched={touched} />
                  </FormRow>

                  <FormRow>
                    <label htmlFor="suppliers">Supplier</label>
                    <SearchableSelect
                      noSelectedMsg="Choose supplier"
                      items={_.get(suppliersForSelect, 'data', [])}
                      selected={values.suppliers[0]}
                      scheme={{ id: 'id', title: 'name' }}
                      onChange={(value) => {
                        setFieldValue('suppliers', [value]);
                      }}
                      ref={supplierRef}
                      onBlur={() => {
                        gateRef && gateRef.current && gateRef.current.focus();
                      }}
                    />
                    <FormError name="suppliers" errors={errors} touched={touched} />
                  </FormRow>

                  <FormRow>
                    <label htmlFor="transport.gate">Gate</label>
                    <SearchableSelect
                      unselectedMsg="Select gate"
                      scheme={{ title: 'name' }}
                      items={gatesArray}
                      selected={selectedGate}
                      onChange={(value) => {
                        setFieldValue('transport.gate', value);
                      }}
                      ref={gateRef}
                      onBlur={() => {
                        numberRef && numberRef.current && numberRef.current.focus();
                      }}
                    />
                    <FormError name="transport.gate" errors={errors} touched={touched} />
                  </FormRow>
                </FieldsSetHorizontal>
              </FormBlock>

              <FormBlock>
                <div css={transportBox}>
                  <div css={transportCount}>
                    <FormRow>
                      <label htmlFor="number" tabIndex={-1}>
                        Number
                      </label>
                      <FormField
                        name="number"
                        placeholder="number min 1 max 20"
                        value={values.number}
                        onKeyPress={(e: any) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation();
                            const number = resolveNumber(values.number);
                            setFieldValue('transport.vehicles', fitVehicles(values.transport.vehicles, number));
                            setFieldValue('number', number);
                          }
                        }}
                        onKeyDown={(e: any) => handleLeaveFocusFromNumber(e)}
                        innerRef={numberRef}
                      />
                      <FormError name="number" errors={errors} touched={touched} />
                    </FormRow>
                    <div css={applyNumber}>
                      <ApplyAction
                        onClick={() => {
                          const number = resolveNumber(values.number);
                          setFieldValue('transport.vehicles', fitVehicles(values.transport.vehicles, number));
                          setFieldValue('number', number);
                        }}
                        tabIndex={0}
                      />
                    </div>
                  </div>
                  <div css={transportList}>
                    {values.transport.vehicles.map((vehicle: any, index: number) => {
                      const name = `transport.vehicles[${index}]`;
                      const typeName = `${name}.type`;
                      const licenceName = `${name}.licence`;

                      return (
                        <div css={vehicleBox} key={index}>
                          <FieldsSetHorizontal>
                            <FormRow>
                              <label htmlFor={typeName} style={{ position: 'relative' }}>
                                Type
                              </label>
                              <SearchableSelect
                                unselectedMsg="Select type"
                                scheme={{ title: 'name' }}
                                items={_.get(transportTypesList, 'data', [])}
                                selected={vehicle.type}
                                onChange={(value) => {
                                  setFieldValue(typeName, value);
                                }}
                                ref={vehicleTypeRef[index]}
                                onBlur={() => {
                                  licencePlate &&
                                    licencePlate[index] &&
                                    licencePlate[index].current &&
                                    licencePlate[index].current.focus();
                                }}
                              />
                              <FormError name={typeName} errors={errors} touched={touched} />
                            </FormRow>
                            <FormRow>
                              <label htmlFor={licenceName}>Licence plate</label>
                              <FormField name={licenceName} innerRef={licencePlate[index]} />
                              <FormError name={licenceName} errors={errors} touched={touched} />
                            </FormRow>
                          </FieldsSetHorizontal>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </FormBlock>
              <FormBlock>
                <FieldsSetHorizontal>
                  <FormRow>
                    <label htmlFor="remarks">Remarks</label>
                    <TextArea
                      value={values.remarks}
                      onChange={(e) => {
                        setFieldValue('remarks', _.get(e, 'currentTarget.value'));
                      }}
                    />
                    <FormError name="remarks" errors={errors} touched={touched} />
                  </FormRow>
                </FieldsSetHorizontal>
              </FormBlock>
            </div>
          </ModalBody>
          <ModalFooter>
            <ModalActions>
              <OutlineBtn type="button" onClick={onClose} title="Cancel" tabIndex={-1} ref={cancelRef} />
              <PrimaryBtn
                title={actionTitle}
                type="submit"
                tabIndex={0}
                onKeyPress={handleLeaveFocusFromBtn}
                ref={btnSubmitRef}
              />
            </ModalActions>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

const fitVehicles = (vehicles: any[], number: number) => {
  const vehiclesCount = vehicles.length;

  if (vehiclesCount > number) {
    return vehicles.slice(0, number);
  } else {
    const newVehicles = Array.apply(null, Array(number - vehiclesCount)).map(() => buildVehicle());
    return [...vehicles, ...newVehicles];
  }
};

const resolveNumber = (number: number) => {
  if (number > 20) {
    number = 20;
    toast.warn('Number must be equal or less then 20');
  } else if (number < 1) {
    toast.warn('Number must be equal or greater than 1');
    number = 1;
  }
  return number;
};

const buildVehicle = (vehicle: any = null) => {
  const vehicleData = {
    type: _.get(vehicle, 'type.id', null),
    licence: _.get(vehicle, 'licence', ''),
  };
  const vehicleId = vehicle ? { id: vehicle.id } : {};
  return { ...vehicleId, ...vehicleData };
};

const getProductionRange = (production: any) => {
  return { from: _.get(production, 'loadIn.from'), to: _.get(production, 'loadOut.to') };
};

const getDefaultRange = (production: any, day: any) => {
  const productionRange = getProductionRange(production);
  const dayStart = moment.utc(day.from).startOf('day');
  const from = limitFrom(moment.utc(dayStart).add(8, 'h'), productionRange.from);
  const to = limitTo(moment.utc(from).add(2, 'h'), productionRange.to);
  return { from, to };
};

const validationSchema = yup.object({}).shape({
  //number: yup.number().min(1, 'The minimum number of vehicles must be 1'),
  range: validatinShemas.vRange,
  suppliers: validatinShemas.vIdListReq,
  functions: validatinShemas.vIdListReq,
  transport: yup.object({}).shape({
    gate: validatinShemas.vIdReq,
    /*vehicles: yup.array().of(
      yup.object({}).shape({
        type: validatinShemas.vIdReq,
        licence: validatinShemas.required,
      })
    ),*/
  }),
});

export const FormikHoc = withFormik<OtherProps, FormValues>({
  enableReinitialize: true,
  mapPropsToValues: ({ item, production, day }) => {
    const defaultTime = getDefaultRange(production, day);

    const vehicles = _.get(item, 'transport.vehicles');
    const transport = {
      id: _.get(item, 'transport.id'),
      gate: _.get(item, 'transport.gate.id'),
      vehicles: vehicles
        ? vehicles.map((vehicle: any) => {
            return buildVehicle(vehicle);
          })
        : [buildVehicle()],
    };

    return {
      name: _.get(item, 'name', ''),
      range: _.get(item, 'range', defaultTime),
      functions: extractKeyValue(_.get(item, 'functions', []), 'id'),
      suppliers: extractKeyValue(_.get(item, 'suppliers', []), 'id'),
      transport: transport,
      remarks: _.get(item, 'remarks', ''),
      number: _.get(transport, 'vehicles').length,
    };
  },
  validationSchema,
  handleSubmit: (values, { props: { onSave, item }, setSubmitting }) => {
    values = item ? { id: item.id, ...values } : values;
    onSave(values);
  },
  displayName: 'TransportForm',
});

export default compose<OtherProps & FormikProps<FormValues>, OtherProps>(
  connect(
    { production, supplierFunctions, suppliersForSelect, gates, transportTypesList },
    {}
  ),
  FormikHoc,
  withVisualContext
)(TransportForm);

const formBox = css({
  width: '600px',
});

const addButtonBox = css({
  marginTop: '30px',
});

const transportBox = css({
  display: 'flex',
  width: '100%',
});

const transportCount = css({
  minWidth: '20%',
  display: 'flex',
});

const applyNumber = css({
  width: '30px',
  height: '30px',
  marginTop: '30px',
  marginLeft: '10px',
  cursor: 'pointer',
  background: `url(${1}) 50% 50% no-repeat`,
});

const transportList = css({
  margiqnLeft: '35px',
  flexGrow: 1,
});

const vehicleBox = css({
  marginBottom: '20px',
});
const xxx = css({});
