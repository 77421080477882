/** @jsx jsx */
import { jsx } from '@emotion/core';
import { forwardRef, TextareaHTMLAttributes, KeyboardEvent } from 'react';
import * as styles from './styles';

jsx;
interface IProps extends TextareaHTMLAttributes<{}> {
  onKeyDown?: (e: KeyboardEvent) => void;
}

export const TextArea = forwardRef<HTMLTextAreaElement, IProps>((props, ref) => {
  return (
    <div css={styles.formFieldBox}>
      <textarea
        {...props}
        ref={ref}
        tabIndex={0}
        // onBlur={props.onBlur}
        onKeyDown={props.onKeyDown}
      />
    </div>
  );
});

export default TextArea;
