/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import * as styles from './styles';

export interface IProps {}

jsx;

export const WidgetCenterContent: FunctionComponent<IProps> = ({ children }): ReactElement<IProps> => {
  return <div css={styles.widgetCenterContent}>{children}</div>;
};

export default WidgetCenterContent;
