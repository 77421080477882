/** @jsx jsx */
import { ReactElement, FunctionComponent } from 'react';
import { jsx, css } from '@emotion/core';
// components
import BaseBtn from '../buttons/BaseBtn';
// icon
import spread from '../grid/images/spread.svg';

interface IProps {
  flat?: Boolean;
}

jsx;
const DropdownButton: FunctionComponent<IProps> = (props): ReactElement<IProps> => {
  const css = [style.dropdownButton, props.flat ? style.flat : {}];
  return <BaseBtn css={css} {...props} />;
};

export default DropdownButton;

const style = {
  dropdownButton: css`
    width: 42px;
    height: 42px;
    border-radius: 50%;
    overflow: auto;
    background-color: white;
    background-image: url(${spread});
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border: none;
    outline: none;
    box-shadow: 2px 2px 7px rgba(150, 150, 150, 0.25);
  `,
  flat: css`
    box-shadow: none;
    background-color: transparent;
  `,
};
