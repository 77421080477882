/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, forwardRef } from 'react';
import modal from '../../../../../common/modal';
import OutlineBtn from '../../../../../common/buttons/OutlineBtn';
import PrimaryBtn from '../../../../../common/buttons/PrimaryBtn';
// utils
import _ from 'lodash';

import MultiselectModal, { IProps as MultiSelectProps } from '../../../../../common/form/multiselect/MultiselectModal';

interface IProps extends MultiSelectProps {
  forwardRef?: any;
  activity: any;
  allSuppliers: any;
  connectFunctionToChosenSuppliers: (funcForAdding: number[]) => void;
}

interface IState {
  checked: boolean;
  isOpenModal: boolean;
  funcForAdding: number[];
}

jsx;

class FunctionSelector extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      checked: false,
      isOpenModal: false,
      funcForAdding: [],
    };
  }

  onCheckAllFunctions = (e: any) => {
    this.setState({ checked: e.currentTarget.checked });
  };

  getFunctionsSelectedSuppliers = () => {
    const { activity, allSuppliers } = this.props;
    return _.get(activity, 'suppliers.length')
      ? _.reduce(
          allSuppliers,
          (result: any, supplier) => {
            if (activity.suppliers.includes(supplier.id)) {
              supplier.functions.forEach(({ id, name }: { id: any; name: any }) => {
                if (!result.find((f: any) => f.id === id)) {
                  result.push({ id, name });
                }
              });
            }
            return result;
          },
          []
        )
      : null;
  };

  getFunctionsForChosing = () => {
    const functionsSelectedSuppliers = this.getFunctionsSelectedSuppliers();
    const { items } = this.props;
    const { checked } = this.state;
    if (!functionsSelectedSuppliers) {
      return items;
    } else {
      return checked ? items : functionsSelectedSuppliers;
    }
  };

  checkAccess = (id: number) => {
    const functionsSelectedSuppliers: any[] | null = this.getFunctionsSelectedSuppliers();
    if (functionsSelectedSuppliers) {
      const haveAllSuppliersSelectedFunc: boolean = functionsSelectedSuppliers.some((func: any) => func.id === id);
      if (!haveAllSuppliersSelectedFunc) {
        this.setState({ isOpenModal: true });
        this.setState((prevState) => ({ funcForAdding: [...prevState.funcForAdding, id] }));
      }
    }
  };

  handleAddFuncToSuppliers = () => {
    const { funcForAdding } = this.state;
    this.props.connectFunctionToChosenSuppliers(funcForAdding);
    this.closeModal();
  };

  closeModal = () => {
    this.setState({ isOpenModal: false, funcForAdding: [] });
  };

  render() {
    const { Modal, ModalHeader, ModalFooter, ModalBody, ModalActions } = modal;

    const functionsForChosing = this.getFunctionsForChosing();

    return (
      <div>
        <MultiselectModal
          {...this.props}
          ref={this.props.forwardRef}
          onSwitchSearch={this.onCheckAllFunctions}
          items={functionsForChosing}
          checkAccess={this.checkAccess}
          deepSearch={true}
          shoudOpenOnFocus
          isVisibilityHight={false}
        />
        <Modal isOpen={this.state.isOpenModal}>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>
            <p>Do you want to connect this function to each chosen supplier?</p>
          </ModalBody>
          <ModalFooter>
            <ModalActions>
              <OutlineBtn type="button" onClick={this.closeModal} title="Cancel" />
              <PrimaryBtn title="Add" type="submit" onClick={this.handleAddFuncToSuppliers} />
            </ModalActions>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default forwardRef<HTMLInputElement, IProps>((props, ref) => <FunctionSelector {...props} forwardRef={ref} />);
