/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import * as styles from '../../../../../styles/styles';

export const mainInfoBox = css({
  display: 'flex',
  justifyContent: 'space-between',
  '& .info-col:last-child': {
    paddingRight: '0px',
  },
  '& .info-col:first-of-type': {
    paddingLeft: '0px',
  },
});

export const mainInfoColBox = css({
  flexGrow: 1,
  padding: '0 15px',
});

export const formBox = css({});
