/** @jsx jsx */
import { Component, Fragment, createRef } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { css, jsx } from '@emotion/core';
import { compose } from 'recompose';
import moment from 'moment';
// redux
import { connect, STATUS_REQUEST } from '../../../../../utils/redux';
import { allProductions, production, productionLoading } from '../store/selectors';
import {
  clearProduction,
  getAllProductionsReq,
  getProductionReq,
  setProduction,
  updateProductionDaysReq,
} from '../store/actions';
import { clearClient } from '../../Clients/store/actions';
import { clearClientAgent } from '../../Clients/Agents/store/actions';
import { clearProducer } from '../../Producers/store/actions';
import { client } from '../../Clients/store/selectors';
import { clientAgents } from '../../Clients/Agents/store/selectors';
import { producer } from '../../Producers/store/selectors';
import { allActivities, versionList } from './store/selectors';
import {
  activityGroupUpdate,
  clearSelectedActivities,
  cloneProductionReq,
  getAllActivitiesReq,
  resetAllActivitiesParams,
  selectAllActivities,
  selectDayActivities,
  unselectFromDayActivities,
  setAllActivitiesParams,
  deleteActivitiesReq,
  cloneActivitiesReq,
  shiftActivitiesReq,
} from './store/actions';
import { getSupplierFunctionsReq, refreshSupplierFunctions } from '../../SupplierFunctions/store/actions';
import { supplierFunctions } from '../../SupplierFunctions/store/selectors';
import {
  getSuppliersForSelectReq,
  refreshSuppliersForSelect,
  updateSupplierInTabReq,
  createSupplierReqFromTabs,
} from '../../Suppliers/store/actions';
import { suppliersForSelect } from '../../Suppliers/store/selectors';
import { ActivitiesListState, ActivityTypes, VersionsListState } from './store/types';

import { gates } from '../../Productions/Item/Gates/store/selectors';
import { getGatesReq, clearGates } from '../../Productions/Item/Gates/store/actions';
import { transportTypesList } from '../../TransportTypes/store/selectors';
import { getTransportTypesReq } from '../../TransportTypes/store/actions';
// types
import { Action } from '../../../../../store/types';
import { IProduction, ProductionListState } from '../store/types';
import { IClient } from '../../Clients/store/types';
import { IClientAgent } from '../../Clients/Agents/store/types';
import { IProducer } from '../../Producers/store/types';
import { SupplierFunctionListState } from '../../SupplierFunctions/store/types';
import { SupplierListState } from '../../Suppliers/store/types';
// components
import Breadcrumbs from '../../../../common/page/Breadcrumbs';
import PageHeader from '../../../../common/page/PageHeader';
import ProductionPageHeader from '../../../../common/page/ProductionPageHeader';
import AsyncData from '../../../../common/async/AsyncData';
import PopoverBtn from '../../../../common/popover/PopoverBtn';
import OutlineBtn from '../../../../common/buttons/OutlineBtn';
import SelectActions from './SelectActions';
import SelectItems from './SelectItems';
import DropdownButton from '../../../../common/popover/DropdownButton';
import DropdownMenu from '../../../../common/popover/DropdownMenu';
import Sticky from '../../../../common/page/Sticky';
import MainInfoBox from './MainInfoBox';
import MainInfoColBox from './MainInfoColBox';
import ProductionInfoWidget from './Widgets/ProductionInfoWidget';
import AdditionalInfoWidget from './Widgets/AdditionalInfoWidget';
import RelatedProductionsWidget from './Widgets/RelatedProductionsWidget';
import ClientInfoWidget from './Widgets/ClientInfoWidget/ClientInfoWidget';
import ProductionLogoWidget from './Widgets/ProductionLogoWidget';
import ProducerInfoWidget from './Widgets/ProducerInfoWidget';
import MainActivitiesWidget from './Widgets/MainActivitiesWidget';
import DateList from './DateList/DateList';
import ActivityFilters from './ActyvityKeyfriendlyFilters';
import CloneProductionModal from './CloneProductionModal';
import CloneActivities from './CloneActivities';
import DeleteActivities from './DeleteActivities';
import MoveActivities from './MoveActivities';
import UpdateActivities from './UpdateActivities';
import PrintModal from './PrintModal';
import Versions from './Versions';
import CheckAcl from '../../CheckAcl';
import Loader from '../../../../common/loader/Loader';
import ActivityTabs from './ActivityTabs';
// other
import routes from '../../../../../routes';
import { Colors } from '../../../../../styles/styles';
import withAcl, { IWrappedProps } from '../../withAcl';
// utils
import _ from 'lodash';
import { extractKeyValue, selectBy } from '../../../../../utils/collection';
import { GatesListState } from './Gates/store/types';
import { TransportTypeListState } from '../../TransportTypes/store/types';
import withVisualContext from '../../../../../contexts/withVisualContext';
import { IVisualContext } from '../../../../../contexts/VisualContext';
import { selectOneBy } from '../../../../../utils/collection';
import * as config from '../../../../../config';

export enum ViewModes {
  activity = 'activity',
  mixed = 'mixed',
  transport = 'transport',
}

export const isViewMode = (val: any): val is ViewModes => {
  return Object.values(ViewModes).includes(val);
};

interface MatchParams {
  id?: string;
}

interface IProps extends RouteComponentProps<MatchParams>, IWrappedProps {
  getProductionReq: Action<{}>;
  setProduction: Action<{}>;
  production: IProduction;
  productionLoading: Boolean;
  client: IClient;
  clientAgents: IClientAgent;
  producer: IProducer;
  setAllActivitiesParams: Action<{}>;
  resetAllActivitiesParams: Action<{}>;
  updateSupplierInTabReq: Action<{}>;
  getAllActivitiesReq: Action<{}>;
  allActivities: ActivitiesListState;
  getSupplierFunctionsReq: Action<{}>;
  supplierFunctions: SupplierFunctionListState;
  getSuppliersForSelectReq: Action<{}>;
  refreshSuppliersForSelect: Action<{}>;
  refreshSupplierFunctions: Action<{}>;
  suppliersForSelect: SupplierListState;
  cloneProductionReq: Action<{}>;
  allProductions: ProductionListState;
  clearProduction: Action<{}>;
  getAllProductionsReq: Action<{}>;
  updateProductionDaysReq: Action<{}>;
  deleteActivitiesReq: Action<{}>;
  shiftActivitiesReq: Action<{}>;
  clearClient: Action<{}>;
  clearClientAgent: Action<{}>;
  clearProducer: Action<{}>;
  clearSelectedActivities: Action<{}>;
  activityGroupUpdate: Action<{}>;
  cloneActivitiesReq: Action<{}>;
  selectAllActivities: Action<{}>;
  selectDayActivities: Action<{}>;
  unselectFromDayActivities: Action<{}>;
  versionList: VersionsListState;

  gates: GatesListState;
  visual: IVisualContext;
  getGatesReq: Action<{}>;
  clearGates: Action<{}>;
  transportTypesList: TransportTypeListState;
  getTransportTypesReq: Action<{}>;
  createSupplierReqFromTabs: Action<{}>;
}

interface IState {
  cloneProductionModalOpen: boolean;
  activitiesUpdateModalOpen: boolean;
  activitiesMoveModalOpen: boolean;
  activitiesCloneModalOpen: boolean;
  activitiesDeleteModalOpen: boolean;
  isPrintModalOpen: boolean;
  isGroupUpdate: boolean;
  isEditMode: boolean;
  viewMode: ViewModes;
  isSelectedAll: boolean;
  isDaysVisible: boolean;
  dayInFocus: string;
  selectedDay: number | null;
}

jsx;
class ProductionItem extends Component<IProps, IState> {
  private tabsRef: any;
  private daysRef: any;
  private mainInfoRef: any;
  private observerForDays: any;
  private observerForHeader: any;
  private observerMainInfo: any;
  private prevTabsRef = null;
  private prevDaysRef = null;
  private prevMainInfoRef = null;

  constructor(props: IProps) {
    super(props);
    this.tabsRef = createRef();
    this.daysRef = createRef();
    this.mainInfoRef = createRef();

    this.state = {
      cloneProductionModalOpen: false,
      activitiesUpdateModalOpen: false,
      activitiesMoveModalOpen: false,
      activitiesCloneModalOpen: false,
      activitiesDeleteModalOpen: false,
      isPrintModalOpen: false,
      isGroupUpdate: false,
      isEditMode: false,
      isSelectedAll: false,
      isDaysVisible: false,
      dayInFocus: '',
      selectedDay: null,
      viewMode: ViewModes.activity,
    };
  }

  componentDidMount = () => {
    const {
      getProductionReq,
      clearProduction,
      clearProducer,
      clearClientAgent,
      clearClient,
      refreshSupplierFunctions,
      refreshSuppliersForSelect,
      resetAllActivitiesParams,
      allProductions,
      getAllProductionsReq,
      clearGates,
      match: { params },
    } = this.props;
    clearGates();
    clearProducer();
    clearClientAgent();
    clearClient();
    resetAllActivitiesParams();
    clearProduction();
    refreshSupplierFunctions();
    refreshSuppliersForSelect();

    getProductionReq(params.id, { fetchRelatedData: true });

    getAllProductionsReq(allProductions.params);

    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode');

    if (isViewMode(mode)) {
      this.setState({ viewMode: mode });
    }

    this.prevTabsRef = this.tabsRef.current;
    this.prevDaysRef = this.daysRef.current;
    this.prevMainInfoRef = this.mainInfoRef.current;
  };

  componentDidUpdate(nextProps: IProps) {
    const {
      getProductionReq,
      production,
      productionLoading,
      match: { params },
      gates,
      getGatesReq,
    } = this.props;

    if (production && !gates.data && gates.status !== STATUS_REQUEST) {
      const productionId = _.get(production, 'main.id', production.id);
      getGatesReq({
        ...gates.params,
        productionId,
        size: 1000,
      });
    }

    if (production && !this.isCurrentProduction(production, params.id) && !productionLoading) {
      getProductionReq(params.id, {
        fetchRelatedData: true,
      });
    }
    if (this.prevDaysRef !== this.daysRef.current) {
      this.prevDaysRef = this.daysRef.current;
      this.createDaysObserver();
    }
    if (this.prevTabsRef !== this.tabsRef.current) {
      this.prevTabsRef = this.tabsRef.current;
      this.createObserverForHeader();
    }

    if (this.prevMainInfoRef !== this.mainInfoRef.current) {
      this.prevMainInfoRef = this.mainInfoRef.current;
      this.createInfoObserver();
    }
  }

  componentWillUnmount(): void {
    const { resetAllActivitiesParams } = this.props;
    resetAllActivitiesParams();

    this.observerForDays &&
      this.daysRef &&
      this.daysRef.current &&
      this.observerForDays.unobserve(this.daysRef.current);

    this.observerForHeader &&
      this.tabsRef &&
      this.tabsRef.current &&
      this.observerForHeader.unobserve(this.tabsRef.current);
  }

  componentWillReceiveProps(nextProps: IProps) {
    const {
      production,
      productionLoading,
      match: { params },
    } = nextProps;

    if (production && !this.isCurrentProduction(production, params.id) && !productionLoading) {
      this.setState({ isEditMode: false });
    }
  }
  createDaysObserver = () => {
    let options = {
      root: null,
      rootMargin: '-100px 0px -75%',
    };
    const callbackForIntersection = (entries: any) => {
      const [entry] = entries;
      if (entry.isIntersecting && !this.state.isDaysVisible) {
        this.setState({ isDaysVisible: true });
      }
    };

    this.observerForDays = new IntersectionObserver(callbackForIntersection, options);
    this.daysRef && this.daysRef.current && this.observerForDays.observe(this.daysRef.current);
  };
  createObserverForHeader = () => {
    let options = {
      root: null,
      rootMargin: '-100px 0px -75%',
    };
    const callbackForIntersection = (entries: any) => {
      const [entry] = entries;
      if (entry.isIntersecting && this.state.isDaysVisible) {
        this.setState({ isDaysVisible: false });
      }
    };

    this.observerForHeader = new IntersectionObserver(callbackForIntersection, options);
    this.tabsRef && this.tabsRef.current && this.observerForHeader.observe(this.tabsRef.current);
  };
  createInfoObserver = () => {
    let options = {
      root: null,
      rootMargin: '-100px 0px -75%',
    };
    const callbackForIntersection = (entries: any) => {
      const [entry] = entries;
      if (entry.isIntersecting && this.state.isDaysVisible) {
        this.setState({ isDaysVisible: false });
      }
    };
    this.observerMainInfo = new IntersectionObserver(callbackForIntersection, options);
    this.mainInfoRef && this.mainInfoRef.current && this.observerMainInfo.observe(this.mainInfoRef.current);
  };

  isCurrentProduction = (production: IProduction, id: string | undefined) => {
    return _.get(production, 'id') === Number(id);
  };

  openCloneProductionModal = () => {
    this.setState({ cloneProductionModalOpen: true });
  };

  closeCloneProductionModal = () => {
    this.setState({ cloneProductionModalOpen: false });
  };

  openActivitiesUpdateModal = () => {
    this.setState({ activitiesUpdateModalOpen: true });
  };

  closeActivitiesUpdateModal = () => {
    this.setState({ activitiesUpdateModalOpen: false });
    this.toggleGroupUpdate();
  };
  openActivitiesMoveModal = () => {
    this.setState({ activitiesMoveModalOpen: true });
  };

  closeActivitiesMoveModal = () => {
    this.setState({ activitiesMoveModalOpen: false });
    this.toggleGroupUpdate();
  };

  openActivitiesCloneModal = () => {
    this.setState({ activitiesCloneModalOpen: true });
  };

  closeActivitiesCloneModal = () => {
    this.setState({ activitiesCloneModalOpen: false });
    this.toggleGroupUpdate();
  };

  openActivitiesDeleteModal = () => {
    this.setState({ activitiesDeleteModalOpen: true });
  };

  closeActivitiesDeleteModal = () => {
    this.setState({ activitiesDeleteModalOpen: false });
    this.toggleGroupUpdate();
  };

  openPrintModal = () => {
    this.setState({ isPrintModalOpen: true });
  };

  onMoveToTabs = () => {
    this.tabsRef && this.tabsRef.current && this.tabsRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  closePrintModal = () => {
    this.setState({ isPrintModalOpen: false });
  };

  addDay = (position: string) => {
    const { updateProductionDaysReq, production } = this.props;
    updateProductionDaysReq({ id: production.id, params: { action: 'add', position } });
  };

  removeDay = (position: string) => {
    const { updateProductionDaysReq, production } = this.props;
    updateProductionDaysReq({ id: production.id, params: { action: 'remove', position } });
  };

  toggleGroupUpdate = () => {
    const { clearSelectedActivities } = this.props;
    const { isGroupUpdate } = this.state;
    this.setState({ isGroupUpdate: !isGroupUpdate });
    if (isGroupUpdate) {
      clearSelectedActivities();
    }
  };
  getProductionRange = () => {
    const { production } = this.props;

    return {
      from: moment.utc(production.loadIn.from),
      to: moment.utc(production.loadOut.to),
    };
  };
  selectDay = (day: number) => {
    this.setState({
      selectedDay: day,
      dayInFocus: moment.utc(day).format(`ddd, ${config.GRID_DATE_FORMAT}, YYYY`),
    });
  };
  selectAllActivities = () => {
    const { allActivities, selectAllActivities, production } = this.props;
    const ids = allActivities.data.reduce((result: number[], activity: any) => {
      return production.id === activity.production.id && activity.type === ActivityTypes.ACTIVITY
        ? [...result, activity.id]
        : result;
    }, []);
    this.setState({ isSelectedAll: true, isGroupUpdate: true });
    selectAllActivities(ids);
  };

  unselectAllActivities = () => {
    const { clearSelectedActivities } = this.props;
    this.setState({ isSelectedAll: false, isGroupUpdate: true });
    clearSelectedActivities();
  };

  onExitEditMode = (e: any) => {
    this.setState({ isEditMode: false, isGroupUpdate: false });
  };

  onStartEditMode = (e: any) => {
    this.setState({ isEditMode: true });
  };

  setViewMode = (viewMode: ViewModes) => {
    const { setAllActivitiesParams } = this.props;
    setAllActivitiesParams({ 'filter.gate.id': [] });
    this.setState({ viewMode });
  };

  filterDataByType = (data: any) => {
    const { viewMode } = this.state;
    const math = getMatchTypeView(viewMode);

    return data.filter((item: any) => {
      return math.includes(item.type);
    });
  };
  setDayInFocus = ({ dayName }: { dayName: string }) => {
    const { dayInFocus } = this.state;
    if (dayInFocus !== dayName) {
      this.setState({ dayInFocus: dayName });
    }
  };
  filterActivityLabels = (allActivities: any) => {
    const { production } = this.props;
    const selectedActivityLabels = allActivities.reduce((result: any, activity: any) => {
      return production.id === activity.production.id && activity.type === ActivityTypes.ACTIVITY
        ? [...result, { id: activity.id, name: activity.name }]
        : result;
    }, []);
    return selectedActivityLabels;
  };
  deleteActivities = (e: any) => {
    this.props.deleteActivitiesReq(e);
    this.closeActivitiesDeleteModal();
  };
  getFilteredData = (activities: any) => {
    const functionsInActivities: any[] = [];
    const funcIds: any[] = [];

    const suppliersInActivities: any[] = [];
    const suppliersIds: any[] = [];

    const subproductionId: any[] = [];
    const subproductionInActivities: any[] = [];

    const gatesInActivities: any[] = [];
    const gatesIds: any[] = [];

    const addActivityWithFunctions = () =>
      activities
        ? activities.forEach((activity: any) => {
            if (activity.functions && activity.functions.length >= 1) {
              activity.functions.forEach((func: any) => {
                if (!funcIds.includes(func.id)) {
                  funcIds.push(func.id);
                  functionsInActivities.push(func);
                }
              });
            }
            if (activity.suppliers && activity.suppliers.length >= 1) {
              activity.suppliers.forEach((supplier: any) => {
                if (!suppliersIds.includes(supplier.id)) {
                  suppliersIds.push(supplier.id);
                  suppliersInActivities.push(supplier);
                }
              });
            }
            if (!activity.isMain && activity.production) {
              if (!subproductionId.includes(activity.production.id)) {
                subproductionId.push(activity.production.id);
                subproductionInActivities.push(activity.production);
              }
            }
            if (activity.transport) {
              if (!gatesIds.includes(activity.transport.gate.id)) {
                gatesIds.push(activity.transport.gate.id);
                gatesInActivities.push(activity.transport.gate);
              }
            }
          })
        : null;
    addActivityWithFunctions();
    return { functionsInActivities, suppliersInActivities, subproductionInActivities, gatesInActivities };
  };

  render() {
    const {
      production,
      client,
      clientAgents,
      producer,
      allActivities,
      getAllActivitiesReq,
      supplierFunctions,
      getSupplierFunctionsReq,
      suppliersForSelect,
      getSuppliersForSelectReq,
      setAllActivitiesParams,
      cloneProductionReq,
      allProductions,
      activityGroupUpdate,
      cloneActivitiesReq,
      shiftActivitiesReq,
      versionList,
      transportTypesList,
      getTransportTypesReq,
      match,
      test,
      visual,
    }: IProps = this.props;
    const {
      cloneProductionModalOpen,
      activitiesUpdateModalOpen,
      activitiesMoveModalOpen,
      activitiesCloneModalOpen,
      activitiesDeleteModalOpen,
      isPrintModalOpen,
      isGroupUpdate,
      isEditMode,
      viewMode,
      dayInFocus,
    } = this.state;

    const { params } = match;
    const hasPermission = test('supplier', 'C');

    const selectedActivityIds = _.get(allActivities, 'selectedIds', []);
    const isCurrentProduction = production && this.isCurrentProduction(production, params.id);
    const clientAgentsData = _.get(clientAgents, 'data');

    if (_.every([production, client, producer], Boolean) && _.isArray(clientAgentsData) && clientAgentsData.length) {
      const selectedClientData = selectBy(clientAgentsData, {
        id: extractKeyValue(production.clientAgents, 'id'),
      });

      const linkEditName = production.isMain
        ? 'Edit production'
        : production.canHaveSub
        ? `Edit ${visual.labels.subproduction}`
        : `Edit ${visual.labels.subSubproduction}`;

      return (
        <AsyncData
          data={[
            { asyncData: allActivities, asyncGetAction: getAllActivitiesReq },
            { asyncData: supplierFunctions, asyncGetAction: getSupplierFunctionsReq },
            { asyncData: suppliersForSelect, asyncGetAction: getSuppliersForSelectReq },
            {
              asyncData: transportTypesList,
              asyncGetAction: (params: any) => {
                return getTransportTypesReq({ ...params, size: 1000 });
              },
            },
            { asyncData: versionList },
          ]}
        >
          {() => {
            const editProductionLink = production.main
              ? routes.SubProductionsEdit.replace(':production_id', String(production.main.id)).replace(
                  ':subproduction_id',
                  String(production.id)
                )
              : routes.ProductionsEdit.replace(':id', String(production.id));

            const {
              functionsInActivities,
              suppliersInActivities,
              subproductionInActivities,
              gatesInActivities,
            } = this.getFilteredData(_.get(allActivities, 'data', []));

            const breadcrumbsPath = production.isMain
              ? [
                  { title: 'Productions', link: routes.Productions },
                  {
                    title: production.name,
                    link: routes.Production.replace(':id', `${production.id}`),
                  },
                ]
              : production.canHaveSub
              ? [
                  { title: 'Productions', link: routes.Productions },
                  {
                    title: production.main.name,
                    link: routes.Production.replace(':id', `${production.main.id}`),
                  },
                  {
                    title: production.name,
                    link: routes.Production.replace(':id', `${production.id}`),
                  },
                ]
              : [
                  { title: 'Productions', link: routes.Productions },
                  {
                    title: production.main.main.name,
                    link: routes.Production.replace(':id', `${production.main.main.id}`),
                  },
                  {
                    title: production.main.name,
                    link: routes.Production.replace(':id', `${production.main.id}`),
                  },
                  {
                    title: production.name,
                    link: routes.Production.replace(':id', `${production.id}`),
                  },
                ];

            const subproductions = Object.values(production.sub);

            const subSubproductions = Object.values(production.sub).reduce((acc: any, el: IProduction) => {
              return el.canHaveSub && el.sub && el.sub.length > 0 ? [...acc, ...Object.values(el.sub)] : acc;
            }, []);

            const dropdownActions = isEditMode
              ? [
                  {
                    label: 'Print schedule',
                    handler: this.openPrintModal,
                    acl: ['production', 'P'],
                  },
                  {
                    label: linkEditName,
                    link: editProductionLink,
                    acl: ['production', 'U'],
                  },
                  {
                    label: 'Clone production',
                    handler: this.openCloneProductionModal,
                    acl: ['production', 'C'],
                  },
                ]
              : [
                  {
                    label: 'Print schedule',
                    handler: this.openPrintModal,
                    acl: ['production', 'P'],
                  },
                  {
                    label: linkEditName,
                    link: editProductionLink,
                    acl: ['production', 'U'],
                  },
                  {
                    label: 'Clone production',
                    handler: this.openCloneProductionModal,
                    acl: ['production', 'C'],
                  },
                  {
                    label: 'Add new day to the beginning',
                    handler: () => this.addDay('before'),
                    acl: ['production', 'U'],
                  },
                  {
                    label: 'Remove day from the beginning',
                    handler: () => this.removeDay('before'),
                    acl: ['production', 'U'],
                  },
                  {
                    label: 'Add new day to the end',
                    handler: () => this.addDay('after'),
                    acl: ['production', 'U'],
                  },
                  {
                    label: 'Remove day from the end',
                    handler: () => this.removeDay('after'),
                    acl: ['production', 'U'],
                  },
                ];

            const prodVersion = versionList.data
              ? _.get(selectOneBy(versionList.data, { 'production.id': production.id }), 'name')
              : null;

            const editButtons = isEditMode ? (
              <Fragment>
                <OutlineBtn
                  onClick={this.onExitEditMode}
                  type="button"
                  title="Exit edit mode"
                  style={{ marginRight: '10px' }}
                />
              </Fragment>
            ) : (
              <Fragment>
                <OutlineBtn
                  onClick={this.onStartEditMode}
                  type="button"
                  title="Edit mode"
                  style={{ marginRight: '10px' }}
                />
              </Fragment>
            );

            const header = !this.state.isDaysVisible ? (
              <PageHeader title={production.name}>
                <Breadcrumbs path={breadcrumbsPath} />
                <div css={style.productionHeaderBox}>
                  {!production.main && (
                    <div css={style.versionBox}>
                      <Versions versionList={versionList} productionId={production.id} prodVersion={prodVersion} />
                    </div>
                  )}

                  <PopoverBtn
                    btn={<DropdownButton />}
                    align="end"
                    renderContent={(closeDropdown) => (
                      <DropdownMenu
                        closeDropdown={closeDropdown}
                        actions={dropdownActions.filter((action) => {
                          const acl = _.get(action, 'acl');
                          return acl ? test(...acl) : true;
                        })}
                      />
                    )}
                  />
                </div>
              </PageHeader>
            ) : (
              <ProductionPageHeader
                onMoveUp={this.onMoveToTabs}
                productionName={production.name}
                viewMode={viewMode}
                prodVersion={prodVersion}
                dayTitle={dayInFocus}
                setSelectedDay={this.selectDay}
                productionRange={this.getProductionRange()}
              >
                {test('production', 'U') && (
                  <Fragment>
                    {editButtons}
                    {isEditMode && viewMode !== ViewModes.transport && (
                      <SelectItems
                        onPointSelect={this.toggleGroupUpdate}
                        onSelectAll={this.selectAllActivities}
                        onUnselectAll={this.unselectAllActivities}
                      />
                    )}
                    {isGroupUpdate && (
                      <SelectActions
                        onUpdate={this.openActivitiesUpdateModal}
                        onMove={this.openActivitiesMoveModal}
                        onClone={this.openActivitiesCloneModal}
                        onDelete={this.openActivitiesDeleteModal}
                      />
                    )}
                  </Fragment>
                )}

                <PopoverBtn
                  btn={<DropdownButton />}
                  align="end"
                  renderContent={(closeDropdown) => (
                    <DropdownMenu
                      closeDropdown={closeDropdown}
                      actions={dropdownActions.filter((action) => {
                        const acl = _.get(action, 'acl');
                        return acl ? test(...acl) : true;
                      })}
                    />
                  )}
                />
              </ProductionPageHeader>
            );

            return (
              <CheckAcl
                resources="production"
                entity={{
                  ...production,
                  activities: allActivities.data,
                }}
                otherwise={<Redirect to={routes.Productions} />}
              >
                {() => (
                  <Fragment>
                    <Sticky>
                      {(isSticky) => {
                        return <div css={style.headerBox(isSticky)}>{header}</div>;
                      }}
                    </Sticky>
                    <MainInfoBox ref={this.mainInfoRef}>
                      <MainInfoColBox>
                        <ProductionInfoWidget production={production} />
                        {production.canHaveSub && <RelatedProductionsWidget production={production} />}
                        <AdditionalInfoWidget production={production} />
                      </MainInfoColBox>

                      {test('client', 'R') ? (
                        <MainInfoColBox>
                          <ClientInfoWidget
                            client={client}
                            productionLogo={production.file}
                            clientAgents={selectedClientData}
                          />
                        </MainInfoColBox>
                      ) : (
                        production.file && (
                          <MainInfoColBox>
                            <ProductionLogoWidget productionLogo={production.file} />
                          </MainInfoColBox>
                        )
                      )}

                      <MainInfoColBox>
                        <ProducerInfoWidget producer={producer} />
                        <MainActivitiesWidget production={production} />
                      </MainInfoColBox>
                    </MainInfoBox>

                    <ActivityTabs
                      value={viewMode}
                      onSelect={this.setViewMode}
                      shouldShowTransport={production.showTransport}
                      ref={this.tabsRef}
                    />

                    <div css={style.filtersRow}>
                      <ActivityFilters
                        activities={allActivities}
                        productionId={production.id}
                        suppliers={suppliersInActivities}
                        supplierFunctions={functionsInActivities}
                        isMainProduction={production.isMain}
                        subproductions={subproductions}
                        subSubproductions={subSubproductions}
                        onChangeParams={(params: any) => {
                          setAllActivitiesParams(params);
                        }}
                        viewMode={viewMode}
                        gates={gatesInActivities}
                      />
                      <div css={style.flexRow}>
                        {test('production', 'U') && (
                          <Fragment>
                            {editButtons}
                            {isEditMode && viewMode !== ViewModes.transport && (
                              <SelectItems
                                onPointSelect={this.toggleGroupUpdate}
                                onSelectAll={this.selectAllActivities}
                                onUnselectAll={this.unselectAllActivities}
                              />
                            )}
                            {isGroupUpdate && isEditMode && (
                              <SelectActions
                                onUpdate={this.openActivitiesUpdateModal}
                                onMove={this.openActivitiesMoveModal}
                                onClone={this.openActivitiesCloneModal}
                                onDelete={this.openActivitiesDeleteModal}
                              />
                            )}
                          </Fragment>
                        )}
                      </div>
                    </div>
                    {isCurrentProduction && (
                      <div ref={this.daysRef} id="days">
                        <DateList
                          allSuppliers={supplierFunctions.data}
                          allFunctions={suppliersForSelect.data}
                          production={production}
                          subproductions={subproductions}
                          activities={this.filterDataByType(allActivities.data)}
                          isSelectMode={isGroupUpdate}
                          isEditMode={isEditMode}
                          viewMode={viewMode}
                          isStickyGridHeader={true}
                          setDayInFocus={this.setDayInFocus}
                          selectedDay={this.state.selectedDay}
                          prodVersion={prodVersion}
                          isShowEmpty={true}
                          onMoveUp={this.onMoveToTabs}
                          selectDayActivities={this.props.selectDayActivities}
                          unselectFromDayActivities={this.props.unselectFromDayActivities}
                        />
                      </div>
                    )}

                    {cloneProductionModalOpen && (
                      <CloneProductionModal
                        onClose={this.closeCloneProductionModal}
                        cloneProduction={cloneProductionReq}
                        production={production}
                        productions={allProductions}
                      />
                    )}

                    <UpdateActivities
                      isOpen={activitiesUpdateModalOpen}
                      onClose={this.closeActivitiesUpdateModal}
                      production={production}
                      hasPermissionCreateSupplier={hasPermission}
                      activitiesUpdate={activityGroupUpdate}
                      selectedLabels={this.filterActivityLabels(allActivities.data)}
                      activities={selectedActivityIds}
                      updateSupplier={this.props.updateSupplierInTabReq}
                      createSupplierFunction={this.props.createSupplierReqFromTabs}
                    />

                    <MoveActivities
                      isOpen={activitiesMoveModalOpen}
                      onClose={this.closeActivitiesMoveModal}
                      production={production}
                      activitiesShift={shiftActivitiesReq}
                      activities={selectedActivityIds}
                    />
                    <CloneActivities
                      isOpen={activitiesCloneModalOpen}
                      onClose={this.closeActivitiesCloneModal}
                      production={production}
                      activitiesClone={cloneActivitiesReq}
                      activities={selectedActivityIds}
                    />
                    <DeleteActivities
                      isOpen={activitiesDeleteModalOpen}
                      onClose={this.closeActivitiesDeleteModal}
                      activities={selectedActivityIds}
                      production={production}
                      onDeleteClick={this.deleteActivities}
                    />

                    {isPrintModalOpen && <PrintModal onClose={this.closePrintModal} production={production} />}
                  </Fragment>
                )}
              </CheckAcl>
            );
          }}
        </AsyncData>
      );
    } else {
      return <Loader isLoading />;
    }
  }
}

export default compose<any, {}>(
  connect(
    {
      production,
      productionLoading,
      client,
      clientAgents,
      producer,
      allActivities,
      supplierFunctions,
      suppliersForSelect,
      allProductions,
      versionList,
      gates,
      transportTypesList,
    },
    {
      getProductionReq,
      deleteActivitiesReq,
      cloneActivitiesReq,
      shiftActivitiesReq,
      clearProduction,
      setProduction,
      getAllProductionsReq,
      updateProductionDaysReq,
      getAllActivitiesReq,
      setAllActivitiesParams,
      resetAllActivitiesParams,
      getSupplierFunctionsReq,
      getSuppliersForSelectReq,
      refreshSuppliersForSelect,
      refreshSupplierFunctions,
      cloneProductionReq,
      clearClient,
      clearClientAgent,
      clearProducer,
      clearSelectedActivities,
      activityGroupUpdate,
      selectAllActivities,
      selectDayActivities,
      unselectFromDayActivities,
      getGatesReq,
      clearGates,
      getTransportTypesReq,
      updateSupplierInTabReq,
      createSupplierReqFromTabs,
    }
  ),
  withAcl,
  withVisualContext
)(ProductionItem);

export const getMatchesViewType = () => {
  const typesList = Object.values(ActivityTypes);
  return {
    [ViewModes.activity]: typesList.filter((type: any) => type !== ActivityTypes.TRANSPORT),
    [ViewModes.mixed]: typesList,
    [ViewModes.transport]: [ActivityTypes.TRANSPORT],
  };
};

export const getMatchTypeView = (viewMode: ViewModes) => {
  return _.get(getMatchesViewType(), viewMode);
};

const style = {
  filtersRow: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
  flexRow: css`
    display: flex;
  `,
  groupUpdateBtn: css`
    background: ${Colors.Green};
    color: white;

    &:hover {
      background: ${Colors.AltGreen};
      color: white;
    }
  `,

  productionHeaderBox: css({
    display: 'flex',
    alignItems: 'center',
  }),

  versionBox: css({
    marginRight: '30px',
  }),

  headerBox: (isSticky: boolean) =>
    css({
      backgroundColor: Colors.LightGreyBlue,
      padding: isSticky ? '0 30px' : 0,
      height: isSticky ? '65px' : 'auto',
      boxShadow: isSticky ? '2px 2px 7px rgba(150,150,150,0.25)' : '',
    }),
};
