/** @jsx jsx */
import { jsx } from '@emotion/core';
import { MouseEvent, Fragment, FunctionComponent, ReactElement } from 'react';

import Row from '../../popover/Row';
import ConfirmButton from '../../buttons/ConfirmButton';
import * as styles from './styles';

export interface IProps {
  onConfirm: (e: MouseEvent<HTMLElement>) => void;
  confirmMessage?: string;
}

jsx;

export const Delete: FunctionComponent<IProps> = (props): ReactElement<IProps> => {
  return (
    <div css={styles.actionText}>
      <ConfirmButton {...props} btn={<Row>Delete</Row>} />
    </div>
  );
};

export default Delete;
