import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import fileSaver from 'file-saver';

// actions
import * as actions from './actions';
import { getClientReq } from '../../Clients/store/actions';
import { getClientAgentsReq } from '../../Clients/Agents/store/actions';
import { getProducerReq } from '../../Producers/store/actions';
import { setAllActivitiesParams, getVersionListReq } from '../Item/store/actions';
// utils
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getResponseMessage } from '../../../../../utils/response';
// other
import routes from '../../../../../routes';

export default function* watchReducersAsync() {
  yield takeLatest(actions.GET_PRODUCTIONS_REQ, getProductionsAsync);
  yield takeLatest(actions.GET_ALL_PRODUCTIONS_REQ, getAllProductionsAsync);
  yield takeEvery(actions.CREATE_PRODUCTION_REQ, createProductionsAsync);
  yield takeEvery(actions.UPDATE_PRODUCTION_REQ, updateProductionAsync);
  yield takeEvery(actions.UPDATE_PRODUCTION_DAYS_REQ, updateProductionDaysAsync);
  yield takeEvery(actions.DELETE_PRODUCTION_REQ, deleteProductionAsync);
  yield takeEvery(actions.GET_PRODUCTION_PRINT_REQ, getProductionPrintAsync);
  yield takeEvery(actions.GET_PRODUCTION_PRINT_INFO_REQ, getProductionPrintInfoAsync);
  yield takeEvery(actions.GET_PRODUCTION_REQ, getProductionAsync);
  yield takeEvery(actions.GET_PRODUCTION_REQ_SUCCESS, fetchProductionRelatedData);
  yield takeEvery(actions.CREATE_SUBPRODUCTION_REQ, createSubproductionsAsync);
  yield takeEvery(actions.GET_SUBPRODUCTION_REQ, getSubproductionAsync);
  yield takeEvery(actions.UPDATE_SUBPRODUCTION_REQ, updateSubproductionsAsync);
}

export function* getProductionsAsync({ api, payload }: any) {
  try {
    const path = '/productions';
    const params = _.omitBy(payload, (item) => !item);
    const response = yield call(api.get, path, { params });
    const { data, meta } = response.data;
    yield put(actions.getProductionsReqSuccess({ data, params }, meta));
    // yield put(actions.setProductions(response.data));
  } catch (err) {
    yield put(actions.getProductionsReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* getAllProductionsAsync({ api, payload }: any) {
  try {
    const path = '/productions';
    const response = yield call(api.get, path, { params: payload });
    yield put(actions.setAllProductions(response.data));
  } catch (err) {
    yield put(actions.getProductionsReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* createProductionsAsync({ api, payload }: any) {
  try {
    const response = yield call(api.post, '/productions', payload);
    const id = _.get(_.head(response.data.data), 'id');
    yield put(actions.createProductionReqSuccess());
    yield put(push(routes.Production.replace(':id', id)));
    //yield call([toast, 'success'], 'Production successfully created!');
  } catch (err) {
    yield put(actions.createProductionReqError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* updateProductionAsync({ api, payload }: any) {
  const data = payload;
  try {
    const path = `/productions/${data.id}`;
    const response = yield call(api.post, path, data);
    yield put(actions.updateProductionReqSuccess());
    yield put(actions.setProduction(response.data.data));
    yield put(push(routes.Production.replace(':id', data.id)));
    //yield call([toast, 'info'], 'Production successfully updated!');
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* updateProductionDaysAsync({ api, payload: { id, params } }: any) {
  try {
    const path = `/productions/${id}/days`;
    const response = yield call(api.patch, path, params);
    yield put(actions.updateProductionReqSuccess());
    yield put(actions.setProduction(response.data.data));
    const message = params.action === 'add' ? 'New day successfully added!' : 'Day successfully deleted!';
    //yield call([toast, 'info'], message);
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* getProductionAsync({ api, payload, meta }: any) {
  try {
    const path = `/productions/${payload}`;
    const response = yield call(api.get, path);
    const production = response.data.data[0];
    yield put(actions.getProductionReqSuccess({ production }, meta));
    yield put(actions.setProduction(response.data.data));
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* getProductionPrintAsync({ api, payload }: any) {
  try {
    const path = `/print/productions/${payload.productionId}/preview`;
    const response = yield call(api.post, path, payload, { responseType: 'blob' });

    //const applyPatch = `/print/productions/${payload.productionId}`;
    //yield call(api.post, applyPatch, payload, { responseType: 'blob' });

    yield put(actions.getProductionPrintReqSuccess());
    const file = window.URL.createObjectURL(response.data);
    window.open(file, '_blank');
  } catch (err) {
    yield put(actions.getProductionPrintReqError());
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* getProductionPrintInfoAsync({ api, payload }: any) {
  try {
    const path = `/print/productions/${payload.ownerId}/meta-info`;
    const response = yield call(api.get, path);
    yield put(actions.getProductionPrintInfoReqSuccess());
    yield put(actions.setProductionPrintInfo(response.data));
  } catch (err) {
    yield put(actions.getProductionPrintInfoReqError());
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* deleteProductionAsync({ api, payload }: any) {
  try {
    const path = `/productions/${payload}`;
    yield call(api.delete, path);
    yield put(actions.deleteProductionReqSuccess({ id: payload }));
    //yield call([toast, 'info'], 'Production successfully deleted!');
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* createSubproductionsAsync({ api, payload }: any) {
  try {
    const { productionId, values } = payload;
    const response = yield call(api.post, `/productions/${productionId}/sub-productions`, values);
    const subproduction = _.get(response, 'data.data[0]');
    yield put(push(routes.Production.replace(':id', String(subproduction.id))));
    //yield call([toast, 'success'], 'Subproduction successfully created!');
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* getSubproductionAsync({ api, payload }: any) {
  try {
    const path = `/productions/${payload}`;
    const response = yield call(api.get, path);
    yield put(actions.getSubproductionReqSuccess({}));
    yield put(actions.setSubproduction(response.data.data));
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* updateSubproductionsAsync({ api, payload }: any) {
  try {
    const { productionId, values } = payload;
    const response = yield call(api.post, `/productions/${productionId}/sub-productions/${values.id}`, values);
    yield put(push(routes.Production.replace(':id', values.id)));
    //yield call([toast, 'info'], 'Subproduction successfully updated!');
  } catch (err) {
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* fetchProductionRelatedData({ payload, meta }: any) {
  const production = _.get(payload, 'production');
  const fetchRelatedData = _.get(meta, 'fetchRelatedData');

  if (production && fetchRelatedData) {
    if (!production.main) {
      yield put(getVersionListReq({ id: production.id, size: 1000 }));
    }

    yield put(getClientReq(production.client.id));
    yield put(getClientAgentsReq({ ownerId: production.client.id, size: 1000 }));

    yield put(getProducerReq(production.producer.id));
    yield put(setAllActivitiesParams({ ownerId: production.id }));
  }
}
