import { put, call, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
// actions
import {
  LOGIN_REQUEST,
  loginRequestSuccsess,
  loginRequestError,
  login,
  RESTORE_EMAIL_REQ,
  RESTORE_PASSWORD_REQ,
  INVITATION_REQ,
  LOGOUT,
} from './actions';

import * as userActions from '../components/layouts/Private/User/store/actions';

// utils
import _ from 'lodash';
import { setToken } from '../utils/auth';
import { getResponseMessage } from '../utils/response';
// other
import { toast } from 'react-toastify';
import routes from '../routes';

export default function* watchMainAsync() {
  yield takeLatest(RESTORE_PASSWORD_REQ, restorePasswordRequest);
  yield takeLatest(RESTORE_EMAIL_REQ, restoreEmailRequest);
  yield takeLatest(INVITATION_REQ, invitationRequest);
  yield takeLatest(LOGIN_REQUEST, loginRequest);
  yield takeLatest(LOGOUT, logout);
}

export function logout() {
  setTimeout(() => {
    window.location.href = routes.Root;
  }, 0);
}

export function* invitationRequest({ api, payload }: any) {
  try {
    const path = `/auth/invitations/${payload.token}`;
    const response = yield call(api.post, path, payload.data);
    yield put(push(routes.Login));
    //yield call([toast, 'success'], 'Welcome to Backbone!');
  } catch (err) {
    put(loginRequestError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* restorePasswordRequest({ api, payload }: any) {
  try {
    const path = `/auth/restores/${payload.token}`;
    const response = yield call(api.post, path, payload.data);
    yield put(push(routes.Login));
    //yield call([toast, 'success'], 'Restore complete');
  } catch (err) {
    put(loginRequestError(err));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* restoreEmailRequest({ api, payload }: any) {
  try {
    const path = '/auth/restores';
    const response = yield call(api.post, path, payload);
    //yield call([toast, 'success'], 'Restore request successfully sent');
  } catch (err) {
    put(loginRequestError(err.data));
    yield call([toast, 'error'], getResponseMessage(err));
  }
}

export function* loginRequest({ api, payload }: any) {
  try {
    const { data } = yield call(api.post, '/auth/login', payload);
    yield put(userActions.setProfile(data));
    yield call(setToken, data.token);
    yield put(loginRequestSuccsess());
    yield put(login());
    yield put(push(routes.Root));
    //yield call([toast, 'success'], 'Welcome to Backbone!');
  } catch (err) {
    yield put(loginRequestError(err.data));
    yield call([toast, 'error'], err.data.message);
  }
}
