/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement, Children } from 'react';
import * as styles from './styles';

export interface IProps {
  itemWidth?: number;
}

jsx;

export const FormRow: FunctionComponent<IProps> = ({ children }): ReactElement<IProps> => {
  return (
    <div css={styles.actionsRowBox}>
      {Children.map(children, (child) => (
        <div css={styles.actionsRowItem}>{child}</div>
      ))}
    </div>
  );
};

export default FormRow;
