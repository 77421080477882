import { spawn, all } from "redux-saga/effects";

export class SagasRegistry {
  constructor() {
    this._sagas = [];
  }

  getSagas() {
    const allSagas = this._sagas;
    return function* root() {
      yield all(allSagas.map(spawn));
    };
  }

  register(saga) {
    this._sagas.push(saga);
  }

  setChangeListener(listener) {
    this._emitChange = listener;
  }
}

const sagasRegistry = new SagasRegistry();
export default sagasRegistry;
