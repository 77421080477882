/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { withFormik, FormikProps, FieldArray } from 'formik';
import * as yup from 'yup';
// types
import { Action } from '../../../../../store/types';
// components
import { Link } from 'react-router-dom';
import BaseBtn from '../../../../common/buttons/BaseBtn';
import PrimaryBtn from '../../../../common/buttons/PrimaryBtn';
import OutlineBtn from '../../../../common/buttons/OutlineBtn';
import form from '../../../../common/form';

import FileThumb, { IMAGE_FILE_TYPES } from '../../../../common/form/fileviewer/FileThumb';
import Dropzone from '../../../../common/form/FormDropzone';
import RemoveBox from '../../../../common/form/RemoveBox';
// types
import { Category } from '../store/types';
// other
import routes from '../../../../../routes';
import removeIcon from '../../../../../images/icons/remove.svg';
import plusIcon from '../../../../../images/icons/plus.svg';
// utils
import _ from 'lodash';
import uniqid from 'uniqid';
import { toast } from 'react-toastify';
import { MAX_FILE_UPLOAD_MB } from '../../../../../config';
import { checkFileSize } from '../../GeneralInfo/Form';

const { FormRow, FormField, FormError, FormBlock, FieldsSet, ActionsRow, Form, FieldsArea } = form;

interface FormValues {
  name: string;
  types: string[];
  safetyIcons: any[];
  id: number | string;
}

interface OtherProps {
  addCategory?: Action<{}>;
  editCategory?: Action<{}>;
  editMode?: boolean;
  category?: Category;
}

interface FormProps {}

jsx;
const CreateCategoryForm = ({
  errors,
  touched,
  values,
  setFieldValue,
  editMode,
}: OtherProps & FormikProps<FormValues>) => {
  const images = values.safetyIcons;
  return (
    <Form>
      <FieldsArea>
        <FormBlock>
          <FieldsSet>
            <FormRow>
              <label htmlFor="name">Category</label>
              <FormField name="name" placeholder="Category name" />
              <FormError name="name" errors={errors} touched={touched} />
            </FormRow>
          </FieldsSet>

          <FieldsSet>
            <FieldArray
              name="types"
              render={(arrayHelpers) => (
                <FormRow>
                  <label htmlFor="types">Types</label>
                  {values.types &&
                    values.types.length > 0 &&
                    values.types.map((type, index) => (
                      <div key={index}>
                        <FormRow css={style.typeRow}>
                          {values.types.length > 1 && (
                            <BaseBtn css={style.removeBtn} onClick={() => arrayHelpers.remove(index)} type="button" />
                          )}
                          <FormField name={`types.${index}`} />
                          <FormError name={`types.${index}`} errors={errors} touched={touched} />
                        </FormRow>
                      </div>
                    ))}
                  <OutlineBtn type="button" onClick={() => arrayHelpers.push('')} css={style.addTypeBtn}>
                    <span css={style.plusIcon} />
                    <span>Add new type</span>
                  </OutlineBtn>
                </FormRow>
              )}
            />
          </FieldsSet>
          <div css={style.safetyIconsSet}>
            <FormRow>
              <label htmlFor="safetyIcons">
                Safety icons
                <div css={style.filesListBox}>
                  {images.map((icon: any, idx: number) => {
                    const file = icon.isNew ? icon.file : icon;
                    return (
                      <div key={`${images.length}-${idx}`} css={style.iconWrapper}>
                        <RemoveBox
                          onRemove={() => {
                            const safetyIcons = values.safetyIcons.reduce((acc, safetyIcon) => {
                              if (!!safetyIcon.isNew) {
                                if (safetyIcon.id !== icon.id) acc.push(safetyIcon);
                                return acc;
                              } else {
                                if (safetyIcon !== icon) acc.push(safetyIcon);
                                return acc;
                              }
                            }, []);
                            setFieldValue('safetyIcons', safetyIcons);
                          }}
                        />
                        <FileThumb file={file} isShowName={false} isImagePreview={true} isLoadableFile={true} />
                      </div>
                    );
                  })}
                </div>
                <Dropzone
                  onDrop={(files: any) => {
                    files = validateFiles(files);
                    files = files.map((file: any) => ({ file, isNew: true, id: uniqid('new-') }));
                    setFieldValue('safetyIcons', [...values.safetyIcons, ...files]);
                  }}
                  maxSize={MAX_FILE_UPLOAD_MB}
                  fileFormats={IMAGE_FILE_TYPES}
                  style={{ backgroundColor: '#F5F5F5' }}
                />
              </label>
            </FormRow>
          </div>
        </FormBlock>
      </FieldsArea>

      <ActionsRow>
        <Link to={routes.Categories}>
          <OutlineBtn title="Cancel" isWide={false} type="button" />
        </Link>
        <PrimaryBtn title={`${editMode ? 'Edit' : 'Create'} category`} isWide={false} type="submit" />
      </ActionsRow>
    </Form>
  );
};

export const validateFiles = (files: any[]) => {
  files = files.filter((file: any) => {
    if (!checkFileFormat(file)) {
      toast.error(`File ${file.name} is incorrect`);
      return false;
    }
    if (!checkFileSize(file)) {
      toast.error(`File ${file.name} is more then ${MAX_FILE_UPLOAD_MB}Mb`);
      return false;
    }
    return true;
  });
  return files;
};

const checkFileFormat = (file: any) => {
  const ext = /(?:\.([^.]+))?$/.exec(file.name)![1] || '';
  return IMAGE_FILE_TYPES.includes(ext.toLowerCase());
};

export default withFormik<FormProps & OtherProps, FormValues>({
  enableReinitialize: true,
  mapPropsToValues: (props: FormProps & OtherProps): FormValues => {
    const { category } = props;
    const categoryTypes = _.get(category, 'types');
    const safetyIconsAttached = _.get(category, 'safetyIcons');

    return {
      id: category ? category.id : uniqid(),
      name: category ? category.name : '',
      types: categoryTypes ? _.map(categoryTypes, 'name') : [''],
      safetyIcons: safetyIconsAttached ? safetyIconsAttached : [],
    };
  },
  validationSchema: yup.object({}).shape({
    name: yup.string().required(),
    types: yup.array().of(yup.string().min(1, 'Type is empty')),
  }),
  handleSubmit: (values, { props: { addCategory, editCategory, editMode }, setSubmitting }) => {
    const { id, name, types, safetyIcons } = values;
    const data = new FormData();
    data.append('name', name);

    data.append('types', JSON.stringify(types));

    const existingIcons: string[] = [];
    safetyIcons.forEach((icon) => {
      if (!!icon.isNew) {
        data.append('safetyIcons', icon.file);
      } else {
        const iconName = icon.match(/[\w-]+\.(jpg|png|gif|jpeg|svg)/);
        if (iconName && iconName.length > 0) existingIcons.push(iconName[0]);
      }
    });

    existingIcons.length > 0 && data.append('existingIcons', JSON.stringify(existingIcons));

    if (editMode) {
      editCategory({ id, data });
    } else {
      addCategory(data);
    }
  },
  displayName: 'CreateCategoryForm',
})(CreateCategoryForm);

const style = {
  typeRow: css`
    position: relative;
    padding: 0;
    margin-bottom: 25px;
  `,
  removeBtn: css`
    width: 32px;
    height: 32px;
    background: no-repeat url(${removeIcon}) center;
    border: none;
    outline: none;
    cursor: pointer;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translate(5px, -50%);
    opacity: 0.8;
  `,
  plusIcon: css`
    display: inline-block;
    width: 16px;
    height: 16px;
    background: no-repeat url(${plusIcon}) center;
    opacity: 0.8;
    margin-right: 10px;
  `,
  addTypeBtn: css`
    display: flex;
    align-items: center;
  `,
  safetyIconsSet: css`
    flex-basis: 100%;
    padding: 50px 20px 10px;
  `,
  filesListBox: css`
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
  `,
  iconWrapper: css`
    margin: 0 40px 20px 40px;
    box-sizing: border-box;
  `,
  iconImg: css`
    width: 100px;
  `,
};
