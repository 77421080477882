export const getSearchParam = (name, url) => {
  url = url || window.location.href;
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const setLinkParams = (link, params = {}) => {
  return Object.entries(params).reduce((result, [key, value]) => {
    return result.replace(`:${key}`, value);
  }, link);
};
