/** @jsx jsx */
import { Fragment, Component } from 'react';
import { jsx, css } from '@emotion/core';

// components
import PrimaryBtn from '../../../common/buttons/PrimaryBtn';
import withAcl, { IWrappedProps } from '../withAcl';
import * as styles from './styles';
import FileThumb from '../../../common/form/fileviewer/FileThumb';
import ActionsRow from '../../../common/form/ActionsRow';
import OutlineBtn from '../../../common/buttons/OutlineBtn';
import { tr } from '../../../common/grid/styles';

interface IProps extends IWrappedProps {
  item: any;
  onEditStart: () => void;
}

interface IState {}

jsx;
class TransportTypesList extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { item, onEditStart, test } = this.props;

    return (
      <Fragment>
        <div css={styles.infoBox}>
          {item.blocks.map(({ id, name, description }: any) => {
            description = description || 'Please go to edit and update this block';

            return (
              <div key={id} css={styles.blockBox}>
                <div css={styles.blockTitle}>{name}</div>
                <div css={styles.blockContent}>
                  <pre css={styles.preContent}>{description}</pre>
                </div>
              </div>
            );
          })}

          <div css={styles.infoFilesListBox}>
            {item.attachments.map((file: any) => {
              return (
                <div key={file.id} css={styles.iconWrapper}>
                  <FileThumb file={file} isShowName={true} isLoadableFile={true} isImagePreview={true} />
                </div>
              );
            })}
          </div>
        </div>
        {test('generalInfo', 'U') && (
          <ActionsRow>
            <PrimaryBtn title="Edit" onClick={onEditStart} />
          </ActionsRow>
        )}
      </Fragment>
    );
  }
}

export default withAcl(TransportTypesList);
