/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent, ReactElement } from 'react';
import _ from 'lodash';

import * as styles from './styles';

export enum Visibility {
  Edit = 'edit',
  View = 'view',
}

export interface EditProps {
  path?: string;
  reducer?: (item: any, value: any) => any;
  serializer?: (item: any) => any;
  initValue?: (item: any) => any;
  render: (value: any, onChange: (value: any) => void, error: any) => ReactElement<any>;
}

export interface ICell {
  title: string | ReactElement<any>;
  headStyle?: any;
  width?: number;
  cellStyle?: any;
  className?: string;
  path?: string | string[];
  serializer?: (data: any) => any;
  render?: (value: any) => ReactElement<any>;
  edit?: EditProps;
  visible?: any;
}

export interface IProps extends ICell {
  data?: any;
  isDisable?: boolean;
  isPrintView?: boolean;
}

jsx;

export const Cell: FunctionComponent<IProps> = ({
  data = null,
  cellStyle,
  className,
  title,
  path,
  serializer = _.identity,
  render = _.identity,
  isDisable = false,
  isPrintView = false,
  width = 100,
}): ReactElement<IProps> => {
  const value = path ? _.get(data, path) : data;
  cellStyle = cellStyle || [];

  const cell = render(serializer(value));

  return (
    <div css={[styles.cell(width), cellStyle]} className={className}>
      {cell}
    </div>
  );
};

export default Cell;
