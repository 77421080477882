import { nsSelector } from '../../../../../utils/redux';

const producersSelector = nsSelector('producers');
export const producers = producersSelector('list');
export const producersPage = producersSelector('page');
export const producersSearch = producersSelector('search');
export const producersTotal = producersSelector('total');
export const producersLoading = producersSelector('listLoading');

export const producer = producersSelector('item');
