/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment, createRef } from 'react';
import { withFormik, FormikProps } from 'formik';
import * as yup from 'yup';
import { compose, lifecycle } from 'recompose';
import _ from 'lodash';
// redux
import { connect } from '../../../../utils/redux';
import { createProductionReq } from './store/actions';
import { getClientsForSelectReq, refreshClientForSelect, createClientReqInTab } from '../Clients/store/actions';
import {
  getClientAgentsReq,
  setClientAgentsParams,
  setClientAgents,
  createClientAgentInTabReq,
} from '../Clients/Agents/store/actions';
import { getProducersReq } from '../Producers/store/actions';
import { clientsForSelect } from '../Clients/store/selectors';
import { clientAgents } from '../Clients/Agents/store/selectors';
import { producers } from '../Producers/store/selectors';
import { createProducersReqInTab } from '../Producers/store/actions';
// types
import { Action } from '../../../../store/types';
import { IProducer } from '../Producers/store/types';
import { ClientAgentListState } from '../Clients/Agents/store/types';
import { ClientListState } from '../Clients/store/types';
// components
import { Link } from 'react-router-dom';
import AsyncData from '../../../common/async/AsyncData';
import PrimaryBtn from '../../../common/buttons/OutlineGreen';
import OutlineBtn from '../../../common/buttons/OutlineBtn';
import OutlineForwarded from '../../../common/buttons/OutlineForwarded';
import Checkbox from '../../../common/form/InputCheckbox';
import form from '../../../common/form';
import ImageUpload from '../../../common/form/kits/ImageUpload';
import page from '../../../common/page';
import SelectModalWithTabs from '../../../common/form/selectWithTabs/SelectModalWithTabs';
import SearchableSelect from '../../../common/form/select/SearchableSelect';
import MultiselectModal from '../../../common/form/multiselect/MultiselectModal';
import CreateAgent from '../Clients/Agents/CreateAgentInTab';
import CreateProducer from '../Producers/CreateInTab';
import CreateClientForm from '../Clients/CreateClientInTab';
import MultiSelectInputWithTabs from '../../../common/form/selectWithTabs/MultiSelectInputWithTabs';
import DateRange, { IDataRangeValue } from '../../../common/form/datepicker/DateRange';
import { IDataRangeWithName } from '../../../common/form/datepicker/DateRangeWithName';
import DateRangeListWithName from '../../../common/form/datepicker/DateRangeListWithName';
import { toast } from 'react-toastify';
import withAcl, { IWrappedProps } from '../withAcl';
import withVisualContext from '../../../../contexts/withVisualContext';
import { IVisualContext } from '../../../../contexts/VisualContext';
// other
import routes from '../../../../routes';
import * as validatinShemas from '../../../../utils/validatinShemas';

interface FormValues {
  name: string;
  subProduction: string;
  code: string;
  location: string;
  client: number | null;
  clientAgents: number[];
  producer: number | null;
  freelancers: number[];
  loadIn: IDataRangeValue;
  loadOut: IDataRangeValue;
  shows: IDataRangeWithName[];
  rehearsals: IDataRangeWithName[];
  file: string | null;
  hideShows: boolean;
  showTransport: boolean;
  showMachinery: boolean;
}

interface OtherProps extends IWrappedProps {
  createProductionReq: Action<{}>;
  createClientAgent: Action<{}>;
  createClient: Action<{}>;
  createProducer: Action<{}>;
  getClientsForSelectReq: Action<{}>;
  refreshClientForSelect: Action<{}>;
  clientsForSelect: ClientListState;
  getClientAgentsReq: Action<{}>;
  setClientAgentsParams: Action<{}>;
  setClientAgents: Action<{}>;
  allClientAgents: ClientAgentListState;
  getProducersReq: Action<{}>;
  producers: IProducer[];
  visual: IVisualContext;
}

interface FormProps {}

jsx;
const withLifecycle = lifecycle<OtherProps, {}>({
  componentDidMount() {
    const { getProducersReq, setClientAgentsParams, setClientAgents, refreshClientForSelect } = this.props;
    refreshClientForSelect();
    setClientAgentsParams({ size: 1000 });
    setClientAgents({ data: {}, meta: {} });
    getProducersReq({ size: 1000 });
  },
});

const prepareProductionValues = (values: FormValues) => {
  const clearEmpty = (item: IDataRangeWithName) => item.name || item.range.from || item.range.to;
  return {
    ...values,
    shows: values.shows.filter(clearEmpty),
    rehearsals: values.rehearsals.filter(clearEmpty),
  };
};

const CreateForm = ({
  errors,
  touched,
  values,
  setFieldValue,
  getClientsForSelectReq,
  clientsForSelect,
  getClientAgentsReq,
  allClientAgents,
  createClientAgent,
  createClient,
  createProducer,
  test,
  visual,
  producers,
}: OtherProps & FormikProps<FormValues>) => {
  const { FormRow, FormRowSections, FormField, FormError, FormBlock, FieldsSet, ActionsRow, Form, FieldsArea } = form;
  const { Page, Breadcrumbs, PageHeader } = page;

  const firstFieldRef: any = createRef();
  const btnCancelRef: any = createRef();
  const handleLeaveFocusFromBtn = (event: KeyboardEvent) => {
    if (event.key === 'Tab' || event.keyCode === 9) {
      event.preventDefault();
      event.stopPropagation();
      firstFieldRef && firstFieldRef.current && firstFieldRef.current.focus();
    }
    if (event.shiftKey === true) {
      if (event.key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
        btnCancelRef && btnCancelRef.current && btnCancelRef.current.focus();
      }
    }
  };
  const handleLeaveFocus = (event: KeyboardEvent) => {
    if (event.shiftKey === true) {
      if (event.key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };

  const clientAgentRef: any = createRef();
  const productionManagerRef: any = createRef();
  const freelancerRef: any = createRef();
  const loadInFromRef: any = createRef();

  const canCreateClient = test('client', 'C');
  const canCreateClientAgent = test('clientAgent', 'C');
  const canCreateProductionManagerOrFreelancer = test('producer', 'C');
  const allClientAgentsData = _.isArray(allClientAgents.data) ? allClientAgents.data : [];

  return (
    <Fragment>
      <PageHeader title="Create production">
        <Breadcrumbs
          path={[
            { title: 'Productions', link: routes.Productions },
            { title: 'Create production', link: routes.ProductionsCreate },
          ]}
        />
      </PageHeader>
      <Page>
        <Form>
          <div>
            <AsyncData
              data={[
                { asyncData: clientsForSelect, asyncGetAction: getClientsForSelectReq },
                { asyncData: allClientAgents, asyncGetAction: getClientAgentsReq },
              ]}
            >
              <Fragment>
                <FieldsArea>
                  {/* MAIN */}
                  <FormBlock>
                    <FieldsSet width={2}>
                      <FormRow>
                        <FormRowSections width={2}>
                          <label htmlFor="name">Name</label>
                          <FormField
                            name="name"
                            placeholder="name"
                            autoFocus
                            innerRef={firstFieldRef}
                            onKeyDown={(e: any) => handleLeaveFocus(e)}
                          />
                          <FormError name="name" errors={errors} touched={touched} />
                        </FormRowSections>

                        <FormRowSections width={2}>
                          <label htmlFor="subProduction">{visual.labels.Subproduction}</label>
                          <FormField name="subProduction" placeholder="Main" disabled={true} />
                          <FormError name="subProduction" errors={errors} touched={touched} />
                        </FormRowSections>
                      </FormRow>

                      <FormRow>
                        <FormRowSections width={2}>
                          <label htmlFor="code">Syntax</label>
                          <FormField name="code" placeholder="sytnax" />
                          <FormError name="code" errors={errors} touched={touched} />
                        </FormRowSections>

                        <FormRowSections width={2}>
                          <label htmlFor="location">Location</label>
                          <FormField name="location" placeholder="location" />
                          <FormError name="location" errors={errors} touched={touched} />
                        </FormRowSections>
                      </FormRow>

                      <FormRow>
                        <FormRowSections width={2}>
                          <label htmlFor="client">Client</label>
                          {canCreateClient ? (
                            <SelectModalWithTabs
                              scheme={{ id: 'id', title: 'name' }}
                              items={clientsForSelect.data}
                              selected={values.client}
                              shoudOpenOnFocus
                              noSelectedMsg="Not selected"
                              onChange={(value) => {
                                setFieldValue('client', value);
                                setFieldValue('clientAgents', []);
                                getClientAgentsReq({ ownerId: value, size: 100 });
                                clientAgentRef && clientAgentRef.current && clientAgentRef.current.focus();
                              }}
                              onFocusLose={() => {
                                clientAgentRef && clientAgentRef.current && clientAgentRef.current.focus();
                              }}
                              elementToCreate={({ init, backToSelect, onCreateAndSelect }) => (
                                <CreateClientForm
                                  initName={init}
                                  createClient={createClient}
                                  backToSelect={(e) => {
                                    backToSelect(e);
                                  }}
                                  onCreateAndSelect={(str) => {
                                    onCreateAndSelect(str);
                                  }}
                                />
                              )}
                            />
                          ) : (
                            <SearchableSelect
                              scheme={{ id: 'id', title: 'name' }}
                              items={clientsForSelect.data}
                              selected={values.client}
                              noSelectedMsg="Not selected"
                              onChange={(value) => {
                                setFieldValue('client', value);
                                setFieldValue('clientAgents', []);
                                getClientAgentsReq({ ownerId: value, size: 100 });
                              }}
                              onBlur={() => {
                                clientAgentRef && clientAgentRef.current && clientAgentRef.current.focus();
                              }}
                            />
                          )}
                          <FormError name="client" errors={errors} touched={touched} />
                        </FormRowSections>

                        <FormRowSections width={2}>
                          <label htmlFor="clientAgents">Client agent</label>
                          {canCreateClientAgent ? (
                            <MultiSelectInputWithTabs
                              noSelectedMsg="Not selected"
                              items={allClientAgentsData}
                              selected={values.clientAgents}
                              scheme={{ id: 'id', title: 'name' }}
                              shoudOpenOnFocus
                              onChange={(value) => {
                                setFieldValue('clientAgents', value);
                              }}
                              onFocusLeave={() => {
                                productionManagerRef &&
                                  productionManagerRef.current &&
                                  productionManagerRef.current.focus();
                              }}
                              elementToCreate={({ init, backToSelect, onCreateAndSelect }) => (
                                <CreateAgent
                                  createAgentForClient={createClientAgent}
                                  initName={init}
                                  backToSelect={backToSelect}
                                  onCreateAndSelect={onCreateAndSelect}
                                  ownerId={values.client}
                                />
                              )}
                              ref={clientAgentRef}
                            />
                          ) : (
                            <MultiselectModal
                              noSelectedMsg="Not selected"
                              items={allClientAgentsData}
                              selected={values.clientAgents}
                              scheme={{ id: 'id', title: 'name' }}
                              shoudOpenOnFocus
                              onChange={(value) => {
                                setFieldValue('clientAgents', value);
                              }}
                              selectAllBtn={false}
                              ref={clientAgentRef}
                              onBlur={() => {
                                productionManagerRef &&
                                  productionManagerRef.current &&
                                  productionManagerRef.current.focus();
                              }}
                            />
                          )}
                          <FormError name="clientAgents" errors={errors} touched={touched} />
                        </FormRowSections>
                      </FormRow>

                      <FormRow>
                        <FormRowSections width={2}>
                          <label htmlFor="producer">Production manager</label>
                          {canCreateProductionManagerOrFreelancer ? (
                            <SelectModalWithTabs
                              scheme={{ id: 'id', title: 'name' }}
                              items={producers.filter((item) => item.isActive)}
                              selected={values.producer}
                              noSelectedMsg="Not selected"
                              onChange={(value) => {
                                setFieldValue('producer', value);
                              }}
                              shoudOpenOnFocus
                              elementToCreate={({ init, backToSelect, onCreateAndSelect }) => (
                                <CreateProducer
                                  initialName={init}
                                  isFreelancer={false}
                                  createProducer={createProducer}
                                  backToSelect={backToSelect}
                                  onCreateAndSelect={(value) => {
                                    onCreateAndSelect(value);
                                  }}
                                />
                              )}
                              onBlur={() => {
                                freelancerRef && freelancerRef.current && freelancerRef.current.focus();
                              }}
                              ref={productionManagerRef}
                            />
                          ) : (
                            <SearchableSelect
                              scheme={{ id: 'id', title: 'name' }}
                              items={producers.filter((item) => item.isActive)}
                              selected={values.producer}
                              shoudOpenOnFocus
                              onChange={(value) => {
                                setFieldValue('producer', value);
                              }}
                              onBlur={() => {
                                freelancerRef && freelancerRef.current && freelancerRef.current.focus();
                              }}
                              ref={productionManagerRef}
                            />
                          )}
                          <FormError name="producer" errors={errors} touched={touched} />
                        </FormRowSections>

                        <FormRowSections width={2}>
                          <label htmlFor="freelancers">Freelancers</label>
                          {canCreateProductionManagerOrFreelancer ? (
                            <MultiSelectInputWithTabs
                              items={producers.filter((item) => item.isActive && item.isFreelancer)}
                              selected={values.freelancers}
                              scheme={{ id: 'id', title: 'name' }}
                              onChange={(value) => {
                                setFieldValue('freelancers', value);
                              }}
                              ref={freelancerRef}
                              shoudOpenOnFocus
                              onFocusLeave={() => {
                                loadInFromRef && loadInFromRef.current && loadInFromRef.current.focus();
                              }}
                              elementToCreate={({ init, backToSelect, onCreateAndSelect }) => (
                                <CreateProducer
                                  initialName={init}
                                  isFreelancer={true}
                                  createProducer={createProducer}
                                  backToSelect={backToSelect}
                                  onCreateAndSelect={onCreateAndSelect}
                                />
                              )}
                            />
                          ) : (
                            <MultiselectModal
                              items={producers.filter((item) => item.isActive && item.isFreelancer)}
                              selected={values.freelancers}
                              scheme={{ id: 'id', title: 'name' }}
                              onChange={(value) => {
                                setFieldValue('freelancers', value);
                              }}
                              shoudOpenOnFocus
                              onBlur={() => {
                                loadInFromRef && loadInFromRef.current && loadInFromRef.current.focus();
                              }}
                              ref={freelancerRef}
                            />
                          )}
                          <FormError name="freelancers" errors={errors} touched={touched} />
                        </FormRowSections>
                      </FormRow>
                    </FieldsSet>
                  </FormBlock>

                  {/* LOAD DATES */}
                  <FormBlock>
                    <FieldsSet width={2}>
                      <FormRow>
                        <DateRange
                          value={values.loadIn}
                          fromLabel={'Load in from'}
                          toLabel={'Load in to'}
                          onChange={(value) => {
                            setFieldValue('loadIn', value);
                          }}
                          shoudSetDefaultValues
                          ref={loadInFromRef}
                        />
                      </FormRow>
                      <FormError name="loadIn" errors={errors} touched={touched} />

                      <FormRow>
                        <DateRange
                          value={values.loadOut}
                          fromLabel={'Load out from'}
                          toLabel={'Load out to'}
                          shoudSetDefaultValues
                          onChange={(value) => {
                            setFieldValue('loadOut', value);
                          }}
                        />
                      </FormRow>
                      <FormError name="loadIn" errors={errors} touched={touched} />
                    </FieldsSet>
                  </FormBlock>

                  {/* SHOWS */}
                  <FormBlock>
                    <FieldsSet width={2}>
                      <DateRangeListWithName
                        nameLabel="Show name"
                        fromLabel="Show start"
                        toLabel="Show end"
                        value={values.shows}
                        shoudSetDefaultValues
                        error={<FormError name="shows" errors={errors} touched={touched} />}
                        btn={<OutlineBtn title={'Add new show'} tabIndex={0} />}
                        onChange={(value) => {
                          setFieldValue('shows', value);
                        }}
                        checkboxForHiding="Hide in the schedule"
                        hidedShow={values.hideShows}
                        onHideShow={(e: any) => {
                          setFieldValue('hideShows', e.currentTarget.checked);
                        }}
                      />
                    </FieldsSet>
                  </FormBlock>

                  {/* REHEARSALS */}
                  <FormBlock>
                    <FieldsSet width={2}>
                      <DateRangeListWithName
                        nameLabel="Rehearsal name"
                        fromLabel="Rehearsal start"
                        toLabel="Rehearsal end"
                        value={values.rehearsals}
                        error={<FormError name="rehearsals" errors={errors} touched={touched} />}
                        btn={<OutlineBtn title={'Add new rehearsal'} tabIndex={0} />}
                        onChange={(value) => {
                          setFieldValue('rehearsals', value);
                        }}
                      />
                    </FieldsSet>
                  </FormBlock>

                  <FormBlock>
                    <FieldsSet width={1}>
                      <FormRow>
                        <label htmlFor="file">Production logo</label>
                        <ImageUpload
                          name="file"
                          file={_.get(values, 'file.file')}
                          onSelect={(file: any) => {
                            setFieldValue('file', file);
                          }}
                          supportedFormats={validatinShemas.SUPPORTED_IMAGE_FORMATS}
                          onSelectUnsupported={(file: any) => {
                            toast.error(`Unsupported image format "${file.type}"`);
                          }}
                        />
                        <FormError name="file" errors={errors} touched={touched} />
                      </FormRow>
                    </FieldsSet>
                  </FormBlock>
                  <FormBlock>
                    <FieldsSet width={1}>
                      <FormRow>
                        <label css={checkboxLabel}>
                          <Checkbox
                            name="showTransport"
                            checked={values.showTransport}
                            onChange={(e: any) => {
                              setFieldValue('showTransport', e.currentTarget.checked);
                            }}
                          />{' '}
                          Show Transport schedule
                        </label>
                        <label css={checkboxLabel}>
                          <Checkbox
                            name="showMachinery"
                            checked={values.showMachinery}
                            onChange={(e: any) => {
                              setFieldValue('showMachinery', e.currentTarget.checked);
                            }}
                          />{' '}
                          Show Machinery schedule
                        </label>
                      </FormRow>
                    </FieldsSet>
                  </FormBlock>
                </FieldsArea>
              </Fragment>
            </AsyncData>

            <ActionsRow>
              <Link to={routes.Productions} tabIndex={-1}>
                <OutlineForwarded title="Cancel" isWide={false} tabIndex={-1} ref={btnCancelRef} />
              </Link>
              <PrimaryBtn title="Create production" isWide={false} tabIndex={0} onKeyPress={handleLeaveFocusFromBtn} />
            </ActionsRow>
          </div>
        </Form>
      </Page>
    </Fragment>
  );
};

export const exchangeFormic = withFormik<FormProps & OtherProps, FormValues>({
  validationSchema: yup.object({}).shape({
    name: validatinShemas.vNameReq,
    clientAgents: validatinShemas.vIdListReq,
    producer: validatinShemas.vIdReq,
    client: yup.mixed().required(validatinShemas.REQUIRE_MESSAGE),
    location: yup.string().required(validatinShemas.REQUIRE_MESSAGE),
    code: yup.string().required(validatinShemas.REQUIRE_MESSAGE),
  }),
  mapPropsToValues: () => {
    return {
      name: '',
      subProduction: 'Main',
      location: '',
      code: '',
      client: null,
      clientAgents: [],
      producer: null,
      freelancers: [],
      loadIn: { from: '', to: '' },
      loadOut: { from: '', to: '' },
      shows: [{ name: '', range: { from: '', to: '' } }],
      rehearsals: [{ name: '', range: { from: '', to: '' } }],
      file: null,
      hideShows: false,
      showTransport: true,
      showMachinery: true,
    };
  },
  handleSubmit: ({ file, ...values }: any, { props: { createProductionReq }, setSubmitting }) => {
    file = file ? file.file : '';
    createProductionReq(prepareProductionValues({ ...values, file }));
  },
  displayName: 'CreateForm',
});

export default compose<OtherProps & FormikProps<FormValues>, {}>(
  connect(
    { clientsForSelect, allClientAgents: clientAgents, producers },
    {
      createProductionReq,
      getClientsForSelectReq,
      refreshClientForSelect,
      getClientAgentsReq,
      setClientAgents,
      setClientAgentsParams,
      getProducersReq,
      createClientAgent: createClientAgentInTabReq,
      createClient: createClientReqInTab,
      createProducer: createProducersReqInTab,
    }
  ),
  withVisualContext,
  withLifecycle,
  exchangeFormic
)(withAcl(CreateForm));

const checkboxLabel = css({
  cursor: 'pointer',
  marginTop: '12px',
  display: 'flex !important',
  alignItems: 'center  !important',
  columnGap: '10px',
});
