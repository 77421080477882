import * as yup from 'yup';

export const REQUIRE_MESSAGE = 'Required';
export const MIN_NAME_MESSAGE = 'Please use at least 3 characters';
export const MIN_PASS_MESSAGE = 'Please use at least 8 characters';
export const REQUIRE = 'Required';

export const SUPPORTED_IMAGE_FORMATS = ['svg', 'png', 'jpeg', 'jpg'];

export const PHONE_REG_EXP = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

export const PRECISION_REG_EXP = /(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/;

export const vNameReq = yup
  .string()
  .min(3, MIN_NAME_MESSAGE)
  .required(REQUIRE);

export const vEmailReq = yup
  .string()
  .email('Invalid email')
  .required(REQUIRE);

export const vPhoneReq = yup
  .string()
  .matches(PHONE_REG_EXP, 'Phone number is not valid')
  .required(REQUIRE);

export const vPasswordReq = yup
  .string()
  .min(8, MIN_PASS_MESSAGE)
  .required(REQUIRE);

export const vIdReq = yup
  .number()
  .nullable()
  .min(1)
  .required(REQUIRE);

export const vIdListReq = yup
  .array()
  .of(
    yup
      .number()
      .nullable()
      .min(1)
  )
  .required(REQUIRE);

export const vRange = yup.object({}).shape({
  from: yup.mixed().test('checkRange', 'message', function(value) {
    const { to } = this.parent;
    if (value) {
      return to >= value
        ? true
        : this.createError({ path: this.path, message: 'Start time must be less then end time' });
    }
    return this.createError({ path: this.path, message: 'Required' });
  }),

  to: yup.mixed().test('checkRange', 'message', function(value) {
    const { from } = this.parent;
    if (value) {
      return value >= from
        ? true
        : this.createError({ path: this.path, message: 'End time must be greater then start time' });
    }
    return this.createError({ path: this.path, message: 'Required' });
  }),
});

export const vCoordinates = (limit, min, max) => {
  // eslint-disable-next-line
  return yup.string().test('CoordinatesTest', '${message}', function validateCoordinates(value) {
    if (!isNaN(value)) {
      if (value < min) {
        return this.createError({ path: this.path, message: `Number must be greater than ${min}` });
      }

      if (value > max) {
        return this.createError({ path: this.path, message: `Number must be less than ${max}` });
      }

      const places = value.toString().match(PRECISION_REG_EXP);
      const decimals = Math.max((places[1] ? places[1].length : 0) - (places[2] ? parseInt(places[2], 10) : 0), 0);
      if (decimals <= limit) {
        return value;
      }

      return this.createError({ path: this.path, message: 'After the comma should be no more than 9 numbers' });
    }

    return this.createError({ path: this.path, message: 'Must be a number' });
  });
};

export const required = yup.string().required(REQUIRE);
